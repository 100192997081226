import React from "react";
import styled from "styled-components";
import { useTable, usePagination, useRowSelect, useSortBy } from "react-table";
import theme from "theme";
const Styles = styled.div`
  padding: 0px;

  table {
    border-spacing: 0;
    background-color: white;
    width: 100%;

    th {
      font-weight: normal;
      font-size: 14px;
      color: #9a9a9a;
      padding: 20px 8px 8px 20px;
      border-bottom: 1px solid #d8d8d8;
      font-family:${theme.palette.fontList.selectedFont};
    }

    td {
      margin: 0;
      padding-left: 20px;
      border-bottom: none;
      font-family:${theme.palette.fontList.selectedFont};
      :last-child {
        border-right: 0;
      }
    }
  }

  .table-checkbox {
    display: flex;
    justify-content: center;
  }

  .pagination {
    padding: 0.5rem;
  }
`;

// const IndeterminateCheckbox = React.forwardRef(
//   ({ indeterminate, ...rest }, ref) => {
//     const defaultRef = React.useRef();
//     const resolvedRef = ref || defaultRef;

//     React.useEffect(() => {
//       resolvedRef.current.indeterminate = indeterminate;
//     }, [resolvedRef, indeterminate]);

//     return (
//       <>
//         <input type="checkbox" ref={resolvedRef} {...rest} />
//       </>
//     );
//   }
// );

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds }
  } = useTable(
    {
      columns,
      data
    },
    useSortBy,
    usePagination,
    useRowSelect
    // hooks => {
    //   hooks.visibleColumns.push(columns => [
    //     // Let's make a column for selection
    //     {
    //       id: "selection",
    //       // The header can use the table's getToggleAllRowsSelectedProps method
    //       // to render a checkbox
    //       Header: ({ getToggleAllPageRowsSelectedProps }) => (
    //         <div className="table-checkbox">
    //           <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
    //         </div>
    //       ),
    //       // The cell can use the individual row's getToggleRowSelectedProps method
    //       // to the render a checkbox
    //       Cell: ({ row }) => (
    //         <div className="table-checkbox">
    //           <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
    //         </div>
    //       )
    //     },
    //     ...columns
    //   ]);
    // }
  );

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

function TrustedDomainTable(props) {
  const data = React.useMemo(() => props.domainList, []);

  const columns = React.useMemo(
    () => [
      {
        Header: <p style={{ textAlign: "start" }}>Domains</p>,
        accessor: "domain" // accessor is the "key" in the data
      },
      {
        Header: <p style={{ textAlign: "end" }}>Action</p>,
        accessor: "action"
      }
    ],
    []
  );

  return (
    <Styles>
      <Table columns={columns} data={data} toggleRightDrawer={props.callback} />
    </Styles>
  );
}

export default TrustedDomainTable;
