import React, { useEffect, useState } from "react";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// Local
import Menu from "../Components/Menu";
import ManageUsers from "./components/ManageUsers";
import SignUpRequests from "./components/SignUpRequests";
import PermissionProfile from "./components/PermissionProfile";
import ViewTraining from "./Instructor/ViewTraining";
import { useDispatch } from "react-redux";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import CreateDealID from "./components/CreateDealID";
import Chatbot from "views/Components/chatbot/Chatbot";
// Styling
const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "50px"
  }
}));

const Usermanagement = () => {
  const profileData = JSON.parse(localStorage.getItem("profileData"));
  const account = profileData.account;
  let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
  let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
  let hasDomain = domain?.some((res) => userEmail.includes(res));
  const [isInternal, setIsInternal] = useState(hasDomain);
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(SET_NAVBAR_TITLE(isInternal?account+"  - User Management": "User Management"));
  }, []);
  // States
  var location_url = window.location.pathname;
  var path = "";
  if(location_url.includes('instructer')){
    path = "Instructor Access";
  }else{
    path = "Users";
  }
  const [currentScreen, setCurrentScreen] = useState(path);

  const menuItems = ["Users", "Sign-up requests", "Permission profiles", "Instructor Access","Create Deal ID"];
  
  const renderContent = () => {
    switch (currentScreen) {
      case "Users":
        return <ManageUsers />;
      case "Sign-up requests":
        return <SignUpRequests />;
      case "Permission profiles":
        return <PermissionProfile />;
      case "Instructor Access":
        return <ViewTraining/>
        break;
      case "Create Deal ID":
        return <CreateDealID/>
        break;
      case "default":
        return <ManageUsers />;
    }
  };

  return (
    <div>
      <Grid container className={classes.root}>
        <Grid item md={2}>
          <Menu
            items={menuItems}
            onItemClick={setCurrentScreen}
            currentScreen={currentScreen}
          />
        </Grid>

        <Grid item md={10}>
          {renderContent()}
        </Grid>
      </Grid>
      <Chatbot/>
    </div>
  );
};

export default Usermanagement;