// This component creates a modal for Reserved IP with respective form fields, the
// modal is popped up during provision or resuming of a lab or poc.

import React, { Component, useState } from "react";
import { SECONDARY_COLOR } from "global_constants/app_constants";
import Button from "@material-ui/core/Button";
// Formik
import { useFormik } from "formik";
import Datetime from "react-datetime";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Api, Urls } from "config/api";
import Loader from "react-loader-spinner";
import { LOADER_TYPE } from "global_constants/app_constants";
import "views/ServiceOffering/LearningLab/acess labs/Overview.scss";
import NotificationAlert from "views/Components/NotificationAlert";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import theme from "theme";

const customStyles = theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    position: "absolute",
    width: 550,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    display: "block"
  },
  buttStyle: {
    background: theme.palette.buttonColor.main,
    "&:hover": {
      background: theme.palette.buttonColor.main
    }
  }
});

const DeleteStaticIpModal = props => {
  const useStyles = makeStyles(customStyles);
  const classes = useStyles();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");

  const hideModal = () => {
    props.hideModal(true);
  };

  const handleDelete = () => {
    setShowNotification(false);
    setBtnDisabled(true);

    let payload = {
      ip: props.ip,
      name: props.name
    };
    SDCloudBackendAPIWithToken()
      .post(Api.deleteStaticIp, payload)
      .then(resp => resp.data)
      .then(data => {
        //   setShowNotification(true);
        //   setNotificationMessage(
        //     "Domain successfully added to trusted domains"
        //   );
        //   setNotificationType("success");
        props.deleteConfirmAlert();
        props.renderData();
        setBtnDisabled(false);
        hideModal();
      })
      .catch(err => {
        let error = err.response.data;
        setShowNotification(true);
        setNotificationMessage(error.message);
        setNotificationType("error");
        setBtnDisabled(false);
      });
  };

  return (
    // Form modal for add hours to lab
    <Modal
      disableAutoFocus
      open={!props.openModal}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      className={classes.modal}
      onClose={() => hideModal()}
    >
      <GridContainer
        justify="center"
        alignItems="center"
        className={classes.paper}
      >
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginBottom: "20px", marginTop: "20px" }}
        >
          <Typography variant="h5">
            Are you sure you want to delete address "{props.name}" ?
          </Typography>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <GridContainer style={{ marginTop: "20px" }}>
            <GridItem xs={12} sm={6} md={6} lg={6}>
              {!btnDisabled ? (
                <Button
                  variant="contained"
                  color="secondary"
                  disableElevation
                  size="large"
                  className={classes.buttStyle}
                  fullWidth
                  onClick={handleDelete}
                  disableFocusRipple
                  type="submit"
                >
                  Delete
                </Button>
              ) : null}
              {btnDisabled ? (
                <Loader
                  type={LOADER_TYPE}
                  color={theme.palette.secondary.main}
                  width={50}
                  height={50}
                  style={{ float: "right", marginRight: "15px" }}
                />
              ) : null}
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={6}>
              <Button
                variant="outlined"
                color="secondary"
                disableElevation
                size="large"
                // className={classes.buttStyle}
                fullWidth
                onClick={hideModal}
                disableFocusRipple
                type="submit"
              >
                Cancel
              </Button>
            </GridItem>
          </GridContainer>

          <NotificationAlert
            notificationType={notificationType}
            showNotification={showNotification}
            message={notificationMessage}
          />
        </GridItem>
      </GridContainer>
    </Modal>
  );
};

export default DeleteStaticIpModal;
