/* eslint-disable react/prop-types */
// view for rendering right hand side drawer from navbar
import React, { useEffect, useState } from "react";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CloseIcon from "@material-ui/icons/Close";
import { FormControl, Grid, TextField } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import { inputBorderColor } from "assets/jss/material-dashboard-pro-react.js";
// material-UI imports

import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Autocomplete from "@material-ui/lab/Autocomplete";

// import Button from "components/CustomButtons/Button.js";
import Button from "@material-ui/core/Button";
import globalTheme from "../../theme";
import { PRIMARY_COLOR } from "global_constants/app_constants";
import NotificationAlert from "../Components/NotificationAlert";
import ImageUpload from "components/CustomUpload/ImageUpload";
import AlertDiv from "components/StatusMessages/StatusMessage";
import { Api } from "config/api";
import { useSelector } from "react-redux";
import LoaderHoc from "components/HOC/LoaderHoc";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import theme from "../../theme";
const styles = () => ({
  container: {
    // padding: "15px 30px"
  },
  contentContainer: {
    // paddingTop: "45px"
  },
  contentHeading: {
    fontSize: 24,
    fontWeight: "bold"
    // padding: "15px 30px"
  },
  form: {
    padding: "15px 0 15px 0"
  },
  dropdownPadding: {
    paddingTop: "15px"
  },
  autoCompleteStyle: {
    "&.MuiAutocomplete-option[data-focus='true']": {
      color: "#FFFFFF",
      backgroundColor: globalTheme.palette.secondary.main
    },
    "&:hover,&:focus,&:visited": {
      color: "#FFFFFF",
      backgroundColor: globalTheme.palette.secondary.main
    },
    '&[aria-selected="true"]': {
      color: "#FFFFFF",
      backgroundColor: globalTheme.palette.secondary.main
    }
  },
  customDropDownStyle: {
    marginTop: "15px"
  },
  select: {
    "& li": {
      "&:hover,&:focus,&:visited": {
        color: "#FFFFFF",
        backgroundColor: globalTheme.palette.secondary.main
      },
      "&.MuiListItem-root.Mui-selected, &.MuiListItem-root.Mui-selected:hover": {
        color: "#FFFFFF",
        backgroundColor: globalTheme.palette.secondary.main
      }
    }
  },
  labelText: {
    color: "black"
  },
  uploadContainer: {
    marginTop: "20px",
    border: "1px solid #8C8C8C",
    boxShadow: "0px 4px 4px 0px #000000 25%",
    borderStyle: "dashed",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
  },
  btnStyle: {
    background: `${theme.palette.buttonColor.main} !important`
  }
});
const useStyles = makeStyles(styles);
const Header = ({ toggleDrawer }) => {
  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={1}>
        <Grid container justify="flex-start" alignItems="center">
          <HelpOutlineIcon fontSize="small" />
        </Grid>
      </Grid>
      <Grid item xs={9}>
        Help & Support
      </Grid>
      <Grid item xs={2}>
        <Grid
          container
          justify="center"
          alignItems="center"
          onClick={() => toggleDrawer()}
          style={{ cursor: "pointer" }}
        >
          <CloseIcon />
        </Grid>
      </Grid>
    </Grid>
  );
};
const Dropdown = ({ value, setValue, menu, onChange, type, label }) => {
  const classes = useStyles();

  const handleDropDown = fieldName => {
    setValue(fieldName.target.value);
    if (type === "offering") {
      onChange(fieldName.target.value);
    }
  };
  return (
    <>
      <FormControl
        fullWidth
        variant="filled"
        className={classes.customDropDownStyle}
      >
        <InputLabel
          id={label}
          // htmlFor="offeringSelect"
          disableAnimation
          shrink={false}
          className="mt-1"
        >
          Please Select...
        </InputLabel>
        <Select
          value={value}
          disableUnderline
          onChange={event => handleDropDown(event)}
          inputProps={{
            name: "offeringSelect"
          }}
          MenuProps={{ classes: { paper: classes.select } }}
          // className={classes.select}
          labelId={label}
        >
          {menu.map((menuItemVal, index) => {
            return (
              <MenuItem key={index} value={menuItemVal}>
                {menuItemVal}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

const Content = props => {
  const classes = useStyles();
  const [subOfferingMenu, setSubOfferingMenu] = React.useState([]);
  const [isSubofferingLoading, setSubofferingLoading] = React.useState(false);
  const [currentOffering, setCurrentOffering] = React.useState(null);
  // const [currentPage, setCurrentPage] = React.useState(0);
  // const [totalPage, setTotalPage] = React.useState(null);
  let resourceData = [];
  let currentPage = 0;
  let totalPage = 0;
  let iterations = 0;
  const [resourceCount, setResourceCount] = React.useState(0);
  const [perPage, setPerPage] = React.useState(500);

  const {
    offeringValue,
    setOfferingValue,
    subOfferingValue,
    setSubOfferingValue,
    handleSubmit,
    handleChange,
    title,
    email,
    description,
    setFiles,
    status,
    message,
    isLoading,
    upgradetype
  } = props;
  const offeringMenu = [
    "Learning Center",
    "Experience Center",
    "Design Center",
    "Others"
  ];

  const [open, setOpen] = React.useState(false);
  const loading = open && subOfferingMenu.length === 0;

  const fetchOffering = async offeringName => {
    setSubofferingLoading(true);
    const offeringDetailsUrls = {
      [offeringMenu[0]]: Api.getNewLearning,
      [offeringMenu[1]]: Api.getClusterlist,
      [offeringMenu[2]]: Api.designerTemplateList
    };

    setCurrentOffering(currentOffering => currentOffering);
    const res = await SDCloudBackendAPIWithToken()
      .post(Api.getSupportresources, {
        first: currentPage,
        last: perPage
      })
      .then(res => {
        totalPage = res.data.total;
      })
      .catch(err => {
        // clearInterval(timer);
        // alert("something went wrong" + err.response.data.message);
        setSubOfferingMenu([]);
        setSubofferingLoading(false);
      });

    iterations = Math.ceil(totalPage / perPage);
    for (let i = 0; i < iterations; i++) {
      await SDCloudBackendAPIWithToken()
        .post(Api.getSupportresources, {
          first: currentPage,
          last: currentPage + perPage
        })
        .then(res => {
          currentPage = currentPage + perPage + 1;
          let responseArray = [];
          let resourceObject = res.data;
          let menu;
          if (offeringName === offeringMenu[0]) {
            for (var resource of Object.keys(resourceObject)) {
              if (resourceObject[resource].offering_type == "learning") {
                if (resourceObject[resource].clusters) {
                  for (
                    let i = 0;
                    i < resourceObject[resource].clusters.length;
                    i++
                  ) {
                    let resource_name = resourceObject[resource].display_name
                      ? `${resourceObject[resource].display_name} - ${resourceObject[resource].clusters[i].cluster_name}`
                      : `${resource} - ${resourceObject[resource].clusters[i].cluster_name}`;
                    responseArray.push({
                      clusterName: resource_name,
                      clusterDetails: resourceObject[resource].clusters[i]
                    });
                  }
                } else if (resourceObject[resource].display_name) {
                  let resource_name = resourceObject[resource].display_name;
                  responseArray.push({
                    clusterName: resource_name,
                    clusterDetails: resource_name
                  });
                } else {
                  responseArray.push({
                    clusterName: resource,
                    clusterDetails: resource
                  });
                }
              }
            }
            menu = responseArray.map(item => {
              return { label: item.clusterName, value: item.clusterName };
            });
          } else if (offeringName === offeringMenu[1]) {
            for (var resource of Object.keys(resourceObject)) {
              if (resourceObject[resource].offering_type == "sales demo") {
                if (resourceObject[resource].clusters) {
                  for (
                    let i = 0;
                    i < resourceObject[resource].clusters.length;
                    i++
                  ) {
                    let resource_name = resourceObject[resource].display_name
                      ? `${resourceObject[resource].display_name} - ${resourceObject[resource].clusters[i].cluster_name}`
                      : `${resource} - ${resourceObject[resource].clusters[i].cluster_name}`;
                    responseArray.push({
                      clusterName: resource_name,
                      clusterDetails: resourceObject[resource].clusters[i]
                    });
                  }
                } else if (resourceObject[resource].display_name) {
                  let resource_name = resourceObject[resource].display_name;
                  responseArray.push({
                    clusterName: resource_name,
                    clusterDetails: resource_name
                  });
                } else {
                  responseArray.push({
                    clusterName: resource,
                    clusterDetails: resource
                  });
                }
              }
              if (resourceObject[resource].offering_type == "poc") {
                if (resourceObject[resource].clusters) {
                  for (
                    let i = 0;
                    i < resourceObject[resource].clusters.length;
                    i++
                  ) {
                    let resource_name = resourceObject[resource].display_name
                      ? `${resourceObject[resource].clusters[i].cluster_name} - ${resourceObject[resource].display_name}`
                      : `${resourceObject[resource].clusters[i].cluster_name} - ${resource}`;
                    responseArray.push({
                      clusterName: resource_name,
                      clusterDetails: resourceObject[resource].clusters[i]
                    });
                  }
                } else if (resourceObject[resource].display_name) {
                  let resource_name = resourceObject[resource].display_name;
                  responseArray.push({
                    clusterName: resource_name,
                    clusterDetails: resource_name
                  });
                } else {
                  responseArray.push({
                    clusterName: resource,
                    clusterDetails: resource
                  });
                }
              }
            }
            menu = responseArray.map(item => {
              return { label: item.clusterName, value: item.clusterName };
            });
          } else if (offeringName === offeringMenu[2]) {
            for (var resource of Object.keys(resourceObject)) {
              if (resourceObject[resource].offering_type == "designer") {
                let resource_name = resource.split("|");
                if (resource_name[0] != "") {
                  responseArray.push({
                    clusterName: resource_name[0],
                    clusterDetails: {
                      cluster_name: resource_name[0],
                      uuid: resource_name[1]
                    }
                  });
                }
              }
            }
            menu = responseArray.map(item => {
              return { label: item.clusterName, value: item.clusterName };
            });
          }
          // to extract clusterdetails instead of set array should be used.
          // let menuset = Array.from(new Set(menu));
          menu.sort();
          resourceData = [...resourceData, ...menu];
          setSubOfferingMenu(resourceData);
          setSubofferingLoading(false);
        })
        .catch(err => {
          // clearInterval(timer);
          // alert("something went wrong" + err.response.data.message);
          // setSubOfferingMenu([]);
          setSubofferingLoading(false);
        });
    }
  };
  const onChange = selectedValue => {
    // resetting dropdown list and value
    setSubOfferingValue("");
    setSubOfferingMenu([]);
    // do nothing on selecting 'others'
    if (selectedValue === offeringMenu[3]) {
      return false;
    }
    fetchOffering(selectedValue);
  };

  return (
    <Grid
      className={classes.contentContainer}
      container
      justify="center"
      alignItems="center"
    >
      {/* <Grid item xs={12}>
        <h2 className={classes.contentHeading}>Submit a new issue </h2>
      </Grid> */}
      <Grid item xs={12}>
        <Grid container justify="flex-start" alignItems="flex-start">
          <Grid item xs={12}>
            <form noValidate>
              <FormControl
                fullWidth
                focused
                className={classes.customInputStyle}
              >
                {/* <InputLabel htmlFor="issue-title">Issue Title</InputLabel> */}
                <TextField
                  InputProps={{ disableUnderline: true }}
                  style={{ padding: 0, margin: 0 }}
                  rows={1}
                  required
                  label="Issue Title"
                  InputLabelProps={{ disableAnimation: true, shrink: false }}
                  id="issue-title"
                  variant="filled"
                  value={title}
                  onChange={event => handleChange(event.target.value, "title")}
                />
              </FormControl>
              <FormControl
                fullWidth
                focused
                className={classes.customInputStyle}
              >
                <TextField
                  rows={1}
                  required
                  id="email"
                  variant="filled"
                  label="Enter your email for further communication"
                  InputLabelProps={{ disableAnimation: true, shrink: false }}
                  InputProps={{ disableUnderline: true }}
                  style={{ paddingTop: "15px" }}
                  value={email}
                  onChange={event => handleChange(event.target.value, "email")}
                />
              </FormControl>
              <FormControl
                fullWidth
                focused
                className={classes.customInputStyle}
                // variant="outlined"
              >
                <TextField
                  id="issue-description"
                  multiline
                  rows={4}
                  variant="filled"
                  label="Issue Description"
                  InputLabelProps={{ disableAnimation: true, shrink: false }}
                  InputProps={{ disableUnderline: true }}
                  style={{ paddingTop: "15px" }}
                  value={description}
                  onChange={event =>
                    handleChange(event.target.value, "description")
                  }
                />
              </FormControl>
              <FormControl
                fullWidth
                focused
                className={classes.customInputStyle}
                // variant="outlined"
              >
                {/* <InputLabel htmlFor="Offering">Offering</InputLabel> */}
                <Dropdown
                  value={offeringValue}
                  setValue={setOfferingValue}
                  menu={offeringMenu}
                  onChange={onChange}
                  type="offering"
                  label="Offering"
                  InputLabelProps={{ disableAnimation: true, shrink: false }}
                />
              </FormControl>
              {/* {offeringValue ? (
                <LoaderHoc isLoading={isSubofferingLoading}>
                  <FormControl
                    fullWidth
                    focused
                    className={classes.customInputStyle}
                   
                  >
                   

                    <Autocomplete
                      ListboxProps={{ id: "async-infinite-scroll" }}
                      open={open}
                      onOpen={() => {
                        setOpen(true);
                      }}
                      onClose={() => {
                        setOpen(false);
                      }}
                      onChange={(event, value) =>
                        value ? setSubOfferingValue(value.value) : null
                      }
                      classes={{
                        groupLabel: classes.autoCompleteStyle,
                        option: classes.autoCompleteStyle
                      }}
                      
                      getOptionLabel={option => option.label.toString()}
                      
                      options={subOfferingMenu}
                      loading={loading}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Please Select..."
                          variant="filled"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                <LoaderHoc isLoading={loading}></LoaderHoc>
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )}
                    />

                    
                  </FormControl>
                </LoaderHoc>
              ) : null} */}
              {/* <Uploader setFiles={setFiles} /> */}

              <LoaderHoc isLoading={isLoading}>
                <Button
                  onClick={(e) => {
                    handleSubmit(e);
                  }}
                  variant="contained"
                  color="secondary"
                  disableElevation
                  size="large"
                  fullWidth
                  disableFocusRipple
                  className={classes.btnStyle}
                  type="submit"
                >
                  submit
                </Button>
              </LoaderHoc>
              <AlertDiv status={status} message={message} />
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default function RightSideDrawer({ toggleDrawer , upgradetype }) {
  const classes = useStyles();

  const [offeringValue, setOfferingValue] = useState(upgradetype.slice(8, 25));
  const [subOfferingValue, setSubOfferingValue] = useState("");
  const [files, setFiles] = useState(null);
  const [state, setState] = useState({
    title: upgradetype,
    email: JSON.parse(localStorage.getItem("profileData")).email,
    description: ""
  });
  const [apiResponse, setApiResponse] = React.useState({
    status: "",
    message: "",
    isLoading: false
  });
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");

  const { profileObj } = useSelector(state => state.profileObj);
  const callSupportApi = (attachments, support) => {
    const {
      title,
      description,
      email,
      offeringValue,
      subOfferingValue
    } = support;

    var formData = new FormData();
    if (attachments) {
      for (let i = 0; i < attachments.length; i++) {
        formData.append("attachments", attachments[i]);
        formData.append("mail_attachments", attachments[i]);
      }
    } else {
      formData.append("attachments", "");
    }
    formData.append(
      "support",
      `{
      "title": "${title}",
      "description": "${description}",
      "email": "${email || profileObj.email}",
      "offering": "${offeringValue}",
      "cluster_details": "",
      "profile": "",
      "template": "${subOfferingValue}"
    }`
    );
    setApiResponse({
      status: "",
      message: "",
      isLoading: true
    });
    SDCloudBackendAPIWithToken()
      .post(Api.support, formData)
      .then(res => {
        setApiResponse({
          status: "success",
          message: "Ticket created successfully",
          isLoading: false
        });
      })
      .catch(err => {
        setApiResponse({
          status: "error",
          message: `Failed to create a ticket. Please mail us at sdcloud-support@criterionnetworks.com ${err.response.data.message}`,
          isLoading: false
        });
      });
  };

  const handleSubmit = (e) => {
    if (!isValid()) {
      e.preventDefault();
      setShowNotification(true);
      setNotificationMessage("Fill all the required fields");
      setNotificationType("error");
      return false;
    } else {
      // api call logic goes here.
      // //console.log({
      //   attachments: files,
      //   support: { ...state, offeringValue, subOfferingValue }
      // });
      callSupportApi(files, { ...state, offeringValue, subOfferingValue });
      setNotificationMessage("");
      setNotificationType("");
    }
  };

  const handleChange = (value, type) => {
    if (type === "title") {
      setState({ ...state, title: value });
      return;
    } else if (type === "email") {
      setState({ ...state, email: value });
      return;
    } else if (type === "description") {
      setState({ ...state, description: value });
      return;
    }
  };
  const isValid = () => {
    return (
      state.title.trim().length > 0 &&
      state.email.trim().length > 0 &&
      state.description.trim().length > 0 &&
      offeringValue.trim().length > 0
    );
  };
  return (
    <Grid
      container
      className={classes.container}
      justify="center"
      alignItems="center"
    >
      <Grid item xs={12}>
        {/* <Header toggleDrawer={toggleDrawer} /> */}
        <Content
          offeringValue={offeringValue}
          setOfferingValue={setOfferingValue}
          subOfferingValue={subOfferingValue}
          setSubOfferingValue={setSubOfferingValue}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          setFiles={setFiles}
          status={apiResponse.status}
          message={apiResponse.message}
          isLoading={apiResponse.isLoading}
          upgradetype={upgradetype}
          {...state}
        />
        <NotificationAlert
          notificationType={notificationType}
          showNotification={showNotification}
          message={notificationMessage}
        />
      </Grid>
    </Grid>
  );
}