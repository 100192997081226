import {
  cardTitle,
  successColor,
  dangerColor
} from "assets/jss/material-dashboard-pro-react.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import theme from "../../../../theme";
const regularFormsStyle = {
  ...customCheckboxRadioSwitch,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  staticFormGroup: {
    marginLeft: "0",
    marginRight: "0",
    paddingBottom: "10px",
    margin: "8px 0 0 0",
    position: "relative",
    "&:before,&:after": {
      display: "table",
      content: '" "'
    },
    "&:after": {
      clear: "both"
    }
  },
  staticFormControl: {
    marginBottom: "0",
    paddingTop: "8px",
    paddingBottom: "8px",
    minHeight: "34px"
  },
  inputAdornment: {
    marginRight: "8px",
    position: "relative"
  },
  inputAdornmentIconSuccess: {
    color: successColor[0] + "!important"
  },
  inputAdornmentIconError: {
    color: dangerColor[0] + "!important"
  },
  linkStyle: {
    color: theme.palette.secondary.main,
    cursor: "pointer",
    marginTop: "10px",
    borderBottom: `0.5px solid ${theme.palette.secondary.main}`,
    "&:hover": {
      color: theme.palette.secondary.main,
      borderBottom: "none"
    }
  },
  labelRoot: {
    fontSize: "14px"
  },
  inputMargin: {
    margin: 0
  },
  customTabRoot: {
    color: `#000`,
    "& .Mui-selected .MuiTab-wrapper": {
      color: `${theme.palette.secondary.main}`
    },
    "& .MuiTab-wrapper":{
        fontFamily: theme.palette.fontList.selectedFont
    }
  },
  backColor: {
    background: theme.palette.buttonColor.main,
    "&:hover":{
      background: theme.palette.buttonColor.main
    }
  }
};

export default regularFormsStyle;