import React, { useEffect, useState } from "react";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
//react 
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// Formik
import { useFormik } from "formik";
// Material UI Components
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {hotjar} from "react-hotjar";
import GridItem from "components/Grid/GridItem";
//markdown editor
import Editor from "react-markdown-editor-lite";
import ReactMarkdown from "react-markdown";
import MarkdownIt from 'markdown-it';
//markdown editor import style manually
import 'react-markdown-editor-lite/lib/index.css';
//API Call
import { CMSAPIWithToken } from "../../../apis/CMS";
import queryString from "query-string";

const useStyles = makeStyles((theme) => ({
  
  gridContainer: {
    padding: "0 40px 30px 40px",
  },
  radioButton: {
    "& .MuiTypography-root": {
      marginLeft: "10px",
    },
  },
  formControl: {
    width: "100%",
    marginTop: "5px",
  },
  Select: {
    marginBottom: "50px",
  },
  warningNote: {
    "& .MuiAlert-icon": {
      display: "none",
    },
  },
  button: {
    marginTop: "50px",
  },
  buttOutlined: {
    color: theme.palette.buttonColor.main,
    borderColor: theme.palette.buttonColor.main,
    "&:hover": {
      border: `1px solid ${theme.palette.buttonColor.main}`,
    },
  },
}));

//sandbox view main
const LearningExercisesUpdate = (props) => {
  const classes = useStyles();
  hotjar.initialize(2281731, 6);
  const mdEditor = React.useRef(null);
  hotjar.identify(localStorage.userName, { userProperty: localStorage.userName});
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [solution, setSolution] = useState("");
  const [introList, setIntroList] = useState("");
  let queryParams = queryString.parse(window.location.search);
  var lern_id = queryParams.id;
  var cat_id = props.category;
  var learning_id_title_map = JSON.parse(localStorage.getItem("lern_id_title_map"))
  var learn_cat_id_map = JSON.parse(localStorage.getItem("learn_cat_id_map"))
  var learning_title = learning_id_title_map[lern_id]
  var category_title = learn_cat_id_map[cat_id]
  const dispatch = useDispatch();
    const history = useHistory();
    const inputProps = {
        disableAnimation: true,
        shrink: false
      };  

      const ITEM_HEIGHT = 48;
      const ITEM_PADDING_TOP = 8;
      const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
          },
        },
      };
      
   

  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
        admin_learnings_id: lern_id,
        admin_learnings_course_labs_category_id: cat_id,
        title: "",
        intro_list: "",
        solution: "",
        exercise_position: ""
              },
    onSubmit: (values) => {
      let payload = {
        admin_learnings_id: lern_id,
        admin_learnings_course_labs_category_id: cat_id,
        title: values.title,
        intro_list: introList,
        solution: solution,
        exercise_position: values.exercise_position,
      };

        CMSAPIWithToken() 
        .patch("/learnings/admin_learnings_course_labs_exercises"+`/${props.user}/`,payload) 
        .then((resp) => resp.data.results)
        .then((data) => {
            const payload = {
                message: "Learning Exercises updated Successfully",
                color: "success",
            };
            payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
            history.push("ProductContentLearningsMenuItems?id="+lern_id+"&title="+learning_title);
        })
        .catch((err) => {
          let message = err.response.data.message;
          const payload = {
            message: message,
            color: "danger",
        };
        payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
    },
    validate: (values) => {
      let errors = {};
      if (!values.admin_learnings_course_labs_category_id) {
        errors.admin_learnings_course_labs_category_id = "Please select a category";
      }
      if (!values.title) {
        errors.title = "This field may not be blank.";
      }
      if (!values.exercise_position) {
        errors.exercise_position = "This field may not be blank.";
      }
      return errors;
    },
  });

    //mark down editor
    const handleSolutionChange = ({ html, text }) => {
      const newValue = text;
      //console.log(newValue);
      setSolution(newValue);
    };
    const handleIntroListChange = ({ html, text }) => {
      const newValue = text;
      //console.log(newValue);
      setIntroList(newValue);
    };
      // Initialize a markdown parser
      const mdParser = new MarkdownIt(/* Markdown-it options */);

  let data = [];

  const renderSanboxView = async () => {
    let sanboxView = CMSAPIWithToken()
      .get("/learnings/admin_learnings_course_labs_exercises/")
      .then((resp) => resp.data.results)
      .then((resp) => {
        resp.forEach((item) => {
          if(props.user == item.id) { 
          formik.setFieldValue("admin_learnings_id", item?.admin_learnings_id);
          formik.setFieldValue("admin_learnings_course_labs_category_id", item?.admin_learnings_course_labs_category_id);
          formik.setFieldValue("title", item?.title);
          setIntroList(item.intro_list);
          setSolution(item.solution);
          formik.setFieldValue("exercise_position", item?.exercise_position);
          formik.setFieldValue("created", item?.created);
          formik.setFieldValue("modified", item?.modified);
        }
        });
      })      
      .catch((err) => {
        let message = err.response.data.message;
        // setRows([]);
        // setIsLoading(false);
        setShowNotification(true);
        setNotificationType("error");
        setNotificationMessage("Failed to retrieve the data");
      });

      

      //setUserDetails({ ...formik.userDetails, ...data });
  };

  useEffect(() => {
    renderSanboxView();
  }, []);

  //checkbox
  
    const [state, setState] = React.useState({
      checkedA: true,
      checkedB: true,
      checkedF: true,
      checkedG: true,
    });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div>
        <form
            onSubmit={formik.handleSubmit}
            className={classes.formContainer}
          >
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.gridContainer}
      >
        <Grid item xs={12} md={5}>
          <Typography variant="h4">Learning Exercises Update</Typography>
          
        </Grid>

        <GridItem md={12}>
                  {formik.errors.admin_learnings_id && formik.touched.admin_learnings_id ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Learnings Name"
                      name="admin_learnings_name"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={learning_title}
                      onChange={formik.handleChange}
                      helperText={formik.errors.admin_learnings_id}
                      onBlur={formik.handleBlur}
                      error
                      disabled={"disabled"}
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Learnings Name"
                      name="admin_learnings_name"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={learning_title}
                      onChange={formik.handleChange}
                      disabled={"disabled"}
                    />
                  )}
                </GridItem>

                <GridItem md={12}>
                  {formik.errors.admin_learnings_course_labs_category_id && formik.touched.admin_learnings_course_labs_category_id ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Learnings course labs category"
                      name="admin_learnings_course_labs_category_name"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={category_title}
                      onChange={formik.handleChange}
                      helperText={formik.errors.admin_learnings_course_labs_category_id}
                      onBlur={formik.handleBlur}
                      error
                      disabled={"disabled"}
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Learnings course labs category"
                      name="admin_learnings_course_labs_category_name"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={category_title}
                      onChange={formik.handleChange}
                      disabled={"disabled"}
                    />
                  )}
                </GridItem>

                <GridItem md={12}>
                {formik.errors.title && formik.touched.title ? (
                  <TextField
                        type="text"
                        variant="filled"
                        label="Title"
                        name="title"
                        fullWidth
                        InputLabelProps={inputProps}
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        helperText={formik.errors.title}
                        onBlur={formik.handleBlur}
                        error
                      />
                    ) : (
                      <TextField
                        type="text"
                        variant="filled"
                        label="Title"
                        name="title"
                        fullWidth
                        InputLabelProps={inputProps}
                        value={formik.values.title}
                        onChange={formik.handleChange}
                      />
                    )}
                </GridItem>

                <GridItem md={12}>
                    <p>Intro List</p>
                        <Editor
                          ref={mdEditor}
                          value={introList}
                          style={{
                            height: "500px"
                          }}
                          onChange={handleIntroListChange}
                          renderHTML={text => <ReactMarkdown source={text} />}
                        />
                </GridItem>

                <GridItem md={12}>
                  <p>Solution</p>
                        <Editor
                          ref={mdEditor}
                          value={solution}
                          style={{
                            height: "500px"
                          }}
                          onChange={handleSolutionChange}
                          renderHTML={text => <ReactMarkdown source={text} />}
                        />
                </GridItem>
                
                <GridItem md={12}>
                  {formik.errors.exercise_position && formik.touched.exercise_position ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Exercise Position"
                      name="exercise_position"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.exercise_position}
                      onChange={formik.handleChange}
                      helperText={formik.errors.exercise_position}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Exercise Position"
                      name="exercise_position"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.exercise_position}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>

                <GridItem md={12}>
                    <h6>Created Date : {formik.values.created}</h6>
                    <h6>Modified Date : {formik.values.modified}</h6>
                
                </GridItem>

        <GridItem xs={12} sm={6} md={3} lg={3}>
                 <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    size="large"
                    className={classes.buttStyle}
                    fullWidth
                    disableFocusRipple
                    type="submit"
                  >
                    Update
                  </Button>
              </GridItem>

      </Grid>
      </form>       
    </div>
  );
};

export default LearningExercisesUpdate;
