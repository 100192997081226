/* eslint-disable no-unused-vars */
/* eslint-disable no-control-regex */
const moment = require("moment");

export function getStringBetween(data, start, end) {
  const firstMatches = data.matchAll(start);
  const secondMatches = data.matchAll(end);

  let filteredContent = [];
  if (start === "%%beginpanel%%" && end === "%%endpanel%%") {
    let string = data.replaceAll("%%endpanel%%", "%%beginpanel%%");
    // string = string.replaceAll(endpanel, "");
    let solutionArray = string.split("%%beginpanel%%");
    filteredContent = solutionArray.filter(item => {
      if (item != "" || item != null) {
        return item;
      }
    });
  }
  const firstMatchIndex = [];
  const secondMatchIndex = [];
  const resultArr = [];

  if (filteredContent.length > 0) {
    for (const content of filteredContent) {
      resultArr.push(content);
    }
    return resultArr;
  } else {
    for (let match of firstMatches) {
      firstMatchIndex.push(match.index);
    }

    for (let match of secondMatches) {
      secondMatchIndex.push(match.index);
    }

    for (let i = 0; i < firstMatchIndex.length; i++) {
      // if (firstMatchIndex[i] + start.length > secondMatchIndex[i]) {
      //   resultArr.push(
      //     "##### error in parsing. beginpanel does not have a corresponsing endpanel."
      //   );
      // }
      resultArr.push(
        data.slice(firstMatchIndex[i] + start.length, secondMatchIndex[i])
      );
    }

    return resultArr;
  }
}

export function replaceToButton(
  panel,
  ipAddress,
  hostName,
  labStatus,
  start,
  end
) {
  const betweenStringArr = getStringBetween(panel, start, end);
  var buttonSet = new Set(betweenStringArr);

  // creating regex expression out of a string.
  const regexStart = new RegExp("%%beginbtn%%", "gi");
  const regexEnd = new RegExp("%%endbtn%%", "gi");
  // removing %%beginbtn%% and %%endbtn%% from input panel.
  panel = panel.replaceAll(regexStart, "");
  panel = panel.replaceAll(regexEnd, "");

  for (let each of buttonSet) {
    if (each) {
      // since input type is not able to render if \n\r is in ${url} so removing it.
      each = each.replaceAll(new RegExp("\n", "gi"), "");
      each = each.replaceAll(new RegExp("\r", "gi"), ""); // each = https://viptela-underlay1:443/

      panel = panel.replaceAll(
        each,
        `<input type="button" class="button" value="Launch" onclick="window.open('${each}')" />`
      );
    }
  }
  if (labStatus === "ready" || labStatus === "error") {
    for (let each of betweenStringArr) {
      if (each.includes(hostName?.split(/-(.+)/)[1])) {
        panel = panel.replace(hostName?.split(/-(.+)/)[1], ipAddress);
      }
    }
  }
  return panel;
}

// sorting title array based on exercise position.
export const sortedTitleArr = data => {
  if (data.length !== 0 && data[0].exercise_position) {
    return data.sort((a, b) => {
      if (parseInt(a.exercise_position) > parseInt(b.exercise_position)) {
        return 1;
      } else {
        return -1;
      }
    });
  } else {
    return data;
  }
};

// calculate time difference using moment.
export const msToStr = (start, end) => {
  let endTime = moment(end);
  let startTime = moment(start);
  let differenceInMs = endTime.diff(startTime);

  let tempTime = moment.duration(differenceInMs),
    timeObj = {
      years: tempTime.years(),
      months: tempTime.months(),
      days: tempTime.days(),
      hrs: tempTime.hours(),
      mins: tempTime.minutes(),
      secs: tempTime.seconds()
      // ms: tempTime.milliseconds()
    },
    timeArr = [];

  for (let k in timeObj) {
    if (Number(timeObj[k]) > 0 && k === "months") {
      timeArr.push(`${timeObj[k]} ${k}`);
    }
    if (
      (Number(timeObj[k]) > 0 && k !== "secs") ||
      (k === "days" || k === "hrs" || k === "mins")
    ) {
      timeArr.push(`${timeObj[k]} ${k}`);
    }
  }

  return timeArr.join(" ");
};
