import React from "react";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

// Local Components
import { Pie } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "20px",
  },
  title: {
    margin: "0 0 20px 0",
    textAlign: "center",
  },
  data: {
    marginTop: "20px",
    textAlign: "center",
  },
}));

const ClusterQuota = (props) => {
  const classes = useStyles();

  let chartData = {
    labels: ["Learning Labs", "Showcases", "Sandboxes", "Design Clusters"],
    datasets: [
      {
        label: "No. of clusters launched",
        data: [
          props.data["total_learning"],
          props.data["total_scripted"],
          props.data["total_poc"],
          props.data["total_designer"],
        ],
        backgroundColor: [
          "#80B1D3",
          // "#FDB462",
          // "#B3DE69",
          // "#FB8072",
          // "#BEBADA",
          // "#CCEBC5",
          // "#BC80BD",
          // "#FFED6F",
          // "#FCCDE5",
          // "#BEBADA",
        ],
        hoverOffset: 4,
      },
    ],
  };

  let options = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        label: false,
      },
    },
  };

  return (
    <Paper className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4" className={classes.title}>
            Cluster Details
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <Grid item xs={10} md={6}>
              {props.data["total_labs"] ? (
                // <Pie data={chartData} options={options} />
                <Bar data={chartData} options={options} />
              ) : (
                <Typography variant="body1" className={classes.title}>
                  No Data Available
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" className={classes.data}>
            Total Clusters: {props.data["total_labs"]}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ClusterQuota;
