import React, { useEffect,useState } from "react";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import './accounts.css'
import { useDispatch } from "react-redux";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import action from 'assets/img/action.png';
import Button from "@material-ui/core/Button";
import { FormControl } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { useHistory } from "react-router-dom";

// Styling
const useStyles = makeStyles((theme, styles) => ({
    root: {
        marginTop: "50px",
    },
    customTabRoot: {
        color: `#000`,
        "& .Mui-selected .MuiTab-wrapper": {
            color: `${theme.palette.secondary.main}`
        }
    },
    btnStyle: {
        background: `${theme.palette.buttonColor.main} !important`,
        height: "40px"
        // padding: "20px 150px"
    },
    switch: {
        background: "none !important"
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={4}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const AccountInventory = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [value, setValue] = React.useState(0);
    const profileData = JSON.parse(localStorage.getItem("profileData"));
    const account = profileData.account;
    let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
    let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
    let hasDomain = domain?.some((res) => userEmail.includes(res));
    const [isInternal, setIsInternal] = useState(hasDomain);

    useEffect(() => {
        dispatch(SET_NAVBAR_TITLE(isInternal?account+"  - Inventory": "Inventory"));
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleEdit = () => {
        history.push("/inventoryedit");
    }

    return (
        <div className={classes.root} id="sandbox-mt-0">
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example"
                    classes={{ root: classes.customTabRoot }}>
                    <Tab label="Learning Center" {...a11yProps(0)} />
                    <Tab label="Showcases" {...a11yProps(1)} />
                    <Tab label="Sandboxes" {...a11yProps(2)} />
                    <Tab label="Design Center" {...a11yProps(3)} />
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Grid container style={{ borderBottom: "1px solid lightgray" }}>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <strong>Name</strong>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <strong>Permission</strong>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <strong>Lab Hours</strong>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <strong>Consumed Hours</strong>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <strong>Available Hours</strong>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <strong>Allocated Hours</strong>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <strong>Action</strong>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>Lorom ipsom dolor sit amet, consec</span>
                        </div>
                    </Grid><Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <FormControlLabel
                                control={
                                    <Switch
                                        disableRipple
                                        value={""}
                                        name={"permissions"}
                                        color="secondary"
                                    />
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <span>4</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>19</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>981</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>30</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <img src={action} style={{ cursor: "pointer" }}
                                onClick={() => {
                                    handleEdit();
                                }}
                            ></img>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>Lorom ipsom dolor sit amet, consec</span>
                        </div>
                    </Grid><Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <FormControlLabel
                                control={
                                    <Switch
                                        disableRipple
                                        value={""}
                                        name={"permissions"}
                                        color="secondary"
                                    />
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <span>4</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>19</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>981</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>30</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <img src={action} style={{ cursor: "pointer" }}
                                onClick={() => {
                                    handleEdit();
                                }}
                            ></img>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>Lorom ipsom dolor sit amet, consec</span>
                        </div>
                    </Grid><Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <FormControlLabel
                                control={
                                    <Switch
                                        disableRipple
                                        value={""}
                                        name={"permissions"}
                                        color="secondary"
                                    />
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <span>4</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>19</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>981</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>30</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <img src={action} style={{ cursor: "pointer" }}
                                onClick={() => {
                                    handleEdit();
                                }}
                            ></img>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>Lorom ipsom dolor sit amet, consec</span>
                        </div>
                    </Grid><Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <FormControlLabel
                                control={
                                    <Switch
                                        disableRipple
                                        value={""}
                                        name={"permissions"}
                                        color="secondary"
                                    />
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <span>4</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>19</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>981</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>30</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <img src={action} style={{ cursor: "pointer" }}
                                onClick={() => {
                                    handleEdit();
                                }}
                            ></img>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>Lorom ipsom dolor sit amet, consec</span>
                        </div>
                    </Grid><Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <FormControlLabel
                                control={
                                    <Switch
                                        disableRipple
                                        value={""}
                                        name={"permissions"}
                                        color="secondary"
                                    />
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <span>4</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>19</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>981</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>30</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <img src={action} style={{ cursor: "pointer" }}
                                onClick={() => {
                                    handleEdit();
                                }}
                            ></img>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>Lorom ipsom dolor sit amet, consec</span>
                        </div>
                    </Grid><Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <FormControlLabel
                                control={
                                    <Switch
                                        disableRipple
                                        value={""}
                                        name={"permissions"}
                                        color="secondary"
                                    />
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <span>4</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>19</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>981</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>30</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <img src={action} style={{ cursor: "pointer" }}
                                onClick={() => {
                                    handleEdit();
                                }}
                            ></img>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>Lorom ipsom dolor sit amet, consec</span>
                        </div>
                    </Grid><Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <FormControlLabel
                                control={
                                    <Switch
                                        disableRipple
                                        value={""}
                                        name={"permissions"}
                                        color="secondary"
                                    />
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <span>4</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>19</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>981</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>30</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <img src={action} style={{ cursor: "pointer" }}
                                onClick={() => {
                                    handleEdit();
                                }}
                            ></img>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={3} md={3}></Grid>
                    <Grid item xs={12} sm={3} md={3} style={{ padding: "0px 10px 0px 10px" }}>
                        <FormControl
                            fullWidth
                            className={classes.customInputStyle}
                        >
                            <Button
                                // onClick={() => {
                                //     handleSubmit();
                                // }}
                                fullWidth
                                variant="contained"
                                color="secondary"
                                disableElevation
                                className={classes.btnStyle}
                                disableFocusRipple
                                type="submit"
                            >
                                Apply
                            </Button>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} style={{ padding: "0px 10px 0px 10px" }}>
                        <FormControl
                            fullWidth
                            className={classes.customInputStyle}
                        >
                            <Button
                                // onClick={() => {
                                //     handleSubmit();
                                // }}
                                fullWidth
                                variant="contained"
                                color="secondary"
                                disableElevation
                                className={classes.btnStyle}
                                disableFocusRipple
                                type="submit"
                            >
                                Back
                            </Button>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}></Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Grid container style={{ borderBottom: "1px solid lightgray" }}>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <strong>Name</strong>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <strong>Permission</strong>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <strong>Lab Hours</strong>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <strong>Consumed Hours</strong>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <strong>Available Hours</strong>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <strong>Allocated Hours</strong>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <strong>Action</strong>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>Lorom ipsom dolor sit amet, consec</span>
                        </div>
                    </Grid><Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <FormControlLabel
                                control={
                                    <Switch
                                        disableRipple
                                        value={""}
                                        name={"permissions"}
                                        color="secondary"
                                    />
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <span>4</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>19</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>981</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>30</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <img src={action} style={{ cursor: "pointer" }}
                                onClick={() => {
                                    handleEdit();
                                }}
                            ></img>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>Lorom ipsom dolor sit amet, consec</span>
                        </div>
                    </Grid><Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <FormControlLabel
                                control={
                                    <Switch
                                        disableRipple
                                        value={""}
                                        name={"permissions"}
                                        color="secondary"
                                    />
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <span>4</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>19</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>981</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>30</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <img src={action} style={{ cursor: "pointer" }}
                                onClick={() => {
                                    handleEdit();
                                }}
                            ></img>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>Lorom ipsom dolor sit amet, consec</span>
                        </div>
                    </Grid><Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <FormControlLabel
                                control={
                                    <Switch
                                        disableRipple
                                        value={""}
                                        name={"permissions"}
                                        color="secondary"
                                    />
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <span>4</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>19</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>981</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>30</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <img src={action} style={{ cursor: "pointer" }}
                                onClick={() => {
                                    handleEdit();
                                }}
                            ></img>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>Lorom ipsom dolor sit amet, consec</span>
                        </div>
                    </Grid><Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <FormControlLabel
                                control={
                                    <Switch
                                        disableRipple
                                        value={""}
                                        name={"permissions"}
                                        color="secondary"
                                    />
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <span>4</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>19</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>981</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>30</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <img src={action} style={{ cursor: "pointer" }}
                                onClick={() => {
                                    handleEdit();
                                }}
                            ></img>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>Lorom ipsom dolor sit amet, consec</span>
                        </div>
                    </Grid><Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <FormControlLabel
                                control={
                                    <Switch
                                        disableRipple
                                        value={""}
                                        name={"permissions"}
                                        color="secondary"
                                    />
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <span>4</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>19</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>981</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>30</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <img src={action} style={{ cursor: "pointer" }}
                                onClick={() => {
                                    handleEdit();
                                }}
                            ></img>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>Lorom ipsom dolor sit amet, consec</span>
                        </div>
                    </Grid><Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <FormControlLabel
                                control={
                                    <Switch
                                        disableRipple
                                        value={""}
                                        name={"permissions"}
                                        color="secondary"
                                    />
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <span>4</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>19</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>981</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>30</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <img src={action} style={{ cursor: "pointer" }}
                                onClick={() => {
                                    handleEdit();
                                }}
                            ></img>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>Lorom ipsom dolor sit amet, consec</span>
                        </div>
                    </Grid><Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <FormControlLabel
                                control={
                                    <Switch
                                        disableRipple
                                        value={""}
                                        name={"permissions"}
                                        color="secondary"
                                    />
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <span>4</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>19</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>981</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>30</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <img src={action} style={{ cursor: "pointer" }}
                                onClick={() => {
                                    handleEdit();
                                }}
                            ></img>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={3} md={3}></Grid>
                    <Grid item xs={12} sm={3} md={3} style={{ padding: "0px 10px 0px 10px" }}>
                        <FormControl
                            fullWidth
                            className={classes.customInputStyle}
                        >
                            <Button
                                // onClick={() => {
                                //     handleSubmit();
                                // }}
                                fullWidth
                                variant="contained"
                                color="secondary"
                                disableElevation
                                className={classes.btnStyle}
                                disableFocusRipple
                                type="submit"
                            >
                                Apply
                            </Button>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} style={{ padding: "0px 10px 0px 10px" }}>
                        <FormControl
                            fullWidth
                            className={classes.customInputStyle}
                        >
                            <Button
                                // onClick={() => {
                                //     handleSubmit();
                                // }}
                                fullWidth
                                variant="contained"
                                color="secondary"
                                disableElevation
                                className={classes.btnStyle}
                                disableFocusRipple
                                type="submit"
                            >
                                Back
                            </Button>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}></Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Grid container style={{ borderBottom: "1px solid lightgray" }}>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <strong>Name</strong>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <strong>Permission</strong>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <strong>Lab Hours</strong>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <strong>Consumed Hours</strong>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <strong>Available Hours</strong>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <strong>Allocated Hours</strong>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <strong>Action</strong>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>Lorom ipsom dolor sit amet, consec</span>
                        </div>
                    </Grid><Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <FormControlLabel
                                control={
                                    <Switch
                                        disableRipple
                                        value={""}
                                        name={"permissions"}
                                        color="secondary"
                                    />
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <span>4</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>19</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>981</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>30</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <img src={action} style={{ cursor: "pointer" }}
                                onClick={() => {
                                    handleEdit();
                                }}
                            ></img>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>Lorom ipsom dolor sit amet, consec</span>
                        </div>
                    </Grid><Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <FormControlLabel
                                control={
                                    <Switch
                                        disableRipple
                                        value={""}
                                        name={"permissions"}
                                        color="secondary"
                                    />
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <span>4</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>19</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>981</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>30</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <img src={action} style={{ cursor: "pointer" }}
                                onClick={() => {
                                    handleEdit();
                                }}
                            ></img>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>Lorom ipsom dolor sit amet, consec</span>
                        </div>
                    </Grid><Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <FormControlLabel
                                control={
                                    <Switch
                                        disableRipple
                                        value={""}
                                        name={"permissions"}
                                        color="secondary"
                                    />
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <span>4</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>19</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>981</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>30</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <img src={action} style={{ cursor: "pointer" }}
                                onClick={() => {
                                    handleEdit();
                                }}
                            ></img>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>Lorom ipsom dolor sit amet, consec</span>
                        </div>
                    </Grid><Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <FormControlLabel
                                control={
                                    <Switch
                                        disableRipple
                                        value={""}
                                        name={"permissions"}
                                        color="secondary"
                                    />
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <span>4</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>19</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>981</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>30</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <img src={action} style={{ cursor: "pointer" }}
                                onClick={() => {
                                    handleEdit();
                                }}
                            ></img>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>Lorom ipsom dolor sit amet, consec</span>
                        </div>
                    </Grid><Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <FormControlLabel
                                control={
                                    <Switch
                                        disableRipple
                                        value={""}
                                        name={"permissions"}
                                        color="secondary"
                                    />
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <span>4</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>19</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>981</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>30</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <img src={action} style={{ cursor: "pointer" }}
                                onClick={() => {
                                    handleEdit();
                                }}
                            ></img>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>Lorom ipsom dolor sit amet, consec</span>
                        </div>
                    </Grid><Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <FormControlLabel
                                control={
                                    <Switch
                                        disableRipple
                                        value={""}
                                        name={"permissions"}
                                        color="secondary"
                                    />
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <span>4</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>19</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>981</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>30</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <img src={action} style={{ cursor: "pointer" }}
                                onClick={() => {
                                    handleEdit();
                                }}
                            ></img>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>Lorom ipsom dolor sit amet, consec</span>
                        </div>
                    </Grid><Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <FormControlLabel
                                control={
                                    <Switch
                                        disableRipple
                                        value={""}
                                        name={"permissions"}
                                        color="secondary"
                                    />
                                }
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <span>4</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>19</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>981</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} className={""}>
                        <div className="inventory-card">
                            <span>30</span>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={1} md={1} className={""}>
                        <div className="inventory-card">
                            <img src={action} style={{ cursor: "pointer" }}
                                onClick={() => {
                                    handleEdit();
                                }}
                            ></img>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={3} md={3}></Grid>
                    <Grid item xs={12} sm={3} md={3} style={{ padding: "0px 10px 0px 10px" }}>
                        <FormControl
                            fullWidth
                            className={classes.customInputStyle}
                        >
                            <Button
                                // onClick={() => {
                                //     handleSubmit();
                                // }}
                                fullWidth
                                variant="contained"
                                color="secondary"
                                disableElevation
                                className={classes.btnStyle}
                                disableFocusRipple
                                type="submit"
                            >
                                Apply
                            </Button>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} style={{ padding: "0px 10px 0px 10px" }}>
                        <FormControl
                            fullWidth
                            className={classes.customInputStyle}
                        >
                            <Button
                                // onClick={() => {
                                //     handleSubmit();
                                // }}
                                fullWidth
                                variant="contained"
                                color="secondary"
                                disableElevation
                                className={classes.btnStyle}
                                disableFocusRipple
                                type="submit"
                            >
                                Back
                            </Button>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}></Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={3}>
            </TabPanel>
        </div>
    );
};

export default AccountInventory;