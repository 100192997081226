// This component creates a modal for Reserved IP with respective form fields, the
// modal is popped up during provision or resuming of a lab or poc.

import React, { Component, useState } from "react";
import { SECONDARY_COLOR } from "global_constants/app_constants";
import Button from "@material-ui/core/Button";
// Formik
import { useFormik } from "formik";
import Datetime from "react-datetime";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Api, Urls } from "config/api";
import Loader from "react-loader-spinner";
import { LOADER_TYPE } from "global_constants/app_constants";
import "views/ServiceOffering/LearningLab/acess labs/Overview.scss";
import NotificationAlert from "views/Components/NotificationAlert";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import theme from "theme";
const inputProps = {
  disableAnimation: true,
  shrink: false
};

const customStyles = theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    position: "absolute",
    width: 550,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    display: "block"
  },
  buttStyle: {
    background: theme.palette.buttonColor.main,
    "&:hover": {
      background: theme.palette.buttonColor.main
    }
  },
  title: {
    marginTop: "20px"
  }
});

const AttachUserModal = props => {
  const useStyles = makeStyles(customStyles);
  const classes = useStyles();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");

  const hideModal = () => {
    props.hideModal(true);
  };

  const formik = useFormik({
    initialValues: {
      userName: ""
    },
    onSubmit: values => {
      setShowNotification(false);
      setBtnDisabled(true);
      SDCloudBackendAPIWithToken()
        .put(Api.updateStaticIp, {
          user: values.userName,
          ip: props.ip,
          action: props.action
        })
        .then(res => {
          //   setShowNotification(true);
          //   setNotificationMessage(
          //     "Domain successfully added to trusted domains"
          //   );
          //   setNotificationType("success");
          props.renderData();
          setBtnDisabled(false);
          hideModal();
        })
        .catch(err => {
          let error = err.response.data;
          setShowNotification(true);
          setNotificationMessage(error.message);
          setNotificationType("error");
          setBtnDisabled(false);
        });
    },
    validate: values => {
      let errors = {};

      if (!values.userName) {
        errors.userName = "Required";
      }

      return errors;
    }
  });

  return (
    // Form modal for add hours to lab
    <Modal
      disableAutoFocus
      open={!props.openModal}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      className={classes.modal}
      onClose={() => hideModal()}
    >
      <GridContainer
        justify="center"
        alignItems="center"
        className={classes.paper}
      >
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginBottom: "20px" }}
        >
          <Typography variant="h4" className={classes.title}>
            Static IP
          </Typography>
          {/* <h3 className={classes.title}>Reserve IP</h3> */}
        </GridItem>
        {/* <GridContainer justify="flex-end"> */}
        {/* <GridItem xs={12} sm={1} md={1} lg={1}>
            {" "}
            <CloseIcon
              className={classes.modalCloseIcon}
              onClick={() => hideModal()}
            />
          </GridItem>{" "} */}
        {/* </GridContainer> */}
        <form onSubmit={formik.handleSubmit}>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            {formik.errors.userName && formik.touched.userName ? (
              <TextField
                type="text"
                variant="filled"
                label="User"
                name="userName"
                fullWidth
                InputLabelProps={inputProps}
                value={formik.values.userName}
                onChange={formik.handleChange}
                helperText={formik.errors.userName}
                onBlur={formik.handleBlur}
                error
              />
            ) : (
              <TextField
                type="text"
                variant="filled"
                label="User"
                name="userName"
                fullWidth
                InputLabelProps={inputProps}
                value={formik.values.userName}
                onChange={formik.handleChange}
              />
            )}
            <GridContainer style={{ marginTop: "20px" }}>
              <GridItem xs={12} sm={6} md={6} lg={6}>
                {!btnDisabled ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    size="large"
                    className={classes.buttStyle}
                    fullWidth
                    disableFocusRipple
                    type="submit"
                  >
                    Submit
                  </Button>
                ) : null}
                {btnDisabled ? (
                  <Loader
                    type={LOADER_TYPE}
                    color={theme.palette.secondary.main}
                    width={50}
                    height={50}
                    style={{ float: "right", marginRight: "15px" }}
                  />
                ) : null}
              </GridItem>
              <GridItem xs={12} sm={6} md={6} lg={6}>
                <Button
                  variant="outlined"
                  color="secondary"
                  disableElevation
                  size="large"
                  // className={classes.buttStyle}
                  fullWidth
                  onClick={hideModal}
                  disableFocusRipple
                  type="submit"
                >
                  Cancel
                </Button>
              </GridItem>
            </GridContainer>

            <NotificationAlert
              notificationType={notificationType}
              showNotification={showNotification}
              message={notificationMessage}
            />
          </GridItem>
        </form>
      </GridContainer>
    </Modal>
  );
};

export default AttachUserModal;
