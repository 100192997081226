import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { BrowserRouter } from "react-router-dom";

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import App from "App";
// importing redux
import { Provider } from "react-redux";
import store from "store/store";
import ErrorBoundary from "components/Error Boundary/ErrorBoundary";

// Global Level Theme

import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";

// carousel styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const hist = createBrowserHistory();
// const mem = createMemoryHistory(Option);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter history={hist}>
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </ErrorBoundary>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
