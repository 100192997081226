import React from "react";
import clsx from "clsx";

// Material UI Components
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    margin: "20px 0"
  },
  success: {
    border: `1px solid ${theme.palette.success.main}`,
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.main
  },
  error: {
    border: `1px solid ${theme.palette.error.main}`,
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.main
  },
  info: {
    border: `1px solid ${theme.palette.info.border}`,
    backgroundColor: theme.palette.info.light,
    color: theme.palette.error.mainText
  },
  message: {
    padding: "10px",
    margin: 0
  }
}));

function NotificationAlert(props) {
  const classes = useStyles();

  return props.showNotification ? (
    <div
      className={clsx(
        classes.root,
        classes[props.notificationType],
        props.className
      )}
    >
      <p className={classes.message}>{props.message}</p>
    </div>
  ) : null;
}

export default NotificationAlert;
