import React, { Component } from "react";
import PropTypes from "prop-types";
import { Animated } from "react-animated-css";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import FormLabel from "@material-ui/core/FormLabel";
import Close from "@material-ui/icons/Close";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "components/CustomInput/CustomInput.js";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import formClasses from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import { SDCloudBackendAPIWithToken, SDCloudCustomizeAPIWithToken} from "apis/backendAPI";
import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
import queryString from "query-string";
import { useDispatch } from "react-redux";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import {
  getServerDetails,
  NODE_URLS,
  ENV_NAME
} from "views/ServiceLifeCycle/utils/constants/urls";

import "./NodeImageUploader.scss";

class NodeImageUploader extends Component {
  constructor(props) {
    super(props);
    this.uploadObj = React.createRef();
    this.nodename_dropdown = React.createRef();
    let queryParams = queryString.parse(window.location.search);
    
    var node_id = this.props.node_name;
    var mode = queryParams.mode;
    if(node_id != null){
      node_id = node_id.replaceAll(' ', '');
      node_id = node_id.replaceAll('_', '')
    }
    var version = queryParams.version;
    var allowDelete = this.props.allowDelete;

    console.log(allowDelete);
    this.state = {
      //for version input field
      version_adornment: "",
      allowDelete : allowDelete,
      version_value: version,
      mode: mode,
      isInputDisabled: false,
      isVersionValid: false,
      image_name:"",

      isUploaderVisible: !props.showAddVersionField,

      // sync fusion
      server_details: [],
      node_id:node_id,
      has_server_details: true,
      chunkSize: 50000000 // 50 MB
    };
  }
  componentWillReceiveProps(newProps) {
    this.setState({allowDelete: newProps.allowDelete});
  }

  // function to verify version is in correct format
  verifyVersion = value => {
    const regex = "^([0-9a-zA-Z]\\.?){0,}[0-9a-zA-Z]{1,}$";
    const found = value.match(regex);
    if (found) {
      this.setState({
        isVersionValid: true,
        isUploaderVisible: true
      });
      return true;
    } else {
      this.setState({
        isVersionValid: false,
        isUploaderVisible: false
      });
      return false;
    }
  };

  renderVersionInput = () => {
    return (
      <GridContainer justify="center" alignItems="center" direction="row">
        <GridItem xs={12} sm={4}>
          <GridContainer justify="center" alignItems="center" direction="row">
            <FormLabel
              style={{
                ...formClasses.labelHorizontal,
                ...InlineStyles.labelText
              }}
            >
              Add Version :
            </FormLabel>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <GridContainer justify="center" alignItems="center" direction="row">
            <CustomInput
              success={
                this.state.version_adornment === "success" ? true : false
              }
              error={this.state.version_adornment === "error" ? true : false}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                onChange: event => {
                  let version_adornment = null;
                  if (this.verifyVersion(event.target.value)) {
                    version_adornment = "success";
                  } else {
                    version_adornment = "error";
                  }
                  this.setState({
                    version_adornment,
                    version_value: event.target.value
                  });
                },
                type: "text",
                required: true,
                value: this.state.version_value,
                disabled: this.state.isInputDisabled,
                placeholder: "Enter only numerical value. eg:   11.04",
                endAdornment:
                  this.state.version_adornment === "error" ? (
                    <InputAdornment position="end">
                      <Close style={{ color: "red" }} />
                    </InputAdornment>
                  ) : (
                    undefined
                  )
              }}
            />
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={2}></GridItem>
      </GridContainer>
    );
  };

  renderNameAndVersion = () => {
    return (
      <>
        <Animated
          animationIn="fadeIn"
          animationOut="fadeOut"
          animationInDuration={1000}
          animationOutDuration={1000}
          isVisible={this.state.isNodeNameSelected}
        >
          {this.renderVersionInput()}
        </Animated>
      </>
    );
  };

  uploadAnotherFile = () => {
    this.setState({
      server_details: [],
      has_server_details: true
    });
  };

  handleOnSubmit = () => {
    // console.log("handled onsubmit");
    this.setState({ showButton: false, showProcessing: true });
    this.submitFile();
  };

  onUploadSuccess = response => {
    // console.log("on success", response.e.currentTarget.response);
    this.props.getResponseFromFileUpload(response.e.currentTarget.response);
    this.setState({ isInputDisabled: false });
  };

  onUploadFailure = response => {
    let resp_message = JSON.parse(response.e.currentTarget.response).message;
    alert(resp_message)
    this.props.onUploadProgress(false); // this.props.onUploadProgress(isAnyFileSelectedOrUploading)
    // this.editStatusText();
    // console.log("on failure");

    this.setState({ isInputDisabled: false });
  };

  getclearButtonRef = () => {
    setTimeout(() => {
      this.uploadObj.clearButton.click(); // attaching ref to clear button only after it is loading.
    }, 300); // 0 second makes it to execute after entire page is loaded.
  };

  // checks if there are no files selected in uploader.
  checkIfAllFilesCleared = () => {
    this.props.onUploadProgress(false); // this.props.onUploadProgress(isAnyFileSelectedOrUploading)
    // console.log("checkifallfiles cleared");
  };

  // attach events to cross/cancel button in files list
  attachCancelButtonEventListeners = () => {
    setTimeout(() => {
      for (let i = 0; i < this.uploadObj.fileList.length; i++) {
        this.uploadObj.fileList[
          i
        ].childNodes[1].onclick = this.checkIfAllFilesCleared;
      }
    }, 300);
  };
  onFileListClearing = (args) => {
    this.props.onUploadProgress(false);
    console.log(this.state.allowDelete);

    if(this.uploadObj !== undefined) {
      // Cancel the upload process
      this.uploadObj.cancel(this.uploadObj.getFilesData()[0]);
    }
    this.uploadMetaData=[]


    if(this.props.version_value !== undefined && this.props.node_name !== undefined) {
      let node_identity = this.props.node_name.replaceAll(' ', '').replaceAll('_', '');
      let data = {
        image_name: this.state.image_name,
        version: this.props.version_value,
        permanent_image_del: true,
        bname: NODE_URLS.BUCKET_NAME,
        node_identity: node_identity
      };

      // Call delete API to clear chunks from GCP
      SDCloudBackendAPIWithToken()
        .post('/lifecycle/delete_node_image_from_gcp', data)
        .then(res => {
          if (res.status === 200) {
            this.props.setImageUrl("");
            console.log("Image deleted successfully")
          }
        })
        .catch(err => {
          console.log("Unable to delete the image.")
        });
    } else {
      alert("Please enter Name and Version before clearing the image");
      this.getclearButtonRef();
      return false;
    }

    // Additional logic to calculate chunk size and handle file data
    let existingFiles = this.uploadObj.getFilesData();
    let fileWithSpace = false;
    const min_chunks = 3;
    const maxChunks = 31;
    const minChunkSize = 150 * 1024 * 1024;  // Minimum chunk size (150 MB)
    const idealChunkSize = Math.ceil(existingFiles[0].size / maxChunks);
    const actualChunks = Math.ceil(existingFiles[0].size / idealChunkSize);
    let chunk_size = Math.ceil(existingFiles[0].size / Math.min(actualChunks, maxChunks));

    // Ensure minimum chunk size
    if (chunk_size < minChunkSize) {
      chunk_size = minChunkSize;
    }

    // Calculate the number of chunks
    const numChunks = Math.ceil(existingFiles[0].size / chunk_size);
    if (minChunkSize < existingFiles[0].size) {
      chunk_size = Math.round(existingFiles[0].size / numChunks);
    } else {
      chunk_size = Math.round(existingFiles[0].size / min_chunks);
    }

    let file_name = existingFiles[0].name;
    this.setState({ image_name: file_name });

    existingFiles.map(existingFile => {
      if (existingFile.name.search(" ") !== -1) {
        alert("Please remove spaces from the file name.");
        this.getclearButtonRef();
        existingFiles.splice(0, 1);
        args.modifiedFilesData = existingFiles; // Update the caller with modified files
        args.isModified = true;
        fileWithSpace = true;
        return false;
      }
      return {};
    });

    let isAnyFileSelectedOrUploading = !fileWithSpace;
    this.props.onUploadProgress(isAnyFileSelectedOrUploading); 
    this.setState({ chunkSize: chunk_size });
    this.attachCancelButtonEventListeners();
    this.setState({ isInputDisabled: false });
    // Reset the uploader after clearing the file
    this.resetUploader();
  };

  resetUploader = () => {
    if (this.uploadObj && this.uploadObj.current) {
      this.uploadObj.current.clearAll();
    }
  };

  onFileSelected = args => {
    if(this.props.version_value != undefined && this.props.node_name != undefined){
      var node_identity = this.props.node_name
      node_identity = node_identity.replaceAll(' ', '')
      node_identity = node_identity.replaceAll('_', '')
      let data = {
        image_name: this.state.image_name,
        version: this.props.version_value,
        permanent_image_del: true,
        bname: NODE_URLS.BUCKET_NAME,
        node_identity: node_identity
      };
      SDCloudBackendAPIWithToken()
      .post('/lifecycle/delete_node_image_from_gcp', data)
      .then(res => {
        if (res.status === 200) {
          this.props.setImageUrl("");
        }
      })
      .catch(err => {
        console.log("Unable to delete the image.")
      });
    }else{
      alert("Please enter Name and Version before uploading the image");
      this.getclearButtonRef();
      return false;
    }
    let existingFiles = this.uploadObj.getFilesData();
    let fileWithSpace = false;
    const min_chunks = 3;
    const maxChunks = 31;
    const minChunkSize = 150 * 1024 * 1024;  // Minimum chunk size (150 MB)
    const idealChunkSize = Math.ceil(args.filesData[0].size / maxChunks);
    const actualChunks = Math.ceil(args.filesData[0].size / idealChunkSize);
    let chunk_size = Math.ceil(args.filesData[0].size / Math.min(actualChunks, maxChunks));
    
    // Ensure minimum chunk size
    if (chunk_size < minChunkSize) {
      chunk_size = minChunkSize;
    }

    var chunkSize = undefined
    // Calculate the number of chunks
    const numChunks = Math.ceil(args.filesData[0].size / chunk_size);

    // if a chunk is less that file size then devide with 3 
    if(minChunkSize < args.filesData[0].size){
      chunkSize = Math.round(args.filesData[0].size / numChunks);
    }else{
      chunkSize = Math.round(args.filesData[0].size / min_chunks);
    }
    
    // const maxSize = 150 * 1024 * 1024; // Maximum chunk size (150 MB)
    // const minSize = 1024 * 1024; // Minimum chunk size (1 MB)
    // const ratio = Math.min(1, Math.sqrt(args.filesData[0].size / maxSize)); // Adjust ratio based on file size
    // let chunkSize =  Math.round(Math.max(minSize, maxSize * ratio));
    // //let chunkSize = Math.round(args.filesData[0].size / 15);
    let file_name = args.filesData[0].name
    this.setState({image_name: file_name})
    args.filesData.map(existingFile => {
      // Math.min(chunkSize, existingFile.size);
      if (existingFile.name.search(" ") !== -1) {
        alert("please remove spaces from file name.");
        this.getclearButtonRef();
        args.filesData.splice(0, 1);
        existingFiles = args.filesData;
        args.modifiedFilesData = existingFiles; // modifiedFilesData is a callback function which is used to update the caller(uploader.)
        args.isModified = true;
        fileWithSpace = true;
        return false;
      }
      return {};
    });
    let isAnyFileSelectedOrUploading = !fileWithSpace;
    this.props.onUploadProgress(isAnyFileSelectedOrUploading); // this.props.onUploadProgress(isAnyFileSelectedOrUploading)
    this.setState({ chunkSize });
    this.attachCancelButtonEventListeners();
  };

  componentDidMount() {
    // if (ENV_NAME === "staging") {
    //   getServerDetails().then(res => {
    //     this.setState({
    //       server_details: res.details
    //     });
    //   });
    // } else if (ENV_NAME === "stagingez") {
    //   this.setState({
    //     server_details: {
    //       server_url: window.REACT_APP_API_BASE_URL + "/",
    //       username: window.REACT_APP_USERNAME
    //     }
    //   });
      
    // }
    this.setState({
      server_details: {
        server_url: window.REACT_APP_API_BASE_URL + "/",
        username: window.REACT_APP_USERNAME
      }
    });
  }

  render() {
    var node_identity = this.props.node_name
    if(node_identity != null){
      node_identity = node_identity.replaceAll(' ','').replaceAll('_', '');
    }
    let path = {
      chunkSize: this.state.chunkSize, //bytes
      saveUrl:
        this.state.server_details.server_url +
        "lifecycle/upload_image/?username=" +
        localStorage.userName +
        "&bname=" +
        NODE_URLS.BUCKET_NAME +
        "&version="+this.props.version_value + "&id="+node_identity,

      // this.state.nodenameDropDownValue will give the name of node

      // set time delay for automatic retry when chunk upload failed
      retryAfterDelay: 3000,
      // set count for automatic retry when chunk upload failed
      retryCount: 5
    };
    return (
      <GridContainer>
        {/* <GridItem xs={12}>
          {this.props.showAddVersionField ? this.renderNameAndVersion() : null}
        </GridItem> */}
        {/* <GridItem xs={12}>
          <Animated
            animationIn="fadeIn"
            animationOut="fadeOut"
            animationInDuration={1000}
            animationOutDuration={1000}
            isVisible={this.state.version_adornment === "error"}
          >
            {this.state.version_adornment === "error" ? (
              <GridContainer justify="center" alignItems="center">
                <GridItem xs={12} sm={4}></GridItem>
                <GridItem xs={12} sm={6}>
                  <GridContainer justify="flex-start" alignItems="center">
                    <p style={{ color: "red" }}>
                      * enter a valid version to start uploading.{" "}
                    </p>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={2}></GridItem>
              </GridContainer>
            ) : null}
          </Animated>
        </GridItem> */}
        <GridItem xs={12}>
          <Animated
            animationIn="fadeIn"
            animationOut="fadeOut"
            animationInDuration={1000}
            animationOutDuration={2000}
            isVisible={this.state.isUploaderVisible}
          >
            {this.state.isUploaderVisible ? (
              <div className="chunkupload" id="uploader">
                <UploaderComponent
                  autoUpload={false}
                  allowedExtensions=".qcow2" // limit the file extension to qcow2 in production.
                  multiple={this.props.allowMultiple}
                  asyncSettings={path}
                  maxFileSize={30000000000} // in bytes (30 GB)
                  success={this.onUploadSuccess}
                  failure={this.onUploadFailure}
                  selected={this.onFileSelected}
                  clearing={this.onFileListClearing}
                  uploading={() => this.setState({ isInputDisabled: true })}
                  ref={upload => {
                    this.uploadObj = upload;
                  }}
                  progress={this.progress}
                />
              </div>
            ) : null}
          </Animated>
        </GridItem>
      </GridContainer>
    );
  }
}
const InlineStyles = {
  labelText: {
    color: "black",
    paddingTop: "0px",
    marginRight: "5%"
  }
};

NodeImageUploader.propTypes = {
  showAddVersionField: PropTypes.bool.isRequired,
  getResponseFromFileUpload: PropTypes.func.isRequired,
  onUploadProgress: PropTypes.func.isRequired,
  version_value: PropTypes.string,
  allowMultiple: PropTypes.bool
};

export default NodeImageUploader;

