import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { getStringBetween, msToStr,replaceToButton} from "views/ServiceOffering/ExperienceCenter/Sandboxes/AccessSandbox/sdntokenizerhelper";
import { Animated } from "react-animated-css";
// Material UI Components
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import copyIcon from "assets/criterion_networks/svg/copy.svg";
import Icon from "@material-ui/core/Icon";
import * as clipboard from "clipboard-polyfill/text";
import { SECONDARY_COLOR } from "global_constants/app_constants";
import { useDispatch } from "react-redux";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
import { blackColor } from "assets/jss/material-dashboard-pro-react";


const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 20px",
    backgroundColor: "#ffffff",
  },
  appBar: {
    backgroundColor: "#ffffff !important",
    color: theme.palette.grey["600"],
    boxShadow: "none",
  },
  tabPanel: {
    backgroundColor: "#ffffff",
    "& div": {
      width: "100%",
      margin: "0",
      paddingTop: "15px",
    },
  },
  customTabRoot: {
    color: `#000`,
    display: "flex",
    flexWrap: "wrap",
    overflow: "hidden",
    "& .MuiTab-root": {
      width: "auto", 
      margin: theme.spacing(1), 
    },
    borderBottom: `1px solid ${theme.palette.grey["400"]}`,
    "& .Mui-selected .MuiTab-wrapper": {
      color: `${theme.palette.secondary.main}`
    },
    "& .MuiTab-wrapper": {
      fontFamily: theme.palette.fontList.selectedFont
    }
  },
  customTab: {
    minWidth: "unset", 
    flex: "1 1 auto", 
  },
  cardStyle: {
    paddingLeft: "25px",
    paddingRight: "25px",
    fontFamily: "Roboto",
    color: "black",
    fontWeight:"normal",
    "& h2": {
      fontWeight: "bold"
    },
    "& img": {
      maxWidth: "100%",
      height: "auto", 
    },
  },
  buttonStyle1: {
    backgroundColor: theme.palette.secondary.main,
    float:"left",
    color: "#fff",
    marginTop: "20px",
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  buttonStyle2: {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    float:"right",
    marginTop: "20px",
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
  },
  imageIcon: {
    display: "flex",
    height: "inherit",
    width: "inherit",
    alignSelf: "middle",
    verticalAlign: "super"
  },
  iconRoot: {
    textAlign: "center",
    "& svg": {
      fill: "grey"
    },
    "& svg:hover": {
      fill: "#F96B13"
    }
  },
  buttStyle: {
    background: theme.palette.buttonColor.main,
    "&:hover": {
      background: theme.palette.buttonColor.main
    }
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}


function Cheatsheets(props) {
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const dispatch = useDispatch();
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  const renderers = {
    code: ({ language, value }) => {
      return (
        <pre
          style={{
            cursor: "auto",
            whiteSpace: "break-spaces"
          }}
        >
          <p
            style={{
              width: "100%",
              padding: "15px",
              backgroundColor: SECONDARY_COLOR,
              cursor: "auto"
            }}
          >
            <code
              style={{
                color: "white",
                width: "100%",
                height: "auto",
                padding: "0px",
                backgroundColor: SECONDARY_COLOR
              }}
              language={language}
              children={value}
            />
            <span
              style={{ color: "white", float: "right", cursor: "pointer" }}
              onClick={() => {
                clipboard.writeText(value);
                const payload = {
                  message: "Code copied successfully.",
                  color: "success",
                  delay: 1000
                };
                dispatch(SHOW_NOTIFICATION({ payload }));
              }}
            >
              <Icon classes={{ root: classes.iconRoot }}>
                <img className={classes.imageIcon} alt="logo" src={copyIcon} />
              </Icon>
            </span>
          </p>
        </pre>
      );
    }
  }
  const renderPanels = (inputString,ip, host,clusterStatus, renderers) => {
    if (inputString) {
      const panels = getStringBetween(inputString, "%%beginpanel%%", "%%endpanel%%");
      if (panels.length !== 0) {
        return panels.map((panel, index) => {
          panel = replaceToButton(
            panel,
            ip,
            host,
            clusterStatus,
            "%%beginbtn%%",
            "%%endbtn%%"
          );
          var launch = true;
          if (panel.includes('class="button" value="Launch"')) {
            launch = false;
          }
          return (
            <Animated
              key={index} // Ensure each element has a unique key when rendering a list of elements
              animationIn="fadeIn"
              animationOut="fadeOut"
              animationInDuration={2000}
              isVisible={true}
            >
              <ReactMarkdown
                className="panel"
                source={panel}
                escapeHtml={false}
                renderers={renderers}
              />
              {panel?.replace("\r", "")?.replace("\n", "")?.length ? <hr /> : null}
            </Animated>
          );
        });
      }
    }
    return null; 
  };
  useEffect(() => {
    setValue(props.cheatsheetIndex);
  }, [props.cheatsheetIndexIndex]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePrev = () => {
    if (value === 0) {
      if (props.categoriesData && props.categoriesData[0].length > 0) {
      const lastCategoryIndex = props.categoriesData[0].length - 1;
      const lastCategoryName = props.categoriesData[0][lastCategoryIndex].sandboxes_category_name;
      const exercisesForSelectedCategory = props.exercisesData[0].filter(
        (exercise) => exercise.admin_sandboxes_categories_id === props.categoriesData[0][lastCategoryIndex].id
      );
      const sortedExercisesData = exercisesForSelectedCategory.slice().sort((a, b) => a.exercise_position - b.exercise_position); 
      props.changePrevActiveTab(lastCategoryName,sortedExercisesData.length-1,lastCategoryIndex);
      } else {
        props.changeActiveTab("Overview")
      }
      // setValue(Cheatsheetsdata.length - 1);
    } else {
      setValue((prevValue) => Math.max(prevValue - 1, 0));
    }
  };

  const handleNext = () => {
    if (value === Cheatsheetsdata.length - 1) {
      setValue(0);
      props.changeActiveTab("Access devices");
    } else {
      setValue((prevValue) => Math.min(prevValue + 1, Cheatsheetsdata.length - 1));
    }
  };

  const host = props.clusterDetails.uuid + "-custom-underlay1"
  const ip = props.clusterDetails.ipdetails[host]
  const clusterStatus = props.clusterDetails.status
  const Cheatsheetsdata = props.data;
  if (Cheatsheetsdata.length === 0) {
    return <div style={{ textAlign: 'center', marginTop: '50px' }}><b>No cheatsheets available for this sandbox.</b></div>;
  }
  const sortedCheatsheets = Cheatsheetsdata.slice().sort((a, b) => a.cheatsheet_position - b.cheatsheet_position);
  const renderCheatsheetContent = (intro_list, details) => {
    const combinedContent = `${intro_list || ''}${details || ''}`;
    const introListPanels = renderPanels(intro_list, ip, host, clusterStatus, renderers);
    const solutionPanels = renderPanels(details, ip, host, clusterStatus, renderers);

  
    return (
      <>
        {introListPanels}
        {intro_list && !introListPanels && ( 
          <div className={classes.cardStyle}>
            <ReactMarkdown source={intro_list} skipHtml={false} renderers={renderers} />
          </div>
        )}
        {solutionPanels}
        {details && !solutionPanels && ( 
          <div className={classes.cardStyle}>
            <ReactMarkdown source={details} skipHtml={false} renderers={renderers} />
          </div>
        )}
      </>
    );
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          classes={{
            root: classes.customTabRoot
          }}
        >
          {sortedCheatsheets.map((sheet, index) => (
              <Tab
                key={index}
                label={sheet.title}
                {...a11yProps(index)}
                className={sortedCheatsheets.length > 6 ? classes.customTab : ''}
              />
            ))}
        </Tabs>
      </AppBar>
      <div>
        <div className={classes.tabContainer}>
          {sortedCheatsheets.map((sheet, index) => (
            <TabPanel
              key={index}
              value={value}
              index={index}
              className={classes.tabPanel}
            >
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{ flexDirection: "row", flexWrap: "wrap" }}
              >
                {renderCheatsheetContent(sheet.intro_list, sheet.details)}
              </Grid>
            </TabPanel>
          ))}
          </div>
          <Button
            onClick={handlePrev}
            className={classes.buttonStyle1}
          >
            Prev
          </Button>
          <Button
            onClick={handleNext}
            className={classes.buttonStyle2}
          >
            Next
          </Button>
      </div>
    </div>
  );
}

export default Cheatsheets;