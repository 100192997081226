// This component creates a modal for Reserved IP with respective form fields, the
// modal is popped up during provision or resuming of a lab or poc.

import React, { Component, useState } from "react";
import { SECONDARY_COLOR } from "global_constants/app_constants";
import Button from "@material-ui/core/Button";
import Datetime from "react-datetime";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Api, Urls } from "config/api";
import moment from "moment-timezone";
import Loader from "react-loader-spinner";
import { LOADER_TYPE } from "global_constants/app_constants";
import "views/ServiceOffering/LearningLab/acess labs/Overview.scss";
import NotificationAlert from "views/Components/NotificationAlert";
import { SDCloudBackendAPIWithoutToken } from "apis/backendAPI";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";

const inputProps = {
  disableAnimation: true,
  shrink: false
};

const customStyles = theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3)
  },
  modalCloseIcon: {
    position: "absolute",
    marginTop: "-155px",
    marginLeft: "45px",
    cursor: "pointer",
    color: "#FFFFFF",
    fontSize: "2rem"
  },
  datetime: {
    color: SECONDARY_COLOR
  },
  buttStyle: {
    background: theme.palette.buttonColor.main,
    "&:hover": {
      background: theme.palette.buttonColor.main,
    }
  },
  buttOutlined: {
    color: theme.palette.buttonColor.main,
    borderColor: theme.palette.buttonColor.main,
    "&:hover": {
      border: `1px solid ${theme.palette.buttonColor.main}`,
    }
  }
});

const DeleteDomainModal = props => {
  const useStyles = makeStyles(customStyles);
  const classes = useStyles();
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");

  const hideModal = () => {
    props.hideModal();
  };

  const deleteDomain = () => {
    // const data = {
    //   uuid: addHoursData.uuid,
    //   hours: hours,
    //   template_name: addHoursData.labName
    // };

    SDCloudBackendAPIWithToken()
      .post(Api.deleteTrustedDomain, {
        trusted_domains: props.domainName
      })
      .then(res => {
        //console.log(`data: ${res.message}`);
        setShowNotification(true);
        setNotificationMessage("Domain successfully deleted");
        setNotificationType("success");
        props.fetchTrustedDomains();
        hideModal();
      })
      .catch(err => {
        let error = err.response.data;
        setShowNotification(true);
        setNotificationMessage(error.message);
        setNotificationType("error");
      });
  };

  return (
    // Form modal for add hours to lab
    <Modal
      disableAutoFocus
      open={!props.openModal}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      className={classes.modal}
      onClose={() => hideModal()}
    >
      <GridContainer
        justify="center"
        alignItems="center"
        className={classes.paper}
      >
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginBottom: "20px" }}
        >
          <h3>Delete trusted domain</h3>
          <p>Are you sure you want to delete this trusted domain ?</p>
        </GridItem>
        <GridContainer justify="flex-end">
          <GridItem xs={12} sm={1} md={1} lg={1}>
            {" "}
            <CloseIcon
              className={classes.modalCloseIcon}
              onClick={() => hideModal()}
            />
          </GridItem>{" "}
        </GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            size="large"
            fullWidth
            className={classes.buttStyle}
            onClick={deleteDomain}
            disableFocusRipple
            type="submit"
          >
            Yes, delete
          </Button>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <Button
            variant="outlined"
            color="secondary"
            disableElevation
            size="large"
            className={classes.buttOutlined}
            fullWidth
            onClick={() => hideModal()}
            disableFocusRipple
            type="submit"
          >
            No, cancel
          </Button>
        </GridItem>
        <NotificationAlert
          notificationType={notificationType}
          showNotification={showNotification}
          message={notificationMessage}
        />
      </GridContainer>
      {/* {showNotification: <NotificationAlert
        notificationType={notificationType}
        showNotification={showNotification}
        message={notificationMessage}
      /> : null} */}
    </Modal>
  );
};

export default DeleteDomainModal;