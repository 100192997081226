import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Tooltip,
  createMuiTheme,
  ThemeProvider,
} from "@material-ui/core";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@material-ui/core";
import "./accounts.css";
import { SDCloudBackendAPIWithToken } from "../../apis/backendAPI";
import VisibilityIcon from "@material-ui/icons/Visibility";

const theme = createMuiTheme();

const useStyles = makeStyles((theme) => ({
  // styles...
  alertStyle: {
    borderColor: theme.palette.buttonColor.main,
    color: theme.palette.buttonColor.main,
    "&:hover": {
      borderColor: theme.palette.buttonColor.main,
    },
  },
  runScriptButton: {
    backgroundColor: "#F96B13",
    color: "##F96B13",
    "&:hover": {
      backgroundColor: "#F96B13",
    },
  },
  Typography: {
    margin: theme.spacing(2),
  },
  boldText: {
    fontWeight: "bold",
    fontSize: "1.2rem",
    color: "#000000",
  },

  iconButton: {
    backgroundColor: (props) =>
      props.disabled ? "#F96B13" : "transparent",
    color: (props) => (props.disabled ? "#FFFFFF" : "#F96B13"), //
    opacity: (props) => (props.disabled ? 0.5 : 1),
    transition: "opacity 0.3s ease",
    "&:hover": {
      opacity: (props) => (props.disabled ? 0.5 : 0.8),
    },
  },
}));

function Automationscripts() {
  const [open, setOpen] = useState(false);
  const [openCredentials, setOpenCredentials] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [output, setOutput] = useState("");
  const [openOutputDialog, setOpenOutputDialog] = useState(false);
  const [scriptExecutionStarted, setScriptExecutionStarted] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);

  const classes = useStyles({ disabled: !output });

  const handleScriptRun = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleContinue = () => {
    setOpen(false);
    setOpenCredentials(true);
  };

  const handleCloseCredentials = () => {
    setOpenCredentials(false);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConnect = () => {
    setOpenCredentials(false);
    setScriptExecutionStarted(true);
    SDCloudBackendAPIWithToken()
      .post("/te_cleanup/", {
        username: username,
        password: password,
      })
      .then((response) => {
        //console.log(response.data);
        const aid = Object.keys(response.data)[0];
        const outputValue = JSON.stringify(response.data);
        //console.log("outputValue", outputValue);
        setOutput(response.data);
        setScriptExecutionStarted(true);
        setLoginFailed(false);
        setOpenCredentials(false); // Close the login credentials dialog
      })
      .catch((error) => {
        console.error(error);
        setLoginFailed(true);
        setOpenCredentials(false); // Close the login credentials dialog
      });
  };

  return (
    <div
      className="home-card"
      style={{
        padding: "20px 20px 10px 50px",
        marginTop: "20px",
        marginRight: "250px",
        minHeight: "100px",
      }}
    >
      <Grid container alignItems="center" justify="space-between" spacing={1}>
        <Grid item>
          <Typography variant="h6" className={classes.boldText}>
            ThousandEyes Cleanup Script
          </Typography>
        </Grid>
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Button
                variant="contained"
                color="secondary" // Changed color to orange
                onClick={handleScriptRun}
                className={classes.runScriptButton}
              >
                Run Script
              </Button>
            </Grid>
            <Grid item>
              <ThemeProvider theme={theme}>
                <Tooltip
                  title={output ? "View the output" : "Script is still executing"}
                >
                  <IconButton
                    onClick={() => setOpenOutputDialog(true)}
                    disabled={!output}
                    className={classes.iconButton}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              </ThemeProvider>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>WARNING</DialogTitle>
        <DialogContent>
          <p>Please verify if there are active workshops related to the script before running.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button
            onClick={handleContinue}
            style={{ color: "#FFFFFF", backgroundColor: "#F96B13" }}
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openCredentials} onClose={handleCloseCredentials}>
        <DialogTitle>Login Credentials</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Username"
            type="text"
            fullWidth
            value={username}
            onChange={handleUsernameChange}
          />
          <TextField
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            value={password}
            onChange={handlePasswordChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCredentials} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleConnect}
            style={{ color: "#FFFFFF", backgroundColor: "#F96B13" }}
          >
            Run
          </Button>
        </DialogActions>
      </Dialog>

      {loginFailed && (
        <Dialog open={loginFailed} onClose={() => setLoginFailed(false)}>
          <DialogTitle>Login Failed</DialogTitle>
          <DialogContent>
            <Typography variant="body2">
              Authentication failed. Please check your username and password and try again.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setLoginFailed(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {output && (
        <div>
          <Dialog open={openOutputDialog} maxWidth="lg" fullWidth>
            <DialogTitle>
              Script has been successfully executed on the accounts
            </DialogTitle>
            <DialogContent>
              {Object.values(output).map((data) => {
                return (
                  <>
                    <Typography variant="body2" className={classes.Typography}>
                      {data["Account Name"]}
                    </Typography>
                    <Typography variant="caption" className={classes.Typography}>
                      {data["Account id"]}
                    </Typography>
                  </>
                );
              })}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenOutputDialog(false)} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}

      {scriptExecutionStarted && !loginFailed && (
        <Typography variant="body2" className={classes.Typography}>
          Script execution has started. 
        </Typography>
      )}
    </div>
  );
}

export default Automationscripts;
