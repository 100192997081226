import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  Button,
  TextField
} from "@material-ui/core";

import GridItem from "components/Grid/GridItem";
import CustomModal from "../../../../components/CustomModal";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

const AddNodeModal = ({
  visible,
  onClose,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [nodeVersion, setNodeVersion] = useState("");
  const [nodeName, setNodeName] = useState("");

  const handleNameChange = e => {
    const { name, value } = e.target;
    setNodeName(value)
  };

  const handleVersionChange = e => {
    const { version, value } = e.target;
    setNodeVersion(value)
  };

  const onSubmit = () => {
    history.push("/create-element?mode=add"); 
  };

  return (
    <CustomModal
      title="Enter Node Details"
      visible={visible}
      onClose={onClose}
    >
      <GridItem className="content" xs={24} sm={24} md={24} lg={24}>
        <TextField
          type="text"
          variant="filled"
          name="nodeName"
          fullWidth
          label="Node Name"
          value={nodeName}
          onChange={handleNameChange}
        />
        <TextField
          type="text"
          variant="filled"
          name="nodeVersion"
          fullWidth
          label="Node Version"
          value={nodeVersion}
          onChange={handleVersionChange}
        />

           <Button
            variant="contained"
            color="secondary"
            disableElevation
            size="large"
            fullWidth
            onClick={onSubmit}
            disableFocusRipple
          >
            Add Node
          </Button>

      </GridItem>
    </CustomModal>
  );
};

export default withRouter(AddNodeModal);