import React from "react";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

// Local Components
import { Line } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: "50px 40px 20px 20px"
  },
  title: {
    margin: "0 0 20px 0"
  },
  data: {
    marginTop: "20px",
    textAlign: "center"
  },
  canvasContainer: {
    height: "37vh"
  }
}));

const ClusterUsage = props => {
  const classes = useStyles();
  let hoursData = props.data.week_statistic;
  let date_range = props.data.week_range_date;
  let learningData = {};
  let showcaseData = {};
  let designerData = {};
  let sandboxData = {};
  hoursData.map(item => {
    return Object.entries(item).map(([key, value]) => {
      learningData[key] = value.filter(item => item.type === "Learning");
      showcaseData[key] = value.filter(item => item.type === "Showcase");
      designerData[key] = value.filter(item => item.type === "Designer");
      sandboxData[key] = value.filter(item => item.type === "Sandbox");
    });
  });
  let learningWeekData = {};
  Object.keys(learningData).forEach((key, ind) => {
    let weekHours = 0;
    learningData[key].map(item => {
      weekHours += item.total_hours;
    });
    learningWeekData[key] = weekHours;
  }, {});
  let showcaseWeekData = {};
  Object.keys(showcaseData).forEach((key, ind) => {
    let weekHours = 0;
    showcaseData[key].map(item => {
      weekHours += item.total_hours;
    });
    showcaseWeekData[key] = weekHours;
  }, {});
  let designerWeekData = {};
  Object.keys(designerData).forEach((key, ind) => {
    let weekHours = 0;
    designerData[key].map(item => {
      weekHours += item.total_hours;
    });
    designerWeekData[key] = weekHours;
  }, {});
  let sandboxWeekData = {};
  Object.keys(sandboxData).forEach((key, ind) => {
    let weekHours = 0;
    sandboxData[key].map(item => {
      weekHours += item.total_hours;
    });
    sandboxWeekData[key] = weekHours;
  }, {});
  learningData = [
    learningWeekData["week1"],
    learningWeekData["week2"],
    learningWeekData["week3"],
    learningWeekData["week4"],
    learningWeekData["week5"],
    learningWeekData["week6"],
    learningWeekData["week7"],
    learningWeekData["week8"],
    learningWeekData["week9"],
    learningWeekData["week10"],
    learningWeekData["week11"],
    learningWeekData["week12"],
    learningWeekData["week13"]
  ];
  showcaseData = [
    showcaseWeekData["week1"],
    showcaseWeekData["week2"],
    showcaseWeekData["week3"],
    showcaseWeekData["week4"],
    showcaseWeekData["week5"],
    showcaseWeekData["week6"],
    showcaseWeekData["week7"],
    showcaseWeekData["week8"],
    showcaseWeekData["week9"],
    showcaseWeekData["week10"],
    showcaseWeekData["week11"],
    showcaseWeekData["week12"],
    showcaseWeekData["week13"]
  ];
  designerData = [
    designerWeekData["week1"],
    designerWeekData["week2"],
    designerWeekData["week3"],
    designerWeekData["week4"],
    designerWeekData["week5"],
    designerWeekData["week6"],
    designerWeekData["week7"],
    designerWeekData["week8"],
    designerWeekData["week9"],
    designerWeekData["week10"],
    designerWeekData["week11"],
    designerWeekData["week12"],
    designerWeekData["week13"]
  ];
  sandboxData = [
    sandboxWeekData["week1"],
    sandboxWeekData["week2"],
    sandboxWeekData["week3"],
    sandboxWeekData["week4"],
    sandboxWeekData["week5"],
    sandboxWeekData["week6"],
    sandboxWeekData["week7"],
    sandboxWeekData["week8"],
    sandboxWeekData["week9"],
    sandboxWeekData["week10"],
    sandboxWeekData["week11"],
    sandboxWeekData["week12"],
    sandboxWeekData["week13"]
  ];

  let chartData = {
    labels: [
      new Date(date_range[0].week1).toLocaleDateString(),
      new Date(date_range[1].week2).toLocaleDateString(),
      new Date(date_range[2].week3).toLocaleDateString(),
      new Date(date_range[3].week4).toLocaleDateString(),
      new Date(date_range[4].week5).toLocaleDateString(),
      new Date(date_range[5].week6).toLocaleDateString(),
      new Date(date_range[6].week7).toLocaleDateString(),
      new Date(date_range[7].week8).toLocaleDateString(),
      new Date(date_range[8].week9).toLocaleDateString(),
      new Date(date_range[9].week10).toLocaleDateString(),
      new Date(date_range[10].week11).toLocaleDateString(),
      new Date(date_range[11].week12).toLocaleDateString(),
      new Date(date_range[12].week13).toLocaleDateString(),
      ""
    ],
    datasets: [
      {
        label: "Design center",
        data: [...designerData],

        backgroundColor: [
          "#FFD32A"
          // "#FDB462",
          // "#B3DE69",
          // "#FB8072",
          // "#BEBADA",
          // "#CCEBC5",
          // "#BC80BD",
          // "#FFED6F",
          // "#FCCDE5",
          // "#BEBADA",
        ],
        borderColor: "#FFD32A",
        hoverOffset: 4
      },
      {
        label: "Showcases",
        data: [...showcaseData],

        backgroundColor: [
          "#4BCFFA"
          // "#FDB462",
          // "#B3DE69",
          // "#FB8072",
          // "#BEBADA",
          // "#CCEBC5",
          // "#BC80BD",
          // "#FFED6F",
          // "#FCCDE5",
          // "#BEBADA",
        ],
        borderColor: "#4BCFFA",
        hoverOffset: 4
      },
      {
        label: "Sandboxes",
        data: [...sandboxData],

        backgroundColor: [
          "#EF5777"
          // "#FDB462",
          // "#B3DE69",
          // "#FB8072",
          // "#BEBADA",
          // "#CCEBC5",
          // "#BC80BD",
          // "#FFED6F",
          // "#FCCDE5",
          // "#BEBADA",
        ],
        borderColor: "#EF5777",
        hoverOffset: 4
      },
      {
        label: "Learning",
        data: [...learningData],
        backgroundColor: [
          "#0BE881"
          // "#FDB462",
          // "#B3DE69",
          // "#FB8072",
          // "#BEBADA",
          // "#CCEBC5",
          // "#BC80BD",
          // "#FFED6F",
          // "#FCCDE5",
          // "#BEBADA",
        ],
        borderColor: "#0BE881",
        hoverOffset: 4
      }
    ],
    pointStyle: "circle"
  };

  const legendMargin = {
    id: "legendMargin",
    beforeInit(chart, legend, options) {
      //console.log(chart.legend.fit);
      const fitValue = chart.legend.fit;

      chart.legend.fit = function fit() {
        fitValue.bind(chart.legend)();
        return (this.height += 25);
      };
    }
  };

  let plugins = [legendMargin];

  let options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "end",
        labels: {
          font: {
            size: 11
          },
          // padding: 40,
          usePointStyle: true
        }
      }
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Last 3 months",
          color: "#000000",
          font: {
            size: 14,
            weight: "normal",
            lineHeight: 1.2
          },
          padding: { top: 10, left: 0, right: 5, bottom: 0 }
        },
        grid: {
          display: false // this will hide vertical lines
        }
      },
      y: {
        title: {
          display: true,
          text: "Hours",
          color: "#000000",
          font: {
            size: 14,
            weight: "normal",
            lineHeight: 1.2
          },
          padding: { top: 20, left: 0, right: 5, bottom: 10 }
        },
        grid: {
          display: true // this will hide vertical lines
        }
      }
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h4" className={classes.title}>
          Usage History
        </Typography>
      </Grid>
      <Paper className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item xs={12}>
                <article className={classes.canvasContainer}>
                  {props.data ? (
                    // <Pie data={chartData} options={options} />
                    <Bar
                      data={chartData}
                      options={options}
                      plugins={plugins}
                      className={classes.canvasContainer}
                    />
                  ) : (
                    <Typography variant="body1" className={classes.title}>
                      No Data Available
                    </Typography>
                  )}
                </article>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default ClusterUsage;
