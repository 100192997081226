import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

// core components
import brand from "assets/criterion_networks/img/brand-logo.png";
import styles from "assets/jss/material-dashboard-pro-react/components/authNavbarStyle.js";

const useStyles = makeStyles(styles);

const BrandImage = () => {
  const classes = useStyles();
  return (
    <div className={classes.brandImage}>
      <img
        src={brand}
        alt="brand-logo"
        style={{
          width: "164px"
        }}
      />
    </div>
  );
};
export default function AuthNavbar(props) {
  const classes = useStyles();
  const { color } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });

  return (
    <AppBar position="static" className={classes.appBar + appBarClasses}>
      <BrandImage />
      <Toolbar className={classes.container}></Toolbar>
    </AppBar>
  );
}

AuthNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  brandText: PropTypes.string
};
