import * as React from "react";
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(sidebarStyle);


function SvgComponent(props) {
  const classes = useStyles();
  return (
    <svg
      width={15}
      height={14}
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={classes.miniMizeICon}
    >
      <path
        d="M14.5 13L7.75 7l6.75-6M7.75 13L1 7l6.75-6"
        stroke="#ffffff"
        strokeOpacity={0.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgComponent;