import React, { Component } from "react";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { PRIMARY_COLOR } from "global_constants/app_constants";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { SHOW_LOADER, HIDE_LOADER } from "action_creators/actioncreator";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import UserInfo from "./MyInformation/UserInfo";
import { Urls } from "config/api";
import {
  SHOW_NOTIFICATION,
  SET_NAVBAR_TITLE,
} from "action_creators/actioncreator";

// React specific library imports
import { Api } from "config/api";
import { asyncSetState } from "utils/utilfunction";
import TrustedDomain from "./TrustedDomains/TrustedDomain";
import LogWidget from "./ActivityLog/components/LogWidget";
// import SingleSignOn from "./SingleSignOn";
// import MultiFactorAuthentication from "./MultiFactorAuth";
import UpdateSecurityQuestion from "./UpdateSecurityQues";
import PlatformNotification from "./SubscribeToNotification";
import UpdatePassword from "./PasswordReset";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import theme from "../../theme";
// Custom notes information object in the current Profile component
const notes = {
  formNote: "You cannot change Email Address and Invite Code",
  passwordNote: `To update password please re-authenticate by clicking "Update Password" below`,
};

const useStyles = makeStyles({
  profileTabStyles: {
    bottom: 0,
    fontSize: "14px",
    marginLeft: "15px",
    paddingLeft: "15px",
    paddingRight: "15px",
    cursor: "pointer",
    marginBottom: "0px",
    paddingBottom: "10px",
    fontWeight: 500,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
    fontFamily: theme.palette.fontList.selectedFont,
  },
  tabContainerStyles: {},
});

const selectedProfileTabStyle = {
  color: theme.palette.secondary.main,
};

class MyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {},
      inviteCode: "",
      currentProfileTab: "",
      profilePermissions: [
        "My information",
        "Security question",
        "Password reset",
        "Activity logs",
        // "Email notifications"
        // "Multi-factor authentication"
      ], // Default permissions
      profileInfo: {},
      accountInfo: {},
    };
  }

  // api call to get profile details/permissions of a user
  getProfileDetails = async () => {
    return SDCloudBackendAPIWithToken()
      .get(Api.getUserProfile)
      .then((res) => {
        return asyncSetState(
          (prevState) => ({
            ...prevState.userInfo,
            userInfo: { ...res.data },
          }),
          this
        );
      })
      .catch((err) => {
        const payload = {
          message: err?.response?.data?.message || "unknown error occured",
          color: "danger",
          error: err,
          showErrorPage: true,
        };
        this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  createProfileTabs = () => {
    // Create profile tabs based on the profilePermissions

    let defaultTab = this.state.profilePermissions[0];
    // Append user specific permissions used for the corresponding tabs
    // let permissions = this.props.profile.profileObj.user_data.permissions;
    let permissions = [this.props.profile.profileObj.privilege];
    this.setState((prevState) => ({
      profilePermissions: [...prevState.profilePermissions],
      // profilePermissions: [...prevState.profilePermissions, ...permissions],
      currentProfileTab: defaultTab,
    }));
  };

  handleHeaderClick = (selectedProfileTab) => {
    this.props.history.replace({
      pathname: Urls.profile,
      state: { profileAction: null },
    });
    this.setState({
      currentProfileTab: selectedProfileTab,
    });
  };

  handleUserInfo = (userData) => {
    this.setState((prevState) => ({
      userInfo: {
        ...prevState.userInfo,
        ...userData,
      },
    }));
  };

  sidebarHeader = () => {
    // Create tab elements using profilePermissions array
    const classes = useStyles();
    return this.state.profilePermissions.map((item) => {
      return (
        <GridContainer alignItems="center" justify="flex-start" key={uuidv4()}>
          <h4
            className={classes.profileTabStyles}
            onClick={() => this.handleHeaderClick(item)}
            style={
              this.state.currentProfileTab.toLowerCase() === item.toLowerCase()
                ? selectedProfileTabStyle
                : {}
            }
          >
            {item}
          </h4>
        </GridContainer>
      );
    });
  };

  // reads common user profile info and update respective states
  getProfile = () => {
    this.setState(
      (prevState) => ({
        ...prevState.userInfo,
        ...prevState.accountInfo,
        userInfo: { ...this.props.profile.profileObj },
      }),
      this.createProfileTabs()
    );
  };

  // getSsoDetails = () => {
  //   SDCloudBackendAPIWithToken()
  //     .get(Api.getSsoDetails)
  //     .then(res => {
  //       this.setState(prevState => ({
  //         profilePermissions: [
  //           ...prevState.profilePermissions,
  //           "Single sign-on"
  //         ]
  //       }));
  //       // this.setState({ ...res.data.sso_details, isLoaded: true });
  //     })
  //     .catch(err => {
  //       const payload = {
  //         message: err?.response?.data?.message || "unknown error occured",
  //         color: "danger",
  //         error: err,
  //         showErrorPage: true
  //       };
  //       // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
  //       // this.setState({ isLoaded: true });
  //     });
  // };

  componentDidMount = async () => {
    const profileData = JSON.parse(localStorage.getItem("profileData"));
    const account = profileData.account;
    let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
    let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
    let hasDomain = domain?.some((res) => userEmail.includes(res));
    const isInternal = hasDomain
    this.props.dispatch(SHOW_LOADER());
    this.props.dispatch(SET_NAVBAR_TITLE(isInternal?account+"  - Criterion SDCloud Platform":"Criterion SDCloud Platform"));

    // First fetch user profile details and update userInfo state
    this.getProfileDetails().catch((err) => {
      this.props.dispatch(HIDE_LOADER());
      const payload = {
        message: "Error in fetching profile.",
        color: "danger",
        error: err,
        showErrorPage: true,
      };
      this.props.dispatch(SHOW_NOTIFICATION({ payload }));
    });
    // await this.getSsoDetails();
    // Next call will be to check redux state for common profile info
    if (this.props.profile.profileObj.privilege) {
      if (this.props.profile.profileObj.privilege == "admin") {
        this.setState((prevState) => ({
          profilePermissions: [
            ...prevState.profilePermissions,
            "Trusted domains",
          ],
        }));
      } else {
        this.setState((prevState) => ({
          profilePermissions: [
            ...prevState.profilePermissions,
            "Email notifications",
          ],
        }));
      }
      this.getProfile();
      this.props.dispatch(HIDE_LOADER());
    }
  };

  componentDidUpdate(prevProps, prevState) {
    // Check updates for redux state and subsequent updates
    if (
      prevProps.profile.profileObj.privilege !==
      this.props.profile.profileObj.privilege
    ) {
      if (this.props.profile.profileObj.privilege == "admin") {
        this.setState((prevState) => ({
          profilePermissions: [
            ...prevState.profilePermissions,
            "Trusted domains",
          ],
        }));
      } else {
        this.setState((prevState) => ({
          profilePermissions: [
            ...prevState.profilePermissions,
            "Email notifications",
          ],
        }));
      }
      this.getProfile();
      this.props.dispatch(HIDE_LOADER());
    }
  }

  render() {
    let profileAction = this.props.history?.location?.state?.profileAction;
    let profileData = this.props.history?.location?.state?.profileObj;
    if (profileAction && profileAction !== this.state.currentProfileTab) {
      this.setState({
        currentProfileTab: profileAction,
      });
    }
    if (this.state.currentProfileTab === "") {
      return null;
    }
    return (
      <GridContainer justify="center">
        <GridItem
          xs={12}
          sm={3}
          md={3}
          lg={3}
          style={{ marginTop: "55px" }}
          // style={{ padding: "55px 30px 10px 30px !important" }}
        >
          <this.sidebarHeader />
        </GridItem>
        <GridItem xs={12} sm={9} md={9} lg={9}>
          {this.state.currentProfileTab == "My information" ? (
            <UserInfo
              notes={notes.formNote}
              userData={
                this.state.userInfo.email ? this.state.userInfo : profileData
              }
              profileDetails={profileData}
              // callback={this.handleUserInfo}
            />
          ) : null}
          {this.state.currentProfileTab == "Trusted domains" ? (
            <TrustedDomain />
          ) : null}
          {this.state.currentProfileTab == "Activity logs" ? (
            <LogWidget />
          ) : null}
          {/* {this.state.currentProfileTab == "Single sign-on" ? (
            <SingleSignOn />
          ) : null} */}
          {/* {this.state.currentProfileTab == "Multi-factor authentication" ? (
            <MultiFactorAuthentication />
          ) : null} */}
          {this.state.currentProfileTab == "Security question" ? (
            <UpdateSecurityQuestion />
          ) : null}
          {this.state.currentProfileTab == "Password reset" ? (
            <UpdatePassword notes={notes.passwordNote} />
          ) : null}
          {this.state.currentProfileTab == "Email notifications" ? (
            <PlatformNotification />
          ) : null}
        </GridItem>
      </GridContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profileObj,
  };
};

// const dispatchToProps =
const MyProfileConnect = withRouter(MyProfile);
export default connect(mapStateToProps)(MyProfileConnect);
