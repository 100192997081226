import React, { useState, useEffect } from "react";

import ReactMarkdown from "react-markdown";

import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import PopupCard from "../../../Components/ServiceOffering/popup";

// Material UI Component
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";

// Local Components
import ProvisionSandboxModal from "./ProvisionSandboxModal";
import SandboxScheduleModal from "./SandboxScheduleModal";
import TopologyModal from "./TopologyModal";
import lock from "assets/img/lock.png";
import unlock from "assets/img/unlock.png";
import { ReactComponent as svg6 } from "../../../../assets/criterion_networks/svg/06.svg";
import { ReactComponent as svg7 } from "../../../../assets/criterion_networks/svg/07.svg";
import SvgComponent from "utils/SvgComponent";
import { ReactComponent as svg11 } from "../../../../assets/criterion_networks/svg/11.svg";

const DefaultCardImage = require("assets/criterion_networks/img/LearningLab_defaultimg.png");

const useStyles = makeStyles(theme => ({
  container: {
    fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    marginTop: "1px",
    marginBottom: "2px",
    padding: "15px 0 15px 50px"
  },
  cardData: {
    padding: "10px 5px 5px 60px !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    fontFamily: `${theme.palette.fontList.selectedFont} !important`
  },
  cardDescription: {
    marginTop: "10px !important",
    fontSize: "14px !important",
    fontFamily: `${theme.palette.fontList.selectedFont} !important`
  },
  image: {
    paddingTop: "10px",
    width: "114px",
    display: "flex",
    justifyContent: "center",
    height: "125px",
    "& img": {
      width: "114px"
    },
    "&:hover": {
      cursor: "pointer"
    }
  },
  button: {
    padding: 0,
    margin: 0,
    alignSelf: "flex-end",
    borderBottom: "1.5px solid",
    fontSize: "15px"
  },
  buttonGroup: {
    display: "flex",
    alignSelf: "flex-end"
  },
  title: {
    fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    textTransform: "none",
    fontSize: "16px !important"
  },
  cardFooter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  availableHours: {
    color: theme.palette.grey["700"],
    marginTop: "2%"
    // fontSize: "12px",
  },
  panelheight: {
    height: "90px !important",
    margin: "0px 0px 13px !important",
    overflow: "hidden !important",
    width: "100%"
  },
  imgFilter: {
    filter: theme.palette.secondary.filterImage,
    background: "transparent !important"
  },
  font: {
    fontFamily: theme.palette.fontList.selectedFont
  },
  unsetIcon: {
    fontSize: "unset",
    overflow: "unset"
  },
  absoluteImg: {
    width: "unset",
    height: "unset"
  }
}));

const BlueOnTooltip = withStyles({
  arrow: {
    width: 17,
    "&::before": {
      border: "1px solid #0F1A2B",
      backgroundColor: "#0F1A2B",
      boxSizing: "border-box"
    }
  },
  tooltip: {
    color: "white",
    fontSize: "1em",
    maxWidth: 600,
    backgroundColor: "#0F1A2B"
  }
})(Tooltip);

function TemplateCards(props) {
  const classes = useStyles();


  const [openProvisionModal, setOpenProvisionModal] = useState(false);
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [openTopologyModal, setOpenTopologyModal] = useState(false);

  let isLabDisabled = false;
  if (props.cardData.access) {
    isLabDisabled = props.cardData.access;
  }

  // let tries = Math.floor((props.cardData.profileData.allocated_hours - props.cardData.profileData.consumed_hours) / props.cardData.profileData.labhours ? props.cardData.profileData.labhours : 0);
  // const availableTries = tries < 1 ? 0 : tries;

  let andescription = props.cardData.description.replaceAll("<p>", " ");
  let description = andescription.replaceAll("<br>", " ");
  let timeLeft = props.cardData.profileData.allocated_hours - props.cardData.profileData.consumed_hours

  return (
    <>
      <ProvisionSandboxModal
        open={openProvisionModal}
        identifier={props.cardData.identity}
        onClose={setOpenProvisionModal}
        reservedIP={props.reservedIP}
        runningClusters={props.runningClusters}
        hoursLeft={timeLeft}
        title={props.cardData.title}
      />
      <SandboxScheduleModal
        open={openScheduleModal}
        identifier={props.cardData.identity}
        onClose={setOpenScheduleModal}
        reservedIP={props.reservedIP}
        setData={{
          showNotification: false,
          notificationMessage: "",
          notificationType: ""
        }}
        initialValues={{
          name: "",
          startDate: null,
          startTime: null,
          run_time: null
        }}
        title={props.cardData.title}
      />
      <TopologyModal
        image={props.cardData.diagram_img}
        openModal={openTopologyModal}
        handleClose={setOpenTopologyModal}
      />

      <Paper elevation={0} className={classes.container}>
        <Grid container>
          <Grid item xs={12} sm={4} md={4} lg={1}>
            <div className={classes.image}>
              {props.cardData.diagram_img &&
              props.cardData.diagram_img.trim() !== "" ? (
                <img
                  src={props.cardData.diagram_img}
                  onClick={() => setOpenTopologyModal(true)}
                />
              ) : (
                <SvgComponent
                  className={classes.absoluteImg}
                  type="default"
                  svgSource={svg11}
                />
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={11}>
            <div className={classes.cardData}>
              <div>
                <Typography variant="h6" className={classes.title}>
                  {props.cardData.title}
                  {!isLabDisabled ? (
                    <SvgComponent
                      className={`new-lock ${classes.unsetIcon}`}
                      type="icon"
                      svgSource={svg6}
                    />
                  ) : (
                    // <img className={`new-lock ${classes.imgFilter}`} src={lock} alt=''></img>
                    <SvgComponent
                      className={`new-lock ${classes.unsetIcon}`}
                      type="icon"
                      svgSource={svg7}
                    />
                    // <img className={`new-lock ${classes.imgFilter}`} src={unlock} alt=''></img>
                  )}
                  {props?.inventoryData ? (
                    props?.inventoryData[props.cardData.title] ? (
                      <Chip
                        label={props.inventoryData[props.cardData.title]}
                        style={{ marginLeft: "10px" }}
                      />
                    ) : null
                  ) : null}
                </Typography>
                {props.cardData.description.length < 160 ? (
                  <div className={classes.panelheight}>
                    <ReactMarkdown /*className={classes.blockellipsis} */
                      source={description}
                      className={classes.cardDescription}
                      skipHtml={true}
                    />
                  </div>
                ) : (
                  <div className={classes.panelheight}>
                    <p>
                      <span className={classes.cardDescription}>
                        <ReactMarkdown
                          source={description.slice(0, 115)}
                          skipHtml={true}
                        />
                        <PopupCard msg="sandboxes" value={description} />
                      </span>
                    </p>
                  </div>
                )}
              </div>
              <div className={classes.cardFooter}>
                <div>
                  <Typography
                    variant="body2"
                    className={classes.availableHours}
                  >
                    <p
                      className={`card-content float-left pt-4 ${classes.font}`}
                    >
                      {" "}
                      Available Hours:{" "}
                      <span>
                        {" "}
                        {props.cardData.profileData.permission === "view"
                          ? 0
                          : props.cardData.profileData.allocated_hours -
                              props.cardData.profileData.consumed_hours <
                            0
                          ? 0
                          : props.cardData.profileData.allocated_hours -
                            props.cardData.profileData.consumed_hours}{" "}
                      </span>
                    </p>
                  </Typography>
                </div>
                <div className={classes.buttonGroup}>
                  <Button
                    color="secondary"
                    variant="text"
                    disableElevation
                    size="medium"
                    disableFocusRipple
                    type="submit"
                    className={classes.button}
                    style={{ marginRight: "20px" }}
                    onClick={e => setOpenScheduleModal(true)}
                    disabled={timeLeft < 1 || !props.cardData.access ? true : false || props.cardData.profileData.permission === "delete"}
                  >
                    Schedule
                  </Button>
                  <Button
                    variant="text"
                    color="secondary"
                    disableElevation
                    size="medium"
                    disableFocusRipple
                    type="submit"
                    className={classes.button}
                    onClick={e => setOpenProvisionModal(true)}
                    disabled={timeLeft < 1 || !props.cardData.access ? true : false || props.cardData.profileData.permission === "delete"}
                  >
                    Launch now
                  </Button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default TemplateCards;
