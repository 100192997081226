// This component creates a modal for Reserved IP with respective form fields, the
// modal is popped up during provision or resuming of a lab or poc.

import React, { useState } from "react";
import { SECONDARY_COLOR } from "global_constants/app_constants";
import Button from "@material-ui/core/Button";
// Formik
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Api } from "config/api";
import Loader from "react-loader-spinner";
import { LOADER_TYPE } from "global_constants/app_constants";
import "views/ServiceOffering/LearningLab/acess labs/Overview.scss";
import NotificationAlert from "views/Components/NotificationAlert";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import theme from "theme";
const inputProps = {
  disableAnimation: true,
  shrink: false,
};

const customStyles = (theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 450,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  modalCloseIcon: {
    position: "absolute",
    marginTop: "-125px",
    marginLeft: "45px",
    cursor: "pointer",
    color: "#FFFFFF",
    fontSize: "2rem",
  },
  datetime: {
    color: SECONDARY_COLOR,
  },
  buttStyle: {
    background: theme.palette.buttonColor.main,
    "&:hover": {
      background: theme.palette.buttonColor.main,
    },
  },
  NotificationAlert: {
    width: 300,
  },
});

const AddHoursSandboxModal = (props) => {
  const useStyles = makeStyles(customStyles);
  const classes = useStyles();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");

  const hideModal = () => {
    props.hideModal();
  };

  const formik = useFormik({
    initialValues: {
      hours: "",
    },
    onSubmit: (values) => {
      setShowNotification(false);
      setBtnDisabled(true);
      addLabHours(props.addHoursDetails, values.hours);
    },
    validate: (values) => {
      let errors = {};

      if (!values.hours) {
        errors.hours = "Required";
      }

      return errors;
    },
  });

  const addLabHours = (addHoursData, hours) => {
    const data = {
      uuid: addHoursData.uuid,
      hours: hours,
      template_name: addHoursData.labIdentifier,
    };
    setBtnDisabled(true);
    SDCloudBackendAPIWithToken()
      .post(Api.addHoursToSandbox, data)
      .then((res) => {
        //console.log(`data: ${res.data.message}`);
        setShowNotification(true);
        setNotificationMessage(res.data.message);
        setNotificationType("success");
        setBtnDisabled(false);
      })
      .catch((err) => {
        let error = err.response.data;
        setShowNotification(true);
        setNotificationMessage(error.message);
        setNotificationType("error");
        setBtnDisabled(false);
      });
  };

  return (
    // Form modal for add hours to lab
    <Modal
      disableAutoFocus
      open={!props.openModal}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      className={classes.modal}
      onClose={() => hideModal()}
    >
      <GridContainer
        justify="center"
        alignItems="center"
        className={classes.paper}
      >
        <GridItem
          xs={14}
          sm={14}
          md={14}
          lg={14}
          style={{ marginBottom: "20px" }}
        >
          <h3>Add more hours</h3>
          {/* <p>Add 2 hours to this sandbox</p> */}
        </GridItem>
        <GridContainer justify="flex-end">
          <GridItem xs={12} sm={1} md={1} lg={1}>
            {" "}
            <CloseIcon
              className={classes.modalCloseIcon}
              onClick={() => hideModal()}
            />
          </GridItem>{" "}
        </GridContainer>
        <form onSubmit={formik.handleSubmit}>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <NotificationAlert
              notificationType={"info"}
              showNotification={true}
              message={`Maximum two hours can be added to this sandbox`}
              className={classes.NotificationAlert}
            />
            {formik.errors.hours && formik.touched.hours ? (
              <TextField
                type="number"
                variant="filled"
                label="No of hours:"
                name="hours"
                fullWidth
                InputLabelProps={inputProps}
                InputProps={{
                  inputProps: {
                    min: 1,
                    max: 2,
                  },
                }}
                onChange={formik.handleChange}
                helperText={formik.errors.hours}
                onBlur={formik.handleBlur}
                error
              />
            ) : (
              <TextField
                type="number"
                variant="filled"
                label="No of hours:"
                name="hours"
                fullWidth
                InputLabelProps={inputProps}
                InputProps={{
                  inputProps: {
                    min: 1,
                    max: 2,
                  },
                }}
                onChange={formik.handleChange}
              />
            )}
            {!btnDisabled ? (
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                size="large"
                fullWidth
                disableFocusRipple
                className={classes.buttStyle}
                type="submit"
              >
                Submit
              </Button>
            ) : null}
            {btnDisabled ? (
              <Loader
                type={LOADER_TYPE}
                color={theme.palette.secondary.main}
                width={50}
                height={50}
                style={{ float: "right", marginRight: "15px" }}
              />
            ) : null}

            <NotificationAlert
              notificationType={notificationType}
              showNotification={showNotification}
              message={notificationMessage}
            />
          </GridItem>
        </form>
      </GridContainer>
    </Modal>
  );
};

export default AddHoursSandboxModal;
