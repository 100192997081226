/* eslint-disable no-use-before-define */
import React from "react";
import Chip from "@material-ui/core/Chip";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
// import { SvgIcon } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

const DotSVGComponent = props => {
  return <FiberManualRecordIcon />;
};

const customStyles = theme => ({
  containerStyle: {
    padding: "0px 30px"
  },
  inputFilterStyle: {
    marginTop: "0px",
    height: "48px"
  },
  filterStyle: {
    padding: "0px !important"
  },
  experienceText: {
    fontSize: "16px"
  }
});

export default function FixedTags(props) {
  const [value, setValue] = React.useState([]);
  const useStyles = makeStyles(customStyles);
  const classes = useStyles();

  return (
    <GridContainer
      justify="center"
      alignItems="center"
      className={classes.containerStyle}
    >
      <GridItem xs={12} sm={2} md={2} lg={2} className={classes.filterStyle}>
        <GridContainer justify="center" alignItems="center">
          <h4 className={classes.experienceText}>Learning</h4>
        </GridContainer>
      </GridItem>
      <GridItem xs={12} sm={10} md={10} lg={10} className={classes.filterStyle}>
        <Autocomplete
          multiple
          id="fixed-tags-demo"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.09)" }}
          value={value}
          onChange={(event, newValue) => {
            setValue([...newValue]);

            let filterValue = event.target.innerText
              ? filterOptions.find(log => log.title === event.target.innerText)
                  .value
              : null;
            let logType = event.target.innerText
              ? filterOptions.find(log => log.title === event.target.innerText)
                  .type
              : null;

            filterValue &&
              logType &&
              props.handleAddFilter(logType, filterValue);
          }}
          options={filterOptions}
          getOptionLabel={option => option.title}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={option.title}
                deleteIcon={<DotSVGComponent />}
                onDelete={() => {
                  setValue(
                    value.filter(valueObj => valueObj.title !== option.title)
                  );
                  props.handleRemoveFilter(
                    filterOptions.find(log => log.title === option.title).type,
                    filterOptions.find(log => log.title === option.title).value
                  );
                }}
              />
            ))
          }
          style={{ width: "auto" }}
          renderInput={params => (
            <TextField
              {...params}
              label=""
              className={classes.inputFilterStyle}
              variant="filled"
              placeholder="+ Add filter (by Vendor/Technology etc."
            />
          )}
        />
      </GridItem>
    </GridContainer>
  );
}

// Initialize Activity Log filters
const filterOptions = [];
