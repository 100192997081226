import React, { useEffect, useState } from "react";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import Switch from "@material-ui/core/Switch";

// Local
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
// Styling
const useStyles = makeStyles(theme => ({
    root: {
        marginTop: "20px"
    },
    font: {
        fontFamily: theme.palette.fontList.selectedFont
    },
    btnStyle: {
        background: `${theme.palette.buttonColor.main} !important`,
        height: "40px"
        // padding: "20px 150px"
    },
    topnav: {
        padding: "0px",
        margin: "0px 20px 15px 4px",
        boxShadow: "none"
    },
    heading: {
        padding: "20px 20px 20px 30px",
        borderBottom: "1px solid lightgray"
    },
    profileCard: {
        background: "#FFFFFF",
        boxSizing: "border-box",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.04)",
        position: "relative"
    },
    profileData: {
        padding: "20px 20px 20px 30px",
        fontWeight: "500"
    },
    radiogroup: {
        marginTop: "45px"
    },
    radio: {
        marginBottom: "5px"
    },
    customTabRoot: {
        color: `#000`,
        "& .Mui-selected .MuiTab-wrapper": {
            color: `${theme.palette.secondary.main}`,
        }
    },
    switch: {
        background: "none !important"
    },
    customInputStyle: {
        background: "white"
    },
    tabpanel: {
        padding: "0px 15px 0px 0px !important"
    }
}));

function TabPanel(props) {
    const { children, value, classes, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={4}  classes={{root: classes.root}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Edituser = () => {
    const profileData = JSON.parse(localStorage.getItem("profileData"));
    const account = profileData.account;
    let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
    let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
    let hasDomain = domain?.some((res) => userEmail.includes(res));
    const [isInternal, setIsInternal] = useState(hasDomain);
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(SET_NAVBAR_TITLE(isInternal?account+"  - User Management": "User Management"));
    }, []);
    // States
    const [radioValue, setRadioValue] = useState("isAdmin");
    const [value, setValue] = React.useState(0);

    const handleRadioChange = event => {
        setRadioValue(event.target.value)
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12} sm={8} md={8} style={{ padding: "15px 15px 0px 0px"}}>
                    <div className="createaccount-card">
                        <h3 className="createaccount-widget-title">Siva Prasad</h3>
                        <p className={classes.span}>Enter text field for Edit User</p>
                        <Grid container className='box-inner-height mt-2'>
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="First Name"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="first-name"
                                        variant="filled"
                                    // value={""}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Last Name"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="last-name"
                                        variant="filled"
                                    // value={""}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Mail ID"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="mail-id"
                                        variant="filled"
                                    // value={""}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Profile"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="profile"
                                        variant="filled"
                                    // value={""}
                                    />
                                </FormControl>
                            </Grid><Grid item xs={12} sm={6} md={6} style={{ paddingRight: "50px", margin: 0 }}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <Button
                                            // onClick={() => {
                                            //     handleSubmit();
                                            // }}
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            disableElevation
                                            className={classes.btnStyle}
                                            disableFocusRipple
                                            type="submit"
                                        >
                                            Save
                                        </Button>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} style={{ paddingRight: "50px", margin: 0 }}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <Button
                                            // onClick={() => {
                                            //     handleSubmit();
                                            // }}
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            disableElevation
                                            className={classes.btnStyle}
                                            disableFocusRipple
                                            type="submit"
                                        >
                                            Cancel
                                        </Button>
                                    </FormControl>
                                </Grid>

                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4} style={{ padding: "15px 15px 0px 0px"}}>
                    <div className="createaccount-card" style={{ minHeight: "409px"}}>
                        <h3 className="createaccount-widget-title">Reset Password</h3>
                        <p className={classes.span}>Help you to reset your password</p>
                        <Grid item xs={6} sm={6}>
                            <FormControl
                                fullWidth
                                className={classes.customInputStyle}
                            >
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="secondary"
                                    disableElevation
                                    className={classes.btnStyle}
                                    disableFocusRipple
                                    type="submit"
                                >
                                    Reset Password
                                </Button>
                            </FormControl>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <div className={classes.root} >
                    <h3 className="createaccount-widget-title">Siva Prasad</h3>
                        <AppBar position="static" classes={{ root: classes.tabpanel }}>
                            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example"
                                classes={{ root: classes.customTabRoot }}>
                                <Tab label="Learning Center (5)" {...a11yProps(0)} />
                                <Tab label="Showcases (5)" {...a11yProps(1)} />
                                <Tab label="Sandboxes (5)" {...a11yProps(2)} />
                                <Tab label="Design Center (5)" {...a11yProps(3)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0} classes={{ root: classes.tabpanel }}>
                            <Grid container style={{ borderBottom: "1px solid lightgray" }}>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <strong>SKU</strong>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <strong>Product</strong>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <strong>Lab RunTime</strong>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <strong>Consumed Hours</strong>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <strong>Available Hours</strong>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <strong>Allocated Hours</strong>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={3} md={3}></Grid>
                                <Grid item xs={12} sm={3} md={3} style={{ padding: "0px 10px 0px 10px" }}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <Button
                                            // onClick={() => {
                                            //     handleSubmit();
                                            // }}
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            disableElevation
                                            className={classes.btnStyle}
                                            disableFocusRipple
                                            type="submit"
                                        >
                                            Save
                                        </Button>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3} style={{ padding: "0px 10px 0px 10px" }}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <Button
                                            // onClick={() => {
                                            //     handleSubmit();
                                            // }}
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            disableElevation
                                            className={classes.btnStyle}
                                            disableFocusRipple
                                            type="submit"
                                        >
                                            Cancel
                                        </Button>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3}></Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={1} classes={{ root: classes.tabpanel }}>
                            <Grid container style={{ borderBottom: "1px solid lightgray" }}>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <strong>SKU</strong>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <strong>Product</strong>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <strong>Lab RunTime</strong>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <strong>Consumed Hours</strong>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <strong>Available Hours</strong>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <strong>Allocated Hours</strong>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={3} md={3}></Grid>
                                <Grid item xs={12} sm={3} md={3} style={{ padding: "0px 10px 0px 10px" }}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <Button
                                            // onClick={() => {
                                            //     handleSubmit();
                                            // }}
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            disableElevation
                                            className={classes.btnStyle}
                                            disableFocusRipple
                                            type="submit"
                                        >
                                            Save
                                        </Button>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3} style={{ padding: "0px 10px 0px 10px" }}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <Button
                                            // onClick={() => {
                                            //     handleSubmit();
                                            // }}
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            disableElevation
                                            className={classes.btnStyle}
                                            disableFocusRipple
                                            type="submit"
                                        >
                                            Cancel
                                        </Button>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3}></Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={2} classes={{ root: classes.tabpanel }}>
                            <Grid container style={{ borderBottom: "1px solid lightgray" }}>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <strong>SKU</strong>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} className={""}>
                                    <div className="inventory-card">
                                        <strong>Product</strong>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <strong>Consumed Hours</strong>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <strong>Available Hours</strong>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <strong>Allocated Hours</strong>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={3} md={3}></Grid>
                                <Grid item xs={12} sm={3} md={3} style={{ padding: "0px 10px 0px 10px" }}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <Button
                                            // onClick={() => {
                                            //     handleSubmit();
                                            // }}
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            disableElevation
                                            className={classes.btnStyle}
                                            disableFocusRipple
                                            type="submit"
                                        >
                                            Save
                                        </Button>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3} style={{ padding: "0px 10px 0px 10px" }}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <Button
                                            // onClick={() => {
                                            //     handleSubmit();
                                            // }}
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            disableElevation
                                            className={classes.btnStyle}
                                            disableFocusRipple
                                            type="submit"
                                        >
                                            Cancel
                                        </Button>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3}></Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={value} index={3} classes={{ root: classes.tabpanel }}>
                        <Grid container style={{ borderBottom: "1px solid lightgray" }}>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <strong>SKU</strong>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} className={""}>
                                    <div className="inventory-card">
                                        <strong>Product</strong>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <strong>Consumed Hours</strong>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <strong>Available Hours</strong>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <strong>Allocated Hours</strong>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>01</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} className={""}>
                                    <div className="inventory-card">
                                        <span>Lorom ipsom dolor sit amet, consec</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>4</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} className={""}>
                                    <div className="inventory-card">
                                        <span>981</span>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}
                                    className={classes.customInputStyle}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <TextField
                                            style={{ paddingRight: "50px", margin: 0 }}
                                            rows={1}
                                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                                            InputProps={{ disableUnderline: true }}
                                            variant="filled"
                                        // value={""}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={3} md={3}></Grid>
                                <Grid item xs={12} sm={3} md={3} style={{ padding: "0px 10px 0px 10px" }}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <Button
                                            // onClick={() => {
                                            //     handleSubmit();
                                            // }}
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            disableElevation
                                            className={classes.btnStyle}
                                            disableFocusRipple
                                            type="submit"
                                        >
                                            Save
                                        </Button>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3} style={{ padding: "0px 10px 0px 10px" }}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <Button
                                            // onClick={() => {
                                            //     handleSubmit();
                                            // }}
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            disableElevation
                                            className={classes.btnStyle}
                                            disableFocusRipple
                                            type="submit"
                                        >
                                            Cancel
                                        </Button>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3}></Grid>
                            </Grid>
                        </TabPanel>
                    </div>
                </Grid>

            </Grid>
        </>
    );
};

export default Edituser;