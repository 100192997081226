import React from "react";
import { Button } from "@material-ui/core";

import GridItem from "components/Grid/GridItem";
import CustomModal from "../../../components/CustomModal";
import { makeStyles } from "@material-ui/core/styles";

const customStyle = theme => ({
  buttStyle: {
    background: `${theme.palette.buttonColor.main} !important`,
    "&:hover": {
      background: `${theme.palette.buttonColor.main} !important`,
    }
  },
  buttOutlined: {
    color: theme.palette.buttonColor.main,
    borderColor: theme.palette.buttonColor.main,
    "&:hover": {
      border: `1px solid ${theme.palette.buttonColor.main}`,
    }
  }
})
const useStyles = makeStyles(customStyle);

const CreateTemplateModal = ({
  visible,
  onClose,
  onConfirm,
  selectedTemplate,
  message,
  font
}) => {
  const classes = useStyles();
  return (
    <CustomModal
      title="Confirmation"
      visible={visible}
      onClose={onClose}
      className={`confirmation-modal ${font}`}
      
    >
      <GridItem className={`confirmation-text ${font}`} xs={12} sm={12} md={12} lg={12}>
       {message}
      </GridItem>
      {/* <GridItem className="warning-content" xs={12} sm={12} md={12} lg={12}>
        <div>
          <b>Warning!</b> Once a template is deleted, it cannot be recovered.
        </div>
      </GridItem> */}
      <GridItem className="btns" md={12}>
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          size="large"
          fullWidth
          onClick={onConfirm}
          disableFocusRipple
          className={`delete ${classes.buttStyle}`}
        >
          Yes
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          disableElevation
          size="large"
          fullWidth
          className={classes.buttOutlined}
          onClick={onClose}
          disableFocusRipple
        >
          No
        </Button>
      </GridItem>
    </CustomModal>
  );
};

export default CreateTemplateModal;