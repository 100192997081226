// This component creates a modal for Reserved IP with respective form fields, the
// modal is popped up during provision or resuming of a lab or poc.

import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import { SECONDARY_COLOR } from "global_constants/app_constants";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Loader from "react-loader-spinner";
import { LOADER_TYPE } from "global_constants/app_constants";
import "views/ServiceOffering/LearningLab/acess labs/Overview.scss";
import theme from "theme";
import NotificationAlert from "views/Components/NotificationAlert";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const inputProps = {
  disableAnimation: true,
  shrink: false,
};

const customStyles = (theme) => ({
  customDropDownStyle: {
    marginTop: "2px",
    marginBottom: "20px",
  },
  errorText: {
    fontSize: "12px",
    color: theme.palette.error.main,
  },
  multilineColor:{
    color:'#7D7D7D'
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  modalCloseIcon: {
    position: "absolute",
    marginTop: "-125px",
    marginLeft: "45px",
    cursor: "pointer",
    color: "#FFFFFF",
    fontSize: "2rem",
  },
  formIpLabel: {},
  datetime: {
    color: SECONDARY_COLOR,
  },
  helperText: {
    margin: "20px 0",
    color: "#7D7D7D",
  },
  helperCustom: {
    color: "#7D7D7D",
    margin: "10px 0 1px",
  },
  buttStyle: {
    background: theme.palette.buttonColor.main,
    "&:hover": {
      background: theme.palette.buttonColor.main,
    },
  },
  purposeDropdown: {
    width: "100%",
    marginTop: "5px",
  },
  zoneDropdown: {
    width: "100%",
    marginTop: "5px",
  }
});

const AlertDialog = (props) => {
  const useStyles = makeStyles(customStyles);
  const classes = useStyles();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [ipListDisabled, setIpListDisabled] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [error, setError] = React.useState(false);
  const [value, setValue] = React.useState("dynamic");
  const [menuItemsVals2, setMenuItemsVals2] = useState([]);
  const [menuItemsVals1, setMenuItemsVals1] = useState([]);
  const [dealId, setDeal] = useState(null);
  const [actionValue, setActionValue] = React.useState("terminate");
  const [ip, setIp] = React.useState(null);
  const [purpose, setPurpose] = useState(null);
  const [zone, setZone] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
  let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
  let hasDomain = domain?.some((res) => userEmail.includes(res));
  const [isInternal, setIsInternal] = useState(hasDomain);

  const hideAlertBox = () => {
    props.hideAlert();
  };

  const handleIpChange = (event) => {
    setIp(event.target.value);
    setError(false);
  };
  const handlePurposeChange = (event) => {
    setPurpose(event.target.value);
    setError(false);
  };
  const handleZoneChange = (event) => {
    setZone(event.target.value);
    setError(false);
  };
  const handleActionChange = (event) => {
    setActionValue(event.target.value);
    setError(false);
  };
  const handleDealChange = (event) => {
    setDeal(event.target.value);
    setError(false);
  };
  const LaunchLabReservedIp = () => {
    if ((value == "reserved" && !ip) || (!purpose && isInternal) || purpose == "" || purpose == "None" || (!zone) || zone == "" || zone == "None"){
      setError(true);
      return;
    }
    var purposedata = purpose;
    if (purpose == null && !isInternal) {
      let available_purposes = JSON.parse(
          localStorage.getItem("available_purposes")
      );
      purposedata = available_purposes.external[0];
    }
    setBtnDisabled(true);
    var zonedata = zone;
    if (zone == null){
      let available_zones = JSON.parse(
        localStorage.getItem("available_zones")
      );
      zonedata = available_zones
    }
    let reservedIp = false;
    if (value != "dynamic") {
      reservedIp = true;
    }
    // setBtnDisabled(true);
    const labData = props.labLaunchData;

    labData.launchLabBtnHandler(
      labData.labId,
      labData.labIdentifier,
      labData.labEnabled, // labEnabled parameter
      labData.labName,
      reservedIp ? reservedIp : null,
      ip ? ip : null,
      null,
      null,
      purposedata ? purposedata : null,
      dealId ? dealId:null,
      zonedata ? zonedata : null,
    );
    setBtnDisabled(false);
    hideAlertBox();
  };

  useEffect(() => {
    let available_purposes = JSON.parse(
      localStorage.getItem("available_purposes")
    );
    if (isInternal) {
      if (available_purposes?.internal) {
        let purposes = available_purposes.internal.map((item) => {
          return <option value={item}>{item}</option>;
        });
        setMenuItems([<option value={null}>None</option>, ...purposes]);
      }
      var deal_id = JSON.parse(localStorage.getItem("deal_ids"))
      let deals = deal_id.map(item => {
        return <option value={item}>{item}</option>;
      });
      setMenuItemsVals2([<option value={null}>None</option>, ...deals]);
    } else {
      if (available_purposes?.external) {
        let purposes = available_purposes.external.map((item) => {
          return <option value={item}>{item}</option>;
        });
        setMenuItems([<option value={null}>None</option>, ...purposes]);
      }
    }
    let available_zone = JSON.parse(
      localStorage.getItem("available_zones")
    );
    let available_zones=Object.keys(available_zone)
    let zones = available_zones.map(item => {
      return <option value={item}>{item}</option>;
    });
    setMenuItemsVals1([<option value={null}>None</option>, ...zones]);
  }, []);

  return (
    // calendar modal for schedule lab
    <Modal
      disableAutoFocus
      open={props.showAlert}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      className={classes.modal}
      onClose={() => hideAlertBox()}
      disableBackdropClick
    >
      <GridContainer
        justify="center"
        alignItems="center"
        className={classes.paper}
      >
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginBottom: "15px" }}
        >
          <h3>{`Launch ${
            props.offeringType === "learninglabs"
              ? "learning labs"
              : props.offeringType
          }`}</h3>
          <p>Please fill the details</p>
        </GridItem>
        <GridContainer justify="flex-end">
          <GridItem xs={12} sm={1} md={1} lg={1}>
            {" "}
            <CloseIcon
              className={classes.modalCloseIcon}
              onClick={() => hideAlertBox()}
            />
          </GridItem>{" "}
        </GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Typography variant="body2" className={classes.helperCustom}>
            Name
          </Typography>
          <TextField
            type="text"
            variant="filled"
            // label="Name"
            name="name"
            inputProps={{
              className: classes.multilineColor
            }}
            value={props.labLaunchData.fullTitle}
            InputProps={{ disableUnderline: true }}
            disabled={true}
            title="This field is not editable"
            fullWidth
          />
          {isInternal ? (
            <FormControl
              fullWidth
              variant="filled"
              label="Purpose"
              className={(classes.formControl, classes.purposeDropdown)}
            >
              <Typography variant="body2" className={classes.helperCustom}>
                Purpose
              </Typography>
              <Typography variant="body2" className={classes.helperCustom}>
              </Typography>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                name="purpose"
                fullWidth
                value={purpose}
                native
                onChange={handlePurposeChange}
                inputProps={{
                  name: "purpose",
                }}
                className={classes.purposeDropdown}
              >
                {menuItems.length > 0 ? (
                  menuItems
                ) : (
                  <MenuItem value="">None</MenuItem>
                )}
              </Select>
              {error && (
                <FormHelperText className={classes.errorText}>
                  This field is required!
                </FormHelperText>
              )}
            </FormControl>
          ) : null}
          {isInternal && purpose == "Mint"? (
              <FormControl
                  fullWidth
                  variant="filled"
                  // label="Purpose"
                  className={(classes.formControl, classes.purposeDropdown)}
              >
                <Typography variant="body2" className={classes.helperCustom}>
                  Select Deal id
                </Typography>
                <Typography
                    variant="body2"
                    className={classes.helperCustom}
                ></Typography>
                <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="dealId"
                    fullWidth
                    value={dealId}
                    native
                    onChange={handleDealChange}
                    inputProps={{
                      name: "dealId"
                    }}
                    className={classes.purposeDropdown}
                >
                  {menuItemsVals2.length > 0 ? (
                      menuItemsVals2
                  ) : (
                      <menuItemsVals2 value="">None</menuItemsVals2>
                  )}
                </Select>
                {error && (
                    <FormHelperText className={classes.errorText}>
                      This field is required!
                    </FormHelperText>
                )}
              </FormControl>
          ) : null}
          <FormControl
              fullWidth
              variant="filled"
              label="Zone"
              className={(classes.formControl, classes.zoneDropdown)}
            >
              <Typography variant="body2" className={classes.helperCustom}>
                Zone
              </Typography>
              <Typography variant="body2" className={classes.helperCustom}>
              </Typography>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                name="zone"
                fullWidth
                value={zone}
                native
                onChange={handleZoneChange}
                inputProps={{
                  name: "zone",
                }}
                className={classes.zoneDropdown}
              >
                {menuItemsVals1.length > 0 ? (
                  menuItemsVals1
                ) : (
                  <menuItemsvals1 value="">None</menuItemsvals1>
                )}
              </Select>
              {error && (
                <FormHelperText className={classes.errorText}>
                  This field is required!
                </FormHelperText>
              )}
            </FormControl>
          <Typography variant="body2" className={classes.helperCustom}>
            Number of Hours
          </Typography>
          <TextField
            // label="Number of Hours"
            variant="filled"
            name="endTime"
            disabled={true}
            title="This field is not editable"
            inputProps={{
              className: classes.multilineColor
            }}
            value={props.labLaunchData.runTime}
            InputProps={{ disableUnderline: true }}
            fullWidth
          />
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body2" className={classes.helperCustom}>
                End action
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="ip_type"
                  name="action"
                  value={actionValue}
                  onChange={handleActionChange}
                >
                  <FormControlLabel
                    value="suspend"
                    disabled={true}
                    title="Suspend feature is not available for this product"
                    control={<Radio color="primary" />}
                    label="Suspend"
                  />
                  <FormControlLabel
                    value="terminate"
                    selected={true}
                    control={<Radio color="primary" />}
                    label="Terminate"
                  />
                </RadioGroup>
              </FormControl>
              <Typography variant="body2" className={classes.helperCustom}>
              </Typography>
            </Grid>

            <Grid item xs={6}></Grid>
          </Grid>

          {/* <NotificationAlert
            notificationType={"info"}
            showNotification={true}
            message={props.notes}
          /> */}
          {ipListDisabled ? (
            <FormControl
              fullWidth
              variant="filled"
              className={classes.customDropDownStyle}
            >
              <InputLabel disableAnimation shrink={false}>
                {props.offeringType} will provision with the selected IP
              </InputLabel>
              <Select
                disableUnderline
                onChange={handleIpChange}
                inputProps={{
                  name: "reservedIps",
                }}
                required
                value={ip}
              >
                <MenuItem disabled value="">
                  Please select...
                </MenuItem>
                {props?.reservedIp?.reservedIps.map((menuItemVal, index) => {
                  return (
                    <MenuItem key={index} value={menuItemVal}>
                      {menuItemVal}
                    </MenuItem>
                  );
                })}
              </Select>
              {error && (
                <FormHelperText className={classes.errorText}>
                  This field is required!
                </FormHelperText>
              )}
            </FormControl>
          ) : null}
          {!btnDisabled ? (
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              size="large"
              fullWidth
              onClick={LaunchLabReservedIp}
              disableFocusRipple
              className={classes.buttStyle}
              type="submit"
            >
              Provision
            </Button>
          ) : null}
          {btnDisabled ? (
            <Loader
              type={LOADER_TYPE}
              color={theme.palette.secondary.main}
              width={50}
              height={50}
              style={{ float: "right", marginRight: "15px" }}
            />
          ) : null}

          <FormHelperText></FormHelperText>

          <NotificationAlert
            notificationType={notificationType}
            showNotification={showNotification}
            message={notificationMessage}
          />
        </GridItem>
      </GridContainer>
    </Modal>
  );
};

export default AlertDialog;
