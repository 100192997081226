
import React, { Fragment, useState, useEffect } from "react";
import { SECONDARY_COLOR } from "global_constants/app_constants";
import Button from "@material-ui/core/Button";
// Formik

import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Api, Urls } from "config/api";

import Loader from "react-loader-spinner";
import { LOADER_TYPE } from "global_constants/app_constants";
import "views/ServiceOffering/LearningLab/acess labs/Overview.scss";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import Axios from "axios";
import theme from "theme";
import { Alert } from "@material-ui/lab";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

const inputProps = {
  disableAnimation: true,
  shrink: false
};

const formControlProps = {
  root: {
    marginTop: "10px"
  }
};

const customStyles = theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3)
  },
  modalCloseIcon: {
    position: "absolute",
    marginTop: "-83px",
    marginLeft: "45px",
    cursor: "pointer",
    color: "#FFFFFF",
    fontSize: "2rem"
  },
  datetime: {
    color: SECONDARY_COLOR
  },
  multilineColor: {
    color: "#7D7D7D"
  },
  form: {
    width: "100%"
  },
  btnColor: {
    background: theme.palette.buttonColor.main,
    "&:hover": {
      background: theme.palette.buttonColor.main
    }
  },
  font: {
    fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    textAlign:'center'
  },
  purposeDropdown: {
    width: "100%",
    marginTop: "5px"
  },
  zoneDropdown: {
    width: "100%",
    marginTop: "5px"
  },
  ipAddressDropdown: {
    width: "100%",
    marginTop: "5px"
  },
  helperText: {
    margin: "10px 0",
    color: "#7D7D7D"
  },
  helperCustom: {
    color: "#7D7D7D",
    margin: "10px 0 1px"
  }
});

const ResumeLearningsModel = props => {
  const useStyles = makeStyles(customStyles);
  const classes = useStyles();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [inputData, setInputData] = useState({});

  const [purpose, setPurpose] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [menuItemsVals2, setMenuItemsVals2] = useState([]);
  let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
  let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
  let hasDomain = domain?.some(res => userEmail.includes(res));
  const [isInternal, setIsInternal] = useState(hasDomain);
  const env = localStorage.getItem("ENVIRONMENT");
  const hideModal = () => {
    props.hideModal();
  };
  const setConfirmModalClose = () => {
    setConfirmModal(false);
    setBtnDisabled(false);
  };
  useEffect(() => {
    let available_purposes = JSON.parse(
      localStorage.getItem("available_purposes") 
    );
    if (isInternal) {
      if (available_purposes?.internal) {
        let purposes = available_purposes.internal.map(item => {
          return <option value={item}>{item}</option>;
        });
        setMenuItems([<option value={null}>None</option>, ...purposes]);
      }
      var deal_id = JSON.parse(localStorage.getItem("deal_ids"));
      let deals = deal_id.map(item => {
        return <option value={item}>{item}</option>;
      });
      setMenuItemsVals2([<option value={null}>None</option>, ...deals]);
    } else {
      if (available_purposes?.external) {
        let purposes = available_purposes.external.map(item => {
          return <option value={item}>{item}</option>;
        });
        setMenuItems([<option value={null}>None</option>, ...purposes]);
      }
    }

  }, []);



  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      name:props?.addHoursDetails?.labName,
      purpose: "",
      action: "terminate",
      ip_type: "default",
      ip_address: "",
      deal_id: "",
      instance:"standard"
    },
    onSubmit: values => {

      setShowNotification(false);
      setBtnDisabled(true);
        // required datetime in format '%Y-%m-%d %H:%M'
      
        const data = {
          uuid: props.addHoursDetails.uuid,
          resource_type:
            props.offeringType === "learninglabs" ? "learning" : "sales_demo",
          identifier: props.addHoursDetails.labIdentifier,

          auto_terminate: "False",
          purpose: values.purpose,
          instance_type:values.instance,
          action: "resume",
        };
        if (values.purpose == "Mint") {
          data["deal_id"] = values.deal_id;
        }

        SDCloudBackendAPIWithToken()
          .post(Api.modifyCluster, data)
          .then(res => {
            setShowNotification(true);
            setNotificationMessage(res.data.message);
            setNotificationType("success");
            setBtnDisabled(false);
            // hideModal();
            window.location.reload();
          })
          .catch(err => {
            setShowNotification(true);
            setNotificationMessage(err.response.data.message);
            setNotificationType("error");
            setBtnDisabled(false);
          });
      
    },
    validate: values => {
      let errors = {};

      if ((values.purpose == "" || values.purpose == "None") && isInternal) {
        errors.purpose = "Required";
      } else if (values.purpose == "" && !isInternal) {
        values.purpose = JSON.parse(
          localStorage.getItem("available_purposes")
        ).external[0];
      }
      if (values.purpose == "Mint" && (values.deal_id=="" || values.deal_id == "None")){
        errors.deal_id = "Required";
      }
      return errors;
    }
  });
  return (
    // calendar modal for schedule lab
    <Modal
      // disablePortal
      // disableEnforceFocus
      disableAutoFocus
      open={!props.openModal}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      className={classes.modal}
      onClose={() => hideModal()}
      // container={() => rootRef.current}
      disableBackdropClick
    >
      <GridContainer
        justify="center"
        alignItems="center"
        className={classes.paper}
      >
        <GridItem xs={12} sm={12} md={12} lg={12}>

          {props.offeringType === "learninglabs" ? (
            <div className={`modal-title ${classes.font}`}>
              {"Resume Learning"}
            </div>
          ) : (
            <div
              className={`modal-title ${classes.font}`}
            >{`Resume ${props.offeringType}`}</div>
          )}
        </GridItem>
        <GridContainer justify="flex-end">
          <GridItem xs={12} sm={1} md={1} lg={1}>
            {" "}
            <CloseIcon
              className={classes.modalCloseIcon}
              onClick={() => hideModal()}
            />
          </GridItem>{" "}
        </GridContainer>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.helperCustom}>
                  Name
                </Typography>
                <TextField
                  type="text"
                  variant="filled"
                  // label="Name"
                  name="name"
                  disabled={true}
                  title="This field is not editable"
                  fullWidth
                  inputProps={{
                    className: classes.multilineColor
                  }}
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={inputProps}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              {isInternal ? (
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.helperCustom}>
                    Purpose
                  </Typography>
                  {
                    <FormControl
                      variant="filled"
                      className={(classes.formControl, classes.purposeDropdown)}
                    >
                      {formik.errors.purpose && formik.touched.purpose ? (
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          fullWidth
                          name="purpose"
                          value={formik.values.purpose}
                          onChange={formik.handleChange}
                          native
                          error
                          helperText={formik.errors.purpose}
                          className={classes.purposeDropdown}
                        >
                          {menuItems.length > 0 ? (
                            menuItems
                          ) : (
                            <MenuItem value="">None</MenuItem>
                          )}
                        </Select>
                      ) : (
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          fullWidth
                          name="purpose"
                          value={formik.values.purpose}
                          onChange={event => {
                            setPurpose(event.target.value);
                            formik.setFieldValue(
                              "purpose",
                              event.target.value
                            );
                          }}
                          native
                          className={classes.purposeDropdown}
                        >
                          {menuItems.length > 0 ? (
                            menuItems
                          ) : (
                            <MenuItem value="">None</MenuItem>
                          )}
                        </Select>
                      )}
                    </FormControl>
                  }
                </Grid>
              ) : null}
              {isInternal && formik.values.purpose == "Mint" ? (
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.helperCustom}>
                    Select Deal id
                  </Typography>
                  {
                    <FormControl
                      variant="filled"
                      className={(classes.formControl, classes.purposeDropdown)}
                    >
                      {formik.errors.deal_id && formik.touched.deal_id ? (
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          fullWidth
                          name="deal_id"
                          value={formik.values.deal_id}
                          onChange={formik.handleChange}
                          native
                          error
                          helperText={formik.errors.deal_id}
                          className={classes.purposeDropdown}
                        >
                          {menuItemsVals2.length > 0 ? (
                            menuItemsVals2
                          ) : (
                            <MenuItem value="">None</MenuItem>
                          )}
                        </Select>
                      ) : (
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          fullWidth
                          name="deal_id"
                          value={formik.values.deal_id}
                          onChange={formik.handleChange}
                          native
                          className={classes.purposeDropdown}
                        >
                          {menuItemsVals2.length > 0 ? (
                            menuItemsVals2
                          ) : (
                            <MenuItem value="">None</MenuItem>
                          )}
                        </Select>
                      )}
                    </FormControl>
                  }
                </Grid>
              ) : null}

              {isInternal && env!=="production"?
              (<Grid item xs={12}>
                              <Typography
                                variant="body2"
                                className={classes.helperCustom}
                              >
                                Instance type
                              </Typography>

                              <FormControl component="fieldset">
                                <RadioGroup
                                  row
                                  aria-label="ip_type"
                                  name="instance"
                                  value={formik.values.instance}
                                  className={classes.radioButton}
                                  onChange={formik.handleChange}
                                >
                                  <FormControlLabel
                                    value="standard"
                                    control={<Radio color="primary" />}
                                    label="Standard"
                                  />
                                  <FormControlLabel
                                    value="spot"
                                    control={<Radio color="primary" />}
                                    label="Spot"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
              ) : null}
              <Grid item xs={6}>
                <Typography className={classes.helperCustom}>
                  End action
                </Typography>

                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="ip_type"
                    name="action"
                    value={formik.values.action}
                    className={classes.radioButton}
                    onChange={formik.handleChange}
                  >
                    <FormControlLabel
                      disabled={true}
                      value="suspend"
                      title="Suspend feature is not available for this product"
                      control={<Radio color="primary" />}
                      label="Suspend"
                    />
                    <FormControlLabel
                      value="terminate"
                      control={<Radio color="primary" />}
                      label="Terminate"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

            </Grid>
            {!btnDisabled ? (
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                size="large"
                fullWidth
                disableFocusRipple
                type="submit"
                className={classes.btnColor}
              >
                Resume
              </Button>
            ) : null}
            {btnDisabled ? (
              <Loader
                type={LOADER_TYPE}
                color={theme.palette.secondary.main}
                width={50}
                height={50}
                style={{ textAlign: "center" }}
              />
            ) : null}

          </GridItem>
        </form>
        {showNotification ? (
          <Alert
            onClose={() => setShowNotification(false)}
            severity={notificationType}
          >
            {notificationMessage}
          </Alert>
        ) : null}
    
      </GridContainer>
    </Modal>
  );
};

export default ResumeLearningsModel;
