import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";

// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Drawer from "@material-ui/core/Drawer";
import SearchIcon from "@material-ui/icons/Search";
import Select from "@material-ui/core/Select";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Card } from "@material-ui/core";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Muted from "components/Typography/Muted";
import { PRIMARY_COLOR } from "global_constants/app_constants";
import "./Profile.scss";
import { Animated } from "react-animated-css";
import ReactTable from "react-table";
import { SHOW_LOADER, HIDE_LOADER } from "action_creators/actioncreator";
import CustomDropdown from "./CustomDropDownWrapper";
import CustomAutocompleteFilter from "./CustomAutocompleteFilter";
import LogDetailsPagination from "./LogDetailsPagination";
import Pagination from "./PaginationWrapper";
import LogsTableWrapper from "./LogsTableWrapper";

// React specific library imports
import { Api } from "config/api";
import { FilledInput } from "@material-ui/core";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import theme from "../../../../theme";
const customStyles = theme => ({
  containerStyles: {
    padding: "0.9375rem 70px"
  },
  inputStyles: {
    width: "100%",
    padding: "0 15px !important",
    [theme.breakpoints.down("sm")]: {
      margin: "15px"
    }
  },
  inputLabelStyles: {
    marginBottom: "35px",
    position: "relative"
  },
  cardStyles: {
    color: "rgba(0, 0, 0, 0.87)",
    width: "100%",
    border: "0",
    display: "flex",
    position: "relative",
    fontSize: ".875rem",
    minWidth: "0",
    wordWrap: "break-word",
    background: "#FFF",
    marginTop: "30px",
    borderRadius: "6px",
    marginBottom: "30px",
    flexDirection: "column"
  },
  cardHeaderStyles: {
    padding: "0.9375rem 65px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative"
  },
  cardBodyStyles: {
    padding: "0.9375rem 65px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative"
  },
  cardFooterStyles: {
    padding: "0.9375rem 65px",
    margin: "35px 0 35px 0",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative"
  },
  drawerContainer: {
    left: "35%"
  },
  headerTitle: {
    fontSize: "24px",
    padding: "15px 65px",
    marginBottom: "40px",
    fontFamily: theme.palette.fontList.selectedFont
  }
});

const TABLE_HEADERS = [
  {
    Header: "Log Type",
    accessor: "logType",
    headerStyle: { fontSize: "14px" },
    Cell: row => (
      <div>
        <p style={{ fontSize: "15px", fontWeight: "normal" }}>
          {row.original.logType}
        </p>
      </div>
    )
  },
  {
    Header: "Most Recent",
    accessor: "mostRecent",
    headerStyle: { fontSize: "14px" },
    Cell: row => (
      <div>
        <p style={{ fontSize: "15px", color: "#8F8A8A" }}>
          {row.original.mostRecent}
        </p>
      </div>
    )
  },
  {
    Header: "Least Recent",
    accessor: "leastRecent",
    headerStyle: { textAlign: "left", fontSize: "14px" },
    Cell: row => (
      <div>
        <p style={{ fontSize: "15px", color: "#8F8A8A" }}>
          {row.original.leastRecent}
        </p>
      </div>
    )
  }
];

class LogWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataTable: [],
      searchRows: [],
      isTableLoaded: false,
      showRightDrawer: false,
      tableOpacity: "",
      logAction: "",
      selectFilters: {
        offeringType: ["learning", "sales demo", "poc", "designer", "None"],
        level: ["info", "critical"]
      },
      unselectFilters: {
        offeringType: [],
        level: []
      }
    };
  }

  // Remove border from React table rows
  getRowProps = () => {
    return {
      style: {
        borderBottom: "none",
        cursor: "pointer"
      }
    };
  };

  getFilterLogs = () => {
    this.setState({ tableOpacity: 0.3 });
    let appliedFilters = [];
    Object.keys(this.state.unselectFilters).forEach(key => {
      return this.state.unselectFilters[key].length
        ? appliedFilters.push(
            `${key}:"${this.state.unselectFilters[key].join(",")}"`
          ) // explode array and create string
        : null;
    });
    const data = appliedFilters.length
      ? {
          query: `{ logRecords(first:0,last:0, name:"${localStorage.getItem(
            "userName"
          )}", ${appliedFilters.join(
            ", "
          )}){ createdAt, action, privilege, requestUser, level, msg, ip, location, browser, offeringType }}`
        }
      : {
          query: `{ logRecords(first:0,last:0, name:"${localStorage.getItem(
            "userName"
          )}"){ createdAt, action, privilege, requestUser, level, msg, ip, location, browser, offeringType }}`
        };

    SDCloudBackendAPIWithToken()
      .post(Api.logRecord, data)
      .then(res => {
        // Stop loader only once when page is getting reloaded
        if (!appliedFilters.length) {
          this.props.dispatch(HIDE_LOADER());
        }
        const finaltable = this.buildTable({
          apiData: this.build2DArray(res.data["data"]["logRecords"])
        });
        this.setState({
          dataTable: finaltable,
          searchRows: finaltable,
          isTableLoaded: true,
          tableOpacity: 1
        });
      })
      .catch(err => {
        //retry if the call fails due to large number of records
        SDCloudBackendAPIWithToken()
            .post(Api.logRecord, data)
            .then(res => {
              // Stop loader only once when page is getting reloaded
              if (!appliedFilters.length) {
                this.props.dispatch(HIDE_LOADER());
              }
              const finaltable = this.buildTable({
                apiData: this.build2DArray(res.data["data"]["logRecords"])
              });
              this.setState({
                dataTable: finaltable,
                searchRows: finaltable,
                isTableLoaded: true,
                tableOpacity: 1
              });
            })
            .catch(err => {
              if (!appliedFilters.length) {
                this.props.dispatch(HIDE_LOADER());
              }
              this.setState({ isTableLoaded: true, tableOpacity: 1 });
            });
      });
  };

  build2DArray = data => {
    // Return null arrray if no logrecords
    if (data.length === 0) {
      return [];
    }
    // Sort log records in recently createdAt timestamp
    data.sort((a, b) => {
      let createdA = new Date(a.createdAt);
      let createdB = new Date(b.createdAt);
      if (createdA > createdB) return -1;
      if (createdA < createdB) return 1;
      return 0;
    });
    // Group log records with duplicate actions in separate groups
    let groupByObj = data.reduce((accum, val) => {
      (accum[val.action] = accum[val.action] || []).push(val.createdAt);
      return accum;
    }, {});
    // Create final index array containing <action>, <startingTimeRange> and <endingTimeRange>
    let uniqueLogRecords = [];
    Object.keys(groupByObj).forEach(key => {
      let _1Darr = [];
      _1Darr[0] = key;
      _1Darr[1] = new Date(groupByObj[key][0]);
      _1Darr[1] = _1Darr[1].toLocaleString();
      _1Darr[2] = new Date(groupByObj[key][groupByObj[key].length - 1]);
      _1Darr[2] = _1Darr[2].toLocaleString();
      uniqueLogRecords.push(_1Darr);
    });

    return uniqueLogRecords;
  };

  buildTable = data => {
    const tableFormat = {
      dataRows: data.apiData
    };
    return tableFormat.dataRows.map((prop, key) => {
      return {
        logType: prop[0],
        mostRecent: prop[1],
        leastRecent: prop[2],
        actions: null
      };
    });
  };

  componentDidMount() {
    // this.props.dispatch(SHOW_LOADER());
    this.getFilterLogs();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.profile.profileObj.username !==
      this.props.profile.profileObj.username
    ) {
      this.getFilterLogs();
    }
  }

  handleDeleteChip = (filterType, chip) => {
    if (filterType === "offeringType") {
      this.setState(
        prevState => ({
          // update the selectFilters and unselectFilters array for Offering Type
          selectFilters: {
            ...prevState.selectFilters,
            offeringType: this.state.selectFilters.offeringType.push(chip)
              ? this.state.selectFilters.offeringType
              : []
          },
          unselectFilters: {
            ...prevState.unselectFilters,
            offeringType: this.state.unselectFilters.offeringType.filter(
              item => {
                return item !== chip;
              }
            )
          }
        }),
        () => this.getFilterLogs()
      );
    } else if (filterType === "level") {
      this.setState(
        prevState => ({
          // update the selectFilters and unselectFilters array for Level
          selectFilters: {
            ...prevState.selectFilters,
            level: this.state.selectFilters.level.push(chip)
              ? this.state.selectFilters.level
              : []
          },
          unselectFilters: {
            ...prevState.unselectFilters,
            level: this.state.unselectFilters.level.filter(item => {
              return item !== chip;
            })
          }
        }),
        () => this.getFilterLogs()
      );
    }
  };

  toggleRightDrawer = logAction => {
    this.setState({
      showRightDrawer: !this.state.showRightDrawer,
      logAction: logAction
    });
  };

  renderChip = (filterType, chip, index) => {
    return (
      <div className="filterchip" key={index}>
        {`${chip}`}
        <span
          className="filter-closebtn"
          onClick={() => this.handleDeleteChip(filterType, chip)}
        >
          &times;
        </span>
      </div>
    );
  };

  handleSearchRequest = event => {
    let searchedVal = event.target.value; //string
    let filteredRows = this.state.dataTable.filter(row => {
      return row.logType.toLowerCase().includes(searchedVal.toLowerCase());
    });
    this.setState({
      searchRows: filteredRows
    });
    // searchRows
  };

  onClickOfferingHandler = offeringName => {
    this.setState(
      prevState => ({
        // update the selectFilters and unselectFilters array
        selectFilters: {
          // object that we want to update
          ...prevState.selectFilters, // keep key-value pairs of previous state
          offeringType: this.state.selectFilters.offeringType.filter(item => {
            return item !== offeringName;
          })
        },
        unselectFilters: {
          // object that we want to update
          ...prevState.unselectFilters, // keep key-value pairs of previous state
          offeringType: this.state.unselectFilters.offeringType.push(
            offeringName
          )
            ? this.state.unselectFilters.offeringType
            : []
        }
      }),
      () => this.getFilterLogs()
    );
  };

  onSelectFilterHandler = (filterType, value) => {
    if (value) {
      this.setState(
        prevState => ({
          // update the selectFilters and unselectFilters array
          selectFilters: {
            ...prevState.selectFilters,
            [filterType]: this.state.selectFilters[filterType].filter(item => {
              return item !== value;
            })
          },
          unselectFilters: {
            ...prevState.unselectFilters,
            [filterType]: this.state.unselectFilters[filterType].push(value)
              ? this.state.unselectFilters[filterType]
              : []
          }
        }),
        () => this.getFilterLogs()
      );
    } else {
      this.setState(
        prevState => ({
          // update the selectFilters and unselectFilters array
          selectFilters: {
            ...prevState.selectFilters,
            ...prevState.unselectFilters
          },
          unselectFilters: {
            level: [],
            offeringType: []
          }
        }),
        () => this.getFilterLogs()
      );
    }
  };

  render() {
    const { classes } = this.props;
    if (this.state.isTableLoaded) {
      return (
        <div>
          <GridContainer className={classes.containerStyles}>
            <Card className={classes.cardStyles} elevation={0}>
              <GridContainer>
                <GridItem xs={12}>
                  <h3 className={classes.headerTitle}>
                    <b>Activity logs</b>
                  </h3>
                </GridItem>
              </GridContainer>
              <GridContainer justify="flex-end" alignItems="flex-end">
                <GridItem
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  style={{ marginBottom: "-54px" }}
                >
                  <CustomAutocompleteFilter
                    handleAddFilter={this.onSelectFilterHandler}
                    handleRemoveFilter={this.handleDeleteChip}
                  />
                </GridItem>
              </GridContainer>
              <LogsTableWrapper
                key={this.state.dataTable || this.state.searchRows}
                logRecords={this.state.dataTable}
                callback={this.toggleRightDrawer}
                onSelectFilterHandler={this.onSelectFilterHandler}
                handleDeleteChip={this.handleDeleteChip}
                handleSearchRequest={this.handleSearchRequest}
                tableOpacity={this.state.tableOpacity}
              />
            </Card>
          </GridContainer>
          <GridContainer>
            {this.state.showRightDrawer && this.state.logAction && (
              <Drawer
                anchor={"right"}
                open={this.state.showRightDrawer}
                // className={classes.drawerContainer}
                classes={{
                  paper: classes.drawerContainer
                }}
                onClose={() => this.toggleRightDrawer("")}
              >
                <LogDetailsPagination
                  logAction={this.state.logAction}
                  unselectFilters={this.state.unselectFilters}
                />
                {/* {generateDrawerComponent(currentMenu)} */}
              </Drawer>
            )}
          </GridContainer>
        </div>
      );
    } else {
      return (
        <div>
          <GridContainer justify="center">
            <Loader
              type="ThreeDots"
              color={theme.palette.secondary.main}
              height={500}
              width={50}
              visible={true}
            />
          </GridContainer>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    profile: state.profileObj
  };
};

LogWidget.propTypes = {
  classes: PropTypes.object.isRequired
};

// const dispatchToProps =
const LogWidgetConnect = withRouter(LogWidget);
export default connect(mapStateToProps)(
  withStyles(customStyles)(LogWidgetConnect)
);