import React from "react";
import styled from "styled-components";
import {
  useTable,
  usePagination,
  useRowSelect,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce
} from "react-table";
import { Animated } from "react-animated-css";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Card } from "@material-ui/core";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import { FilledInput } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Button } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import { secondaryColor } from "assets/jss/material-dashboard-pro-react";
import { v4 as uuidv4 } from "uuid";
import CustomAutocompleteFilter from "./CustomAutocompleteFilter";
import { paginationBtnActiveColor } from "assets/jss/material-dashboard-pro-react";

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    background-color: white;
    width: 100%;
    tr {
      cursor: pointer;
    }

    th {
      font-weight: normal;
      font-size: 14px;
      color: #9a9a9a;
      padding: 20px 8px 8px 20px;
    }

    td {
      margin: 0;
      padding: 15px;
      padding-left: 20px;
      border-bottom: 1px solid #c2c9d1;

      :last-child {
        border-right: 0;
      }
    }
  }

  .table-checkbox {
    display: flex;
    justify-content: center;
  }

  .pagination {
    padding: 0.5rem;
  }
`;

const useStyles = makeStyles(theme => ({
  containerStyles: {
    padding: "0.9375rem 70px"
  },
  inputStyles: {
    width: "100%",
    padding: "0 15px !important",
    [theme.breakpoints.down("sm")]: {
      margin: "15px"
    }
  },
  inputLabelStyles: {
    marginBottom: "35px",
    position: "relative"
  },
  cardStyles: {
    color: "rgba(0, 0, 0, 0.87)",
    width: "100%",
    border: "0",
    display: "flex",
    position: "relative",
    fontSize: ".875rem",
    minWidth: "0",
    wordWrap: "break-word",
    background: "#FFF",
    marginTop: "30px",
    borderRadius: "6px",
    marginBottom: "30px",
    flexDirection: "column"
  },
  gridItemStyle: {
    padding: "0px !important",
    marginBottom: "20px"
  },
  cardBodyStyles: {
    padding: "0.9375rem 65px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative"
  },
  cardFooterStyles: {
    padding: "0.9375rem 65px",
    margin: "35px 0 35px 0",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative"
  },
  drawerContainer: {
    left: "35%"
  },
  font:{
    fontFamily: theme.palette.fontList.selectedFont
  }
}));

const SearchBar = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  useAsyncDebounce
}) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  const classes = useStyles();

  return (
    <FilledInput
      value={value || ""}
      onChange={e => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      disableUnderline={true}
      className={classes.inputStyles}
      endAdornment={<SearchIcon />}
    ></FilledInput>
  );
};

function TableWrapper({ columns, data, toggleRightDrawer, props }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    preGlobalFilteredRows,
    state,
    setGlobalFilter,
    state: { pageIndex, pageSize, selectedRowIds }
  } = useTable(
    {
      columns,
      data
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const classes = useStyles();
  return (
    <>
      {/* <CardHeader className={classes.cardHeaderStyles}> */}
      <GridContainer>
        <GridContainer style={{ paddingLeft: "65px" }}>
          <GridItem
            xs={12}
            sm={5}
            md={5}
            lg={5}
            className={classes.gridItemStyle}
          >
            <SearchBar
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
              useAsyncDebounce={useAsyncDebounce}
            ></SearchBar>
          </GridItem>
          {/* <GridItem xs={12} sm={6} md={6} lg={6}>
              <CustomAutocompleteFilter
                handleAddFilter={props.onSelectFilterHandler}
                handleRemoveFilter={props.handleDeleteChip}
              /> */}

          {/* <TableHeader
            handleButtonClick={handleButton}
            handleDeleteButtonClick={handleDeleteButton}
            buttonTitle={buttonTitle}
            dropDownValues={dropDownValues}
            handleDropDownChange={handleDropDownChange}
            showDropdown={showDropdown}
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            useAsyncDebounce={useAsyncDebounce}
          /> */}
          {/* </GridItem> */}
        </GridContainer>
      </GridContainer>
      {/* </CardHeader> */}

      <CardBody className={classes.cardBodyStyles}>
        <GridItem xs={12} sm={12} style={{ opacity: props.tableOpacity }}>
          <Animated
            animationIn="fadeIn"
            animationOut="fadeOut"
            animationInDuration={1000}
            animationOutDuration={1000}
            isVisible={true}
          >
            <>
              <Table
                // className={classes.table}
                aria-label="customized table"
                {...getTableProps()}
              >
                <TableHead>
                  {headerGroups.map(headerGroup => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start"
                            }}
                          >
                            <span>{column.render("Header")}</span>
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <ArrowDropDownIcon />
                                ) : (
                                  <ArrowDropUpIcon />
                                )
                              ) : (
                                <UnfoldMoreIcon
                                  style={{
                                    fontSize: "12px",
                                    verticalAlign: "middle"
                                  }}
                                />
                              )}
                            </span>
                          </div>
                        </th>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody>
                  {page.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        onClick={() => {
                          toggleRightDrawer(row.original);
                        }}
                      >
                        {row.cells.map(cell => {
                          return (
                            <td className={classes.font} {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </TableBody>
              </Table>

              <div className="pagination">
                <Select
                  value={pageSize}
                  className="show-item-dropdown"
                  onChange={e => {
                    setPageSize(Number(e.target.value));
                  }}
                >
                  {[5, 10, 15, 20, 25, 30].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      {pageSize} Items
                    </option>
                  ))}
                </Select>
                <span className={classes.font} style={{ marginLeft: "13px", color: secondaryColor }}>
                  per page
                </span>
                <span>
                  <Button
                    style={{
                      fontWeight: 300,
                      float: "right",
                      color: secondaryColor,
                      marginLeft: "5px"
                    }}
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                  >
                    {"Next"}
                  </Button>{" "}
                </span>
                <span style={{ float: "right" }}>
                  {Array.from(Array(8 > pageCount ? pageCount : 8)).map(
                    (_, index) => {
                      return (
                        <Button
                          style={{
                            fontWeight: 300,
                            backgroundColor:
                              index === pageIndex
                                ? paginationBtnActiveColor
                                : "white",
                            color:
                              index === pageIndex ? "white" : secondaryColor,
                            marginRight: "2px",
                            minWidth: "24px",
                            height: "24px",
                            borderRadius: 0
                          }}
                          key={uuidv4()}
                          onClick={() => gotoPage(index)}
                        >
                          {index + 1}
                        </Button>
                      );
                    }
                  )}
                  {pageCount > 8 && pageIndex + 1 > 8 ? (
                    <Button
                      style={{
                        fontWeight: 300,
                        backgroundColor: paginationBtnActiveColor,
                        color: "white",
                        marginRight: "2px",
                        marginLeft: "5px",
                        minWidth: "24px",
                        height: "24px",
                        borderRadius: 0
                      }}
                    >
                      {pageIndex + 1}
                    </Button>
                  ) : null}
                </span>
                <span>
                  <Button
                    style={{
                      fontWeight: 300,
                      float: "right",
                      color: secondaryColor,
                      marginRight: "25px"
                    }}
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                  >
                    {"Previous"}
                  </Button>{" "}
                </span>
              </div>
            </>
          </Animated>
        </GridItem>
      </CardBody>
    </>
  );
}

function LogsTableWrapper(props) {
  const data = React.useMemo(() => props.logRecords, []);
  const classes = useStyles();
  const columns = React.useMemo(
    () => [
      {
        Header: <p className={classes.font} style={{ textAlign: "start" }}>Log Type</p>,
        accessor: "logType" // accessor is the "key" in the data
      },
      {
        Header: <p className={classes.font} style={{ textAlign: "start" }}>Most Recent</p>,
        accessor: "mostRecent"
      },
      {
        Header: <p className={classes.font} style={{ textAlign: "start" }}>Least Recent</p>,
        accessor: "leastRecent"
      }
    ],
    []
  );

  return (
    <Styles>
      <TableWrapper
        columns={columns}
        data={data}
        toggleRightDrawer={props.callback}
        props={props}
      />
    </Styles>
  );
}

export default LogsTableWrapper;
