import React, { useEffect, useState } from "react";

// Material UI Components
import { Alert } from "@material-ui/lab";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { useFormik } from "formik";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
// Icons
import Visibility from "@material-ui/icons/Visibility";

// Local
import CustomTable from "./Table";
import { SDCloudBackendAPIWithToken } from "../../../apis/backendAPI";
import { IconButton } from "@material-ui/core";
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import RightDrawer from "./RightDrawer";
import AddUser from "./AddUser";
import UserDetails from "./UserDetails";
import { Add } from "@material-ui/icons";

// Styling
const useStyles = makeStyles((theme) => ({
  root: {
    // padding: "30px 20px",
  },
  container: {
    padding: "40px",
    backgroundColor:"white"
  },
}));
const inputProps = {
  disableAnimation: true,
  shrink: false,
};
const CreateDealID = (props) => {
  const classes = useStyles();
  // States
  const [rows, setRows] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentScreen, setCurrentScreen] = useState();
  const [currentUser, setCurrentUser] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const profileData = JSON.parse(localStorage.getItem("profileData"));
  const account = profileData.account;
  const formik = useFormik({
    initialValues: {
      dealid: "",
      account:"",
    },
    onSubmit: (values) => {
      let payload = {
        deal_id: values.dealid,
        account:account
      };
      //console.log(payload,"payload")
      setShowLoader(true)
      SDCloudBackendAPIWithToken()
        .post("/create_deal_id/", payload)
        .then((resp) => resp.data)
        .then((data) => {
          setShowNotification(true);
          setNotificationType("success");
          setNotificationMessage("Deal ID created successfully");
          setShowLoader(false)

          // props.resetapi();
        })
        .catch((err) => {
          let message = err.response.data.message;
          setShowNotification(true);
          setNotificationType("error");
          setNotificationMessage(message);
          setShowLoader(false)
        });
    },
    validate: (values) => {
      let errors = {};

      let chkName = /^[a-zA-Z0-9-\s]+$/
      if (!chkName.test(String(values.dealid))) {
        errors.dealid =
          "Deal Id should contain alpha numeric characters with -";
      }
      if (!values.dealid) {
        errors.dealid = "Required";
      }

      return errors;
    },
  });

  return (
    <div className={classes.container}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container className={classes.gridContainer}>
          <Grid item md={12}>
            <Typography variant="h4">Create New Deal Id</Typography>
          </Grid>
              <Grid item md={12}>
                <Grid container justify="space-between">
                  <Grid item xs={12} md={5}>
                    {formik.errors.dealid && formik.touched.dealid ? (
                      <TextField
                        type="text"
                        variant="filled"
                        label="Deal ID"
                        name="dealid"
                        fullWidth
                        InputLabelProps={inputProps}
                        value={formik.values.dealid}
                        onChange={formik.handleChange}
                        helperText={formik.errors.dealid}
                        onBlur={formik.handleBlur}
                        error
                      />
                    ) : (
                      <TextField
                        type="text"
                        variant="filled"
                        label="Deal ID"
                        name="dealid"
                        fullWidth
                        InputLabelProps={inputProps}
                        value={formik.values.dealid}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    )}
                  </Grid>
                  </Grid>
                  {showLoader ? (
                <div 
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "80px"
                }}>
                <LoaderHoc isLoading={showLoader}></LoaderHoc>
              </div>
              ):(
                <>
                   <Grid item xs={10} md={2}>
                <Button
                  variant="outlined"
                  color="secondary"
                  disableElevation
                  size="large"
                  fullWidth
                  disableFocusRipple
                  type="submit"
                  style={{
                    marginTop: "50px"
                  }}
                  className={classes.button + " " + classes.buttOutlined}
                >
                  Submit
                </Button>
              </Grid>
                </>
        
              )}
              
             
              <Grid item xs={12} md={12}>
                {showNotification ? (
                  <Alert
                    onClose={() => setShowNotification(false)}
                    severity={notificationType}
                  >
                    {notificationMessage}
                  </Alert>
                ) : null}
                </Grid>
                  </Grid>
                  </Grid>
                  </form>
                  </div>
);
  
};

export default CreateDealID;