import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// Local
import Menu from "../../Components/Menu";
import { SDCloudBackendAPIWithToken } from "../../../apis/backendAPI";
import { CMSAPIWithToken } from "../../../apis/CMS";
import { useDispatch } from "react-redux";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import ShowcaseView from "./ShowcaseView";
import ShowcaseUpdate from "./ShowcaseUpdate";
import IntroductionView from "./IntroductionView";
import IntroductionUpdate from "./IntroductionUpdate";
import CheatSheetsView from "./CheatSheetsView";
import CheatSheetsUpdate from "./CheatSheetsUpdate";
import UseCaseView from "./UseCaseView";
import UseCaseUpdate from "./UseCaseUpdate";
// Material UI Components
import { Alert } from "@material-ui/lab";
// Icons
import Visibility from "@material-ui/icons/Visibility";
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// Local
import CustomTable from "../Components/Table";
import RightDrawer from "../Components/RightDrawer";
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import { IconButton } from "@material-ui/core";
import ForwardIcon from '@material-ui/icons/Forward';
//import Visibility from "@material-ui/icons/Visibility";
import {hotjar} from "react-hotjar";
import queryString from "query-string";


// Styling
const useStyles = makeStyles(theme => ({
    // root: {
    //     marginTop: "50px"
    // },
    backButton: {
      margin: "15px 0",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "& svg": {
        marginRight: "5px",
      },
      "&:hover": {
        color: theme.palette.secondary.main,
        "& svg": {
          fill: theme.palette.secondary.main,
        },
      },
    },
}));

const ProductContentShowcaseMenuItems = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const profileData = JSON.parse(localStorage.getItem("profileData"));
    const account = profileData.account;
    let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
    let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
    let hasDomain = domain?.some((res) => userEmail.includes(res));
    const [isInternal, setIsInternal] = useState(hasDomain);
    useEffect(() => {
        dispatch(SET_NAVBAR_TITLE(isInternal?account+"  - Content Management  Showcases":"Content Management  Showcases"));
        renderShowcaseIntroduction()
        renderShowcaseCheatSheet()
        renderShowcaseUseCase()


    }, []);
    hotjar.initialize(2281731, 6);
    hotjar.identify(localStorage.userName, { userProperty: localStorage.userName});
    // States
    
    const [showcasesIntroductionRows, setShowcasesIntroductionRows] = useState([]);
    const [showcaseCheatSheetRows, setShowcaseCheatSheetRows] = useState([]);
    const [showcaseUseCaseRows, setShowcaseUseCaseRows] = useState([]);
    const [courseELearningRows, setCourseELearningRows] = useState([]);
    const [learningLabRows, setLearningLabRows] = useState([]);
    const [learningCategoryRows, setLearningCategoryRows] = useState([]);
    const [learningExercisesRows, setLearningExercisesRows] = useState([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [currentInventory, setCurrentInventory] = useState("");
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState([]);
    const [currentScreen, setCurrentScreen] = useState("Introduction");
    const [currentDrawerScreen, setcurrentDrawerScreen] = useState();
    let queryParams = queryString.parse(window.location.search);
    var sb_id = queryParams.id;
    var showcases_id_title_map = JSON.parse(localStorage.getItem("showcases_id_title_map"))
    var showcases_title = showcases_id_title_map[sb_id]
    var learn_cat_id_map = {};
    const [currentUser, setCurrentUser] = useState();
    const [currentCategory, setCurrentCategory] = useState();
    const [notificationType, setNotificationType] = useState("");
  
    const showcaseIntroductionColumns = [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Showcase Name",
        accessor: "admin_sales_demos_id",
      },
      {
        Header: "Created",
        accessor: "created",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ];
    const showcaseCheatSheetcolumns = [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Showcase Name",
        accessor: "admin_sales_demos_id",
      },
      {
        Header: "Created",
        accessor: "created",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ];
    const showcaseUseCasecolumns = [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Showcase Name",
        accessor: "admin_sales_demos_id",
      },
      {
        Header: "Created",
        accessor: "created",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ];
    
    let ShowcasesIntroductionData = [];let showcaseCheatSheetData = [];let showcaseUseCaseData = [];
    var menuItems = ["Introduction", "Cheat Sheets", "Use Case"];
    const backButton = () => {
      history.goBack();
    };
    const [isLoading, setIsLoading] = useState(true);
    const renderNotififications = () => {
        let notifications = notificationMessage.map((item) => {
          let notificationType = item.success ? "success" : "error";
          return (
            <Alert
              onClose={() => setShowNotification(false)}
              severity={notificationType}
              spacing={1}
            >
              {item.message}
            </Alert>
          );
        });
    
        return notifications;
      };

      //Learning Overview API call --- for data featch
      const renderShowcaseIntroduction = async (value) => {
        return CMSAPIWithToken()
        .get("/sales/admin_sales_demos_course_demos/")
        .then((resp) => resp.data.results)
          .then((resp) => {
            resp.forEach((item) => {
              if(item.admin_sales_demos_id== sb_id){
                ShowcasesIntroductionData.push({
                  id: item.id,
                  admin_sales_demos_id: showcases_title,
                  modified: item.modified,
                  created: item.created,
                actions: (
                  <Grid container wrap="wrap" spacing={1}>
                    <IconButton onClick={() => handleIntroductionViewButton(item.id)}>
                      <Visibility />
                    </IconButton>
                    <IconButton onClick={() => handleIntroductionEditButton(item.id)}>
                      <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleIntroductionDeleteButton(item.id)}>
                      <DeleteForeverIcon/>
                    </IconButton>
                  </Grid>
                ),
                });
              }
            });
           
            setShowcasesIntroductionRows(ShowcasesIntroductionData);
            
            setIsLoading(false);
          })
          .catch((err) => {
            let message = err.response.data.message || "unknown error occured";
            setIsLoading(false);
          });
      };


      //Learnings Modules API call --- for data featch
      const renderShowcaseCheatSheet = async (value) => {
        return CMSAPIWithToken()
        .get("/sales/admin_sales_demos_cheat_sheets/")
        .then((resp) => resp.data.results)
          .then((resp) => {
            resp.forEach((item) => {
              if(item.admin_sales_demos_id == sb_id){
              showcaseCheatSheetData.push({
                  id: item.id,
                  admin_sales_demos_id: showcases_title,
                  created: item.created,
                  modified: item.modified,
                actions: (
                  <Grid container wrap="wrap" spacing={1}>
                    <IconButton onClick={() => handlCheatSheetsViewButton(item.id)}>
                      <Visibility />
                    </IconButton>
                    <IconButton onClick={() => handleCheatSheetsEditButton(item.id)}>
                      <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleCheatSheetsDeleteButton(item.id)}>
                      <DeleteForeverIcon/>
                    </IconButton>
                  </Grid>
                ),
                });
              }
            });
           
            setShowcaseCheatSheetRows(showcaseCheatSheetData);
            
            setIsLoading(false);
          })
          .catch((err) => {
            let message = err.response.data.message || "unknown error occured";
            setIsLoading(false);
          });
      };

      //Course Overviews API call --- for data featch
      const renderShowcaseUseCase = async (value) => {
        return CMSAPIWithToken()
        .get("/sales/admin_sales_demos_use_cases/")
        .then((resp) => resp.data.results)
          .then((resp) => {
            resp.forEach((item) => {
              if(item.admin_sales_demos_id == sb_id){
              showcaseUseCaseData.push({
                id: item.id,
                admin_sales_demos_id: showcases_title,
                created: item.created,
                modified: item.modified,
                actions: (
                  <Grid container wrap="wrap" spacing={1}>
                    <IconButton onClick={() => handlUseCaseViewButton(item.id)}>
                      <Visibility />
                    </IconButton>
                    <IconButton onClick={() => handleUseCaseEditButton(item.id)}>
                      <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleUseCaseDeleteButton(item.id)}>
                      <DeleteForeverIcon/>
                    </IconButton>
                  </Grid>
                ),
                });
              }
            });
            
            setShowcaseUseCaseRows(showcaseUseCaseData);
            
            setIsLoading(false);
          })
          .catch((err) => {
            let message = err.response.data.message || "unknown error occured";
            setIsLoading(false);
          });
      };
        //learnings Overview Delete Items 
      const handleIntroductionDeleteButton = (id) => {
        let user_confirm = window.confirm("Do you want to delete introduction?")
        if(user_confirm){
        CMSAPIWithToken()
        .delete("/sales/admin_sales_demos_course_demos"+`/${id}/`)
          .then((resp) => resp.data.results)
          .then((data) => {
          const payload = {
          message: "Introduction Deleted Successfully",
          color: "success",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
          history.push("ProductContentShowcasesMenuItems?id="+sb_id+"&title="+showcases_title);
          // window.location.reload()
          })
          .catch((err) => {
            let message = err.response.data.message;
            const payload = {
              message: message,
              color: "danger",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
        };
      }
//Cheat Sheets Delete Items 
      const handleCheatSheetsDeleteButton = (id) => {
        let user_confirm = window.confirm("Do you want to delete Cheat sheet?")
        if(user_confirm){
        CMSAPIWithToken()
        .delete("/sales/admin_sales_demos_cheat_sheets"+`/${id}/`)
          .then((resp) => resp.data.results)
          .then((data) => {
          const payload = {
          message: "Cheat Sheets Deleted Successfully",
          color: "success",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
          history.push("ProductContentShowcasesMenuItems?id="+sb_id+"&title="+showcases_title);
          // window.location.reload()
          })
          .catch((err) => {
            let message = err.response.data.message;
            const payload = {
              message: message,
              color: "danger",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
        };
      }
//showcase usecase Delete Items 
      const handleUseCaseDeleteButton = (id) => {
      let user_confirm = window.confirm("Do you want to delete Usecase?")
      if(user_confirm){
        CMSAPIWithToken()
        .delete("/sales/admin_sales_demos_use_cases"+`/${id}/`)
          .then((resp) => resp.data.results)
          .then((data) => {
          const payload = {
          message: "UseCase Deleted Successfully",
          color: "success",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
          history.push("ProductContentShowcasesMenuItems?id="+sb_id+"&title="+showcases_title);
          // window.location.reload()
          })
          .catch((err) => {
            let message = err.response.data.message;
            const payload = {
              message: message,
              color: "danger",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
        };
      }

      // view single product details
      // view single product details
      const renderDrawerComponent = () => {
        switch (currentDrawerScreen) {
          //case "Add User":
          //  return <AddUser resetapi={renderuser} />;
          case "Showcase View":
            return <ShowcaseView user={currentUser} />;
          case "Showcase Edit":
            return <ShowcaseUpdate user={currentUser} />;
          case "Introduction View":
            return <IntroductionView user={currentUser} />;  
          case "Introduction Edit":
            return <IntroductionUpdate user={currentUser} />;
          case "CheatSheets View":
            return <CheatSheetsView user={currentUser} />;  
          case "CheatSheets Edit":
            return <CheatSheetsUpdate user={currentUser} />;
          case "UseCase View":
            return <UseCaseView user={currentUser} />;  
          case "UseCase Edit":
            return <UseCaseUpdate user={currentUser} />;                
          case "default":
            return null;
        }
      };

//code for view button
const handleIntroductionViewButton = (username) => {
  setOpenDrawer(true);
  setcurrentDrawerScreen("Introduction View");
  setCurrentUser(username);
};
const handlCheatSheetsViewButton = (username) => {
  setOpenDrawer(true);
  setcurrentDrawerScreen("CheatSheets View");
  setCurrentUser(username);
};
const handlUseCaseViewButton = (username) => {
  setOpenDrawer(true);
  setcurrentDrawerScreen("UseCase View");
  setCurrentUser(username);
};
const handleViewButton = (username) => {
  setOpenDrawer(true);
  setcurrentDrawerScreen("Showcase View");
  setCurrentUser(username);
};
      //Add new Learning , overview , Details ,Cheat Sheets, Labs ,Categories,Exercises
      const handleEditButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Showcase Edit");
        setCurrentUser(username);
      };

      const handleIntroductionEditButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Introduction Edit");
        setCurrentUser(username);
      };
      const handleCheatSheetsEditButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("CheatSheets Edit");
        setCurrentUser(username);
      };
      const handleUseCaseEditButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("UseCase Edit");
        setCurrentUser(username);
      };
      const handleAddUserIntroduction= () => {
        //console.log("---------------------------")
        history.push("/ProductContentShowcases/IntroductionCreate");  
      };
      const handleAddUserCheatSheets = () => {
        history.push("/ProductContentShowcases/CheatSheetsCreate");  
      };
      const handleAddUserUseCase = () => {
        history.push("/ProductContentShowcases/UseCaseCreate");  
      };
     
      
    const renderContent = () => {
        const query=new URLSearchParams(window.location.search);
        const title = query.get("title");
        switch (currentScreen) {
            case "Introduction":
              return (
                <Grid container className={classes.root} spacing={1}>
                  
                  <Grid item xs={12} md={12}>
                    {showNotification ? renderNotififications() : null}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LoaderHoc isLoading={isLoading}>
                      <CustomTable
                        columns={showcaseIntroductionColumns}
                        data={showcasesIntroductionRows}
                        handleDeleteButtonClick={handleIntroductionDeleteButton}
                        handleButtonClick={handleAddUserIntroduction}
                        handleViewButtonClick={handleViewButton}
                        tableTitle={title}
                        buttonTitle="Add Showcases Introduction"
                        hiddenColumns={[]}
                      />
                    </LoaderHoc>
                  </Grid>
                </Grid>
              );
              case "Cheat Sheets":
              return (
                <Grid container className={classes.root} spacing={1}>
                  <Grid item xs={12} md={12}>
                    {showNotification ? renderNotififications() : null}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LoaderHoc isLoading={isLoading}>
                      <CustomTable
                        columns={showcaseCheatSheetcolumns}
                        data={showcaseCheatSheetRows}
                        handleDeleteButtonClick={handleCheatSheetsDeleteButton}
                        handleButtonClick={handleAddUserCheatSheets}
                        handleViewButtonClick={handleViewButton}
                        tableTitle={title}
                        buttonTitle="Add Showcase Cheat Sheet"
                        hiddenColumns={[]}
                      />
                    </LoaderHoc>
                  </Grid>
                </Grid>
              );
              case "Use Case":
              return (
                <Grid container className={classes.root} spacing={1}>
                  <Grid item xs={12} md={12}>
                    {showNotification ? renderNotififications() : null}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LoaderHoc isLoading={isLoading}>
                      <CustomTable
                        columns={showcaseUseCasecolumns}
                        data={showcaseUseCaseRows}
                        handleDeleteButtonClick={handleUseCaseDeleteButton}
                        handleButtonClick={handleAddUserUseCase}
                        handleViewButtonClick={handleViewButton}
                        tableTitle={title}
                        buttonTitle="Add Showcase Use Case"
                        hiddenColumns={[]}
                      />
                    </LoaderHoc>
                  </Grid>
                </Grid>
              );
        }
    };
    return (
        <div>

            <Grid container className={classes.root}>
              <GridContainer className="backbutton">
                <GridItem sm={12} md={12}>
                  <Typography
                    variant="body1"
                    className={classes.backButton}
                    onClick={backButton}
                  >
                    <ArrowBackIcon /> Back
                  </Typography>
                </GridItem>
              </GridContainer>
                <Grid item md={2}>
                    <Menu
                        items={menuItems}
                        onItemClick={setCurrentScreen}
                        currentScreen={currentScreen}
                    />
                </Grid>

                <Grid item md={10}>
                    {renderContent()}
                </Grid>
                 {/* Drawer */}
                 <RightDrawer
                      open={openDrawer}
                      onClose={setOpenDrawer}
                      title={currentInventory}
                    >
                      {renderDrawerComponent()}
                    </RightDrawer>
            </Grid>
        </div>
    );
};

export default ProductContentShowcaseMenuItems;