import axios from "axios";

const BASE_URL = window.REACT_APP_API_BASE_URL;

export const ENV_NAME = window.REACT_APP_ENV_NAME;
export default BASE_URL;

export const ROUTING_URLS = {
  
};

// Get Backend server details

export const NODE_URLS = {
  CREATE_NODE_ACTION: "create_node", // working
  GET_NODE_ACTION: "get_nodes", // working
  DEL_NODE_ACTION: "delete_node",
  IMAGE_NODE_ACTION: "get_images",
  DELETE_IMAGE_NODE_ACTION: "delete_images",
  UPLOAD_IMAGE_NODE_ACTION: "upload_images",
  BUCKET_NAME: "django-drf-filepond"+"-"+ENV_NAME,
  NODE_URL_PROXY_URL: "/criterion/service-life-cycle/apiProxy",
  NODE_DETAILS_ACTION: "get_node_details" // working ; get node data
};
export const CAKEPHP_URLS = {
  BASE_URL: ENV_NAME === "staging" ? "/criterion/build" : ""
};

export function buildAxiosParams(apiname, data = null) {
  // make environment check
  // ******** REACT STAGING-EZ **********
  if (ENV_NAME === "stagingez") {
    switch (apiname) {
      case "CREATE_NODE":
        return {
          url: BASE_URL + "/lifecycle/nodes",
          data: data,
          method: "post"
        };
      case "UPDATE_NODE":
        return {
          url: BASE_URL + "/lifecycle/nodes",
          data: data,
          method: "put"
        };
      case "FETCH_IMAGE_LIST":
        return {
          url: BASE_URL + "/lifecycle/get_images",
          data: { bname: NODE_URLS.BUCKET_NAME },
          method: "post"
        };
      case "DELETE_IMAGE":
        return {
          url: BASE_URL + "/lifecycle/del_images",
          data: data,
          method: "post"
        };
      case "FETCH_NODE_LIST":
        return {
          url: BASE_URL + "/lifecycle/get_node",
          method: "get"
        };
      case "DELETE_NODE":
        return {
          url: BASE_URL + "/lifecycle/del_node",
          data: data,
          method: "post"
        };
      case "GET_NODE_DETAILS":
        return {
          url: BASE_URL + "/lifecycle/get_node_details",
          data: data,
          method: "post"
        };
      default:
        return {};
    }
  }

  // *********  START OF STAGING PHP *********
  else if (ENV_NAME === "staging") {
    switch (apiname) {
      case "CREATE_NODE":
        return {
          url: NODE_URLS.NODE_URL_PROXY_URL,
          data: {
            action: NODE_URLS.CREATE_NODE_ACTION,
            api_data: data
          },
          method: "post"
        };
      case "UPDATE_NODE":
        return {
          url: NODE_URLS.NODE_URL_PROXY_URL,
          data: {
            action: NODE_URLS.CREATE_NODE_ACTION,
            api_data: data
          },
          method: "put"
        };
      case "FETCH_IMAGE_LIST":
        return {
          url: NODE_URLS.NODE_URL_PROXY_URL,
          data: {
            action: NODE_URLS.IMAGE_NODE_ACTION,
            api_data: data
          },
          method: "post"
        };
      case "DELETE_IMAGE":
        return {
          url: NODE_URLS.NODE_URL_PROXY_URL,
          data: {
            action: NODE_URLS.DELETE_IMAGE_NODE_ACTION,
            api_data: data
          },
          method: "post"
        };
      case "FETCH_NODE_LIST":
        return {
          url: NODE_URLS.NODE_URL_PROXY_URL,
          params: {
            action: NODE_URLS.GET_NODE_ACTION
          },
          method: "get"
        };
      case "DELETE_NODE":
        return {
          url: NODE_URLS.NODE_URL_PROXY_URL,
          data: {
            action: NODE_URLS.DEL_NODE_ACTION,
            api_data: data
          },
          method: "post"
        };
      case "GET_NODE_DETAILS":
        return {
          url: NODE_URLS.NODE_URL_PROXY_URL,
          data: {
            action: NODE_URLS.NODE_DETAILS_ACTION,
            api_data: data
          },
          method: "post"
        };
      default:
        return {};
    }
  }
}