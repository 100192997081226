import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
// import axios from 'axios';

import LoaderHoc from "../../components/HOC/LoaderHoc";

import {SDCloudBackendAPIWithToken} from "../../apis/backendAPI";

function VmTable() {
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  // const [currentPage, setCurrentPage] = useState(1); // State to hold the current page number
  // const per_page = 10; // Number of rows per page
  const [pageSize, setPageSize] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchData({ page: 1, per_page: 10 });
  }, []);

  const fetchData = async (params) => {
    try {
      const response = await SDCloudBackendAPIWithToken()
        .get(`/gcp/get_vm_details?page=${currentPage}&per_page=${pageSize}`);

      setRows(response.data.data);
      setTotalRows(response.data.total);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  const handlePageChange = (newPage) => {
    //console.log(newPage, 'new page')
    setCurrentPage(newPage);
    fetchData();
    
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const columns = [
    {
        field: 'name',
        headerName: 'Name (UUID)',
        // width: 90
        minWidth: 300, flex: 1
    },
    {
        field: 'zone',
        headerName: 'Zone',
        minWidth: 200, flex: 1
    },
    {
        field: 'status',
        headerName: 'GCP-Status',
        minWidth: 150, flex: 1
    },
    {
        field: 'created_at',
        headerName: 'Created On',
        minWidth: 200, flex: 1
    },
    {
        field: 'uuid_db_status',
        headerName: 'Cluster-Status',
        minWidth: 150, flex: 1
    },
    {
        field: 'external_ip',
        headerName: 'External IP',
        minWidth: 150, flex: 1
    },
    {
        field: 'tags',
        headerName: 'Tags',
        minWidth: 200, flex: 1
    },
    
  ];

  return (
    <div style={{ width: '100%' }}>
      {isLoading ? ( // Conditional rendering based on the loading state
        <LoaderHoc isLoading={isLoading}></LoaderHoc> // Show CircularProgress while loading
      ) : (
      
      // <DataGrid
      //   getRowId={(row) => row.name}
      //   rows={rows}
      //   columns={columns}
      //   per_page={10}
      //   rowCount={totalRows}
      //   pagination
      //   onPageChange={handlePageChange}
      //   // pageSizeOptions={[5, 10, 25]}
      // />
      <DataGrid
        getRowId={(row) => row.name}
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        rowCount={totalRows}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
        onPageChange={(newPage) => handlePageChange(newPage)}
        onPageSizeChange={handlePageSizeChange}
        // handling client side pagination for timebeing as there is no much data from the backend
      />
)}
    </div>
  );
}

export default VmTable;