import React, { useEffect, useState } from "react";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
//react 
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// Formik
import { useFormik } from "formik";
// Material UI Components
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {hotjar} from "react-hotjar";
import GridItem from "components/Grid/GridItem";
import Switch from "@material-ui/core/Switch";
//checkbox
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
//markdown editor
import MdEditor from 'react-markdown-editor-lite';
import MarkdownIt from 'markdown-it';
//markdown editor import style manually
import 'react-markdown-editor-lite/lib/index.css';
//API Call
import { CMSAPIWithToken } from "../../../apis/CMS";
import queryString from "query-string";


const useStyles = makeStyles((theme) => ({
  
  gridContainer: {
    padding: "0 40px 30px 40px",
  },
  radioButton: {
    "& .MuiTypography-root": {
      marginLeft: "10px",
    },
  },
  formControl: {
    width: "100%",
    marginTop: "5px",
  },
  Select: {
    marginBottom: "50px",
  },
  warningNote: {
    "& .MuiAlert-icon": {
      display: "none",
    },
  },
  button: {
    marginTop: "50px",
  },
  buttOutlined: {
    color: theme.palette.buttonColor.main,
    borderColor: theme.palette.buttonColor.main,
    "&:hover": {
      border: `1px solid ${theme.palette.buttonColor.main}`,
    },
  },
}));

//sandbox view main
const SandboxOptionsUpdate = (props) => {
  const classes = useStyles();
  hotjar.initialize(2281731, 6);
  hotjar.identify(localStorage.userName, { userProperty: localStorage.userName});
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [btnDisabled, setBtnDisabled] = useState(false);
  let queryParams = queryString.parse(window.location.search);
  var sb_id = queryParams.id;
  var sandbox_id_title_map = JSON.parse(localStorage.getItem("sandbox_id_title_map"))
  var sandbox_title = sandbox_id_title_map[sb_id]
  const dispatch = useDispatch();
    const history = useHistory();
    const inputProps = {
        disableAnimation: true,
        shrink: false
      };  

  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
        admin_sandboxes_id: null,
        label: "",
        value: "",
        identifier: "",
        is_hidden: false
              },
    onSubmit: (values) => {
      let payload = {
        admin_sandboxes_id: values.admin_sandboxes_id, 
        label: values.label,
        value: values.value, 
        identifier: values.identifier,
        is_hidden: values.is_hidden,
      };

        CMSAPIWithToken() 
        .patch("/poc/admin_sandboxes_options"+`/${props.user}/`,payload) 
        .then((resp) => resp.data.results)
        .then((data) => {
            const payload = {
                message: "Sandbox Option updated Successfully",
                color: "success",
            };
            payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
            history.push("ProductContentSandboxesMenuItems?id="+sb_id+"&title="+sandbox_title);
            // window.location.reload()
           //renderSanboxView();
        })
        .catch((err) => {
          let message = err.response.data.message;
          const payload = {
            message: message,
            color: "danger",
        };
        payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
    },
    validate: (values) => {
      let errors = {};
      if (!values.admin_sandboxes_id) {
        errors.admin_sandboxes_id = "Required";
      }
      if (!values.label) {
        errors.label = "Required";
      }
      return errors;
    },
  });

 //mark down editor
function handleEditorChange({ html, text }) {
    //console.log('handleEditorChange', html, text);
  }
  // Initialize a markdown parser
  const mdParser = new MarkdownIt(/* Markdown-it options */);

  let data = [];

  const renderSanboxView = async () => {
    let sanboxView = CMSAPIWithToken()
      .get("/poc/admin_sandboxes_options/")
      .then((resp) => resp.data.results)
      .then((resp) => {
        resp.forEach((item) => {
          if(props.user == item.id) { 
            formik.setFieldValue("admin_sandboxes_id", item?.admin_sandboxes_id);
            formik.setFieldValue("label", item?.label);
            formik.setFieldValue("value", item?.value);
            formik.setFieldValue("identifier", item?.identifier);
            formik.setFieldValue("is_hidden", item?.is_hidden);
        }
        });
      })      
      .catch((err) => {
        let message = err.response.data.message;
        // setRows([]);
        // setIsLoading(false);
        setShowNotification(true);
        setNotificationType("error");
        setNotificationMessage("Failed to retrieve the data");
      });

      

      //setUserDetails({ ...formik.userDetails, ...data });
  };

  useEffect(() => {
    renderSanboxView();
  }, []);

  //checkbox
  
    const [state, setState] = React.useState({
      checkedA: true,
      checkedB: true,
      checkedF: true,
      checkedG: true,
    });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div>
        <form
            onSubmit={formik.handleSubmit}
            className={classes.formContainer}
          >
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.gridContainer}
      >
          <Grid item xs={12} md={5}>
          <Typography variant="h4">Sandbox Options</Typography>
          
        </Grid>

        <Grid item xs={12} md={7}>
        <TextField
          type="text"
          variant="filled"
          label="Sandboxes Id"
          name="admin_sandboxes_id"
          fullWidth
          InputLabelProps=""
          inputProps=""
          value={formik.values.admin_sandboxes_id}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        /> 
        </Grid>

        <Grid item xs={12} md={5}>
        <TextField
          type="text"
          variant="filled"
          label="Label"
          name="label"
          fullWidth
          InputLabelProps=""
          inputProps=""
          value={formik.values.label}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        /> 
        </Grid>

        <Grid item xs={12} md={6}>
        <TextField
          type="text"
          variant="filled"
          label="Identifier"
          name="identifier"
          fullWidth
          InputLabelProps=""
          inputProps=""
          value={formik.values.identifier}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        /> 
        </Grid>
        

        
        </Grid>

        <GridItem xs={12} sm={6} md={3} lg={3}>
        <span>Is Hidden</span>
                     <FormControlLabel
                     control={<Checkbox checked={formik.values.is_hidden} />} 
                     name="is_hidden"  
                     onChange={formik.handleChange}     
                        />  
            
        </GridItem>                 



        <GridItem xs={12} sm={6} md={3} lg={3}>
                 <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    size="large"
                    className={classes.buttStyle}
                    fullWidth
                    disableFocusRipple
                    type="submit"
                  >
                    Submit
                  </Button>
              </GridItem>

      
      </form>       
    </div>
  );
};

export default SandboxOptionsUpdate;
