import { createMuiTheme } from "@material-ui/core/styles";
import { hexToCSSFilter } from "hex-to-css-filter";

let primarycolorcode = localStorage.getItem("PrimaryColor")
  ? localStorage.getItem("PrimaryColor")
  : "#0F1A2B";
let secondarycolorcode = localStorage.getItem("SecondaryColor")
  ? localStorage.getItem("SecondaryColor")
  : "#F96B13";
let buttoncolorcode = localStorage.getItem("ButtonColor")
  ? localStorage.getItem("ButtonColor")
  : "#F96B13";
let panelcolorcode = localStorage.getItem("PanelColor")
  ? localStorage.getItem("PanelColor")
  : "#15253D";
let fontFamilyname = localStorage.getItem("FontFamily")
  ? localStorage.getItem("FontFamily")
  : "Roboto";
const theme = createMuiTheme({
  palette: {
    primarycolor: {
      maincolor: primarycolorcode
    },
    primary: {
      light: primarycolorcode,
      main: primarycolorcode,
      dark: primarycolorcode
    },
    secondary: {
      light: secondarycolorcode,
      main: secondarycolorcode,
      dark: secondarycolorcode,
      contrastText: "#fff",
      filterImage: hexToCSSFilter(secondarycolorcode, {
        acceptanceLossPercentage: 1
      }).filter
    },
    buttonColor: {
      light: buttoncolorcode,
      main: buttoncolorcode,
      dark: buttoncolorcode
    },
    fontList: {
      selectedFont: fontFamilyname,
      fonts: [
        "Arial",
        "Times New Roman",
        "Times",
        "Courier New",
        "Courier",
        "Verdana",
        "Georgia",
        "Palatino",
        "Garamond",
        // 'Bookman',
        "Tahoma",
        "Trebuchet MS",
        // 'Arial Black',
        // 'Impact',
        "Comic Sans MS",
        "Roboto",
        "Open Sans"
      ]
    },
    background: {
      default: "#9A9A9A",
      paper: "#ffffff"
    },
    success: {
      light: "rgba(4, 128, 1, 0.1)",
      main: "#048001"
    },
    error: {
      light: "rgba(223, 0, 0, 0.2)",
      main: "#DF0000"
    },
    info: {
      light: "rgba(193, 193, 193, 0.1)",
      border: "rgba(193, 193, 193, 0.5)",
      textMain: "#737373",
      main: "#D9E8FF"
    },
    warning: {
      light: "#FFFFFF",
      main: "#FFFFFF"
    },
    text: {
      primary: "#000000",
      secondary: "#ffffff",
      disabled: "#c4c4c4"
    },
    action: {
      active: "#4A4E53",
      hover: "#F96B13",
      selected: "#000000",
      disabled: "#F96B13",
      disabledBackground: "#F96B13"
    }
  },
  typography: {
    h1: {
      fontSize: "2.5rem", // 40px
      fontWeight: "700",
      fontFamily: fontFamilyname
    },
    h2: {
      fontSize: "2rem", // 32px
      fontWeight: "700",
      fontFamily: fontFamilyname
    },
    h3: {
      fontSize: "1.75rem", // 28px
      fontWeight: "700",
      fontFamily: fontFamilyname
    },
    h4: {
      fontSize: "1.5rem", // 24px
      fontWeight: "700",
      fontFamily: fontFamilyname
    },
    h5: {
      fontSize: "1.25rem", // 20px
      fontWeight: "700",
      fontFamily: fontFamilyname
    },
    h6: {
      fontSize: "1rem", // 16px
      fontWeight: "700",
      fontFamily: fontFamilyname
    },
    body1: {
      fontSize: "1rem", // 16px
      fontWeight: "400",
      fontFamily: fontFamilyname
    },
    body2: {
      fontSize: "0.875rem", // 14px
      fontWeight: "400",
      fontFamily: fontFamilyname
    },
    subtitle2: {
      textTransform: "initial",
      fontSize: "0.875rem",
      fontWeight: "400",
      fontFamily: fontFamilyname
    }
  },
  overrides: {
    MuiTypography: {
      body1: {
        fontSize: "14px",
        color: "#797979",
        fontFamily: fontFamilyname
      }
    },
    MuiButton: {
      root: {
        height: "32px",
        margin: "10px auto",
        borderRadius: "none",
        "&$disabled": {
          opacity: "0.5"
        },
        fontFamily: fontFamilyname
      },
      sizeSmall: {
        height: "24px"
      },
      sizeLarge: {
        height: "48px"
      },
      label: {
        textTransform: "capitalize",
        fontFamily: fontFamilyname
      },
      contained: {
        "&:hover": {
          backgroundColor: "#F96B13"
        },
        "&$disabled": {
          color: "#ffffff"
        }
      },
      containedSecondary: {
        color: "#ffffff"
      }
    },
    MuiButtonLabel: {
      root: {
        color: "#ffffff"
      },
      fontFamily: fontFamilyname
    },
    MuiInputBase: {
      root: {},
      fontFamily: fontFamilyname
    },
    MuiFilledInput: {
      root: {
        height: "40px",
        borderTopLeftRadius: "0",
        borderTopRightRadius: "0",
        fontSize: "16px",
        "&$focused": {
          border: "1px solid #172842"
        },
        "&$error": {
          border: "1px solid #DF0000"
        },
        color: "#797979",
        backgroundColor: "#F1F1F1",
        fontFamily: fontFamilyname
      },
      focused: {
        fontFamily: fontFamilyname
      },
      input: {
        padding: "0 0.5rem",
        fontFamily: fontFamilyname
      },
      underline: {
        "&:hover::before, &:hover::after, &::before, &::after": {
          borderBottom: "none",
          fontFamily: fontFamilyname
        }
      },
      multiline: {
        height: "auto"
      }
    },
    MuiInputLabel: {
      root: {
        fontSize: "12px",
        color: "#797979",
        fontFamily: fontFamilyname
      },
      filled: {
        transform: "none",
        "&.MuiInputLabel-shrink": {
          transform: "none"
        }
      },
      formControl: {
        position: "relative",
        marginBottom: "8px",
        marginLeft: "0",
        color: "#797979"
      }
    },
    MuiInput: {
      underline: {
        "&:hover:not(.Mui-disabled):before": {
          borderBottomColor: secondarycolorcode,
          borderWidth: "thin"
        },
        "&:hover:after": {
          borderBottom: `1px solid ${secondarycolorcode}`,
          borderWidth: "thin"
        },
        "&:active:after": {
          borderBottom: `1px solid ${secondarycolorcode}`,
          borderWidth: "thin"
        },
        "&:after": {
          borderBottomColor: secondarycolorcode,
          borderWidth: "thin"
        }
      }
    },
    MuiFormHelperText: {
      root: {
        fontSize: "12px",
        fontFamily: fontFamilyname
      },
      contained: {
        marginLeft: 0,
        marginRight: 0
      }
    },
    MuiSwitch: {
      root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: "10px"
      },
      switchBase: {
        padding: 1,
        top: "1px",
        "&$checked": {
          transform: "translateX(16px)",
          backgroundColor: "#0277C5",
          "& + $track": {
            backgroundColor: "#0277C5",
            opacity: 1,
            border: "none"
          }
        },
        "&$focusVisible $thumb": {
          color: "green",
          border: "6px solid #fff"
        }
      },
      thumb: {
        width: 22,
        height: 22,
        backgroundColor: "white"
      },
      track: {
        borderRadius: 26 / 2,
        border: `1px solid #F1F1F1`,
        backgroundColor: "#F1F1F1",
        opacity: 1
        // transition: theme.transitions.create(["background-color", "border"]),
      },
      checked: {},
      focusVisible: {}
    },
    MuiCheckbox: {
      root: {
        height: "14px",
        // width: "14px",
        color: "#172842"
      },
      sizeSmall: {
        height: "12px",
        width: "12px"
      }
    },
    MuiTab: {
      root: {
        textTransform: "capitalize"
      }
    },
    MuiRadio: {
      root: {
        height: "14px",
        width: "14px",
        color: "#172842",
        paddingRight: "18px"
      },
      colorSecondary: {
        "&$checked": {
          color: "#172842"
        }
      },
      sizeSmall: {
        height: "12px",
        width: "12px"
      }
    },
    MuiFormControl: {
      root: {
        marginTop: "15px"
      }
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: "0px",
        marginRight: "20px"
      }
    },
    MuiFormLabel: {
      root: {
        marginTop: "15px",
        marginBottom: "30px",
        "&$focused": {
          color: "#7D7D7D !important"
        },
        fontSize: "14px",
        color: "#7D7D7D",
        fontFamily: fontFamilyname
      }
    },
    MuiAutocomplete: {
      inputRoot: {
        '&[class*="MuiFilledInput-root"]': {
          paddingTop: "0px"
        },
        fontFamily: fontFamilyname
      },
      option: {
        '&[aria-selected="true"]': {
          backgroundColor: "#a4a4a4"
        },
        '&[data-focus="true"]': {
          backgroundColor: "#f1f1f1"
        },
        fontFamily: fontFamilyname
      }
    },
    MuiLink: {
      root: {
        cursor: "pointer",
        "&:hover": {
          color: "#F96B13"
        }
      }
    },
    MuiTableCell: {
      root: {
        height: "60px",
        padding: "0"
      },
      body: {
        color: "#1C1C28",
        fontSize: "14px",
        fontWeight: "400"
      },
      head: {
        color: "#4A4E53"
      }
    },
    MuiTableBody: {
      root: {
        "& .MuiTableCell-root": {
          borderBottom: "0"
        }
      }
    },
    MuiFormLabel: {
      root: {
        color: "black",
        fontSize: "0.875rem",
        fontFamily: fontFamilyname
      }
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: "0"
      }
    },
    MuiAlert: {
      root: {
        padding: 0,
        width: "100%",
        marginTop: "10px",
        marginBottom: "20px"
      },
      standardWarning: {
        backgroundColor: "rgba(249, 107, 19, 0.1)",
        padding: "5px"
      },
      standardSuccess: {
        padding: "5px",
        border: "1px solid #048001"
      },
      standardError: {
        padding: "5px",
        border: "1px solid #DF0000",
        color: "#DF0000",
        borderRadius: "0"
      },
      action: {
        marginRight: "0"
      }
    },
    MuiIcon: {
      colorTest: {}
    }
  },
  MuiAutocomplete: {
    inputRoot: {
      '&[class*="MuiFilledInput-root"]': {
        paddingTop: "0px"
      }
    }
  }
});

export default theme;
