import React, { useEffect, useState } from "react";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
//react 
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// Formik
import { useFormik } from "formik";
// Material UI Components
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {hotjar} from "react-hotjar";
import GridItem from "components/Grid/GridItem";
import Switch from "@material-ui/core/Switch";
//markdown editor
import Editor from "react-markdown-editor-lite";
import ReactMarkdown from "react-markdown";
import MarkdownIt from 'markdown-it';
//markdown editor import style manually
import 'react-markdown-editor-lite/lib/index.css';
//API Call
import { CMSAPIWithToken } from "../../../apis/CMS";
//checkbox
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';



const useStyles = makeStyles((theme) => ({
  
  gridContainer: {
    padding: "0 40px 30px 40px",
  },
  radioButton: {
    "& .MuiTypography-root": {
      marginLeft: "10px",
    },
  },
  formControl: {
    width: "100%",
    marginTop: "5px",
  },
  Select: {
    marginBottom: "50px",
  },
  warningNote: {
    "& .MuiAlert-icon": {
      display: "none",
    },
  },
  button: {
    marginTop: "50px",
  },
  buttOutlined: {
    color: theme.palette.buttonColor.main,
    borderColor: theme.palette.buttonColor.main,
    "&:hover": {
      border: `1px solid ${theme.palette.buttonColor.main}`,
    },
  },
}));

//sandbox view main
const SandboxUpdate = (props) => {
  const classes = useStyles();
  const mdEditor = React.useRef(null);
  //const [value, setValue] = React.useState("xxx");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [description, setDescription] = useState("");
  //const mdParser = new MarkdownIt(/* Markdown-it options */);
  const dispatch = useDispatch();
    const history = useHistory();
    const inputProps = {
        disableAnimation: true,
        shrink: false
      };  

  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
                title: "", 
                subtitle: "",
                identity: "", 
                price: "",
                price_unit: "", 
                diagram: "",
                diagram_img: "", 
                icon_image: "", 
                email_content: "",
                is_enabled: false, 
                access_impairment: false, 
                access_tcpreplay: false, 
                account: [ ]
              },
    onSubmit: (values) => {
      let payload = {
                title: values.title, 
                subtitle: values.subtitle ,
                identity: values.identity, 
                price: values.price,
                price_unit: values.price_unit, 
                description:description, 
                diagram: values.diagram,
                diagram_img:values.diagram_img, 
                icon_image: values.icon_image, 
                email_content: values.email_content,
                is_enabled: values.is_enabled, 
                access_impairment:values.access_impairment, 
                access_tcpreplay: values.access_tcpreplay, 
                account: [ ]
      };

        CMSAPIWithToken() 
        .patch("/poc/admin_sandboxes"+`/${props.user}/`,payload) 
        .then((resp) => resp.data.results)
        .then((data) => {
            const payload = {
                message: "Sandbox updated Successfully",
                color: "success",
            };
            payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
            history.push("/product-admin-sandboxes");
            window.location.reload()
           //renderSanboxView();
        })
        .catch((err) => {
          let message = err.response.data.message;
          const payload = {
            message: message,
            color: "danger",
        };
        payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
    },
    validate: (values) => {
      let errors = {};
      if (!values.title) {
        errors.title = "Required";
      }
      if (!values.subtitle) {
        errors.subtitle = "Required";
      }
      if(!values.identity){
        errors.identity = "Required";
      }
      return errors;
    },
  });

 //mark down editor
 const handleEditorChange = ({ html, text }) => {
  const newValue = text;
  //console.log(newValue);
  setDescription(newValue);
};

  let data = [];

  const renderSanboxView = async () => {
    let sanboxView = CMSAPIWithToken()
      .get("/poc/admin_sandboxes/")
      .then((resp) => resp.data.results)
      .then((resp) => {
        resp.forEach((item) => {
          if(props.user == item.id) {
            for(const field in item){
              formik.setFieldValue(field, item[field]);
              if(field == 'description'){
                setDescription(item[field])
              }
            }

            return false;
          }
        });
      })      
      .catch((err) => {
        let message = err.response.data.message;
        // setRows([]);
        // setIsLoading(false);
        setShowNotification(true);
        setNotificationType("error");
        setNotificationMessage("Failed to retrieve the data");
      });

      

      //setUserDetails({ ...formik.userDetails, ...data });
  };

  useEffect(() => {
    renderSanboxView();
  }, []);

  //checkbox
  
    const [state, setState] = React.useState({
      checkedA: true,
      checkedB: true,
      checkedF: true,
      checkedG: true,
    });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div>
        <form
            onSubmit={formik.handleSubmit}
            className={classes.formContainer}
          >
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.gridContainer}
      >
        <Grid item xs={12} md={5}>
          <Typography variant="h4">Sandbox Update</Typography>
          
        </Grid>

        <Grid item xs={12} md={7}>
        <TextField
          type="text"
          variant="filled"
          label="Title"
          name="title"
          fullWidth
          InputLabelProps=""
          inputProps=""
          value={formik.values.title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        /> 
        </Grid>

        <Grid item xs={12} md={5}>
        <TextField
          type="text"
          variant="filled"
          label="Subtitle"
          name="subtitle"
          fullWidth
          InputLabelProps=""
          inputProps={{readOnly: false }}
          value={formik.values.subtitle}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        /> 
        </Grid>

        <Grid item xs={12} md={6}>
        <TextField
          type="text"
          variant="filled"
          label="Identity"
          name="identity"
          disabled
          fullWidth
          InputLabelProps=""
          inputProps=""
          value={formik.values.identity}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        /> 
        </Grid>
        

        <Grid item xs={12} md={5}>
        <TextField
          type="text"
          variant="filled"
          label="Price"
          name="price"
          fullWidth
          InputLabelProps=""
          inputProps=""
          value={formik.values.price}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        /> 
        </Grid>

        <Grid item xs={12} md={6}>
        <TextField
          type="text"
          variant="filled"
          label="Price Unit"
          name="price_unit"
          fullWidth
          InputLabelProps=""
          inputProps=""
          value={formik.values.price_unit}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        /> 
        </Grid>

    

        <Grid item xs={12} md={12}>
        <p>Description</p>
          <Editor
            ref={mdEditor}
            value={description}
            style={{
              height: "500px"
            }}
            onChange={handleEditorChange}
            renderHTML={text => <ReactMarkdown source={text} />}
          />
           </Grid>

           <Grid item xs={12} md={12}>     

        <TextField
          type="text"
          variant="filled"
          label="Email Content"
          name="email_content"
          fullWidth
          InputLabelProps=""
          inputProps=""
          value={formik.values.email_content}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          multiline
          rows={8}
        /> 
        </Grid>

        <Grid item xs={12} md={12}>
        <TextField
          type="text"
          variant="filled"
          label="Diagram Javascript"
          name="diagram"
          fullWidth
          InputLabelProps=""
          inputProps=""
          value={formik.values.diagram}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        /> 
        </Grid>

        <Grid item xs={12} md={12}>
        <TextField
          type="text"
          variant="filled"
          label="Diagram Img"
          name="diagram_img"
          fullWidth
          InputLabelProps=""
          inputProps=""
          value={formik.values.diagram_img}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        /> 
        </Grid>

        <Grid item xs={12} md={12}>
        <TextField
          type="text"
          variant="filled"
          label="Icon Image"
          name="icon_image"
          fullWidth
          InputLabelProps=""
          inputProps=""
          value={formik.values.icon_image}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        /> 
        </Grid>

        <Grid item xs={12} md={12}>
        <Grid item xs={12} sm={12} md={12}>
        <span>Is Enabled</span>
                   <FormControlLabel
                            control={<Checkbox checked={formik.values.is_enabled} />} 
                            name="is_enabled"
                            onChange={formik.handleChange}
                            />

                  < span>Access Impairment</span>
                  <FormControlLabel
                            control={<Checkbox checked={formik.values.access_impairment} />}
                            name="access_impairment"
                            onChange={formik.handleChange}
                            />

                  < span>Access Tcpreplay</span>
                  <FormControlLabel
                            control={<Checkbox checked={formik.values.access_tcpreplay} />}
                            name="access_tcpreplay"
                            onChange={formik.handleChange}
                            />
                  </Grid>
        </Grid>

        <GridItem xs={12} sm={6} md={3} lg={3}>
                 <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    size="large"
                    className={classes.buttStyle}
                    fullWidth
                    disableFocusRipple
                    type="submit"
                  >
                    Submit
                  </Button>
              </GridItem>

      </Grid>
      </form>       
    </div>
  );
};

export default SandboxUpdate;
