import React, { useEffect, useState } from "react";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

// API
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import { Api } from "config/api";

// Local Components
import ClusterUsage from "./components/StatisticsGraph.js";
import ClusterQuota from "./components/ClusterQuota";
import StatisticsTable from "./components/StatisticsTable.js";
import LoaderHoc from "components/HOC/LoaderHoc";
import { useDispatch } from "react-redux";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import { saveAs } from "file-saver";
import Chatbot from "views/Components/chatbot/Chatbot.js";
import {
  SHOW_NOTIFICATION,
  SHOW_LOADER,
  HIDE_LOADER
} from "action_creators/actioncreator";

const useStyles = makeStyles(theme => ({
  root: {
    padding: "20px"
  },
  tableConfig: {
    padding: "20px",
    paddingTop: "2px"
  },
  backColor: {
    background: theme.palette.buttonColor.main,
    "&:hover": {
      background: theme.palette.buttonColor.main
    }
  }
}));

const Statistics = () => {
  const [quotaDetailsData, setQuotaDetailsData] = useState([]);
  const [clusterQuotaData, setClusterQuotaData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [rows, setRows] = useState([]);
  const profileData = JSON.parse(localStorage.getItem("profileData"));
  const account = profileData.account;
  let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
  let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
  let hasDomain = domain?.some((res) => userEmail.includes(res));
  const [isInternal, setIsInternal] = useState(hasDomain);
  const classes = useStyles();

  useEffect(() => {
    renderData();
  }, []);

  const columns = [
    {
      Header: "Product category",
      accessor: "category"
    },
    {
      Header: "Total hours consumed",
      accessor: "hours",
      disableGroupBy: true,
        textDecoration: "underline",
        cursor: "pointer",
    },
    {
      Header: "Calculated from",
      accessor: "since_date"
    }
  ];

  const getStats = async () => {
    await SDCloudBackendAPIWithToken()
      .get(Api.getUserStatistics)
      .then(resp => resp.data)
      .then(data => {
        setClusterQuotaData(data);
      });

    // await SDCloudBackendAPIWithToken()
    //   .get(Api.get_quota_admin)
    //   .then(resp => resp.data)
    //   .then(data => {
    //     setQuotaDetailsData(data);
    //   });

    setShowLoader(false);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    getStats();
    dispatch(SET_NAVBAR_TITLE(isInternal?account+"  - Statistics": "Statistics"));
  }, []);

  const handleExcel = () => {
    dispatch(SHOW_LOADER());
    SDCloudBackendAPIWithToken()
      .get(`/statistics/download_userdetails`, { responseType: "blob" })
      .then(resp => {
        var blob = new Blob([resp.data], {
          type: "application/ms-excel;charset=utf-8"
        });
        saveAs(blob, `${"Statistics"}.xls`);
        dispatch(HIDE_LOADER());
      })
      .catch(err => {
        const payload = {
          message: err?.response?.data?.message || "unknown error occured",
          color: "danger",
          error: err,
          showErrorPage: true
        };
        dispatch(SHOW_NOTIFICATION({ payload }));
        dispatch(HIDE_LOADER());
      });
  };

  const renderData = () => {
    let data = [];
    let response = SDCloudBackendAPIWithToken()
      .get(Api.getUserStatistics)
      .then(resp => {
        let defaultDate = new Date();
        defaultDate.setDate(defaultDate.getDate() - 12 * 7);
        defaultDate = defaultDate.toLocaleDateString();
        let designerStartdate = defaultDate;
        let showcaseStartdate = defaultDate;
        let sandboxStartdate = defaultDate;
        let learningStartdate = defaultDate;

        if (resp.data?.Start_Date?.designer_startdate) {
          designerStartdate = new Date(
            resp.data?.Start_Date?.designer_startdate
          ).toLocaleDateString();
        }
        if (resp.data?.Start_Date?.showcase_startdate) {
          showcaseStartdate = new Date(
            resp.data?.Start_Date?.showcase_startdate
          ).toLocaleDateString();
        }
        if (resp.data?.Start_Date?.sandbox_startdate) {
          sandboxStartdate = new Date(
            resp.data?.Start_Date?.sandbox_startdate
          ).toLocaleDateString();
        }
        if (resp.data?.Start_Date?.learning_startdate) {
          learningStartdate = new Date(
            resp.data?.Start_Date?.learning_startdate
          ).toLocaleDateString();
        }
        data.push({
          category: "Design center",
          hours: resp.data?.total_hours?.designer_hours,
          since_date: designerStartdate
        });
          data.push({
              category: "Designer usage",
              hours: resp.data?.total_hours?.designer_usage,
              since_date: designerStartdate
          });
        data.push({
          category: "Showcase",
          hours: resp.data?.total_hours?.showcase_hours,
          since_date: showcaseStartdate
        });
        data.push({
          category: "Sandbox",
          hours: resp.data?.total_hours?.sandbox_hours,
          since_date: sandboxStartdate
        });
        data.push({
          category: "Learning",
          hours: resp.data?.total_hours?.learning_hours,
          since_date: learningStartdate
        });

        // data.sort((a, b) => {
        //   if (a.lastUpdated > b.lastUpdated) return -1;
        //   if (a.lastUpdated < b.lastUpdated) return 1;
        //   return 0;
        // });
        setRows(data);
      })
      .catch(err => {
        // let message = err.response.data.message;
        setRows([]);
      });
  };

  return (
    <>
      <LoaderHoc isLoading={showLoader}>
        {/* <Grid container justify="space-between">
          <Grid item xs={12} sm={12} md={10}></Grid>
          <Grid item xs={12} sm={12} md={2}>
            <Button
              onClick={handleExcel}
              variant="contained"
              color="secondary"
              disableElevation
              size="large"
              fullWidth
              disableFocusRipple
              className={classes.backColor}
              type="button"
              style={{ cursor: "pointer" }}
            >
              Download
            </Button>
          </Grid>
        </Grid> */}
        <Grid container justify="space-between" className={classes.root}>
          {/* <QuotaDetails data={quotaDetailsData} /> */}
          {/* <ClusterQuota data={clusterQuotaData} /> */}
          <ClusterUsage data={clusterQuotaData} />
        </Grid>
        <Grid container className={classes.tableConfig}>
          <StatisticsTable
            columns={columns}
            data={rows}
            // handleDeleteButtonClick={handleDeleteButton}
            // handleButtonClick={handleAddIpButton}
            //   handleViewButtonClick={handleViewButton}
            tableTitle="Details"
            buttonTitle="Show details"
            // dropDownValues={[]}
          />
        </Grid>
        {/* <Grid container justify="space-between" spacing={2}>
          <Grid item xs={12} sm={12} md={3}>
            <LearningStats data={clusterQuotaData} />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <ShowcaseStats data={clusterQuotaData} />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <SandboxStats data={clusterQuotaData} />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <DesignerStats data={clusterQuotaData} />
          </Grid>
        </Grid> */}
        <Chatbot/>
      </LoaderHoc>
    </>
  );
};

export default Statistics;
