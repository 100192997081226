import React, { useEffect , useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import SettingsIcon from '@material-ui/icons/Settings';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import Grid from "@material-ui/core/Grid";
import StepButton from '@material-ui/core/StepButton';
import { useDispatch } from "react-redux";
import AddNode from "./AddNode.js";
import ConfigureNode from "./ConfigureNode.js";
import TestNode from "./TestNode.js";
import { blackColor } from 'assets/jss/material-dashboard-pro-react.js';
import { useHistory } from "react-router-dom";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#ffffff',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
    color:blackColor
  },
  active: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgbrgb(242,113,33) 50%,rgbrgb(242,113,33) 100%)',
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(247,58,0) 50%,rgb(247,58,0) 100%)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(242,113,33) 50%, rgb(242,113,33) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(242,113,33) 50%, rgb(242,113,33) 100%)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <AddIcon />,
    2: <SettingsIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor:"#ffffff",
    marginLeft:"10px"
  },
  button: {
    float: 'right',
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Add / Edit Element', 'Configure Element', 'Test Element'];
}



export default function CreateNodeWizard() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [nodeId, setNodeId] = React.useState("");
  const [testStep, setTestStep] = React.useState(100);
  const [skipped, setSkipped] = React.useState(new Set());
  const [completed, setCompleted] = React.useState(new Set());
  const steps = getSteps();
  const history = useHistory();

  const callback = useCallback((nodeId) => {
    setNodeId(nodeId);
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(SET_NAVBAR_TITLE("Add/Edit Element"));
  }, []);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if(activeStep == 2){
      history.push("/custom-elements");
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  function handleNodeId(newValue) {
    setNodeId(newValue);
  }

  function isStepComplete(step) {
    return completed.has(step);
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <AddNode activeStep={activeStep} nodeId={handleNodeId} handleNext={handleNext} handleBack={handleBack}/>;
      case 1:
        return <ConfigureNode activeStep={activeStep} nodeId={nodeId} handleNext={handleNext} handleBack={handleBack}/>;
      case 2:
        return <TestNode activeStep={activeStep} nodeId={nodeId} handleNext={handleNext} handleBack={handleBack}/>;
      default:
        return 'Unknown step';
    }
  }

  return (
    <div className={classes.root}>
        <Stepper nonLinear alternativeLabel style={{color:'black'}}  activeStep={activeStep} connector={<ColorlibConnector />}>
          {/* {steps.map((label) => (
            <Step key={label} style={{color:'black'}} >
              <StepLabel StepIconComponent={ColorlibStepIcon} style={{color:'black'}} >{label}</StepLabel>
            </Step>
          ))} */}
          {steps.map((label, index) => {
          const stepProps = {};
          const buttonProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepButton
                onClick={handleStep(index)}
                completed={isStepComplete(index)}
                {...buttonProps}
              >
               <StepLabel StepIconComponent={ColorlibStepIcon} style={{color:'black'}} >{label}</StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper> 
      <Grid container className={classes.root} style={{marginLeft:'1px'}} spacing={1}>
        <Grid item xs={12} md={12}>
          <div>
              {activeStep === steps.length ? (
                <div>
                  <Typography className={classes.instructions}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Button onClick={handleReset} className={classes.button}>
                    Reset
                  </Button>
                </div>
              ) : (
                <div>
                  <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                  <div>
                    {/* <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                      right
                      type="submit"
                    >
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                    <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                      Back
                    </Button> */}
                  </div>
                </div>
              )}
            </div> 
          </Grid>
        </Grid>
    </div>
  );
}
