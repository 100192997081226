import React, {useEffect, useState} from "react";
import { useFormik } from "formik";
import {makeStyles} from "@material-ui/core/styles";
import MuiAlert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { FormControl, TextField, InputLabel, FilledInput, InputAdornment, FormControlLabel,Icon, Button } from "@material-ui/core";
import { SDCloudBackendAPIWithToken, SDCloudCustomizeAPIWithToken} from "apis/backendAPI";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import queryString from "query-string";
import Select from "@material-ui/core/Select";
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Checkbox from "@material-ui/core/Checkbox";

// Styling
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} style={{boxShadow:"none", color:"#000000"}} />;
  }
const useStyles = makeStyles((theme) => ({
    infoicon: {
        padding: "12px 2px 0px 0px",
        position:"relative",
        right:"60px"
    },
    root: {
        marginTop: "50px",
    },
    item: {
        textAlign: "end",
    },
    actionsContainer: {
    marginBottom: theme.spacing(2),
    },
    resetContainer: {
    padding: theme.spacing(3),
    },
    button: {
        background: `${theme.palette.buttonColor.main} !important`,
        height: "35px",
        width: "80px",
        color: "white",
        border: "none",
        fontSize: "0.875rem",
        fontFamily: `${theme.palette.fontList.selectedFont} !important`,
        cursor: "pointer",
    },
    filter: {
        marginTop: "2%",
        padding: "20px 0px 50px 50px",
        background: "#FFFFFF",
        border: "1px solid #F0F0F0",
        boxSizing: "border-box",
        boxShadow: "0px 4px 12px rgb(0 0 0 / 4%)",
        position: "relative",
        width: "80%"
    },
    buttongrid:{
        marginTop: "20px"
    },
    btnStyle: {
        background: `#FFFFFF`,
        height: "35px",
        width: "80px",
        fontSize: "0.875rem",
        fontFamily: `${theme.palette.fontList.selectedFont} !important`,
        cursor: "pointer",
    },
    filterButton: {
        padding: "8px 50px 0px 0px",
    },
    icon: {
        marginTop: "30px",
    },
}));

const ConfigureNode = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [nodeData, setNodeData] = useState({});
    const [versionData, setVersionData] = useState({});
    const [versionSpecificData, setVersionSpecificData] = useState([]);
    var activeStep = props.activeStep;
    var pnodeId = props.nodeId;
    const [state, setState] = React.useState({
        is_version_specific: false,
      });
    
    const [isEdgeDevice, setIsEdgeDevice] = useState(false);
    const [configList, setConfigList] = useState([{
        protocol:'',
        port: '',
        username: '',
        password: ''
    }]);
    const [additionalProps, setAdditionalProps] = useState([{
        prop_type:'',
        prop_key: '',
        prop_label: '',
        prop_value: '',
        child_of:'',
        default:'',
        is_version_specific: false
    }]);
    const [addlPropType, setAddlPropType] = useState("Text"); 
    const [additionalIntfProps, setAdditionalIntfProps] = useState([{
        intf_prop_type:'',
        intf_prop_key: '',
        intf_prop_label: '',
        intf_prop_value: ''
    }]);
    const NO_OF_VCPU = [1, 2, 4, 8, 16, 32];
    const RAM = [1, 2, 4, 8, 16, 32, 64];
    const DISK = [10, 20, 40, 50, 80, 100];
    const CPU_TYPE = ["Pass Through", "Model"];
    const VIRTUALISATION_TYPE = ["qemu", "kvm"];
    const INTERFACE_TYPE = ["e1000", "VIRTIO"];
    const ADDITIONAL_INTERFACE = ["1","2","3","4","5","6","7","8","9","10"];
    const PROTOCOL_LIST = ["HTTP", "HTTPS", "SSH"];
    const PROP_TYPE_LIST = ["property-text", "property-hidden", "property-checkbox"];
    const INTF_PROP_TYPE_LIST = ["Text", "Select"];
    let queryParams = queryString.parse(window.location.search);
    var mode = queryParams.mode;
    if(mode == 'add'){
        var node_name = localStorage.getItem("node_name")
        var node_id = localStorage.getItem("node_id")
        var node_version = localStorage.getItem("node_version")
    }else{
        var node_id = queryParams.id
        var node_name = queryParams.name
        var node_version =queryParams.version
    }
    
    const formik = useFormik({
      enableReinitialize:true,
      initialValues: {
        node_name: node_name,
        node_sku_id: nodeData.node_sku_id,
        node_version: node_version,
        node_id:node_id,
        image_url:nodeData.image_url,
        vcpu: versionData != undefined ? versionData.vcpu : nodeData.vcpu,
        ram: versionData != undefined ? versionData.ram : nodeData.ram,
        disk: versionData != undefined ? versionData.disk: nodeData.disk,
        cpu_type: nodeData.cpu_type,
        virt_type: nodeData.virt_type,
        interface_type: nodeData.interface_type,
        post_inst_script: nodeData.post_inst_script,
        interface_name: nodeData.interface_name,
        mgmt_interface_name: nodeData.mgmt_interface_name,
        additional_interface: nodeData.additional_interface,
        configuration_setting: versionData != undefined ? versionData.configuration_setting: nodeData.configuration_setting,
        additional_config: nodeData.additional_config,
        version_specific_config: versionData != undefined ? versionData.version_specific_config:[],
        additional_intf_config: nodeData.additional_intf_config,
        command_props: nodeData.command_props,                    
        externalservices_setting: nodeData.externalservices_setting,
        version_list:nodeData.version_list,
        test_info:nodeData.test_info,
        is_edge_device:nodeData.is_edge_device

      },
      enableReinitialize:true,
      onSubmit: (values) => {
        var ver_name_list = []
        var ver_spfc_config = []
        for(const versn in nodeData.version_list){
            ver_name_list.push(nodeData.version_list[parseInt(versn)]['version'])
        }
        // additionalProps.forEach(function(conf_item, conf_index){
        //     //console.log(conf_index)
        //     //console.log(conf_item)
        //     if(conf_item.is_version_specific){
        //         ver_spfc_config.push(conf_item)
        //         additionalProps.splice(conf_index,1)
        //     }
        // })
        for (var i = additionalProps.length - 1; i >= 0; i -= 1) {
            if (additionalProps[i].is_version_specific) {
                ver_spfc_config.push(additionalProps[i]);
                additionalProps.splice(i, 1);
            }
        }
        for (var i = versionSpecificData.length - 1; i >= 0; i -= 1) {
            if (!versionSpecificData[i].is_version_specific) {
                additionalProps.push(versionSpecificData[i])
            }else{
                ver_spfc_config.push(versionSpecificData[i]);
            }
        }
        var curr_node_version = localStorage.getItem("node_version")
        if(ver_name_list.includes(curr_node_version)){
            for(const ver in nodeData.version_list){
                if(nodeData.version_list[parseInt(ver)]['version'] == curr_node_version){
                    nodeData.version_list[parseInt(ver)]['image_url'] = values.image_url
                    nodeData.version_list[parseInt(ver)]['custom_config'] = {
                        vcpu:values.vcpu,
                        ram:values.ram,
                        disk: values.disk,
                        configuration_setting: configList,
                        version_specific_config: ver_spfc_config
                    };
                }
            }  
        }else{
            nodeData.version_list.push({'image_url': localStorage.getItem("node_image_url"),
                                        'version': curr_node_version,
                                        'custom_config': {
                                            vcpu:values.vcpu,
                                            ram:values.ram,
                                            disk: values.disk,
                                            configuration_setting: configList,
                                            version_specific_config: ver_spfc_config
                                        }});
        }
        if(values.vcpu == undefined || values.vcpu == "" || 
            values.ram == undefined || values.ram == "" ||
            values.disk == undefined || values.disk == "" || 
            values.interface_type == undefined || values.interface_type == "" ||
            values.virt_type == undefined || values.virt_type == "" || 
            values.additional_interface == undefined || values.additional_interface == "" ){
            let payload = {
                message: "Please fill up all the element properties.",
                color: "danger",
            };
            payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
            return false;
        }
        if(values.interface_name == undefined || values.interface_name == "" ||
            values.mgmt_interface_name == undefined || values.mgmt_interface_name == ""){
            let payload = {
                message: "Please fill up the interface information.",
                color: "danger",
            };
            payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
            return false; 
        }
          if (values.command_props == undefined || values.command_props.length == 0)
          {
              let payload = {
                  message: "Please enter atleast one command.",
                  color: "danger",
              };
            payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
            return false;
        }
        if(configList.length == 0 ){
            let payload = {
                message: "Please enter atleast one node accessibility configuration.",
                color: "danger",
            };
            payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
            return false;
        }else{
            if(configList.length > 0 ){
                if(configList[0].protocol == ""){
                    let payload = {
                        message: "Please enter atleast one node accessibility configuration.",
                        color: "danger",
                    };
                    payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
                    return false;
                }
            }
        }
        
        let api_payload = {
            node_name: values.node_name,
            node_version: curr_node_version,
            node_id:node_id,
            node_sku_id:values.node_sku_id,
            image_url:values.image_url,
            vcpu:values.vcpu,
            ram:values.ram,
            disk: values.disk,
            cpu_type: values.cpu_type,
            virt_type: values.virt_type,
            interface_type: values.interface_type,
            post_inst_script: "",
            interface_name: values.interface_name,
            mgmt_interface_name: values.mgmt_interface_name,
            additional_interface: values.additional_interface,
            configuration_setting:configList,
            additional_config: additionalProps,
            additional_intf_config: additionalIntfProps,
            command_props: values.command_props,               
            externalservices_setting: {},
            state:"configured",
            test_info:nodeData.test_info,
            version_list:nodeData.version_list,
            is_edge_device: isEdgeDevice
          };

          SDCloudBackendAPIWithToken()
            .post("/inventory/update_designer_node", api_payload)
            .then((resp) => resp.data)
            .then((data) => {
                let payload = {
                  message: "Configurations saved in the Inventory.",
                  color: "success",
              };
              payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
              SDCloudBackendAPIWithToken()
                .post("/lifecycle/configure_node_json", api_payload)
                .then((resp) => resp.data)
                .then((data) => {
                    let payload = {
                        message: "Element configuration saved successfully",
                        color: "success",
                    };
                    payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
                    localStorage.removeItem("design_objects"); 
                    props.handleNext();  
                })
                .catch((err) => {
                    let payload = {
                        message: err.response.data.message,
                        color: "danger",
                    };
                    payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
                    localStorage.removeItem("design_objects"); 
                    props.handleNext(); 
                });
            })
            .catch((err) => {
              let message = err.response.data.message;
              const payload = {
                message: message,
                color: "danger",
            };
            payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
            }); 
      },
      validate: (values) => {
        let errors = {};
        return errors;
      },
    });
    const getNodeInventoryDetails = async (payload) => {
        SDCloudBackendAPIWithToken()
            .post("/inventory/get_designer_node_details", payload)
            .then((resp) => resp.data)
            .then((data) => {
            let node_details = data['node_details'];
            setConfigList(node_details.configuration_setting)
            setAdditionalProps(node_details.additional_config)
            setAdditionalIntfProps(node_details.additional_intf_config)
            setIsEdgeDevice(node_details.is_edge_device)
            setNodeData(node_details)
            for(const version_index in node_details.version_list){
                if(node_details.version_list[parseInt(version_index)]['version'] == node_version){
                    if(node_details.version_list[parseInt(version_index)]['custom_config'] != undefined){
                        setVersionData(node_details.version_list[parseInt(version_index)]['custom_config'])
                        if(node_details.version_list[parseInt(version_index)]['custom_config']['version_specific_config'] !== undefined){
                            setVersionSpecificData(node_details.version_list[parseInt(version_index)]['custom_config']['version_specific_config'])
                        }else{
                            setVersionSpecificData([])
                        }
                        setConfigList(node_details.version_list[parseInt(version_index)]['custom_config'].configuration_setting)
                    }
                    break;
                }
            }
        });
    };

    // const handleAddConfig = () => {
    //     let config_obj = {username: formik.values.username, password:formik.values.password , protocol:formik.values.protocol, port:formik.values.port}
    //     let config_list = configList;
    //     config_list.push(config_obj);
    //     setConfigList(config_list);
    //     //console.log(configList);
    // };

    // const handleRemoveConfig = (index) => {
    //     let vals = formik.values.configuration_setting;
    //     vals = vals.splice(index,1);
    //     formik.setFieldValue("configuration_setting", [...formik.values.configuration_setting,vals]);
    //     //formik.values.configuration_setting.splice(index,1);
    // };

    const handleOtherConfigChange = (event) => {
        setIsEdgeDevice(event.target.checked);
      };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...configList];
        for(let idx in list){
            if(list[idx]['protocol'] == value){
                alert("Protocol "+ value + " is already configured.");
                return false;
            } 
         }
        list[index][name] = value;
        if(name == 'protocol'){
            if(value == "HTTP"){
                list[index]['port'] = "80"
            }if(value == "SSH"){
                list[index]['port'] = "22"
            }if(value == "HTTPS"){
                list[index]['port'] = "443"
            }
        }
        setConfigList(list);
      };
     
      const handleAddlInputChange = (e, index, category) => {
        const { name, value } = e.target;
        if(!category){
            var list = [...additionalProps];
        }else{
            var list = [...versionSpecificData];
        }
        list[index][name] = value;
        if(name == 'is_version_specific'){
            list[index][name] = e.target.checked
        }
        if(value == 'property-checkbox'){
            setAddlPropType("checkbox");
            list[index]['prop_value'] = "true"
        }else{
            setAddlPropType("text");
        }
        if(!category){
            setAdditionalProps(list); 
        }else{
            setVersionSpecificData(list)
        }
      };

      const handleAddlInputTypeChange = (e, index, category) => {
        const { name, value } = e.target;
        if(!category){
            const list = [...additionalProps];
            list[index][name] = value;
            setAdditionalProps(list);
        }else{
            const list = [...versionSpecificData];
            list[index][name] = value;
            setVersionSpecificData(list);
        }
        
      };

      const handleAddlIntfInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...additionalIntfProps];
        if(name == 'intf_prop_key' && value == 'color'){
            list[index][name] = value;
            list[index]['intf_prop_value'] = "Metro Ethernet, Mpls, Private1, Private2, Private3, Private4, Private5, Private6, Gold, Silver, Bronze, Red, Green, Blue, Default, Biz-internet, Public-internet, LTE, Custom1, Custom2, Custom3";
        }if(name == 'intf_prop_key' && value == 'tunnel'){
            list[index][name] = value;
            list[index]['intf_prop_value'] = "True, False";
        }if(name == 'intf_prop_key' && value == 'encap'){
            list[index][name] = value;
            list[index]['intf_prop_value'] = "IPSEC, GRE";
        }else{
            list[index][name] = value;
        }
        setAdditionalIntfProps(list);
      };

    //   const handleRuntimeInputChange = (e, index) => {
    //     const { name, value } = e.target;
    //     const list = [...commandProps];
    //     list[index][name] = value;
    //     setCommandProps(list);
    //   };

      // handle click event of the Remove button
      const handleDeleteConfig = index => {
        const list = [...configList];
        list.splice(index, 1);
        setConfigList(list);
      };
     
      // handle click event of the Add button
      const handleAddConfig = () => {
        var config_list = [...configList];
        config_list.push({ username: "", password: "", protocol:"", port:"" });
        setConfigList(config_list);
        //console.log(configList);
      };

      // handle click event of the Remove button
      const handleDeleteAddlConfig = (index, category) => {
        if(!category){
            const list = [...additionalProps];
            list.splice(index, 1);
            setAdditionalProps(list);
        }else{
            const list = [...versionSpecificData];
            list.splice(index, 1);
            setVersionSpecificData(list); 
        }
        
      };
     
      // handle click event of the Add button
      const handleAddAddlConfig = () => {
        var addl_config_list = [...additionalProps];
        addl_config_list.push({ prop_type:"",
                                prop_key: "",
                                prop_label: "",
                                prop_value: "",
                                child_of:"",
                                default:"",
                                is_version_specific: false });
        setAdditionalProps(addl_config_list);
      };

      const handleDeleteAddlIntfConfig = index => {
        const list = [...additionalIntfProps];
        list.splice(index, 1);
        setAdditionalIntfProps(list);
      };
     
      // handle click event of the Add button
      const handleAddIntfConfig = () => {
        var config_list = [...additionalIntfProps];
        config_list.push({ intf_prop_type:'',
        intf_prop_key: '',
        intf_prop_label: '',
        intf_prop_value: '', });
        setAdditionalIntfProps(config_list);
      };

      const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
      };
    //   const handleDeleteRuntimeConfig = index => {
    //     const list = [...commandProps];
    //     list.splice(index, 1);
    //     setCommandProps(list);
    //   };
     
      // handle click event of the Add button
    //   const handleAddRuntimeConfig = () => {
    //     var config_list = [...commandProps];
    //     config_list.push({ key: '',
    //     label: '',
    //     value: '', });
    //     setCommandProps(config_list);
    //   };

    useEffect(() => {
        const handleTabClose = event => {
            event.preventDefault();
            localStorage.removeItem("node_id");
            localStorage.removeItem("node_version");
            localStorage.removeItem("node_name");
            //console.log('beforeunload event triggered');
            return (event.returnValue = 'Are you sure you want to exit?');
        };
        window.addEventListener('beforeunload', handleTabClose);
        dispatch(SET_NAVBAR_TITLE("Configure Element: "+node_name));
        let payload = {}
        if(mode == 'add'){
            if(localStorage.getItem('node_id') != undefined && localStorage.getItem('node_id') != ''){
                payload = { node_id: localStorage.getItem('node_id'), node_version: localStorage.getItem('node_version') };
                getNodeInventoryDetails(payload);
            }
        }else{
            payload = { node_id: node_id, node_version: node_version };
            getNodeInventoryDetails(payload);
        }
        return () => {
            window.removeEventListener('beforeunload', handleTabClose);
        };
    }, []);
    return (
      <div>
        <GridContainer>
        <GridItem xs={12}>
        <form onSubmit={formik.handleSubmit}>
          <GridItem xs={12} sm={12} md={12}>
          <GridContainer justify="left" style={{marginLeft:'20px'}}>
              <GridItem xs={6} sm={4}>
              {formik.errors.sku_id && formik.touched.node_name ? (
                  <FormControl
                      fullWidth
                      className={classes.customInputStyle}
                  >
                    <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="Name"
                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                        InputProps={{ disableUnderline: true }}
                        id="node_name"
                        value={formik.values.node_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        helperText={formik.errors.node_name}
                        error={
                            formik.errors.node_name && formik.touched.node_name
                            ? true
                            : false
                        }
                    // value={""}
                    />
                  </FormControl>
              ) : (
                  <FormControl
                      fullWidth
                      className={classes.customInputStyle}
                  >
                    <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="Name"
                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                        InputProps={{ disableUnderline: true }}
                        id="node_name"
                        value={formik.values.node_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        disabled="disabled"
                    // value={""}
                    />
                  </FormControl>
              )}
              </GridItem>

              <GridItem xs={6} sm={4}>
              {formik.errors.node_id && formik.touched.node_id ? (
                  <FormControl
                      fullWidth
                      className={classes.customInputStyle}
                  >
                    <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="SKU ID"
                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                        InputProps={{ disableUnderline: true }}
                        id="node_id"
                        value={formik.values.node_id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        helperText={formik.errors.node_id}
                        disabled="disabled"
                        error={
                            formik.errors.node_id && formik.touched.node_id
                            ? true
                            : false
                        }
                    />
                  </FormControl>
              ):(
                  <FormControl
                  fullWidth
                  className={classes.customInputStyle}
              >
                <TextField
                    style={{ paddingRight: "50px", margin: 0 }}
                    rows={1}
                    label="SKU Id"
                    InputLabelProps={{ disableAnimation: true, shrink: false }}
                    InputProps={{ disableUnderline: true }}
                    id="node_sku_id"
                    value={formik.values.node_sku_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="filled"
                    disabled="disabled"
                // value={""}
                />
              </FormControl>
              )}
              </GridItem>
              <GridItem xs={6} sm={4}>
              {formik.errors.node_version && formik.touched.node_version ? (
                  <FormControl
                      fullWidth
                      className={classes.customInputStyle}
                  >
                    <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="Version"
                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                        InputProps={{ disableUnderline: true }}
                        id="node_version"
                        value={formik.values.node_version}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        helperText={formik.errors.node_version}
                        disabled="disabled"
                        error={
                            formik.errors.node_version && formik.touched.node_version
                            ? true
                            : false
                        }
                    />
                  </FormControl>
              ) : (
                  <FormControl
                      fullWidth
                      className={classes.customInputStyle}
                  >
                    <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="Version"
                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                        InputProps={{ disableUnderline: true }}
                        id="node_version"
                        value={formik.values.node_version}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        disabled="disabled"
                    />
                  </FormControl>
              )}
              </GridItem>
              <GridContainer
                justify="flex-start"
                spacing={0}
                style={{ margin:"25px 70px 5px 15px", border:"1px solid", borderRadius:"10px"}}
              >
                <GridItem xs={4} sm={1} style={{ padding: "0px 2px 0px 15px" }}>
                  <Button justIcon round color="info">
                    <i className="material-icons">arrow_forward</i>
                  </Button>
                </GridItem>
                <GridItem xs={8} sm={11} style={{ paddingLeft: "0px" }}>
                  <h4> Element properties </h4>
                </GridItem>
              </GridContainer>
              <GridItem xs={6} sm={3}>
                {formik.errors.vcpu && formik.touched.vcpu ? (
                    <FormControl
                        fullWidth
                        variant="filled"
                        style={{ marginTop: "10", marginRight: "5px" }}
                    >
                        <InputLabel id="vcpu">
                        Number of Vcpu
                        </InputLabel>
                        <Select
                            labelId="vcpu"
                            id="vcpu"
                            labelWidth={100}
                            style={{width:"82%"}}
                            name="vcpu"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            native
                            variant="filled"
                            helperText={formik.errors.vcpu}
                            value={formik.values.vcpu}
                            error={
                                formik.errors.vcpu && formik.touched.vcpu
                                ? true
                                : false
                            }
                        >
                          <option value="">Select Vcpu</option>
                            {NO_OF_VCPU.map(value =>  
                                <option value={value}>{value}</option> 
                            )}   
                        </Select> 
                    </FormControl>
                ) : (
                    <FormControl
                        fullWidth
                        className={classes.customInputStyle}
                        variant="filled"
                        style={{ marginTop: "10", marginRight: "25px" }}
                    >
                        <InputLabel id="inv-state">
                          Number of Vcpu
                        </InputLabel>
                        <Select
                            labelId="inv-state"
                            id="vcpu"
                            labelWidth={100}
                            fullWidth
                            name="vcpu"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            native
                            variant="filled"
                            value={formik.values.vcpu}
                        >
                            <option value="">Select Vcpu</option>
                            {NO_OF_VCPU.map(value =>  
                                <option value={value}>{value}</option> 
                            )} 
                        </Select> 
                    </FormControl>
                )}
                </GridItem>
                <GridItem xs={6} sm={3}>
                {formik.errors.ram && formik.touched.ram ? (
                    <FormControl
                        fullWidth
                        variant="filled"
                        style={{ marginTop: "10", marginRight: "5px" }}
                    >
                        <InputLabel id="ram">
                        Ram (GB)
                        </InputLabel>
                        <Select
                            labelId="ram"
                            id="ram"
                            labelWidth={100}
                            style={{width:"82%"}}
                            name="ram"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            native
                            variant="filled"
                            value={formik.values.ram}
                            helperText={formik.errors.ram}
                            error={
                                formik.errors.ram && formik.touched.ram
                                ? true
                                : false
                            }
                        >
                          <option value="">Select RAM</option>
                            {RAM.map(value =>  
                                <option value={value}>{value}</option> 
                            )} 
                        </Select> 
                    </FormControl>
                ) : (
                    <FormControl
                        fullWidth
                        className={classes.customInputStyle}
                        variant="filled"
                        style={{ marginTop: "10", marginRight: "25px" }}
                    >
                        <InputLabel id="ram">
                        Ram (GB)
                        </InputLabel>
                        <Select
                            labelId="ram"
                            id="ram"
                            labelWidth={100}
                            fullWidth
                            name="ram"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            native
                            value={formik.values.ram}
                            variant="filled"
                        >
                            <option value="">Select RAM</option>
                            {RAM.map(value =>  
                                <option value={value}>{value}</option> 
                            )} 
                        </Select> 
                    </FormControl>
                )}
                </GridItem>
                <GridItem xs={6} sm={3}>
                {formik.errors.disk && formik.touched.disk ? (
                    <FormControl
                        fullWidth
                        variant="filled"
                        style={{ marginTop: "10", marginRight: "5px" }}
                    >
                        <InputLabel id="disk">
                            Disk (GB)
                        </InputLabel>
                        <Select
                            labelId="disk"
                            id="disk"
                            labelWidth={100}
                            style={{width:"82%"}}
                            name="disk"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            native
                            variant="filled"
                            helperText={formik.errors.disk}
                            value={formik.values.disk}
                            error={
                                formik.errors.disk && formik.touched.disk
                                ? true
                                : false
                            }
                        >
                          <option value="">Select DISK</option>
                            {DISK.map(value =>  
                                <option value={value}>{value}</option> 
                            )} 
                        </Select> 
                    </FormControl>
                ) : (
                    <FormControl
                        fullWidth
                        className={classes.customInputStyle}
                        variant="filled"
                        style={{ marginTop: "10", marginRight: "25px" }}
                    >
                        <InputLabel id="disk">
                        Disk (GB)
                        </InputLabel>
                        <Select
                            labelId="disk"
                            id="disk"
                            labelWidth={100}
                            fullWidth
                            name="disk"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            native
                            value={formik.values.disk}
                            variant="filled"
                        >
                            <option value="">Select DISK</option>
                            {DISK.map(value =>  
                                <option value={value}>{value}</option> 
                            )} 
                        </Select> 
                    </FormControl>
                )}
                </GridItem>
                <GridItem xs={6} sm={2}>
                {formik.errors.virt_type && formik.touched.virt_type ? (
                    <FormControl
                        fullWidth
                        variant="filled"
                        style={{ marginTop: "10", marginRight: "5px" }}
                    >
                        <InputLabel id="virt_type">
                        Virtualisation Type
                        </InputLabel>
                        <Select
                            labelId="virt_type"
                            id="virt_type"
                            labelWidth={100}
                            style={{width:"82%"}}
                            name="virt_type"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            native
                            variant="filled"
                            value={formik.values.virt_type}
                            helperText={formik.errors.virt_type}
                            error={
                                formik.errors.virt_type && formik.touched.virt_type
                                ? true
                                : false
                            }
                        >
                          <option value="">Select CPU Type</option>
                            {VIRTUALISATION_TYPE.map(value =>  
                                <option value={value}>{value}</option> 
                            )}
                        </Select> 
                    </FormControl>
                ) : (
                    <FormControl
                        fullWidth
                        className={classes.customInputStyle}
                        variant="filled"
                        style={{ marginTop: "10", marginRight: "25px" }}
                    >
                        <InputLabel id="virt_type">
                        Virtualisation Type
                        </InputLabel>
                        <Select
                            labelId="virt_type"
                            id="virt_type"
                            labelWidth={100}
                            fullWidth
                            name="virt_type"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            native
                            value={formik.values.virt_type}
                            variant="filled"
                        >
                            <option value="">Select CPU Type</option>
                            {VIRTUALISATION_TYPE.map(value =>  
                                <option value={value}>{value}</option> 
                            )}
                        </Select> 
                    </FormControl>
                )}
                </GridItem>
                <GridItem xs={6} sm={3}>
                {formik.errors.interface_type && formik.touched.interface_type ? (
                    <FormControl
                        fullWidth
                        variant="filled"
                        style={{ marginTop: "10", marginRight: "5px" }}
                    >
                        <InputLabel id="interface_type">
                        Interface Type
                        </InputLabel>
                        <Select
                            labelId="interface_type"
                            id="interface_type"
                            labelWidth={100}
                            style={{width:"82%"}}
                            name="interface_type"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            native
                            variant="filled"
                            value={formik.values.interface_type}
                            helperText={formik.errors.interface_type}
                            error={
                                formik.errors.interface_type && formik.touched.interface_type
                                ? true
                                : false
                            }
                        >
                          <option value="">Select Interface Type</option>
                            {INTERFACE_TYPE.map(value =>  
                                <option value={value}>{value}</option> 
                            )}
                        </Select> 
                    </FormControl>
                ) : (
                    <FormControl
                        fullWidth
                        className={classes.customInputStyle}
                        variant="filled"
                        style={{ marginTop: "10", marginRight: "25px" }}
                    >
                        <InputLabel id="interface_type">
                        Interface Type
                        </InputLabel>
                        <Select
                            labelId="interface_type"
                            id="interface_type"
                            labelWidth={100}
                            fullWidth
                            name="interface_type"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            native
                            value={formik.values.interface_type}
                            variant="filled"
                        >
                           <option value="">Select Interface Type</option>
                            {INTERFACE_TYPE.map(value =>  
                                <option value={value}>{value}</option> 
                            )}
                        </Select> 
                    </FormControl>
                )}
                </GridItem>
                <GridItem xs={6} sm={2}>
                {formik.errors.additional_interface && formik.touched.additional_interface ? (
                    <FormControl
                        fullWidth
                        variant="filled"
                        style={{ marginTop: "10", marginRight: "5px" }}
                    >
                        <InputLabel id="additional_interface">
                        Additional Interface
                        </InputLabel>
                        <Select
                            labelId="additional_interface"
                            id="additional_interface"
                            labelWidth={100}
                            style={{width:"82%"}}
                            name="additional_interface"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            native
                            variant="filled"
                            value={formik.values.additional_interface}
                            helperText={formik.errors.additional_interface}
                            error={
                                formik.errors.additional_interface && formik.touched.additional_interface
                                ? true
                                : false
                            }
                        >
                          <option value="">Select Additional Interfaces</option>
                            {ADDITIONAL_INTERFACE.map(value =>  
                                <option value={value}>{value}</option> 
                            )}
                        </Select> 
                    </FormControl>
                ) : (
                    <FormControl
                        fullWidth
                        className={classes.customInputStyle}
                        variant="filled"
                        style={{ marginTop: "10", marginRight: "25px" }}
                    >
                        <InputLabel id="additional_interface">
                        Additional Interface
                        </InputLabel>
                        <Select
                            labelId="additional_interface"
                            id="additional_interface"
                            labelWidth={100}
                            fullWidth
                            name="additional_interface"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            native
                            value={formik.values.additional_interface}
                            variant="filled"
                        >
                            <option value="">Select Additional Interfaces</option>
                            {ADDITIONAL_INTERFACE.map(value =>  
                                <option value={value}>{value}</option> 
                            )}
                        </Select> 
                    </FormControl>
                )}
                </GridItem>
                <Divider variant="middle" />
                <Divider variant="middle" />
                <Divider variant="middle" />
                <GridContainer
                    justify="flex-start"
                    spacing={0}
                    style={{ margin:"25px 70px 5px 15px", border:"1px solid", borderRadius:"10px"}}
                >
                    <GridItem xs={4} sm={1} style={{ padding: "0px 2px 0px 15px" }}>
                    <Button justIcon round color="info">
                        <i className="material-icons">arrow_forward</i>
                    </Button>
                    </GridItem>
                    <GridItem xs={8} sm={11} style={{ paddingLeft: "0px" }}>
                    <h4> Advanced Properties </h4>
                    </GridItem>
                </GridContainer>
                {additionalProps.map((x, i) => {
                return (
                <GridContainer style={{marginLeft: '0px'}}>
                    <GridItem xs={6} sm={2}>
                        <FormControl
                            fullWidth
                            className={classes.customInputStyle}
                            variant="filled"
                            style={{ marginTop: "10", marginRight: "25px" }}
                        >
                            <InputLabel id="prop_type">
                            Property Type
                            </InputLabel>
                            <Select
                                labelId="prop_type"
                                id="prop_type"
                                labelWidth={100}
                                fullWidth
                                name="prop_type"
                                onChange={e => handleAddlInputChange(e, i, false)}
                                native
                                value={x.prop_type}
                                variant="filled"
                            >
                                <option value="">Select Property Type</option>
                                {PROP_TYPE_LIST.map(value =>  
                                    <option value={value}>{value}</option> 
                                )}                         </Select> 
                        </FormControl>
                    </GridItem> 
                    <GridItem xs={6} sm={2} style={{ marginTop: "-15px;" }}>
                    
                    <FormControl
                        fullWidth
                        className={classes.customInputStyle}
                    >
                        <TextField
                            style={{ paddingRight: "0px", margin: 0 }}
                            rows={1}
                            label="Key"
                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                            InputProps={{ disableUnderline: true }}
                            id="prop_key"
                            name="prop_key"
                            value={x.prop_key}
                            onChange={e => handleAddlInputChange(e, i, false)}
                            variant="filled"
                        // value={""}
                        />
                    </FormControl>
                    </GridItem> 
                    <GridItem xs={6} sm={2}>
                        <FormControl
                            fullWidth
                            className={classes.customInputStyle}
                        >
                            <TextField
                                style={{ paddingRight: "0px", margin: 0 }}
                                rows={1}
                                label="Label"
                                InputLabelProps={{ disableAnimation: true, shrink: false }}
                                InputProps={{ disableUnderline: true }}
                                id="prop_label"
                                name="prop_label"
                                value={x.prop_label}
                                onChange={e => handleAddlInputChange(e, i, false)}
                                variant="filled"
                            // value={""}
                            />
                        </FormControl>
                    </GridItem>
                    
                    <GridItem xs={6} sm={2}>
                    { x.prop_type == 'property-checkbox' ? (
                        <FormControl
                            fullWidth
                            className={classes.customInputStyle}
                            variant="filled"
                            style={{ marginTop: "10", marginRight: "25px" }}
                        >
                            <InputLabel id="prop_type">
                                Value
                            </InputLabel>
                            <Select
                                labelId="prop_type"
                                id="prop_value"
                                labelWidth={100}
                                fullWidth
                                name="prop_value"
                                onChange={e => handleAddlInputTypeChange(e, i, false)}
                                native
                                value={x.prop_value}
                                variant="filled"
                            >
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </Select> 
                        </FormControl>
                    ):(
                        <FormControl
                            fullWidth
                            className={classes.customInputStyle}
                        >
                            <TextField
                                style={{ paddingRight: "0px", margin: 0 }}
                                rows={1}
                                label="Value"
                                InputLabelProps={{ disableAnimation: true, shrink: false }}
                                InputProps={{ disableUnderline: true }}
                                id="prop_value"
                                name="prop_value"
                                value={x.prop_value}
                                onChange={e => handleAddlInputChange(e, i, false)}
                                variant="filled"
                            // value={""}
                            />
                        </FormControl>
                    )}
                    </GridItem>
                    <GridItem xs={6} sm={1}>
                        <FormControl
                            fullWidth
                            className={classes.customInputStyle}
                        >
                            <TextField
                                style={{ paddingRight: "0px", margin: 0 }}
                                rows={1}
                                label="Child Of"
                                InputLabelProps={{ disableAnimation: true, shrink: false }}
                                InputProps={{ disableUnderline: true }}
                                id="child_of"
                                name="child_of"
                                value={x.child_of}
                                onChange={e => handleAddlInputChange(e, i, false)}
                                variant="filled"
                            // value={""}
                            />
                        </FormControl>
                    </GridItem>
                    <GridItem xs={6} sm={1}>
                        <FormControl
                            fullWidth
                            className={classes.customInputStyle}
                        >
                            <TextField
                                style={{ paddingRight: "0px", margin: 0 }}
                                rows={1}
                                label="Default"
                                InputLabelProps={{ disableAnimation: true, shrink: false }}
                                InputProps={{ disableUnderline: true }}
                                id="default"
                                name="default"
                                value={x.default}
                                onChange={e => handleAddlInputChange(e, i, false)}
                                variant="filled"
                            // value={""}
                            />
                        </FormControl>
                    </GridItem>
                    <GridItem xs={6} sm={1}>
                    <FormControlLabel
                        style={{ padding: "20px", marginLeft: "-40px", marginTop: "30px" }}
                        control={
                        <Checkbox
                            checked={x.is_version_specific}
                            onChange={e => handleAddlInputChange(e,i, false)}
                            name="is_version_specific"
                        />
                        }
                        label="Specific"
                            />
                    </GridItem>
                    <GridItem xs={1} sm={1}>
                        <Button
                            justIcon
                            round
                            size="sm"
                            style={{  marginTop:'45px', marginLeft: '-20px'}}
                            onClick={() => handleDeleteAddlConfig(i, false)}
                            >
                            <i className="material-icons">delete</i>
                        </Button>

                    </GridItem>
                    </GridContainer>
                    );
                })}
                {versionSpecificData.map((x, i) => {
                return (
                <GridContainer style={{marginLeft: '0px'}}>
                    <GridItem xs={6} sm={2}>
                        <FormControl
                            fullWidth
                            className={classes.customInputStyle}
                            variant="filled"
                            style={{ marginTop: "10", marginRight: "25px" }}
                        >
                            <InputLabel id="prop_type">
                            Property Type
                            </InputLabel>
                            <Select
                                labelId="prop_type"
                                id="prop_type"
                                labelWidth={100}
                                fullWidth
                                name="prop_type"
                                onChange={e => handleAddlInputChange(e, i, true)}
                                native
                                value={x.prop_type}
                                variant="filled"
                            >
                                <option value="">Select Property Type</option>
                                {PROP_TYPE_LIST.map(value =>  
                                    <option value={value}>{value}</option> 
                                )}                         </Select> 
                        </FormControl>
                    </GridItem> 
                    <GridItem xs={6} sm={2} style={{ marginTop: "-15px;" }}>
                    
                    <FormControl
                        fullWidth
                        className={classes.customInputStyle}
                    >
                        <TextField
                            style={{ paddingRight: "0px", margin: 0 }}
                            rows={1}
                            label="Key"
                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                            InputProps={{ disableUnderline: true }}
                            id="prop_key"
                            name="prop_key"
                            value={x.prop_key}
                            onChange={e => handleAddlInputChange(e, i, true)}
                            variant="filled"
                        // value={""}
                        />
                    </FormControl>
                    </GridItem> 
                    <GridItem xs={6} sm={2}>
                        <FormControl
                            fullWidth
                            className={classes.customInputStyle}
                        >
                            <TextField
                                style={{ paddingRight: "0px", margin: 0 }}
                                rows={1}
                                label="Label"
                                InputLabelProps={{ disableAnimation: true, shrink: false }}
                                InputProps={{ disableUnderline: true }}
                                id="prop_label"
                                name="prop_label"
                                value={x.prop_label}
                                onChange={e => handleAddlInputChange(e, i, true)}
                                variant="filled"
                            // value={""}
                            />
                        </FormControl>
                    </GridItem>
                    
                    <GridItem xs={6} sm={2}>
                    { x.prop_type == 'property-checkbox' ? (
                        <FormControl
                            fullWidth
                            className={classes.customInputStyle}
                            variant="filled"
                            style={{ marginTop: "10", marginRight: "25px" }}
                        >
                            <InputLabel id="prop_type">
                                Value
                            </InputLabel>
                            <Select
                                labelId="prop_type"
                                id="prop_value"
                                labelWidth={100}
                                fullWidth
                                name="prop_value"
                                onChange={e => handleAddlInputTypeChange(e, i, true)}
                                native
                                value={x.prop_value}
                                variant="filled"
                            >
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </Select> 
                        </FormControl>
                    ):(
                        <FormControl
                            fullWidth
                            className={classes.customInputStyle}
                        >
                            <TextField
                                style={{ paddingRight: "0px", margin: 0 }}
                                rows={1}
                                label="Value"
                                InputLabelProps={{ disableAnimation: true, shrink: false }}
                                InputProps={{ disableUnderline: true }}
                                id="prop_value"
                                name="prop_value"
                                value={x.prop_value}
                                onChange={e => handleAddlInputChange(e, i, true)}
                                variant="filled"
                            // value={""}
                            />
                        </FormControl>
                    )}
                    </GridItem>
                    <GridItem xs={6} sm={1}>
                        <FormControl
                            fullWidth
                            className={classes.customInputStyle}
                        >
                            <TextField
                                style={{ paddingRight: "0px", margin: 0 }}
                                rows={1}
                                label="Child Of"
                                InputLabelProps={{ disableAnimation: true, shrink: false }}
                                InputProps={{ disableUnderline: true }}
                                id="child_of"
                                name="child_of"
                                value={x.child_of}
                                onChange={e => handleAddlInputChange(e, i, true)}
                                variant="filled"
                            // value={""}
                            />
                        </FormControl>
                    </GridItem>
                    <GridItem xs={6} sm={1}>
                        <FormControl
                            fullWidth
                            className={classes.customInputStyle}
                        >
                            <TextField
                                style={{ paddingRight: "0px", margin: 0 }}
                                rows={1}
                                label="Default"
                                InputLabelProps={{ disableAnimation: true, shrink: false }}
                                InputProps={{ disableUnderline: true }}
                                id="default"
                                name="default"
                                value={x.default}
                                onChange={e => handleAddlInputChange(e, i, true)}
                                variant="filled"
                            // value={""}
                            />
                        </FormControl>
                    </GridItem>
                    <GridItem xs={6} sm={1}>
                    <FormControlLabel
                        style={{ padding: "20px", marginLeft: "-40px", marginTop: "30px" }}
                        control={
                        <Checkbox
                            checked={x.is_version_specific}
                            onChange={e => handleAddlInputChange(e,i, true)}
                            name="is_version_specific"
                        />
                        }
                        label="Specific"
                            />
                    </GridItem>
                    <GridItem xs={1} sm={1}>
                        <Button
                            justIcon
                            round
                            size="sm"
                            style={{  marginTop:'45px', marginLeft: '-20px'}}
                            onClick={() => handleDeleteAddlConfig(i, true)}
                            >
                            <i className="material-icons">delete</i>
                        </Button>

                    </GridItem>
                    </GridContainer>
                    );
                })}

                <GridContainer>
                    <GridItem xs={2} sm={1} style={{ padding: "0px 2px 0px 30px" }}>
                    <Button
                        justIcon
                        round
                        size="sm"
                        onClick={handleAddAddlConfig}
                    >
                        <i className="material-icons">add</i>
                    </Button>
                    </GridItem>
                    <GridItem xs={8} sm={10} style={{ paddingLeft: "0px" }}>
                    <h5> Add Another Advanced Property </h5>
                    </GridItem>
                </GridContainer>
                <GridContainer
                    justify="flex-start"
                    spacing={0}
                    style={{ margin:"25px 70px 5px 15px", border:"1px solid", borderRadius:"10px"}}
                >
                    <GridItem xs={4} sm={1} style={{ padding: "0px 2px 0px 15px" }}>
                    <Button justIcon round color="info">
                        <i className="material-icons">arrow_forward</i>
                    </Button>
                    </GridItem>
                    <GridItem xs={8} sm={2} style={{ paddingLeft: "0px" }}>
                    <h4> Interface Information </h4>
                    </GridItem>
                    <GridItem xs={4} sm={6} className={classes.infoicon}>
                    <Tooltip title="Some sample interface names are ge0/0, port0, eth0, Gigabitethernet0/0" placement="right" arrow>
                        <InfoIcon />
                    </Tooltip>
                    </GridItem>
                </GridContainer>
                <GridItem xs={6} sm={4}>
              {formik.errors.mgmt_interface_name && formik.touched.mgmt_interface_name ? (
                  <FormControl
                      fullWidth
                      className={classes.customInputStyle}
                  ><Tooltip title="Management interface should be bypassed for any device connectivity" arrow>
                    <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="Management Interface Name"
                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                        InputProps={{ disableUnderline: true }}
                        id="mgmt_interface_name"
                        value={formik.values.mgmt_interface_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        helperText={formik.errors.mgmt_interface_name}
                        error={
                            formik.errors.mgmt_interface_name && formik.touched.mgmt_interface_name
                            ? true
                            : false
                        }
                    // value={""}
                    /></Tooltip>
                  </FormControl>
              ) : (
                  <FormControl
                      fullWidth
                      className={classes.customInputStyle}
                  ><Tooltip title="Management interface will be bypassed for any device connectivity" arrow>
                    <TextField
                        style={{ paddingRight: "0px", margin: 0 }}
                        rows={1}
                        label="Management Interface Name"
                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                        InputProps={{ disableUnderline: true }}
                        id="mgmt_interface_name"
                        value={formik.values.mgmt_interface_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                    // value={""}
                    /></Tooltip>
                  </FormControl>
              )}
              </GridItem>
              <GridItem xs={6} sm={4}>
             
              {formik.errors.interface_name && formik.touched.interface_name ? (
                  <FormControl
                      fullWidth
                      className={classes.customInputStyle}
                  ><Tooltip title="Give the first interface name that will be used for making a connection" arrow>
                    <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="First Interface Name"
                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                        InputProps={{ disableUnderline: true }}
                        id="interface_name"
                        value={formik.values.interface_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        helperText={formik.errors.interface_name}
                        error={
                            formik.errors.interface_name && formik.touched.interface_name
                            ? true
                            : false
                        }
                    // value={""}
                    />
                    </Tooltip>
                  </FormControl>
              ) : (
                  <FormControl
                      fullWidth
                      className={classes.customInputStyle}
                  >
                      <Tooltip title="Give the first interface name that will be used for making a connection" arrow>
                    <TextField
                        style={{ paddingRight: "0px", margin: 0 }}
                        rows={1}
                        label="First Interface Name"
                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                        InputProps={{ disableUnderline: true }}
                        id="interface_name"
                        value={formik.values.interface_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                    // value={""}
                    /></Tooltip>
                  </FormControl>
              )}
              </GridItem>
              
              {additionalIntfProps.map((x, i) => {
                return (
                <GridContainer style={{marginLeft: '0px'}}>
                    <GridItem xs={6} sm={2}>
                        <FormControl
                            fullWidth
                            className={classes.customInputStyle}
                            variant="filled"
                            style={{ marginTop: "10", marginRight: "25px" }}
                        >
                            <InputLabel id="intf_prop_type">
                            Property Type
                            </InputLabel>
                            <Select
                                labelId="intf_prop_type"
                                id="intf_prop_type"
                                labelWidth={100}
                                fullWidth
                                name="intf_prop_type"
                                onChange={e => handleAddlIntfInputChange(e, i)}
                                native
                                value={x.intf_prop_type}
                                variant="filled"
                            >
                                <option value="">Select Property Type</option>
                                {INTF_PROP_TYPE_LIST.map(value =>  
                                    <option value={value}>{value}</option> 
                                )}                         </Select> 
                        </FormControl>
                    </GridItem> 
                    <GridItem xs={6} sm={2} style={{ marginTop: "-15px;" }}>
                    
                    <FormControl
                        fullWidth
                        className={classes.customInputStyle}
                    >
                        <TextField
                            style={{ paddingRight: "0px", margin: 0 }}
                            rows={1}
                            label="Key"
                            InputLabelProps={{ disableAnimation: true, shrink: false }}
                            InputProps={{ disableUnderline: true }}
                            id="intf_prop_key"
                            name="intf_prop_key"
                            value={x.intf_prop_key}
                            onChange={e => handleAddlIntfInputChange(e, i)}
                            variant="filled"
                        // value={""}
                        />
                    </FormControl>
                    </GridItem> 
                    <GridItem xs={6} sm={2}>
                        <FormControl
                            fullWidth
                            className={classes.customInputStyle}
                        >
                            <TextField
                                style={{ paddingRight: "0px", margin: 0 }}
                                rows={1}
                                label="Label"
                                InputLabelProps={{ disableAnimation: true, shrink: false }}
                                InputProps={{ disableUnderline: true }}
                                id="intf_prop_label"
                                name="intf_prop_label"
                                value={x.intf_prop_label}
                                onChange={e => handleAddlIntfInputChange(e, i)}
                                variant="filled"
                            // value={""}
                            />
                        </FormControl>
                    </GridItem>
                    
                    <GridItem xs={6} sm={4}>
                    {x.intf_prop_type == "Select" ? (
                        <FormControl
                            fullWidth
                            className={classes.customInputStyle}
                        >
                            <Tooltip title="Give the options separated by comma (,) that will  appear in the drop-down " arrow>
                            <TextField
                                style={{ paddingRight: "0px", margin: 0 }}
                                label="Values"
                                placeholder="Value1, Velue2, Value3"
                                InputLabelProps={{ disableAnimation: true, shrink: false }}
                                InputProps={{ disableUnderline: true }}
                                id="intf_prop_value"
                                name="intf_prop_value"
                                value={x.intf_prop_value}
                                onChange={e => handleAddlIntfInputChange(e, i)}
                                variant="filled"
                            />
                            </Tooltip>
                        </FormControl>
                    ):(
                        <FormControl
                            fullWidth
                            className={classes.customInputStyle}
                        >
                            <TextField
                                style={{ paddingRight: "0px", margin: 0 }}
                                rows={2}
                                label="Value"
                                InputLabelProps={{ disableAnimation: true, shrink: false }}
                                InputProps={{ disableUnderline: true }}
                                id="intf_prop_value"
                                name="intf_prop_value"
                                value={x.intf_prop_value}
                                onChange={e => handleAddlIntfInputChange(e, i)}
                                variant="filled"
                            // value={""}
                            />
                        </FormControl>
                    )}
                    </GridItem>
                    <GridItem xs={1} sm={1}>
                        <Button
                            justIcon
                            round
                            size="sm"
                            style={{  marginTop:'45px', marginLeft: '-20px'}}
                            onClick={() => handleDeleteAddlIntfConfig(i)}
                            >
                            <i className="material-icons">delete</i>
                        </Button>

                    </GridItem>
                    </GridContainer>
                    );
                })}
                <GridContainer>
                    <GridItem xs={2} sm={1} style={{ padding: "0px 2px 0px 30px" }}>
                    <Button
                        justIcon
                        round
                        size="sm"
                        onClick={handleAddIntfConfig}
                    >
                        <i className="material-icons">add</i>
                    </Button>
                    </GridItem>
                    <GridItem xs={8} sm={10} style={{ paddingLeft: "0px" }}>
                    <h5> Add Another Interface Property</h5>
                    </GridItem>
                </GridContainer>
                <GridContainer
                justify="flex-start"
                spacing={0}
                style={{ margin:"25px 70px 5px 15px", border:"1px solid", borderRadius:"10px"}}
              >
                <GridItem xs={4} sm={1} style={{ padding: "0px 2px 0px 15px" }}>
                  <Button justIcon round color="info">
                    <i className="material-icons">arrow_forward</i>
                  </Button>
                </GridItem>
                <GridItem xs={8} sm={11} style={{ paddingLeft: "0px" }}>
                  <h4> Node Accessibility Configuration </h4>
                </GridItem>
              </GridContainer>
              {configList.map((x, i) => {
                return (
            <GridContainer style={{marginLeft: '0px'}}>
                <GridItem xs={6} sm={2}>
                    <FormControl
                        fullWidth
                        className={classes.customInputStyle}
                        variant="filled"
                        style={{ marginTop: "10", marginRight: "25px" }}
                    >
                        <InputLabel id="protocol">
                        Protocol
                        </InputLabel>
                        <Select
                            labelId="protocol"
                            id="protocol"
                            labelWidth={100}
                            fullWidth
                            name="protocol"
                            onChange={e => handleInputChange(e, i)}
                            native
                            value={x.protocol}
                            variant="filled"
                        >
                            <option value="">Select Protocol</option>
                            {PROTOCOL_LIST.map(value =>  
                                <option value={value}>{value}</option> 
                            )}                         </Select> 
                    </FormControl>
                </GridItem> 
                <GridItem xs={6} sm={2} style={{ marginTop: "-15px;" }}>
                
                <FormControl
                      fullWidth
                      className={classes.customInputStyle}
                  >
                    <TextField
                        style={{ paddingRight: "0px", margin: 0 }}
                        rows={1}
                        label="Port"
                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                        InputProps={{ disableUnderline: true }}
                        id="port"
                        name="port"
                        value={x.port}
                        onChange={e => handleInputChange(e, i)}
                        variant="filled"
                    // value={""}
                    />
                  </FormControl>
            </GridItem> 
              <GridItem xs={6} sm={3}>
                  <FormControl
                      fullWidth
                      className={classes.customInputStyle}
                  >
                    <TextField
                        style={{ paddingRight: "0px", margin: 0 }}
                        rows={1}
                        label="Default Username"
                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                        InputProps={{ disableUnderline: true }}
                        id="username"
                        name="username"
                        value={x.username}
                        onChange={e => handleInputChange(e, i)}
                        variant="filled"
                    // value={""}
                    />
                  </FormControl>
              </GridItem>
              
              <GridItem xs={6} sm={3}>
                  <FormControl
                      fullWidth
                      className={classes.customInputStyle}
                  >
                    <TextField
                        style={{ paddingRight: "0px", margin: 0 }}
                        rows={1}
                        label="Default Password"
                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                        InputProps={{ disableUnderline: true }}
                        id="password"
                        name="password"
                        value={x.password}
                        onChange={e => handleInputChange(e, i)}
                        variant="filled"
                    // value={""}
                    />
                  </FormControl>
              </GridItem>
              
            <GridItem xs={1} sm={1}>
               
                    <Button
                    justIcon
                    round
                    size="sm"
                    style={{  marginTop:'45px', marginLeft: '-20px'}}
                    onClick={() => handleDeleteConfig(i)}
                    >
                    <i className="material-icons">delete</i>
                    </Button>

                </GridItem>
            </GridContainer>
            );
            })}
            <GridContainer>
                <GridItem xs={2} sm={1} style={{ padding: "0px 2px 0px 30px" }}>
                  <Button
                    justIcon
                    round
                    size="sm"
                    onClick={handleAddConfig}
                  >
                    <i className="material-icons">add</i>
                  </Button>
                </GridItem>
                <GridItem xs={8} sm={10} style={{ paddingLeft: "0px" }}>
                  <h5> Add Another Node Accessibility Configuration </h5>
                </GridItem>
            </GridContainer>

            <GridContainer
            justify="flex-start"
            spacing={0}
            style={{ margin:"25px 70px 5px 15px", border:"1px solid", borderRadius:"10px"}}
        >
            <GridItem xs={4} sm={1} style={{ padding: "0px 2px 0px 15px" }}>
            <Button justIcon round color="info">
                <i className="material-icons">arrow_forward</i>
            </Button>
            </GridItem>
            <GridItem xs={8} sm={11} style={{ paddingLeft: "0px" }}>
            <h4> Runtime Configuration </h4>
            </GridItem>
        </GridContainer>
        <GridContainer style={{marginLeft: '0px'}}>
            
            
            <GridItem xs={6} sm={10}>
                <FormControl
                    fullWidth
                    className={classes.customInputStyle}
                >
                <TextField
                    style={{ paddingRight: "0px", margin: 0 }}
                    rows={10}
                    multiline
                    label="Commands"
                    InputLabelProps={{ disableAnimation: true, shrink: false }}
                    InputProps={{ disableUnderline: true }}
                    id="command_props"
                    name="command_props"
                    value={formik.values.command_props}
                    onChange={formik.handleChange}
                    variant="filled"
                />
                </FormControl>
            </GridItem>
        </GridContainer>
        <GridContainer
            justify="flex-start"
            spacing={0}
            style={{ margin:"25px 70px 5px 15px", border:"1px solid", borderRadius:"10px"}}
        >
            <GridItem xs={4} sm={1} style={{ padding: "0px 2px 0px 15px" }}>
            <Button justIcon round color="info">
                <i className="material-icons">arrow_forward</i>
            </Button>
            </GridItem>
            <GridItem xs={8} sm={11} style={{ paddingLeft: "0px" }}>
            <h4> Other Configurations </h4>
            </GridItem>
        </GridContainer>
        <GridContainer style={{marginLeft: '0px'}}>
            <GridItem xs={12} sm={6}>
                <FormControlLabel
                    style={{ padding: "20px", marginLeft: "-20px", marginTop: "5px" }}
                    control={
                    <Checkbox
                        checked={isEdgeDevice}
                        onChange={handleOtherConfigChange}
                        name="is_edge_device"
                    />
                    }
                    label="Is Edge Device "
                        />
                </GridItem>
        </GridContainer>
        </GridContainer>
        </GridItem>
        <div>
        <Paper square elevation={0} className={classes.resetContainer}>
        <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              right
              style={{float:'right'}}
              type="submit"
            >
              Next
          </Button>
          
          <Button onClick={props.handleBack} className={classes.button}>
            Back
        </Button>
        </Paper>
                  </div>
        {/* <Button
              variant="contained"
              color="primary"
              className={classes.button}
              right
              style={{float:'right'}}
              type="submit"
            >
              Next
          </Button> */}
        </form>
        </GridItem>
        </GridContainer>
    </div>
  );
};

export default ConfigureNode;
