import React from "react";
import { makeStyles } from "@material-ui/styles";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles({
  imageIcon: {
    display: "flex",
    height: "inherit",
    width: "inherit"
  },
  iconRoot: {
    textAlign: "center",
    "& svg": {
      fill: "#fff",
    },
    "& svg:hover": {
      fill: "#F96B13"
    }
  },
  iconRoot2: {
    textAlign: "center",
    "& svg": {
      fill: "#fff",
      "& path": {
        stroke: "#fff"
      }
    },
    "& svg:hover": {
      fill: "#F96B13"
    }
  }
});

export default function SvgtoIcon(props) {
  const classes = useStyles();
  
  let classN = props.type === "stroke" ? classes.iconRoot2 : classes.iconRoot
  return (
    <Icon classes={{ root: classN }}>
      {props.isString ? (
        <img className={classes.imageIcon} alt="logo" src={props.svgSource} />
      ) : (
        <props.svgSource />
      )}
    </Icon>
  );
}