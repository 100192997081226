export const FORM_FIELDS = {
  name: "",
  // description: "",
  // version: "",
  category: "Custom Elements",
  display_url: "",
  // image_url: "",
  no_of_vcpu: "1",
  ram: "1",
  disk: "10",
  cpu_type: "Pass Through",
  virt_type: "qemu",
  interface_type: "e1000",
  post_inst_script: "",
  interface_name: "",
  additional_interface: "1",
  configuration_setting: [
    { id: 0, application_name: "HTTP0", protocol: "HTTP", port: 80 },
  ],
  externalservices_setting: [
    { id: 0, application_name: "TCP0", protocol: "TCP", port: 5900 },
  ],
};

// represent the text shown on page to user.
export const FORM_LABELS = {
  name: "Name",
  // description: "Description",
  // version: "Version",
  category: "Category",
  display_url: "Image url",
  // image_url: "Image Url",
  no_of_vcpu: "Number of Vcpu",
  ram: "Ram (GB)",
  disk: "Disk (GB)",
  cpu_type: "CPU Type",
  virt_type: "Virtualisation Type",
  interface_type: "Interface Type",
  post_inst_script: "Post Inst Script",
  interface_name: "Interface Name",
  additional_interface: "Additional Interface",
  configuration_setting: "Configuration Settings",
  externalservices_setting: "External Services Settings",
};

export const CATEGORY = [
  "Custom Devices",
  "Generic Shapes",
  "Cisco Networking",
  "Desktops",
  "viptela1",
  "Linux Networking",
  "Viptela Objects",
  "Transports",
  "Traffic Generators",
];
export const NO_OF_VCPU = [1, 2, 4, 8, 16, 32];
export const RAM = [1, 2, 4, 8, 16, 32];
export const DISK = [10, 20, 40, 50, 80, 100];
export const CPU_TYPE = ["Pass Through", "Model"];
export const VIRTUALISATION_TYPE = ["qemu", "kvm"];
export const INTERFACE_TYPE = ["e1000", "VIRTIO"];
export const ADDITIONAL_INTERFACE = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
];

// wizard constants

export const RADIO_OPTIONS = {
  PUBLIC_URL: "Public URL",
  UPLOAD_FROM_COMPUTER: "Upload from computer",
  SELECT_FROM_EXISTING: "Saved Images",
  ADD_QCOW2: "Upload Device image in qcow2 format",
};
