import React from "react";
import { Skeleton } from "@material-ui/lab";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import theme from "../../theme";
const MySkeleton = ({ children, isLoading }) => {
  if (isLoading) {
    return (
      <>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "2.5rem",
          }}
        >
          <Skeleton variant="text" sx={{ fontSize: "1rem" }} width={200} />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "2.5rem",
          }}
        >
          <Typography variant="h1">
            {isLoading ? <Skeleton width={300} height={100} /> : "h1"}
          </Typography>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h1">
            {isLoading ? <Skeleton width={300} height={100} /> : "h1"}
          </Typography>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h1">
            {isLoading ? <Skeleton width={300} height={100} /> : "h1"}
          </Typography>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h1">
            {isLoading ? <Skeleton width={300} height={100} /> : "h1"}
          </Typography>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h1">
            {isLoading ? <Skeleton width={300} height={100} /> : "h1"}
          </Typography>
        </div>
      </>
    );
  } else {
    return <>{children}</>;
  }
};

MySkeleton.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node,
};
export default MySkeleton;
