// View that gets rendered when launch new lab is clicked.
import React, { Component } from "react";
import { Api, Urls, Offerings } from "config/api";
import LaunchNewOfferingCard from "views/Components/ServiceOffering/LaunchNewOfferingCard";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { PRIMARY_COLOR } from "global_constants/app_constants";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { HIDE_LOADER } from "action_creators/actioncreator";
import { SHOW_LOADER } from "action_creators/actioncreator";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import globalTheme from "../../../theme";
import { v4 as uuidv4 } from "uuid";
import { asyncSetState } from "utils/utilfunction";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import { CMSAPIWithToken } from "apis/CMS";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import "views/ServiceOffering/LearningLab/learning.css";
import { getSandboxesCluster } from "apis/APIServices";
import LearningCards from "../LearningLab/LearningCards";
import ExperienceCenterUpgrade from "views/Components/ExperienceCenterUpgrade";
import { getResourcesStatus } from "apis/APIServices";
import { getServiceStatus } from "apis/APIServices";
import ScheduleCluster from "../../../views/ScheduleCluster/ScheduleCluster";
import Loader from "react-loader-spinner";
import { LOADER_TYPE } from "global_constants/app_constants";
import Chatbot from "views/Components/chatbot/Chatbot";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={4}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

const useStyles = makeStyles({
  labType: {
    bottom: 0,
    // marginLeft: "15px",
    // paddingLeft: "15px",
    paddingRight: "15px",
    cursor: "pointer",
    marginBottom: "0px",
    paddingBottom: "10px",
    fontSize: "14px",
    fontWeight: "normal",
    "&:hover": {
      color: PRIMARY_COLOR
    }
  }
});

const customStyles = theme => ({
  headerStyle: {
    backgroundColor: "white",
    margin: "15px",
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      margin: "15px"
    }
  },
  launchLabTtile: {
    bottom: 0,
    marginLeft: "15px",
    paddingLeft: "15px",
    paddingRight: "15px",
    marginBottom: "0px",
    paddingBottom: "10px",
    fontSize: "36px",
    fontWeight: "400",
    color: "black"
  },
  customTabRoot: {
    color: `#000`,
    "& .Mui-selected .MuiTab-wrapper": {
      color: `${theme.palette.secondary.main}`
    },
    "& .MuiTab-wrapper": {
      fontFamily: theme.palette.fontList.selectedFont
    }
  },
  font: {
    fontFamily: theme.palette.fontList.selectedFont
  }
});

const selectedlabTypeStyle = {
  color: globalTheme.palette.secondary.main
};

//const templateFilters = ["All", "Recent"];
// Custom notes information object in the current component
const notes = {
  reservedIpNote:
    "Option selected will reflect the IP address for the sales demo."
};
const EXPERIENCEFILTER = "Experience Center - Showcases";

class LaunchSalesdemo extends Component {
  constructor(props) {
    super(props);
    this.offeringType = Offerings.SALES_DEMO;
    this.state = {
      adminLabArray: undefined,
      labIDToNameMap: {},
      reservedIp: null,
      templateFilters: ["All", "Recent"],
      currentLabType: "",
      activeLabsTime: {},
      labTypetoLabMap: {},
      allLabTemplates: [],
      provisionedLab: {},
      reservedIpLoaded: false,
      canLaunch: true,
      value: 0,
      subscribedList: [],
      subscribedListTemp: [],
      profileDetails: null,
      resourceDetails: null,
      searchfilter: [],
      searchText: "",
      currentTabIndex: 0,
      activeLabs: [],
      inactiveLabs: [],
      subscribedLabs: [],
      unsubscribedLabs: [],
      allLabs: [],
      allLabsTemp: [],
      activeLabsTemp: [],
      inactiveLabsTemp: [],
      cmsdata: [],
      isProfileLoaded: false,
      currentLabSubItem: "All",
      activeLabSubItem: "Active",
      selectedRows: [],
      isResourceLoaded: false,
      resources: [],
      services: [],
      sections: [
        {
          name: "Designer Templates",
          id: "designer",
          ScreenName: "Design",
          Upgrade: "Design Center"
        },
        {
          name: "Learning labs",
          id: "learning",
          ScreenName: "Learning",
          Upgrade: "Learning Center"
        },
        {
          name: "Experience Center - Showcases",
          id: "sales_demo",
          tempId: "showcases",
          ScreenName: "Showcases",
          Upgrade: "Experience Center"
        },
        {
          name: "Experience Center - Sandboxes",
          id: "poc",
          tempId: "sandboxes",
          ScreenName: "Sandboxes",
          Upgrade: "Experience Center"
        }
      ]
    };
  }
  // subscribe to a learning lab
  subscribeLab = (labIdentifier, customerName, demoName) => {
    const data = {
      learning: labIdentifier,
      auto_renew: false
    };
    if (customerName) {
      data["cust_name"] = customerName;
    }
    if (demoName) {
      data["name"] = demoName;
    }
    return SDCloudBackendAPIWithToken().post(Api.subscribeLearningLab, data);
  };

  // provision a learning lab
  provisionLab = (
    labIdentifier,
    isReservedIp,
    ip,
    purpose,
    testValue,
    dealId,
    // zone,
    instance_type,
  ) => {
    const data = {
      learning: labIdentifier
    };
    if (isReservedIp) {
      data["reserved_ip"] = isReservedIp;
    }
    if (ip) {
      data["ip_address"] = ip;
    }
    if (purpose) {
      data["purpose"] = purpose;
    }
    if (testValue) {
      data["mode"] = testValue;
    }
    if (dealId) {
      data["deal_id"] = dealId;
    }
    // if (zone) {
    //   data["zone"] = zone;
    // }
    if (instance_type) {
      data["instance_type"] = instance_type;
    }
    return SDCloudBackendAPIWithToken()
      .post(Api.provisionLearningLab, data)
      .then(res => {
        return res.data.uuid;
      });
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const { adminLabArray, currentLabType } = this.state;
    const labTypetoLabMap = {};
    let labIDToNameMap = this.state.labIDToNameMap;

    if (
      prevState.currentLabType != this.state.currentLabType &&
      currentLabType != "ActiveLab"
    ) {
      if (adminLabArray && adminLabArray.length > 0) {
        adminLabArray.forEach(item => {
          if (labTypetoLabMap[labIDToNameMap[item.admin_lab_types_id].name]) {
            labTypetoLabMap[labIDToNameMap[item.admin_lab_types_id].name].push(
              item
            );
          } else {
            labTypetoLabMap[labIDToNameMap[item.admin_lab_types_id].name] = [
              item
            ];
          }
        });
      }
      this.setState({
        labTypetoLabMap
      });
      this.handleHeaderClick(currentLabType);
    }

    if (prevState.currentLabType !== this.state.currentLabType) {
      // this.setState({
      //   isProfileLoaded: true
      // });
      if (this.offeringType.title === Offerings.POC_LIBRARY.title) {
        const res = await getSandboxesCluster();
        if (!res.success) {
          // const payload = {
          //   message: res.msg || "unknown error occured",
          //   color: "danger"
          // };
          // this.props.dispatch(SHOW_NOTIFICATION({ payload }));

          this.props.dispatch(HIDE_LOADER());
        } else {
          const clouds = res.data.clouds;
          const activeLabs = clouds.filter(
            item => !item.deleted && item.uuid && item.status !== "suspended"
          );
          const terminatedLabs = clouds.filter(item => item.deleted);
          const inactiveLabs = clouds.filter(
            item =>
              (!item.deleted && item.status === "suspended") ||
              item.status === "terminated"
          );
          let updatedState = {
            activeLabs,
            inactiveLabs,
            allLabs: activeLabs.concat(inactiveLabs).concat(terminatedLabs)
          };
          if (activeLabs.length === 0) {
            updatedState = { ...updatedState, show: true };
          } else {
            updatedState = { ...updatedState, show: false, learn: clouds };
          }

          this.setState(updatedState);
          this.setState({
            isResourceLoaded: true
          });
          this.props.dispatch(HIDE_LOADER());
        }
      } else {
        SDCloudBackendAPIWithToken()
          .get(Api.getNewLearning)
          .then(res => {
            let activeLabs = [];
            let inactiveLabs = [];
            let allLabs = [];
            const is_scripted_demo = this.offeringType === Offerings.SALES_DEMO;
            res.data.learnings.map(x => {
              this.state.cmsdata &&
                this.state.cmsdata.map(cms => {
                  if (x.name === cms.identifier) {
                    if (x.is_scripted_demo === is_scripted_demo) {
                      if (x.is_active) {
                        activeLabs.push(x);
                      } else {
                        inactiveLabs.push(x);
                      }
                    }
                  }
                });
            });
            allLabs = activeLabs.concat(inactiveLabs);
            this.setState({
              activeLabs,
              inactiveLabs,
              allLabs,
              allLabsTemp: allLabs,
              activeLabsTemp: activeLabs,
              inactiveLabsTemp: inactiveLabs
            });
            if (activeLabs.length === 0) {
              this.setState({ show: true });
            } else {
              this.setState({ show: false, learn: res.data.learnings });
            }
          })
          .then(() => {
            this.setState({
              isResourceLoaded: true
            });

            this.props.dispatch(HIDE_LOADER());
          })
          .catch(err => {
            // const payload = {
            //   message: err?.response?.data?.message || "unknown error occured",
            //   color: "danger",
            //   error: err
            // };
            // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
          })
          .finally(() => {
            // this.props.dispatch(HIDE_LOADER());
          });
      }
    }
    if (prevProps.labFilter !== this.props.labFilter) {
      this.setState({
        labFilter: this.props.labFilter
      });
    }
  };

  launchLabBtnHandler = async (
    labId,
    labIdentifier,
    labEnabled,
    labName,
    isReservedIp = null,
    ip = null,
    customerName = null,
    demoName = null,
    purpose = null,
    // zone = null,
    instance_type = null,
    testValue = null,
    dealId = null
  ) => {
    let uuid = null;
    let redirectRoute = Urls.accessSalesDemo;
    this.props.dispatch(SHOW_LOADER());
    try {
      await this.subscribeLab(labIdentifier, customerName, demoName).catch(
        err => {
          let stateParams = {
            labId: labId,
            labEnabled: labEnabled,
            labIdentifier: labIdentifier,
            uuid: uuid,
            labName: labName
          };
          let additionParams = stateParams;
          additionParams["offeringType"] = Offerings.SALES_DEMO;
          this.props.history.push({
            pathname: redirectRoute,
            state: { stateParams: additionParams },
            search: `?labId=${labId}&uuid=${uuid}&labIdentifier=${labIdentifier}&labEnabled=${labEnabled}&labName=${labName}&offeringType=${Offerings.SALES_DEMO.title}`
          });

          this.props.dispatch(HIDE_LOADER());
          throw err?.response?.data?.message || "error in subscribing";
        }
      );
      uuid = await this.provisionLab(
        labIdentifier,
        isReservedIp,
        ip,
        purpose,
        // zone,
        instance_type,
        testValue,
        dealId
      ).catch(err => {
        throw err?.response?.data?.message || "error in provisioning";
      });
      const stateParams = {
        labId: labId,
        labEnabled: labEnabled,
        labIdentifier: labIdentifier,
        uuid: uuid,
        labName: labName
      };
      if (isReservedIp) {
        stateParams["reserved_ip"] = true;
      }
      if (ip) {
        stateParams["ip_address"] = ip;
      }
      if (dealId) {
        stateParams["deal_id"] = dealId;
      }
      if (uuid) {
        let additionParams = stateParams;
        additionParams[
          "addHoursDetails"
        ] = this.props.profile.profileObj.add_hours;
        additionParams["offeringType"] = Offerings.SALES_DEMO;
        await this.props.history.push({
          pathname: redirectRoute,
          state: { stateParams: additionParams },
          search: `?labId=${labId}&uuid=${uuid}&labIdentifier=${labIdentifier}&labEnabled=${labEnabled}&labName=${labName}&offeringType=${Offerings.SALES_DEMO.title}`
        });
      }

      await this.props.dispatch(HIDE_LOADER());
    } catch (err) {
      this.props.dispatch(HIDE_LOADER());
      const payload = {
        message: err,
        color: "danger"
      };
      this.props.dispatch(SHOW_NOTIFICATION({ payload }));
    }
  };

  accessLabBtnHandler = stateParams => {
    let redirectRoute = Urls.accessSalesDemo;

    let additionParams = stateParams;
    additionParams["addHoursDetails"] = this.props.profile.profileObj.add_hours;
    additionParams["offeringType"] = Offerings.SALES_DEMO;
    this.props.history.push({
      pathname: redirectRoute,
      state: { stateParams: additionParams },
      search: `?labId=${stateParams.labId}&uuid=${stateParams.uuid}&labIdentifier=${stateParams.labIdentifier}&labEnabled=${stateParams.labEnabled}&labName=${stateParams.labName}&expiryDate=${stateParams.expiryDate}&createdDate=${stateParams.createdDate}&offeringType=${stateParams.offeringType.title}`
    });
  };

  // api call to find active labs
  getNewLearnings = async () => {
    return SDCloudBackendAPIWithToken()
      .get(Api.getNewLearning)
      .then(res => {
        let activeLabs = { names: [], userNames: {} };
        let provisionedLab = { labData: [], userNames: {} };
        res.data.learnings.forEach(item => {
          if (item.lab_uuid) {
            provisionedLab["labData"].push({
              labName: item.name,
              uuid: item.lab_uuid
            });
            if (
              provisionedLab.userNames[item.name] &&
              provisionedLab.userNames[item.name] ==
                localStorage.getItem("userName")
            ) {
            } else {
              Object.assign(provisionedLab["userNames"], {
                [item.name]: item.user_name
              });
            }
          }
          if (item.is_active === true) {
            this.setState(prevState => ({
              activeLabsTime: {
                ...prevState.activeLabsTime,
                [item.name]: {
                  createdDate: item.created,
                  expiryDate: item.expiry_date
                }
              }
            }));
            activeLabs["names"].push(item.name);
            if (
              activeLabs.userNames[item.name] &&
              activeLabs.userNames[item.name] ==
                localStorage.getItem("userName")
            ) {
            } else {
              Object.assign(activeLabs["userNames"], {
                [item.name]: item.user_name
              });
            }
          }
        });
        return { activeLabs, provisionedLab };
      })
      .then(({ activeLabs, provisionedLab }) => {
        return (
          asyncSetState(
            {
              activeLabs,
              provisionedLab
            },
            this
          )
            // .then(() => this.getAdminLabTypes())
            .catch(err => {
              throw err;
            })
        );
      })
      .catch(err => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err,
        //   showErrorPage: true
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  // api call to find lab mappings
  // independent of sales demo or learning lab. calls a `general` api.
  getAdminLabTypes = async () => {
    let currentTabScreen;
    let currentTabIndex;
    if (JSON.parse(localStorage.getItem("profileData")).privilege === "admin") {
      currentTabIndex = 0;
      currentTabScreen = "All";
    } else {
      currentTabIndex = 1;
      currentTabScreen = "Subscribed";
    }
    if (
      JSON.parse(localStorage.getItem("profileData")).privilege === "admin" ||
      JSON.parse(localStorage.getItem("profileData")).privilege ===
        "profile_admin"
    ) {
      if (this.props.location.state && this.props.location.state.currentTab) {
        currentTabScreen =
          this.props.location.state.currentTab === "Active"
            ? "ActiveLab"
            : "All";
      }
      if (this.props.location.state && this.props.location.state.currentTab) {
        currentTabIndex =
          this.props.location.state.currentTab === "Active" ? 1 : 0;
      }
    } else {
      if (this.props.location.state && this.props.location.state.currentTab) {
        currentTabScreen =
          this.props.location.state.currentTab === "Active"
            ? "ActiveLab"
            : this.props.location.state.currentTab === "Subscribed"
            ? "Subscribed"
            : "All";
      }
      if (this.props.location.state && this.props.location.state.currentTab) {
        currentTabIndex =
          this.props.location.state.currentTab === "Active"
            ? 2
            : this.props.location.state.currentTab === "Subscribed"
            ? 1
            : 0;
      }
    }
    this.setState({
      value: currentTabIndex,
      templateFilters: [currentTabScreen, "Recent"]
    });
    return CMSAPIWithToken()
      .get(Api.adminLabTypes)
      .then(res => {
        const labIDToNameMap = {};
        res.data.results.forEach(item => {
          labIDToNameMap[item.id] = {
            sequence: item.sequence,
            name: item.name
          };
        });
        this.setState({
          labIDToNameMap
        });
      })
      .then(() => {
        // if (this.props.profile.profileObj.privilege === "developer") {
        //   return this.getResources()
        //     .then(() => {
        //       return this.getProfile();
        //     })
        // } else if (
        //   this.props.profile.profileObj.privilege === "admin" ||
        //   this.props.profile.profileObj.privilege === "profile_admin"
        // ) {
        //   return this.getResources();
        // }
      })
      .catch(err => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err,
        //   showErrorPage: true
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };
  //get resources for user type admin
  getResources = async () => {
    const isSalesDemo = this.offeringType === Offerings.SALES_DEMO;
    return SDCloudBackendAPIWithToken()
      .get(Api.getResource_learnigs)
      .then(res => {
        let getResourcesDetails = {}; // { labname : { ...details }}

        if (res.data.hasOwnProperty("learnings")) {
          // creating a mapping of lab Name to lab details.
          res.data.learnings.forEach(item => {
            // is_scripted_demo = false implies not a sales demo.
            if (item.is_scripted_demo === isSalesDemo) {
              getResourcesDetails[item.name] = { ...item };
              delete getResourcesDetails.name; // delete name field to align with getProfile data.
            }
          });
        }

        return getResourcesDetails;
      })
      .then(getResourcesDetails => {
        this.setState({
          resourceDetails: getResourcesDetails
        });
      })
      .catch(err => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err,
        //   showErrorPage: true
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  // gets lab name and tries related data from backend db for user type non admin.
  getProfile = () => {
    const isSalesDemo = this.offeringType === Offerings.SALES_DEMO;
    return SDCloudBackendAPIWithToken()
      .get(Api.getProfile)
      .then(res => {
        let getProfileDetails = {}; // { labname : { ...labDetails }}
        res.data.profiles[0].permissions.forEach(item => {
          // is_scripted_demo = false implies not a sales demo.
          if (item.is_scripted_demo === isSalesDemo) {
            getProfileDetails[item.resource_name] = { ...item };
          }
        });
        return getProfileDetails;
      })
      .then(getProfileDetails => {
        this.setState({
          profileDetails: getProfileDetails
        });
        // return this.getCmsData(getProfileDetails);
      })
      .catch(err => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err,
        //   showErrorPage: true
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  getInventory = () => {
    let learningsInventoryData = {};
    SDCloudBackendAPIWithToken()
      .get(`/inventory/view_inventories/`)
      .then(resp => resp.data.inventories)
      .then(resp => {
        resp.forEach(item => {
          if (item.inventory_type == "Showcase") {
            learningsInventoryData[item.sku_name] = item.tags;
          }
          this.setState({
            learningsInventoryData: learningsInventoryData
          });
        });
      });
  };

  // gets lab related data from cms db.
  // merging of cms data and backend get_profile data. data from get_profile will overwrite cms data.
  getCmsData = async (getProfileDetails = null, getResourcesDetails = null) => {
    let getResourceLabs = {};
    if (getProfileDetails && getResourcesDetails) {
      let getProfileLabs = Object.keys(getProfileDetails);
      let resourceKeys = Object.keys(getResourcesDetails);
      resourceKeys.forEach((item, index) => {
        if (
          !getProfileLabs.includes(getResourcesDetails[item].name) &&
          !getProfileLabs.includes(getResourcesDetails[item].resource_name)
        ) {
          getResourceLabs[item] = getResourcesDetails[item];
          getResourceLabs[item]["access"] = "no";
        }
        // getResourceLabs[item] = getResourcesDetails[item];
      });
    } else {
      getResourceLabs = getResourcesDetails;
    }
    getProfileDetails = { ...getProfileDetails, ...getResourceLabs };
    const getCmsDataUrl = Api.adminSalesDemo(1000);
    // this.offeringType === Offerings.SALES_DEMO.resource
    //   ? Api.viewLearningLab(1000)
    //   : Api.adminSalesDemo(1000);
    return CMSAPIWithToken()
      .get(getCmsDataUrl)
      .then(res => {
        this.setState({ cmsdata: res.data.results });
        let cmsData = res.data.results;
        // creating a mapping of labName to lab details for cms data.
        let cmsLabtoDetailsMap = [];
        cmsData.forEach(item => {
          cmsLabtoDetailsMap[item.identifier] = { ...item };
        });
        // Array containing combined data from getProfile/resources and cms data.
        let cmsAndProfileCombinedData = [];
        // Array of lab names derived from getProfile / resoures.
        let labNameKeysArrGetProfile = Object.keys(getProfileDetails);

        // combining data from cms and profile/resources
        for (let i = 0; i < labNameKeysArrGetProfile.length; i++) {
          if (cmsLabtoDetailsMap[labNameKeysArrGetProfile[i]]) {
            cmsAndProfileCombinedData.push({
              // data from cms { labName : { ...labDetails }}
              ...cmsLabtoDetailsMap[labNameKeysArrGetProfile[i]],
              // data from getProfile/resources { labName : { ...labDetails }}
              ...getProfileDetails[labNameKeysArrGetProfile[i]],
              // if a lab is active from getNewLearnings then add it to combined data.
              isLabActive: this.state.activeLabs.names.includes(
                labNameKeysArrGetProfile[i]
              ),
              userName: this.state.provisionedLab.userNames[
                labNameKeysArrGetProfile[i]
              ]
                ? this.state.provisionedLab.userNames[
                    labNameKeysArrGetProfile[i]
                  ]
                : this.state.activeLabs.userNames[labNameKeysArrGetProfile[i]],
              activeLabTime: this.state.activeLabsTime[
                labNameKeysArrGetProfile[i]
              ],
              isLabProvisioned: this.state.provisionedLab.labData
                .flatMap(x => x.labName)
                .includes(labNameKeysArrGetProfile[i]),
              uuid: this.state.provisionedLab.labData.find(
                x => x.labName === labNameKeysArrGetProfile[i]
              )
                ? this.state.provisionedLab.labData.find(
                    x => x.labName === labNameKeysArrGetProfile[i]
                  ).uuid
                : null
            });
          }
        }
        // segregating data based on lab type
        const labTypetoLabMap = {}; // { primer : {...} , advance : { ... }}
        let labIDToNameMap = this.state.labIDToNameMap; // { 2 : "advance" , 3 : "primer" }
        const launchAccessList = []; // [ { {id: 2, short_title: "AAR" , type : "Learning" }]
        const noAccessList = [];
        const labProvisionedList = [];
        const subscribedList = [];
        cmsAndProfileCombinedData.forEach(item => {
          let tries = Math.floor(
            (item.allocated_hours - item.consumed_hours) / item.labhours
          );
          let availableTries = tries < 1 ? 0 : tries;

          if (item.isLabActive && item.isLabProvisioned) {
            labProvisionedList.push(item);
          } else if (availableTries < 1) {
            noAccessList.push(item);
          } else {
            launchAccessList.push(item);
          }

          if (labTypetoLabMap[labIDToNameMap[item.admin_lab_types_id].name]) {
            labTypetoLabMap[labIDToNameMap[item.admin_lab_types_id].name].push(
              item
            );
          } else {
            labTypetoLabMap[labIDToNameMap[item.admin_lab_types_id].name] = [
              item
            ];
          }
          let isLabDisabled = false;
          if (item.permission) {
            isLabDisabled = item.permission === "delete";
          }
          if (item.access != "no" && availableTries > 0 && !isLabDisabled) {
            subscribedList.push(item);
          }
        });

        const allLabTypetoLabMap = labProvisionedList
          .concat(launchAccessList)
          .concat(noAccessList);
        if (cmsAndProfileCombinedData.length > 0) {
          this.setState(
            {
              // adminLabArray: labTypetoLabMap[defaultLabType],
              // currentLabType: defaultLabType,
              allLabTemplates: allLabTypetoLabMap,
              adminLabArray: allLabTypetoLabMap,
              currentLabType: this.state.templateFilters[0],
              labTypetoLabMap: labTypetoLabMap,
              subscribedList,
              subscribedListTemp: subscribedList,
              searchfilter: allLabTypetoLabMap
            },
            () => {
              return;
            }
          );
        } else {
          this.setState({
            adminLabArray: [],
            currentLabType: this.state.templateFilters[0],
            allLabTemplates: [],
            labTypetoLabMap: labTypetoLabMap
          });
        }
      })
      .then(() => {
        return this.getReservedIp();
      })
      .catch(err => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err,
        //   showErrorPage: true
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  handleTabItemClick = selectedLabType => {
    switch (selectedLabType) {
      case "All":
        this.setState({
          adminLabArray: this.state.allLabs,
          activeLabSubItem: selectedLabType
        });
        break;
      case "Active":
        this.setState({
          adminLabArray: this.state.activeLabs,
          activeLabSubItem: selectedLabType
        });
        break;
      case "Inactive":
        this.setState({
          adminLabArray: this.state.inactiveLabs,
          activeLabSubItem: selectedLabType
        });
        break;
      default:
        this.setState({
          adminLabArray: [],
          activeLabSubItem: selectedLabType
        });
    }
  };

  getLabIdfromLabIdentifier = labIdentifier => {
    let labCmsUrl =
      this.offeringType.resource === Offerings.SALES_DEMO.resource
        ? Api.adminSalesDemo(1000)
        : Api.viewLearningLab(1000);
    return CMSAPIWithToken()
      .get(labCmsUrl)
      .then(res => {
        const matchedLabFromCms = res.data.results.filter(item => {
          return item.identifier == labIdentifier;
        });
        return matchedLabFromCms[0];
      })
      .catch(err => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err,
        //   showErrorPage: true
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  // get reserved IP settings for user, account and offering specific
  getReservedIp = async () => {
    const isSalesDemo = this.offeringType === Offerings.SALES_DEMO;
    return SDCloudBackendAPIWithToken()
      .get(Api.getUserProfile)
      .then(res => {
        // Check launch lab permission for user
        if (res.data?.can_launch === false) {
          this.setState({
            canLaunch: false
          });
        }
        let reservedIpObj = {};
        if (res.data.static_ips?.length) {
          reservedIpObj["reservedIps"] = [...res.data.static_ips];
          reservedIpObj["ipService"] = true;
        }
        return reservedIpObj;
      })
      .then(reservedIpObj => {
        return SDCloudBackendAPIWithToken()
          .get(Api.getAccountDetails)
          .then(res => {
            if (res.data.reserved_ip) {
              reservedIpObj["staticIp"] = res.data.reserved_ip;
            }
            if (
              isSalesDemo &&
              res.data.ip_available_services?.includes("sales_demo")
            ) {
              reservedIpObj["ipService"] = true;
            } else if (res.data.ip_available_services?.includes("learnings")) {
              reservedIpObj["ipService"] = true;
            } else {
              reservedIpObj["ipService"] = false;
            }
            return reservedIpObj;
          });
      })
      .then(reservedIpObj => {
        if (
          reservedIpObj.ipService &&
          (reservedIpObj.reservedIps || reservedIpObj.staticIp)
        ) {
          reservedIpObj["dynamicIp"] = "default";
          this.setState(
            {
              reservedIp: reservedIpObj,
              reservedIpLoaded: true
            },
            () => {
              return;
            }
          );

          this.props.dispatch(HIDE_LOADER());
        } else {
          this.setState({
            reservedIpLoaded: true
          });
        }
      })
      .catch(err => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err,
        //   showErrorPage: true
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  handleHeaderClick = (selectedLabType, type) => {
    this.setState(
      {
        currentSelectedItem: type,
        currentLabSubItem:
          selectedLabType === "ActiveLab" || selectedLabType === "Subscribed"
            ? "All"
            : selectedLabType,
        activeLabSubItem:
          selectedLabType === "ActiveLab" ? "Active" : selectedLabType
      },
      () => {
        if (type == "labItem") {
          let index;
          if (selectedLabType == "All") {
            this.state.searchText = "";
            this.setState({});
            index = 0;
          } else if (selectedLabType == "ActiveLab") {
            this.state.searchText = "";
            this.setState({});
            index = 2;
          } else if (selectedLabType == "ScheduleLab") {
            this.state.searchText = "";
            this.setState({});
            index = 3;
          } else {
            this.state.searchText = "";
            this.setState({});
            index = 1;
          }

          this.setState({
            currentTabIndex: index
          });
        }
      }
    );

    switch (selectedLabType) {
      case "All":
        this.setState({
          adminLabArray: this.state.allLabTemplates,
          currentLabType: selectedLabType
        });
        break;
      case "Subscribed":
        this.setState({
          adminLabArray: this.state.subscribedList,
          currentLabType: selectedLabType
        });
        break;
      case "ActiveLab":
        this.setState({
          // adminLabArray: this.state.allLabs,
          currentLabType: selectedLabType
        });
        break;
      case "ScheduleLab":
        this.setState({
          // adminLabArray: this.state.subscribedList,
          currentLabType: selectedLabType
        });
        break;
      case "Recent":
        this.setState({
          adminLabArray: this.state.allLabTemplates.filter(item => {
            return item.uuid !== null;
          }),
          currentLabType: selectedLabType
        });
        break;
      default:
        this.setState({
          adminLabArray: this.state.labTypetoLabMap[selectedLabType],
          currentLabType: selectedLabType
        });
    }
  };

  componentWillUnmount = () => {
    // resetting navbar to show current route name
    this.props.dispatch(SET_NAVBAR_TITLE(""));
  };

  componentDidMount = async () => {
    const profileData = JSON.parse(localStorage.getItem("profileData"));
    const account = profileData.account;
    let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
    let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
    let hasDomain = domain?.some((res) => userEmail.includes(res));
    const isInternal = hasDomain
    this.props.dispatch(SET_NAVBAR_TITLE(isInternal?account+"  - " +EXPERIENCEFILTER:EXPERIENCEFILTER));
    this.props.dispatch(SHOW_LOADER());
    this.getResourcesStatusAPI();
    if (
      JSON.parse(localStorage.getItem("profileData")).privilege === "developer"
    ) {
      await this.getNewLearnings();
      await this.getAdminLabTypes();
      await this.getResources();
      await this.getProfile();
    } else if (
      JSON.parse(localStorage.getItem("profileData")).privilege === "admin" ||
      JSON.parse(localStorage.getItem("profileData")).privilege ===
        "profile_admin"
    ) {
      await this.getNewLearnings();
      await this.getAdminLabTypes();
      await this.getResources();
    }
    await this.getInventory();
    this.getCmsData(
      this.state.profileDetails,
      this.state.resourceDetails
    ).finally(() => {
      this.props.dispatch(HIDE_LOADER());
    });
  };

  getResourcesStatusAPI = async () => {
    const res = await getResourcesStatus();

    if (!res.success) {
      return;
    }
    const resources = res.data;
    this.state.sections = this.state.sections.map(item => ({
      available:
        (resources[item.id] &&
          resources[item.id].allocated_hours &&
          resources[item.id].allocated_hours -
            resources[item.id].consumed_hours) ||
        0,
      isLaunch: !!resources[item.id],
      ...item
    }));
    this.setState({
      resources: this.state.sections
    });
    this.getServicesStatusAPI();
  };

  getServicesStatusAPI = async () => {
    const res = await getServiceStatus();
    if (!res.success) {
      return;
    }
    this.setState({
      services: res.data
    });
  };

  Header = () => {
    const classes = useStyles();
    const labType = new Set();
    if (
      Object.keys(this.state.labTypetoLabMap).length > 0 &&
      this.state.reservedIpLoaded
    ) {
      Object.keys(this.state.labTypetoLabMap).forEach(item => {
        labType.add(item);
      });
      let templateFilter = Array.from(this.state.templateFilters).map(item => {
        return (
          <GridItem xs={12} key={uuidv4()}>
            <h4
              className={classes.labType}
              onClick={() => this.handleHeaderClick(item)}
              style={
                this.state.currentLabType.toLowerCase() === item.toLowerCase()
                  ? selectedlabTypeStyle
                  : {}
              }
            >
              {item}
            </h4>
          </GridItem>
        );
      });
      return (
        <GridContainer justify="flex-start">
          {templateFilter}
          <GridItem xs={12}>
            <Divider
              variant="inset"
              style={{
                height: "1px",
                background: "#E6E6E6",
                margin: "15px 30px 20px"
              }}
            />
          </GridItem>
          {Array.from(labType).map(item => {
            return (
              <GridItem xs={12} key={uuidv4()}>
                <h4
                  className={classes.labType}
                  onClick={() => this.handleHeaderClick(item)}
                  style={
                    this.state.currentLabType.toLowerCase() ===
                    item.toLowerCase()
                      ? selectedlabTypeStyle
                      : {}
                  }
                >
                  {item}
                </h4>
              </GridItem>
            );
          })}
        </GridContainer>
      );
    } else {
      return null;
    }
  };

  renderSideLabMenu = () => {
    // const classes = useStyles();
    const labType = new Set();
    if (
      Object.keys(this.state.labTypetoLabMap).length > 0 &&
      this.state.reservedIpLoaded
    ) {
      Object.keys(this.state.labTypetoLabMap).forEach(item => {
        labType.add(item);
      });
      return (
        <div>
          {Array.from(labType).map(item => {
            return (
              <GridItem xs={12} key={uuidv4()}>
                <h4
                  onClick={() => this.handleHeaderClick(item)}
                  style={
                    this.state.currentLabType.toLowerCase() ===
                    item.toLowerCase()
                      ? selectedlabTypeStyle
                      : {}
                  }
                >
                  {item}
                </h4>
              </GridItem>
            );
          })}
        </div>
      );
    }
  };
  // renderActiveTabMenu() {
  //   let activeTabFilter = ["All", "Active", "Inactive"];
  //   const { classes } = this.props;
  //   return (
  //     <div className="search-container-right">
  //       {activeTabFilter.map((item) => {
  //         return (
  //           <a
  //             href="#"
  //             onClick={() => this.handleTabItemClick(item)}
  //             className={classes.font}
  //             style={
  //               this.state.activeLabSubItem.toLowerCase() === item.toLowerCase()
  //                 ? selectedlabTypeStyle
  //                 : {}
  //             }
  //           >
  //             {item}
  //           </a>
  //         );
  //       })}
  //     </div>
  //   );
  // }

  onRowSelect = selectedRows => {
    this.setState({
      selectedRows
    });
  };

  handleChange = (e, type) => {
    const { value } = e.target;
    this.state.searchText = value;
    let category = Object.keys(this.state.labTypetoLabMap);
    let titles = category.filter(
      title => title.toLowerCase() === value.toLowerCase()
    );
    let admin =
      JSON.parse(localStorage.getItem("profileData")).privilege === "admin";
    this.setState({
      searchText: value
    });
    if (this.state.currentLabType === "All") {
      const searchList = titles?.length
        ? this.state.labTypetoLabMap[titles[0]]
        : this.state.searchfilter &&
          this.state.searchfilter.filter(obj =>
            ["display_name", "name", "short_title"].some(key => {
              return (
                obj[key] && obj[key].toLowerCase().includes(value.toLowerCase())
              );
            })
          );
      this.setState({
        adminLabArray: value !== "" ? searchList : this.state.searchfilter
      });
    } else if (this.state.currentLabType === "Subscribed") {
      const searchList =
        this.state.subscribedListTemp &&
        this.state.subscribedListTemp.filter(obj =>
          ["display_name", "name", "short_title"].some(key => {
            return (
              obj[key] && obj[key].toLowerCase().includes(value.toLowerCase())
            );
          })
        );
      this.setState({
        adminLabArray: value !== "" ? searchList : this.state.subscribedListTemp
      });
    } else if (this.state.currentLabType === "ActiveLab") {
      if (this.state.activeLabSubItem === "All") {
        const searchList =
          this.state.allLabsTemp &&
          this.state.allLabsTemp.filter(obj =>
            admin
              ? ["display_name", "name", "user_name"].some(key => {
                  return (
                    obj[key] &&
                    obj[key].toLowerCase().includes(value.toLowerCase())
                  );
                })
              : ["display_name", "name"].some(key => {
                  return (
                    obj[key] &&
                    obj[key].toLowerCase().includes(value.toLowerCase())
                  );
                })
          );
        this.setState({
          allLabs: value !== "" ? searchList : this.state.allLabsTemp
        });
      }
      if (this.state.activeLabSubItem === "Active") {
        const searchList =
          this.state.activeLabsTemp &&
          this.state.activeLabsTemp.filter(obj =>
            admin
              ? ["display_name", "name", "user_name"].some(key => {
                  return (
                    obj[key] &&
                    obj[key].toLowerCase().includes(value.toLowerCase())
                  );
                })
              : ["display_name", "name"].some(key => {
                  return (
                    obj[key] &&
                    obj[key].toLowerCase().includes(value.toLowerCase())
                  );
                })
          );
        this.setState({
          activeLabs: value !== "" ? searchList : this.state.activeLabsTemp
        });
      }
      if (this.state.activeLabSubItem === "Inactive") {
        const searchList =
          this.state.inactiveLabsTemp &&
          this.state.inactiveLabsTemp.filter(obj =>
            admin
              ? ["display_name", "name", "user_name"].some(key => {
                  return (
                    obj[key] &&
                    obj[key].toLowerCase().includes(value.toLowerCase())
                  );
                })
              : ["display_name", "name"].some(key => {
                  return (
                    obj[key] &&
                    obj[key].toLowerCase().includes(value.toLowerCase())
                  );
                })
          );
        this.setState({
          inactiveLabs: value !== "" ? searchList : this.state.inactiveLabsTemp
        });
      }
    }
  };

  handleChanges = (event, newValue) => {
    this.setState({ value: newValue });
  };

  render() {
    const { classes } = this.props;
    const { resources } = this.state;
    let currentStatus = resources.find(o => o.id == "sales_demo");

    if (this.state.allLabTemplates === undefined) {
      return null;
    }
    // if (
    //     this.state.allLabTemplates.length === 0 &&
    //     this.state.reservedIpLoaded
    // ) {
    //     return (
    //         <GridContainer alignItems="flex-start" justify="flex-start">
    //             <p> No labs subscribed! </p>
    //         </GridContainer>
    //     );
    // }

    if (currentStatus && !currentStatus.isLaunch) {
      return <ExperienceCenterUpgrade page={"showcases"} />;
    } else {
      return (
        <div className="launch-demo">
          {this.state.reservedIpLoaded ? (
            <>
              {this.props.profile.profileObj.privilege === "admin" ? (
                <AppBar position="static">
                  <Tabs
                    value={this.state.value}
                    classes={{
                      root: classes.customTabRoot
                    }}
                    onChange={this.handleChanges}
                    aria-label="simple tabs example"
                  >
                    <Tab
                      label="Catalog"
                      {...a11yProps(0)}
                      onClick={() => this.handleHeaderClick("All", "labItem")}
                    />
                    <Tab
                      label="Active"
                      {...a11yProps(1)}
                      onClick={() =>
                        this.handleHeaderClick("ActiveLab", "labItem")
                      }
                    />
                    <Tab
                      label="Scheduled"
                      {...a11yProps(2)}
                      onClick={() =>
                        this.handleHeaderClick("ScheduleLab", "labItem")
                      }
                    />
                    {/* <Tab label="Scheduled" {...a11yProps(3)} /> */}
                  </Tabs>
                </AppBar>
              ) : (
                <AppBar position="static">
                  <Tabs
                    value={this.state.value}
                    classes={{
                      root: classes.customTabRoot
                    }}
                    onChange={this.handleChanges}
                    aria-label="simple tabs example"
                  >
                    <Tab
                      label="Catalog"
                      {...a11yProps(0)}
                      onClick={() => this.handleHeaderClick("All", "labItem")}
                    />
                    <Tab
                      label="Subscribed"
                      {...a11yProps(1)}
                      onClick={() =>
                        this.handleHeaderClick("Subscribed", "labItem")
                      }
                    />
                    <Tab
                      label="Active"
                      {...a11yProps(2)}
                      onClick={() =>
                        this.handleHeaderClick("ActiveLab", "labItem")
                      }
                    />
                    <Tab
                      label="Scheduled"
                      {...a11yProps(3)}
                      onClick={() =>
                        this.handleHeaderClick("ScheduleLab", "labItem")
                      }
                    />
                    {/* <Tab label="Scheduled" {...a11yProps(3)} /> */}
                  </Tabs>
                </AppBar>
              )}

              <div className="topnav">
                <div className="search-container">
                  <form action="">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.4163 16.7497L14.4163 12.7497C15.333 11.4997 15.833 9.99967 15.833 8.33301C15.833 4.16634 12.4997 0.833008 8.33301 0.833008C4.16634 0.833008 0.833008 4.16634 0.833008 8.33301C0.833008 12.4997 4.16634 15.833 8.33301 15.833C9.99967 15.833 11.4997 15.333 12.7497 14.4163L16.7497 18.4163C17.2497 18.9163 17.9163 18.9163 18.4163 18.4163C18.833 17.9163 18.833 17.1663 18.4163 16.7497ZM8.33301 13.7497C5.33301 13.7497 2.91634 11.333 2.91634 8.33301C2.91634 5.33301 5.33301 2.91634 8.33301 2.91634C11.333 2.91634 13.7497 5.33301 13.7497 8.33301C13.7497 11.333 11.333 13.7497 8.33301 13.7497Z"
                        fill="#545454"
                      />
                    </svg>
                    <input
                      type="text"
                      className={classes.font}
                      placeholder=""
                      name="search"
                      value={this.state.searchText}
                      onChange={e => this.handleChange(e, "All")}
                    ></input>
                  </form>
                </div>
                {/* {this.state.currentLabType == "ActiveLab" &&
                  this.renderActiveTabMenu()} */}
                {/* {this.renderSideLabMenu()} */}
                {/* <a href="#"
                            onClick={() => this.handleHeaderClick("All")}
                            style={
                                this.state.currentLabType.toLowerCase() ===
                                    templateFilters[0].toLowerCase()
                                    ? selectedlabTypeStyle
                                    : {}
                            }
                        >All</a> */}
              </div>

              <TabPanel
                value={this.state.value}
                index={
                  this.props.profile.profileObj.privilege === "admin" ? 0 : 0
                }
              >
                {this.state.adminLabArray.length === 0 ? (
                  <h4 className={classes.font} style={{ fontWeight: 400 }}>
                    {" "}
                    No matches found.{" "}
                  </h4>
                ) : (
                  <>
                    {" "}
                    {this.state.adminLabArray.map(lab => {
                      const labProps = { ...lab };
                      return (
                        <LaunchNewOfferingCard
                          labData={{
                            ...labProps,
                            launchLabBtnHandler: this.launchLabBtnHandler,
                            accessLabBtnHandler: this.accessLabBtnHandler,
                            reservedIp: this.state.reservedIp,
                            canLaunch: this.state.canLaunch,
                            offeringType: Offerings.SALES_DEMO
                          }}
                          inventoryData={this.state.learningsInventoryData}
                          notes={notes.reservedIpNote}
                          key={uuidv4()}
                          id={lab.identifier}
                        />
                      );
                    })}{" "}
                  </>
                )}
              </TabPanel>
              {}
              <TabPanel
                value={this.state.value}
                index={
                  this.props.profile.profileObj.privilege === "admin" ? "" : 1
                }
              >
                {this.state.adminLabArray.length === 0 ? (
                  <h4 className={classes.font} style={{ fontWeight: 400 }}>
                    {" "}
                    No matches found.{" "}
                  </h4>
                ) : (
                  <>
                    {" "}
                    {this.state.adminLabArray &&
                      this.state.adminLabArray.map(lab => {
                        const labProps = { ...lab };
                        return (
                          <LaunchNewOfferingCard
                            labData={{
                              ...labProps,
                              launchLabBtnHandler: this.launchLabBtnHandler,
                              accessLabBtnHandler: this.accessLabBtnHandler,
                              reservedIp: this.state.reservedIp,
                              canLaunch: this.state.canLaunch,
                              offeringType:
                                this.props.offeringType || Offerings.SALES_DEMO
                            }}
                            inventoryData={this.state.learningsInventoryData}
                            notes={notes.reservedIpNote}
                            key={uuidv4()}
                            id={lab.identifier}
                          />
                        );
                      })}{" "}
                  </>
                )}
              </TabPanel>
              <TabPanel
                value={this.state.value}
                index={
                  this.props.profile.profileObj.privilege === "admin" ? 1 : 2
                }
              >
                {this.state.isResourceLoaded && (
                  <GridContainer>
                    <GridItem xs={12}>
                      <LearningCards
                        labFilter={this.state.activeLabSubItem}
                        offeringType={this.offeringType}
                        privilege={this.props.profile.profileObj.privilege}
                        labs={{
                          activeLabs: this.state.activeLabs,
                          inactiveLabs: this.state.inactiveLabs,
                          allLabs: this.state.allLabs
                        }}
                        addHoursDetails={
                          this.props.profile.profileObj.add_hours
                        }
                        getLabIdfromLabIdentifier={
                          this.getLabIdfromLabIdentifier
                        }
                        onRowSelect={this.onRowSelect}
                        handleActive={this.props.handleActive}
                        buttonTitle={this.props.buttonTitle}
                        handleLaunchNewLab={this.props.handleLaunchNewLab}
                      />
                    </GridItem>
                  </GridContainer>
                )}
              </TabPanel>
              <TabPanel
                value={this.state.value}
                index={
                  this.props.profile.profileObj.privilege === "admin" ? 2 : 3
                }
              >
                <GridContainer>
                  <GridItem xs={12}>
                    <ScheduleCluster
                      value="No matches found."
                      offeringType={this.offeringType.resource}
                      ScheduleSearchInput={this.state.searchText}
                      noofhours={this.state?.adminLabArray}
                    />
                  </GridItem>
                </GridContainer>
              </TabPanel>
            </>
          ) : (
            <Loader
              type={LOADER_TYPE}
              color={globalTheme.palette.secondary.main}
              width={50}
              height={50}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)"
              }}
            />
          )}
          <Chatbot/>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = state => {
  return {
    profile: state.profileObj
  };
};

// const dispatchToProps =
const LaunchLabsHoc = withRouter(withStyles(customStyles)(LaunchSalesdemo));
export default connect(mapStateToProps)(LaunchLabsHoc);
