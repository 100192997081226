import React, { Component } from "react";

export const USER_FORM_FIELDS = {
  firstname: "Pushpendra",
  lastname: "Kumar",
  email: "pmishra@criterionnetworks.com",
  phone: "8989989898",
  company: "Organization",
  title: "Software Engineer",
  invitation_code: "H35D0ACCJ2"
};

// represent the form label shown on page of user
export const USER_FORM_LABELS = {
  first_name: "First Name",
  last_name: "Last Name",
  email: "Email Address",
  phone: "Phone Number",
  organization: "Organization",
  title: "Job Title",
  invite_code: "Invite Code",
  user_name: "User Name"
};

// represent the form label shown on page of security question
export const SECURITY_FORM_LABELS = {
  question: "Question",
  answer: "Your Answer"
};

// represent the form label shown on page of password reset
export const PASSWORD_FORM_LABELS = {
  password: "New Password",
  confirmPassword: "Confirm password"
};

export const SSO_FORM_FIELDS = {
  entity_id: "Pushpendra",
  sso_url: "Kumar",
  slo_url: "pmishra@criterionnetworks.com"
};

// represent the form label shown on page of sso
export const SSO_FORM_LABELS = {
  entity_id: "IdP Issuer ",
  sso_url: "Single SignOn URL",
  slo_url: "Single Logout URL"
};

export const SECURITY_QUESTIONS = [
  "What primary school did you attend?",
  "In what town or city was your first full time job?",
  "What is your mother's maiden name?",
  "What was your childhood nickname?",
  "What was your dream job as a child?",
  "What is your pet's name?",
  "What is the name of the company of your first job?",
  "What is the name of your favorite childhood friend?"
];

// regex constants
export const strongRegex = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);
export const mediumRegex = new RegExp(
  "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
);
export const staticIpRegex = new RegExp("^[a-z0-9.-]+$");
