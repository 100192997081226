import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink } from "react-router-dom";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";

// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";
import SvgtoIcon from "utils/SvgtoIcon";
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";

import MuiButton from "@material-ui/core/Button";
import SidebarMinimizeIcon from "./SidebarMinimiseIcon";
import { sidebarSelectedItemBackground } from "assets/jss/material-dashboard-pro-react";
import theme from "../../theme";
import Contentmanagement from "views/Contentmanagement/Contentmanagement";
var ps;
var showPoweredLogo = localStorage.getItem("BrandLogo") !== null && (localStorage.getItem('BrandLogo').includes('assets') === false)
var showLeftbar =true
// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  sidebarWrapper = React.createRef();
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebarWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //   // avoids false renders on hover when sidebar is open
  //   if (this.props.fixedMiniActive === false) {
  //     return false;
  //   }
  //   return true;
  // }

  render() {
    const {
      className,
      headerLinks,
      links,
      fixedMiniActive,
      toggleDrawer,
      bottomPanelLinks,
    } = this.props;
    return (
      <div className={className} ref={this.sidebarWrapper}>
        {headerLinks}
        {links}

        <hr style={{ border: "1px solid #1C2D46" }} />
        <div
          className="side-bottom-menu"
          style={{
            position: "relative",
            bottom: "0",

            // backgroundColor: sidebarSelectedItemBackground,
            width: "100%",
            // height: "40px",
            display: "flex",
            // alignItems: "center",
            flexDirection: "column",
            justifyContent: fixedMiniActive ? "flex-start" : "flex-end",
          }}
        >
          {bottomPanelLinks}
          <div
            className="colapse-sidemenu"
            style={{
              background: theme.palette.primary.main,
              filter: "brightness(85%)",
            }}
          >
            {" "}
            <MuiButton // justIcon <MuiButton // justIcon
              onClick={() => {
                  toggleDrawer();
                  showLeftbar =!showLeftbar
              }}
              style={{
                transform: fixedMiniActive ? "rotate(180deg)" : "rotate(0deg)",
                transitionProperty: "transform",
                transitionDelay: "0.1s",
                transitionTimingFunction: "ease",
                margin: "0px",
                width: "50px",
                alignSelf: "end",
              }}
              size="large"
            >
              <SidebarMinimizeIcon />
            </MuiButton>
          </div>

        </div>
          {showPoweredLogo?
              (<div  style={{
              // position: "relative",
              bottom: "0",
              width: "100%",
              // display: "flex",
              alignItems: "center",
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              fontSize:"14px",
              fontStyle:"normal",
              fontWeight:"300",
              lineHeight:"16px",
              letterSpacing:"0em"
          }}>
              {showLeftbar?
                  (<div style={{paddingLeft: "20px"}}>Powered by</div>):
                  (<div>Powered by</div>)
              }
              {showLeftbar?
              (<img width={"175px"} height={"35px"} style={{paddingLeft: "20px"}}
                       src={"https://sdcloud-imgs.s3.amazonaws.com/designer_objects/brand-logo.png"}/>):(
                  <img width={"35px"} height={"35px"}
                  src={"https://sdcloud-imgs.s3.amazonaws.com/designer_objects/criterion-logo-mini.png"}/>)
              }
          </div>):null}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      miniActive: true,
      ...this.getCollapseStates(props.routes),
      // getting user profile data from redux store
      profileObj: this.props.profile.profileObj
        ? this.props.profile.profileObj
        : {},
    };
  }
  componentDidUpdate = (prevProps) => {
    if (this.props.profile != prevProps.profile) {
      this.setState({ profileObj: this.props.profile.profileObj });
    }
  };
  mainPanel = React.createRef();
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return window.location.pathname === routeName ? "active" : "";
  };
  openCollapse(collapse) {
    var st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes, isBottomPanel) => {
    const { classes, color, rtlActive } = this.props;

    return routes.map((prop, key) => {
      if (
        prop.redirect ||
        !prop.view ||
        prop.bottomPanel !== isBottomPanel ||
        !prop.privileges.includes(this.props.profile.profileObj.privilege) ||
        (prop.privileges.includes("is_ui_admin") &&
          !JSON.parse(localStorage.profileData).is_ui_admin)
      ) {
        return null;
      }
      // else {
      //   if (!JSON.parse(localStorage.profileData).is_ui_admin) {
      //   }
      // }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        const navLinkClasses =
          classes.itemLink +
          " " +
          cx({
            [" " + classes.collapseActive]: this.getCollapseInitialState(
              prop.views
            ),
          });
        const itemText =
          classes.itemText +
          " " +
          cx({
            [classes.itemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.itemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.itemTextRTL]: rtlActive,
          });
        const collapseItemText =
          classes.collapseItemText +
          " " +
          cx({
            [classes.collapseItemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.collapseItemTextRTL]: rtlActive,
          });
        const itemIcon =
          classes.itemIcon +
          " " +
          cx({
            [classes.itemIconRTL]: rtlActive,
          });
        const caret =
          classes.caret +
          " " +
          cx({
            [classes.caretRTL]: rtlActive,
          });
        const collapseItemMini =
          classes.collapseItemMini +
          " " +
          cx({
            [classes.collapseItemMiniRTL]: rtlActive,
          });
        return (
          <ListItem
            key={key}
            className={cx(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <NavLink
              to={"#"}
              className={navLinkClasses}
              onClick={(e) => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === "string" ? (
                  <Icon className={`nav-icon ${itemIcon}`}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>
                  {rtlActive ? prop.rtlMini : prop.mini}
                </span>
              )}
              <ListItemText
                primary={rtlActive ? prop.rtlName : prop.name}
                secondary={
                  <b
                    className={
                      caret +
                      " " +
                      (this.state[prop.state] ? classes.caretActive : "")
                    }
                  />
                }
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </NavLink>
            <Collapse in={this.state[prop.state]} unmountOnExit>
              <List className={classes.list + " " + classes.collapseList}>
                {this.createLinks(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        );
      }
      const innerNavLinkClasses =
        classes.collapseItemLink +
        " " +
        cx({
          [" " + classes[color]]: this.activeRoute(`${prop.path}`),
        });
      const collapseItemMini =
        classes.collapseItemMini +
        " " +
        cx({
          [classes.collapseItemMiniRTL]: rtlActive,
        });
      const navLinkClasses =
        classes.itemLink +
        " " +
        cx({
          [" " + classes[color]]: this.activeRoute(`${prop.path}`),
        });
      const itemText =
        classes.itemText +
        " " +
        cx({
          [classes.itemTextMini]:
            this.props.miniActive && this.state.miniActive,
          [classes.itemTextMiniRTL]:
            rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.itemTextRTL]: rtlActive,
        });
      const collapseItemText =
        classes.collapseItemText +
        " " +
        cx({
          [classes.collapseItemTextMini]:
            this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextMiniRTL]:
            rtlActive && this.props.miniActive && this.state.miniActive,
          [classes.collapseItemTextRTL]: rtlActive,
        });
      const itemIcon =
        classes.itemIcon +
        " " +
        cx({
          [classes.itemIconRTL]: rtlActive,
        });

      return (
        <ListItem
          key={key}
          className={cx(
            { [classes.item]: prop.icon !== undefined },
            { [classes.collapseItem]: prop.icon === undefined },
            {
              [classes.hoverSVG]:
                prop.name === "Statistics" || prop.name === "Reports",
            }
          )}
        >
          <NavLink
            to={prop.path}
            className={cx(
              { [navLinkClasses]: prop.icon !== undefined },
              { [innerNavLinkClasses]: prop.icon === undefined },
              {
                [classes.itemLink + " " + classes["blue"]]:
                  (window.location.pathname ===
                    "/experience-center-showcases" ||
                    window.location.pathname ===
                      "/experience-center-sandboxes") &&
                  prop.name === "Experience",
              }
            )}
          >
            {prop.icon !== undefined ? (
              typeof prop.icon === "string" ? (
                // <Icon className={itemIcon}>{prop.icon}</Icon>
                // "string"
                <SvgtoIcon
                  className={itemIcon}
                  svgSource={prop.icon}
                  active={this.activeRoute(`${prop.path}`)}
                  name={prop.name}
                  type={
                    prop.name === "Statistics" || prop.name === "Reports"
                      ? "stroke"
                      : "fill"
                  }
                />
              ) : (
                // <prop.icon className={itemIcon} />
                <SvgtoIcon
                  className={itemIcon}
                  svgSource={prop.icon}
                  active={this.activeRoute(`${prop.path}`)}
                  isString={false}
                  name={prop.name}
                  type={
                    prop.name === "Statistics" || prop.name === "Reports"
                      ? "stroke"
                      : "fill"
                  }
                />
              )
            ) : (
              <span className={collapseItemMini}>
                {/* {rtlActive ? prop.rtlMini : "-"} */}
                {/* <prop.mini className={itemIcon} /> */}
                <SvgtoIcon className={itemIcon} svgSource={prop.mini} />
              </span>
            )}
            {prop.name === "Learning" ? (
              <ListItemText
                primary="Learn"
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            ) : (
              <ListItemText
                primary={rtlActive ? prop.rtlName : prop.name}
                disableTypography={true}
                className={cx(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            )}
          </NavLink>
          {prop.name === "Experience" ? (
            <List className={`side-menu-inner`} style={{}}>
              <ListItem style={{}}>
                <NavLink
                  to="/experience-center-showcases"
                  className={`${itemText + " " + classes.submenuIcon} submenu`}
                >
                  Showcases
                </NavLink>
              </ListItem>
              <ListItem style={{}}>
                <NavLink
                  to="/experience-center-sandboxes"
                  className={`${itemText + " " + classes.submenuIcon} submenu`}
                >
                  Sandboxes
                </NavLink>
              </ListItem>
            </List>
          ) : ""}
        
        </ListItem>
      );
    });
  };
  render() {
    const {
      classes,
      logo,
      image,
      logoFull,
      routes,
      bgColor,
      rtlActive,
      history,
    } = this.props;

    var links = (
      <List className={classes.list}>{this.createLinks(routes, false)}</List>
    );

    var bottomPanelLinks = (
      <List className={classes.list}>{this.createLinks(routes, true)}</List>
    );

    const logoNormal =
      classes.logoNormal +
      " " +
      cx({
        [classes.logoNormalSidebarMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.logoNormalSidebarMiniRTL]:
          rtlActive && this.props.miniActive && this.state.miniActive,
        [classes.logoNormalRTL]: rtlActive,
      });
    const logoMini =
      classes.logoMini +
      " " +
      cx({
        [classes.logoMiniRTL]: rtlActive,
      });
    const logoClasses =
      classes.logo +
      " " +
      cx({
        [classes.whiteAfter]: bgColor === "white",
      });
    var brand = (
      <div className={logoClasses}>
        <a
          href="/dashboard"
          className={logoMini}
          style={{
            display:
              this.state.miniActive && this.props.miniActive ? "block" : "none",
          }}
        >
          <img
            src={logo}
            alt="logo"
            className={classes.img}
            style={{ width: "35px", height: "35px" }}
          />
        </a>
        <a href="/dashboard" className={logoNormal} id="logo2">
          <img
            src={logoFull}
            alt="logoFull"
            className={classes.logoFull}
            style={{
              filter: "brightness(1)",
              objectFit: "contain",
              height: "80px",
            }}
          />
        </a>
      </div>
    );
    const drawerPaper =
      classes.drawerPaper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.drawerPaperRTL]: rtlActive,
      });
    const sidebarWrapper =
      classes.sidebarWrapper +
      " " +
      cx({
        [classes.drawerPaperMini]:
          this.props.miniActive && this.state.miniActive,
        [classes.sidebarWrapperWithPerfectScrollbar]:
          navigator.platform.indexOf("Win") > -1,
      });

    return (
      <div ref={this.mainPanel}>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={rtlActive ? "left" : "right"}
            open={this.props.open}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"],
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              headerLinks={
                <AdminNavbarLinks
                  userData={this.state.profileObj}
                  rtlActive={rtlActive}
                  history={history}
                />
              }
              links={links}
              hoverMiniActive={this.state.miniActive} //on hover to sidebar miniActive changes
              fixedMiniActive={this.props.miniActive} //minimize sidebar using button
              toggleDrawer={this.props.sidebarMinimize}
              bottomPanelLinks={bottomPanelLinks}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            // onMouseOver={() => this.setState({ miniActive: false })}
            // onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? "right" : "left"}
            variant="permanent"
            open
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"],
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              links={links}
              hoverMiniActive={this.state.miniActive} //on hover to sidebar miniActive changes
              fixedMiniActive={this.props.miniActive} //minimize sidebar using button
              toggleDrawer={this.props.sidebarMinimize}
              bottomPanelLinks={bottomPanelLinks}
            />

            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: "url(" + image + ")" }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: "info",
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(["white", "black", "blue", "info"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    "white",
    "red",
    "orange",
    "green",
    "blue",
    "purple",
    "rose",
  ]),
  logo: PropTypes.string,
  logoFull: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  miniActive: PropTypes.bool,
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
};

SidebarWrapper.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  headerLinks: PropTypes.object,
  links: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    profile: state.profileObj,
  };
};

export default connect(mapStateToProps)(withStyles(sidebarStyle)(Sidebar));
