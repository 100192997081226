import React, { Component } from "react";
import NodeImageUploader from "views/ServiceLifeCycle/components/NodeImageUploader";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card.js";
import GridItem from "components/Grid/GridItem";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Snackbar from "components/Snackbar/Snackbar.js";

import { Prompt } from "react-router-dom";

import TableWrapper from "views/ServiceLifeCycle/components/TableWrapper";

export default class UploadImage extends Component {
  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
    this.nodeUploaderRef = React.createRef();
    this.state = {
      showNotification: false,
      notificationMessage: "",
      notificationColor: "success",
      uploadOnProgress: false
    };
  }

  // after a file is uploaded successfully
  onFileUpload = apiData => {
    this.tableRef.current.fetchTableData();
    this.setState({
      uploadOnProgress: false
    });
    // //console.log("on file upload of upload image called", JSON.parse(apiData));
    this.callNotification(
      "success",
      `${JSON.parse(apiData).name} uploaded successfully.`
    );
  };

  // when a file is selected or an upload is ongoing
  onUploadProgress = isAnyFileSelectedOrUploading => {
    if (isAnyFileSelectedOrUploading) {
      this.setState({
        uploadOnProgress: true
      });
    } else {
      this.setState({
        uploadOnProgress: false
      });
    }
  };

  callNotification = (notificationColor, notificationMessage) => {
    this.setState({
      notificationColor,
      notificationMessage,
      showNotification: true
    });
    // setTimeout(() => {
    //   this.setState({
    //     showNotification: false,
    //     notificationMessage: ""
    //   });
    // }, 3500);
  };

  componentDidMount = () => {
    window.onbeforeunload = e => {
      if (this.state.uploadOnProgress) {
        return "Don't leave";
      } else {
        e.preventDefault();
      }
    };
  };

  componentWillUnmount = () => {
    window.onbeforeunload = null;
  };

  render() {
    return (
      <GridContainer>
        <Card>
          <GridItem xs={12}>
            <CardHeader>
              <h4>
                {" "}
                Click below to Upload one or more image file(s) (.qcow2){" "}
              </h4>
            </CardHeader>
            <CardBody>
              <GridItem xs={12}>
                <NodeImageUploader
                  getResponseFromFileUpload={this.onFileUpload}
                  ref={this.nodeUploaderRef}
                  allowMultiple={false}
                  onUploadProgress={this.onUploadProgress}
                  showAddVersionField={true}
                />
              </GridItem>
            </CardBody>
          </GridItem>
          <GridItem
            xs={12}
            style={{
              backgroundColor: "rgba(183, 183, 183, 0.12)",
              height: "10px"
            }}
          >
            <p />
          </GridItem>
          <GridItem
            xs={12}
            // style={{ backgroundColor: "rgba(183, 183, 183, 0.12)" }}
          >
            <CardHeader>
              <h4> My Uploaded Images: </h4>
            </CardHeader>
            <CardBody>
              <GridItem xs={12}>
                {/* <p> A table containing uploaded images by user </p> */}
                <TableWrapper ref={this.tableRef} />
              </GridItem>
            </CardBody>
          </GridItem>
        </Card>
        <Snackbar
          place="tr"
          color={this.state.notificationColor}
          //   icon={AddAlert}
          message={this.state.notificationMessage}
          open={this.state.showNotification}
          closeNotification={() => this.setState({ showNotification: false })}
          close
        />
        <Prompt
          when={this.state.uploadOnProgress}
          // pass location in callback to access the location.
          message={() =>
            `Unsaved changes. Are you sure you want to leave? Click cancel to stay. Okay to leave.`
          }
        />
      </GridContainer>
    );
  }
}
