import React, { useState } from "react";
import { DropzoneArea } from 'material-ui-dropzone';

// Material UI Components
import { Alert } from "@material-ui/lab";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

// Local
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import { SDCloudBackendAPIWithToken, SDCloudCustomizeAPIWithToken } from "../../../apis/backendAPI";
import { Api } from "../../../config/api";
import { useDispatch } from "react-redux";

// Styling
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "50px",
  },
  filter: {
    marginTop: "2%",
    padding: "20px 0px 50px 50px",
    background: "#FFFFFF",
    border: "1px solid #F0F0F0",
    width: "80%",
  },
  button: {
    background: `${theme.palette.buttonColor.main} !important`,
    height: "40px",
    color: "white",
    border: "none",
    width: "200px",
    cursor: "pointer",
  },
}));

const S3upload = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  
  // States
  const [notificationType, setNotificationType] = useState("");
  const [value, setValue] = useState("learnings");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  // Handle radio selection change
  const handleRadioChange = (event) => {
    setValue(event.target.value);
  };

  // Function to handle file selection in DropzoneArea
  const attachfile = (file) => {
    if (file.length > 0) {
      const fileType = file[0].type;

      // Check if it's a PDF for watermarking, or other file types for direct upload
      if (fileType === 'application/pdf') {
        setSelectedFile({ file: file[0], fileType: 'pdf' });
      } else {
        setSelectedFile({ file: file[0], fileType: 'image' });
      }
    }
  };

  // Function to upload watermarked PDF to S3
  const uploadWatermarkedPdfToS3 = (watermarkedBlob, fileName) => {
    const s3FormData = new FormData();
    const watermarkedFile = new Blob([watermarkedBlob], { type: 'application/pdf' });
    s3FormData.append('attachment', watermarkedFile, `${fileName}.pdf`);
    s3FormData.append('path', value); // Append path based on selected radio value

    // Now, upload the watermarked PDF to S3
    SDCloudCustomizeAPIWithToken()
      .post(Api.s3Upload, s3FormData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("user")}`,
        },
      })
      .then((response) => {
        setIsLoading(false);
        setShowNotification(true);
        setNotificationMessage(`Watermarked PDF uploaded successfully: ${response.data.urls[0]}`);
        setNotificationType("success");
      })
      .catch((err) => {
        setIsLoading(false);
        setShowNotification(true);
        setNotificationMessage('Failed to upload watermarked PDF to S3.');
        setNotificationType("error");
      });
  };

  // Function to handle the overall upload process
  const upload = () => {
    setIsLoading(true);

    if (selectedFile?.fileType === 'pdf') {
      // If the file is a PDF, call the watermarking API first
      const pdfFormData = new FormData();
      pdfFormData.append('slide_deck', selectedFile.file); // Send the file as slide_deck to watermark API
      pdfFormData.append('path', value); // Append path (selected category)

      // Call the API to watermark the PDF
      SDCloudBackendAPIWithToken()
        .post("/export_slide_deck", pdfFormData, { responseType: 'blob' }) // Get the watermarked PDF as blob
        .then((response) => {
          const watermarkedPdf = response.data;

          // Once watermarked, upload to S3
          uploadWatermarkedPdfToS3(watermarkedPdf, selectedFile.file.name.split('.')[0]);
        })
        .catch((err) => {
          setIsLoading(false);
          setShowNotification(true);
          setNotificationMessage('Failed to apply watermark to the PDF.');
          setNotificationType("error");
        });
    } else {
      // For images, directly upload to S3
      const imageFormData = new FormData();
      imageFormData.append('attachment', selectedFile.file); // Attach the image
      imageFormData.append('path', value); // Append path based on selected radio value

      SDCloudCustomizeAPIWithToken()
        .post(Api.s3Upload, imageFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("user")}`,
          },
        })
        .then((response) => {
          setIsLoading(false);
          setShowNotification(true);
          setNotificationMessage(`Image uploaded successfully: ${response.data.urls[0]}`);
          setNotificationType("success");
        })
        .catch((err) => {
          setIsLoading(false);
          setShowNotification(true);
          setNotificationMessage('Failed to upload image to S3.');
          setNotificationType("error");
        });
    }
  };

  return (
    <div>
      <Grid container>
        {showNotification && (
          <Alert
            onClose={() => setShowNotification(false)}
            severity={notificationType}
            style={{ width: "80%", borderRadius: "0.5rem", marginBottom: "-0.5rem" }}
          >
            {notificationMessage}
          </Alert>
        )}

        <Grid container className={classes.filter}>
          <Grid item xs={12} md={12}>
            <FormControl fullWidth>
              <Grid container className={classes.gridContainer}>
                <Grid item xs={12}>
                  <Typography variant="h4">S3upload</Typography>
                </Grid>

                <p>Drag and drop your files here</p>

                <Grid item xs={12}>
                  <Grid container justify="space-around">
                    <Grid item xs={12} md={10}>
                      <Grid container justify="space-between">
                        <Grid item xs={12}>
                          {isLoading ? (
                            <LoaderHoc isLoading={isLoading} />
                          ) : (
                            <DropzoneArea
                              acceptedFiles={['image/*', 'application/pdf']}
                              dropzoneText={".SVG .JPG .PNG .PDF"}
                              showPreviews={false}
                              onChange={(files) => attachfile(files)}
                              filesLimit={1}
                            />
                          )}
                        </Grid>

                        <Grid item xs={12} md={12} className={classes.buttongrid}>
                          <FormControl component="fieldset">
                            <RadioGroup
                              row
                              value={value}
                              onChange={handleRadioChange}
                              className={classes.radioButton}
                            >
                              <FormControlLabel
                                value="learnings"
                                control={<Radio color="primary" />}
                                label="Learning Center"
                              />
                              <FormControlLabel
                                value="salesdemo"
                                control={<Radio color="primary" />}
                                label="Showcases"
                              />
                              <FormControlLabel
                                value="sandboxes"
                                control={<Radio color="primary" />}
                                label="Sandboxes"
                              />
                              <FormControlLabel
                                value="designer_objects"
                                control={<Radio color="primary" />}
                                label="Design Center"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        <Grid container alignItems="center" justify="space-evenly" className={classes.buttongrid}>
                          <Grid item xs={12} md={5}>
                            <Button
                              variant="outlined"
                              color="secondary"
                              size="large"
                              fullWidth
                              onClick={upload}
                              className={classes.button}
                            >
                              Submit
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default S3upload;
