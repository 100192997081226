import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// core components
import Button from "components/CustomButtons/Button.js";

import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

export default function ImageUpload(props) {
  const [files, setFiles] = React.useState([]);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState([]);
  let fileInput = React.createRef();
  let { fileSelected } = props;
  const initiateFileRead = (reader, files, i = 0) => {
    if (!files[i]) {
      fileSelected(files);
      return;
    }
    reader.onloadend = () => {
      setFiles((prevFilesArr) => [...prevFilesArr, files[i]]);
      setImagePreviewUrl((prevImageArr) => [...prevImageArr, reader.result]);
      initiateFileRead(reader, files, i + 1);
    };
    reader.readAsDataURL(files[i]);
  };
  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let files = e.target.files;
    initiateFileRead(reader, files, 0);
  };
  // eslint-disable-next-line
  const handleSubmit = (e) => {
    e.preventDefault();
    // file is the file/image uploaded
    // in this function you can save the image (file) on form submit
    // you have to call it yourself
  };
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    setFiles([]);
    setImagePreviewUrl([]);
    fileInput.current.value = null;
    fileSelected(null);
  };
  let { avatar, addButtonProps, removeButtonProps, addButtonText } = props;
  return (
    <div className="fileinput text-center">
      <input
        type="file"
        onChange={handleImageChange}
        ref={fileInput}
        multiple
      />
      {files.length >= 0 &&
        [...Array(files.length).keys()].map((_, key) => (
          <div
            key={key}
            className={"thumbnail" + (avatar ? " img-circle" : "")}
          >
            <img src={imagePreviewUrl[key]} alt="..." />
          </div>
        ))}
      <div>
        {files.length === 0 ? (
          <Button {...addButtonProps} onClick={() => handleClick()}>
            {avatar ? "Add Photo" : addButtonText || "Select image"}
          </Button>
        ) : (
          <span>
            {/* <Button {...changeButtonProps} onClick={() => handleClick()}>
              Change
            </Button> */}
            {avatar ? <br /> : null}
            <Button {...removeButtonProps} onClick={() => handleRemove()}>
              <i className="fas fa-times" /> Remove
            </Button>
          </span>
        )}
      </div>
    </div>
  );
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
  fileSelected: PropTypes.func,
  addButtonText: PropTypes.string,
};
