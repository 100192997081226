import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import LabelTabs from "../../../components/LabelTabs";
import LaunchSalesdemo from "./LaunchSalesdemo";
import SandboxTemplates from "./Sandboxes/SandboxTemplates";
import { expTabs } from "../../../utils/utilfunction";
import { Urls } from "../../../config/api";
import "./LaunchDemo.scss";
import backIcon from "../../../assets/criterion_networks/icons/learnings/long-left-arrow.png";

const LaunchDemo = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    const isSales = history.location.pathname === Urls.launchNewSalesDemo;
    onActiveTab({ value: isSales ? "sales" : "poc" });
  }, []);

  const onActiveTab = tab => {
    setActiveTab(tab.value);
    history.push(
      tab.value === "poc" ? Urls.launchNewPocDemo : Urls.launchNewSalesDemo
    );
  };

  return (
    <div className="launch-demo">
      <GridContainer className="first-section">
        <GridItem sm={12} md={12}>
          <span className="go-back" onClick={() => history.goBack()}>
            <img src={backIcon} alt="back" /> Back
          </span>
        </GridItem>
        <GridItem xs={12} sm={3} md={3} lg={3}>
          <h3 className="page-title">
            {activeTab === "poc" ? "Sandboxes" : "Showcases"}
          </h3>
        </GridItem>
        <GridItem xs={12} sm={9} md={9} lg={9}>
          <LabelTabs
            activeTab={activeTab}
            tabs={expTabs}
            onActiveTab={onActiveTab}
          />
        </GridItem>
      </GridContainer>
      <div>
        {activeTab === "sales" ? (
          <LaunchSalesdemo activeTab={activeTab} />
        ) : activeTab === "poc" ? (
          <SandboxTemplates activeTab={activeTab} />
        ) : null}
      </div>
    </div>
  );
};

export default LaunchDemo;
