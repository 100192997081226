import React, { useState } from "react";
import { BrowserRouter, Route, NavLink } from "react-router-dom";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";
import { getRouteByName } from "../../utils/utilfunction";
import HeaderImage2 from "assets/img/w2.png";
import ArrowImage from "assets/img/arrow-right.png";
import Drawer from "@material-ui/core/Drawer";
import UpgradeSubscription from "views/Dashboard/UpgradeSubscription";

// Images
// import LearningCenterUpgradeImage from "assets/criterion_networks/img/LearningCenterUpgrade.png";
import arrowright from "assets/criterion_networks/img//arrow-right.png";
import learningcenter from "assets/criterion_networks/img/learning-center.png";
import { ReactComponent as learningSVG } from "assets/criterion_networks/svg/Learning center.svg";
import { ReactComponent as svg5 } from "assets/criterion_networks/svg/05.svg";
import SvgComponent from "utils/SvgComponent";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import { Api } from "config/api";
import Chatbot from "./chatbot/Chatbot";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "20px",
  },
  title: {
    color: theme.palette.secondary.main,
    marginTop: "10px",
  },
  subtitle: {
    marginBottom: "20px",
  },
  drawerPaper: {
    width: "30%",
  },
  btnColor: {
    background: theme.palette.buttonColor.main,
  },
  linkColor: {
    color: theme.palette.secondary.main,
    "&:hover": {
      color: `${theme.palette.secondary.main} !important`,
    },
    "&:focus": {
      color: `${theme.palette.secondary.main} !important`,
    },
  },
  font: {
    fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    "& li": {
      fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    },
    "& h3": {
      fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    },
    "& p": {
      fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    },
  },
  ClsOpacity: {
    opacity: "0.5",
  },
  arrow: {
    marginBottom: "1%",
    marginLeft: "1px",
  },
}));

const LearningCenterUpgrade = () => {
  const classes = useStyles();
  const [upgrade, setUpgrade] = useState("");
  const [showRightDrawer, toggleRightDrawer] = useState(false);

  let sections = {
    name: "Learning labs",
    id: "learning",
    path: getRouteByName("Learning"),
    Headerimg: HeaderImage2,
    Arrowimg: ArrowImage,
    ScreenName: "Learning",
    Upgrade: "Upgrade Learning Center",
  };
  const handleClickHelp = () => {
    SDCloudBackendAPIWithToken()
      .get(Api.getSSO)
      .then((sso) => window.open(sso.data.urls, "_blank"));
  };

  // const handleClickCustomerSupport = (value) => {
  //   setUpgrade(value);
  //   toggleRightDrawer(!showRightDrawer);
  // };

  const generateDrawerComponent = (value) => {
    return (
      <UpgradeSubscription
        toggleDrawer={toggleRightDrawer}
        expanded="support"
        upgradetype={value}
      />
    );
  };

  return (
    <div className={classes.root}>
      {/* <Grid container spacing={3}  className="header"> */}
      {/* <Grid item xs={6} sm={6} pl={5} className="page-title pl-5">Learning center</Grid>
        <Grid item xs={6} sm={6} className="text-right"> 
            <p class="profile-icon">DN</p>
		      	<p class="profilename"> Longfirst Name  <br/> <span>Network Manager</span></p>
		      	<p class="alertbox"><img src={alert} class="" /> <a href="#" class="badge badge-danger">4</a></p>                  
        </Grid>
      </Grid>  */}

      <Grid container spacing={3} className="bg-gray pt-2">
        <Grid item xs={12} sm={6} className="ml-5 pt-0">
          <div class={`design-card ${classes.font}`}>
            <h3 className="design-title">Learning center</h3>
            <p className="notavailale">Not available in your plan</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className="mr-3 ">
          <button
            className={`button upgrade-button ${classes.btnColor +
              " " +
              classes.font}`}
            style={{ cursor: "pointer" }}
            onClick={handleClickHelp}
          >
            {" "}
            Upgrade your subscription
          </button>
        </Grid>
      </Grid>

      <Grid container spacing={3} className="bg-gray pt-2">
        <Grid item xs={12} sm={6} className="pl-8 pt-0">
          <div class="design-card">
            <ul className={`design-list ${classes.font}`}>
              <li>Self paced learning focused on skills development</li>
              <li>Hands-on learning modules with scripted lab exercises</li>
              <li>On-Demand Lab access</li>
            </ul>
            <a
              className={`sub-title-link ${classes.btnStyle +
                " " +
                classes.font}`}
              onClick={handleClickHelp}
              target="_blank"
              rel="noreferrer noopener"
            >
              Please visit our help center and submit a request to upgrade your
              subscription
              <SvgComponent
                className={classes.arrow}
                type="stroke"
                svgSource={svg5}
              />
              {/* <img src={arrowright} class="arrow" /> */}
            </a>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className="mr-3">
          <SvgComponent
            className={classes.ClsOpacity}
            type="fill"
            svgSource={learningSVG}
          />
          {/* <img src={learningSVG} class="align-center" /> */}
        </Grid>
      </Grid>
      {/* </Grid> */}
      <Drawer
        anchor={"right"}
        open={showRightDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        PaperProps={{ id: "infiniteScrollNotification" }}
        onClose={() => toggleRightDrawer(!showRightDrawer)}
      >
        {generateDrawerComponent(upgrade)}
      </Drawer>
      <Chatbot/>
    </div>
  );
};

export default LearningCenterUpgrade;
