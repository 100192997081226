import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
// import style manually
import 'react-markdown-editor-lite/lib/index.css';

// Finish!

// This component creates a modal for Reserved IP with respective form fields, the
// modal is popped up during provision or resuming of a lab or poc.

import React, { Component, useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
// Formik
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import GridItem from "components/Grid/GridItem";
import { Api, Urls } from "config/api";
import Loader from "react-loader-spinner";
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import { LOADER_TYPE } from "global_constants/app_constants";
import "views/ServiceOffering/LearningLab/acess labs/Overview.scss";
import NotificationAlert from "views/Components/NotificationAlert";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import Typography from "@material-ui/core/Typography";
import theme from "theme";
//API Call
import { CMSAPIWithToken } from "../../../apis/CMS";
//react 
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

const inputProps = {
  disableAnimation: true,
  shrink: false
};

const customStyles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
      },
  mainContainer: {
    padding: "40px"
  },
  container: {
    marginTop: "20px"
  },
  formContainer: {
    width: "100%"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    position: "absolute",
    width: 550,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    display: "block"
  },
  buttStyle: {
    background: theme.palette.buttonColor.main,
    "&:hover": {
      background: theme.palette.buttonColor.main
    }
  },
  firstTitleLabel: {
    padding: "0 15px"
  },
  titleLabel: {
    padding: "0 15px",
    marginTop: "30px"
  },
  errorText: {
    fontSize: "12px",
    color: theme.palette.error.main
  }
});


const LearningCategoryCreate = props => {
  const useStyles = makeStyles(customStyles);
  const classes = useStyles();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [value, setValue] = React.useState("ipv4");
  const dispatch = useDispatch();
  const history = useHistory();
  let queryParams = queryString.parse(window.location.search);
  var lern_id = queryParams.id;
  var learning_id_title_map = JSON.parse(localStorage.getItem("lern_id_title_map"))
  var learning_title = learning_id_title_map[lern_id]

  useEffect(() => {
    getAccountInfo();
  }, []);
 
  
  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {  
    lab_name: "",
    lab_identifier: "",
    lab_icon: "",
    admin_learnings_id: lern_id,
    category_position: null
              },
    onSubmit: (values) => {
      let payload = {
        lab_name: values.lab_name,
        lab_identifier: values.lab_identifier,
        lab_icon:values.lab_icon,
        admin_learnings_id:lern_id,
        category_position:values.category_position,
      };

        CMSAPIWithToken() 
        .post("/learnings/admin_learnings_course_labs_categories/",payload) 
        .then((resp) => resp.data.results)
        .then((data) => {
            const payload = {
                message: "Category Created Successfully",
                color: "success",
            };
            payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
            history.push("ProductContentLearningsMenuItems?id="+lern_id+"&title="+learning_title);
        })
        .catch((err) => {
          let message = err.response.data.message;
          const payload = {
            message: message,
            color: "danger",
        };
        payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
    },
    validate: (values) => {
      let errors = {};
      if (!values.lab_name) {
        errors.lab_name = "Required";
      }
      if (!values.lab_identifier) {
        errors.lab_identifier = "Required";
      }
      if (!values.lab_icon) {
        errors.lab_icon = "Required";
      }
      if (!values.category_position) {
        errors.category_position = "Valid intiger Required";
      }
      return errors;
    },
  });

  const getAccountInfo = () => {
    SDCloudBackendAPIWithToken()
      .get(Api.accountInfo)
      .then(res => {
      
      })
      .then(() => {
        setIsLoading(false);
      })
      .catch(err => {
        //console.log(err.response);
        setIsLoading(false);
      });
  };
let Cancelbutton=()=>{
  history.push("ProductContentLearningsMenuItems?id="+lern_id+"&title="+learning_title);
}
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });

const handleChange = (event) => {
  setState({ ...state, [event.target.name]: event.target.checked });
};

  return (
    // Form modal for sandbox create
    <div>
      <LoaderHoc isLoading={isLoading}>
        <Paper elevation={0}>
          <Grid
            container
            className={classes.mainContainer}
            style={{ marginTop: "2%" }}
          >
            <form
              onSubmit={formik.handleSubmit}
              className={classes.formContainer}
            >
              {/* <Typography variant="h4">{props.formTitle}</Typography> */}
              <Typography variant="h4" className={classes.firstTitleLabel}>
              Add Learning Category
              </Typography>
              <Grid container className={classes.container}>

              <GridItem md={9}>
                  {formik.errors.admin_learnings_id && formik.touched.admin_learnings_id ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Admin Learnings Name"
                      name="admin_learnings_name"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={learning_title}
                      onChange={formik.handleChange}
                      helperText={formik.errors.admin_learnings_id}
                      onBlur={formik.handleBlur}
                      error
                      disabled={"disabled"}
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Admin Learnings Name"
                      name="admin_learnings_name"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={learning_title}
                      onChange={formik.handleChange}
                      disabled={"disabled"}
                    />
                  )}
                </GridItem>

              <GridItem md={9}>
              {formik.errors.lab_name && formik.touched.lab_name ? (
                <TextField
                      type="text"
                      variant="filled"
                      label="Lab Name"
                      name="lab_name"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.lab_name}
                      helperText={formik.errors.lab_name}
                      onChange={formik.handleChange}
                      error
                    />
                    ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Lab Name"
                      name="lab_name"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.lab_name}
                      onChange={formik.handleChange}
                      />
                    )}
                </GridItem>

                <GridItem md={9}>
                  {formik.errors.lab_identifier && formik.touched.lab_identifier ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Lab Identifier"
                      name="lab_identifier"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.lab_identifier}
                      onChange={formik.handleChange}
                      helperText={formik.errors.lab_identifier}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Lab Identifier"
                      name="lab_identifier"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.lab_identifier}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>

                <GridItem md={9}>
                  {formik.errors.lab_icon && formik.touched.lab_icon ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Lab Icon"
                      name="lab_icon"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.lab_icon}
                      onChange={formik.handleChange}
                      helperText={formik.errors.lab_icon}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Lab Icon"
                      name="lab_icon"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.lab_icon}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>

                <GridItem md={9}>
                  {formik.errors.category_position && formik.touched.category_position ? (
                    <TextField
                      type="number"
                      variant="filled"
                      label="Category Position"
                      name="category_position"
                      min={1}
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.category_position}
                      onChange={formik.handleChange}
                      helperText={formik.errors.category_position}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="number"
                      min={1}
                      variant="filled"
                      label="Category Position"
                      name="category_position"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.category_position}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>
                

              </Grid>
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{ marginTop: "20px", marginBottom: "30px" }}
              >
                <GridItem xs={12} sm={6} md={3} lg={3}>
                  {!btnDisabled ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      disableElevation
                      size="large"
                      className={classes.buttStyle}
                      fullWidth
                      disableFocusRipple
                      type="submit"
                    >
                      Submit
                    </Button>
                  ) : null}
                  {btnDisabled ? (
                    <Loader
                      type={LOADER_TYPE}
                      color={theme.palette.secondary.main}
                      width={50}
                      height={50}
                      style={{ float: "right", marginRight: "15px" }}
                    />
                  ) : null}
                </GridItem>
                <GridItem xs={12} sm={6} md={3} lg={3}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    disableElevation
                    size="large"
                    // className={classes.buttStyle}
                    fullWidth
                    onClick={Cancelbutton}
                    disableFocusRipple
                    type="submit"
                  >
                    Cancel
                  </Button>
                </GridItem>
              </Grid>

              <NotificationAlert
                notificationType={notificationType}
                showNotification={showNotification}
                message={notificationMessage}
              />
            </form>
          </Grid>
        </Paper>
      </LoaderHoc>
    </div>
  );
};

export default LearningCategoryCreate;
