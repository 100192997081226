// calls getNewLearnings api, handles loader and renders cards of learning labs.

import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Api, Offerings } from "config/api";
import LearningCards from "views/ServiceOffering/LearningLab/LearningCards";
import LearningCenterUpgrade from "../../../views/Components/LearningCenterUpgrade";
import ExperienceCenterUpgrade from "../../../views/Components/ExperienceCenterUpgrade";
import PlaceholderCard from "views/ServiceOffering/LearningLab/PlaceholderCard";
import { SHOW_LOADER } from "action_creators/actioncreator";
import { HIDE_LOADER } from "action_creators/actioncreator";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import { CMSAPIWithToken } from "apis/CMS";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import { getSandboxesCluster } from "../../../apis/APIServices";
import theme from "theme";

class ViewLearningLabs extends React.Component {
  constructor(props) {
    super(props);
    this.offeringType = this.props.offeringType;
    this.state = {
      learn: [],
      labFilter: this.props.labFilter,
      show: false,
      activeLabs: [],
      inactiveLabs: [],
      subscribedLabs: [],
      unsubscribedLabs: [],
      allLabs: [],
      selectedRows: [],
      isResourceLoaded: false,
      isProfileLoaded: false,
      isProfileResourceLoaded: false
    };
  }

  componentDidUpdate = async prevProps => {
    if (
      prevProps.profile.profileObj.username !==
      this.props.profile.profileObj.username
    ) {
      this.setState({
        isProfileLoaded: true
      });
      if (this.offeringType.title === Offerings.POC_LIBRARY.title) {
        const res = await getSandboxesCluster();
        if (!res.success) {
          const payload = {
            message: res.msg || "unknown error occured",
            color: "danger"
          };
          this.props.dispatch(SHOW_NOTIFICATION({ payload }));
          this.props.dispatch(HIDE_LOADER());
        } else {
          const clouds = res.data.clouds;
          const activeLabs = clouds.filter(
            item => !item.deleted && item.uuid && item.status != "suspended"
          );
          const terminatedLabs = clouds.filter(item => item.deleted);
          const inactiveLabs = clouds.filter(
            item => !item.deleted && item.status == "suspended"
          );
          let updatedState = {
            activeLabs,
            inactiveLabs,
            allLabs: activeLabs.concat(inactiveLabs).concat(terminatedLabs)
          };
          if (activeLabs.length === 0) {
            updatedState = { ...updatedState, show: true };
          } else {
            updatedState = { ...updatedState, show: false, learn: clouds };
          }
          // if (this.props.profile.profileObj.privilege === "developer") {
          //   this.getProfile();
          // } else if (this.props.profile.profileObj.privilege === "admin") {
          //   this.getResources();
          // }
          this.setState(updatedState);
          this.setState({
            isResourceLoaded: true
          });
          this.props.dispatch(HIDE_LOADER());
        }
      } else {
        SDCloudBackendAPIWithToken()
          .get(Api.getNewLearning)
          .then(res => {
            let activeLabs = [];
            let inactiveLabs = [];
            let allLabs = [];
            const is_scripted_demo = this.offeringType === Offerings.SALES_DEMO;
            res.data.learnings.map(x => {
              if (x.is_scripted_demo === is_scripted_demo) {
                if (x.is_active && x.lab_status != "Not provisioned") {
                  activeLabs.push(x);
                } else {
                  inactiveLabs.push(x);
                }
              }
            });
            allLabs = activeLabs.concat(inactiveLabs);
            this.setState({ activeLabs, inactiveLabs, allLabs });
            if (activeLabs.length === 0) {
              this.setState({ show: true });
            } else {
              this.setState({ show: false, learn: res.data.learnings });
            }
          })
          .then(() => {
            // if (this.props.profile.profileObj.privilege === "developer") {
            //   this.getProfile();
            // } else if (this.props.profile.profileObj.privilege === "admin") {
            //   this.getResources();
            // }
            this.setState({
              isResourceLoaded: true
            });
            this.props.dispatch(HIDE_LOADER());
          })
          .catch(err => {
            // const payload = {
            //   message: err?.response?.data?.message || "unknown error occured",
            //   color: "danger",
            //   error: err
            // };
            // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
          })
          .finally(() => {
            // this.props.dispatch(HIDE_LOADER());
          });
      }
    }
    if (prevProps.labFilter !== this.props.labFilter) {
      this.setState({
        labFilter: this.props.labFilter
      });
    }
  };

  // ping cms for getting lab id using lab identifier for access learning lab.
  getLabIdfromLabIdentifier = labIdentifier => {
    let labCmsUrl =
      this.offeringType.resource === Offerings.SALES_DEMO.resource
        ? Api.adminSalesDemo(1000)
        : Api.viewLearningLab(1000);
    return CMSAPIWithToken()
      .get(labCmsUrl)
      .then(res => {
        const matchedLabFromCms = res.data.results.filter(item => {
          return item.identifier == labIdentifier;
        });
        return matchedLabFromCms[0];
      })
      .catch(err => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err,
        //   showErrorPage: true
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  componentWillUnmount = () => {
    // resetting navbar to show current route name
    this.props.dispatch(SET_NAVBAR_TITLE(""));
  };

  componentDidMount = async () => {
    this.offeringType.title === "learning lab"
      ? this.props.dispatch(SET_NAVBAR_TITLE("Learn"))
      : this.props.dispatch(SET_NAVBAR_TITLE("Experience"));
    this.props.dispatch(SHOW_LOADER());

    // For coming back from browser statck to this component
    if (this.props.profile.profileObj.add_hours) {
      this.setState({
        isProfileLoaded: true
      });
    } else {
      this.setState({
        isProfileLoaded: true
      });
    }
    if (this.offeringType.title === Offerings.POC_LIBRARY.title) {
      const res = await getSandboxesCluster();
      if (!res.success) {
        const payload = {
          message: res.msg || "unknown error occured",
          color: "danger"
        };
        this.props.dispatch(SHOW_NOTIFICATION({ payload }));
        this.props.dispatch(HIDE_LOADER());
      } else {
        const clouds = res.data.clouds;
        const activeLabs = clouds.filter(
          item => !item.deleted && item.uuid && item.status != "suspended"
        );
        const terminatedLabs = clouds.filter(item => item.deleted);
        const inactiveLabs = clouds.filter(
          item => !item.deleted && item.status == "suspended"
        );
        let updatedState = {
          activeLabs,
          inactiveLabs,
          allLabs: activeLabs.concat(inactiveLabs).concat(terminatedLabs)
        };
        if (activeLabs.length === 0) {
          updatedState = { ...updatedState, show: true };
        } else {
          updatedState = { ...updatedState, show: false, learn: clouds };
        }
        // if (this.props.profile.profileObj.privilege === "developer") {
        //   this.getProfile();
        // } else if (this.props.profile.profileObj.privilege === "admin") {
        //   this.getResources();
        // }
        this.setState(updatedState);
        this.setState({
          isResourceLoaded: true
        });
        this.props.dispatch(HIDE_LOADER());
        // this.setState({ isResourceLoaded: true });
        // this.props.dispatch(HIDE_LOADER());
      }
    } else {
      SDCloudBackendAPIWithToken()
        .get(Api.getNewLearning)
        .then(res => {
          let activeLabs = [];
          let inactiveLabs = [];
          let allLabs = [];
          const is_scripted_demo = this.offeringType === Offerings.SALES_DEMO;
          res.data.learnings.map(x => {
            if (x.is_scripted_demo === is_scripted_demo) {
              if (x.is_active && x.lab_status != "Not provisioned") {
                activeLabs.push(x);
              } else {
                inactiveLabs.push(x);
              }
            }
          });
          allLabs = activeLabs.concat(inactiveLabs);
          this.setState({ activeLabs, inactiveLabs, allLabs });
          if (activeLabs.length === 0) {
            this.setState({ show: true });
          } else {
            this.setState({ show: false, learn: res.data.learnings });
          }
        })
        .then(() => {
          // if (this.props.profile.profileObj.privilege === "developer") {
          //   this.getProfile();
          // } else if (this.props.profile.profileObj.privilege === "admin") {
          //   this.getResources();
          // }
          this.setState({
            isResourceLoaded: true
          });
          this.props.dispatch(HIDE_LOADER());
        })
        .catch(err => {
          // const payload = {
          //   message: err?.response?.data?.message || "unknown error occured",
          //   color: "danger",
          //   error: err
          // };
          // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
        })
        .finally(() => {
          // this.props.dispatch(HIDE_LOADER());
        });
    }
  };

  //get resources for user type admin
  getResources = async () => {
    const isLearn =
      this.offeringType.resource === Offerings.LEARNING_LABS.resource;
    const isSalesDemo =
      this.offeringType.resource === Offerings.SALES_DEMO.resource;
    const isSandbox =
      this.offeringType.resource === Offerings.POC_LIBRARY.resource;
    return SDCloudBackendAPIWithToken()
      .get(Api.getResource)
      .then(res => {
        if (isLearn || isSalesDemo) {
          // creating a mapping of lab details.
          let subscribedLabs = [];
          let unsubscribedLabs = [];
          let allLabs = [...this.state.allLabs];
          res.data.learnings.map(x => {
            if (x.is_scripted_demo === isSalesDemo) {
              let tries = Math.floor(
                (x.allocated_hours - x.consumed_hours) / x.labhours
              );
              let availableTries = tries < 1 ? 0 : tries;
              if (availableTries) {
                subscribedLabs.push(x);
              } else {
                unsubscribedLabs.push(x);
              }
            }
          });
          this.setState({ allLabs });
          return { subscribedLabs, unsubscribedLabs, allLabs };
        } else {
          let subscribedLabs = [];
          let unsubscribedLabs = [];
          let allLabs = [...this.state.allLabs];
          res.data.sandboxes.map(x => {
            let tries = Math.floor(
              (x.allocated_hours - x.consumed_hours) / x.labhours
            );
            let availableTries = tries < 1 ? 0 : tries;
            if (availableTries) {
              subscribedLabs.push(x);
            } else {
              unsubscribedLabs.push(x);
            }
          });
          this.setState({ allLabs });
          return { subscribedLabs, unsubscribedLabs, allLabs };
        }
        // this.props.dispatch(HIDE_LOADER());
      })
      .then(({ subscribedLabs, unsubscribedLabs, allLabs }) => {
        return this.getCmsData(subscribedLabs, unsubscribedLabs, allLabs);
      })
      .catch(err => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err,
        //   showErrorPage: true
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
        this.props.dispatch(HIDE_LOADER());
      });
  };

  // gets lab name and tries related data from backend db for user type non admin.
  getProfile = () => {
    const isLearn =
      this.offeringType.resource === Offerings.LEARNING_LABS.resource;
    const isSalesDemo =
      this.offeringType.resource === Offerings.SALES_DEMO.resource;
    const isSandbox =
      this.offeringType.resource === Offerings.POC_LIBRARY.resource;
    return SDCloudBackendAPIWithToken()
      .get(Api.getProfile)
      .then(res => {
        let subscribedLabs = [];
        let unsubscribedLabs = [];
        let allLabs = [...this.state.allLabs];
        res.data.profiles[0].permissions.forEach(item => {
          // is_scripted_demo = false implies not a sales demo.
          if (isSandbox && item.resource_type === "sandbox") {
            let tries = Math.floor(
              (item.allocated_hours - item.consumed_hours) / item.labhours
            );
            let availableTries = tries < 1 ? 0 : tries;
            if (availableTries) {
              subscribedLabs.push(item);
            } else {
              unsubscribedLabs.push(item);
            }
          } else if (isSalesDemo || isLearn) {
            if (item.is_scripted_demo === isSalesDemo) {
              let tries = Math.floor(
                (item.allocated_hours - item.consumed_hours) / item.labhours
              );
              let availableTries = tries < 1 ? 0 : tries;
              if (availableTries) {
                subscribedLabs.push(item);
              } else {
                unsubscribedLabs.push(item);
              }
            }
          }
          // else if (isSandbox && item.resource_type === "sandbox") {
          //   let tries = Math.floor(
          //     (item.allocated_hours - item.consumed_hours) / item.labhours
          //   );
          //   let availableTries = tries < 1 ? 0 : tries;
          //   if (availableTries) {
          //     subscribedLabs.push(item);
          //   } else {
          //     unsubscribedLabs.push(item);
          //   }
          // }
        });
        this.setState({ allLabs });
        return { subscribedLabs, unsubscribedLabs, allLabs };
      })
      .then(({ subscribedLabs, unsubscribedLabs, allLabs }) => {
        return this.getCmsData(subscribedLabs, unsubscribedLabs, allLabs);
      })
      .catch(err => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err,
        //   showErrorPage: true
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  getCmsData = async (subscribedDetails, unsubscribedDetails, allLabs) => {
    const getCmsDataUrl =
      this.offeringType.resource === Offerings.LEARNING_LABS.resource
        ? Api.viewLearningLab(1000)
        : this.offeringType.resource === Offerings.SALES_DEMO.resource
        ? Api.adminSalesDemo(1000)
        : `${Api.adminSandboxes}?limit=1000`;
    return CMSAPIWithToken()
      .get(getCmsDataUrl)
      .then(res => {
        let cmsData = res.data.results;
        // creating a mapping of labName to lab details for cms data.
        let cmsLabtoDetailsMap = [];
        cmsData.forEach(item => {
          cmsLabtoDetailsMap.push(
            item.identifier ? item.identifier : item.identity
          );
        });
        let activeLabsFilter = [];
        allLabs.forEach(item => {
          activeLabsFilter.push(item.name);
        });
        cmsLabtoDetailsMap = cmsLabtoDetailsMap.filter(name => {
          if (!activeLabsFilter.includes(name)) {
            return name;
          }
        });
        let subscribedLabs = subscribedDetails.filter(item => {
          let name = item.name ? item.name : item.resource_name;
          if (cmsLabtoDetailsMap.includes(name)) {
            return item;
          }
        });
        let unsubscribedLabs = unsubscribedDetails.filter(item => {
          let name = item.name ? item.name : item.resource_name;
          if (cmsLabtoDetailsMap.includes(name)) {
            return item;
          }
        });
        this.setState({ subscribedLabs, unsubscribedLabs });
        let activeLabsMap = [];
        allLabs.forEach(item => {
          activeLabsMap.push(item.name);
        });
        let inactiveSubscribedLabs = subscribedLabs;
        inactiveSubscribedLabs = inactiveSubscribedLabs.filter(item => {
          if (!activeLabsMap.includes(item.resource_name)) {
            return item;
          }
        });
        allLabs = allLabs
          .concat(inactiveSubscribedLabs)
          .concat(unsubscribedLabs);
        this.setState({ allLabs });
        this.setState({
          isResourceLoaded: true
        });
        this.props.dispatch(HIDE_LOADER());
      })
      .catch(err => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err,
        //   showErrorPage: true
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
        this.setState({
          isResourceLoaded: true
        });
        this.props.dispatch(HIDE_LOADER());
      });
  };

  onRowSelect = selectedRows => {
    this.setState({
      selectedRows
    });
  };

  render() {
    if (!this.state.isProfileLoaded || !this.state.isResourceLoaded) {
      return null;
    } else {
      // load placeholder card when there are no learnings subscribed.
      if (this.state.isResourceLoaded && this.state.allLabs.length === 0) {
        return this.offeringType.title === "learning lab" ? (
          // <div>
          //   <Grid container justify="flex-end" alignItems="flex-end">
          //     <Grid className="launch-btn" item xs={12} sm={12} md={12} lg={2}>
          //       <Button
          //         variant="contained"
          //         color="secondary"
          //         disableElevation
          //         size="large"
          //         disableFocusRipple
          //         onClick={this.props.handleLaunchNewLab}
          //       >
          //         Launch new +
          //       </Button>
          //     </Grid>
          //   </Grid>
          //   <div className="learning-card">
          //     <LearningCenterUpgrade />
          //   </div>
          // </div>
          <div className="card-content">
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="column"
            >
              <Grid item xs={12} md={10} lg={10}>
                <div className="learning-card">
                  <LearningCenterUpgrade />
                  <Grid
                    className="launch-btn"
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={4}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      disableElevation
                      size="large"
                      style={{ background: theme.palette.buttonColor.main }}
                      disableFocusRipple
                      onClick={this.props.handleLaunchNewLab}
                    >
                      Launch new +
                    </Button>
                  </Grid>
                </div>
              </Grid>
            </Grid>

            {/* <Grid container>
              <PlaceholderCard offeringType={this.offeringType} />
            </Grid> */}
          </div>
        ) : (
          <div className="card-content">
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="column"
            >
              <Grid item xs={12} md={10} lg={10}>
                <div className="learning-card">
                  <ExperienceCenterUpgrade />
                  <Grid
                    className="launch-btn"
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={4}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      disableElevation
                      size="large"
                      style={{ background: theme.palette.buttonColor.main }}
                      disableFocusRipple
                      onClick={this.props.handleLaunchNewLab}
                    >
                      Launch new +
                    </Button>
                  </Grid>
                </div>
              </Grid>
            </Grid>

            {/* <Grid container>
              <PlaceholderCard offeringType={this.offeringType} />
            </Grid> */}
          </div>
        );
      }
      // return existing labs otherwise.
      if (this.state.isProfileLoaded && this.state.isResourceLoaded) {
        return (
          <GridContainer>
            <GridItem xs={12}>
              <LearningCards
                labFilter={this.state.labFilter}
                offeringType={this.offeringType}
                privilege={this.props.profile.profileObj.privilege}
                labs={{
                  activeLabs: this.state.activeLabs,
                  inactiveLabs: this.state.inactiveLabs,
                  allLabs: this.state.allLabs,
                  subscribedLabs: this.state.subscribedLabs,
                  unsubscribedLabs: this.state.unsubscribedLabs
                }}
                addHoursDetails={this.props.profile.profileObj.add_hours}
                getLabIdfromLabIdentifier={this.getLabIdfromLabIdentifier}
                onRowSelect={this.onRowSelect}
                handleActive={this.props.handleActive}
                buttonTitle={this.props.buttonTitle}
                handleLaunchNewLab={this.props.handleLaunchNewLab}
              />
            </GridItem>
          </GridContainer>
        );
      }
    }
  }
}

const mapStateToProps = state => {
  return {
    profile: state.profileObj
  };
};

const ViewLearningLabsConnect = withRouter(ViewLearningLabs);
export default connect(mapStateToProps)(ViewLearningLabsConnect);
