// This component creates a modal for Reserved IP with respective form fields, the
// modal is popped up during provision or resuming of a lab or poc.

import React, { useEffect, useState } from "react";
import { SECONDARY_COLOR } from "global_constants/app_constants";
import Button from "@material-ui/core/Button";
// Formik
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Loader from "react-loader-spinner";
import { LOADER_TYPE } from "global_constants/app_constants";
import "views/ServiceOffering/LearningLab/acess labs/Overview.scss";
import NotificationAlert from "views/Components/NotificationAlert";
import theme from "theme";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { msToStr } from "../../ServiceOffering/LearningLab/acess labs/sdntokenizerhelper";
import moment from "moment";
import { Alert } from "@material-ui/lab";

const inputProps = {
  disableAnimation: true,
  shrink: false
};

const customStyles = theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3)
  },
  modalCloseIcon: {
    position: "absolute",
    marginTop: "-125px",
    marginLeft: "45px",
    cursor: "pointer",
    color: "#FFFFFF",
    fontSize: "2rem"
  },
  datetime: {
    color: SECONDARY_COLOR
  },
  multilineColor: {
    color: "#7D7D7D"
  },
  helperCustom: {
    color: "#7D7D7D",
    margin: "10px 0 1px"
  },
  containerNew: {
    margin: "0px"
  },
  customDropDownStyle: {
    marginTop: "2px",
    marginBottom: "20px"
  },
  radioGroup: {
    marginBottom: "20px"
  },
  buttStyle: {
    background: theme.palette.buttonColor.main,
    "&:hover": {
      background: theme.palette.buttonColor.main
    }
  },
  font: {
    fontFamily: `${theme.palette.fontList.selectedFont} !important`
  },
  purposeDropdown: {
    width: "100%",
    marginTop: "5px"
  },
  zoneDropdown: {
    width: "100%",
    marginTop: "5px",
  },
  testEnvironmentRadio: {
    marginTop: "-5px",
    fontWeight: "400"
  }
});

const DemoLaunchModal = props => {
  const useStyles = makeStyles(customStyles);
  const classes = useStyles();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [ipListDisabled, setIpListDisabled] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [error, setError] = React.useState(false);
  const [value, setValue] = React.useState("dynamic");
  const [ip, setIp] = React.useState(null);
  const [purpose, setPurpose] = useState(null);
  const [zone, setZone] = useState('North_America');
  const [menuItems, setMenuItems] = useState([]);
  const [menuItemsVals2, setMenuItemsVals2] = useState([]);
  const [menuItemsVals1, setMenuItemsVals1] = useState([]);
  let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
  let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
  let hasDomain = domain?.some(res => userEmail.includes(res));
  const [isInternal, setIsInternal] = useState(hasDomain);
  const env = localStorage.getItem("ENVIRONMENT");
  useEffect(() => {
    let available_purposes = JSON.parse(
      localStorage.getItem("available_purposes")
    );
    if (isInternal) {
      if (available_purposes?.internal) {
        let purposes = available_purposes.internal.map(item => {
          return <option value={item}>{item}</option>;
        });
        setMenuItems([<option value={null}>None</option>, ...purposes]);
        var deal_id = JSON.parse(localStorage.getItem("deal_ids"))
        let deals = deal_id.map(item => {
          return <option value={item}>{item}</option>;
        });
        setMenuItemsVals2([<option value={null}>None</option>, ...deals]);
      }
    } else {
      if (available_purposes?.external) {
        let purposes = available_purposes.external.map(item => {
          return <option value={item}>{item}</option>;
        });
        setMenuItems([<option value={null}>None</option>, ...purposes]);
      }
    }
    let available_zone = JSON.parse(
      localStorage.getItem("available_zones")
    );
    let available_zones=Object.keys(available_zone)
    if (available_zones) {
      let zones = available_zones.map(item => {
        return <option value={item}>{item}</option>;
      }); 
      setMenuItemsVals1([...zones]);
    }
  }, []);

  const hideModal = () => {
    props.hideDemoModal();
  };

  const handleRadioChange = event => {
    event.target.value === "reserved"
      ? setIpListDisabled(true)
      : setIpListDisabled(false);
    setValue(event.target.value);
  };
  const handleIpChange = event => {
    setIp(event.target.value);
    setError(false);
  };
  const handleChange=(event) => {
    setZone(event.target.value)
    setShowNotification(false);
    if(event.target.value !== 'North_America'){
      setShowNotification(true);
      setNotificationType("warning");
      setNotificationMessage("By default, the lowest cost zone is set. If you switch, it will cost more.");
    }
  }

  let labTimeLeft = msToStr(
    moment(new Date()).format("YYYY-MM-DD HH:mm"),
    props.demoLaunchData.runTime.labData.expiryDate
  );
  const formik = useFormik({
    initialValues: {
      demoName: "",
      customerName: "",
      name: props.demoLaunchData.runTime.labData.display_name,
      action: "terminate",
      endTime: props.demoLaunchData.runTime.labData.labhours,
      purpose: "",
      environment: false,
      dealId:"",
      // zone:"",
      instance:"standard"
    },
    onSubmit: values => {
      if (value == "reserved" && !ip) {
        setError(true);
        return;
      }

      setBtnDisabled(true);
      let reservedIp = false;
      if (value != "dynamic") {
        reservedIp = true;
      }
      let zoneValue = ''
      if(isInternal){
        zoneValue = ''
      } else {
        zoneValue = zone
      }
      values.customerName = "";
      values.demoName = props.demoLaunchData.runTime.labData.display_name;
      const labData = props.demoLaunchData;
      labData.launchLabBtnHandler(
        labData.labId,
        labData.labIdentifier,
        labData.labEnabled, // labEnabled parameter
        labData.labName,
        reservedIp ? reservedIp : null,
        ip ? ip : null,
        values.customerName,
        values.demoName,
        values.purpose ? values.purpose : null,
        values.environment ? "test" : "prod",
        values.dealId ?  values.dealId : null,
        // zoneValue ? zoneValue : null,
        values.instance ? values.instance : null,
      );
      setBtnDisabled(false);
      hideModal();
    },
    validate: values => {
      let errors = {};

      if ((values.purpose == "" || values.purpose == "None") && isInternal) {
        errors.purpose = "Required";
      } else if (values.purpose == "" && !isInternal) {
        values.purpose = JSON.parse(
          localStorage.getItem("available_purposes")
        ).external[0];
      }
      if (values.purpose == "Mint" && (values.dealId=="" || values.dealId == "None")){
        errors.dealId = "Required";
      }
      // if (values.zone == "" || values.zone == "None"){
      //   errors.zone = "Required";
      // }
      return errors;
    }
  });

  return (
    // Form modal for add hours to lab
    <Modal
      disableAutoFocus
      open={!props.openModal}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      className={classes.modal}
      onClose={() => hideModal()}
      disableBackdropClick
    >
      <GridContainer
        justify="center"
        alignItems="center"
        className={classes.paper}
      >
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginBottom: "15px" }}
        >
          <h3>{`Launch Sales demo`}</h3>
          <p>Please fill the details</p>
        </GridItem>
        <GridContainer justify="flex-end">
          <GridItem xs={12} sm={1} md={1} lg={1}>
            {" "}
            <CloseIcon
              className={classes.modalCloseIcon}
              onClick={() => hideModal()}
            />
          </GridItem>{" "}
        </GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <form onSubmit={formik.handleSubmit}>
            <Typography variant="body2" className={classes.helperCustom}>
              Name
            </Typography>
            {
              <TextField
                type="text"
                variant="filled"
                // label="Name"
                name="name"
                disabled={true}
                title="This field is not editable"
                fullWidth
                inputProps={{
                  className: classes.multilineColor
                }}
                InputProps={{ disableUnderline: true }}
                InputLabelProps={inputProps}
                value={
                  formik.values.name
                    ? formik.values.name
                    : props.demoLaunchData.runTime.labData.labName
                }
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            }
            {isInternal ? (
              <Typography variant="body2" className={classes.helperCustom}>
                Purpose
              </Typography>
            ) : null}
            {isInternal ? (
              <FormControl
                variant="filled"
                className={(classes.formControl, classes.purposeDropdown)}
              >
                {formik.errors.purpose && formik.touched.purpose ? (
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    fullWidth
                    name="purpose"
                    value={formik.values.purpose}
                    onChange={formik.handleChange}
                    native
                    error
                    helperText={formik.errors.purpose}
                    className={classes.purposeDropdown}
                  >
                    {menuItems.length > 0 ? (
                      menuItems
                    ) : (
                      <MenuItem value="">None</MenuItem>
                    )}
                  </Select>
                ) : (
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    fullWidth
                    name="purpose"
                    value={formik.values.purpose}
                    onChange={formik.handleChange}
                    native
                    className={classes.purposeDropdown}
                  >
                    {menuItems.length > 0 ? (
                      menuItems
                    ) : (
                      <MenuItem value="">None</MenuItem>
                    )}
                  </Select>
                )}
              </FormControl>
            ) : null}
            {isInternal && formik.values.purpose == "Mint" ? (
                <Typography variant="body2" className={classes.helperCustom}>
                  Select Deal id
                </Typography>
            ) : null}
            {isInternal && formik.values.purpose == "Mint"? (
                <FormControl
                    variant="filled"
                    className={(classes.formControl, classes.purposeDropdown)}
                >
                  {formik.errors.dealId && formik.touched.dealId ? (
                      <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          fullWidth
                          name="dealId"
                          value={formik.values.dealId}
                          onChange={formik.handleChange}
                          native
                          error
                          helperText={formik.errors.dealId}
                          className={classes.purposeDropdown}
                      >
                        {menuItemsVals2.length > 0 ? (
                            menuItemsVals2
                        ) : (
                            <MenuItem value="">None</MenuItem>
                        )}
                      </Select>
                  ) : (
                      <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          fullWidth
                          name="dealId"
                          value={formik.values.dealId}
                          onChange={formik.handleChange}
                          native
                          className={classes.purposeDropdown}
                      >
                        {menuItemsVals2.length > 0 ? (
                            menuItemsVals2
                        ) : (
                            <MenuItem value="">None</MenuItem>
                        )}
                      </Select>
                  )}
                </FormControl>
            ) : null}
            {/* {!isInternal ? (
                  <Typography variant="body2" className={classes.helperCustom}>
                    Zone
                  </Typography>
                  ) : null}
                  {!isInternal ? (
                    <FormControl
                      variant="filled"
                      className={
                        (classes.formControl,
                        classes.zoneDropdown)
                      }
                    >
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          fullWidth
                          name="zone"
                          value={zone}
                          onChange={handleChange}
                          native
                          className={classes.zoneDropdown}
                        >
                          {menuItemsVals1.length > 0 ? (
                            menuItemsVals1
                          ) : (
                            <MenuItem value="">None</MenuItem>
                          )}
                        </Select>
                    </FormControl>
              ) : null} */}
                          
            <Typography variant="body2" className={classes.helperCustom}>
              Number of Hours
            </Typography>
            {
              <TextField
                // label="Number of Hours"
                variant="filled"
                name="endTime"
                disabled={true}
                title="This field is not editable"
                fullWidth
                inputProps={{
                  className: classes.multilineColor
                }}
                InputProps={{ disableUnderline: true }}
                InputLabelProps={inputProps}
                value={
                  formik.values.endTime ? formik.values.endTime : labTimeLeft
                }
                onBlur={formik.handleBlur}
              />
            }
            {isInternal && env!=="production"?
            (<Grid item xs={12}>
                              <Typography
                                variant="body2"
                                className={classes.helperCustom}
                              >
                                Instance type
                              </Typography>

                              <FormControl component="fieldset">
                                <RadioGroup
                                  row
                                  aria-label="ip_type"
                                  name="instance"
                                  value={formik.values.instance}
                                  className={classes.radioButton}
                                  onChange={formik.handleChange}
                                >
                                  <FormControlLabel
                                    value="standard"
                                    control={<Radio color="primary" />}
                                    label="Standard"
                                  />
                                  <FormControlLabel
                                    value="spot"
                                    control={<Radio color="primary" />}
                                    label="Spot"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
            ) : null}
            <GridContainer className={classes.containerNew}>
              <Grid item xs={5}>
                <Typography variant="body2" className={classes.helperCustom}>
                  End action
                </Typography>

                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="ip_type"
                    name="action"
                    value={formik.values.action}
                    className={classes.radioButton}
                    onChange={formik.handleChange}
                  >
                    <FormControlLabel
                      value="suspend"
                      disabled={true}
                      title="Suspend feature is not available for this product"
                      control={<Radio color="primary" />}
                      label="Suspend"
                    />
                    <FormControlLabel
                      value="terminate"
                      control={<Radio color="primary" />}
                      label="Terminate"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {props.reservedIp?.reservedIps ? (
                <Grid item xs={7}>
                  <Typography variant="body2" className={classes.helperCustom}>
                    IP Address Type
                  </Typography>
                  <FormControl>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="ipType"
                      value={value}
                      className={classes.radioGroup}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        control={<Radio />}
                        label="Default"
                        value="dynamic"
                      />
                      {/* {props.reservedIp.staticIp ? (
                      <FormControlLabel
                        control={<Radio />}
                        label="Static"
                        value="static"
                      />
                    ) : null} */}
                      <FormControlLabel
                        control={<Radio />}
                        label="Static"
                        value="reserved"
                      />
                    </RadioGroup>
                    <FormHelperText></FormHelperText>
                  </FormControl>
                </Grid>
              ) : null}
            </GridContainer>
            {ipListDisabled ? (
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  variant="filled"
                  className={classes.customDropDownStyle}
                >
                  <InputLabel disableAnimation shrink={false}>
                    {"Showcase will provision with the selected IP"}
                  </InputLabel>
                  <Select
                    disableUnderline
                    onChange={handleIpChange}
                    inputProps={{
                      name: "reservedIps"
                    }}
                    required
                    value={ip}
                  >
                    <MenuItem disabled value="">
                      Please select...
                    </MenuItem>
                    {props.reservedIp.reservedIps.map((menuItemVal, index) => {
                      return (
                        <MenuItem style={{background:"#FFFFFF"}} key={index} value={menuItemVal}>
                          {menuItemVal}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {error && (
                    <FormHelperText className={classes.errorText}>
                      This field is required!
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            ) : null}
            {!btnDisabled ? (
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                size="large"
                fullWidth
                className={classes.buttStyle}
                // onClick={addDomain}
                disableFocusRipple
                type="submit"
              >
                Provision
              </Button>
            ) : null}
            {isInternal ? (
              <div className={classes.testEnvironmentRadio}>
                <FormControl>
                  <span>
                    <Checkbox
                      tabIndex={-1}
                      name="environment"
                      disableRipple
                      onChange={formik.handleChange}
                      // checkedIcon={<Check className={classes.checkedIcon} />}
                      // icon={<Check className={classes.uncheckedIcon} />}
                    />{" "}
                    Is test run ?
                  </span>
                </FormControl>{" "}
              </div>
            ) : null}
            {btnDisabled ? (
              <Loader
                type={LOADER_TYPE}
                color={theme.palette.secondary.main}
                width={50}
                height={50}
                style={{ float: "right", marginRight: "15px" }}
              />
            ) : null}

            {showNotification ? (
              <Alert
                onClose={() => setShowNotification(false)}
                severity={notificationType}
              >
                {notificationMessage}
              </Alert>
            ) : null}
          </form>
        </GridItem>
      </GridContainer>
    </Modal>
  );
};

export default DemoLaunchModal;
