import React from "react";
import { SECURITY_QUESTIONS } from "global_constants/app_constants";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { FormControl, Grid, Input, InputLabel } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
const useStyles = makeStyles(styles);
const SecurityQuestionComponent = ({
  securityComponentState,
  setSecurityComponentState
}) => {
  const classes = useStyles();
  const MENU = SECURITY_QUESTIONS;
  const [userAnswer, setUserAnswer] = React.useState("");
  const [securityQuestion, setSecurityQuestion] = React.useState(MENU[0]);

  const handleDropDown = event => {
    setSecurityQuestion(event.target.value);
  };
  const handleAnswerInputText = event => {
    setUserAnswer(event.target.value);
  };
  const handleUpdateSecurityQuestion = () => {
    const { question, answer, id } = securityComponentState[0];
    // building obj
    // const questionAnswerObj = { id, question, answer };
  };
  return (
    <Grid
      className={classes.loginContainer}
      container
      justify="center"
      alignItems="center"
      style={{ height: "100%" }}
    >
      <Grid item xs={12}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={10} md={6}>
            <Grid container justify="flex-start" alignItems="center">
              <h4
                className={classes.loginTitle}
              >{`Please update Security Questions`}</h4>
              {/* <AlertBox
                  alertVisibility={alertVisibility}
                  alertData={alertData}
                /> */}
              {/* {!alertVisibility ? ( */}
              <form style={{ width: "100%" }}>
                <FormControl
                  fullWidth
                  // variant="filled"
                  className={classes.customInputStyle}
                >
                  <InputLabel htmlFor="questionSelect">Question</InputLabel>
                  <Select
                    value={securityQuestion}
                    // disableUnderline
                    onChange={event => handleDropDown(event)}
                    inputProps={{
                      name: "questionSelect"
                    }}
                    className={classes.select}
                  >
                    {MENU.map((menuItemVal, index) => {
                      return (
                        <MenuItem key={index} value={menuItemVal}>
                          {menuItemVal}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <br />
                <br />
                <FormControl
                  fullWidth
                  focused
                  className={classes.margin + " " + classes.customInputStyle}
                  // variant="outlined"
                >
                  <InputLabel htmlFor="securityanswer">Your answer</InputLabel>
                  <Input
                    id="securityanswer"
                    onChange={handleAnswerInputText}
                    value={userAnswer}
                  />
                </FormControl>
                <Button
                  className={classes.updateSecurityQuestionBtn}
                  onClick={() => handleUpdateSecurityQuestion()}
                  color="info"
                >
                  Update security question
                </Button>
                <p className={classes.skipthisstep}>Skip this step </p>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SecurityQuestionComponent;
