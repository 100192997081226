// This component creates a modal for Reserved IP with respective form fields, the
// modal is popped up during provision or resuming of a lab or poc.

import React, { Component, useState } from "react";
import { SECONDARY_COLOR } from "global_constants/app_constants";
import Button from "@material-ui/core/Button";
// Formik
import { useFormik } from "formik";
import Datetime from "react-datetime";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Api, Urls } from "config/api";
import Loader from "react-loader-spinner";
import { LOADER_TYPE } from "global_constants/app_constants";
import "views/ServiceOffering/LearningLab/acess labs/Overview.scss";
import NotificationAlert from "views/Components/NotificationAlert";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import theme from "theme";
const inputProps = {
  disableAnimation: true,
  shrink: false
};

const customStyles = theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3)
  },
  modalCloseIcon: {
    position: "absolute",
    marginTop: "-133px",
    marginLeft: "45px",
    cursor: "pointer",
    color: "#FFFFFF",
    fontSize: "2rem"
  },
  datetime: {
    color: SECONDARY_COLOR
  },
  buttStyle: {
    background: theme.palette.buttonColor.main,
    "&:hover": {
      background: theme.palette.buttonColor.main,
    }
  },
});

const AddDomainModal = props => {
  const useStyles = makeStyles(customStyles);
  const classes = useStyles();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");

  const hideModal = () => {
    props.hideModal();
  };

  const formik = useFormik({
    initialValues: {
      domainName: ""
    },
    onSubmit: values => {
      setShowNotification(false);
      setBtnDisabled(true);
      SDCloudBackendAPIWithToken()
        .post(Api.addTrustedDomain, {
          trusted_domains: [values.domainName]
        })
        .then(res => {
          setShowNotification(true);
          setNotificationMessage(
            "Domain successfully added to trusted domains"
          );
          setNotificationType("success");
          setBtnDisabled(false);
          props.fetchTrustedDomains();
        })
        .catch(err => {
          let error = err.response.data;
          setShowNotification(true);
          setNotificationMessage(error.message);
          setNotificationType("error");
          setBtnDisabled(false);
        });
    },
    validate: values => {
      let errors = {};

      if (!values.domainName) {
        errors.domainName = "Required";
      }

      return errors;
    }
  });

  return (
    // Form modal for add hours to lab
    <Modal
      disableAutoFocus
      open={!props.openModal}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      className={classes.modal}
      onClose={() => hideModal()}
    >
      <GridContainer
        justify="center"
        alignItems="center"
        className={classes.paper}
      >
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ marginBottom: "20px" }}
        >
          <h3>New trusted domain</h3>
          <p>Please enter the domain name</p>
        </GridItem>
        <GridContainer justify="flex-end">
          <GridItem xs={12} sm={1} md={1} lg={1}>
            {" "}
            <CloseIcon
              className={classes.modalCloseIcon}
              onClick={() => hideModal()}
            />
          </GridItem>{" "}
        </GridContainer>
        <form onSubmit={formik.handleSubmit}>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            {formik.errors.domainName && formik.touched.domainName ? (
              <TextField
                type="text"
                variant="filled"
                label="Domain Name:"
                name="domainName"
                fullWidth
                InputLabelProps={inputProps}
                value={formik.values.domainName}
                placeholder="e.g. https://domain.com"
                onChange={formik.handleChange}
                helperText={formik.errors.domainName}
                onBlur={formik.handleBlur}
                error
              />
            ) : (
              <TextField
                variant="filled"
                label="Domain Name:"
                name="domainName"
                fullWidth
                InputLabelProps={inputProps}
                value={formik.values.domainName}
                placeholder="e.g. https://domain.com"
                onChange={formik.handleChange}
              />
            )}
            {!btnDisabled ? (
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                size="large"
                className={classes.buttStyle}
                fullWidth
                // onClick={addDomain}
                disableFocusRipple
                type="submit"
              >
                Submit
              </Button>
            ) : null}
            {btnDisabled ? (
              <Loader
                type={LOADER_TYPE}
                color={theme.palette.secondary.main}
                width={50}
                height={50}
                style={{ float: "right", marginRight: "15px" }}
              />
            ) : null}

            <NotificationAlert
              notificationType={notificationType}
              showNotification={showNotification}
              message={notificationMessage}
            />
          </GridItem>
        </form>
      </GridContainer>
    </Modal>
  );
};

export default AddDomainModal;