import React, { useState } from 'react';
import { SDCloudBackendAPIWithToken,SDCloudCustomizeAPIWithToken} from "apis/backendAPI";
import "./chat.css";
import { IconButton } from '@material-ui/core';
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import SvgComponent from "./svgcomponent";
import { ReactComponent as chaticon } from "./chat.svg"
import { ReactComponent as arrowicon } from "./arrow.svg"
import LoaderHoc from "./Loader";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import criterion from "./criterion-logo-mini.png";
import {ReactComponent as CopyIcon} from "./copycontent.svg"
import ContentCopyIcon from '@material-ui/icons/FileCopy';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ReactComponent as MaximizeIcon} from './maximize.svg';
import { ReactComponent as MinimizeIcon} from './minimize.svg';
import { ReactComponent as CloseIcon} from './closebutton.svg';
import { ReactComponent as SendIcon} from './sendbutton.svg';
import { v4 as uuidv4 } from "uuid";
const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isMinimized, setIsMinimized] = useState(true);
  const [isMaximized, setIsMaximized] = useState(false);
  const [fileselected, setFilesSelected] = useState('');
  let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
  let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
  let hasDomain = domain?.some(res => userEmail.includes(res));
  let admin =JSON.parse(localStorage.getItem("profileData")).privilege === "admin";
  const [isInternal, setIsInternal] = useState(hasDomain);
  const location = useLocation();
  const handleInputChange = (event) => {
    setInputMessage(event.target.value);
  };
  const toggleChat = () => {
    setIsOpen(!isOpen);
    setIsMinimized(true)
    setIsMaximized(false)
    if (!isOpen) {
      getMessages();
    }
  };
  const toggleClose = () => {
    if(messages.length > 1){
      setDialogOpen(true);
    } else {
      setIsOpen(!isOpen);
    }
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
    setIsOpen(!isOpen)
  };

  const handleClearMessages = () => {
    setDialogOpen(false);
    let payload = {session_id1:session_id1,session_id2:session_id2}
    SDCloudBackendAPIWithToken()
    .post('clear_bot_history/',payload)
    .then((resp) => resp.data)
    .then((data) => {
      setMessages([]);
    })
    .catch((err) => {
      setIsLoading(false);
      let message = err.response.data.message;
      setMessages(prevMessages => [
        ...prevMessages,
        { text: message, sender: 'chat' }  
      ]);
    });
  };
  const getMessages = () => {
    if(messages.length === 0){
      const now = new Date();
      const hour = now.getHours();
      var greeting;
      if (hour >= 5 && hour < 12) {
          greeting = "Hello Good morning,\nHow can i help you?";
      } else if (hour >= 12 && hour < 18) {
          greeting = "Hello Good afternoon,\nHow can i help you?";
      } else {
          greeting = "Hello Good evening,\nHow can i help you?";
      }
      messages.push({ text: greeting, sender: 'chat' })
    }
    let payload = {session_id1:session_id1,session_id2:session_id2}
    SDCloudBackendAPIWithToken()
    .post('get_bot_history/',payload)
    .then((resp) => resp.data)
    .then((data) => {
      if(data.messages !== ''){
        const newMessages = [];
        Object.entries(data.messages).forEach(([query, answer]) => {
          newMessages.push({ text: query, sender: 'user' });
          newMessages.push({ text: answer[query], sender: 'chat' });
        });
        setMessages(newMessages);
        setIsLoading(false);
      } 
    })
  };

  const handleCopyToClipboard = (index) => {
    const message = messages[index];
    navigator.clipboard.writeText(message.text).then(() => {
      const updatedMessages = messages.map((msg, idx) => ({
        ...msg,
        copyFeedback: idx === index ? 'Copied!' : '',
      }));
      setMessages(updatedMessages);
      setTimeout(() => {
        const resetMessages = updatedMessages.map((msg) => ({
          ...msg,
          copyFeedback: '',
        }));
        setMessages(resetMessages);
      }, 2000);
    });
  }
  const handleKeepMessages = () => {
    setIsOpen(!isOpen)
    setDialogOpen(false);
  };
  const toggleMaximize = () => {
    if(isMinimized){
      setIsMaximized(!isMaximized);
      setIsMinimized(!isMinimized);
    } else {
    setIsMaximized(!isMaximized);
    setIsMinimized(!isMinimized);
    }
  };
  const getSessionId1 = () => {
    let sessionId1 = localStorage.getItem('sessionId1');
    if (!sessionId1) {
      localStorage.setItem('sessionId1', '');
    }
    return sessionId1;
  };
  const getSessionId2 = () => {
    let sessionId2 = localStorage.getItem('sessionId2');
    if (!sessionId2) {
      localStorage.setItem('sessionId2', '');
    }
    return sessionId2;
  };
  const session_id1 = getSessionId1();
  const session_id2 = getSessionId2();
  useEffect(() => {
    if (!isOpen) { 
        const icon = document.querySelector('.chat-toggle-icon');
        if (icon) {
            icon.classList.add('animate-icon');
        }
        const timer = setTimeout(() => {
            if (icon) {
                icon.classList.remove('animate-icon');
            }
        }, 5000);
        return () => clearTimeout(timer);
    }
  }, [isOpen]);
  const handleSendMessage = () => {
    if (inputMessage.trim() !== '') {
      if (messages.length === 1){
        messages.splice(0,1)
      }
      setMessages([...messages, { text: inputMessage, sender: 'user' }]);
      setInputMessage('');
      setIsLoading(true);
      let payload = { message: inputMessage , session_id1:session_id1, session_id2:session_id2}
      SDCloudBackendAPIWithToken()
      .post('trigger_chatbot/',payload)
      .then((resp) => resp.data)
      .then((data) => {
        const newMessages = [];
        Object.entries(data.messages).forEach(([query, answer]) => {
          newMessages.push({ text: query, sender: 'user' });
          newMessages.push({ text: answer[query], sender: 'chat', copyFeedback:'' });
        });
        setMessages([]);
        setMessages(prevMessages => [...prevMessages, ...newMessages]);
        localStorage.setItem('sessionId1', data.session_id1);
        localStorage.setItem('sessionId2', data.session_id2);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        let message = err.response.data.messages;
        setMessages(prevMessages => [
          ...prevMessages,
          { text: message, sender: 'chat' }  
        ]);
      });
    }
  };
  return (
    <div>
            {!isOpen ? (
          // {!isOpen && isInternal && !admin && (
          <SvgComponent
                  className="chat-toggle-icon"
                  type="fill"
                  svgSource={chaticon}
                  onClick={toggleChat}
          />
          ) : (
            <SvgComponent
                  className="chat-icon-toogle"
                  type="fill"
                  svgSource={arrowicon}
                  onClick={toggleChat}
          />
      )}
      {isOpen && (
         <div className={`chat-widget ${isMinimized ? 'minimized' : 'maximized'}`}>
      <div className="chat-header">
        <span className="chat-title"><img src={criterion} alt="Logo" className="logo-image" />AI Assistant</span>
        <span>
              <IconButton onClick={toggleMaximize} style={{ color: '#FFFFFF' }}>
                {isMinimized ? <MaximizeIcon /> : <MinimizeIcon />}
              </IconButton>
              <IconButton onClick={toggleClose} style={{ color: '#FFFFFF',paddingLeft: '0px'}}><CloseIcon /></IconButton>
        </span>
      </div>
      <div className="chat-container">
      <div className="chat-messages">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`message ${message.sender === 'user' ? 'user' : 'bot'}`}
          >
            
            {message.text.split('\n').map((line, idx) => (
        <React.Fragment key={idx}>
          {line}
          <br />
        </React.Fragment>
            ))} 
                {message.sender !== 'user' && messages.length > 1 && (
                  <>
                    <IconButton 
                      style={{float:'right',bottom:'0',marginRight:'-8px',position:'relative',padding:'2px'}}
                      className='copy-button'
                      onClick={() => handleCopyToClipboard(index)}
                    >
                      <CopyIcon />
                    </IconButton>
                    {message.copyFeedback && (
                      <div className="copy-feedback">
                        {message.copyFeedback}
                      </div>
                    )}
                  </>
                )}
          </div>
        ))}
        {isLoading && (
              <div className='loader-container'>
                <LoaderHoc isLoading={isLoading}></LoaderHoc>
              </div>
            )}
      </div>
      </div>
      <div className="chat-input">
        <TextField
          variant="filled"
          placeholder="Type your message here"
          value={inputMessage}
          fullWidth
          onChange={handleInputChange}
          onKeyPress={(event) => {
            if (event.key === 'Enter') handleSendMessage();
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  type="submit"
                  onClick={handleSendMessage}
                  style={{ color: '#F96B13',marginRight:'-20px', cursor:'pointer'}}
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </div>
      )}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        disableBackdropClick
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={`custom-dialog ${isMinimized ? 'minimized' : 'maximized'}`}
        PaperProps={{ className: `custom-dialog ${isMinimized ? 'minimized' : 'maximized'}`}}
      >
        <DialogTitle id="alert-dialog-title" style={{textAlign:'center'}}>{"Clear conversation"}</DialogTitle>
        <DialogContent style={{fontWeight:'normal'}}>
          All messages in the chat will be cleared. Would you like to clear this conversation?
        </DialogContent>
        <DialogActions>
        <div className='dialog-actions'>
          <span className='cancel-button'><Button onClick={handleKeepMessages}>
            Cancel
          </Button></span>
          <span className='clear-button'><Button onClick={handleClearMessages}>
            Clear
          </Button></span>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Chatbot;