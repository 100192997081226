// This component creates a modal for Reserved IP with respective form fields, the
// modal is popped up during provision or resuming of a lab or poc.

import React, { Fragment, useState, useEffect } from "react";
import { SECONDARY_COLOR } from "global_constants/app_constants";
import Button from "@material-ui/core/Button";
// Formik
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { useFormik } from "formik";
import Datetime from "react-datetime";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Api, Urls } from "config/api";
import moment from "moment-timezone";
import Loader from "react-loader-spinner";
import { LOADER_TYPE } from "global_constants/app_constants";
import "views/ServiceOffering/LearningLab/acess labs/Overview.scss";
import NotificationAlert from "views/Components/NotificationAlert";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import Axios from "axios";
import ConfirmationModal from "../../../views/Design/Components/ConfirmationDelete";
import theme from "theme";
import { Alert } from "@material-ui/lab";
import { TimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import DateFnsUtils from "@date-io/date-fns";
import LuxonUtils from "@date-io/luxon";
import { KeyboardTimePicker } from "@material-ui/pickers";
import { KeyboardDatePicker } from "@material-ui/pickers";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
const inputProps = {
  disableAnimation: true,
  shrink: false
};

const formControlProps = {
  root: {
    marginTop: "10px"
  }
};

const customStyles = theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3)
  },
  modalCloseIcon: {
    position: "absolute",
    marginTop: "-83px",
    marginLeft: "45px",
    cursor: "pointer",
    color: "#FFFFFF",
    fontSize: "2rem"
  },
  datetime: {
    color: SECONDARY_COLOR
  },
  multilineColor: {
    color: "#7D7D7D"
  },
  form: {
    width: "100%"
  },
  btnColor: {
    background: theme.palette.buttonColor.main,
    "&:hover": {
      background: theme.palette.buttonColor.main
    }
  },
  font: {
    fontFamily: `${theme.palette.fontList.selectedFont} !important`
  },
  purposeDropdown: {
    width: "100%",
    marginTop: "5px"
  },
  zoneDropdown: {
    width: "100%",
    marginTop: "5px"
  },
  ipAddressDropdown: {
    width: "100%",
    marginTop: "5px"
  },
  helperText: {
    margin: "10px 0",
    color: "#7D7D7D"
  },
  helperCustom: {
    color: "#7D7D7D",
    margin: "10px 0 1px"
  }
});

const ScheduleLabModal = props => {
  const useStyles = makeStyles(customStyles);
  const classes = useStyles();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [confirmModal, setConfirmModal] = useState(false);
  const [inputData, setInputData] = useState({});
  const [startDate, setstartDate] = useState(null);
  const [startTime, setstartTime] = useState(null);
  const [Time_run, settime_run] = useState(true);
  const [error, setError] = React.useState(false);
  const [ipItems, setIpItems] = useState([]);
  const [actionValue, setActionValue] = React.useState("terminate");
  const [purpose, setPurpose] = useState(null);
  const [zone, setZone] = useState('North_America');
  const [menuItemsVals1, setMenuItemsVals1] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [menuItemsVals2, setMenuItemsVals2] = useState([]);
  let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
  let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
  let hasDomain = domain?.some(res => userEmail.includes(res));
  const [isInternal, setIsInternal] = useState(hasDomain);
  const env = localStorage.getItem("ENVIRONMENT");
  const hideModal = () => {
    props.hideModal();
  };
  const handleChange=(event) => {
    setZone(event.target.value)
    setShowNotification(false);
    if(event.target.value !== 'North_America'){
      setShowNotification(true);
      setNotificationType("warning");
      setNotificationMessage("By default, the lowest cost zone is set. If you switch, it will cost more.");
    }
  }
  const handleActionChange = event => {
    setActionValue(event.target.value);
    setError(false);
  };
  const setConfirmModalClose = () => {
    setConfirmModal(false);
    setBtnDisabled(false);
  };
  useEffect(() => {
    let available_purposes = JSON.parse(
      localStorage.getItem("available_purposes") 
    );
    if (isInternal) {
      if (available_purposes?.internal) {
        let purposes = available_purposes.internal.map(item => {
          return <option value={item}>{item}</option>;
        });
        setMenuItems([<option value={null}>None</option>, ...purposes]);
      }
      var deal_id = JSON.parse(localStorage.getItem("deal_ids"));
      let deals = deal_id.map(item => {
        return <option value={item}>{item}</option>;
      });
      setMenuItemsVals2([<option value={null}>None</option>, ...deals]);
    } else {
      if (available_purposes?.external) {
        let purposes = available_purposes.external.map(item => {
          return <option value={item}>{item}</option>;
        });
        setMenuItems([<option value={null}>None</option>, ...purposes]);
      }
    }
    let available_zone = JSON.parse(
      localStorage.getItem("available_zones")
    );
    let available_zones=Object.keys(available_zone)
    if (available_zones) {
      let zones = available_zones.map(item => {
        return <option value={item}>{item}</option>;
      }); 
      setMenuItemsVals1([...zones]);
    }
    if (props.labScheduleData.reservedIp?.hasOwnProperty("reservedIps")) {
      let ipAddresses = props.labScheduleData.reservedIp.reservedIps.map(
        item => {
          return <option value={item}>{item}</option>;
        }
      );
      setIpItems([<option value={null}>None</option>, ...ipAddresses]);
    }
  }, []);

  const ConfirmModalSubmit = () => {
    setConfirmModal(false);
    Axios.post(
      Api.delScheduleCluster,
      { uuid: props.uuid },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("user")}`
        }
      }
    )
      .then(() => {
        // required datetime in format '%Y-%m-%d %H:%M'
        const formattedDateTime = `${moment(
          inputData.startDate + " " + inputData.startTime
        )
          .tz(moment.tz.guess())
          .utc()
          .format("YYYY-MM-DD HH:mm")}`;
        let instance_type;
        if (inputData.instance == "standard") {
          instance_type = "standard";
        } else {
          instance_type = "spot";
        }
        let zoneValue = ''
        if(isInternal){
          zoneValue = ''
        } else {
          zoneValue = zone
        }
        const data = {
          // resource_type: String(labScheduleData.resourceType).toLowerCase(),
          resource_type:
            props.offeringType === "learning lab"
              ? "learning"
              : props.offeringType === "sales demo"
              ? "sales_demo"
              : "poc",
          identifier: props.labScheduleData.identifier,
          url: `${Api.baseURL}/`,
          start_time: formattedDateTime,
          purpose: purpose,
          input_action:"schedule",
          // zone:zoneValue,
          instance_type:instance_type,
        };
        //console.log(data,"dataaaaaaaaaaa",purpose)
        SDCloudBackendAPIWithToken()
          .post(Api.scheduleCluster, data)
          .then(res => {
            props.refetch();
            setShowNotification(true);
            setNotificationMessage(res.data.message);
            setNotificationType("success");
            setBtnDisabled(false);
            // hideModal();
          })
          .catch(err => {
            setShowNotification(true);
            setNotificationMessage(err.response.data.message);
            setNotificationType("error");
            setBtnDisabled(false);
          });
      })
      .catch(err => {
        setShowNotification(true);
        setNotificationMessage(err.response.data.message);
        setNotificationType("error");
        setBtnDisabled(false);
        //console.log(err.response);
      });
  };
  let name = props.labScheduleData.fullTitle;
  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      startDate:
        props?.initialValues?.run_time === null
          ? null
          : moment(props?.initialValues?.run_time).format("MM/DD/YYYY"),
      startTime:
        props?.initialValues?.run_time === null
          ? null
          : moment(props?.initialValues?.run_time).format("HH:mm"),
      name: name
        ? name
        : props?.labScheduleData?.runTime?.labData?.display_name,
      purpose: "",
      // zone:"",
      endTime:
        props?.labScheduleData?.runTime?.labData?.labhours ||
        props?.labScheduleData?.labhours,
      action: "terminate",
      ip_type: "default",
      ip_address: "",
      deal_id: "",
      instance:"standard"
    },
    onSubmit: values => {
      setShowNotification(false);
      setBtnDisabled(true);

      if (props.mode && props.mode == "Edit") {
        setConfirmModal(true);
        setInputData(values);
      } else {
        // required datetime in format '%Y-%m-%d %H:%M'
        const formattedDateTime = `${moment(
          values.startDate + " " + values.startTime
        )
          .tz(moment.tz.guess())
          .utc()
          .format("YYYY-MM-DD HH:mm")}`;
          let zoneValue = ''
          if(isInternal){
            zoneValue = ''
          } else {
            zoneValue = zone
          }
        const data = {
          // resource_type: String(labScheduleData.resourceType).toLowerCase(),
          resource_type:
            props.offeringType === "learning lab" ? "learning" : "sales_demo",
          identifier: props.labScheduleData.identifier,
          url: `${Api.baseURL}/`,
          start_time: formattedDateTime,
          auto_terminate: "False",
          purpose: values.purpose,
          // zone:zoneValue,
          instance_type:values.instance,
          input_action: "schedule",
        };
        if (values.ip_type == "reserved" && values.ip_address != "") {
          data["reserved_ip"] = true;
          data["ip_address"] = values.ip_address;
        }
        if (values.purpose == "Mint") {
          data["deal_id"] = values.deal_id;
        }
        SDCloudBackendAPIWithToken()
          .post(Api.scheduleCluster, data)
          .then(res => {
            setShowNotification(true);
            setNotificationMessage(res.data.message);
            setNotificationType("success");
            setBtnDisabled(false);
            // hideModal();
          })
          .catch(err => {
            setShowNotification(true);
            setNotificationMessage(err.response.data.message);
            setNotificationType("error");
            setBtnDisabled(false);
          });
      }
    },
    validate: values => {
      let errors = {};

      if (!values.startDate) {
        errors.startDate = "Required";
      }
      if (!values.startTime) {
        errors.startTime = "Required";
      }

      if (values.ip_type == "reserved" && values.ip_address == "") {
        errors.ip_address = "Required";
      }

      if ((values.purpose == "" || values.purpose == "None") && isInternal) {
        errors.purpose = "Required";
      } else if (values.purpose == "" && !isInternal) {
        values.purpose = JSON.parse(
          localStorage.getItem("available_purposes")
        ).external[0];
      }
      if (values.purpose == "Mint" && (values.deal_id=="" || values.deal_id == "None")){
        errors.deal_id = "Required";
      }
      // if (values.zone == "" || values.zone == "None"){
      //   errors.zone = "Required";
      // } 
      return errors;
    }
  });

  //  useEffect(() => {
  //   if (props.mode && props.mode == "Edit") {
  //     setstartDate(props.initialValues.startDate)
  //     setstartTime(props.initialValues.startTime)
  //   }
  //   }, [])

  // if (props.mode && props.mode == "Edit") {
  //   formik.initialValues = props.initialValues
  // }

  return (
    // calendar modal for schedule lab
    <Modal
      // disablePortal
      // disableEnforceFocus
      disableAutoFocus
      open={!props.openModal}
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      className={classes.modal}
      onClose={() => hideModal()}
      // container={() => rootRef.current}
      disableBackdropClick
    >
      <GridContainer
        justify="center"
        alignItems="center"
        className={classes.paper}
      >
        <GridItem xs={12} sm={12} md={12} lg={12}>
          {/* <h3>
            {props.labScheduleData.fullTitle
              ? `Schedule  ${props.labScheduleData.fullTitle}`
              : `Schedule ${props.offeringType}`}
          </h3> */}
          {props.offeringType === "poc" ? (
            <div className={`modal-title ${classes.font}`}>
              {"Schedule Sandbox"}
            </div>
          ) : props.page === "SalesDemo" ? (
            <div className={`modal-title ${classes.font}`}>
              {"Schedule Showcases"}
            </div>
          ) : props.offeringType === "learning lab" ? (
            <div className={`modal-title ${classes.font}`}>
              {"Schedule Learning"}
            </div>
          ) : (
            <div
              className={`modal-title ${classes.font}`}
            >{`Schedule ${props.offeringType}`}</div>
          )}
        </GridItem>
        <GridContainer justify="flex-end">
          <GridItem xs={12} sm={1} md={1} lg={1}>
            {" "}
            <CloseIcon
              className={classes.modalCloseIcon}
              onClick={() => hideModal()}
            />
          </GridItem>{" "}
        </GridContainer>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.helperCustom}>
                  Name
                </Typography>
                <TextField
                  type="text"
                  variant="filled"
                  // label="Name"
                  name="name"
                  disabled={true}
                  title="This field is not editable"
                  fullWidth
                  inputProps={{
                    className: classes.multilineColor
                  }}
                  InputProps={{ disableUnderline: true }}
                  InputLabelProps={inputProps}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
              {isInternal ? (
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.helperCustom}>
                    Purpose
                  </Typography>
                  {
                    <FormControl
                      variant="filled"
                      className={(classes.formControl, classes.purposeDropdown)}
                    >
                      {formik.errors.purpose && formik.touched.purpose ? (
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          fullWidth
                          name="purpose"
                          value={formik.values.purpose}
                          onChange={formik.handleChange}
                          native
                          error
                          helperText={formik.errors.purpose}
                          className={classes.purposeDropdown}
                        >
                          {menuItems.length > 0 ? (
                            menuItems
                          ) : (
                            <MenuItem value="">None</MenuItem>
                          )}
                        </Select>
                      ) : (
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          fullWidth
                          name="purpose"
                          value={formik.values.purpose}
                          onChange={event => {
                            setPurpose(event.target.value);
                            formik.setFieldValue(
                              "purpose",
                              event.target.value
                            );
                          }}
                          native
                          className={classes.purposeDropdown}
                        >
                          {menuItems.length > 0 ? (
                            menuItems
                          ) : (
                            <MenuItem value="">None</MenuItem>
                          )}
                        </Select>
                      )}
                    </FormControl>
                  }
                </Grid>
              ) : null}
              {isInternal && formik.values.purpose == "Mint" ? (
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.helperCustom}>
                    Select Deal id
                  </Typography>
                  {
                    <FormControl
                      variant="filled"
                      className={(classes.formControl, classes.purposeDropdown)}
                    >
                      {formik.errors.deal_id && formik.touched.deal_id ? (
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          fullWidth
                          name="deal_id"
                          value={formik.values.deal_id}
                          onChange={formik.handleChange}
                          native
                          error
                          helperText={formik.errors.deal_id}
                          className={classes.purposeDropdown}
                        >
                          {menuItemsVals2.length > 0 ? (
                            menuItemsVals2
                          ) : (
                            <MenuItem value="">None</MenuItem>
                          )}
                        </Select>
                      ) : (
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          fullWidth
                          name="deal_id"
                          value={formik.values.deal_id}
                          onChange={formik.handleChange}
                          native
                          className={classes.purposeDropdown}
                        >
                          {menuItemsVals2.length > 0 ? (
                            menuItemsVals2
                          ) : (
                            <MenuItem value="">None</MenuItem>
                          )}
                        </Select>
                      )}
                    </FormControl>
                  }
                </Grid>
              ) : null}
              {/* {!isInternal ? (
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.helperCustom}>
                    Zone
                  </Typography>
                  {
                    <FormControl
                      variant="filled"
                      className={
                        (classes.formControl,
                        classes.zoneDropdown)
                      }
                    >
                        <Select
                          labelId="demo-simple-select-filled-label"
                          id="demo-simple-select-filled"
                          fullWidth
                          name="zone"
                          value={zone}
                          onChange={handleChange}
                          native
                          className={classes.zoneDropdown}
                        >
                          {menuItemsVals1.length > 0 ? (
                            menuItemsVals1
                          ) : (
                            <MenuItem value="">None</MenuItem>
                          )}
                        </Select>
                    </FormControl>
                  }
                </Grid>
              ) : null} */}
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.helperCustom}>
                  Number of Hours
                </Typography>
                {
                  <TextField
                    type="number"
                    // label="Number of Hours"
                    variant="filled"
                    name="endTime"
                    disabled={true}
                    title="This field is not editable"
                    fullWidth
                    InputLabelProps={inputProps}
                    inputProps={{
                      className: classes.multilineColor
                    }}
                    InputProps={{ disableUnderline: true }}
                    value={formik.values.endTime}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    // InputProps={{ disableUnderline: true }}
                  />
                }
              </Grid>
              {isInternal && env!=="production"?
              (<Grid item xs={12}>
                              <Typography
                                variant="body2"
                                className={classes.helperCustom}
                              >
                                Instance type
                              </Typography>

                              <FormControl component="fieldset">
                                <RadioGroup
                                  row
                                  aria-label="ip_type"
                                  name="instance"
                                  value={formik.values.instance}
                                  className={classes.radioButton}
                                  onChange={formik.handleChange}
                                >
                                  <FormControlLabel
                                    value="standard"
                                    control={<Radio color="primary" />}
                                    label="Standard"
                                  />
                                  <FormControlLabel
                                    value="spot"
                                    control={<Radio color="primary" />}
                                    label="Spot"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
              ) : null}
              <Grid item xs={6}>
                <Typography className={classes.helperCustom}>
                  End action
                </Typography>

                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="ip_type"
                    name="action"
                    value={formik.values.action}
                    className={classes.radioButton}
                    onChange={formik.handleChange}
                  >
                    <FormControlLabel
                      value="suspend"
                      disabled={true}
                      title="Suspend feature is not available for this product"
                      control={<Radio color="primary" />}
                      label="Suspend"
                    />
                    <FormControlLabel
                      value="terminate"
                      control={<Radio color="primary" />}
                      label="Terminate"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {props.labScheduleData.reservedIp?.reservedIps ? (
                <Grid item xs={4}>
                  <Typography className={classes.helperCustom}>
                    IP Address Type
                  </Typography>

                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="ip_type"
                      name="ip_type"
                      value={formik.values.ip_type}
                      className={classes.radioButton}
                      onChange={formik.handleChange}
                    >
                      <FormControlLabel
                        value="default"
                        control={<Radio color="primary" />}
                        label="Default"
                      />
                      {props.labScheduleData.reservedIp?.reservedIps ? (
                        <FormControlLabel
                          value="reserved"
                          control={<Radio color="primary" />}
                          label="Static"
                        />
                      ) : null}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              ) : null}

              <Grid item xs={12}>
                {formik.values.ip_type === "reserved" ? (
                  <FormControl
                    variant="filled"
                    className={(classes.formControl, classes.ipAddressDropdown)}
                  >
                    {formik.errors.ip_address && formik.touched.ip_address ? (
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        fullWidth
                        name="ip_address"
                        value={formik.values.ip_address}
                        onChange={formik.handleChange}
                        label="Select IP Address"
                        native
                        error
                        helperText={formik.errors.ip_address}
                        className={classes.ipAddressDropdown}
                      >
                        {ipItems.length > 0 ? (
                          ipItems
                        ) : (
                          <MenuItem value="">None</MenuItem>
                        )}
                      </Select>
                    ) : (
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        label="Select IP Address"
                        fullWidth
                        name="ip_address"
                        value={formik.values.ip_address}
                        onChange={formik.handleChange}
                        native
                        className={classes.ipAddressDropdown}
                      >
                        {ipItems.length > 0 ? (
                          ipItems
                        ) : (
                          <MenuItem value="">None</MenuItem>
                        )}
                      </Select>
                    )}
                  </FormControl>
                ) : null}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.helperCustom}>
                  The time is in local computer timezone.
                </Typography>
              </Grid>
              {formik.errors.startDate && formik.touched.startDate ? (
                /*<TextField
                type="date"
                variant="filled"
                label="Start Date"
                name="startDate"
                fullWidth
                InputLabelProps={inputProps}
                onChange={formik.handleChange}
                helperText={formik.errors.startDate}
                onBlur={formik.handleBlur}
                error
             />*/
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Fragment>
                    <Typography
                      variant="body2"
                      className={classes.helperCustom}
                    >
                      Start Date
                    </Typography>
                    <KeyboardDatePicker
                      inputVariant="filled"
                      // label="Start Date"
                      name="startDate"
                      format="MM/dd/yyyy"
                      // placeholder="MM/DD/YYYY"
                      fullWidth
                      //InputLabelProps={inputProps}
                      //onChange={formik.handleChange}
                      // value={startDate}
                      labelFunc={() => formik.values.startDate || "MM/DD/YYYY"}
                      onChange={val => {
                        setstartDate(moment(val).format("MM/DD/YYYY"));
                        formik.setFieldValue(
                          "startDate",
                          moment(val).format("MM/DD/YYYY")
                        );
                      }}
                      helperText={formik.errors.startDate}
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors.startDate && formik.touched.startDate
                          ? true
                          : false
                      }
                    />
                  </Fragment>
                </MuiPickersUtilsProvider>
              ) : props.mode == "Edit" ? (
                /*<TextField
                  type="date"
                  variant="filled"
                  label="Start Date"
                  name="startDate"
                  fullWidth
                  InputLabelProps={inputProps}
                  onChange={formik.handleChange}
                  defaultValue={props.initialValues.startDate}
                  value={formik.values.startDate ? formik.values.startDate : formik.initialValues.startDate}
                />*/

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Fragment>
                    <Typography
                      variant="body2"
                      className={classes.helperCustom}
                    >
                      Start Date
                    </Typography>
                    <KeyboardDatePicker
                      inputVariant="filled"
                      // label="Start Date"
                      name="startDate"
                      format="MM/dd/yyyy"
                      // placeholder="MM/DD/YYYY"
                      fullWidth
                      //InputLabelProps={inputProps}
                      // value={startDate}
                      //onChange={formik.handleChange}
                      defaultValue={formik.values.startDate || null}
                      // value={startDate}
                      labelFunc={() => formik.values.startDate || "MM/DD/YYYY"}
                      onChange={val => {
                        setstartDate(moment(val).format("MM/DD/YYYY"));
                        formik.setFieldValue(
                          "startDate",
                          moment(val).format("MM/DD/YYYY")
                        );
                      }}
                    />
                  </Fragment>
                </MuiPickersUtilsProvider>
              ) : (
                /*<TextField
                  type="date"
                  variant="filled"
                  label="Start Date"
                  name="startDate"
                  fullWidth
                  InputLabelProps={inputProps}
                  onChange={formik.handleChange}
                />*/
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Typography variant="body2" className={classes.helperCustom}>
                    Start Date
                  </Typography>
                  <Fragment>
                    <KeyboardDatePicker
                      inputVariant="filled"
                      // label="Start Date"
                      name="startDate"
                      format="MM/dd/yyyy"
                      // placeholder="MM/DD/YYYY"
                      fullWidth
                      InputLabelProps={inputProps}
                      // value={startDate}
                      labelFunc={() => formik.values.startDate || "MM/DD/YYYY"}
                      //onChange={formik.handleChange}
                      onChange={val => {
                        setstartDate(moment(val).format("MM/DD/YYYY"));
                        formik.setFieldValue(
                          "startDate",
                          moment(val).format("MM/DD/YYYY")
                        );
                      }}
                    />
                  </Fragment>
                </MuiPickersUtilsProvider>
              )}
              {formik.errors.startTime && formik.touched.startTime ? (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Fragment>
                    <Typography
                      variant="body2"
                      className={classes.helperCustom}
                    >
                      Start Time
                    </Typography>
                    <KeyboardTimePicker
                      fullWidth
                      ampm={false}
                      inputVariant="filled"
                      // label="Start Time"
                      // placeholder="HH:MM"
                      mask="__:__"
                      name="startTime"
                      // value={
                      //   formik.initialValues.run_time && Time_run === true
                      //     ? formik.initialValues.run_time
                      //     : startTime
                      // }
                      labelFunc={() =>
                        formik.initialValues.startTime && Time_run === true
                          ? formik.initialValues.startTime
                          : formik.values.startTime || "HH:MM"
                      }
                      onChange={val => {
                        setstartTime(moment(val).format("HH:mm"));
                        formik.setFieldValue(
                          "startTime",
                          moment(val).format("HH:mm")
                        );
                        settime_run(false);
                      }}
                      helperText={formik.errors.startTime}
                      onBlur={formik.handleBlur}
                      error={
                        formik.errors.startTime && formik.touched.startTime
                          ? true
                          : false
                      }
                      keyboardIcon={<AccessTimeIcon />}
                    />
                  </Fragment>
                </MuiPickersUtilsProvider>
              ) : // <TextField
              //   type="time"
              //   variant="filled"
              //   label="Start Time"
              //   name="startTime"
              //   fullWidth
              //   InputLabelProps={inputProps}
              //   formControlProps={formControlProps}
              //   onChange={formik.handleChange}
              //   helperText={formik.errors.startTime}
              //   onBlur={formik.handleBlur}
              //   error
              // />
              props.mode == "Edit" ? (
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Fragment>
                    <Typography
                      variant="body2"
                      className={classes.helperCustom}
                    >
                      Start Time
                    </Typography>
                    <KeyboardTimePicker
                      fullWidth
                      ampm={false}
                      inputVariant="filled"
                      // label="Start Time"
                      // placeholder="HH:MM"
                      mask="__:__"
                      name="startTime"
                      // value={
                      //   formik.initialValues.run_time && Time_run === true
                      //     ? formik.initialValues.run_time
                      //     : startTime
                      // }
                      labelFunc={() =>
                        formik.initialValues.startTime && Time_run === true
                          ? formik.initialValues.startTime
                          : formik.values.startTime || "HH:MM"
                      }
                      onChange={val => {
                        setstartTime(moment(val).format("HH:mm"));
                        formik.setFieldValue(
                          "startTime",
                          moment(val).format("HH:mm")
                        );
                        settime_run(false);
                      }}
                      keyboardIcon={<AccessTimeIcon />}
                    />
                  </Fragment>
                </MuiPickersUtilsProvider>
              ) : (
                // <TextField
                //   type="time"
                //   variant="filled"
                //   label="Start Time"
                //   name="startTime"
                //   fullWidth
                //   InputLabelProps={inputProps}
                //   formControlProps={formControlProps}
                //   onChange={formik.handleChange}
                //   value={formik.values.startTime ? formik.values.startTime : formik.initialValues.startTime}
                // />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Fragment>
                    <Typography
                      variant="body2"
                      className={classes.helperCustom}
                    >
                      Start Time
                    </Typography>
                    <KeyboardTimePicker
                      fullWidth
                      ampm={false}
                      inputVariant="filled"
                      // label="Start Time"
                      // placeholder="HH:MM"
                      mask="__:__"
                      name="startTime"
                      // value={startTime}
                      labelFunc={() => formik.values.startTime || "HH:MM"}
                      onChange={val => {
                        setstartTime(moment(val).format("HH:mm"));
                        formik.setFieldValue(
                          "startTime",
                          moment(val).format("HH:mm")
                        );
                      }}
                      keyboardIcon={<AccessTimeIcon />}
                    />
                  </Fragment>
                </MuiPickersUtilsProvider>
              )
              // <TextField
              //   type="time"
              //   variant="filled"
              //   label="Start Time"
              //   name="startTime"
              //   fullWidth
              //   InputLabelProps={inputProps}
              //   formControlProps={formControlProps}
              //   onChange={formik.handleChange}
              // />
              }
            </Grid>
            {!btnDisabled ? (
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                size="large"
                fullWidth
                disableFocusRipple
                type="submit"
                className={classes.btnColor}
              >
                Schedule
              </Button>
            ) : null}
            {btnDisabled ? (
              <Loader
                type={LOADER_TYPE}
                color={theme.palette.secondary.main}
                width={50}
                height={50}
                style={{ textAlign: "center" }}
              />
            ) : null}

            {/* <NotificationAlert
              notificationType={notificationType}
              showNotification={showNotification}
              message={notificationMessage}
            /> */}
          </GridItem>
        </form>
        {showNotification ? (
          <Alert
            onClose={() => setShowNotification(false)}
            severity={notificationType}
          >
            {notificationMessage}
          </Alert>
        ) : null}
        <ConfirmationModal
          visible={confirmModal}
          onClose={setConfirmModalClose}
          onConfirm={ConfirmModalSubmit}
          message="Are you sure want to reschedule?"
          font={classes.font}
          //selectedTemplate={selectedTemplates.length}
        />
      </GridContainer>
    </Modal>
  );
};

export default ScheduleLabModal;
