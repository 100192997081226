import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import { Api } from "config/api";
import React, { useState, useEffect,useRef} from 'react';
import { useDispatch } from "react-redux";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import ReactDiffViewer from 'react-diff-viewer';
import TextareaAutosize from 'react-textarea-autosize';
import {
  Step,
  StepLabel,
  Stepper,
  Button,
  Typography,
  withStyles,
  StepConnector,
  Grid, makeStyles
} from '@material-ui/core';
import { Dialog, Tooltip, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import { Alert, AlertTitle } from '@material-ui/lab';



const steps = ['Export/Import', 'Comparision', 'Import'];

const ErrorDialog = ({ open, handleClose, errorMessage }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Error</DialogTitle>
      <DialogContent>
        <DialogContentText>{errorMessage}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ConfirmDialog = ({ open, handleClose, handleConfirm, message }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{ textAlign: 'center' }}>Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText style={{ color: 'black' }}>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{
          background: 'rgb(249, 107, 19)',
          color: 'white'
        }}>
          Cancel
        </Button>
        <Button onClick={handleConfirm} style={{
          background: 'rgb(249, 107, 19)',
          color: 'white'
        }}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};





const useStyles = makeStyles((theme) => ({
  rootAlert: {
    width: '60%',
    '& > * + *': {
      marginTop: theme.spacing(2),
      float: 'left',
    },
  },
  editor: {
    marginBottom: theme.spacing(2),
  },
  preview: {
    height: 10

  },
  getDownload: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    height: 100,
    background: '#fff',
    alignItems: 'baseline',
    margin: '0px 74px',
    borderRadius: '3px',
  },
  getDownloadButton: {
    height: 31,
    marginTop: 32,
    marginRight: 100,
    background: '#F96B13',
    color: '#fff',
    border: 'none',
    borderRadius: 2,
    marginLeft: '-414px',
    fontFamily: 'Roboto',
    textTransform: 'capitalize',
    fontWeight: '500',
    lineHeight: '1.75',

  },

  customDropzone: {
    width: '80%',
    height: '300px',
    // backgroundColor: 'grey',
    border: '2px dashed grey',
    borderRadius: '8px',
    padding: '20px',
    boxSizing: 'border-box',
    marginLeft: '140px'
  },
  fileUpload: {
    width: '90%',
    background: 'white',
    borderRadius: '8px',
    padding: '20px',
    boxSizing: 'border-box',
    padding: '30px',
    marginLeft: '75px'
  },
  uploadButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px'
  },
  uploadTitle: {
    fontSize: '1.25rem',
    fontFamily: 'Roboto',
    fontWeight: 700,
    lineHeight: '1.334',
    letterSpacing: '0em',
    margin: '20px 20px'
  }

}));

const OrangeConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      borderColor: '#e57373',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#e57373',
    },
  },
  line: {
    borderColor: '#bdbdbd',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);


function ImportConfiguration() {
  const [activeStep, setActiveStep] = useState(0);
  const [device, setDevice] = useState('')
  const [fileContent, setFileContent] = useState('');
  const [currentConfig, setCurrentConfig] = useState('');
  const [nodeid, setNodeid] = useState('')
  const [isMultiVM, setMultiVM] = useState('')
  const [uuid, setUUID] = useState('')
  const [errorDialogOpen, setErrorDialogOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [select,setSelect] = useState(false)
  const [errorMessage,setErrorMessage] = useState('')
  const [importCall,setImportCall] = useState(false)
  const [alertType, setAlertType] = useState("");
  const [alertMsg, setAlertMsg] = useState("");
  const errorRef = useRef(null);
  

  const classes = useStyles();

  const left_title = "Current Configuration Data of Device"
  const right_title = "Modifying Configuration Data"
  const dispatch = useDispatch();

    // adding custom css styles react diff viewer component
    const newStyles = {
      variables: {
        dark: {
          highlightBackground: '#fefed5',
          highlightGutterBackground: '#ffcd3c',
        },
      },
      line: {
        padding: '10px 2px',
        transition: 'background-color 0.3s ease',
        '&:hover': {
          backgroundColor: '#e0e0e0',
        },
      },
      titleBlock: {
        background: '#e7cccc',
        textAlign: 'center',
        marginBottom: '20px',
  
      }
    };


  // initail value set up when this component mounted on browser
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const name = urlSearchParams.get('device');
    const node_name = urlSearchParams.get('node');
    const uuid = urlSearchParams.get('uuid');
    const ismulti = urlSearchParams.get('ismulti');
    dispatch(SET_NAVBAR_TITLE(`Import Configuration For Device ${name}`));
    setDevice(name)
    setNodeid(node_name)
    setUUID(uuid)
    setMultiVM(ismulti)

  }, []);


  // Function to handle error dialog close
  const handleErrorDialogClose = () => {
    setErrorDialogOpen(false);
  };

  // Function to handle confirm dialog close
  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  // Function to handle confirm button click
  const handleConfirmButtonClick = async () => {
    // Do something when the user confirms
    // ...
    if (fileContent) {
      let payload = {
        "device_name": nodeid,
        "hostname": device,
        "action": 'import',
        "template_uuid": uuid,
        "is_multivm": isMultiVM,
        "import_content": fileContent
      };


      try {
        const response = await SDCloudBackendAPIWithToken().post(
          Api.importDeviceConfig,
          payload
        );
        if (response.data.status_is == 200){
          showAlert('success', response.data?.msg ?? 'Configuration applied successfully');
          window.scrollTo(0, document.body.scrollHeight);
        
         }else{
          showAlert('error',response.data?.msg ?? 'Something went wrong!')
          window.scrollTo(0, document.body.scrollHeight);
         }
          
      } catch (error) {
        setImportCall(true)
      }

    }


    // Close the confirm dialog
    setConfirmDialogOpen(false);
  };

  const showAlert = (type, message) => {
    setAlertType(type);
    setAlertMsg(message);

    // Close the alert after 5 seconds
    setTimeout(() => {
      setAlertType('');
      setAlertMsg('');
    }, 5000);
  };
  const handleNext = async () => {
    // setConfirmDialogOpen(true)

    if (activeStep == 0) {
      if (!fileContent){
        setErrorMessage('Please select a config file')
        return
      }
      if (!currentConfig) {
        let payload = {
          "device_name": nodeid,
          "hostname": device,
          "action": 'export',
          "template_uuid": uuid,
          "is_multivm": isMultiVM
        };


        try {
          const response = await SDCloudBackendAPIWithToken().post(
            Api.ExportDeviceConfig,
            payload
          );
          if (response.status == 200){
            showAlert('success', response.data?.msg ?? 'Successfully received device configuration data.');
            window.scrollTo(0, document.body.scrollHeight);
          
           }else{
            showAlert('error',response.data?.msg ?? 'Something went wrong!')
            window.scrollTo(0, document.body.scrollHeight);
           }
          setCurrentConfig(response.data.download_info.content)
        } catch (error) {
          //console.log(error)
        }

      }
    }
    else if (activeStep == 2) {
      let val = setConfirmDialogOpen(true)
      return
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // download current config
  const DownloadCurrentConfig = async () => {
    let payload = {
      "device_name": nodeid,
      "hostname": device,
      "action": 'export',
      "template_uuid": uuid,
      "is_multivm": isMultiVM
    };
    //console.log(payload, "pload")

    try {
      const response = await SDCloudBackendAPIWithToken().post(
        Api.ExportDeviceConfig,
        payload
      );
      if (response.status == 200){
        showAlert('success', response.data?.msg ?? 'Configuration download successfully');
        window.scrollTo(0, document.body.scrollHeight);
      
       }else{
        showAlert('error',response.data?.msg ?? 'Something went wrong!')
        window.scrollTo(0, document.body.scrollHeight);
       }
      const responseData = response.data;

      const { download_info } = responseData;

      if (!download_info) {
        console.error('Download information not found in response');
        return;
      }

      const { zip_data, output_file_name, content } = download_info;

      if (!content) {
        console.error('Missing zip data or zip file name in response');
        return;
      }
      const blob = new Blob([content], { type: 'text/plain' });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = output_file_name || 'device.txt';
      document.body.appendChild(a);

      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      setCurrentConfig(content);
    } catch (error) {
      console.error('Failed to fetch or process data:', error);
    }

  }

  // filter config data
  // Function to compare configurations while preserving certain blocks
  const compareConfigurations = (oldConfig, newConfig) => {
    // Split configurations into lines
    const oldLines = oldConfig.split('\n');
    const newLines = newConfig.split('\n');

    // Array to store lines to be preserved
    const preservedLines = [];

    // Function to check if a line contains a certain block
    const containsBlock = (line, block) => {
      return line.trim().toLowerCase().startsWith(block.toLowerCase());
    };

    // Iterate over lines in old configuration
    for (let i = 0; i < oldLines.length; i++) {
      const line = oldLines[i];

      // Check if line contains any of the blocks to be preserved
      if (containsBlock(line, 'line') ||
        containsBlock(line, 'aaa') ||
        containsBlock(line, 'organization') ||
        containsBlock(line, 'crypto') ||
        containsBlock(line, 'management interface')) {
        preservedLines.push(line);
      }
    }

    // Iterate over lines in new configuration
    for (let i = 0; i < newLines.length; i++) {
      const line = newLines[i];

      // Check if line contains any of the blocks to be preserved
      if (containsBlock(line, 'line') ||
        containsBlock(line, 'aaa') ||
        containsBlock(line, 'organization') ||
        containsBlock(line, 'crypto') ||
        containsBlock(line, 'management interface')) {
        // If line is not already present in preservedLines, add it
        if (!preservedLines.includes(line)) {
          preservedLines.push(line);
        }
      }
    }

    // Join preserved lines into a single string
    const preservedConfig = preservedLines.join('\n');

    return preservedConfig;
  };

  return (
    <>
      <Grid>
        {/* this block for stepper */}
        <Grid item xs={12} md={12} style={{ marginBottom: "30px" }}>

          <Stepper alternativeLabel activeStep={activeStep} connector={<OrangeConnector />}>
            {steps.map((label, index) => (
              <Step key={label} completed={index < activeStep}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
        {/* this block of code for export device config */}
        {activeStep == 0 && (<Grid item xs={12} md={12} style={{ marginBottom: "30px" }}>
          <Grid className={classes.getDownload}>
            <Grid>
              <Typography >
                Download the device current configuration data, Before import your configuration
              </Typography>
            </Grid>
            <Grid >
              <Tooltip title='download'>
                <button className={classes.getDownloadButton} onClick={DownloadCurrentConfig}> Download Current Config </button>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>)}
        {/* this block of code for step 1 */}
        {activeStep === 0 && (
          <Grid className={classes.fileUpload}>
            <Grid className={classes.uploadTitle}>
              <Typography variant="h5" style={{ textAlign: 'center' }}>{`Upload ${device} Config File `}</Typography>
            </Grid>
            <DropzoneArea
              dropzoneClass={classes.customDropzone}
              previewGridClasses={{
                item: classes.preview,
              }}
              showFileNames={true}
              showFileNamesInPreview={true}
              acceptedFiles={['.txt']}
              dropzoneText={"Drag and drop File here "}
              onChange={(files) => {
                setErrorMessage('')
                const selectedFile = files[0];
                setSelect("true")

                if (selectedFile) {
                  const reader = new FileReader();
                  reader.onload = (event) => {
                    const content = event.target.result;
                    setFileContent(content);
                  };

                  reader.readAsText(selectedFile);
                }
              }
              }
              style={{ width: '80%', background: "grey", innerHeight: '80%' }}
              onDropRejected={(files) => {
                alert('Only text files are allowed');
              }}
            ></DropzoneArea>
            {errorMessage && (
                <Typography style={{textAlign:'center',
                  position: 'relative',
                  top: '-70px',
                  fontSize: 23}} color="error" variant="body2">{errorMessage}</Typography>
            )}

          </Grid>
        )}
        {/* this block of code for step 2 */}
        {activeStep == 1 && (
          <div style={{ height: 660, overflowY: 'auto' }}>
            <Grid>

              <ReactDiffViewer oldValue={currentConfig} newValue={fileContent}
                splitView={true} leftTitle={left_title} rightTitle={right_title}
                useDarkTheme={false} styles={newStyles}
              />
            </Grid>
          </div>
        )}
        {activeStep == 2 && (
          <div style={{ height: 600, overflowY: 'auto' }}>
            <TextareaAutosize
              value={fileContent}
              readOnly
              style={{
                width: '100%', minHeight: 200, cursor: 'not-allowed',
                border: '2px solid #ccc', boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)', padding: '20px', backgroundColor: 'rgb(84 64 64 / 20%)', minHeight: 200, fontSize: '14px',
              }}
            />


          </div>
        )}
        {/* {importCall &&(
          <Grid>

          <Typography variant="h6" gutterBottom>
              Result: 
          </Typography>
          <Typography variant="body1" gutterBottom>
              Response:
          </Typography>
          <pre>
              
          </pre>
      </Grid>
        )} */}
        {/* this block for next and back button */}
        <Grid>
          <Grid style={{ marginLeft: 60 }}>
            <Button disabled={activeStep === 0} onClick={handleBack}>
              Back
            </Button>
            <Button variant="contained" style={{ background: '#F96B13', color: 'white' }} onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Apply' : 'Next'}
            </Button>

          </Grid>
        </Grid>
        <Grid>
          <ErrorDialog open={errorDialogOpen} handleClose={handleErrorDialogClose} errorMessage="An error occurred!" />

          {/* Use the Confirm Dialog */}
          <ConfirmDialog
            open={confirmDialogOpen}
            handleClose={handleConfirmDialogClose}
            handleConfirm={handleConfirmButtonClick}
            message={`Are you sure you want to Apply your configuration for ${device}?`}
          />
        </Grid>
      </Grid>
      <div className={classes.rootAlert} ref={errorRef}>
      {alertType == 'error' && <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {alertMsg} 
      </Alert>}

      {alertType == 'success' && <Alert severity="success" style={{float:'right'}}>
        <AlertTitle>Success</AlertTitle>
        {alertMsg}. Verify new changes after few minutes
      </Alert>}
    </div>
    </>
  );
}

export default ImportConfiguration;