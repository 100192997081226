// React specific library imports
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { Api } from "config/api";
import { withRouter } from "react-router-dom";
import Loader from "react-loader-spinner";

// @material-ui/core components
import Delete from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Grid } from "@material-ui/core";

// core components
import { Card } from "@material-ui/core";
import CardHeader from "components/Card/CardHeader";
import CardFooter from "components/Card/CardFooter";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { PRIMARY_COLOR } from "global_constants/app_constants";
import CardBody from "components/Card/CardBody";
import {
  USER_FORM_FIELDS,
  USER_FORM_LABELS
} from "../utils/constants/constants_profile";
import SvgtoIcon from "utils/SvgtoIcon";
import TrustedDomainTable from "views/MyProfile/TrustedDomains/components/TrustedDomainTable";
import AddDomainModal from "views/MyProfile/TrustedDomains/components/AddDomainModal";
import DeleteDomainModal from "views/MyProfile/TrustedDomains/components/DeleteDomainModal";
import { ReactComponent as deleteSvg } from "assets/criterion_networks/svg/delete.svg";
import "../Profile.scss";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import theme from "../../../theme";
const COLS = ["name"];

const customStyles = theme => ({
  containerStyles: {
    padding: "0.9375rem 70px"
  },
  inputStyles: {
    margin: "10px auto 20px auto",
    [theme.breakpoints.down("sm")]: {
      margin: "15px"
    }
  },
  inputLabelStyles: {
    marginBottom: "35px",
    position: "relative"
  },
  cardStyles: {
    color: "rgba(0, 0, 0, 0.87)",
    width: "100%",
    border: "0",
    display: "flex",
    position: "relative",
    fontSize: ".875rem",
    minWidth: "0",
    wordWrap: "break-word",
    background: "#FFF",
    marginTop: "30px",
    borderRadius: "6px",
    marginBottom: "30px",
    flexDirection: "column"
  },
  cardHeaderStyles: {
    padding: "0.9375rem 65px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative",
    "& h3": {
      fontFamily: theme.palette.fontList.selectedFont
    }
  },
  cardBodyStyles: {
    padding: "0.9375rem 65px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative"
  },
  cardFooterStyles: {
    padding: "0.9375rem 65px",
    margin: "35px 0 35px 0",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative"
  },
  iconButtonLabel: {
    fontSize: "14px"
  },
  tableHeaderStyles: {
    borderBottom: "1px solid black"
  },
  btnStyle:{
    background: `${theme.palette.buttonColor.main} !important`,
  }
});

const TABLE_HEADERS = [
  {
    Header: "Domains",
    accessor: "domain",
    headerClassName: "domainHeaderStyles",
    Cell: row => (
      <div>
        {row.original.domain}
        <p style={{ color: "#8F8A8A", marginTop: "-3px" }}>
          <small>Modified by me 16/01/2021.</small>
        </p>
      </div>
    )
  },
  {
    Header: "Action",
    accessor: "action",
    sortable: false,
    filterable: false,
    headerClassName: "domainHeaderStyles"
  }
];

class TrustedDomain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataTable: [],
      hideModal: true,
      hideConfirmationModal: true,
      delDomainName: null,
      isTableLoaded: false
    };
  }

  hideModal = () => {
    this.setState({
      hideModal: true
    });
  };
  showModal = () => {
    this.setState({
      hideModal: false
    });
  };

  hideConfirmationModal = () => {
    this.setState({
      hideConfirmationModal: true
    });
  };
  showConfirmationModal = domainName => {
    this.setState({
      delDomainName: domainName,
      hideConfirmationModal: false
    });
  };

  build1DArray = data => {
    let _1Darr = [];
    _1Darr = [...data];
    return _1Darr;
  };

  buildTable = data => {
    const tableFormat = {
      headerRow: COLS,
      footerRow: COLS,
      dataRows: data.apiData
    };
    const { classes } = this.props;
    return tableFormat.dataRows.map((prop, key) => {
      return {
        id: key + 1,
        domain: prop,
        action: (
          // use this button to remove the data row
          <Grid container justify="flex-end" alignItems="flex-end">
            <IconButton
              color="primary"
              disableElevation
              disableFocusRipple
              disableRipple
              onClick={() => {
                this.showConfirmationModal(prop, key);
              }}
              classes={{ label: classes.iconButtonLabel }}
              className="domainIconStyles"
            >
              <Grid container>
                <Grid item xs={12}>
                  <SvgtoIcon svgSource={deleteSvg} />
                </Grid>
              </Grid>
            </IconButton>
            {/* <Button
              simple
              onClick={() => {
                this.handleDeleteImage(prop, key);
              }}
              className="domainIconStyles"
              key={key}
            >
              <Edit className="Edit" /> Edit
            </Button>{" "} */}
          </Grid>
        )
      };
    });
  };

  fetchTrustedDomains = () => {
    SDCloudBackendAPIWithToken()
      .get(Api.getAccountDetails)
      .then(res => {
        const finaltable = this.buildTable({
          apiData: this.build1DArray(res.data["trusted_domains"])
        });
        this.setState({ dataTable: finaltable, isTableLoaded: true });
      })
      .catch(err => {
        //console.log(err);
      });
  };

  handleSubmitForm = () => {
    const formFieldskeys = Object.keys(USER_FORM_FIELDS);

    // filtering only required formfields from state and building formObj
    const formObj = Object.keys(this.state).reduce((accum, val) => {
      if (formFieldskeys.includes(val)) {
        accum[val] = this.state[val];
        return accum;
      }
      return accum;
    }, {});
  };

  componentDidMount() {
    // this.props.dispatch(SHOW_LOADER());
    this.fetchTrustedDomains();
  }

  render() {
    const { classes } = this.props;
    if (this.state.isTableLoaded) {
      return (
        <div>
          <GridContainer className={classes.containerStyles}>
            <Card className={classes.cardStyles} elevation={0}>
              <CardHeader className={classes.cardHeaderStyles}>
                <div>
                  <h3 style={{ fontSize: "24px" }}>
                    <b>Trusted domains</b>
                  </h3>
                </div>
              </CardHeader>

              <CardBody className={classes.cardBodyStyles}>
                <TrustedDomainTable
                  key={this.state.dataTable}
                  domainList={this.state.dataTable}
                />
                {/* <ReactTable
              data={this.state.dataTable}
              columns={TABLE_HEADERS}
              showPaginationBottom={false}
              // className="-striped -highlight"
              // tableHeaderColor="warning"
              defaultPageSize={1}
            /> */}
                <Button
                  variant="contained"
                  color="secondary"
                  disableElevation
                  size="large"
                  // fullWidth
                  className={classes.btnStyle}
                  disableFocusRipple
                  type="submit"
                  onClick={() => this.showModal()}
                >
                  Add new trusted domain
                </Button>
                {this.state.hideModal ? null : (
                  <AddDomainModal
                    openModal={this.state.hideModal}
                    hideModal={this.hideModal}
                    fetchTrustedDomains={this.fetchTrustedDomains}
                  />
                )}
                {this.state.hideConfirmationModal ? null : (
                  <DeleteDomainModal
                    domainName={this.state.delDomainName}
                    openModal={this.state.hideConfirmationModal}
                    hideModal={this.hideConfirmationModal}
                    fetchTrustedDomains={this.fetchTrustedDomains}
                  />
                )}
              </CardBody>
            </Card>
          </GridContainer>
        </div>
      );
    } else {
      return (
        <div>
          <GridContainer justify="center">
            <Loader
              type="ThreeDots"
              color={theme.palette.secondary.main}
              height={500}
              width={50}
              visible={true}
            />
          </GridContainer>
        </div>
      );
    }
  }
}

TrustedDomain.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(withStyles(customStyles)(TrustedDomain));