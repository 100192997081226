import * as actions from "../actions/action";
export const SAVE_TOKEN = ({ payload }) => {
  return {
    type: actions.SAVE_TOKEN,
    data: payload.token || null
  };
};
// below action creater returns function to reducer which
// is intercepted in between by thunk.
export const LOGOUT = history => dispatch => {
  // const history = useHistory();
  localStorage.removeItem("user");
  localStorage.removeItem("userName");
  localStorage.removeItem("profileData");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("BASE_URL");
  localStorage.removeItem("design_objects");
  localStorage.removeItem("client_details");
  localStorage.removeItem("available_purposes");
  localStorage.removeItem("available_zones");
  localStorage.removeItem("internal_domain");
  localStorage.removeItem("deal_ids");
  localStorage.removeItem("customAccountName");
  for (let i = 0; i < localStorage.length; i++) {
    let key = localStorage.key(i);
    if (key.startsWith('designer_logs_')) {
      localStorage.removeItem(key);
    }
  }
  // flushes current path and adds new.
  history.push("/auth/login-page");
  dispatch({
    type: actions.LOGOUT
  });
};

// this action creator returns the profile component
export const MYPROFILE = history => dispatch => {
  // flushes current path and adds new.
  history.push("/profile");
  dispatch({
    type: actions.MYPROFILE
  });
};

export const SAVE_PROFILE = ({ payload }) => {
  return {
    type: actions.SAVE_PROFILE,
    data: payload.data || null
  };
};
export const THEME_CUSTOMIZE = ({ payload }) => {
  return {
    type: actions.THEME_CUSTOMIZE,
    data: payload.data || null
  };
};
// when using dispatch, you must call dispatch from inside as "thunk" considers it a thunk
// and tries to execute the return type which is an action object instead of thunk function.
export const SHOW_NOTIFICATION = ({ payload }) => dispatch => {
  if (process.env.REACT_APP_ENV_NAME === "stagingez") {
    //console.log(payload?.error);
  }
  if (payload?.showErrorPage) {
    dispatch({
      type: actions.SHOW_ERROR_PAGE
    });
  } else if (payload.color && payload.message) {
    if (typeof payload.delay === 'number') {
      setTimeout(() => {
        dispatch({ type: actions.HIDE_NOTIFICATION });
      }, payload.delay);
    }
    dispatch({
      type: actions.SHOW_NOTIFICATION,
      color: payload.color,
      message: payload.message
    });
  } else {
    throw "message and color parameters are missing.";
  }
};
export const HIDE_NOTIFICATION = () => {
  return {
    type: actions.HIDE_NOTIFICATION
  };
};
export const SHOW_LOADER = () => {
  return {
    type: actions.SHOW_LOADER
  };
};
export const HIDE_LOADER = () => {
  return {
    type: actions.HIDE_LOADER
  };
};

export const SET_NAVBAR_TITLE = navbarTitle => {
  return {
    type: actions.SET_NAVBAR_TITLE,
    navbarTitle
  };
};

export const SHOW_ERROR_PAGE = () => {
  return {
    type: actions.SHOW_ERROR_PAGE
  };
};

export const HIDE_ERROR_PAGE = () => {
  return {
    type: actions.HIDE_ERROR_PAGE
  };
};

export const SET_TEMPLATES = payload => {
  return {
    type: actions.SET_TEMPLATES,
    payload
  };
};

export const SET_WIZARDS = payload => {
  return {
    type: actions.SET_WIZARDS,
    payload
  };
};
