// This component creates a modal for Reserved IP with respective form fields, the
// modal is popped up during provision or resuming of a lab or poc.

import React, { useState } from "react";
import { SECONDARY_COLOR } from "global_constants/app_constants";
import Button from "@material-ui/core/Button";
// Formik
import { useFormik } from "formik";
import { Card } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Api } from "config/api";
import Loader from "react-loader-spinner";
import { LOADER_TYPE } from "global_constants/app_constants";
import "views/ServiceOffering/LearningLab/acess labs/Overview.scss";
import NotificationAlert from "views/Components/NotificationAlert";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import theme from "theme";
const inputProps = {
  disableAnimation: true,
  shrink: false
};

const customStyles = theme => ({
  cardStyle: {
    paddingLeft: "20px",
    paddingRight: "20px"
  },
  datetime: {
    color: SECONDARY_COLOR
  },
  buttStyle: {
    background: theme.palette.buttonColor.main,
    "&:hover":{
      background: theme.palette.buttonColor.main,
    }
  },
  font: {
    fontFamily: `${theme.palette.fontList.selectedFont} !important`
  }
});

const DemoSummary = props => {
  const useStyles = makeStyles(customStyles);
  const classes = useStyles();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [formOpacity, setformOpacity] = useState("");
  const formattedDateTime = new Date(props?.demoSummaryDetails?.created_date);
  const formik = useFormik({
    initialValues: {
      demoName: props?.demoSummaryDetails?.learning_name,
      customerName: props?.demoSummaryDetails?.customer_name,
      userName: props?.demoSummaryDetails?.userid,
      demoDate: formattedDateTime?.toLocaleString(),
      demoComments: props?.demoSummaryDetails?.learning_summary
    },
    onSubmit: values => {
      setShowNotification(false);
      setBtnDisabled(true);
      setformOpacity(0.3);
      const data = {
        uuid: props.uuid,
        name: values.demoName,
        cust_name: values.customerName,
        learning_summary: values.demoComments,
        progres_data: ""
      };
      SDCloudBackendAPIWithToken()
        .post(Api.updateDemoSummary, data)
        .then(res => {
          setShowNotification(true);
          setformOpacity(1);
          setNotificationMessage("Sales demo successfully updated");
          setNotificationType("success");
          setBtnDisabled(false);
        })
        .catch(err => {
          let error = err.response.data;
          setShowNotification(true);
          setNotificationMessage(error.message);
          setNotificationType("error");
          setBtnDisabled(false);
          setformOpacity(1);
        });
    },
    validate: values => {
      let errors = {};

      if (!values.demoName) {
        errors.demoName = "Required";
      }
      if (!values.customerName) {
        errors.customerName = "Required";
      }
      if (!values.userName) {
        errors.userName = "Required";
      }
      if (!values.demoDate) {
        errors.demoDate = "Required";
      }

      return errors;
    }
  });

  return (
    <Card className={classes.cardStyle}>
      <CardBody>
        <GridContainer
          justify="center"
          alignItems="center"
          style={{ opacity: formOpacity }}
          // className={classes.paper}
        >
          <GridItem
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ marginBottom: "20px" }}
          >
            <h3 className={classes.font}>Update demo summary</h3>
            <p className={classes.font}>Please enter the fields to update the demo details</p>
          </GridItem>
          <form onSubmit={formik.handleSubmit}>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              {formik.errors.demoName && formik.touched.demoName ? (
                <TextField
                  type="text"
                  variant="filled"
                  label="Demo Name:"
                  name="demoName"
                  fullWidth
                  InputLabelProps={inputProps}
                  value={formik.values.demoName}
                  onChange={formik.handleChange}
                  helperText={formik.errors.demoName}
                  onBlur={formik.handleBlur}
                  error
                />
              ) : (
                <TextField
                  variant="filled"
                  label="Demo Name:"
                  name="demoName"
                  fullWidth
                  InputLabelProps={inputProps}
                  value={formik.values.demoName}
                  onChange={formik.handleChange}
                />
              )}
              {formik.errors.customerName && formik.touched.customerName ? (
                <TextField
                  type="text"
                  variant="filled"
                  label="Customer Name:"
                  name="customerName"
                  fullWidth
                  InputLabelProps={inputProps}
                  value={formik.values.customerName}
                  onChange={formik.handleChange}
                  helperText={formik.errors.customerName}
                  onBlur={formik.handleBlur}
                  error
                />
              ) : (
                <TextField
                  variant="filled"
                  label="Customer Name:"
                  name="customerName"
                  fullWidth
                  InputLabelProps={inputProps}
                  value={formik.values.customerName}
                  onChange={formik.handleChange}
                />
              )}
              {formik.errors.userName && formik.touched.userName ? (
                <TextField
                  type="text"
                  variant="filled"
                  label="User Name:"
                  name="userName"
                  disabled={true}
                  fullWidth
                  InputLabelProps={inputProps}
                  value={formik.values.userName}
                  onChange={formik.handleChange}
                  helperText={formik.errors.userName}
                  onBlur={formik.handleBlur}
                  error
                />
              ) : (
                <TextField
                  variant="filled"
                  label="User Name:"
                  name="userName"
                  disabled={true}
                  fullWidth
                  InputLabelProps={inputProps}
                  value={formik.values.userName}
                  onChange={formik.handleChange}
                />
              )}
              {formik.errors.demoDate && formik.touched.demoDate ? (
                <TextField
                  type="datetime"
                  variant="filled"
                  label="Demo Date:"
                  name="demoDate"
                  disabled={true}
                  fullWidth
                  InputLabelProps={inputProps}
                  value={formik.values.demoDate}
                  onChange={formik.handleChange}
                  helperText={formik.errors.demoDate}
                  onBlur={formik.handleBlur}
                  error
                />
              ) : (
                <TextField
                  type="datetime"
                  variant="filled"
                  label="Demo Date:"
                  name="demoDate"
                  disabled={true}
                  fullWidth
                  InputLabelProps={inputProps}
                  value={formik.values.demoDate}
                  onChange={formik.handleChange}
                />
              )}
              <TextField
                type="text"
                variant="filled"
                label="Additional Comments:"
                name="demoComments"
                fullWidth
                multiline
                rowsMax={4}
                InputLabelProps={inputProps}
                value={formik.values.demoComments}
                onChange={formik.handleChange}
              />
              {!btnDisabled ? (
                <Button
                  variant="contained"
                  color="secondary"
                  disableElevation
                  size="large"
                  // onClick={addDomain}
                  disableFocusRipple
                  className={classes.buttStyle}
                  type="submit"
                >
                  Submit
                </Button>
              ) : null}
              {btnDisabled ? (
                <Loader
                  type={LOADER_TYPE}
                  color={theme.palette.secondary.main}
                  width={50}
                  height={50}
                  style={{ float: "right", marginRight: "15px" }}
                />
              ) : null}

              <NotificationAlert
                notificationType={notificationType}
                showNotification={showNotification}
                message={notificationMessage}
              />
            </GridItem>
          </form>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

export default DemoSummary;