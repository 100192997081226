import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Formik
import { useFormik } from "formik";

// Material UI Components
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Fade from "@material-ui/core/Fade";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
// Icons
import CloseIcon from "@material-ui/icons/Close";

// API
import { SDCloudBackendAPIWithToken } from "../../../../apis/backendAPI";
import { Api } from "../../../../config/api";

// Local Components
import LoaderHOC from "components/HOC/LoaderHoc";
import moment from "moment-timezone";
import { SHOW_NOTIFICATION } from "../../../../action_creators/actioncreator";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  multilineColor: {
    color: "#7D7D7D"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 600,
    borderRadius: "0"
  },
  button: {
    margin: "30px 0 40px"
  },
  purposeDropdown: {
    width: "100%",
    marginTop: "5px"
  },
  btnColor: {
    background: theme.palette.buttonColor.main,
    "&:hover": {
      background: theme.palette.buttonColor.main
    }
  },
  title: {
    margin: "20px 0",
    textTransform: "capitalize"
  },
  CloseIcon: {
    color: "#ffffff ",
    cursor: "pointer",
    marginLeft: "10px"
  },
  helperText: {
    marginTop: "20px",
    color: "#7D7D7D"
  },
  helperCustom: {
    color: "#7D7D7D",
    margin: "10px 0 1px"
  },
  ipAddressDropdown: {
    width: "100%",
    marginTop: "15px"
  },
  testEnvironmentRadio: {
    marginTop: "-15px",
    fontWeight: "400"
  }
}));

const inputProps = {
  disableAnimation: true,
  shrink: false
};

const formControlProps = {
  root: {
    marginTop: "10px"
  }
};

function ResumeSandboxModal(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [menuItems, setMenuItems] = useState([]);
  const [menuItemsVals, setMenuItemsVals] = useState([]);
  const [menuItemsVals2, setMenuItemsVals2] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [error, setError] = React.useState(false);
  const [purpose, setPurpose] = useState(null);
  let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
  let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
  let hasDomain = domain?.some(res => userEmail.includes(res));
  const [isInternal, setIsInternal] = useState(hasDomain);
  const env = localStorage.getItem("ENVIRONMENT");
  const formik = useFormik({
    initialValues: {
      name: props.name,
      endTime: "",
      action: "suspend",
      environment: false,
      purpose: "",
      deal_id:"",
      archive:"unarchive",
    },
    onSubmit: values => {
      setShowLoader(true);
      let ends = `${moment()
        .add(values.endTime, "hours")
        .tz(moment.tz.guess())
        .utc()
        .format("YYYY-MM-DD HH:mm")}`;
      let auto_terminate;
      if (values.action == "terminate") {
        auto_terminate = "True";
      } else {
        auto_terminate = "False";
      }
      let mode = "prod";
      if (values.environment) {
        mode = "test";
      }
      let is_archive;
      if (isInternal){
        is_archive = values.archive
      } else {
        is_archive = "archive"
      }
      let payload = {
        uuid: props.uuid,
        action: props.action,
        end_time: ends,
        auto_terminate: auto_terminate,
        purpose: values.purpose,
        archive: is_archive,
      };
      if(values.purpose == 'Mint') {
        payload["deal_id"] = values.deal_id;
      }
      SDCloudBackendAPIWithToken()
        .post(Api.modifyCluster, payload)
        .then(resp => resp.data)
        .then(data => {
          let action = props.action;
          const payload = {
            message: `Sandbox successfully ${action}`,
            color: "success"
          };

          dispatch(SHOW_NOTIFICATION({ payload }));
          window.location.reload();
        })
        .catch(err => {
          //console.log(err);
          const payload = {
            message: err?.response?.data?.message || "unknown error occured",
            color: "danger",
            error: err,
          };
          dispatch(SHOW_NOTIFICATION({ payload }));
          setShowLoader(false);
        });
    },
    validate: values => {
      let errors = {};

      if (!values.endTime || values.endTime < 1 || values.endTime > 336) {
        errors.endTime = "Required";
      }
      if (isInternal && env!=="production" && values.endTime > 2){
        errors.endTime = "Maximum time allowed for internal users is 2 hours."
      }
      if (values.purpose == "Mint" && (values.deal_id=="" || values.deal_id == "None")){
        errors.deal_id = "Required";
      }
      if ((values.purpose == "" || values.purpose == "None") && isInternal) {
        errors.purpose = "Required";
      } else if (values.purpose == "" && !isInternal) {
        values.purpose = JSON.parse(
          localStorage.getItem("available_purposes")
        ).external[0];
      }
      return errors;
    }
  });

  useEffect(() => {
    let available_purposes = JSON.parse(
      localStorage.getItem("available_purposes")
    );
    if (isInternal) {
      if (available_purposes?.internal) {
        let purposes = available_purposes.internal.map(item => {
          return <option value={item}>{item}</option>;
        });
        setMenuItemsVals([<option value={null}>None</option>, ...purposes]);
      }
      var deal_id = JSON.parse(localStorage.getItem("deal_ids"))
      if(deal_id) {
        let deals = deal_id.map(item => {
          return <option value={item}>{item}</option>;
        });
        setMenuItemsVals2([<option value={null}>None</option>, ...deals]);
      }
    } else {
      if (available_purposes?.external) {
        let purposes = available_purposes.external.map(item => {
          return <option value={item}>{item}</option>;
        });
        setMenuItemsVals([<option value={null}>None</option>, ...purposes]);
      }
    }
  }, []);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={props.open}>
          <div className={classes.root}>
            <Paper elevation={0} className={classes.paper}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h4" className={classes.title}>
                    Resume Sandbox
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        className={classes.helperText}
                      >
                        Please fill all the details
                      </Typography>
                      <form onSubmit={formik.handleSubmit}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography
                              variant="body2"
                              className={classes.helperText}
                            >
                              Name
                            </Typography>
                            <TextField
                              type="text"
                              variant="filled"
                              // label="Name"
                              name="name"
                              disabled={true}
                              title="This field is not editable"
                              fullWidth
                              inputProps={{
                                className: classes.multilineColor
                              }}
                              InputProps={{ disableUnderline: true }}
                              InputLabelProps={inputProps}
                              value={props.name}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </Grid>
                          {isInternal ? (
                            <Grid item xs={12}>
                              <Typography
                                variant="body2"
                                className={classes.helperCustom}
                              >
                                Purpose
                              </Typography>
                              {
                                <FormControl
                                  variant="filled"
                                  className={
                                    (classes.formControl,
                                    classes.purposeDropdown)
                                  }
                                >
                                  {formik.errors.purpose &&
                                  formik.touched.purpose ? (
                                    <Select
                                      labelId="demo-simple-select-filled-label"
                                      id="demo-simple-select-filled"
                                      fullWidth
                                      name="purpose"
                                      value={formik.values.purpose}
                                      onChange={formik.handleChange}
                                      native
                                      error
                                      helperText={formik.errors.purpose}
                                      className={classes.purposeDropdown}
                                    >
                                      {menuItemsVals.length > 0 ? (
                                        menuItemsVals
                                      ) : (
                                        <MenuItem value="">None</MenuItem>
                                      )}
                                    </Select>
                                  ) : (
                                    <Select
                                      labelId="demo-simple-select-filled-label"
                                      id="demo-simple-select-filled"
                                      fullWidth
                                      name="purpose"
                                      value={formik.values.purpose}
                                      onChange={formik.handleChange}
                                      native
                                      className={classes.purposeDropdown}
                                    >
                                      {menuItemsVals.length > 0 ? (
                                        menuItemsVals
                                      ) : (
                                        <MenuItem value="">None</MenuItem>
                                      )}
                                    </Select>
                                  )}
                                </FormControl>
                              }
                            </Grid>
                          ) : null}
                          {isInternal && formik.values.purpose == "Mint"? (
                              <Grid item xs={12}>
                                <Typography
                                    variant="body2"
                                    className={classes.helperCustom}
                                >
                                  Select Deal id
                                </Typography>
                                {
                                  <FormControl
                                      variant="filled"
                                      className={
                                        (classes.formControl,
                                            classes.purposeDropdown)
                                      }
                                  >
                                    {formik.errors.deal_id &&
                                    formik.touched.deal_id ? (
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            fullWidth
                                            name="deal_id"
                                            value={formik.values.deal_id}
                                            onChange={formik.handleChange}
                                            native
                                            error
                                            helperText={formik.errors.deal_id}
                                            className={classes.purposeDropdown}
                                        >
                                          {menuItemsVals2.length > 0 ? (
                                              menuItemsVals2
                                          ) : (
                                              <MenuItem value="">None</MenuItem>
                                          )}
                                        </Select>
                                    ) : (
                                        <Select
                                            labelId="demo-simple-select-filled-label"
                                            id="demo-simple-select-filled"
                                            fullWidth
                                            name="deal_id"
                                            value={formik.values.deal_id}
                                            onChange={formik.handleChange}
                                            native
                                            className={classes.purposeDropdown}
                                        >
                                          {menuItemsVals2.length > 0 ? (
                                              menuItemsVals2
                                          ) : (
                                              <MenuItem value="">None</MenuItem>
                                          )}
                                        </Select>
                                    )}
                                  </FormControl>
                                }
                              </Grid>

                          ) : null}
                           {(isInternal && !props.archiveaction) ?(<Grid item xs={12}>
                              <Typography
                                variant="body2"
                                className={classes.helperCustom}
                              >
                                Archive
                              </Typography>

                              <FormControl component="fieldset">
                                <RadioGroup
                                  row
                                  aria-label="ip_type"
                                  name="archive"
                                  value={formik.values.archive}
                                  className={classes.radioButton}
                                  onChange={formik.handleChange}
                                >
                                  <FormControlLabel
                                    value="archive"
                                    control={<Radio color="primary" />}
                                    label="Yes"
                                  />
                                  <FormControlLabel
                                    value="unarchive"
                                    control={<Radio color="primary" />}
                                    label="No"
                                  />
                                </RadioGroup>
                              </FormControl>
                          </Grid>
                          ) : null}
                          <Grid item xs={12}>
                            <Typography
                              variant="body2"
                              className={classes.helperText}
                            >
                              Number of Hours
                            </Typography>
                            {formik.errors.endTime && formik.touched.endTime ? (
                              <TextField
                                type="number"
                                variant="filled"
                                // label="Number of Hours"
                                name="endTime"
                                fullWidth
                                InputLabelProps={inputProps}
                                value={formik.values.endTime}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error
                              />
                            ) : (
                              <TextField
                                type="number"
                                variant="filled"
                                // label="Number of Hours"
                                name="endTime"
                                fullWidth
                                InputLabelProps={inputProps}
                                value={formik.values.endTime}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            )}
                          </Grid>
                          {
                            <Grid item xs={6}>
                              <Typography
                                variant="body2"
                                className={classes.helperCustom}
                              >
                                End action
                              </Typography>

                              <FormControl component="fieldset">
                                <RadioGroup
                                  row
                                  aria-label="ip_type"
                                  name="action"
                                  value={formik.values.action}
                                  className={classes.radioButton}
                                  onChange={formik.handleChange}
                                >
                                  <FormControlLabel
                                    value="suspend"
                                    control={<Radio color="primary" />}
                                    label="Suspend"
                                  />
                                  <FormControlLabel
                                    value="terminate"
                                    control={<Radio color="primary" />}
                                    label="Terminate"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          }
                          <Grid item xs={12}>
                            <LoaderHOC isLoading={showLoader}>
                              <Button
                                variant="contained"
                                color="secondary"
                                disableElevation
                                size="large"
                                fullWidth
                                disableFocusRipple
                                type="submit"
                                className={
                                  classes.button + " " + classes.btnColor
                                }
                              >
                                Resume
                              </Button>
                            </LoaderHOC>
                          </Grid>
                          {isInternal ? (
                            <div className={classes.testEnvironmentRadio}>
                              <FormControl>
                                <span>
                                  <Checkbox
                                    tabIndex={-1}
                                    name="environment"
                                    disableRipple
                                    onChange={formik.handleChange}
                                    // checkedIcon={<Check className={classes.checkedIcon} />}
                                    // icon={<Check className={classes.uncheckedIcon} />}
                                  />{" "}
                                  Is test run ?
                                </span>
                              </FormControl>{" "}
                            </div>
                          ) : null}
                        </Grid>
                      </form>
                      {showNotification ? (
                        <Alert
                          onClose={() => setShowNotification(false)}
                          severity={notificationType}
                        >
                          {notificationMessage}
                        </Alert>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <CloseIcon
              className={classes.CloseIcon}
              onClick={() => {
                props.onClose(false);
                formik.resetForm();
              }}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default ResumeSandboxModal;
