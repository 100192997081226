import React from "react";

// Material UI Components
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SvgtoIcon from "utils/SvgtoIcon";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";

// Other Components
import { useTable, usePagination, useRowSelect } from "react-table";
import { v4 as uuidv4 } from "uuid";
import TableHeader from "./TableHeader";

// Styling
const useStyles = makeStyles((theme) => ({
  container: {
    // padding: "40px",
  },
  paperContainer: {
    width: "100%",
  },
  pagesDropdown: {
    backgroundColor: "#ffffff",
    color: "black",
    border: "none",
    padding: "5px",
  },
  paginationToolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
  },
  paginationNumbers: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& p, div": {
      margin: "0 10px",
    },
  },
  numberButton: {
    minWidth: "0px",
    padding: "4px",
    "& .MuiButton-label": {
      width: "24px",
      height: "24px",
      backgroundColor: "lightblue",
      color: "#ffffff",
    },
    "& .MuiButton-text": {
      padding: "0 1px",
    },
  },
  table: {
    root: {
      "& .MuiTableCell-root": {
        borderBottom: "5px solid green",
      },
      "& .MuiTableCell-body": {
        color: theme.palette.grey["500"],
      },
    },
  },
  tableTitle: {
    margin: "20px 0",
    fontWeight: "700",
  },
}));

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const ProfileTable = ({
  columns,
  data,
  handleDeleteButtonClick,
  hiddenColumns,
  tableTitle,
}) => {
  const classes = useStyles();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    selectedFlatRows,
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        hiddenColumns: hiddenColumns ? hiddenColumns : [],
      },
    },
    usePagination,
    useRowSelect
  );

  const handleDeleteButton = () => {
    handleDeleteButtonClick(selectedRowIds, selectedFlatRows);
  };

  return (
    <Paper elevation={0} className={classes.paperContainer}>
      <TableContainer className={classes.container}>
        <Typography variant="subtitle2" className={classes.tableTitle}>
          {tableTitle}
        </Typography>
        <Table
          className={classes.table}
          aria-label="customized table"
          {...getTableProps()}
        >
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ProfileTable;
