import React, { useEffect, useState,createContext, useContext, } from "react";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
//react 
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// Formik




import { FormControl, InputLabel, Select, FormHelperText,} from '@material-ui/core';
import { useFormik } from "formik";
// Material UI Components
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {hotjar} from "react-hotjar";
import GridItem from "components/Grid/GridItem";
import Switch from "@material-ui/core/Switch";
//markdown editor
import Editor from "react-markdown-editor-lite";
import ReactMarkdown from "react-markdown";
import MarkdownIt from 'markdown-it';
//markdown editor import style manually
import 'react-markdown-editor-lite/lib/index.css';
//API Call
import { CMSAPIWithToken } from "../../../apis/CMS";
//checkbox
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import queryString from "query-string";


const useStyles = makeStyles((theme) => ({
  
  gridContainer: {
    padding: "0 40px 30px 40px",
  },
  radioButton: {
    "& .MuiTypography-root": {
      marginLeft: "10px",
    },
  },
  formControl: {
    width: "100%",
    marginTop: "5px",
  },
  Select: {
    marginBottom: "50px",
  },
  warningNote: {
    "& .MuiAlert-icon": {
      display: "none",
    },
  },
  button: {
    marginTop: "50px",
  },
  buttOutlined: {
    color: theme.palette.buttonColor.main,
    borderColor: theme.palette.buttonColor.main,
    "&:hover": {
      border: `1px solid ${theme.palette.buttonColor.main}`,
    },
  },
}));

//sandbox view main
const SandboxesExercisesUpdate = (props) => {
  const classes = useStyles();
  hotjar.initialize(2281731, 6);
  hotjar.identify(localStorage.userName, { userProperty: localStorage.userName});
  const mdEditor = React.useRef(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [solution, setSolution] = useState("");
  const [introList, setIntroList] = useState("");
  let queryParams = queryString.parse(window.location.search);
  var sb_id = queryParams.id;
  var sandbox_id_title_map = JSON.parse(localStorage.getItem("sandbox_id_title_map"))
  var sandbox_title = sandbox_id_title_map[sb_id]
  const dispatch = useDispatch();
    const history = useHistory();
    const inputProps = {
        disableAnimation: true,
        shrink: false
      };  

  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
        admin_sandboxes_id: null,
        admin_sandboxes_categories_id: null,
        title: "",
        intro_list: "",
        solution: "",
        exercise_position: ""
              },
    onSubmit: (values) => {
      let payload = {
        admin_sandboxes_id: values.admin_sandboxes_id, 
        admin_sandboxes_categories_id: values.admin_sandboxes_categories_id,
        title: values.title, 
        intro_list: introList,
        solution: solution,
        exercise_position: values.exercise_position,
      };

        CMSAPIWithToken() 
        .patch("/poc/admin_sandboxes_exercises"+`/${props.user}/`,payload) 
        .then((resp) => resp.data.results)
        .then((data) => {
            const payload = {
                message: "Sandbox Exercise updated Successfully",
                color: "success",
            };
            payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
            history.push("ProductContentSandboxesMenuItems?id="+sb_id+"&title="+sandbox_title);
            // window.location.reload()
           //renderSanboxView();
        })
        .catch((err) => {
          let message = err.response.data.message;
          const payload = {
            message: message,
            color: "danger",
        };
        payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
    },
    validate: (values) => {
      let errors = {};
      if (!values.admin_sandboxes_id) {
        errors.admin_sandboxes_id = "Required";
      }
      if (!values.exercise_position) {
        errors.exercise_position = "Valid integer Required";
      }
      return errors;
    },
  });

    //mark down editor
    const handleEditorChange = ({ html, text }) => {
      const newValue = text;
      //console.log(newValue);
      setSolution(newValue);
    };
    const handleIntroListChange = ({ html, text }) => {
      const newValue = text;
      setIntroList(newValue);
    };
      // Initialize a markdown parser
      const mdParser = new MarkdownIt(/* Markdown-it options */);

  let data = [];

  const renderSanboxView = async () => {
    let sanboxView = CMSAPIWithToken()
      .get("/poc/admin_sandboxes_exercises/")
      .then((resp) => resp.data.results)
      .then((resp) => {
        resp.forEach((item) => {
          if(props.user == item.id) { 
          formik.setFieldValue("admin_sandboxes_id", item?.admin_sandboxes_id);
          formik.setFieldValue("admin_sandboxes_categories_id", item?.admin_sandboxes_categories_id);
          formik.setFieldValue("title", item?.title);
          setIntroList(item.intro_list);
          setSolution(item.solution);
          formik.setFieldValue("exercise_position", item?.exercise_position);
          formik.setFieldValue("created", item?.created);
          formik.setFieldValue("modified", item?.modified);
        }
        });
      })      
      .catch((err) => {
        let message = err.response.data.message;
        // setRows([]);
        // setIsLoading(false);
        setShowNotification(true);
        setNotificationType("error");
        setNotificationMessage("Failed to retrieve the data");
      });

      

      //setUserDetails({ ...formik.userDetails, ...data });
  };

  useEffect(() => {
    renderSanboxView();
  }, []);

  //checkbox
  
    const [state, setState] = React.useState({
      checkedA: true,
      checkedB: true,
      checkedF: true,
      checkedG: true,
    });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div>
        <form
            onSubmit={formik.handleSubmit}
            className={classes.formContainer}
          >
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.gridContainer}
      >
        <Grid item xs={12} md={5}>
          <Typography variant="h4">Sandbox Exercise Update</Typography>
          
        </Grid>

        <Grid container className={classes.container}>
        <GridItem md={12} >
                  {formik.errors.admin_sandboxes_id && formik.touched.admin_sandboxes_id ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Admin Sandboxes Id"
                      name="admin_sandboxes_id"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.admin_sandboxes_id}
                      onChange={formik.handleChange}
                      helperText={formik.errors.admin_sandboxes_id}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Sandboxes Id"
                      name="admin_sandboxes_id"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.admin_sandboxes_id}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>
                
                <GridItem md={12}>
                  {formik.errors.admin_sandboxes_categories_id && formik.touched.admin_sandboxes_categories_id ? (
                    <FormControl variant="filled" fullWidth error>
                      <InputLabel id="admin-sandboxes-categories-id-label">Admin Sandboxes Categories Id</InputLabel>
                      <Select
                        native
                        labelId="admin-sandboxes-categories-id-label"
                        id="admin_sandboxes_categories_id"
                        name="admin_sandboxes_categories_id"
                        value={formik.values.admin_sandboxes_categories_id}
                        onChange={e => {
                          //console.log("Value changed to:", e.target.value);
                          formik.handleChange(e);
                        }}
                        onBlur={formik.handleBlur}
                        inputProps={inputProps}
                      >
                        <option value="">Select Category</option>
                        {props.categories.map((category) => (
                          <option key={category.id} value={category.id}>{category.sandboxes_category_name}</option>
                        ))}
                      </Select>
                      <FormHelperText>{formik.errors.admin_sandboxes_categories_id}</FormHelperText>
                    </FormControl>
                  ) : (
                    <FormControl variant="filled" fullWidth>
                      <InputLabel id="admin-sandboxes-categories-id-label">Admin Sandboxes Categories Id</InputLabel>
                      <Select
                        native
                        labelId="admin-sandboxes-categories-id-label"
                        id="admin_sandboxes_categories_id"
                        name="admin_sandboxes_categories_id"
                        value={formik.values.admin_sandboxes_categories_id}
                        onChange={e => {
                          //console.log("Value changed to:", e.target.value);
                          formik.handleChange(e);
                        }}
                        inputProps={inputProps}
                      >
                        <option value="">Select Category</option>
                        {props.categories.map((category) => (
                          <option key={category.id} value={category.id}>{category.sandboxes_category_name}</option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </GridItem>

                <GridItem md={12}>
                  <TextField
                    type="text"
                    variant="filled"
                    label="Title"
                    name="title"
                    fullWidth
                    InputLabelProps={inputProps}
                    value={formik.values.title}
                    onChange={formik.handleChange}
                  />
                </GridItem>

                <GridItem md={12}>
                <p>Intro List</p>
                        <Editor
                          ref={mdEditor}
                          value={introList}
                          style={{
                            height: "500px"
                          }}
                          onChange={handleIntroListChange}
                          renderHTML={text => <ReactMarkdown source={text} />}
                        />
                </GridItem>

                <GridItem md={12}>
                  <p>Solution</p>
                        <Editor
                          ref={mdEditor}
                          value={solution}
                          style={{
                            height: "500px"
                          }}
                          onChange={handleEditorChange}
                          renderHTML={text => <ReactMarkdown source={text} />}
                        />
                </GridItem>
                <GridItem md={12}>
                  {formik.errors.exercise_position && formik.touched.exercise_position ? (
                    <TextField
                      type="number"
                      variant="filled"
                      label="Exercise Position"
                      name="exercise_position"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.exercise_position}
                      onChange={formik.handleChange}
                      helperText={formik.errors.exercise_position}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="number"
                      variant="filled"
                      label="Exercise Position"
                      name="exercise_position"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.exercise_position}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>
                <GridItem md={12}>
                <Grid item xs={12} sm={12} md={12}>
                  <p>Crated Date:{formik.values.created}</p>
                  <p>Modified Date:{formik.values.modified}</p>          
                  </Grid>
                </GridItem>


              </Grid>

        

        <GridItem xs={12} sm={6} md={3} lg={3}>
                 <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    size="large"
                    className={classes.buttStyle}
                    fullWidth
                    disableFocusRipple
                    type="submit"
                  >
                    Update
                  </Button>
              </GridItem>

      </Grid>
      </form>       
    </div>
  );
};

export default SandboxesExercisesUpdate;
