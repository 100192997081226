import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import {
  Modal,
  Backdrop,
  Fade,
  Paper,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  makeStyles
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Alert from "@material-ui/lab/Alert";
import { CMSAPIWithToken } from "../../../apis/CMS";
import { SHOW_LOADER, HIDE_LOADER } from "../../../action_creators/actioncreator";
import { Api } from "../../../config/api";
import * as Yup from "yup";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    width: 600,
    borderRadius: "0"
  },
  button: {
    margin: "30px 0 40px"
  },
  formControl: {
    width: "100%",
    marginTop: "5px"
  },
  btnColor: {
    background: theme.palette.buttonColor.main,
    "&:hover": {
      background: theme.palette.buttonColor.main
    }
  },
  title: {
    margin: "20px 0",
    textTransform: "capitalize"
  },
  CloseIcon: {
    color: "#000",
    cursor: "pointer",
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  helperText: {
    marginTop: "20px",
    color: "#7D7D7D"
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  dropdownGrid: {
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    backgroundColor: "#F96B13",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#F96B13",
    },
  },
  menuItem: {
    "&.Mui-selected, &.Mui-selected:hover": {
      backgroundColor: "#f5f5f5", // Light gray color for selected item
    }
  },
  error: {
    borderColor: 'red',
  }
}));

const validationSchema = Yup.object({
  from: Yup.string().required("Identifier is required"),
  to: Yup.string().required("Identifier is required")
    .notOneOf([Yup.ref('from'), null], "Identifiers cannot be the same"),
});

const SandboxCopyModal = ({ open, handleClose, sandboxIdentifiers, targetIdentifiers }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [toOptions, setToOptions] = useState([]);
  const [toSelection, setToSelection] = useState("learning");

  useEffect(() => {
    if (toSelection === "learning") {
      setToOptions(targetIdentifiers);
    } else {
      setToOptions(sandboxIdentifiers);
    }
  }, [toSelection, targetIdentifiers, sandboxIdentifiers]);

  const handleToSelectionChange = (event) => {
    setToSelection(event.target.value);
  };

  const formik = useFormik({
    initialValues: {
      from: "",
      to: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        dispatch(SHOW_LOADER());
        const payload = {
          from: values.from,
          to: values.to,
          fromType: 'sandbox',
          toType: toSelection,
        };
        await CMSAPIWithToken().post(Api.convert_sandbox, payload);
        setSubmitting(false);
        handleClose();
        // Optionally show a success message here
      } catch (error) {
        console.error("Error submitting form: ", error);
        // Optionally show an error message here
      } finally {
        dispatch(HIDE_LOADER());
      }
    },
  });

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <CloseIcon className={classes.CloseIcon} onClick={handleClose} />
            <Typography variant="h4" className={classes.title}>
              Sandbox Copy
            </Typography>
            <Typography variant="body2" className={classes.helperText}>
              Please fill all the details
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <Grid container className={classes.dropdownGrid} spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" className={classes.formControl} error={formik.touched.from && Boolean(formik.errors.from)}>
                    <InputLabel>From</InputLabel>
                    <Select
                      value="Sandbox"
                      label="From"
                      readOnly
                    >
                      <MenuItem value="Sandbox">Sandbox</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: "15px" }} error={formik.touched.from && Boolean(formik.errors.from)}>
                    <InputLabel id="from-label">Identifier</InputLabel>
                    <Select
                      labelId="from-label"
                      value={formik.values.from}
                      onChange={formik.handleChange}
                      label="Identifier"
                      name="from"
                      MenuProps={{
                        MenuListProps: {
                          style: {
                            backgroundColor: '#f5f5f5', // Light gray color
                          },
                        },
                      }}
                      className={formik.touched.from && Boolean(formik.errors.from) ? classes.error : ''}
                    >
                      <MenuItem className={classes.menuItem} value="">
                        
                      </MenuItem>
                      {sandboxIdentifiers.map((identifier, index) => (
                        <MenuItem key={index} value={identifier} className={classes.menuItem}>
                          {identifier}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.from && formik.errors.from ? (
                      <Typography variant="body2" color="error">{formik.errors.from}</Typography>
                    ) : null}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="outlined" className={classes.formControl} error={formik.touched.to && Boolean(formik.errors.to)}>
                    <InputLabel id="to-selection-label">To</InputLabel>
                    <Select
                      labelId="to-selection-label"
                      value={toSelection}
                      onChange={handleToSelectionChange}
                      label="To"
                      name="toSelection"
                      MenuProps={{
                        MenuListProps: {
                          style: {
                            backgroundColor: '#f5f5f5', // Light gray color
                          },
                        },
                      }}
                    >
                      <MenuItem value="learning" className={classes.menuItem}>Learning</MenuItem>
                      <MenuItem value="sandbox" className={classes.menuItem}>Sandbox</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" className={classes.formControl} style={{ marginTop: "15px" }} error={formik.touched.to && Boolean(formik.errors.to)}>
                    <InputLabel id="to-label">Identifier</InputLabel>
                    <Select
                      labelId="to-label"
                      value={formik.values.to}
                      onChange={formik.handleChange}
                      label="Identifier"
                      name="to"
                      MenuProps={{
                        MenuListProps: {
                          style: {
                            backgroundColor: '#f5f5f5', // Light gray color
                          },
                        },
                      }}
                      className={formik.touched.to && Boolean(formik.errors.to) ? classes.error : ''}
                    >
                      <MenuItem className={classes.menuItem} value="">
                      </MenuItem>
                      {toOptions.map((identifier, index) => (
                        <MenuItem key={index} value={identifier} className={classes.menuItem}>
                          {identifier}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.to && formik.errors.to ? (
                      <Typography variant="body2" color="error">{formik.errors.to}</Typography>
                    ) : null}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2} className={classes.button}>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    className={classes.submitButton}
                    fullWidth
                    type="submit"
                    disabled={formik.isSubmitting}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
            {false ? (
              <Alert
                onClose={() => {}}
                severity={"success"}
              >
                {"Notification message"}
              </Alert>
            ) : null}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default SandboxCopyModal;
