import React, { useEffect, useState } from "react";

// Redux
import { useDispatch } from "react-redux";
import {
  SHOW_LOADER,
  HIDE_LOADER,
  SET_NAVBAR_TITLE
} from "action_creators/actioncreator";
import Grid from '@material-ui/core/Grid';

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import ViewSalesDemo from "./ViewSalesdemo";
import ViewSandbox from "./Sandboxes/ViewSandbox";
import LabelTabs from "../../../components/LabelTabs";
import { expTabs, getRouteByName } from "../../../utils/utilfunction";
import "./ExperienceCenter.scss";
import Button from "@material-ui/core/Button";
import squareb from 'assets/criterion_networks/img/square-b.png';
import squarel from 'assets/criterion_networks/img/square-l.png';
import arrow from 'assets/criterion_networks/img/arrow.png';
import { ReactComponent as svg8 } from "../../../assets/criterion_networks/svg/08.svg";
import { ReactComponent as svg9 } from "../../../assets/criterion_networks/svg/09.svg";
import svg1 from "../../../assets/criterion_networks/svg/01.svg";
import { makeStyles, Paper, Box } from "@material-ui/core";
import SvgComponent from "utils/SvgComponent";
import Chatbot from "views/Components/chatbot/Chatbot";
const customStyles = theme => ({
  imgFilter: {
    filter: theme.palette.secondary.filterImage
  },
  title: {
    background: `url(data:image/svg+xml;base64,${window.btoa(`<svg width="40" height="3" viewBox="0 0 40 3" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="40" height="3" fill="${theme.palette.secondary.main}"/></svg>`)}) no-repeat top left`,
  },
  font: {
    fontFamily: theme.palette.fontList.selectedFont
  },
  widthUnset: {
    width: "unset"
  },
  heightUnset: {
    height: "unset"
  }
});
const ExperienceCenter = props => {
  const useStyles = makeStyles(customStyles);
  const classes = useStyles();

  const { history } = props;

  const [view, setView] = React.useState(true);
  const [activeTab, setActiveTab] = useState("");
  const dispatch = useDispatch();
  const profileData = JSON.parse(localStorage.getItem("profileData"));
  const account = profileData.account;
  let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
  let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
  let hasDomain = domain?.some((res) => userEmail.includes(res));
  const [isInternal, setIsInternal] = useState(hasDomain);

  const handleClick = v => {
    setView(v);
  };

  const onActiveTab = tab => {
    setActiveTab(tab.value);
    tabClick(tab.value);
  };

  useEffect(() => {
    // const url_string = window.location.href;
    // const url = new URL(url_string);
    // const activeView = url.searchParams.get("tab") || "sales";
    // if (activeView) {
    //   history.push(`${getRouteByName("Experience")}?tab=${activeView}`);
    //   setActiveTab(activeView);
    // }
    dispatch(SET_NAVBAR_TITLE(isInternal?account+"  - Experience Center":"Experience Center"));
  }, []);

  const tabClick = tab => {
    setActiveTab(tab);
    props.history.push(`${getRouteByName("Experience")}?tab=${tab}`);
  };

  const nextButtonClick = (type) => {
    //console.log("inside", type);
    if (type == "showcases") {
      props.history.push("/experience-center-showcases");
      //console.log("inside", props);
    } else {
      props.history.push("/experience-center-sandboxes");
      //console.log("experience", props);
    }
  }

  return (
    <div className="experience-page">
      {/* <GridContainer> */}
      <Grid container spacing={3} className="bg-gray mt-2">
        <Grid item xs={12} sm={6} className="ml-5">
          <div class="exp-card">
            <h3 className={`exp-widget-title ${classes.title + ' ' + classes.font}`}>Experience Center </h3>
            {/* <img src={svg9} className={`exp-wid-img ${classes.imgFilter}`}></img> */}
            <SvgComponent className={`exp-wid-img ${classes.widthUnset + ' ' + classes.heightUnset}`} type="stroke" svgSource={svg9} />
            <Grid container className='box-inner-height'>
              <Grid item xs={6} sm={6}>
                <p class={`exp-sub-title ${classes.font}`}>Showcases</p>
                <span onClick={() => nextButtonClick("showcases")} >
                  <SvgComponent className={`arrow-link`} type="fill" svgSource={svg8} />
                </span>
                {/* <img src={svg8} className={`arrow-link ${classes.imgFilter}`} onClick={() => nextButtonClick("showcases")} /> */}
              </Grid>
              <Grid item xs={6} sm={6}>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className="mr-5">
          <div class="exp-card">
            <h3 className={`exp-widget-title ${classes.title + ' ' + classes.font}`}>Experience Center </h3>
            {/* <img src={svg9} className={`exp-wid-img ${classes.imgFilter}`}></img> */}
            <SvgComponent className={`exp-wid-img ${classes.widthUnset + ' ' + classes.heightUnset}`} type="stroke" svgSource={svg9} />
            <Grid container className='box-inner-height'>
              <Grid item xs={6} sm={6}>
                <p class={`exp-sub-title ${classes.font}`}>Sandboxes</p>
                <span onClick={() => nextButtonClick("sandboxes")}>
                  <SvgComponent className={`arrow-link`} type="fill" svgSource={svg8} />
                </span>
                {/* <img src={svg8} className={`arrow-link ${classes.imgFilter}`} onClick={() => nextButtonClick("sandboxes")} /> */}
              </Grid>
              <Grid item xs={6} sm={6}>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      {/* </GridContainer> */}
      <Chatbot/>
    </div>
  );
};

export default ExperienceCenter;