import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import * as reducers from "../reducers/reducer";
const rootReducer = combineReducers({
  profileObj: reducers.authreducer,
  util: reducers.utilReducer,
  learningLab: reducers.learningLabReducer,
  design: reducers.designPageReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
