import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Snack from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import { defaultFont } from "assets/jss/material-dashboard-pro-react.js";
import Grid from "@material-ui/core/Grid";

// @material-ui/icons
import Close from "@material-ui/icons/Close";
import theme from "theme";

const snackbarContentStyle = {
  root: {
    ...defaultFont,
    flexWrap: "unset",
    position: "relative",
    padding: "20px 15px",
    lineHeight: "20px",
    fontWeight: "normal",
    marginBottom: "20px",
    fontSize: "15px",
    backgroundColor: "white",
    // color: grayColor[6],
    borderRadius: "3px"
  },
  top20: {
    top: "20px"
  },
  top40: {
    top: "40px"
  },
  //   info: {
  //     backgroundColor: infoColor[3],
  //     color: whiteColor,
  //     ...infoBoxShadow
  //   },
  success: {
    backgroundColor: "#ffffff",
    minWidth: "0px",
    borderBottom: `4px solid ${theme.palette.secondary.main}`
  },
  //   warning: {
  //     backgroundColor: warningColor[3],
  //     color: whiteColor,
  //     ...warningBoxShadow
  //   },
  //   danger: {
  //     backgroundColor: dangerColor[3],
  //     color: whiteColor,
  //     ...dangerBoxShadow
  //   },
  //   primary: {
  //     backgroundColor: primaryColor[3],
  //     color: whiteColor,
  //     ...primaryBoxShadow
  //   },
  //   rose: {
  //     backgroundColor: roseColor[3],
  //     color: whiteColor,
  //     ...roseBoxShadow
  //   },
  message: {
    padding: "0",
    width: "220px",
    display: "block",
    maxWidth: "89%"
  },
  close: {
    width: "25px",
    height: "25px"
  },
  iconButton: {
    width: "24px",
    height: "24px",
    paddingRight: "16px",
    color: "#bdbdbd"
  },
  icon: {
    width: "38px",
    height: "38px",
    display: "block",
    // left: "15px",
    position: "relative",
    // marginTop: "-39px",
    fontSize: "20px",
    // backgroundColor: whiteColor,
    // padding: "5px",
    borderRadius: "50%",
    maxWidth: "38px"
  },
  //   infoIcon: {
  //     color: successColor[3]
  //   },
  successIcon: {
    color: theme.palette.secondary.main
  },
  //   warningIcon: {
  //     color: warningColor[3]
  //   },
  //   dangerIcon: {
  //     color: dangerColor[3]
  //   },
  //   primaryIcon: {
  //     color: primaryColor[3]
  //   },
  //   roseIcon: {
  //     color: roseColor[3]
  //   },
  iconMessage: {
    paddingLeft: "50px",
    display: "block"
  },
  messageStyle: {
    alignSelf: "center"
  }
};

const useStyles = makeStyles(snackbarContentStyle);

export default function CustomSnackbar(props) {
  const classes = useStyles();
  const { message, color, close, icon, place, open, autoHideDuration } = props;
  var action = [];
  const messageClasses = cx({
    [classes.iconMessage]: icon !== undefined
  });
  if (close !== undefined) {
    action = [
      <IconButton
        className={classes.iconButton}
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={() => props.closeNotification()}
      >
        <Close className={classes.close} />
      </IconButton>
    ];
  }
  const iconClasses = cx({
    [classes.icon]: classes.icon,
    [classes.infoIcon]: color === "info",
    [classes.successIcon]: color === "success",
    [classes.warningIcon]: color === "warning",
    [classes.dangerIcon]: color === "danger",
    [classes.primaryIcon]: color === "primary",
    [classes.roseIcon]: color === "rose"
  });
  return (
    <Snack
      classes={{
        anchorOriginTopCenter: classes.top20,
        anchorOriginTopRight: classes.top40,
        anchorOriginTopLeft: classes.top40
      }}
      autoHideDuration={autoHideDuration ? autoHideDuration : null}
      anchorOrigin={{
        vertical: place.indexOf("t") === -1 ? "bottom" : "top",
        horizontal:
          place.indexOf("l") !== -1
            ? "left"
            : place.indexOf("c") !== -1
            ? "center"
            : "right"
      }}
      open={open}
      onClose={() => props.closeNotification()}
      message={
        <div>
          <Grid container>
            <Grid item xs={12} sm={4} md={3}>
              {icon !== undefined ? (
                <props.icon className={iconClasses} />
              ) : null}
            </Grid>
            <Grid item xs={12} sm={8} md={9} className={classes.messageStyle}>
              {message}
            </Grid>
          </Grid>
        </div>
      }
      action={action}
      ContentProps={{
        classes: {
          root: classes.root + " " + classes[color],
          message: classes.message
        }
      }}
    />
  );
}

CustomSnackbar.defaultProps = {
  color: "info"
};

CustomSnackbar.propTypes = {
  message: PropTypes.node.isRequired,
  color: PropTypes.oneOf([
    "info",
    "success",
    "warning",
    "danger",
    "primary",
    "rose"
  ]),
  close: PropTypes.bool,
  icon: PropTypes.object,
  place: PropTypes.oneOf(["tl", "tr", "tc", "br", "bl", "bc"]),
  open: PropTypes.bool,
  closeNotification: PropTypes.func
};
