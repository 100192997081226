import React, { useEffect, useState } from "react";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
//react 
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// Formik
import { useFormik } from "formik";
//checkbox
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
// Material UI Components
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {hotjar} from "react-hotjar";
import GridItem from "components/Grid/GridItem";
import Switch from "@material-ui/core/Switch";
//markdown editor
import Editor from "react-markdown-editor-lite";
import ReactMarkdown from "react-markdown";
import MarkdownIt from 'markdown-it';
//markdown editor import style manually
import 'react-markdown-editor-lite/lib/index.css';
//API Call
import { CMSAPIWithToken } from "../../../apis/CMS";



const useStyles = makeStyles((theme) => ({
  
  gridContainer: {
    padding: "0 40px 30px 40px",
  },
  radioButton: {
    "& .MuiTypography-root": {
      marginLeft: "10px",
    },
  },
  formControl: {
    width: "100%",
    marginTop: "5px",
  },
  Select: {
    marginBottom: "50px",
  },
  warningNote: {
    "& .MuiAlert-icon": {
      display: "none",
    },
  },
  button: {
    marginTop: "50px",
  },
  buttOutlined: {
    color: theme.palette.buttonColor.main,
    borderColor: theme.palette.buttonColor.main,
    "&:hover": {
      border: `1px solid ${theme.palette.buttonColor.main}`,
    },
  },
}));

//sandbox view main
const SandboxDetailsView = (props) => {
  const classes = useStyles();
  const mdEditor = React.useRef(null);
  hotjar.initialize(2281731, 6);
  hotjar.identify(localStorage.userName, { userProperty: localStorage.userName});
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [overview, setOverview] = useState('');
  const [nodeDetails, setNodeDetails] = useState('');
  const [interfaceDetails, setInterfaceDetails] = useState('');
  const [accessDetails, setAccessDetails] = useState('');
  const [clusteripDetails, setClusteripDetails] = useState('');
  const dispatch = useDispatch();
    const history = useHistory();
    const inputProps = {
        disableAnimation: true,
        shrink: false
      };  

  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
      admin_sandboxes_id: null,
      overview: "",
      node_details: "",
      interface_details: "",
      access_details: "",
      clusterip_details: "",
      access_device: "",
      show_sandbox_details: false,
      ip_details_access: "",
      show_ip_details_access: false
              },
    onSubmit: (values) => {
      let payload = {
        admin_sandboxes_id: values.admin_sandboxes_id, 
        overview: overview,
        node_details: nodeDetails, 
        interface_details: interfaceDetails,
        access_details: accessDetails,
        clusterip_details: clusteripDetails,
        access_device: values.access_device,
        show_sandbox_details: values.show_sandbox_details,
        ip_details_access: values.ip_details_access,
        show_ip_details_access: values.show_ip_details_access,
      };
    },
  });

 //mark down editor 
 const handleEditorChange = ({ html, text }) => {
  const newValue = text.replace(/\d/g, "");
  //console.log(newValue);
  setOverview(newValue);
};
const handleNodeDetailsChange = ({ html, text }) => {
  const newValue = text.replace(/\d/g, "");
  //console.log(newValue);
  setNodeDetails(newValue);
};
const handleInterfaceDetailsChange = ({ html, text }) => {
  const newValue = text.replace(/\d/g, "");
  //console.log(newValue);
  setInterfaceDetails(newValue);
};
const handleAccessDetailsChange = ({ html, text }) => {
  const newValue = text.replace(/\d/g, "");
  //console.log(newValue);
  setAccessDetails(newValue);
};
const handleClusteripDetailsChange = ({ html, text }) => {
  const newValue = text.replace(/\d/g, "");
  //console.log(newValue);
  setClusteripDetails(newValue);
};
  // Initialize a markdown parser
  const mdParser = new MarkdownIt(/* Markdown-it options */);

  let data = [];

  const renderSanboxView = async () => {
    let sanboxView = CMSAPIWithToken()
      .get("/poc/admin_sandboxes_details/")
      .then((resp) => resp.data.results)
      .then((resp) => {
        resp.forEach((item) => {
          if(props.user == item.id) { 
          formik.setFieldValue("admin_sandboxes_id", item?.admin_sandboxes_id);
          setOverview(item.overview);
          setNodeDetails(item.node_details);
          setInterfaceDetails(item.interface_details);
          setAccessDetails(item.access_details);
          setClusteripDetails(item.clusterip_details);
          formik.setFieldValue("access_device", item?.access_device);
          formik.setFieldValue("show_sandbox_details", item?.show_sandbox_details);
          formik.setFieldValue("ip_details_access", item?.ip_details_access);
          formik.setFieldValue("show_ip_details_access", item?.show_ip_details_access);
        }
        });
      })      
      .catch((err) => {
        let message = err.response.data.message;
        // setRows([]);
        // setIsLoading(false);
        setShowNotification(true);
        setNotificationType("error");
        setNotificationMessage("Failed to retrieve the data");
      });

      

      //setUserDetails({ ...formik.userDetails, ...data });
  };

  useEffect(() => {
    renderSanboxView();
  }, []);

  //checkbox
  
    const [state, setState] = React.useState({
      checkedA: true,
      checkedB: true,
      checkedF: true,
      checkedG: true,
    });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div>
        <form
            onSubmit={formik.handleSubmit}
            className={classes.formContainer}
          >
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.gridContainer}
      >
        <Grid item xs={12} md={5}>
          <Typography variant="h4">Sandbox Details</Typography>
          
        </Grid>

        <Grid container className={classes.container}>
        <GridItem md={12} >
                  {formik.errors.admin_sandboxes_id && formik.touched.admin_sandboxes_id ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Sandboxes Id"
                      name="admin_sandboxes_id"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.admin_sandboxes_iditle}
                      onChange={formik.handleChange}
                      helperText={formik.errors.admin_sandboxes_id}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Sandboxes Id"
                      name="admin_sandboxes_id"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.admin_sandboxes_id}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>
                
                <GridItem md={12}>
                <p>Overview: </p>
                  <Editor
                    ref={mdEditor}
                    value={overview}
                    style={{
                      height: "500px"
                    }}
                    onChange={handleEditorChange}
                    renderHTML={text => <ReactMarkdown source={text} />}
                  />
                </GridItem>
                

                <GridItem md={12}>
                  <p>Node Details : </p>
                <Editor
                    ref={mdEditor}
                    value={nodeDetails}
                    
                    style={{
                      height: "500px"
                    }}
                    onChange={handleNodeDetailsChange}
                    renderHTML={text => <ReactMarkdown source={text} />}
                  />
                </GridItem>

                <GridItem md={12}>
                <p>Interface Details : </p>
                <Editor
                    ref={mdEditor}
                    value={interfaceDetails}
                    
                    style={{
                      height: "500px"
                    }}
                    onChange={handleInterfaceDetailsChange}
                    renderHTML={text => <ReactMarkdown source={text} />}
                  />
                </GridItem>

                <GridItem md={12}>
                <p>Access Details : </p>
                <Editor
                    ref={mdEditor}
                    value={accessDetails}
                    
                    style={{
                      height: "500px"
                    }}
                    onChange={handleAccessDetailsChange}
                    renderHTML={text => <ReactMarkdown source={text} />}
                  />
                </GridItem>

                <GridItem md={12}>
                <p>Clusterip Details : </p>
                <Editor
                    ref={mdEditor}
                    value={clusteripDetails}
                    
                    style={{
                      height: "500px"
                    }}
                    onChange={handleClusteripDetailsChange}
                    renderHTML={text => <ReactMarkdown source={text} />}
                  />
                </GridItem>

                <GridItem md={12}>
                  <TextField
                    type="text"
                    variant="filled"
                    label="Access Device"
                    name="access_device"
                    fullWidth
                    InputLabelProps={inputProps}
                    value={formik.values.access_device}
                    onChange={formik.handleChange}
                  />
                </GridItem>

                
                <GridItem md={12}>
                <Grid item xs={12} sm={12} md={12}>
                   <span>Show Sandbox Details</span>
                     <FormControlLabel
                     control={<Checkbox checked={formik.values.show_sandbox_details} />} 
                     name="show_sandbox_details"  
                     onChange={formik.handleChange}     
                        />          
                  </Grid>
                </GridItem>

                <GridItem md={12}>
                  <TextField
                    type="text"
                    variant="filled"
                    label="IP Details Access"
                    name="ip_details_access"
                    fullWidth
                    InputLabelProps={inputProps}
                    value={formik.values.ip_details_access}
                    onChange={formik.handleChange}
                  />
                </GridItem>

                <GridItem md={9}>
                <Grid item xs={12} sm={12} md={12}>
                <span>Show IP Details Access</span>
                     <FormControlLabel
                     control={<Checkbox checked={formik.values.show_ip_details_access} />} 
                     name="show_ip_details_access"  
                     onChange={formik.handleChange}     
                        /> 
                  </Grid>
                </GridItem>


              </Grid>

      </Grid>
      </form>       
    </div>
  );
};

export default SandboxDetailsView;
