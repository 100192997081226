import React, { useState } from "react";
import { BrowserRouter, Route, NavLink } from "react-router-dom";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import {
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";

// Images
// import ExperienceCenterUpgradeImage from "assets/criterion_networks/img/ExperienceCenterUpgrade.png";
import experiencecenter from "assets/criterion_networks/img/experiencecenter.png";
import { getRouteByName } from "../../utils/utilfunction";
import HeaderImage from "assets/img/w1.png";
import ArrowImage from "assets/img/arrow-right.png";
import Drawer from "@material-ui/core/Drawer";
import UpgradeSubscription from "views/Dashboard/UpgradeSubscription";
import { ReactComponent as ExperienceSVG } from "assets/criterion_networks/svg/Experience center.svg";
import SvgComponent from "utils/SvgComponent";
import { ReactComponent as svg5 } from "assets/criterion_networks/svg/05.svg";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import { Api } from "config/api";
import Chatbot from "./chatbot/Chatbot";

const handleClickHelp = () => {
  SDCloudBackendAPIWithToken()
    .get(Api.getSSO)
    .then((sso) => window.open(sso.data.urls, "_blank"));
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "20px",
    width: "100%",
  },
  title: {
    color: theme.palette.secondary.main,
    marginTop: "10px",
    textAlign: "left",
  },
  subtitle: {
    marginBottom: "20px",
    textAlign: "left",
  },
  content: {
    textAlign: "left",
  },
  drawerPaper: {
    width: "30%",
  },
  btnColor: {
    background: theme.palette.buttonColor.main,
    borderRadius: "3px",
    cursor: "pointer",
  },
  font: {
    fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    "& li": {
      fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    },
    "& h3": {
      fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    },
    "& p": {
      fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    },
    cursor: "pointer",
    textDecoration: "none",
  },
  ClsOpacity: {
    opacity: "0.5",
  },
  arrow: {
    marginBottom: "1%",
    marginLeft: "1px",
  },
}));

const ExperienceCenterUpgrade = (props) => {
  const classes = useStyles();
  //console.log("props", props);
  let heading = props && props.page == "showcases" ? "Showcases" : "Sandboxes";

  const [upgrade, setUpgrade] = useState("");
  const [showRightDrawer, toggleRightDrawer] = useState(false);

  let sections = [
    {
      name: "Experience Center - Showcases",
      id: "sales_demo",
      tempId: "showcases",
      path: `${getRouteByName("Experience")}?tab=sales`,
      Headerimg: HeaderImage,
      Arrowimg: ArrowImage,
      ScreenName: "Showcases",
      Upgrade: "Upgrade Experience Center - Showcases",
    },
    {
      name: "Experience Center - Sandboxes",
      id: "poc",
      tempId: "sandboxes",
      path: `${getRouteByName("Experience")}?tab=poc`,
      Headerimg: HeaderImage,
      Arrowimg: ArrowImage,
      ScreenName: "Sandboxes",
      Upgrade: "Upgrade Experience Center - Sandboxes",
    },
  ];

  // const handleClickCustomerSupport = (value) => {
  //   setUpgrade(value);
  //   toggleRightDrawer(!showRightDrawer);
  // };

  const generateDrawerComponent = (value) => {
    return (
      <UpgradeSubscription
        toggleDrawer={toggleRightDrawer}
        expanded="support"
        upgradetype={value}
      />
    );
  };

  return (
    <div className={classes.root}>
      {/* <Grid container>
        <Grid item sm={12} md={6} lg={6} className={classes.content}>
          <Typography variant="h3" className={classes.title}>
            Criterion SDCloud®
          </Typography>
          <Typography variant="h1" className={classes.subtitle}>
            Experience Center
          </Typography>
          <Typography variant="body1" className={classes.description}>
            Sell faster, sell smarter with environments to showcase & sell new
            services
          </Typography>
          <List>
            <ListItem>
              <ListItemText>
                1. Extensive sales libraries to showcase technologies
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                2. 1-click environments to streamline demos
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                3. Customizable UI, branding, built-in collaboration tools
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <Grid container justify="flex-end">
            <img src={ExperienceCenterUpgradeImage} className={classes.image} />
          </Grid>
        </Grid>
      </Grid> */}
      <Grid container spacing={3} className="bg-gray pt-2">
        <Grid item xs={12} sm={8} className="ml-5 pt-0">
          <div class={`design-card ${classes.font}`}>
            <h3 className="design-title">Experience center - {heading}</h3>
            <p className="notavailale" style={{ marginTop: "10px" }}>
              Not available in your plan
            </p>
          </div>
        </Grid>
        <Grid item xs={12} sm={4} className="mr-3 ">
          {heading === "Showcases" ? (
            <button
              className={`button upgrade-button ${classes.btnColor +
                " " +
                classes.font}`}
              style={{ cursor: "pointer" }}
              onClick={handleClickHelp}
            >
              Upgrade your subscription
            </button>
          ) : (
            <button
              className={`button upgrade-button ${classes.btnColor +
                " " +
                classes.font}`}
              style={{ cursor: "pointer" }}
              onClick={handleClickHelp}
            >
              Upgrade your subscription
            </button>
          )}
        </Grid>
      </Grid>

      <Grid container spacing={3} className="bg-gray pt-2">
        <Grid item xs={12} sm={6} className="pl-8 pt-0">
          <>
            <div class="design-card">
              {heading === "Showcases" ? (
                <>
                  <p className={`sales-title ${classes.font}`}>Sales Demo</p>
                  <ul className={`design-list ${classes.font}`}>
                    <li>Understand the SD-WAN use-cases through sales demo</li>
                    <li>On-Demand Demo access</li>
                    <li>
                      Self paced sales demos to quickly showcase use-cases
                    </li>
                    <li>Showcase demo in single page</li>
                  </ul>
                  <a
                    className={`sub-title-link ${classes.btnStyle +
                      " " +
                      classes.font}`}
                    onClick={handleClickHelp}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Please visit our help center and submit a request to upgrade
                    your subscription
                    <SvgComponent
                      className={classes.arrow}
                      type="stroke"
                      svgSource={svg5}
                    />
                    {/* <img src={arrowright} class="arrow" /> */}
                  </a>
                </>
              ) : (
                <>
                  <p className={`sales-title ${classes.font}`}>PoC Library</p>
                  <ul className={`design-list ${classes.font}`}>
                    <li>Experiment with self service deployments</li>
                    <li>
                      Prerequisite: Learning Labs - Primer Series and Scripted
                      Demos
                    </li>
                    <li>
                      Tests your workflow with realtime cloud orchestration
                    </li>
                    <li>Develop & Integrate with multi-vendor solutions</li>
                  </ul>
                  <a
                    className={`sub-title-link ${classes.btnStyle +
                      " " +
                      classes.font}`}
                    onClick={handleClickHelp}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Please visit our help center and submit a request to upgrade
                    your subscription
                    <SvgComponent
                      className={classes.arrow}
                      type="stroke"
                      svgSource={svg5}
                    />
                    {/* <img src={arrowright} class="arrow" /> */}
                  </a>{" "}
                </>
              )}
              {/* <a href="" class="sub-title-link">Please contact to sales to learn more<img src={arrowright} class="arrow" /></a>             */}
            </div>{" "}
          </>
        </Grid>
        <Grid item xs={12} sm={6} className="mr-3 ">
          <SvgComponent
            className={classes.ClsOpacity}
            type="fill"
            svgSource={ExperienceSVG}
          />
          {/* <img src={experiencecenter} class="flex" /> */}
        </Grid>
      </Grid>

      <Drawer
        anchor={"right"}
        open={showRightDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        PaperProps={{ id: "infiniteScrollNotification" }}
        onClose={() => toggleRightDrawer(!showRightDrawer)}
      >
        {generateDrawerComponent(upgrade)}
      </Drawer>
      <Chatbot/>
    </div>
  );
};

export default ExperienceCenterUpgrade;
