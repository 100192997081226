import React from "react";
import { makeStyles } from "@material-ui/styles";
import Icon from "@material-ui/core/Icon";
import theme from "theme";

const useStyles = makeStyles({
    imageIcon: {
        display: "flex",
        height: "inherit",
        width: "inherit"
    },
    iconRoot: {
        overflow: "unset",
        textAlign: "center",
        "& svg": {
            fill: "#fff",
            "& circle": {
                stroke: "#OF1A2B"
            },
            "& rect": {
                fill: theme.palette.secondary.main
            }
        }
    },
    svgPathFill: {
        "& svg path": {
            fill: "#OF1A2B"
        }
    },
    svgFillDefault: {
        "& svg path": {
            fill: theme.palette.secondary.main
        },
        "& svg circle": {
            fill: "#OF1A2B"
        }, 
        "& svg rect:nth-child(5)": {
            fill: theme.palette.secondary.main
        }
    },
    svgPathStroke: {
        "& svg path": {
            stroke: theme.palette.secondary.main
        },
        "& svg rect": {
            stroke: theme.palette.secondary.main
        }
    }
});

export default function SvgComponent(props) {
    const classes = useStyles();
    let classType = props.type === "fill" ? classes.svgPathFill + ' ' + classes.iconRoot + ' ' + props.className :
        props.type === "default" ? props.className + ' ' + classes.svgFillDefault : classes.svgPathStroke + ' ' + props.className;
    return (
        <Icon classes={{ root: classType }} onClick={props.onClick}>
            {props.isString ? (
                <img className={classes.imageIcon} alt="logo" src={props.svgSource} />
            ) : (
                <props.svgSource />
            )}
        </Icon>
    );
}