import React from "react";
import cx from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import ErrorPage from "views/Pages/ErrorPage";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import Loader from "react-loader-spinner";
import { LOADER_TYPE } from "global_constants/app_constants";
import routes from "routes.js";
import { SECONDARY_COLOR } from "global_constants/app_constants";
import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import { HIDE_NOTIFICATION } from "action_creators/actioncreator";
import { HIDE_ERROR_PAGE } from "action_creators/actioncreator";
import theme from "../theme";
import bgImg from "assets/criterion_networks/img/background-image.png";

var ps;
const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const Subtitle = localStorage.getItem("SubTitle")
    ? localStorage.getItem("SubTitle")
    : "Learning Center. Experience. Design Center";
  const { ...rest } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { util } = useSelector(state => state);
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [image, setImage] = React.useState(require("assets/img/sidebar-2.jpg"));
  const [color, setColor] = React.useState("blue");
  const [bgColor, setBgColor] = React.useState("info");
  // const [hasImage, setHasImage] = React.useState(true);
  // styles
  let logoimage = localStorage.getItem("BrandLogoInv");
  let logosmallimage = localStorage.getItem("BrandLogoSmall");

  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
    });
  // ref for main panel div
  const mainPanel = React.createRef();

  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    // listen to route change and reset error page.
    let historyUnlistener = history.listen((location, action) => {
      if (util.showErrorPage) {
        dispatch(HIDE_ERROR_PAGE());
      }
    });
    if (navigator.platform.indexOf("Win") > -1) {
      if (mainPanel && mainPanel.current && mainPanel.current != null) {
        ps = new PerfectScrollbar(mainPanel?.current, {
          suppressScrollX: true,
          suppressScrollY: false
        });
        document.body.style.overflow = "auto";
      }
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        if (ps) ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
      historyUnlistener();
    };
  });
  // functions for changeing the states from components
  // const handleImageClick = image => {
  //   setImage(image);
  // };
  // const handleColorClick = color => {
  //   setColor(color);
  // };
  // const handleBgColorClick = bgColor => {
  //   switch (bgColor) {
  //     case "white":
  //       setLogo(require("assets/img/logo.svg"));
  //       break;
  //     default:
  //       setLogo(require("assets/img/logo-white.svg"));
  //       break;
  //   }
  //   setBgColor(bgColor);
  // };
  // const handleFixedClick = () => {
  //   if (fixedClasses === "dropdown") {
  //     setFixedClasses("dropdown show");
  //   } else {
  //     setFixedClasses("dropdown");
  //   }
  // };
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getRoute = () => {
    return window.location.pathname !== "/full-screen-maps";
  };
  const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/") {
        return <Route path={prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  if (localStorage.getItem("isThemeSet")) {
    const secondarycolorcode = localStorage.getItem("isThemeSet");
    // setTimeout(() => {
      localStorage.removeItem("isThemeSet");
    // }, 250);
    const wrapper = React.createRef();
    return (
      <div className={classes.wrapper} ref={wrapper}>
        <div
          className={classes.fullPage}
          style={{
            backgroundImage: "url(" + bgImg + ")",
            width: "100%",
            height: "100%"
          }}
        >
          <Loader
            type={LOADER_TYPE}
            color={secondarycolorcode}
            width={50}
            height={50}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)"
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoFull={
          logoimage != null && logoimage.includes("assets") === false
            ? logoimage
            : require("assets/criterion_networks/img/brand-logo.png")
        }
        logo={
          logosmallimage != null && logosmallimage.includes("assets") === false
            ? logosmallimage
            : require("assets/criterion_networks/img/criterion-logo-mini.png")
        }
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        sidebarMinimize={sidebarMinimize.bind(this)}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          brandText={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {/* getRoute checks if the selected route is full screen map page route */}
        {getRoute() ? (
          <div className={classes.content}>
            {util.showLoader ? (
              <Loader
                type={LOADER_TYPE}
                color={theme.palette.secondary.main}
                width={50}
                height={50}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)"
                }}
              />
            ) : null}
            <div
              className={classes.container}
              style={{ display: util.showLoader ? "none" : "" }}
            >
              {window.location.pathname === "/dashboard" ? (
                <h4 className={classes.Subtitle}>{Subtitle}</h4>
              ) : (
                ""
              )}
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </div>
          </div>
        ) : (
          <div className={classes.map}>
            <Switch>
              {getRoutes(routes)}
              <Redirect from="/" to="/dashboard" />
            </Switch>
          </div>
        )}
        {/* {getRoute() ? <Footer fluid /> : null} */}
        <Snackbar
          place="tr"
          color={util.notificationColor}
          // icon={AddAlert}
          message={util.notificationMessage}
          open={util.showNotification}
          closeNotification={() => {
            dispatch(HIDE_NOTIFICATION());
          }}
          close
        />
        {/* <FixedPlugin
          handleImageClick={handleImageClick}
          handleColorClick={handleColorClick}
          handleBgColorClick={handleBgColorClick}
          color={color}
          bgColor={bgColor}
          bgImage={image}
          handleFixedClick={handleFixedClick}
          fixedClasses={fixedClasses}
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
        /> */}
      </div>
    </div>
  );
}
