// component for different service offering feature description on home page
import React from "react";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import CardBody from "components/Card/CardBody.js";
import { PRIMARY_COLOR } from "global_constants/app_constants";
import { SECONDARY_COLOR } from "global_constants/app_constants";
const useStyles = makeStyles(styles);

export default function ViewOffering(props) {
  const [list, setList] = React.useState([]);
  const classes = useStyles();
  let history = useHistory();

  React.useEffect(() => {
    setList(props.list);
  }, []);
  const handleBtnClick = () => {
    history.push(props.redirect);
  };

  if (props.offeringType !== "sales demo") {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ backgroundColor: "transparent", boxShadow: "none" }}>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={2} md={2}>
                  <GridContainer
                    justify="center"
                    alignItems="center"
                    style={{
                      paddingBottom: "10%",
                      paddingLeft: "5%",
                      height: "100%"
                    }}
                  >
                    <GridItem>
                      <img src={props.icon} />
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem xs={12} sm={10} md={10}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <h4
                        style={{
                          marginTop: "1%",
                          fontWeight: "bold",
                          color: PRIMARY_COLOR
                        }}
                      >
                        {props.name}
                      </h4>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer direction="row">
                        <GridItem xs={12} sm={6} md={6}>
                          <ul className="fa-ul">
                            {list.map(
                              (x, key) =>
                                key < Math.ceil(list.length / 2) && (
                                  <li
                                    key={key}
                                    style={{
                                      marginBottom:
                                        key === Math.ceil(list.length / 2) - 1
                                          ? "0"
                                          : "1%"
                                    }}
                                  >
                                    <span className="fa-li">
                                      <i
                                        style={{ color: SECONDARY_COLOR }}
                                        className="fas fa-check-square"
                                      ></i>
                                    </span>
                                    {x}
                                  </li>
                                )
                            )}
                          </ul>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <ul className="fa-ul">
                            {list.map(
                              (x, key) =>
                                key >= Math.ceil(list.length / 2) && (
                                  <li key={key} style={{ marginBottom: "1%" }}>
                                    <span className="fa-li">
                                      <i
                                        style={{ color: SECONDARY_COLOR }}
                                        className="fas fa-check-square"
                                      ></i>
                                    </span>
                                    {x}
                                  </li>
                                )
                            )}
                          </ul>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </GridItem>

                {props.button.length > 0 ? (
                  <GridItem xs={12}>
                    <GridContainer
                      justify="flex-end"
                      alignItems="flex-end"
                      style={{ height: "100%" }}
                    >
                      <GridItem>
                        {props.button.length > 0 && (
                          <Button
                            className={classes.marginRight}
                            color="secondary"
                            onClick={() => handleBtnClick()}
                          >
                            {props.button}
                            <KeyboardArrowRightIcon className={classes.icons} />
                          </Button>
                        )}
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                ) : null}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  } else {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ backgroundColor: "transparent", boxShadow: "none" }}>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={2} md={2}>
                  <GridContainer
                    justify="center"
                    alignItems="center"
                    style={{
                      paddingBottom: "10%",
                      paddingLeft: "5%",
                      height: "100%"
                    }}
                  >
                    <GridItem>
                      <img src={props.icon} />
                    </GridItem>
                  </GridContainer>
                </GridItem>

                <GridItem xs={12} sm={10} md={10}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <h4
                        style={{
                          marginTop: "1%",
                          fontWeight: "bold",
                          color: PRIMARY_COLOR
                        }}
                      >
                        {props.name}
                      </h4>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer direction="row">
                        <GridItem xs={12} sm={6} md={6}>
                          <ul className="fa-ul">
                            {list.map(
                              (x, key) =>
                                key < Math.ceil(list.length / 2) && (
                                  <li
                                    key={key}
                                    style={{
                                      marginBottom:
                                        key === Math.ceil(list.length / 2) - 1
                                          ? "0"
                                          : "1%"
                                    }}
                                  >
                                    <span className="fa-li">
                                      <i
                                        style={{ color: SECONDARY_COLOR }}
                                        className="fas fa-check-square"
                                      ></i>
                                    </span>
                                    {x}
                                  </li>
                                )
                            )}
                          </ul>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                          <ul className="fa-ul">
                            {list.map(
                              (x, key) =>
                                key >= Math.ceil(list.length / 2) && (
                                  <li key={key} style={{ marginBottom: "1%" }}>
                                    <span className="fa-li">
                                      <i
                                        style={{ color: SECONDARY_COLOR }}
                                        className="fas fa-check-square"
                                      ></i>
                                    </span>
                                    {x}
                                  </li>
                                )
                            )}
                          </ul>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </GridItem>

                {props.button.length > 0 ? (
                  <GridItem xs={12}>
                    <GridContainer
                      justify="flex-end"
                      alignItems="flex-end"
                      style={{ height: "100%" }}
                    >
                      <GridItem>
                        {props.button.length > 0 && (
                          <Button
                            className={classes.marginRight}
                            color="secondary"
                            onClick={() => handleBtnClick()}
                          >
                            {props.button}
                            <KeyboardArrowRightIcon className={classes.icons} />
                          </Button>
                        )}
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                ) : null}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
