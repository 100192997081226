import React, { Component, useEffect } from "react";
import { PropTypes } from "prop-types";
import Loader from "react-loader-spinner";
import { Card, Tooltip } from "@material-ui/core";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { PRIMARY_COLOR } from "global_constants/app_constants";
import { Api } from "config/api";
import "./Profile.scss";
import { makeStyles, Switch } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import PriorityHighRoundedIcon from "@material-ui/icons/PriorityHighRounded";
import { SECURITY_QUESTIONS } from "./utils/constants/constants_profile";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import theme from "../../theme";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles(styles);

const customStyles = (theme) => ({
  containerStyles: {
    padding: "0.9375rem 70px",
  },
  formInputStyles: {
    margin: "10px auto 10px auto",
    [theme.breakpoints.down("sm")]: {
      margin: "15px",
    },
  },
  inputStyles: {
    padding: "7px 15px 7px",
    height: "36px",
  },
  inputUnderlineStyles: {
    borderBottom: "none",
  },
  inputLabelStyles: {
    position: "relative",
  },
  cardStyles: {
    color: "rgba(0, 0, 0, 0.87)",
    width: "100%",
    border: "0",
    display: "flex",
    position: "relative",
    fontSize: ".875rem",
    minWidth: "0",
    wordWrap: "break-word",
    background: "#FFF",
    marginTop: "30px",
    borderRadius: "6px",
    marginBottom: "30px",
    flexDirection: "column",
  },
  cardHeaderStyles: {
    padding: "0.9375rem 65px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative",
    "& h3": {
      fontFamily: theme.palette.fontList.selectedFont,
    },
  },
  cardBodyStyles: {
    padding: "0.9375rem 65px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative",
  },
  cardFooterStyles: {
    padding: "0.9375rem 50px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative",
  },
  buttonStyles: {
    background: PRIMARY_COLOR,
    color: "white",
    width: "100%",
    minHeight: "20px",
    padding: "15px 10px 15px",
    fontSize: "1rem",
    textTransform: "none",
  },
  btnStyle: {
    background: `${theme.palette.buttonColor.main} !important`,
  },
});

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 60,
    height: 34,
    padding: 0,
    marginTop: 6,
    display: "flex",
    boxShadow: "inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1)",
    borderRadius: "24px",
  },
  switchBase: {
    padding: 2,
    paddingLeft: 4,
    color: "red",
    "&$checked": {
      transform: "translateX(24px)",
      backgroundColor: "transparent",
    },
  },
  thumb: {
    width: 28,
    height: 28,
    boxShadow: "none",
  },
  track: {
    borderRadius: 32,
    opacity: 1,
    backgroundColor: "#D8D8D8",
  },
  checked: {},
}))(Switch);

const MENU = SECURITY_QUESTIONS;

class PlatformNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formCardOpacity: "",
      showNotification: false,
      notificationType: "",
      notificationMessage: "",
      isLoaded: false,
    };
  }

  getEmailNotifications = () => {
    SDCloudBackendAPIWithToken()
      .get(Api.getEmailNotifications)
      .then((res) => {
        res.data.data.map((item) => {
          this.setState({ [item.name]: item.enabled });
        });
        this.setState({ isLoaded: true });
      })
      .catch((err) => {
        const payload = {
          message: err?.response?.data?.message || "unknown error occured",
          color: "danger",
          error: err,
          showErrorPage: true,
        };
        this.setState({ isLoaded: true });
      });
  };

  //handle toggle switch for notification
  toggleChanged = (event) => {
    //console.log("event", event.target.checked);
    //console.log("name", event.target.name);
    if (event.target.checked) {
      let payload = {
        type: "email",
        name: event.target.name,
        action: "enable",
      };
      SDCloudBackendAPIWithToken()
        .put(Api.updateNotification, payload)
        .then((res) => {
          this.setState({ formCardOpacity: 1 });
          this.setState({ [payload.name]: true });
          const successMessage = {
            message: `Subscription added for ${payload.name.replace(
              "_",
              "\xa0"
            )}`,
            color: "success",
          };
          this.setState({
            showNotification: true,
            notificationType: "success",
            notificationMessage: successMessage.message,
          });
        })
        .catch((err) => {
          //console.log(err);
          const payload = {
            message: "Something went wrong. Please try again.",
            color: "danger",
            error: err,
          };
          this.setState({
            showNotification: true,
            notificationType: "error",
            notificationMessage: payload.message,
          });
          this.setState({ formCardOpacity: 1 });
        });
    } else {
      let payload = {
        type: "email",
        name: event.target.name,
        action: "disable",
      };
      SDCloudBackendAPIWithToken()
        .put(Api.updateNotification, payload)
        .then((res) => {
          this.setState({ formCardOpacity: 1 });
          this.setState({ [payload.name]: false });
          const successMessage = {
            message: `Subscription removed for ${payload.name.replace(
              "_",
              "\xa0"
            )}`,
            color: "success",
          };
          this.setState({
            showNotification: true,
            notificationType: "warning",
            notificationMessage: successMessage.message,
          });
          this.setState({ formCardOpacity: 1 });
        })
        .catch((err) => {
          //console.log(err);
          const payload = {
            message: "Something went wrong. Please try again.",
            color: "danger",
            error: err,
          };
          this.setState({
            showNotification: true,
            notificationType: "error",
            notificationMessage: payload.message,
          });
          this.setState({ formCardOpacity: 1 });
        });
    }
  };
  componentDidMount() {
    this.getEmailNotifications();
  }

  render() {
    const { classes } = this.props;
    if (this.state.isLoaded) {
      return (
        <GridContainer className={classes.containerStyles}>
          <Card className={classes.cardStyles} elevation={0}>
            <CardHeader className={classes.cardHeaderStyles}>
              <div>
                <h3 style={{ fontSize: "24px" }}>
                  <b>Subscribe to Email Notifications</b>
                </h3>
                {this.props.notes ? (
                  <p className="noteStyles">{this.props.notes}</p>
                ) : null}
              </div>
              <div>
                <p
                  style={{
                    fontSize: "15px",
                    color: "#606060",
                    fontWeight: "normal",
                  }}
                >
                  Turn Email notifications on or off
                </p>
              </div>
            </CardHeader>

            <CardBody
              className={classes.cardBodyStyles}
              style={{ opacity: this.state.formCardOpacity }}
            >
              <GridContainer style={{ marginTop: "1rem" }}>
                <GridItem xs={12} sm={9} md={9} lg={9}>
                  <GridContainer>
                    <GridItem xs={13} sm={5} md={5} lg={5}>
                      <Typography
                        style={{
                          color: "#606060",
                          fontWeight: 450,
                          fontSize: "17px",
                          margin: "10px auto 20px auto",
                        }}
                        variant="body1"
                      >
                        Critical Notifications
                        <Tooltip
                          title="Checkout User Guides in our help portal to know more about Critical Notifications"
                          arrow="true"
                          placement="top"
                        >
                          <InfoIcon
                            style={{
                              verticalAlign: "bottom",
                              marginLeft: "5px",
                            }}
                          />
                        </Tooltip>
                      </Typography>
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3} lg={3}>
                      <AntSwitch
                        name="Critical_Notifications"
                        defaultChecked = {this.state.Critical_Notifications}
                        onChange={this.toggleChanged} 
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={9} md={9} lg={9}>
                  <GridContainer>
                    <GridItem xs={13} sm={5} md={5} lg={5}>
                      <Typography
                        style={{
                          color: "#606060",
                          fontWeight: 450,
                          fontSize: "17px",
                          margin: "10px auto 20px auto",
                        }}
                        variant="body1"
                      >
                        Other Notifications
                        <Tooltip
                          title="Checkout User Guides in our help portal to know more about Other Notifications"
                          arrow="true"
                          placement="bottom"
                        >
                          <InfoIcon
                            style={{
                              verticalAlign: "bottom",
                              marginLeft: "5px",
                            }}
                          />
                        </Tooltip>
                      </Typography>
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3} lg={3}>
                      <AntSwitch
                        name="Other_Notifications"
                        onChange={this.toggleChanged}
                        checked={this.state.Other_Notifications}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} md={12}>
                  {this.state.showNotification &&
                  this.state.notificationType == "warning" ? (
                    <Alert
                      onClose={() => this.setState({ showNotification: false })}
                      severity={this.state.notificationType}
                      iconMapping={{
                        warning: (
                          <PriorityHighRoundedIcon
                            fontSize="inherit"
                            style={{ color: "#D99151" }}
                          />
                        ),
                      }}
                      style={{ width: "70%", borderRadius: "0.5rem" }}
                    >
                      <Typography
                        style={{
                          color: "#606060",
                          fontWeight: 450,
                          fontSize: "15px",
                        }}
                      >
                        {this.state.notificationMessage}
                      </Typography>
                    </Alert>
                  ) : null}
                  {this.state.showNotification &&
                  this.state.notificationType != "warning" ? (
                    <Alert
                      onClose={() => this.setState({ showNotification: false })}
                      severity={this.state.notificationType}
                      variant="standard"
                      style={{ width: "70%", borderRadius: "0.5rem" }}
                    >
                      <Typography
                        style={{
                          color: "#606060",
                          fontWeight: 450,
                          fontSize: "15px",
                        }}
                      >
                        {this.state.notificationMessage}
                      </Typography>
                    </Alert>
                  ) : null}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridContainer>
      );
    } else {
      return (
        <div>
          <GridContainer justify="center">
            <Loader
              type="ThreeDots"
              color={theme.palette.secondary.main}
              height={500}
              width={50}
              visible={true}
            />
          </GridContainer>
        </div>
      );
    }
  }
}

PlatformNotification.propTypes = {
  classes: PropTypes.object.isRequired,
  notes: PropTypes.string.isRequired,
};

export default withRouter(
  withStyles(customStyles, useStyles)(PlatformNotification)
);
