import React, { useState, useEffect } from "react";

// Material UI Components
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
// import SvgtoIcon from "utils/SvgtoIcon";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import arrowIcon from "assets/img/up-arrow.png";
import Pagination from "@material-ui/lab/Pagination";

// Other Components
import {
  useTable,
  usePagination,
  useRowSelect,
  useGlobalFilter,
  useAsyncDebounce,
  // useGroupBy,
  useExpanded,
  useSortBy,
} from "react-table";
import { v4 as uuidv4 } from "uuid";
import {SDCloudBackendAPIWithToken} from "../../apis/backendAPI";
import {useFormik} from "formik";
import RightDrawer from "../Usermanagement/components/RightDrawer";
import AddUser from "../Usermanagement/components/AddUser";
import UserDetails from "../Usermanagement/components/UserDetails";
// import TableHeader from "./TableHeader";

// Styling
const useStyles = makeStyles((theme) => ({
  container: {
    padding: "25px",
  },
  pagesDropdown: {
    backgroundColor: "#ffffff",
    color: theme.palette.grey[700],
    border: "none",
    padding: "5px",
    "& .MuiOutlinedInput-input": {
      padding: "0 25px 0 0",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  paginationToolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
  },
  paginationNumbers: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& p, div": {
      margin: "0 10px",
    },
  },
  numberButton: {
    minWidth: "0px",
    padding: "0px",
    "& .MuiButton-label": {
      width: "35px",
      height: "24px",
      backgroundColor: "#ffffff",
      color: theme.palette.grey["600"],
    },
    "& .MuiButton-text": {
      padding: "0 1px",
    },
  },
  table: {
    root: {
      "& .MuiTableCell-root": {
        borderBottom: "5px solid green",
      },
      "& .MuiTableCell-body": {
        color: "black",
      },
      "& ,MuiFormControl-root": {
        marginTop: "0",
      },
    },
  },
  previousButton: {
    color: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  nextButton: {
    color: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  active: {
    "& .MuiButton-label": {
      backgroundColor: "#3868C2",
      color: "#fff",
    },
  },
  font: {
    fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    "& .MuiPaginationItem-page": {
      fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    },
    "& .MuiPaginationItem-page:hover": {
      backgroundColor: "unset !important",
    },
    "& .MuiPagination-ul li:first-child .MuiPaginationItem-page:after": {
      fontFamily: `${theme.palette.fontList.selectedFont} !important`,
      color: theme.palette.secondary.main,
    },
    "& .MuiPagination-ul li:last-child .MuiPaginationItem-page:after": {
      fontFamily: `${theme.palette.fontList.selectedFont} !important`,
      color: theme.palette.secondary.main,
    },
  },
  asc: {
    marginRight: "5px",
  },
  desc: {
    marginRight: "5px",
    // transform: "rotate(180deg)"
  },
  arrowRight: {
    marginRight: "5px",
    // transform: "rotate(90deg)"
  },
  tablehead: {
    fontWeight: "bold !important",
    padding: "5px",
  },
  tablebody: {
    padding: "5px",
  },
  sortasc: {
    marginLeft: "11px",
  },
  sortdesc: {
    marginLeft: "11px",
    transform: "rotate(-180deg)",
  },
}));

const ReportCustomTable = ({
  columns,
  data,
  tableTitle,
  hiddenColumns,
  table,
  count,
  renderrow,
}) => {
  const classes = useStyles();
  const [initialState, setinitialState] = React.useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [userDetails, setUserDetails] = useState({
    username: "",
    email: "",
    privilege: "",
    permission_profile: "",
    firstName: "",
    lastName: "",
  });
  const renderDrawerComponent = () => {
    switch (currentScreen) {
      case "default":
        return <UserDetails user={currentUser} />;
      case "User Details":
        return <UserDetails user={currentUser} />;
    }
  };
  const formik = useFormik({
    initialValues: userDetails,
    onSubmit: (values) => {
      let payload = {
        username: values.username,
        email: values.email,
        privilege: values.privilege,
        permission_profile_name: values.permission_profile,
        first_name: values.firstName,
        last_name: values.lastName,
      };

      SDCloudBackendAPIWithToken()
          .post("/update_user", payload)
          .then((resp) => resp.data)
          .then((data) => {
            setShowNotification(true);
            setNotificationType("success");
            setNotificationMessage("User updated successfully");
          })
          .catch((err) => {
            let message = err.response.data.message;
            setShowNotification(true);
            setNotificationType("error");
            setNotificationMessage(message);
          });
    },
    enableReinitialize: true,
  });
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentScreen, setCurrentScreen] = useState();
  const [currentUser, setCurrentUser] = useState("");
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    gotoPage,
    state: { pageIndex, pageSize, selectedRowIds },
    toggleAllRowsExpanded,
    isAllRowsExpanded,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 40,
        hiddenColumns: hiddenColumns ? hiddenColumns : [],
        // groupBy: [columns[0].accessor],
        isExpanded: true,
        // sortBy: [columns[5].accessor]
      },
    },
    // useGroupBy,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  useEffect(() => {
    if (initialState) {
      toggleAllRowsExpanded(true);
      setinitialState(false);
    }
  }, [isAllRowsExpanded]);

  const handlePage = (event, newPage) => {
    document.getElementById("table-head").scrollIntoView();
    setinitialState(true);
    renderrow(newPage);
    gotoPage(newPage - 1);
  };
  return (
    <div>
      <Paper elevation={0}>
        <TableContainer className={classes.container}>
          <Typography variant="h4">{tableTitle}</Typography>
          <Table
            className={classes.table}
            aria-label="customized table"
            {...getTableProps()}
            id={table}
          >
            <TableHead id="table-head">
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell
                      {...column.getHeaderProps(
                          // column.getSortByToggleProps()                      Disabling the sort
                      )}
                      className={classes.tablehead}
                    >
                      {column.render("Header")}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <span>
                            <img
                              className={classes.sortdesc}
                              src={arrowIcon}
                              alt="down"
                            />
                          </span>
                        ) : (
                          <span>
                            <img
                              className={classes.sortasc}
                              src={arrowIcon}
                              alt="up"
                            />
                          </span>
                        )
                      ) : null}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell
                          {...cell.getCellProps({
                            onClick: ()=> {
                              if(cell.column.id == "userName"){
                                        setCurrentUser(cell.value);
                                        setOpenDrawer(true);
                                        setCurrentScreen("User Details");
                              }
                            },
                            style: {
                              minWidth: cell.column.minWidth,
                              width: cell.column.width,
                              maxWidth: cell.column.maxWidth,
                              textOverflow: cell.column.textOverflow,
                              textDecoration: cell.column.textDecoration,
                              cursor: cell.column.cursor
                            }
                          })}
                          className={classes.tablebody}
                        >
                          {  cell.isPlaceholder ? null : (
                            cell.render("Cell")
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <RightDrawer
          open={openDrawer}
          onClose={setOpenDrawer}
          title={currentScreen}
      >
        {renderDrawerComponent()}
      </RightDrawer>
      <div className={classes.paginationToolbar} style={{ float: "right" }}>
        <Pagination
          className={`table-pagination ${classes.font}`}
          count={count}
          variant="outlined"
          onChange={handlePage}
        />
      </div>
    </div>
  );
};

export default ReportCustomTable;
