import React from "react";
import "./LabelTabs.scss";

const LabelTabs = ({ activeTab, tabs, onActiveTab }) => {
  return (
    <div className="label-tabs">
      {tabs.map((tab, i) => (
        <div
          key={i}
          className={`tab ${activeTab === tab.value && "active"}`}
          onClick={() => onActiveTab(tab)}
        >
          {tab.label}
        </div>
      ))}
    </div>
  );
};

export default LabelTabs;
