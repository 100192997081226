import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { styled } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useFormikContext } from "formik";

import {
  FormControl,
  TextField,
  InputLabel,
  FilledInput,
  InputAdornment,
  FormControlLabel,
  Icon,
  Button,
} from "@material-ui/core";
import {
  SDCloudBackendAPIWithToken,
  SDCloudCustomizeAPIWithToken,
} from "apis/backendAPI";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { DropzoneArea } from "material-ui-dropzone";
import NodeImageUploader from "views/ServiceLifeCycle/components/NodeImageUploader";
import { RADIO_OPTIONS } from "views/ServiceLifeCycle/utils/constants/constants_createnode";
import { Api } from "../../../config/api";
import queryString from "query-string";
import Select from "@material-ui/core/Select";
import {
  getServerDetails,
  NODE_URLS,
  ENV_NAME
} from "views/ServiceLifeCycle/utils/constants/urls";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

// Styling
const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "50px",
  },
  item: {
    textAlign: "end",
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: `${theme.palette.buttonColor.main} !important`,
    color: "white",
    border: "none",
    height: "35px",
    width: "80px",
    fontSize: "0.875rem",
    fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    cursor: "pointer",
  },
  filter: {
    marginTop: "2%",
    padding: "20px 0px 50px 50px",
    background: "#FFFFFF",
    border: "1px solid #F0F0F0",
    boxSizing: "border-box",
    boxShadow: "0px 4px 12px rgb(0 0 0 / 4%)",
    position: "relative",
    width: "80%",
  },
  buttongrid: {
    marginTop: "20px",
  },
  btnStyle: {
    background: `#FFFFFF`,
    height: "40px",
    width: "200px",
    fontSize: "0.875rem",
    fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    cursor: "pointer",
  },
  filterButton: {
    padding: "8px 50px 0px 0px",
  },
  icon: {
    marginTop: "30px",
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  table: {
    minWidth: 700,
  },
}));

const AddNode = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [value, setValue] = React.useState("learnings");
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setformData] = useState(null);
  const [customFileUrl, setCustomFileUrl] = useState("");
  const [uploadOnProgress, setUploadOnProgress] = useState(false);
  const [nodeVersionList, setNodeVersionList] = useState([]);
  const [nodeIcon, setNodeIcon] = useState("");
  const [nodeData, setNodeData] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  const [imageName, setImageName] = useState("");
  const [allowDelete, setAllowDelete] = useState(true);
  let queryParams = queryString.parse(window.location.search);
  var mode = queryParams.mode;
  var node_id = queryParams.id;
  var disabled = "";
  if (mode == "add") {
    var node_name = localStorage.getItem("node_name");
    var node_id = localStorage.getItem("node_id");
    var node_version = localStorage.getItem("node_version");
  } else {
    var node_id = queryParams.id;
    var node_name = queryParams.name;
    var node_version = queryParams.version;
    var disabled = "disabled";
  }
  var fileData = new FormData();
  var activeStep = props.activeStep;
  const rows = [{ nodeIcon }, { imageName }, { imageUrl }];
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      node_id: node_id,
      node_name: node_name,
      node_version: node_version,
      image_url: "",
      icon_url: "",
    },
    onSubmit: (values) => {
      var node_identity = values.node_name;
      node_identity = node_identity.replaceAll(" ", "");
      node_identity = node_identity.replaceAll("_", "");
      localStorage.setItem("node_id", node_identity);
      localStorage.setItem("node_version", values.node_version);
      localStorage.setItem("node_name", values.node_name);
      if(nodeIcon == ""){
        let message = "Please select/browse the node icon to proceed to next step";
        const payload = {
          message: message,
          color: "danger",
        };
        payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
        return false;
      }
      if (imageUrl == "") {
        let message = "Please upload the image to proceed to next step";
        const payload = {
          message: message,
          color: "danger",
        };
        payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
        return false;
      }
      if (uploadOnProgress) {
        let message = "Please wait till the file is being uploaded";
        const payload = {
          message: message,
          color: "danger",
        };
        payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
        return false;
      }
      if (nodeIcon != "" && imageUrl != "") {
        if (!uploadOnProgress) {
          let api_payload_xml = {
            node_id: node_identity,
            node_name: values.node_name,
            node_version: values.node_version,
            node_icon: nodeIcon,
            node_image : imageUrl,
          };
          
          SDCloudBackendAPIWithToken()
            .post("/lifecycle/add_node_xml", api_payload_xml)
            .then((resp) => resp.data)
            .then((data) => {
              let payload = {
                message: "Element added under custom category",
                color: "success",
              };
              payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
              props.handleNext();
            })
            .catch((err) => {
              let message = err.response.data.message;
              let payload = {
                message: message,
                color: "danger",
              };
              payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
              return false;
            });
            
        }else {
          let payload = {
            message: "Please wait till the upload is being completed",
            color: "danger",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
          return false;
        }
      }
    },
    validate: (values) => {
      let errors = {};
      // if (!values.node_id) {
      //   errors.node_id = "Required";
      // }else{
      //   const re =  /^[a-zA-Z]*$/;
      //   if (!re.test(values.node_id)) {
      //     errors.node_id = "Identity should contain only letters";
      //   }
      // }
      if (!values.node_name) {
        errors.node_name = "Required";
      } else {
        const re = /^[a-zA-Z\s_]*$/;
        if (!re.test(values.node_name)) {
          errors.node_name = "Only alphabets, space and underscore are allowed";
        }
        localStorage.setItem("node_name", values.node_name);
      }
      if (!values.node_version) {
        errors.node_version = "Required";
      } else {
        const re = /^[a-zA-Z0-9.]*$/;
        if (!re.test(values.node_version)) {
          errors.node_version = "Invalid Version";
        }
        localStorage.setItem("node_version", values.node_version);
      }
      return errors;
    },
  });
  const getNodeInventoryDetails = async (payload) => {
    SDCloudBackendAPIWithToken()
      .post("/inventory/get_designer_node_details", payload)
      .then((resp) => resp.data)
      .then((data) => {
        let node_details = data["node_details"];
        setNodeData(node_details);
        setNodeIcon(node_details.icon_url);
        setImageUrl(node_details.image_url);
        setImageName(node_details.image_name);
      });
  };

  const attachfile = (file) => {
    if (file.length > 0) {
      fileData.append("attachment", file[0]);
      fileData.append("path", value);
      setformData(fileData);
      let upload_payload = fileData;
      SDCloudCustomizeAPIWithToken()
        .post(Api.s3Upload, upload_payload, {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("user")}`,
          },
        })
        .then((response) => {
          let icon_url = response.data.urls[0];
          setNodeIcon(icon_url);
        });
    }
  };

  const handleDeleteNodeImage = (img_url, img_name) => {
      let data = {
        image_name: img_name,
        permanent_image_del: true,
        bname: NODE_URLS.BUCKET_NAME,
      };
      SDCloudBackendAPIWithToken()
      .post('/lifecycle/delete_image_from_gcp', data)
      .then(res => {
        if (res.status === 200) {
          this.props.setImageUrl("");
        }
      })
      .catch(err => {
        let message = err.response.data.message;
        const payload = {
          message: message,
          color: "danger",
        };
        payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  const handleDeleteIcon = () => {
    setformData(null);
  };
  const onFileUpload = (apiRes) => {
    setCustomFileUrl(JSON.parse(apiRes).image_url);
    setImageUrl(JSON.parse(apiRes).image_url);
    localStorage.setItem("node_image_url", JSON.parse(apiRes).image_url)
    setImageName(JSON.parse(apiRes).name);
    setUploadOnProgress(false);
    var node_identity = node_name;
    var image_url = JSON.parse(apiRes).image_url
    node_identity = node_identity.replaceAll(" ", "");
    node_identity = node_identity.replaceAll("_", "");
    localStorage.setItem("node_id", node_identity);
    let api_payload_xml = {
      node_id: node_identity,
      node_name: node_name,
      node_version: node_version,
      node_icon: nodeIcon,
      node_image : image_url,
    };
    if(mode == 'add'){
      SDCloudBackendAPIWithToken()
        .post("/inventory/add_new_designer_node", api_payload_xml)
        .then((resp) => resp.data)
        .then((data) => {
          let message = "Please wait while the node is getting added to the inventory";
          const payload = {
            message: message,
            color: "info",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
        })
        .catch((err) => {
          if(err.response.status == 500){
            alert("Something went wrong while adding node to the Inventory.")
          }
          let message = err.response.data.message;
          const payload = {
            message: message,
            color: "danger",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
      });
    }
    if(mode=='edit'){
      SDCloudBackendAPIWithToken()
          .post("/inventory/update_designer_node_version", api_payload_xml)
          .then((resp) => resp.data)
          .then((data) => {
            let message = "Please wait while the node is getting updated in the inventory";
            const payload = {
              message: message,
              color: "info",
            };
            payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
          })
          .catch((err) => {
            if(err.response.status == 500){
              alert("Something went wrong while adding node to the Inventory.")
            }
            let message = err.response.data.message;
            const payload = {
              message: message,
              color: "danger",
            };
            payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
        });
    } 
  };
  const onUploadProgress = (isAnyFileSelectedOrUploading) => {
    if (isAnyFileSelectedOrUploading) {
      setUploadOnProgress(true);
    } else {
      setUploadOnProgress(false);
    }
  };

  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();
      localStorage.removeItem("node_id");
      localStorage.removeItem("node_version");
      localStorage.removeItem("node_name");
      //console.log("beforeunload event triggered");
      return (event.returnValue = "Are you sure you want to exit?");
    };
    window.addEventListener("beforeunload", handleTabClose);
    dispatch(SET_NAVBAR_TITLE("Add/Edit Node: " + node_name));
    let payload = {};
    if (mode == "add") {
      if (
        localStorage.getItem("node_id") != undefined &&
        localStorage.getItem("node_id") != ""
      ) {
        payload = {
          node_id: localStorage.getItem("node_id"),
          node_version: localStorage.getItem("node_version"),
        };
        getNodeInventoryDetails(payload);
      }
    } else {
      payload = { node_id: node_id, node_version: node_version };
      getNodeInventoryDetails(payload);
    }
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <GridContainer style={{ opacity: 1, padding: "50px" }}>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            {/* <GridContainer>
            <FormControl fullWidth className={classes.customInputStyle}>
                  <TextField
                    style={{ paddingRight: "50px", margin: 0 }}
                    rows={1}
                    label="Identity"
                    InputLabelProps={{ disableAnimation: true, shrink: false }}
                    InputProps={{ disableUnderline: true }}
                    id="node_id"
                    value={formik.values.node_id}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="filled"
                    helperText={formik.errors.node_id}
                    error={
                        formik.errors.node_id && formik.touched.node_id
                        ? true
                        : false
                    }
                  />
                </FormControl>
              
            </GridContainer> */}
            <GridContainer>
              <FormControl fullWidth className={classes.customInputStyle}>
                <TextField
                  style={{ paddingRight: "50px", margin: 0 }}
                  rows={1}
                  label="Name"
                  InputLabelProps={{ disableAnimation: true, shrink: false }}
                  InputProps={{ disableUnderline: true }}
                  id="node_name"
                  value={formik.values.node_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  variant="filled"
                  helperText={formik.errors.node_name}
                  disabled={disabled}
                  error={
                    formik.errors.node_name && formik.touched.node_name
                      ? true
                      : false
                  }
                />
              </FormControl>
            </GridContainer>
            <GridContainer>
              <FormControl fullWidth className={classes.customInputStyle}>
                <TextField
                  style={{ paddingRight: "50px", margin: 0 }}
                  rows={1}
                  label="Version"
                  InputLabelProps={{ disableAnimation: true, shrink: false }}
                  InputProps={{ disableUnderline: true }}
                  id="node_version"
                  value={formik.values.node_version}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  variant="filled"
                  helperText={formik.errors.node_version}
                  error={
                    formik.errors.node_version && formik.touched.node_version
                      ? true
                      : false
                  }
                />
              </FormControl>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <FormControl
              fullWidth
              style={{ margin: "10px auto 10px auto", paddingLeft: "20px" }}
              variant="filled"
            >
              <InputLabel className={classes.inputLabelStyles} shrink={false}>
                Upload Display Icon
              </InputLabel>
              <DropzoneArea
                acceptedFiles={["image/*"]}
                dropzoneText={".SVG .JPG .PNG "}
                showPreviews={false}
                showAlerts={false}
                onChange={(files) => attachfile(files)}
                onDelete={handleDeleteIcon}
                name="files"
                filesLimit={1}
              />
            </FormControl>
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ padding: "0px", paddingTop: "15px", paddingBottom: "5px" }}
          >
            <InputLabel className={classes.inputLabelStyles} shrink={false}>
              {RADIO_OPTIONS.ADD_QCOW2}
            </InputLabel>
          </GridItem>
          {formik.version_adornment === "error" ? null : (
            <NodeImageUploader
              getResponseFromFileUpload={onFileUpload}
              allowMultiple={false}
              onUploadProgress={onUploadProgress}
              version_value={formik.values.node_version}
              node_name={formik.values.node_name}
              setImageUrl={setImageUrl}
              setAllowDelete={setAllowDelete}
              allowDelete={allowDelete}
           />
          )}
          <br />
          <br />
        </GridContainer>
        {imageUrl !== "" || nodeIcon !== "" ? (
          <TableContainer component={Paper} style={{ padding: "30px"}}>
            <Table className={classes.table} aria-label="customized table" style={{ padding: "30px" , border:"1px solid"}}>
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">
                    &nbsp;&nbsp;&nbsp;Node Icon
                  </StyledTableCell>
                  <StyledTableCell align="center">Image Name</StyledTableCell>
                  <StyledTableCell align="center">Image URL</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <StyledTableRow key={rows[1].imageName}>
                  <StyledTableCell align="center" component="th" scope="row" style={{ padding: "20px"}}> 
                    <img src={rows[0].nodeIcon} width="84" height="64" />
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ padding: "20px"}}>
                    {rows[1].imageName}
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ padding: "20px"}}>
                    {rows[2].imageUrl}
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ padding: "20px"}}>
                  <Button
                      justIcon
                      round
                      size="sm"
                      onClick={() => handleDeleteNodeImage(rows[2].imageUrl, rows[1].imageName)} >
                      <i className="material-icons">delete</i>
                  </Button>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div></div>
        )}
        <div>
          <Paper square elevation={0} className={classes.resetContainer}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              right
              style={{ float: "right" }}
              type="submit"
            >
              Next
            </Button>
          </Paper>
        </div>
      </form>
    </div>
  );
};

export default AddNode;
