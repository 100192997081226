export const SAVE_TOKEN = "save_token";
export const LOGOUT = "log_out";
export const SAVE_PROFILE = "save_profile";
export const MYPROFILE = "my_profile";
export const SHOW_NOTIFICATION = "show_notification";
export const HIDE_NOTIFICATION = "hide_notification";
export const SHOW_LOADER = "show_loader";
export const HIDE_LOADER = "hide_loader";
export const SET_NAVBAR_TITLE = "set_current_lab_name";
export const SHOW_ERROR_PAGE = "show_error_page";
export const HIDE_ERROR_PAGE = "hide_error_page";
export const SET_TEMPLATES = "set_templates";
export const SET_WIZARDS = "set_wizards";
export const THEME_CUSTOMIZE = "themeC_customize";
