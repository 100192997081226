import React, { Component } from "react";
import { PropTypes } from "prop-types";
import Loader from "react-loader-spinner";
import { Card } from "@material-ui/core";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { Alert } from "@material-ui/lab";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import CardFooter from "components/Card/CardFooter";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { PRIMARY_COLOR } from "global_constants/app_constants";
import { Api } from "config/api";
import "./Profile.scss";
import {
  FormControl,
  InputLabel,
  FilledInput,
  makeStyles,
  Icon,
  Button
} from "@material-ui/core";

import {
  SECURITY_FORM_LABELS,
  SECURITY_QUESTIONS
} from "./utils/constants/constants_profile";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import theme from "../../theme";
const useStyles = makeStyles(styles);

const customStyles = theme => ({
  containerStyles: {
    padding: "0.9375rem 70px"
  },
  formInputStyles: {
    margin: "10px auto 10px auto",
    [theme.breakpoints.down("sm")]: {
      margin: "15px"
    }
  },
  inputStyles: {
    padding: "7px 15px 7px",
    height: "36px"
  },
  inputUnderlineStyles: {
    borderBottom: "none"
  },
  inputLabelStyles: {
    // marginBottom: "35px",
    position: "relative"
  },
  cardStyles: {
    color: "rgba(0, 0, 0, 0.87)",
    width: "100%",
    border: "0",
    display: "flex",
    position: "relative",
    fontSize: ".875rem",
    minWidth: "0",
    wordWrap: "break-word",
    background: "#FFF",
    marginTop: "30px",
    borderRadius: "6px",
    marginBottom: "30px",
    flexDirection: "column"
  },
  cardHeaderStyles: {
    padding: "0.9375rem 65px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative",
    "& h3": {
      fontFamily: theme.palette.fontList.selectedFont
    }
  },
  cardBodyStyles: {
    padding: "0.9375rem 65px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative"
  },
  cardFooterStyles: {
    padding: "0.9375rem 50px",
    // margin: "35px 0 35px 0",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative"
  },
  buttonStyles: {
    background: PRIMARY_COLOR,
    color: "white",
    width: "100%",
    minHeight: "20px",
    padding: "15px 10px 15px",
    fontSize: "1rem",
    textTransform: "none"
  },
  btnStyle:{
    background: `${theme.palette.buttonColor.main} !important`,
  }
});

const MENU = SECURITY_QUESTIONS;

class UpdateSecurityQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      securityQuestion: MENU[0],
      answer: "",
      formCardOpacity: "",
      showNotification: false,
      notificationType: "",
      notificationMessage: "",
      isLoaded: false
    };
  }

  getSecurityQuestionnAnswer = () => {
    SDCloudBackendAPIWithToken()
      .post(Api.getSecurityQs, {
        username: localStorage.getItem("userName")
      })
      .then(res => {
        this.setState({ securityQuestion: res.data.question, isLoaded: true });
      })
      .catch(err => {
        const payload = {
          message: err?.response?.data?.message || "unknown error occured",
          color: "danger",
          error: err,
          showErrorPage: true
        };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
        this.setState({ isLoaded: true });
      });
  };

  handleSubmitForm = () => {
    this.setState({ formCardOpacity: 0.3 });
    SDCloudBackendAPIWithToken()
      .post(Api.storeSecurityQs, {
        question: this.state.securityQuestion,
        answer: this.state.answer,
        username: localStorage.getItem("userName")
      })
      .then(res => {
        const payload = {
          message: res.data
            ? res?.data?.message
            : "Security question updated successfully",
          color: "success"
        };
        // dispatch(SHOW_NOTIFICATION({ payload }));

        this.setState({ formCardOpacity: 1 });
        this.setState({
          showNotification: true,
          notificationType: "success",
          notificationMessage: payload.message
        });
      })
      .catch(err => {
        const payload = {
          message: err?.response?.data?.message || "unknown error occured",
          color: "danger",
          error: err
        };
        this.setState({ formCardOpacity: 1 });
        this.setState({
          showNotification: true,
          notificationType: "error",
          notificationMessage: payload.message
        });
      });
  };

  //handle state updates passed from custom child components to create node parent
  handleFormFieldChange = stateUpdateObj => {
    const {
      target: { name, value }
    } = stateUpdateObj;
    // Update the key in the state with the new value
    this.setState({ [name]: value });
  };

  componentDidMount() {
    // this.props.dispatch(SHOW_LOADER());
    this.getSecurityQuestionnAnswer();
  }

  render() {
    const renderDropDown = () => {
      let dropDownItems = MENU.map(item => {
        return <option value={item}>{item}</option>;
      });

      return dropDownItems;
    };
    const { classes } = this.props;
    if (this.state.isLoaded) {
      return (
        <GridContainer className={classes.containerStyles}>
          <Card className={classes.cardStyles} elevation={0}>
            <CardHeader className={classes.cardHeaderStyles}>
              <div>
                <h3 style={{ fontSize: "24px" }}>
                  <b>Update security question</b>
                </h3>
                {this.props.notes ? (
                  <p className="noteStyles">{this.props.notes}</p>
                ) : null}
              </div>
            </CardHeader>

            <CardBody
              className={classes.cardBodyStyles}
              style={{ opacity: this.state.formCardOpacity }}
            >
              <GridContainer>
                <GridItem xs={12} sm={9} md={9} lg={9}>
                  <FormControl
                    fullWidth
                    className={classes.formInputStyles}
                    variant="filled"
                  >
                    <InputLabel
                      className={classes.inputLabelStyles}
                      // classes={{ focused: "input-label-pseudo" }}
                      shrink={false}
                    >
                      {SECURITY_FORM_LABELS.question}
                    </InputLabel>
                    <Select
                      // disableUnderline
                      onChange={this.handleFormFieldChange}
                      inputProps={{
                        name: "securityQuestion"
                      }}
                      className={classes.select}
                      value={this.state.securityQuestion}
                      native
                    >
                      {renderDropDown()}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={9} md={9} lg={9}>
                  <FormControl
                    fullWidth
                    className={classes.formInputStyles}
                    variant="filled"
                  >
                    <InputLabel
                      className={classes.inputLabelStyles}
                      // classes={{ focused: "input-label-pseudo" }}
                      shrink={false}
                    >
                      {SECURITY_FORM_LABELS.answer}
                    </InputLabel>
                    <FilledInput
                      onChange={this.handleFormFieldChange}
                      name="answer"
                      classes={{
                        input: classes.inputStyles,
                        root: classes.inputUnderlineStyles
                      }}
                      // className="input-pseudo"
                      value={this.state.answer}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                size="large"
                // fullWidth
                className={classes.btnStyle}
                disableFocusRipple
                type="submit"
                disabled={!String(this.state.answer).trim()}
                onClick={() => this.handleSubmitForm()}
              >
                Update security question
              </Button>
              <GridContainer>
                <GridItem xs={12} md={12}>
                  {this.state.showNotification ? (
                    <Alert
                      onClose={() => this.setState({ showNotification: false })}
                      severity={this.state.notificationType}
                    >
                      {this.state.notificationMessage}
                    </Alert>
                  ) : null}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridContainer>
      );
    } else {
      return (
        <div>
          <GridContainer justify="center">
            <Loader
              type="ThreeDots"
              color={theme.palette.secondary.main}
              height={500}
              width={50}
              visible={true}
            />
          </GridContainer>
        </div>
      );
    }
  }
}

UpdateSecurityQuestion.propTypes = {
  classes: PropTypes.object.isRequired,
  notes: PropTypes.string.isRequired
};

export default withRouter(
  withStyles(customStyles, useStyles)(UpdateSecurityQuestion)
);