/* eslint-disable no-use-before-define */
import React from "react";
import Chip from "@material-ui/core/Chip";
import { SvgIcon } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import globalTheme from "../../../../theme";

const CloseSVGComponent = props => {
  return (
    <SvgIcon className="customClose" viewBox="0 0 8 8" {...props}>
      <svg
        className="MuiSvgIcon-root MuiChip-deleteIcon"
        width="8"
        height="8"
        viewBox="0 0 24 24"
      >
        <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z"></path>
      </svg>
    </SvgIcon>
  );
};

const customStyles = theme => ({
  filterStyle: {
    marginTop: "-20px",
    "& .MuiFilledInput-root": {
      height: "auto !important"
    },
    "& span":{
      fontFamily: theme.palette.fontList.selectedFont
    }
  },
  customPaddingStyles: {
    "&.MuiAutocomplete-root": {
      paddingRight: "54px"
    }
  },
  autoCompleteStyle: {
    "&.MuiAutocomplete-option[data-focus='true']": {
      color: "#FFFFFF",
      backgroundColor: globalTheme.palette.secondary.main
    },
    "&:hover,&:focus,&:visited": {
      color: "#FFFFFF",
      backgroundColor: globalTheme.palette.secondary.main
    },
    '&[aria-selected="true"]': {
      color: "#FFFFFF",
      backgroundColor: globalTheme.palette.secondary.main
    }
  }
});

// customInputStyle: {
//   margin: "15px 0 15px 0",
//   paddingTop: "5px",
//   "& .MuiFilledInput-input": {
//     paddingTop: "10px"
//   },
//   "& .MuiFilledInput-multiline": {
//     paddingTop: "10px"
//   },
//   "& .MuiSelect-filled.MuiSelect-filled": {
//     paddingTop: "27px"
//   },
//   "& label.Mui-focused": {
//     color: inputBorderColor[0],
//     border: "0px"
//   },
//   "& .MuiInput-underline:after": {
//     borderBottomColor: inputBorderColor[0],
//     border: "0px"
//   },
//   "& .MuiOutlinedInput-root": {
//     "& fieldset": {
//       borderColor: inputBorderColor[0]
//     },
//     "&:hover fieldset": {
//       borderColor: inputBorderColor[0]
//     },
//     "&.Mui-focused fieldset": {
//       borderColor: inputBorderColor[0]
//     }
//   }
// },

export default function FixedTags(props) {
  const [value, setValue] = React.useState([]);
  const useStyles = makeStyles(customStyles);
  const classes = useStyles();

  return (
    <Autocomplete
      multiple
      id="fixed-tags-demo"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.09)" }}
      value={value}
      className={classes.customPaddingStyles}
      classes={{
        groupLabel: classes.autoCompleteStyle,
        option: classes.autoCompleteStyle
      }}
      onChange={(event, newValue) => {
        setValue([...newValue]);

        let filterValue = event.target.innerText
          ? filterOptions.find(log => log.title === event.target.innerText)
              .value
          : null;
        let logType = event.target.innerText
          ? filterOptions.find(log => log.title === event.target.innerText).type
          : null;

        props.handleAddFilter(logType, filterValue);
      }}
      options={filterOptions}
      getOptionLabel={option => option.title}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option.title}
            deleteIcon={<CloseSVGComponent />}
            onDelete={() => {
              setValue(
                value.filter(valueObj => valueObj.title !== option.title)
              );
              props.handleRemoveFilter(
                filterOptions.find(log => log.title === option.title).type,
                filterOptions.find(log => log.title === option.title).value
              );
            }}
          />
        ))
      }
      style={{ width: "auto" }}
      renderInput={params => (
        <TextField
          {...params}
          label="Add filters"
          variant="filled"
          // inputProps={inputProps}
          className={classes.filterStyle}
          placeholder="No filters applied"
        />
      )}
    />
  );
}

// Initialize Activity Log filters
const filterOptions = [
  { title: "offeringType : learning", type: "offeringType", value: "learning" },
  {
    title: "offeringType: sales demo",
    type: "offeringType",
    value: "sales demo"
  },
  { title: "offeringType: poc", type: "offeringType", value: "poc" },
  { title: "offeringType: designer", type: "offeringType", value: "designer" },
  { title: "offeringType: None", type: "offeringType", value: "None" },
  { title: "level: info", type: "level", value: "info" },
  { title: "level: critical", type: "level", value: "critical" }
];
