// This component creates the learning card seen on landing page of launch new learning labs.

import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { SECONDARY_COLOR } from "global_constants/app_constants";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { withRouter } from "react-router-dom";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
import "views/ServiceOffering/LearningLab/acess labs/Overview.scss";
import ReservedIpModal from "./ReservedIpModal";
import ScheduleLabModal from "./ScheduleLabModal";
import DemoLaunchModal from "./DemoLaunchModal";
import AlertDialog from "./AlertDialog";
import { Offerings } from "config/api";
import Grid from "@material-ui/core/Grid";
import lock from "assets/img/lock.png";
import unlock from "assets/img/unlock.png";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import PopupCard from "./popup";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as svg11 } from "../../../assets/criterion_networks/svg/11.svg";
import { ReactComponent as svg12 } from "../../../assets/criterion_networks/svg/12.svg";
import { ReactComponent as svg6 } from "../../../assets/criterion_networks/svg/06.svg";
import { ReactComponent as svg7 } from "../../../assets/criterion_networks/svg/07.svg";
import SvgComponent from "utils/SvgComponent";

const certificationLogo = require("assets/criterion_networks/img/certification.png");
const ReactMarkdown = require("react-markdown");

const useStyles = makeStyles(theme => ({
  colour: {
    color: `${theme.palette.secondary.main} !important`
  }
}));

const customStyles = theme => ({
  containerStyle: {
    paddingBottom: "15px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0%",
      paddingLeft: "0px",
      paddingRight: "0px"
    }
  },
  cardStyle: {
    borderRadius: "4px",
    backgroundColor: "white",
    marginBottom: "15px",
    lineHeight: "25px"
  },
  cardBody: {
    flex: "1 1 auto",
    padding: "0.9375rem 20px !important",
    position: "relative",
    "-webkit-box-flex": "1"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  learningThumbnail: {
    maxWidth: "100%",
    maxHeight: "100%",
    border: "8px solid #EBEBEB",
    padding: "0px !important"
  },
  modalCloseIcon: {
    position: "absolute",
    marginTop: "-115px",
    marginLeft: "45px",
    cursor: "pointer"
  },
  datetime: {
    color: SECONDARY_COLOR
  },
  btnStyle: {
    color: SECONDARY_COLOR,
    width: "auto",
    backgroundColor: "white",
    borderColor: SECONDARY_COLOR,
    float: "right",
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px",
      width: "100%"
    },
    "&:hover, &$focusVisible": {
      color: "white",
      backgroundColor: SECONDARY_COLOR,
      borderColor: SECONDARY_COLOR
    },
    [theme.breakpoints.down("sm")]: {
      float: "none",
      width: "auto"
    }
  },
  imgFilter: {
    filter: theme.palette.secondary.filterImage,
    background: "transparent !important"
  },
  font: {
    fontFamily: theme.palette.fontList.selectedFont
  },
  unsetIcon: {
    fontSize: "unset",
    overflow: "unset"
  },
  absoluteImg: {
    height: "114px",
    width: "114px",
    left: "56px",
    borderRadius: "0px",
    top: "28px",
    background: "black"
  }
});

const Heading = props => {
  return (
    <div>
      <h4 id={props.labName} className="card-content-heading">
        {props.showHeadingIcon
          ? (
              <span>
                <img
                  style={{
                    marginBottom: "2px",
                    // cursor: "pointer",
                    height: "12px"
                  }}
                  alt="logo"
                  src={certificationLogo}
                />
              </span>
            )` `
          : null}
        {props.labName}
        {props.showLock || props.availableTries == 0 || props.access == "no" ? (
          <LockIcon className="lock" />
        ) : (
          <LockOpenIcon className="lock" />
        )}
      </h4>
      <div>
        {/*<Button className="Card-button">Vendor</Button>
        <Button className="Card-button">Technology</Button>*/}
        &nbsp;
      </div>
    </div>
  );
};
const BlueOnTooltip = withStyles({
  arrow: {
    width: 17,
    "&::before": {
      border: "1px solid #0F1A2B",
      backgroundColor: "#0F1A2B",
      boxSizing: "border-box"
    }
  },
  tooltip: {
    color: "white",
    fontSize: "1em",
    maxWidth: 600,
    backgroundColor: "#0F1A2B"
  }
})(Tooltip);

const styles = theme => ({
  typography: {
    margin: theme.spacing.unit * 2
  }
});

const Description = props => {
  let description = props.description.replaceAll("</br>", "\n");
  description = description.replaceAll(/<br\s*\/?>/gi, "\n");
  return description.length < 360 ? (
    <div className="card-content">
      <ReactMarkdown
        className={`panel panelheight block-ellipsis ${props.font}`}
        source={description}
      />
    </div>
  ) : (
    <div className="card-content">
      <p>
        <ReactMarkdown
          className={`block-ellipsis ${props.font}`}
          source={description.slice(0, 304) + "..."}
        />
        <PopupCard value={description} />
      </p>
    </div>
  );
};

const LabButtons = props => {
  const classes = useStyles();
  if (
    (props.isLabActive || props.availableTries == 0) &&
    props.offeringType === Offerings.LEARNING_LABS.title &&
    props.userName != localStorage.getItem("userName")
  ) {
    return (
      <>
        <Button
          color="secondary"
          disabled={
            // false
            !props.showButtons ||
            props.availableTries == 0 ||
            !props.canLaunch ||
            props.access == "no"
          }
          className={`${classes.colour} bottom-button`}
          variant="text"
          onClick={props.showIpModal}
        >
          Launch Now
        </Button>
        <Button
          color="secondary"
          variant="text"
          className={`${classes.colour} bottom-button`}
          disabled={
            // false
            !props.showButtons ||
            props.availableTries == 0 ||
            !props.canLaunch ||
            props.access == "no"
          }
          onClick={props.showModal}
        >
          Schedule
        </Button>
      </>
    );
  } else if (
    (!props.isLabActive || props.availableTries == 0) &&
    props.offeringType === Offerings.LEARNING_LABS.title &&
    (props.userName == localStorage.getItem("userName") ||
      props.userName != localStorage.getItem("userName"))
  ) {
    return (
      <>
        <Button
          color="secondary"
          disabled={
            !props.showButtons ||
            props.availableTries == 0 ||
            !props.canLaunch ||
            props.access == "no"
          }
          className={`${classes.colour} bottom-button`}
          variant="text"
          onClick={props.showIpModal}
        >
          Launch Now
        </Button>
        <Button
          color="secondary"
          variant="text"
          className={`${classes.colour} bottom-button`}
          disabled={
            !props.showButtons ||
            props.availableTries == 0 ||
            !props.canLaunch ||
            props.access == "no"
          }
          onClick={props.showModal}
        >
          Schedule
        </Button>
      </>
    );
  } else if (
    (props.isLabActive || props.availableTries == 0) &&
    props.offeringType !== Offerings.LEARNING_LABS.title &&
    props.userName != localStorage.getItem("userName")
  ) {
    return (
      <>
        <Button
          color="secondary"
          disabled={
            !props.showButtons ||
            props.availableTries == 0 ||
            !props.canLaunch ||
            props.access == "no"
          }
          className={`${classes.colour} bottom-button`}
          variant="text"
          onClick={
            props.reservedIp?.ipService && props.reservedIp?.dynamicIp
              ? props.showIpModal
              : props.showDemoModal
          }
        >
          Launch Now
        </Button>
        <Button
          color="secondary"
          variant="text"
          className={`${classes.colour} bottom-button`}
          disabled={
            !props.showButtons ||
            props.availableTries == 0 ||
            !props.canLaunch ||
            props.access == "no"
          }
          onClick={props.showModal}
        >
          Schedule
        </Button>
      </>
    );
  } else if (
    (!props.isLabActive || props.availableTries == 0) &&
    props.offeringType !== Offerings.LEARNING_LABS.title &&
    (props.userName == localStorage.getItem("userName") ||
      props.userName != localStorage.getItem("userName"))
  ) {
    return (
      <>
        <Button
          color="secondary"
          disabled={
            !props.showButtons ||
            props.availableTries == 0 ||
            !props.canLaunch ||
            props.access == "no"
          }
          className={`${classes.colour} bottom-button`}
          variant="text"
          onClick={
            props.reservedIp?.ipService && props.reservedIp?.dynamicIp
              ? props.showIpModal
              : props.showDemoModal
          }
        >
          Launch Now
        </Button>
        <Button
          color="secondary"
          variant="text"
          className={`${classes.colour} bottom-button`}
          disabled={
            !props.showButtons ||
            props.availableTries == 0 ||
            !props.canLaunch ||
            props.access == "no"
          }
          onClick={props.showModal}
        >
          Schedule
        </Button>
      </>
    );
  } else {
    return (
      <>
        <Button
          color="secondary"
          style={{
            float: "right",
            // borderBottom: "1px solid",
            fontSize: "12px"
          }}
          disabled={!props.showButtons}
          onClick={props.accessLabBtnHandler}
        >
          {props.offeringType === Offerings.LEARNING_LABS.title
            ? "Access Learning Lab"
            : "Access Salesdemo"}
        </Button>
      </>
    );
  }
};

const LabRunTime = props => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12} sm={6} style={{ marginTop: "10px" }}>
        <p className={`card-content float-left pt-4 ${props.font}`}>
          {" "}
          Lab Run Time : <span className={props.font}> {props.labRunTime}</span>
          <span className={`sepbar ${props.font}`}>|</span> Available Tries :{" "}
          <span className={props.font}>
            {props.access == "no" ? 0 : props.availableTries}
          </span>
        </p>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        justifyContent="flex-end"
        className="right-button"
      >
        <LabButtons
          showButtons={props.showButtons}
          availableTries={props.availableTries}
          isLabActive={props.isLabActive}
          showModal={props.showModal}
          access={props.access}
          showIpModal={props.showIpModal}
          showDemoModal={props.showDemoModal}
          reservedIp={props.reservedIp}
          accessLabBtnHandler={props.accessLabBtnHandler}
          showAlert={props.showAlert}
          labIdentifier={props.labIdentifier}
          offeringType={props.offeringType}
          userName={props.userName}
          canLaunch={props.canLaunch}
        />
      </Grid>
    </Grid>
  );
};

// const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

class LaunchNewOfferingCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hideModal: true,
      hideIpModal: true,
      hideDemoModal: true,
      notificationColor: "",
      notificationMessage: "",
      showNotification: false,
      labIdentifier: this.props.labData.identifier,
      labName: this.props.labData.full_title,
      expiryDate: this.props.labData.activeLabTime?.expiryDate,
      createdDate: this.props.labData.activeLabTime?.createdDate,
      labId: this.props.labData.id,
      isLabProvisioned: this.props.labData.isLabProvisioned,
      uuid: this.props.labData.uuid,
      showLaunchAlert: false
    };
  }
  hideModal = () => {
    this.setState({
      hideModal: true
    });
  };
  showModal = () => {
    this.setState({
      hideModal: false
    });
  };

  hideIpModal = () => {
    this.setState({
      hideIpModal: true
    });
  };
  showIpModal = () => {
    this.setState({
      hideIpModal: false,
      hideDemoModal: false
    });
  };

  hideDemoModal = () => {
    if (
      this.props.labData.offeringType.title !== Offerings.LEARNING_LABS.title
    ) {
      this.setState({
        hideDemoModal: true
      });
    }
  };

  showDemoModal = () => {
    if (
      this.props.labData.offeringType.title !== Offerings.LEARNING_LABS.title
    ) {
      this.setState({
        hideDemoModal: false
      });
    }
  };

  hideAlert = () => {
    this.setState({ showLaunchAlert: false });
  };

  showAlert = () => {
    this.setState({ showLaunchAlert: true });
  };

  showNotification = (color = "success", message = "notification") => {
    const payload = {
      message: message,
      color: color
    };
    this.props.dispatch(SHOW_NOTIFICATION({ payload }));
  };

  accessLabBtnHandler = () => {
    const stateParams = {
      labId: this.state.labId,
      labName: this.state.labName,
      labEnabled: this.state.isLabProvisioned,
      labIdentifier: this.state.labIdentifier,
      createdDate: this.state.createdDate,
      expiryDate: this.state.expiryDate,
      uuid: this.state.uuid
    };
    this.props.labData.accessLabBtnHandler(stateParams);
  };

  launchLabBtnHandler = () => {
    this.props.labData.launchLabBtnHandler(
      this.state.labId,
      this.state.labIdentifier,
      true, // labEnabled parameter
      this.state.labName
    );
  };

  render() {
    const offeringType = this.props.labData.offeringType;
    // cms data
    const labName = this.props.labData.display_name;
    const labDesc = this.props.labData.description;
    // const stage = this.props.labData.stage || `New`; // text for the ribbon.
    const labRunTime = `${this.props.labData.labhours} Hours`;
    const imageUrl = this.props.labData.image ? this.props.labData.image : "";
    // svg12 : svg11;
    // "https://sdcloud-imgs.s3.us-west-2.amazonaws.com/default_logos/default_learning.svg"
    // : "https://sdcloud-imgs.s3.us-west-2.amazonaws.com/default_logos/default_experience.svg";

    const resourceType = offeringType.resource;
    const identifier = this.props.labData.identifier;
    const fullTitle = this.props.labData.full_title;

    // getProfile/resources api data

    const consumedHours = this.props.labData.consumed_hours;
    const allocatedHours = this.props.labData.allocated_hours;
    const labHours = this.props.labData.labhours;
    let isLabDisabled = false;
    if (this.props.labData.permission) {
      isLabDisabled = this.props.labData.permission === "delete";
    }
    let tries = Math.floor((allocatedHours - consumedHours) / labHours);
    const availableTries = tries < 1 ? 0 : tries;

    // getNewLearning data
    const isLabActive = this.props.labData.isLabActive;
    const tagName = this.props?.inventoryData
      ? this.props?.inventoryData[labName]
      : null;

    let description = labDesc?.replaceAll("</br>", "\n");
    description = description?.replaceAll(/<br\s*\/?>/gi, "\n");

    return (
      <div className="sortable-moves">
        {imageUrl.trim() !== "" ? (
          <img
            className="img-fluid p-absolute image-dim"
            src={imageUrl}
            alt=""
          ></img>
        ) : this.props.labData.offeringType?.resource === "learninglabs" ? (
          <div className="imgdefault">
            <SvgComponent
              className={this.props.classes.absoluteImg}
              type="default"
              svgSource={svg12}
            />
          </div>
        ) : (
          <div className="imgdefault">
            <SvgComponent
              className={this.props.classes.absoluteImg}
              type="default"
              svgSource={svg11}
            />
          </div>
        )}

        <h6 className={`card-title ${this.props.classes.font}`}>
          {labName}
          {isLabDisabled ||
          availableTries == 0 ||
          this.props.labData.access == "no" ? (
            <SvgComponent
              className={`new-lock ${this.props.classes.unsetIcon}`}
              type="icon"
              svgSource={svg6}
            />
          ) : (
            <div>
              {/* <img className={`new-lock ${this.props.classes.imgFilter}`} src={svg6} alt=''></img>
            <svg class="lock" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h1.9c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10z"></path></svg> */}
              <SvgComponent
                className={`new-lock ${this.props.classes.unsetIcon}`}
                type="icon"
                svgSource={svg7}
              />
              {tagName ? (
                <Chip label={tagName} style={{ marginLeft: "10px" }} />
              ) : null}
            </div>
            // <img className={`new-lock ${this.props.classes.imgFilter}`} src={svg7} alt=''></img>
          )}
        </h6>
        <Description description={labDesc} font={this.props.classes.font} />
        {/* <p class="card-content float-left pt-4"> Lab Run Time : <span> {labRunTime}</span> <span class="sepbar">|</span> Available Tries : <span>{availableTries}</span></p>
        <p class="card-content float-right pt-4"><a href="">Schedule</a>  <span class="sepbar"></span> <a href="">Launch Now</a></p> */}
        {this.state.hideModal ? null : (
          <ScheduleLabModal
            labScheduleData={{
              resourceType: resourceType,
              identifier: identifier,
              fullTitle: fullTitle,
              runTime: this.props,
              reservedIp: this.props.labData.reservedIp
            }}
            initialValues={{
              startDate: null,
              startTime: null,
              run_time: null
            }}
            offeringType={offeringType.title}
            openModal={this.state.hideModal}
            hideModal={this.hideModal}
          />
        )}
        {/* <p class="card-content float-left pt-4"> Lab Run Time : <span> {labRunTime}</span> <span class="sepbar">|</span> Available Tries : <span>{availableTries}</span></p> */}
        {/* <p class="card-content float-right pt-4"><a href="">Schedule</a>  <span class="sepbar"></span> <a href="">Launch Now</a></p> */}
        <LabRunTime
          labRunTime={labRunTime}
          availableTries={availableTries}
          showButtons={!isLabDisabled}
          isLabActive={isLabActive}
          showModal={this.showModal}
          access={this.props.labData.access}
          showIpModal={this.showIpModal}
          showDemoModal={this.showDemoModal}
          reservedIp={this.props.labData.reservedIp}
          accessLabBtnHandler={this.accessLabBtnHandler}
          labName={labName}
          showAlert={this.showAlert}
          labIdentifier={identifier}
          offeringType={offeringType.title}
          userName={this.props.labData.userName}
          canLaunch={this.props.labData.canLaunch}
          font={this.props.classes.font}
        />
        {this.state.showLaunchAlert &&
        offeringType.title === Offerings.LEARNING_LABS.title ? (
          <AlertDialog
            labLaunchData={{
              labId: this.state.labId,
              labIdentifier: this.state.labIdentifier,
              labEnabled: true, // labEnabled parameter
              fullTitle: fullTitle,
              labName: this.state.labName,
              launchLabBtnHandler: this.props.labData.launchLabBtnHandler,
              runTime: this.props.labData.labhours
            }}
            reservedIp={this.props.labData.reservedIp}
            notes={this.props.notes}
            offeringType={offeringType.title}
            showAlert={this.state.showLaunchAlert}
            hideAlert={this.hideAlert}
          />
        ) : null}
        {/* <AlertDialog
          showAlert={this.state.showLaunchAlert}
          hideAlert={this.hideAlert}
          titleText={"Wish to launch the lab now?"}
          contentText={
            " Learning lab cannot be paused once launched, we recommend to consume the lab within the provided lab run time. Happy Learning!"
          }
          btnDisagreeText={"Cancel"}
          btnAgreeText={"Launch"}
          submitAction={this.launchLabBtnHandler}
        /> */}
        {!this.state.hideIpModal &&
        offeringType.title === Offerings.SALES_DEMO.title ? (
          <DemoLaunchModal
            demoLaunchData={{
              labId: this.state.labId,
              labIdentifier: this.state.labIdentifier,
              labEnabled: true, // labEnabled parameter
              fullTitle: fullTitle,
              labName: this.state.labName,
              launchLabBtnHandler: this.props.labData.launchLabBtnHandler,
              runTime: this.props
            }}
            reservedIp={this.props.labData.reservedIp}
            notes={this.props.notes}
            offeringType={offeringType.title}
            openModal={this.state.hideDemoModal}
            hideDemoModal={this.hideDemoModal}
          />
        ) : this.state.hideIpModal &&
          !this.state.hideDemoModal &&
          offeringType.title === Offerings.SALES_DEMO.title ? (
          <DemoLaunchModal
            demoLaunchData={{
              labId: this.state.labId,
              labIdentifier: this.state.labIdentifier,
              labEnabled: true, // labEnabled parameter
              fullTitle: fullTitle,
              labName: this.state.labName,
              launchLabBtnHandler: this.props.labData.launchLabBtnHandler,
              runTime: this.props
            }}
            openModal={this.state.hideDemoModal}
            hideDemoModal={this.hideDemoModal}
          />
        ) : null}
        {!this.state.hideIpModal &&
        offeringType.title === Offerings.LEARNING_LABS.title ? (
          <ReservedIpModal
            labLaunchData={{
              labId: this.state.labId,
              labIdentifier: this.state.labIdentifier,
              labEnabled: true, // labEnabled parameter
              fullTitle: fullTitle,
              labName: this.state.labName,
              launchLabBtnHandler: this.props.labData.launchLabBtnHandler,
              runTime: this.props.labData.labhours
            }}
            reservedIp={this.props.labData.reservedIp}
            notes={this.props.notes}
            offeringType={offeringType.title}
            openModal={this.state.hideIpModal}
            hideIpModal={this.hideIpModal}
          />
        ) : null}
      </div>
    );
  }
}

LaunchNewOfferingCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withRouter(
  withStyles(customStyles)(connect()(LaunchNewOfferingCard))
);
