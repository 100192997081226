import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
// This component creates a modal for Reserved IP with respective form fields, the
// modal is popped up during provision or resuming of a lab or poc.

import React, { Component, useState, useEffect } from "react";
import { SECONDARY_COLOR } from "global_constants/app_constants";
import Button from "@material-ui/core/Button";
// Formik
import { useFormik } from "formik";
import MuiPhoneNumber from "material-ui-phone-number";
import Datetime from "react-datetime";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Api, Urls } from "config/api";
import Loader from "react-loader-spinner";
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import { LOADER_TYPE } from "global_constants/app_constants";
import "views/ServiceOffering/LearningLab/acess labs/Overview.scss";
import NotificationAlert from "views/Components/NotificationAlert";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import Typography from "@material-ui/core/Typography";
import theme from "theme";
//API Call
import { CMSAPIWithToken } from "../../../apis/CMS";
//checkbox
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from "@material-ui/core/Switch";
//react 
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
//markdown editor
import Editor from "react-markdown-editor-lite";
import ReactMarkdown from "react-markdown";
import MarkdownIt from 'markdown-it';
//markdown editor import style manually
import 'react-markdown-editor-lite/lib/index.css';
import queryString from "query-string";

const inputProps = {
  disableAnimation: true,
  shrink: false
};

const customStyles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
      },
  mainContainer: {
    padding: "40px"
  },
  container: {
    marginTop: "20px"
  },
  formContainer: {
    width: "100%"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    position: "absolute",
    width: 550,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    display: "block"
  },
  buttStyle: {
    background: theme.palette.buttonColor.main,
    "&:hover": {
      background: theme.palette.buttonColor.main
    }
  },
  firstTitleLabel: {
    padding: "0 15px"
  },
  titleLabel: {
    padding: "0 15px",
    marginTop: "30px"
  },
  errorText: {
    fontSize: "12px",
    color: theme.palette.error.main
  }
});


const LearningExercisesCreate = props => {
  const useStyles = makeStyles(customStyles);
  const classes = useStyles();
  const mdEditor = React.useRef(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [value, setValue] = React.useState("ipv4");
  const [categoriesData, setCategoriesData] = useState([]);
  const [solution, setSolution] = useState("");
  const [introList, setIntroList] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  let queryParams = queryString.parse(window.location.search);
  var lern_id = queryParams.id;
  var learning_id_title_map = JSON.parse(localStorage.getItem("lern_id_title_map"))
  var learning_title = learning_id_title_map[lern_id]
  //var category_title = learn_cat_id_map[]
  
  useEffect(() => {
    getAccountInfo();
    getLearningCategories();
  }, []);
 
  
  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {  
        admin_learnings_id: lern_id,
        admin_learnings_course_labs_category_id: null,
        title: "",
        intro_list: "",
        solution: "",
        exercise_position: ""
    },
    onSubmit: (values) => {
      let payload = {
        admin_learnings_id: lern_id,
        admin_learnings_course_labs_category_id: values.admin_learnings_course_labs_category_id,
        title: values.title,
        intro_list: introList,
        solution: solution,
        exercise_position: values.exercise_position,
      };

        CMSAPIWithToken() 
        .post("/learnings/admin_learnings_course_labs_exercises/",payload) 
        .then((resp) => resp.data.results)
        .then((data) => {
            const payload = {
                message: "Learnings exercises Created Successfully",
                color: "success",
            };
            payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
            history.push("ProductContentLearningsMenuItems?id="+lern_id+"&title="+learning_title);
        })
        .catch((err) => {
          let message = err.response.data.message;
          const payload = {
            message: message,
            color: "danger",
        };
        payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
    },
    validate: (values) => {
      let errors = {};
      if (!values.admin_learnings_course_labs_category_id) {
        errors.admin_learnings_course_labs_category_id = "Please select a category";
      }
      if (!values.title) {
        errors.title = "This field may not be blank.";
      }
      if (!values.exercise_position) {
        errors.exercise_position = "This field may not be blank.";
      }
      return errors;
    },
  });

      //mark down editor
      const handleSolutionChange = ({ html, text }) => {
        const newValue = text;
        //console.log(newValue);
        setSolution(newValue);
      };
      const handleIntroListChange = ({ html, text }) => {
        const newValue = text;
        //console.log(newValue);
        setIntroList(newValue);
      };
        // Initialize a markdown parser
        const mdParser = new MarkdownIt(/* Markdown-it options */);
  

  const getAccountInfo = () => {
    SDCloudBackendAPIWithToken()
      .get(Api.accountInfo)
      .then(res => {
      
      })
      .then(() => {
        setIsLoading(false);
      })
      .catch(err => {
        //console.log(err.response);
        setIsLoading(false);
      });
  };

  const getLearningCategories = () => {
    let catData = []
    CMSAPIWithToken()
    .get("/learnings/admin_learnings_course_labs_categories/")
    .then((resp) => resp.data.results)
      .then((resp) => {
        resp.forEach((item) => {
          if(item.admin_learnings_id == lern_id){
            catData.push({
              id: item.id,
              lab_name: item.lab_name,
              lab_identifier: item.lab_identifier,
            });
          }
        });
        setCategoriesData(catData);
        setIsLoading(false);
      })
      .catch((err) => {
        let message = err.response.data.message || "unknown error occured";
        setIsLoading(false);
      });
  };


  
let Cancelbutton=()=>{
  history.push("ProductContentLearningsMenuItems?id="+lern_id+"&title="+learning_title);
}
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });

const handleChange = (event) => {
  setState({ ...state, [event.target.name]: event.target.checked });
};

  return (
    // Form modal for sandbox create
    <div>
      <LoaderHoc isLoading={isLoading}>
        <Paper elevation={0}>
          <Grid
            container
            className={classes.mainContainer}
            style={{ marginTop: "2%" }}
          >
            <form
              onSubmit={formik.handleSubmit}
              className={classes.formContainer}
            >
              {/* <Typography variant="h4">{props.formTitle}</Typography> */}
              <Typography variant="h4" className={classes.firstTitleLabel}>
              Add Learning Exercises
              </Typography>
              <Grid container className={classes.container}>
                <GridItem md={12}>
                  {formik.errors.admin_learnings_id && formik.touched.admin_learnings_id ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Learnings Name"
                      name="admin_learnings_name"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={learning_title}
                      onChange={formik.handleChange}
                      helperText={formik.errors.admin_learnings_id}
                      onBlur={formik.handleBlur}
                      error
                      disabled={"disabled"}
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Learnings Name"
                      name="admin_learnings_name"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={learning_title}
                      onChange={formik.handleChange}
                      disabled={"disabled"}
                    />
                  )}
                </GridItem>

                <GridItem md={12}>
                <InputLabel style={{marginTop:"15px", width: "82%"}} id="admin_learnings_course_labs_category_id">
                    Admin Learnings Categories Id
                  </InputLabel>
                  {formik.errors.admin_learnings_course_labs_category_id && formik.touched.admin_learnings_course_labs_category_id ? (
                     <Select
                     labelId="admin_learnings_course_labs_category_id"
                     id="admin_learnings_course_labs_category_id"
                     labelWidth={100}
                     style={{ width: "100%" }}
                     name="admin_learnings_course_labs_category_id"
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                     native
                     variant="filled"
                     value={formik.values.admin_learnings_course_labs_category_id}
                     helperText={formik.errors.admin_learnings_course_labs_category_id}
                     error={formik.errors.admin_learnings_course_labs_category_id && formik.touched.admin_learnings_course_labs_category_id
                       ? true
                       : false}
                   >
                       <option value="">Select Category</option>
                       {categoriesData.map(data => <option value={data.id}>{data.lab_name}</option>
                       )}
                     </Select>

                  
                 ) : (
                   <Select
                     labelId="admin_learnings_course_labs_category_id"
                     id="admin_learnings_course_labs_category_id"
                     labelWidth={100}
                     style={{marginTop:"15px", width: "100%"}}
                     name="admin_learnings_course_labs_category_id"
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                     native
                     variant="filled"
                     value={formik.values.admin_learnings_course_labs_category_id}
                     helperText={formik.errors.admin_learnings_course_labs_category_id}
                     error={
                         formik.errors.admin_learnings_course_labs_category_id && formik.touched.admin_learnings_course_labs_category_id
                         ? true
                         : false
                     }
                 >
                 <option value="">Select Category</option>
                   {categoriesData.map(data =>  
                       <option value={data.id}>{data.lab_name}</option> 
                   )}   
                 </Select> 

                  )}
                </GridItem>

                <GridItem md={12}>
                {formik.errors.title && formik.touched.title ? (
                  <TextField
                        type="text"
                        variant="filled"
                        label="Title"
                        name="title"
                        fullWidth
                        InputLabelProps={inputProps}
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        helperText={formik.errors.title}
                        onBlur={formik.handleBlur}
                        error
                      />
                    ) : (
                      <TextField
                        type="text"
                        variant="filled"
                        label="Title"
                        name="title"
                        fullWidth
                        InputLabelProps={inputProps}
                        value={formik.values.title}
                        onChange={formik.handleChange}
                      />
                    )}
                </GridItem>

                <GridItem md={12}>
                    <p>Intro List</p>
                        <Editor
                          ref={mdEditor}
                          value={introList}
                          style={{
                            height: "500px"
                          }}
                          onChange={handleIntroListChange}
                          renderHTML={text => <ReactMarkdown source={text} />}
                        />
                </GridItem>

                <GridItem md={12}>
                  <p>Solution</p>
                        <Editor
                          ref={mdEditor}
                          value={solution}
                          style={{
                            height: "500px"
                          }}
                          onChange={handleSolutionChange}
                          renderHTML={text => <ReactMarkdown source={text} />}
                        />
                </GridItem>
                
                <GridItem md={12}>
                  {formik.errors.exercise_position && formik.touched.exercise_position ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Exercise Position"
                      name="exercise_position"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.exercise_position}
                      onChange={formik.handleChange}
                      helperText={formik.errors.exercise_position}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Exercise Position"
                      name="exercise_position"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.exercise_position}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>

              </Grid>
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{ marginTop: "20px", marginBottom: "30px" }}
              >
                <GridItem xs={12} sm={6} md={3} lg={3}>
                  {!btnDisabled ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      disableElevation
                      size="large"
                      className={classes.buttStyle}
                      fullWidth
                      disableFocusRipple
                      type="submit"
                    >
                      Submit
                    </Button>
                  ) : null}
                  {btnDisabled ? (
                    <Loader
                      type={LOADER_TYPE}
                      color={theme.palette.secondary.main}
                      width={50}
                      height={50}
                      style={{ float: "right", marginRight: "15px" }}
                    />
                  ) : null}
                </GridItem>
                <GridItem xs={12} sm={6} md={3} lg={3}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    disableElevation
                    size="large"
                    // className={classes.buttStyle}
                    fullWidth
                    onClick={Cancelbutton}
                    disableFocusRipple
                    type="submit"
                  >
                    Cancel
                  </Button>
                </GridItem>
              </Grid>

              <NotificationAlert
                notificationType={notificationType}
                showNotification={showNotification}
                message={notificationMessage}
              />
            </form>
          </Grid>
        </Paper>
      </LoaderHoc>
    </div>
  );
};

export default LearningExercisesCreate;
