import React, { useEffect, useState, Fragment } from "react";

// Formik
import { useFormik } from "formik";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { FormControl, IconButton, TextField, Tooltip } from "@material-ui/core";
import "../../Accounts/accounts.css";
import { useDispatch } from "react-redux";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import { MenuProps } from "./utils";
import { useHistory } from "react-router-dom";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
// import moment from "moment-timezone";
import { Visibility } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import Typography from "@material-ui/core/Typography";

// Styling
const useStyles = makeStyles(() => ({
  root: {
    textAlign: "end",
  },
  labelRoot: {
    fontSize: "14px",
    padding: "40px 10px 0px 0px",
  },
}));

const ViewInventory = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const inv_type = props.inv_type;
  const sku_id = props.sku_id;
  const [isLoading, setIsLoading] = useState(true);
  const [clusterTemplatesList, setClusterTemplatesList] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [clusterTemplate, setClusterTemplate] = useState("");
  const [tags, setTags] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [combinedArray, setCombinedArray] = useState([]);
  const [hideButton, setHideButton] = useState(false);
  const [emptyImageArray, setEmptyImageArray] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [auditLogs, setAuditLogs] = useState();
  const [inventoryState, setInventoryState] = useState([]);
  const [inventoryStatesList, setInventoryStatesList] = useState([]);
  const [inventoryEnvironmentList, setInventoryEnvironmentList] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [hideAuditLogs , setOpenAuditLogs] = useState(false);
  const [costdata, setCostData] = useState('');
  const [hideImageManagement, setOpenImageManagement] = useState(false);
  const isAllSelected =
    accounts.length > 0 && selectedAccounts.length === accounts.length;

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedAccounts(
        selectedAccounts.length === accounts.length ? [] : accounts
      );
      return;
    }
    setSelectedAccounts(value);
  };
  const [open, setOpen] = useState(false);

  const [selectedTags, setSelectedTags] = useState([]);
  const isAllSelectedTags =
    tags.length > 0 && selectedTags.length === tags.length;

  const handleTagChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedTags(selectedTags.length === tags.length ? [] : tags);
      return;
    }
    setSelectedTags(value);
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      sku_id: "",
      sku_name: "",
      accounts: [],
      display_name: "",
      sub_category: "",
      sub_categories: "",
      inventory_type: inv_type,
      // inventory_type: "",
      image_link: "",
      icon_url: "",
      image_version: "",
      add_run_time: "" / 24,
      owner_name: "",
      owner_email: "",
      inventory_state: "",
      inventory_env: "",
      tags: [],
      cost_per_unit: "",
      comments: "",
      commissioned_date: "",
      decommission_date: "",
      cluster_template: "",
      resource_allocation_type: "",
      cloud_provider: "",
      vcpu: "",
      memory: "",
      storage: "",
      max_nodes: "",
      max_int: "",
      prop_manual: "",
      prop_external: "",
      automated_l1: "",
      automated_l2: "",
      erb_template: "",
      //is_active: ""
      boot_time: "",
      consumed_cpu: "",
      consumed_ram: "",
      consumed_disk: "",
    },
    onSubmit: (values) => {
      let payload = {
        sku_id: values.sku_id,
        sku_name: values.sku_name,
        accounts: selectedAccounts,
        display_name: values.display_name,
        inventory_type: values.inventory_type,
        image_link: values.image_link,
        icon_url: values.icon_url,
        image_version: values.image_version,
        add_run_time: values.add_run_time / 24,
        owner_name: values.owner_name,
        owner_email: values.owner_email,
        inventory_state: values.inventory_state,
        inventory_env: values.inventory_env,
        tags: selectedTags,
        sub_category: values.sub_category,
        cost_per_unit: values.cost_per_unit,
        comments: values.comments,
        commissioned_date: values.commissioned_date,
        decommission_date: values.decommission_date,
        cluster_template: values.cluster_template,
        resource_allocation_type: values.resource_allocation_type,
        cloud_provider: values.cloud_provider,
        vcpu: values.vcpu,
        memory: values.memory,
        storage: values.storage,
        freeze_template: props?.freeze_template,
        max_nodes: values.max_nodes,
        max_int: values.max_int,
        prop_manual: values.prop_manual,
        prop_external: values.prop_external,
        automated_l1: values.automated_l1,
        automated_l2: values.automated_l2,
        erb_template: values.erb_template,
        //is_active:values.is_active,
        boot_time: values.boot_time,
        consumed_cpu: values.consumed_cpu,
        consumed_ram: values.consumed_ram,
        consumed_disk: values.consumed_disk,
      };

      SDCloudBackendAPIWithToken()
        .post("/inventory/update_inventory", payload)
        .then((resp) => resp.data)
        .then(() => {
          const payload = {
            message: "Inventory Updated Successfully",
            color: "success",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
          history.push("/inventorymanagement");
          window.location.reload();
        })
        .catch((err) => {
          let message = (
            <div style={{ width: "600px" }}>{err.response.data.message}</div>
          );
          const payload = {
            message: message,
            color: "danger",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
        });
    },
    validate: (values) => {
      let errors = {};
      if (!values.sku_id) {
        errors.sku_id = "Required";
      }
      if (!values.sku_name) {
        errors.sku_name = "Required";
      }
      // if (values.sku_name.length > 45) {
      //   errors.sku_name = "SKU Name should be of 45 characters";
      // }
      if (!values.display_name) {
        errors.display_name = "Required";
      }
      if (!values.inventory_type) {
        errors.inventory_type = "Required";
      }
      if (!values.image_link) {
        errors.image_link = "Required";
      }
      if (!values.image_version) {
        errors.image_version = "Required";
      }
      if (!values.owner_name) {
        errors.owner_name = "Required";
      }
      if (!values.owner_email) {
        errors.owner_email = "Required";
      }
      if (!values.inventory_state) {
        errors.inventory_state = "Required";
      }
      if (!values.inventory_env) {
        errors.inventory_env = "Required";
      }
      // if (!values.cost_per_unit) {
      //   errors.cost_per_unit = "Required";
      // }
      // if (!values.comments) {
      //   errors.comments = "Required";
      // }
      if (!values.resource_allocation_type) {
        errors.resource_allocation_type = "Required";
      }
      if (!values.cloud_provider) {
        errors.cloud_provider = "Required";
      }
      if (!values.vcpu) {
        errors.vcpu = "Required";
      }
      if (!values.memory) {
        errors.memory = "Required";
      }
      if (!values.storage) {
        errors.storage = "Required";
      }
      return errors;
    },
  });

  const handleImageDeletion = (image_id, sku_name) => {
    setHideButton(false);
    if (window.confirm("Are you sure you want to delete this image?")) {
      const payload = { imageName: image_id, sku_name: sku_name };
      SDCloudBackendAPIWithToken()
        .post("/inventory/delete_labcreation_images", payload)
        .then((resp) => {
          const payload = {
            message: "Image deletion initiated",
            color: "success",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
          if (resp.status === 200) {
            const payload = {
              message: "Image deleted successfully",
              color: "success",
            };
            payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
          }
        })
        .catch((error) => {
          let message = error.response.data.message;
          const payload = {
            message: message,
            color: "danger",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
        });
    };
  }
  const renderTemplateDropdown = async () => {};
  const fetchTemplateDetails = async (selectedTemplate) => {
    let payload = { template: selectedTemplate, inv_type: inv_type };
    SDCloudBackendAPIWithToken()
      .post("/inventory/get_template_details", payload)
      .then((resp) => resp.data)
      .then((data) => {
        let template_details = data.template_details;
        for (const prop in template_details) {
          formik.setFieldValue(prop, template_details[prop]);
          if (prop == "accounts") {
            setAccounts(template_details[prop]);
          }
          if (prop == "tags") {
            setTags(template_details[prop]);
          }
          if (prop == "audit_logs") {
            setAuditLogs(template_details[prop]);
          }
        }
        setIsLoading(false);
      });
  };
  const fetchInventoryDetails = async () => {
    let payload = { sku_id: sku_id, inv_type: inv_type };
    SDCloudBackendAPIWithToken()
      .post("/inventory/get_inventory_details", payload)
      .then((resp) => resp.data)
      .then((data) => {
        let inventory_details = data.inventory_details;
        if (inv_type !== "Designer") {
          setClusterTemplate(inventory_details["cluster_template"]);
        }
        for (const prop in inventory_details) {
          formik.setFieldValue(prop, inventory_details[prop]);
          if (prop == "accounts") {
            setAccounts(inventory_details[prop]);
            setSelectedAccounts(inventory_details["selected_accounts"]);
          }
          if (prop == "tags") {
            setTags(inventory_details[prop]);
            setSelectedTags(inventory_details["selected_tags"]);
          }
          if (prop == "audit_logs") {
            setAuditLogs(inventory_details[prop]);
          }
          if (prop == "sub_category") {
            setSubCategories(inventory_details["sub_categories"]);
          }
          if (prop == "image_context") {
            let allImageInfo = inventory_details[prop];
            let imgs = allImageInfo.image_list.split(",");
            let descriptions = allImageInfo.description.split(',')
            let combinedArray = imgs.map((image, index) => ({
              image: image.replace(/["']/g, ""),
              description: descriptions[index].replace(/["']/g, "")
            }));
            setCombinedArray(combinedArray)

            if (Array.isArray(imgs) && imgs.length === 1 && imgs[0] === '[]') {
              setEmptyImageArray(true);
            }
          }
          let t = ''
          let cost_data=data.inventory_details.cost_per_unit
          if (cost_data == 0){
            setCostData(cost_data)
          } else {
          for(var k in cost_data) {
              t=t+' '+(k+ " : " +cost_data[k])+"\n";
          }
          setCostData(t)
        }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        let message = err.response.data.message;
        const payload = {
          message: message,
          color: "danger",
        };
        payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };
  useEffect(() => {
    if (inv_type !== "Designer") {
      renderTemplateDropdown();
    }
    const inv_states = SDCloudBackendAPIWithToken()
        .get("/inventory/get_inventory_states")
        .then(resp => resp.data)
        .then(data => {
          let states = data["inventory_states"];
          setInventoryStatesList(states);
        });
    const inv_env= SDCloudBackendAPIWithToken()
        .get("/inventory/get_inventory_env")
        .then((resp) => resp.data)
        .then((data) => {
          let envs = data["inventory_envs"];
          setInventoryEnvironmentList(envs);
        });
    fetchInventoryDetails(sku_id);
  }, []);

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <form onSubmit={formik.handleSubmit}>
            <div className="inventoryedit-card">
              <h3 className="createaccount-widget-title">
                View/Edit Inventory
              </h3>
              <p className={classes.span}>Edit account Inventory details</p>
              <LoaderHoc isLoading={isLoading}></LoaderHoc>
              <Grid container className="box-inner-height mt-2">
                <Grid item xs={12} sm={12} md={12}>
                  {inv_type !== "Designer" ? (
                    <>
                      <h3 className="createaccount-widget-title">
                        Selected Cluster Template
                      </h3>
                      <Grid item xs={12} sm={8} md={8}>
                        {formik.errors.cluster_template &&
                        formik.touched.cluster_template ? (
                          <FormControl
                            fullWidth
                            variant="filled"
                            className={classes.formControl}
                          >
                            <InputLabel id="select_cluster_template">
                              Select Cluster Template
                            </InputLabel>
                            <Autocomplete
                              openOnFocus
                              label="Select Cluster Template"
                              id="select_cluster_template"
                              options={clusterTemplatesList}
                              value={clusterTemplate}
                              onChange={(event, newValue) => {
                                setClusterTemplate(newValue);
                                fetchTemplateDetails(newValue);
                              }}
                              helperText={formik.errors.training_name}
                              error={
                                formik.errors.training_name &&
                                formik.touched.training_name
                                  ? true
                                  : false
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  type="text"
                                  variant="filled"
                                  fullWidth
                                />
                              )}
                            />
                          </FormControl>
                        ) : (
                          <FormControl
                            fullWidth
                            variant="filled"
                            className={classes.formControl}
                          >
                            <Autocomplete
                              openOnFocus
                              id="select_cluster_template"
                              options={clusterTemplatesList}
                              value={clusterTemplate}
                              onChange={(event, newValue) => {
                                setClusterTemplate(newValue);
                                fetchTemplateDetails(newValue);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  type="text"
                                  variant="filled"
                                  fullWidth
                                />
                              )}
                            />
                          </FormControl>
                        )}
                      </Grid>
                    </>
                  ) : (
                    <div></div>
                  )}
                  <h3 className="createaccount-widget-title">
                    General Information
                  </h3>
                </Grid>
                <Grid item xs={12} sm={12} md={12}></Grid>

                <Grid item xs={12} sm={4} md={4}>
                  {formik.errors.sku_id && formik.touched.sku_id ? (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="SKU ID"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="sku_id"
                        value={formik.values.sku_id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        helperText={formik.errors.sku_id}
                        error={
                          formik.errors.sku_id && formik.touched.sku_id
                            ? true
                            : false
                        }
                        // value={""}
                      />
                    </FormControl>
                  ) : (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="SKU ID"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="sku_id"
                        value={formik.values.sku_id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        disabled
                        // value={""}
                      />
                    </FormControl>
                  )}
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                  {formik.errors.sku_name && formik.touched.sku_name ? (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="SKU Name"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="sku_name"
                        value={formik.values.sku_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        helperText={formik.errors.sku_name}
                        error={
                          formik.errors.sku_name && formik.touched.sku_name
                            ? true
                            : false
                        }
                      />
                    </FormControl>
                  ) : (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="SKU Name"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="sku_name"
                        disabled={true}
                        value={formik.values.sku_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        // value={""}
                      />
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  {formik.errors.display_name && formik.touched.display_name ? (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label={
                          inv_type !== "Designer"
                            ? "Display Name"
                            : "Display Name Category"
                        }
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="display_name"
                        value={formik.values.display_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        helperText={formik.errors.display_name}
                        error={
                          formik.errors.display_name &&
                          formik.touched.display_name
                            ? true
                            : false
                        }
                      />
                    </FormControl>
                  ) : (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label={
                          inv_type !== "Designer"
                            ? "Display Name"
                            : "Display Name Category"
                        }
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="display_name"
                        value={formik.values.display_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                      />
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  {formik.errors.inventory_type &&
                  formik.touched.inventory_type ? (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="Inventory Type"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="inventory_type"
                        value={formik.values.inventory_type}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        helperText={formik.errors.inventory_type}
                        error={
                          formik.errors.inventory_type &&
                          formik.touched.inventory_type
                            ? true
                            : false
                        }
                      />
                    </FormControl>
                  ) : (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="Inventory Type"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        disabled={true}
                        InputProps={{ disableUnderline: true }}
                        id="inventory_type"
                        value={formik.values.inventory_type}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        // value={""}
                      />
                    </FormControl>
                  )}
                </Grid>
                {inv_type == "Designer" ? (
                  <Grid item xs={12} sm={4} md={4} style={{ width: "82%" }}>
                    {formik.errors.sub_categories &&
                    formik.touched.sub_categories ? (
                      <FormControl
                        fullWidth
                        className={classes.customInputStyle}
                        variant="filled"
                        style={{
                          marginTop: "10",
                          marginRight: "5px",
                          width: "83%",
                        }}
                      >
                        <InputLabel id="sub_category">Sub Category</InputLabel>
                        <Select
                          labelId="sub_category"
                          id="sub_category"
                          labelWidth={100}
                          style={{ width: "82%" }}
                          name="sub_category"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          native
                          variant="filled"
                          value={formik.values.sub_category}
                          helperText={formik.errors.sub_category}
                          error={
                            formik.errors.sub_category &&
                            formik.touched.sub_category
                              ? true
                              : false
                          }
                        >
                          <option value="">Select Sub Category</option>
                          {subCategories.map((value) => (
                            <option value={value}>{value}</option>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <FormControl
                        fullWidth
                        className={classes.customInputStyle}
                        variant="filled"
                        style={{
                          marginTop: "10",
                          marginRight: "25px",
                          width: "83%",
                        }}
                      >
                        <InputLabel id="sub_category">Sub Category</InputLabel>
                        <Select
                          labelId="sub_category"
                          id="sub_category"
                          labelWidth={100}
                          fullWidth
                          name="sub_category"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.sub_category}
                          native
                          variant="filled"
                        >
                          <option value="">Select Sub Category</option>
                          {subCategories.map((value) => (
                            <option value={value}>{value}</option>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                ) : (
                  <div></div>
                )}
                <Grid item xs={12} sm={4} md={4}>
                  {formik.errors.owner_name && formik.touched.owner_name ? (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="Owner Name"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="owner_name"
                        value={formik.values.owner_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        helperText={formik.errors.owner_name}
                        error={
                          formik.errors.owner_name && formik.touched.owner_name
                            ? true
                            : false
                        }
                      />
                    </FormControl>
                  ) : (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="Owner Name"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="owner_name"
                        value={formik.values.owner_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={true}
                        variant="filled"
                        // value={""}
                      />
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  {formik.errors.owner_email && formik.touched.owner_email ? (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="Owner Email"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="owner_email"
                        value={formik.values.owner_email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        helperText={formik.errors.owner_email}
                        error={
                          formik.errors.owner_email &&
                          formik.touched.owner_email
                            ? true
                            : false
                        }
                      />
                    </FormControl>
                  ) : (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="Owner Email"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="owner_email"
                        value={formik.values.owner_email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        // value={""}
                      />
                    </FormControl>
                  )}
                </Grid>
                {/* <Grid item xs={12} sm={4} md={4}>
                  {formik.errors.cost_per_unit &&
                  formik.touched.cost_per_unit ? (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="Cost Per Unit"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="cost_per_unit"
                        value={formik.values.cost_per_unit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        helperText={formik.errors.cost_per_unit}
                        error={
                          formik.errors.cost_per_unit &&
                          formik.touched.cost_per_unit
                            ? true
                            : false
                        }
                      />
                    </FormControl>
                  ) : (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="Cost Per Unit"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="cost_per_unit"
                        value={formik.values.cost_per_unit}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        // value={""}
                      />
                    </FormControl>
                  )}
                </Grid> */}
                {inv_type !== 'Designer' ? (
                <Grid item xs={12} sm={4} md={4}>
                  {formik.errors.comments && formik.touched.comments ? (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="comments"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="comments"
                        value={formik.values.comments}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        helperText={formik.errors.comments}
                        error={
                          formik.errors.comments && formik.touched.comments
                            ? true
                            : false
                        }
                      />
                    </FormControl>
                  ) : (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="comments"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="comments"
                        value={formik.values.comments}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                      />
                    </FormControl>
                  )}
                </Grid>):(<div></div>)}
                <Grid item xs={12} sm={4} md={4}>
                  {formik.errors.inventory_state &&
                  formik.touched.inventory_state ? (
                    <FormControl
                      fullWidth
                      className={classes.customInputStyle}
                      variant="filled"
                      style={{ marginTop: "10", marginRight: "5px", paddingRight:"50px" }}
                    >
                      <InputLabel id="inv-state">Inventory State</InputLabel>
                      <Select
                        labelId="inv-state"
                        id="inventory_state"
                        labelWidth={100}
                        fullWidth
                        name="inventory_state"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        native
                        value={formik.values.inventory_state}
                        variant="filled"
                        helperText={formik.errors.inventory_state}
                        error={
                          formik.errors.inventory_state &&
                          formik.touched.inventory_state
                            ? true
                            : false
                        }
                      >
                        <option value="">Select Inventory State</option>
                        {inventoryStatesList.map((value) => (
                          <option value={value}>{value}</option>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <FormControl
                      fullWidth
                      className={classes.customInputStyle}
                      variant="filled"
                      style={{ marginTop: "10", marginRight: "25px" , paddingRight:"50px"}}
                    >
                      <InputLabel id="inv-state">Inventory State</InputLabel>
                      <Select
                        labelId="inv-state"
                        id="inventory_state"
                        labelWidth={100}
                        fullWidth
                        name="inventory_state"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.inventory_state}
                        native
                        variant="filled"
                      >
                        <option value="">Select Inventory State</option>
                        {inventoryStatesList.map((value) => (
                          <option value={value}>{value}</option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  {formik.errors.inventory_env &&
                  formik.touched.inventory_env ? (
                    <FormControl
                      fullWidth
                      className={classes.customInputStyle}
                      variant="filled"
                      style={{ marginTop: "10", marginRight: "5px" , paddingRight:"50px"}}
                    >
                      <InputLabel id="inv-state">
                        Inventory Environment
                      </InputLabel>
                      <Select
                        labelId="inv-env"
                        id="inventory_env"
                        labelWidth={100}
                        fullWidth
                        name="inventory_env"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        native
                        value={formik.values.inventory_env}
                        variant="filled"
                        helperText={formik.errors.inventory_env}
                        error={
                          formik.errors.inventory_env &&
                          formik.touched.inventory_env
                            ? true
                            : false
                        }
                      >
                        <option value="">Select Environment</option>
                        {inventoryEnvironmentList.map((value) => (
                          <option value={value}>{value}</option>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <FormControl
                      fullWidth
                      className={classes.customInputStyle}
                      variant="filled"
                      style={{marginTop: "10",marginRight: "25px",paddingRight:"50px"}}
                    >
                      <InputLabel id="inv-state">
                        Inventory Environment
                      </InputLabel>
                      <Select
                        labelId="inv-env"
                        id="inventory_env"
                        labelWidth={100}
                        fullWidth
                        name="inventory_env"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.inventory_env}
                        native
                        variant="filled"
                      >
                        <option value="">Select Environment</option>
                        {inventoryEnvironmentList.map((value) => (
                          <option value={value}>{value}</option>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  className={classes.body2}
                >
                  {formik.errors.commissioned_date &&
                  formik.touched.commissioned_date ? (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Fragment>
                        <FormControl fullWidth>
                          <KeyboardDatePicker
                            inputVariant="filled"
                            label="Commissioned Date"
                            name="commissioned_date"
                            format="MM/dd/yyyy"
                            // placeholder="MM/DD/YYYY"
                            labelFunc={() =>
                              formik.values.commissioned_date || "MM/DD/YYYY"
                            }
                            style={{marginTop: "10", marginRight: "5px" , paddingRight:"50px"}}
                            // style={{marginLeft:"2rem"}}
                            fullWidth
                            onChange={(val) => {
                              formik.setFieldValue(
                                "commissioned_date",
                                moment(val).format("MM/DD/YYYY")
                              );
                            }}
                            helperText={formik.errors.commissioned_date}
                            onBlur={formik.handleBlur}
                            error={
                              formik.errors.commissioned_date &&
                              formik.touched.commissioned_date
                                ? true
                                : false
                            }
                          />
                        </FormControl>
                      </Fragment>
                    </MuiPickersUtilsProvider>
                  ) : (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Fragment>
                        <FormControl fullWidth>
                          <KeyboardDatePicker
                            inputVariant="filled"
                            label="Commissioned Date"
                            name="commissioned_date"
                            format="MM/dd/yyyy"
                            // placeholder="MM/DD/YYYY"
                            labelFunc={() =>
                              formik.values.commissioned_date || "MM/DD/YYYY"
                            }
                            fullWidth
                            // style={{marginLeft:"2rem"}}
                            style={{marginTop: "10", marginRight: "25px" , paddingRight:"50px"}}
                            //onChange={formik.handleChange}
                            onChange={(val) => {
                              formik.setFieldValue(
                                "commissioned_date",
                                moment(val).format("MM/DD/YYYY")
                              );
                            }}
                          />
                        </FormControl>
                      </Fragment>
                    </MuiPickersUtilsProvider>
                  )}
                </Grid>
                <Grid item xs={5} sm={5} className={classes.body2}>
                  {formik.errors.decommission_date &&
                  formik.touched.decommission_date ? (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Fragment>
                        <FormControl fullWidth>
                          <KeyboardDatePicker
                            inputVariant="filled"
                            label="Decommission Date"
                            name="decommission_date"
                            format="MM/dd/yyyy"
                            // placeholder="MM/DD/YYYY"
                            fullWidth
                            labelFunc={() =>
                              formik.values.decommission_date || "MM/DD/YYYY"
                            }
                            onChange={(val) => {
                              formik.setFieldValue(
                                "decommission_date",
                                moment(val).format("MM/DD/YYYY")
                              );
                            }}
                            helperText={formik.errors.decommission_date}
                            onBlur={formik.handleBlur}
                            error={
                              formik.errors.decommission_date &&
                              formik.touched.decommission_date
                                ? true
                                : false
                            }
                          />
                        </FormControl>
                      </Fragment>
                    </MuiPickersUtilsProvider>
                  ) : (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Fragment>
                        <FormControl fullWidth>
                          <KeyboardDatePicker
                            inputVariant="filled"
                            label="Decommission Date"
                            name="decommission_date"
                            format="MM/dd/yyyy"
                            // placeholder="MM/DD/YYYY"
                            fullWidth
                            labelFunc={() =>
                              formik.values.decommission_date || "MM/DD/YYYY"
                            }
                            //onChange={formik.handleChange}
                            onChange={(val) => {
                              formik.setFieldValue(
                                "decommission_date",
                                moment(val).format("MM/DD/YYYY")
                              );
                            }}
                          />
                        </FormControl>
                      </Fragment>
                    </MuiPickersUtilsProvider>
                  )}
                </Grid>
                {inv_type !== 'Designer' ? (
                <Grid item xs={12} sm={10}>
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 ,width: "45%"}}
                                        rows={5}
                                        multiline
                                        label="Cost Per Unit"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="cost_per_unit"
                                        value={costdata}
                                        disabled={true}
                                        variant="filled"
                                    />
                                </FormControl>
                            </Grid>):(<div></div>)}
                <Grid item xs={12} sm={12} md={12}>
                  <h3 className="createaccount-widget-title">
                    Image Information
                  </h3>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  {formik.errors.image_link && formik.touched.image_link ? (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="Image Link"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="image_link"
                        value={formik.values.image_link}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        helperText={formik.errors.image_link}
                        error={
                          formik.errors.image_link && formik.touched.image_link
                            ? true
                            : false
                        }
                      />
                    </FormControl>
                  ) : (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="Image Link"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="image_link"
                        value={formik.values.image_link}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                      />
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                  {formik.errors.image_version &&
                  formik.touched.image_version ? (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="Image Version"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="image_version"
                        value={formik.values.image_version}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        helperText={formik.errors.image_version}
                        error={
                          formik.errors.image_version &&
                          formik.touched.image_version
                            ? true
                            : false
                        }
                      />
                    </FormControl>
                  ) : (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="Image Version"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="image_version"
                        value={formik.values.image_version}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                      />
                    </FormControl>
                  )}
                </Grid>
                {inv_type == "Designer" ? (
                  <Grid item xs={12} sm={8} md={8}>
                    {formik.errors.icon_url && formik.touched.icon_url ? (
                      <FormControl
                        fullWidth
                        className={classes.customInputStyle}
                      >
                        <TextField
                          style={{ paddingRight: "50px", margin: 0 }}
                          rows={1}
                          label="Icon URL"
                          InputLabelProps={{
                            disableAnimation: true,
                            shrink: false,
                          }}
                          InputProps={{ disableUnderline: true }}
                          id="icon_url"
                          value={formik.values.icon_url}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          variant="filled"
                          helperText={formik.errors.icon_url}
                          error={
                            formik.errors.icon_url && formik.touched.icon_url
                              ? true
                              : false
                          }
                        />
                      </FormControl>
                    ) : (
                      <FormControl
                        fullWidth
                        className={classes.customInputStyle}
                      >
                        <TextField
                          style={{ paddingRight: "50px", margin: 0 }}
                          rows={1}
                          label="Icon URL"
                          InputLabelProps={{
                            disableAnimation: true,
                            shrink: false,
                          }}
                          InputProps={{ disableUnderline: true }}
                          id="icon_url"
                          value={formik.values.icon_url}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          variant="filled"
                        />
                      </FormControl>
                    )}
                  </Grid>
                ) : (
                  <div></div>
                )}
                <Grid item xs={12} sm={4} md={4}>
                  {formik.errors.cloud_provider &&
                  formik.touched.cloud_provider ? (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="Cloud Proviser"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="cloud_provider"
                        value={formik.values.cloud_provider}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        helperText={formik.errors.cloud_provider}
                        error={
                          formik.errors.cloud_provider &&
                          formik.touched.cloud_provider
                            ? true
                            : false
                        }
                      />
                    </FormControl>
                  ) : (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        disabled={true}
                        label="Cloud Provider"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="cloud_provider"
                        value={formik.values.cloud_provider}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                      />
                    </FormControl>
                  )}
                </Grid>
                {inv_type == "Designer" ? (
                  <>
                    <Grid item xs={12} sm={12} md={12}>
                      <h3 className="createaccount-widget-title">
                        Device Properties
                      </h3>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      {formik.errors.max_nodes && formik.touched.max_nodes ? (
                        <FormControl
                          fullWidth
                          className={classes.customInputStyle}
                        >
                          <TextField
                            style={{ paddingRight: "50px", margin: 0 }}
                            rows={1}
                            label="Max Nodes"
                            InputLabelProps={{
                              disableAnimation: true,
                              shrink: false,
                            }}
                            InputProps={{ disableUnderline: true }}
                            id="max_nodes"
                            value={formik.values.max_nodes}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="filled"
                            helperText={formik.errors.max_nodes}
                            error={
                              formik.errors.max_nodes &&
                              formik.touched.max_nodes
                                ? true
                                : false
                            }
                          />
                        </FormControl>
                      ) : (
                        <FormControl
                          fullWidth
                          className={classes.customInputStyle}
                        >
                          <TextField
                            style={{ paddingRight: "50px", margin: 0 }}
                            rows={1}
                            label="Max Nodes"
                            InputLabelProps={{
                              disableAnimation: true,
                              shrink: false,
                            }}
                            InputProps={{ disableUnderline: true }}
                            id="max_nodes"
                            value={formik.values.max_nodes}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="filled"
                          />
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      {formik.errors.max_int && formik.touched.max_int ? (
                        <FormControl
                          fullWidth
                          className={classes.customInputStyle}
                        >
                          <TextField
                            style={{ paddingRight: "50px", margin: 0 }}
                            rows={1}
                            label="Max Interfaces"
                            InputLabelProps={{
                              disableAnimation: true,
                              shrink: false,
                            }}
                            InputProps={{ disableUnderline: true }}
                            id="max_int"
                            value={formik.values.max_int}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="filled"
                            helperText={formik.errors.max_int}
                            error={
                              formik.errors.max_int && formik.touched.max_int
                                ? true
                                : false
                            }
                          />
                        </FormControl>
                      ) : (
                        <FormControl
                          fullWidth
                          className={classes.customInputStyle}
                        >
                          <TextField
                            style={{ paddingRight: "50px", margin: 0 }}
                            rows={1}
                            label="Max Interfaces"
                            InputLabelProps={{
                              disableAnimation: true,
                              shrink: false,
                            }}
                            InputProps={{ disableUnderline: true }}
                            id="max_int"
                            value={formik.values.max_int}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="filled"
                          />
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <FormControl
                        fullWidth
                        className={classes.customInputStyle}
                      >
                        <TextField
                          style={{ paddingRight: "50px", margin: 0 }}
                          label="Automated L1"
                          InputLabelProps={{
                            disableAnimation: true,
                            shrink: false,
                          }}
                          InputProps={{ disableUnderline: true }}
                          id="automated_l1"
                          value={formik.values.automated_l1}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          rows={1}
                          variant="filled"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <FormControl
                        fullWidth
                        className={classes.customInputStyle}
                      >
                        <TextField
                          style={{ paddingRight: "50px", margin: 0 }}
                          label="Automated L2"
                          InputLabelProps={{
                            disableAnimation: true,
                            shrink: false,
                          }}
                          InputProps={{ disableUnderline: true }}
                          id="automated_l2"
                          value={formik.values.automated_l2}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          rows={1}
                          variant="filled"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <FormControl
                        fullWidth
                        className={classes.customInputStyle}
                      >
                        <TextField
                          style={{ paddingRight: "50px", margin: 0 }}
                          label="ERB Template"
                          rows={10}
                          multiline
                          InputLabelProps={{
                            disableAnimation: true,
                            shrink: false,
                          }}
                          InputProps={{ disableUnderline: true }}
                          id="erb_template"
                          value={formik.values.erb_template}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          variant="filled"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      {formik.errors.prop_manual &&
                      formik.touched.prop_manual ? (
                        <FormControl
                          fullWidth
                          className={classes.customInputStyle}
                        >
                          <TextField
                            style={{ paddingRight: "50px", margin: 0 }}
                            rows={10}
                            multiline
                            label="Node XML"
                            InputLabelProps={{
                              disableAnimation: true,
                              shrink: false,
                            }}
                            InputProps={{ disableUnderline: true }}
                            id="prop_manual"
                            value={formik.values.prop_manual}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="filled"
                            helperText={formik.errors.prop_manual}
                            error={
                              formik.errors.prop_manual &&
                              formik.touched.prop_manual
                                ? true
                                : false
                            }
                          />
                        </FormControl>
                      ) : (
                        <FormControl
                          fullWidth
                          className={classes.customInputStyle}
                        >
                          <TextField
                            style={{ paddingRight: "50px", margin: 0 }}
                            rows={10}
                            multiline
                            label="Node XML"
                            InputLabelProps={{
                              disableAnimation: true,
                              shrink: false,
                            }}
                            InputProps={{ disableUnderline: true }}
                            id="prop_manual"
                            value={formik.values.prop_manual}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="filled"
                          />
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      {formik.errors.prop_external &&
                      formik.touched.prop_external ? (
                        <FormControl
                          fullWidth
                          className={classes.customInputStyle}
                        >
                          <TextField
                            style={{ paddingRight: "50px", margin: 0 }}
                            rows={10}
                            multiline
                            label="Node JSON"
                            InputLabelProps={{
                              disableAnimation: true,
                              shrink: false,
                            }}
                            InputProps={{ disableUnderline: true }}
                            id="prop_external"
                            value={formik.values.prop_external}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="filled"
                            helperText={formik.errors.prop_external}
                            error={
                              formik.errors.prop_external &&
                              formik.touched.prop_external
                                ? true
                                : false
                            }
                          />
                        </FormControl>
                      ) : (
                        <FormControl
                          fullWidth
                          className={classes.customInputStyle}
                        >
                          <TextField
                            style={{ paddingRight: "50px", margin: 0 }}
                            rows={10}
                            multiline
                            label="Node JSON"
                            InputLabelProps={{
                              disableAnimation: true,
                              shrink: false,
                            }}
                            InputProps={{ disableUnderline: true }}
                            id="prop_external"
                            value={formik.values.prop_external}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="filled"
                          />
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      {formik.errors.boot_time && formik.touched.boot_time ? (
                        <FormControl
                          fullWidth
                          className={classes.customInputStyle}
                        >
                          <TextField
                            style={{ paddingRight: "50px", margin: 0 }}
                            rows={1}
                            label="Boot Time"
                            InputLabelProps={{
                              disableAnimation: true,
                              shrink: false,
                            }}
                            InputProps={{ disableUnderline: true }}
                            id="boot_time"
                            value={formik.values.boot_time}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="filled"
                            helperText={formik.errors.boot_time}
                            error={
                              formik.errors.boot_time &&
                              formik.touched.boot_time
                                ? true
                                : false
                            }
                          />
                        </FormControl>
                      ) : (
                        <FormControl
                          fullWidth
                          className={classes.customInputStyle}
                        >
                          <TextField
                            style={{ paddingRight: "50px", margin: 0 }}
                            rows={1}
                            label="Boot Time"
                            InputLabelProps={{
                              disableAnimation: true,
                              shrink: false,
                            }}
                            InputProps={{ disableUnderline: true }}
                            id="boot_time"
                            value={formik.values.boot_time}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="filled"
                          />
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      {formik.errors.consumed_cpu &&
                      formik.touched.consumed_cpu ? (
                        <FormControl
                          fullWidth
                          className={classes.customInputStyle}
                        >
                          <TextField
                            style={{ paddingRight: "50px", margin: 0 }}
                            rows={1}
                            label="Consumed CPU"
                            InputLabelProps={{
                              disableAnimation: true,
                              shrink: false,
                            }}
                            InputProps={{ disableUnderline: true }}
                            id="consumed_cpu"
                            value={formik.values.consumed_cpu}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="filled"
                            helperText={formik.errors.consumed_cpu}
                            error={
                              formik.errors.consumed_cpu &&
                              formik.touched.consumed_cpu
                                ? true
                                : false
                            }
                          />
                        </FormControl>
                      ) : (
                        <FormControl
                          fullWidth
                          className={classes.customInputStyle}
                        >
                          <TextField
                            style={{ paddingRight: "50px", margin: 0 }}
                            rows={1}
                            label="Consumed CPU"
                            InputLabelProps={{
                              disableAnimation: true,
                              shrink: false,
                            }}
                            InputProps={{ disableUnderline: true }}
                            id="consumed_cpu"
                            value={formik.values.consumed_cpu}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="filled"
                          />
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      {formik.errors.consumed_ram &&
                      formik.touched.consumed_ram ? (
                        <FormControl
                          fullWidth
                          className={classes.customInputStyle}
                        >
                          <TextField
                            style={{ paddingRight: "50px", margin: 0 }}
                            rows={1}
                            label="Consumed RAM"
                            InputLabelProps={{
                              disableAnimation: true,
                              shrink: false,
                            }}
                            InputProps={{ disableUnderline: true }}
                            id="consumed_ram"
                            value={formik.values.consumed_ram}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="filled"
                            helperText={formik.errors.consumed_ram}
                            error={
                              formik.errors.consumed_ram &&
                              formik.touched.consumed_ram
                                ? true
                                : false
                            }
                          />
                        </FormControl>
                      ) : (
                        <FormControl
                          fullWidth
                          className={classes.customInputStyle}
                        >
                          <TextField
                            style={{ paddingRight: "50px", margin: 0 }}
                            rows={1}
                            label="Consumed RAM"
                            InputLabelProps={{
                              disableAnimation: true,
                              shrink: false,
                            }}
                            InputProps={{ disableUnderline: true }}
                            id="consumed_ram"
                            value={formik.values.consumed_ram}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="filled"
                          />
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      {formik.errors.consumed_disk &&
                      formik.touched.consumed_disk ? (
                        <FormControl
                          fullWidth
                          className={classes.customInputStyle}
                        >
                          <TextField
                            style={{ paddingRight: "50px", margin: 0 }}
                            rows={1}
                            label="Consumed Disk"
                            InputLabelProps={{
                              disableAnimation: true,
                              shrink: false,
                            }}
                            InputProps={{ disableUnderline: true }}
                            id="consumed_disk"
                            value={formik.values.consumed_disk}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="filled"
                            helperText={formik.errors.consumed_disk}
                            error={
                              formik.errors.consumed_disk &&
                              formik.touched.consumed_disk
                                ? true
                                : false
                            }
                          />
                        </FormControl>
                      ) : (
                        <FormControl
                          fullWidth
                          className={classes.customInputStyle}
                        >
                          <TextField
                            style={{ paddingRight: "50px", margin: 0 }}
                            rows={1}
                            label="Consumed Disk"
                            InputLabelProps={{
                              disableAnimation: true,
                              shrink: false,
                            }}
                            InputProps={{ disableUnderline: true }}
                            id="consumed_disk"
                            value={formik.values.consumed_disk}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            variant="filled"
                          />
                        </FormControl>
                      )}
                    </Grid>
                  </>
                ) : (
                  <div></div>
                )}
                <Grid item xs={12} sm={12} md={12}>
                  <h3 className="createaccount-widget-title">Resources</h3>
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  {formik.errors.resource_allocation_type &&
                  formik.touched.resource_allocation_type ? (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="Allocation Type"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="resource_allocation_type"
                        value={formik.values.resource_allocation_type}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        helperText={formik.errors.resource_allocation_type}
                        error={
                          formik.errors.resource_allocation_type &&
                          formik.touched.resource_allocation_type
                            ? true
                            : false
                        }
                      />
                    </FormControl>
                  ) : (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="Allocation Type"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="resource_allocation_type"
                        value={formik.values.resource_allocation_type}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                      />
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  {formik.errors.vcpu && formik.touched.vcpu ? (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="CPU"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="vcpu"
                        value={formik.values.vcpu}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        helperText={formik.errors.vcpu}
                        error={
                          formik.errors.vcpu && formik.touched.vcpu
                            ? true
                            : false
                        }
                      />
                    </FormControl>
                  ) : (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="CPU"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="vcpu"
                        value={formik.values.vcpu}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                      />
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  {formik.errors.memory && formik.touched.memory ? (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="RAM (in MBs)"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="memory"
                        value={formik.values.memory}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        helperText={formik.errors.memory}
                        error={
                          formik.errors.memory && formik.touched.memory
                            ? true
                            : false
                        }
                      />
                    </FormControl>
                  ) : (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="RAM (in MBs)"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="memory"
                        value={formik.values.memory}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                      />
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                  {formik.errors.storage && formik.touched.storage ? (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="DISK"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="storage"
                        value={formik.values.storage}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        helperText={formik.errors.storage}
                        error={
                          formik.errors.storage && formik.touched.storage
                            ? true
                            : false
                        }
                      />
                    </FormControl>
                  ) : (
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <TextField
                        style={{ paddingRight: "50px", margin: 0 }}
                        rows={1}
                        label="DISK"
                        InputLabelProps={{
                          disableAnimation: true,
                          shrink: false,
                        }}
                        InputProps={{ disableUnderline: true }}
                        id="storage"
                        value={formik.values.storage}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="filled"
                        // value={""}
                      />
                    </FormControl>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <h3 className="createaccount-widget-title">
                    Select Accounts and Tags
                  </h3>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <FormControl
                    fullWidth
                    className={classes.customInputStyle}
                    variant="filled"
                    style={{ marginTop: "10", marginRight: "5px" }}
                  >
                    <InputLabel id="mutiple-select-label">
                      Select Accounts
                    </InputLabel>
                    <Select
                      labelId="mutiple-select-label"
                      multiple
                      value={selectedAccounts}
                      onChange={handleChange}
                      renderValue={(selectedAccounts) =>
                        selectedAccounts.join(", ")
                      }
                      MenuProps={MenuProps}
                      onBlur={formik.handleBlur}
                      variant="filled"
                    >
                      <MenuItem
                        value="all"
                        classes={{
                          root: isAllSelected ? classes.selectedAll : "",
                        }}
                      >
                        <ListItemIcon>
                          <Checkbox
                            classes={{
                              indeterminate: classes.indeterminateColor,
                            }}
                            checked={isAllSelected}
                            indeterminate={
                              selectedAccounts.length > 0 &&
                              selectedAccounts.length < accounts.length
                            }
                          />
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.selectAllText }}
                          primary="Select All"
                        />
                      </MenuItem>
                      {accounts.map((option) => (
                        <MenuItem key={option} value={option}>
                          <ListItemIcon>
                            <Checkbox
                              checked={selectedAccounts.indexOf(option) > -1}
                            />
                          </ListItemIcon>
                          <ListItemText primary={option} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                  <FormControl
                    fullWidth
                    className={classes.customInputStyle}
                    variant="filled"
                    style={{ marginTop: "10", marginRight: "5px" }}
                  >
                    <InputLabel id="select-tag">Select Tags</InputLabel>
                    <Select
                      labelId="select-tag"
                      multiple
                      value={selectedTags}
                      onChange={handleTagChange}
                      renderValue={(selectedTags) => selectedTags.join(", ")}
                      onBlur={formik.handleBlur}
                      variant="filled"
                    >
                      <MenuItem
                        value="all"
                        classes={{
                          root: isAllSelectedTags ? classes.selectedAll : "",
                        }}
                      >
                        <ListItemIcon>
                          <Checkbox
                            classes={{
                              indeterminate: classes.indeterminateColor,
                            }}
                            checked={isAllSelectedTags}
                            indeterminate={
                              selectedTags.length > 0 &&
                              selectedTags.length < tags.length
                            }
                          />
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.selectAllText }}
                          primary="Select All"
                        />
                      </MenuItem>
                      {tags.map((option) => (
                        <MenuItem key={option} value={option}>
                          <ListItemIcon>
                            <Checkbox
                              checked={selectedTags.indexOf(option) > -1}
                            />
                          </ListItemIcon>
                          <ListItemText primary={option} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {inv_type == "Learnings" || inv_type == "Showcases" ? (
                  <>
                    <Grid item xs={12} sm={12} md={12}>
                      <h3 className="createaccount-widget-title">
                        Edit Lab Hours
                      </h3>
                    </Grid>
                    <Grid item xs={12} sm={8} md={8}>
                      {formik.errors.add_run_time &&
                      formik.touched.add_run_time ? (
                        <TextField
                          type="number"
                          style={{ paddingRight: "50px", margin: 0 }}
                          rows={1}
                          label="Lab Hours"
                          InputLabelProps={{
                            disableAnimation: true,
                            shrink: false,
                          }}
                          InputProps={{ disableUnderline: true }}
                          id="add_run_time"
                          value={formik.values.add_run_time}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          variant="filled"
                          helperText={formik.errors.add_run_time}
                          error={
                            formik.errors.add_run_time &&
                            formik.touched.add_run_time
                              ? true
                              : false
                          }
                        />
                      ) : (
                        <TextField
                          type="number"
                          style={{ paddingRight: "50px", margin: 0 }}
                          rows={1}
                          label="Lab Hours"
                          InputLabelProps={{
                            disableAnimation: true,
                            shrink: false,
                          }}
                          InputProps={{ disableUnderline: true }}
                          id="add_run_time"
                          value={formik.values.add_run_time}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          variant="filled"
                        />
                      )}
                    </Grid>
                  </>
                ) : (
                  <div></div>
                )}
                <Grid item xs={12} sm={12} md={12}></Grid>
                <h3 className="createaccount-widget-title">Image Management</h3>
                <IconButton
                  onClick={() => setOpenImageManagement(!hideImageManagement)}
                  style={{ marginTop: "1%" }}
                >
                  <Visibility />
                </IconButton>
                {hideImageManagement && (
                  <>
                    <Grid container className="audit-log">
                      <Grid item xs={12} sm={4} md={4}>
                        <strong>Image Name</strong>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <strong>Image Description</strong>
                      </Grid>
                      <Grid item xs={12} sm={2} md={2}>
                        <strong>Action</strong>
                      </Grid>
                    </Grid>
                    {!emptyImageArray ? (
                      <Grid
                        container
                        style={{ borderBottom: "1px solid lightgray" }}
                      >
                        {combinedArray.map((item) => (
                          <Grid container className="audit-log-data">
                            <Grid item xs={12} sm={4} md={4}>
                              <span>
                                {item.image}
                              </span>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <span>
                                {item.description}
                              </span>
                            </Grid>
                            {!hideButton ? (
                              <Grid item xs={12} sm={2} md={2}>
                                <Tooltip
                                  title="Delete Image"
                                  placement="bottom"
                                >
                                  <IconButton
                                    onClick={() =>
                                      handleImageDeletion(
                                        item.image,
                                        formik.values.sku_name
                                      )
                                    }
                                    style={{
                                      cursor: "pointer",
                                      top: "-1rem",
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            ) : (
                              <Grid item xs={12} sm={3} md={3}>
                                <CircularProgress color="orange" size={30} />
                              </Grid>
                            )}
                          </Grid>
                        ))}
                      </Grid>
                    ) : (
                      <Typography
                        style={{
                          marginLeft: "18rem",
                          marginTop: "1rem",
                          fontSize: "15px",
                        }}
                      >
                        No Images
                      </Typography>
                    )}
                  </>
                )}
                <Grid item xs={12} sm={12} md={12}></Grid>
                <h3 className="createaccount-widget-title">Audit Logs</h3>
                <IconButton
                  onClick={() => setOpenAuditLogs(!hideAuditLogs)}
                  style={{ marginTop: "1%" }}
                >
                  <Visibility />
                </IconButton>
                {hideAuditLogs && (
                  <>
                    <Grid container className="audit-log">
                      <Grid item xs={12} sm={3} md={3}>
                        <strong>Time Stamp</strong>
                      </Grid>
                      <Grid item xs={12} sm={3} md={3}>
                        <strong>Action by</strong>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <strong>Action Performed</strong>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      style={{ borderBottom: "1px solid lightgray" }}
                    >
                      {auditLogs.map((logs) => (
                        <Grid container className="audit-log-data">
                          <Grid item xs={12} sm={3} md={3}>
                            <span>
                              {logs.time_stamp
                                ? moment
                                    .utc(logs.time_stamp)
                                    .local()
                                    .format("MM/DD/YYYY hh:mm a")
                                : ""}
                            </span>
                          </Grid>
                          <Grid item xs={12} sm={3} md={3}>
                            <span>{logs.action_by}</span>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <span>{logs.action}</span>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
                <Grid container>
                  <Grid item xs={12} sm={3} md={3}></Grid>
                  <Grid
                    item
                    xs={12}
                    sm={10}
                    md={10}
                    style={{ padding: "0px 20px 0px 20px" }}
                  >
                    <FormControl fullWidth className={classes.customInputStyle}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        disableElevation
                        className={classes.btnStyle}
                        disableFocusRipple
                        type="submit"
                      >
                        Save
                      </Button>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewInventory;
