import React, { useEffect, useState } from "react";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
//react 
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Loader from "react-loader-spinner";
import { LOADER_TYPE } from "global_constants/app_constants";
import theme from "theme";
// Formik
import { useFormik } from "formik";
//checkbox
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
// Material UI Components
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {hotjar} from "react-hotjar";
import GridItem from "components/Grid/GridItem";
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import NotificationAlert from "views/Components/NotificationAlert";
import Switch from "@material-ui/core/Switch";
//markdown editor
import Editor from "react-markdown-editor-lite";
import ReactMarkdown from "react-markdown";
import MarkdownIt from 'markdown-it';
//markdown editor import style manually
import 'react-markdown-editor-lite/lib/index.css';
import queryString from "query-string";
//API Call
import { CMSAPIWithToken } from "../../../apis/CMS";
import { Api, Urls } from "config/api";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";

const inputProps = {
  disableAnimation: true,
  shrink: false
};

const customStyles = theme => ({
  mainContainer: {
    padding: "40px"
  },
  container: {
    marginTop: "20px"
  },
  formContainer: {
    width: "100%"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    position: "absolute",
    width: 550,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    display: "block"
  },
  buttStyle: {
    background: theme.palette.buttonColor.main,
    "&:hover": {
      background: theme.palette.buttonColor.main
    }
  },
  firstTitleLabel: {
    padding: "0 15px"
  },
  titleLabel: {
    padding: "0 15px",
    marginTop: "30px"
  },
  errorText: {
    fontSize: "12px",
    color: theme.palette.error.main
  }
});




const SandboxDetailsCreate = props => {
  const useStyles = makeStyles(customStyles);
  const classes = useStyles();
  const mdEditor = React.useRef(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [value, setValue] = React.useState("ipv4");
  const [overview, setOverview] = useState('');
  const [nodeDetails, setNodeDetails] = useState('');
  const [interfaceDetails, setInterfaceDetails] = useState('');
  const [accessDetails, setAccessDetails] = useState('');
  const [clusteripDetails, setClusteripDetails] = useState('');
  let queryParams = queryString.parse(window.location.search);
  var sb_id = queryParams.id;
  var sandbox_id_title_map = JSON.parse(localStorage.getItem("sandbox_id_title_map"))
  var sandbox_title = sandbox_id_title_map[sb_id]

  const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
     getAccountInfo();
    }, []);
    
  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
      admin_sandboxes_id: sb_id,
      overview: "",
      node_details: "",
      interface_details: "",
      access_details: "",
      clusterip_details: "",
      access_device: "",
      show_sandbox_details: false,
      ip_details_access: "",
      show_ip_details_access: false
              },
    onSubmit: (values) => {
      let payload = {
        admin_sandboxes_id: values.admin_sandboxes_id, 
        overview: overview,
        node_details: nodeDetails, 
        interface_details: interfaceDetails,
        access_details: accessDetails,
        clusterip_details: clusteripDetails,
        access_device: values.access_device,
        show_sandbox_details: values.show_sandbox_details,
        ip_details_access: values.ip_details_access,
        show_ip_details_access: values.show_ip_details_access,
      };

        CMSAPIWithToken() 
        .post("/poc/admin_sandboxes_details/",payload) 
        .then((resp) => resp.data.results)
        .then((data) => {
            const payload = {
                message: "Sandbox Details Created Successfully",
                color: "success",
            };
            payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
            history.push("ProductContentSandboxesMenuItems?id="+sb_id+"&title="+sandbox_title);
            // window.location.reload()
        })
        .catch((err) => {
          let message = err.response.data.message;
          const payload = {
            message: message,
            color: "danger",
        };
        payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
    },
    validate: (values) => {
      let errors = {};
      if (!values.admin_sandboxes_id) {
        errors.admin_sandboxes_id = "Required";
      }
      return errors;
    },
  });

   //mark down editor 
 const handleEditorChange = ({ html, text }) => {
  const newValue = text;
  //console.log(newValue);
  setOverview(newValue);
};
const handleNodeDetailsChange = ({ html, text }) => {
  const newValue = text;
  //console.log(newValue);
  setNodeDetails(newValue);
};
const handleInterfaceDetailsChange = ({ html, text }) => {
  const newValue = text;
  //console.log(newValue);
  setInterfaceDetails(newValue);
};
const handleAccessDetailsChange = ({ html, text }) => {
  const newValue = text;
  //console.log(newValue);
  setAccessDetails(newValue);
};
const handleClusteripDetailsChange = ({ html, text }) => {
  const newValue = text;
  //console.log(newValue);
  setClusteripDetails(newValue);
};
// Initialize a markdown parser
const mdParser = new MarkdownIt(/* Markdown-it options */);

  const getAccountInfo = () => {
    SDCloudBackendAPIWithToken()
    .get(Api.accountInfo)
    .then(res => {
    
    })
    .then(() => {
      setIsLoading(false);
    })
    .catch(err => {
      //console.log(err.response);
      setIsLoading(false);
    });
  };

  let Cancelbutton=()=>{
    history.push("ProductContentSandboxesMenuItems?id="+sb_id+"&title="+sandbox_title);
    // window.location.reload()
  }

  return (
    // Form modal for sandbox create
    <div>
      <LoaderHoc isLoading={isLoading}>
        <Paper elevation={0}>
          <Grid
            container
            className={classes.mainContainer}
            style={{ marginTop: "2%" }}
          >
            <form
              onSubmit={formik.handleSubmit}
              className={classes.formContainer}
            >
              {/* <Typography variant="h4">{props.formTitle}</Typography> */}
              <Typography variant="h4" className={classes.firstTitleLabel}>
              Add Sandbox Details
              </Typography>
              <Grid container className={classes.container}>
              <GridItem md={12} >
                  {formik.errors.admin_sandboxes_id && formik.touched.admin_sandboxes_id ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Sandboxes Id"
                      name="admin_sandboxes_id"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.admin_sandboxes_id}
                      onChange={formik.handleChange}
                      helperText={formik.errors.admin_sandboxes_id}
                      onBlur={formik.handleBlur}
                      disabled='disabled'
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Sandboxes Id"
                      name="admin_sandboxes_id"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.admin_sandboxes_id}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>
                
                <GridItem md={12}>
                <p>Overview: </p>
                  <Editor
                    ref={mdEditor}
                    value={overview}
                    style={{
                      height: "500px"
                    }}
                    onChange={handleEditorChange}
                    renderHTML={text => <ReactMarkdown source={text} />}
                  />
                </GridItem>
                

                <GridItem md={12}>
                  <p>Node Details : </p>
                <Editor
                    ref={mdEditor}
                    value={nodeDetails}
                    
                    style={{
                      height: "500px"
                    }}
                    onChange={handleNodeDetailsChange}
                    renderHTML={text => <ReactMarkdown source={text} />}
                  />
                </GridItem>

                <GridItem md={12}>
                <p>Interface Details : </p>
                <Editor
                    ref={mdEditor}
                    value={interfaceDetails}
                    
                    style={{
                      height: "500px"
                    }}
                    onChange={handleInterfaceDetailsChange}
                    renderHTML={text => <ReactMarkdown source={text} />}
                  />
                </GridItem>

                <GridItem md={12}>
                <p>Access Details : </p>
                <Editor
                    ref={mdEditor}
                    value={accessDetails}
                    
                    style={{
                      height: "500px"
                    }}
                    onChange={handleAccessDetailsChange}
                    renderHTML={text => <ReactMarkdown source={text} />}
                  />
                </GridItem>

                <GridItem md={12}>
                <p>Clusterip Details : </p>
                <Editor
                    ref={mdEditor}
                    value={clusteripDetails}
                    
                    style={{
                      height: "500px"
                    }}
                    onChange={handleClusteripDetailsChange}
                    renderHTML={text => <ReactMarkdown source={text} />}
                  />
                </GridItem>

                <GridItem md={12}>
                  <TextField
                    type="text"
                    variant="filled"
                    label="Access Device"
                    name="access_device"
                    fullWidth
                    InputLabelProps={inputProps}
                    value={formik.values.access_device}
                    onChange={formik.handleChange}
                  />
                </GridItem>

                
                <GridItem md={12}>
                <Grid item xs={12} sm={12} md={12}>
                   <span>Show Sandbox Details</span>
                     <FormControlLabel
                     control={<Checkbox checked={formik.values.show_sandbox_details} />} 
                     name="show_sandbox_details"  
                     onChange={formik.handleChange}     
                        />          
                  </Grid>
                </GridItem>

                <GridItem md={12}>
                  <TextField
                    type="text"
                    variant="filled"
                    label="IP Details Access"
                    name="ip_details_access"
                    fullWidth
                    InputLabelProps={inputProps}
                    value={formik.values.ip_details_access}
                    onChange={formik.handleChange}
                  />
                </GridItem>

                <GridItem md={9}>
                <Grid item xs={12} sm={12} md={12}>
                <span>Show IP Details Access</span>
                     <FormControlLabel
                     control={<Checkbox checked={formik.values.show_ip_details_access} />} 
                     name="show_ip_details_access"  
                     onChange={formik.handleChange}     
                        /> 
                  </Grid>
                </GridItem>


              </Grid>
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{ marginTop: "20px", marginBottom: "30px" }}
              >
                <GridItem xs={12} sm={6} md={3} lg={3}>
                  {!btnDisabled ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      disableElevation
                      size="large"
                      className={classes.buttStyle}
                      fullWidth
                      disableFocusRipple
                      type="submit"
                    >
                      Submit
                    </Button>
                  ) : null}
                  {btnDisabled ? (
                    <Loader
                      type={LOADER_TYPE}
                      color={theme.palette.secondary.main}
                      width={50}
                      height={50}
                      style={{ float: "right", marginRight: "15px" }}
                    />
                  ) : null}
                </GridItem>
                <GridItem xs={12} sm={6} md={3} lg={3}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    disableElevation
                    size="large"
                    // className={classes.buttStyle}
                    fullWidth
                    onClick={Cancelbutton}
                    disableFocusRipple
                    type="submit"
                  >
                    Cancel
                  </Button>
                </GridItem>
              </Grid>

              <NotificationAlert
                notificationType={notificationType}
                showNotification={showNotification}
                message={notificationMessage}
              />
            </form>
          </Grid>
        </Paper>
      </LoaderHoc>
    </div>
  );
};

export default SandboxDetailsCreate;
