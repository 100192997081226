import React, { useState, useEffect } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Urls } from "config/api";
// @material-ui/core components
import BellIcon from "react-bell-icon";
import { makeStyles } from "@material-ui/core/styles";
import HeadsetIcon from "@material-ui/icons/Headset";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import { Grid, Tooltip, Badge } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { Popper } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";

// @material-ui/icons
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import InfoRoundedIcon from "@material-ui/icons/InfoOutlined";
import IconButton from "@material-ui/core/IconButton";
import QueryBuilderOutlinedIcon from "@material-ui/icons/QueryBuilderOutlined";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";

// core components
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import { LOGOUT } from "action_creators/actioncreator";
import NotificationRightSideDrawer from "views/Components/NotificationRightSideDrawer";
import HelpSideBar from "views/Components/HelpSideBar";
import { Api } from "config/api";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import { ReactComponent as passwordSvg } from "assets/criterion_networks/svg/password-star.svg";
import SvgtoIcon from "utils/SvgtoIcon";

const useStyles = makeStyles(styles);

const MENU_NAMES = {
  help: "help",
  notification: "notification",
  feedback: "feedback",
  support: "support",
};
export default function HeaderLinks(props) {
  let notificationCount = 0;

  const dispatch = useDispatch();

  const userData = useSelector((state) => state.profileObj.profileObj);
  const firstname = userData?.firstname || "Loading...";
  const lastname = userData?.lastname || "Loading...";
  const privilege = userData?.privilege || "Loading...";
  const { rtlActive, history } = props;
  const [showRightDrawer, toggleRightDrawer] = useState(false);
  const [currentMenu, setCurrentMenu] = React.useState(MENU_NAMES.help);
  const profileData = JSON.parse(localStorage.getItem("profileData"));
  const account = profileData.account;
  let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
  let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
  let hasDomain = domain?.some((res) => userEmail.includes(res));
  const [isInternal, setIsInternal] = useState(hasDomain);

  useEffect(() => {
    renderNoitificationCount();

    let countUpdateInterval = setInterval(() => {
      renderNoitificationCount(countUpdateInterval);
    }, 60000);
    return () => {
      clearInterval(countUpdateInterval);
    };
  });

  const [newCount, setNewCount] = useState(0);
  const [notificationBadgeCount, setNotificationBadgeCount] = useState(
    notificationCount
  );
  const renderNoitificationCount = () => {
    SDCloudBackendAPIWithToken()
      .get(`${Api.getNotifications}`)
      .then((resp) => resp.data)
      .then((resp) => {
        let count = 0;
        resp.data.map((el) => {
          if (!el.mark_read) {
            count += 1;
          }
        });
        setNewCount(count);

        let readCount = resp.data.filter((x) => x.mark_read === false);
        notificationCount = count ? readCount.length : count;
        setNotificationBadgeCount(notificationCount);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleClickNotification = () => {
    toggleRightDrawer(!showRightDrawer);
    setCurrentMenu(MENU_NAMES.notification);
  };

  const handleClickFeedback = () => {
    toggleRightDrawer(!showRightDrawer);
    setCurrentMenu(MENU_NAMES.feedback);
  };

  const handleClickHelp = () => {
    SDCloudBackendAPIWithToken()
      .get(Api.getSSO)
      .then((sso) => window.open(sso.data.urls, "_blank"));
  };

  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const handleLogout = () => {
    localStorage.removeItem("Title");
    localStorage.removeItem("SubTitle");
    localStorage.removeItem("BrandLogo");
    localStorage.removeItem("BrandLogoInv");
    localStorage.removeItem("BrandLogoSmall");
    localStorage.removeItem("PrimaryColor");
    localStorage.removeItem("SecondaryColor");
    localStorage.removeItem("ButtonColor");
    localStorage.removeItem("FontFamily");
    localStorage.removeItem("PanelColor");
    dispatch(LOGOUT(history));
  };
  const handleMyProfile = () => {
    history.push({
      pathname: Urls.passwordReset,
      state: { profileAction: "My information", profileObj: userData },
    });
    setOpenProfile(null);
  };
  const handlePasswordReset = () => {
    history.push({
      pathname: Urls.passwordReset,
      state: { profileAction: "Password reset" },
    });
    setOpenProfile(null);
  };
  const handleActivityLogs = () => {
    history.push({
      pathname: Urls.passwordReset,
      state: { profileAction: "Activity logs" },
    });
    setOpenProfile(null);
  };
  if (window.location.href.indexOf("resetPassword") > 1) {
    handlePasswordReset();
  } else if (window.location.href.indexOf("activity") > 1) {
    handleActivityLogs();
  }
  const classes = useStyles();
  const dropdownItem = classNames(
    classes.font,
    classes.dropdownItem,
    classes.primaryHover,
    classes.settingsMenuItem,
    {
      [classes.dropdownItemRTL]: rtlActive,
    }
  );
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive,
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });

  const generateDrawerComponent = (iconName) => {
    if (iconName === "notification") {
      return <NotificationRightSideDrawer toggleDrawer={toggleRightDrawer} />;
    } else if (iconName === "support") {
      return (
        <HelpSideBar expanded="support" toggleDrawer={toggleRightDrawer} />
      );
    } else if (iconName === "feedback") {
      return (
        <HelpSideBar expanded="feedback" toggleDrawer={toggleRightDrawer} />
      );
    } else if (iconName === "help") {
      return <HelpSideBar expanded="help" toggleDrawer={toggleRightDrawer} />;
    }
  };
  const StaticBell = () => {
    return (
      <BellIcon
        width="39"
        className={
          classes.headerLinksSvg +
          " " +
          (rtlActive ? classes.links + " " + classes.linksRTL : classes.links)
        }
      />
    );
  };
  const RingingBell = () => {
    const [ringingTime, setRingingTime] = useState(true);
    setTimeout(() => setRingingTime(false), 2000);
    return (
      <BellIcon
        width="39"
        active={ringingTime}
        animate={ringingTime}
        className={
          classes.headerLinksSvg +
          " " +
          (rtlActive ? classes.links + " " + classes.linksRTL : classes.links)
        }
      />
    );
  };
  return (
    <div className={wrapper}>
      <Drawer
        anchor={"right"}
        open={showRightDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        PaperProps={{ id: "infiniteScrollNotification" }}
        onClose={() => toggleRightDrawer(!showRightDrawer)}
      >
        {generateDrawerComponent(currentMenu)}
      </Drawer>

      <div className={managerClasses}>
        {/* Help button */}

        <Button
          color="transparent"
          justIcon
          aria-label="HelpOutlineOutlinedIcon"
          // title="Help and Support"
          onClick={handleClickHelp}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : "",
          }}
        >
          <Tooltip title="Help and Support" arrow="true" placement="top">
            <HelpOutlineOutlinedIcon
              className={
                classes.headerLinksSvg +
                " " +
                (rtlActive
                  ? classes.links + " " + classes.linksRTL
                  : classes.links)
              }
              style={{ color: "black" }}
            />
          </Tooltip>
          <Hidden mdUp implementation="css">
            <span className={classes.linkText} onClick={handleClickHelp}>
              {"Help"}
            </span>
          </Hidden>
        </Button>

        {/* Feedback button */}
        <Button
          color="transparent"
          justIcon
          aria-label="HeadsetMicIcon"
          title="Feedback"
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          onClick={handleClickFeedback}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : "",
          }}
        >
          <Tooltip title="Feedback" arrow="true" placement="top">
            <HeadsetIcon
              className={
                classes.headerLinksSvg +
                " " +
                (rtlActive
                  ? classes.links + " " + classes.linksRTL
                  : classes.links)
              }
              style={{ color: "black" }}
            />
          </Tooltip>
          <Hidden mdUp implementation="css">
            <span className={classes.linkText} onClick={handleClickFeedback}>
              {"Feedback"}
            </span>
          </Hidden>
        </Button>
        {/* Notification button */}
        <Tooltip title="Alerts" arrow="true">
          <Button
            color="transparent"
            justIcon
            aria-label="Notifications"
            aria-owns={"notification-menu-list"}
            aria-haspopup="true"
            onClick={() => handleClickNotification()}
            // onClick={handleOpen}
            className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
            muiClasses={{
              label: rtlActive ? classes.labelRTL : "",
            }}
          >
            {newCount > 0 ? <RingingBell /> : <StaticBell />}

            {/* show number of notification */}
            {notificationBadgeCount ? (
              <span className={classes.notifications}>
                {notificationBadgeCount}
              </span>
            ) : null}
            <Hidden mdUp implementation="css">
              <span
                className={classes.linkText}
                onClick={() => handleClickNotification()}
              >
                {rtlActive ? "إعلام" : "Notification"}
              </span>
            </Hidden>
          </Button>
        </Tooltip>
      </div>

      <div
        style={{
          display: "inline-block",
          verticalAlign: "middle",
          marginLeft: "28px",
        }}
      >
        <div
          style={{ height: "50px", display: "flex" }}
          className={classes.userInfoContainer}
          // onClick={handleClickProfile}
        >
          <div style={{ marginRight: "9px", paddingTop: "5px" }}>
            <p className={classes.firstName}>{firstname}</p>
            <p className={classes.privilege}>
              {privilege == "developer" ? "user" : privilege}
            </p>
          </div>
          <div
            style={{
              borderRadius: "100%",
              backgroundColor: "#CBCBCB",
              width: "50px",
              height: "50px",
              textAlign: "center",
              cursor: "pointer",
            }}
            aria-owns={openProfile ? "profile-menu-list" : null} // links the popper to div
            aria-haspopup="true" // links the popper to div
            // onMouseOver={handleClickProfile}
          >
            <p
              style={{ margin: "14px", textTransform: "uppercase" }}
              onMouseOver={handleClickProfile}
              onClick={handleClickProfile}
            >
              {[firstname[0], lastname[0]]}
            </p>
          </div>
        </div>
        <Hidden mdUp implementation="css">
          <span onClick={handleClickProfile} className={classes.linkText}>
            {rtlActive ? "الملف الشخصي" : "Profile"}
          </span>
        </Hidden>
        {/* </Button> */}
        <Popper
          open={Boolean(openProfile)}
          modifiers={{
            offset: {
              offset: "0, 15",
            },
          }}
          anchorEl={openProfile}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleMyProfile}
                      className={dropdownItem}
                      style={{ padding: "10px 14px" }}
                    >
                      <Grid container>
                        <Grid item xs={3}>
                          <InfoRoundedIcon
                            className={classes.settingLinksSvg}
                          />
                        </Grid>
                        <Grid item xs={8}>
                          {"My Settings"}
                        </Grid>
                      </Grid>
                    </MenuItem>
                    {/*<MenuItem*/}
                    {/*  onClick={handlePasswordReset}*/}
                    {/*  className={`${dropdownItem} password-link`}*/}
                    {/*  style={{ padding: "10px 14px" }}*/}
                    {/*>*/}
                    {/*  <Grid container>*/}
                    {/*    <Grid item xs={1} style={{ flexBasis: "0" }}>*/}
                    {/*      <IconButton className={classes.passwordResetSvg}>*/}
                    {/*        <SvgtoIcon svgSource={passwordSvg} />*/}
                    {/*      </IconButton>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={1} style={{ flexBasis: "0" }}>*/}
                    {/*      <IconButton className={classes.passwordResetSvg}>*/}
                    {/*        <SvgtoIcon svgSource={passwordSvg} />*/}
                    {/*      </IconButton>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={2}>*/}
                    {/*      <IconButton className={classes.passwordResetSvg}>*/}
                    {/*        <SvgtoIcon svgSource={passwordSvg} />*/}
                    {/*      </IconButton>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={8}>*/}
                    {/*      {"Password reset"}*/}
                    {/*    </Grid>*/}
                    {/*  </Grid>*/}
                    {/*</MenuItem>*/}
                    {/*<MenuItem*/}
                    {/*  onClick={handleActivityLogs}*/}
                    {/*  className={dropdownItem}*/}
                    {/*  style={{ padding: "10px 14px" }}*/}
                    {/*>*/}
                    {/*  <Grid container>*/}
                    {/*    <Grid item xs={3}>*/}
                    {/*      <QueryBuilderOutlinedIcon*/}
                    {/*        className={classes.settingLinksSvg}*/}
                    {/*      />*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={8}>*/}
                    {/*      {"Activity Logs"}*/}
                    {/*    </Grid>*/}
                    {/*  </Grid>*/}
                    {/*</MenuItem>*/}
                    <MenuItem
                      onClick={handleLogout}
                      className={dropdownItem}
                      style={{ padding: "10px 14px" }}
                    >
                      <Grid container>
                        <Grid item xs={3}>
                          <ExitToAppOutlinedIcon
                            className={classes.settingLinksSvg}
                          />
                        </Grid>
                        <Grid item xs={8}>
                          {"Log out"}
                        </Grid>
                      </Grid>
                    </MenuItem>
                  {isInternal ?(
                  <MenuItem
                      className={dropdownItem}
                      style={{ padding: "10px 14px" }}
                    >
                      <Grid container>
                       <Grid item xs={8}>
                         {account}
                       </Grid>
                     </Grid>
                     
                    </MenuItem>): null}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
  history: PropTypes.object,
};
