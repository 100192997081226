import React, { useState } from "react";

// Material UI Components
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";

import deleteSvg from "../../../assets/criterion_networks/svg/trash.svg";
import copySvg from "../../../assets/criterion_networks/svg/copy-black.svg";
import deleteicon from 'assets/img/deleteicon.png';

const useStyles = makeStyles(theme => ({
  deleteIcon: {
    height: "40px",
    width: "40px",
    border: "1px solid grey",
    borderRadius: "0",
    fill: theme.palette.grey["900"]
  },
  headerContainer: {
    margin: "50px 0"
  },
  formControl: {
    marginTop: "0",
    width: "100%"
  }
}));

const SearchHeader = ({ handleDelete, templates, setSearchList }) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");

  const handleChange = e => {
    const { value } = e.target;
    const searchList = templates.filter(obj =>
      ["name", "status"].some(key => {
        return obj[key] && obj[key].toLowerCase().includes(value.toLowerCase());
      })
    );
    setSearchText(value);
    setSearchList(value ? searchList : templates);
  };

  return (
    <div className="search-header">
      <Grid
        container
        className={classes.headerContainer}
        alignItems="center"
        justify="space-between"
        wrap="wrap"
        style={{margin: "23px 0"}}
        
      >
        <Grid className="first-col" item xs={12} sm={12} md={12}>
          <Button
            variant="outlined"
            color="secondary"
            disableElevation
            size="large"
            fullWidth
            disableFocusRipple
            className="gray-btn trash"
            onClick={handleDelete}
          >
            <span>
              <img alt="trash" src={deleteicon} />
            </span>
          </Button>
          {/* <Button
            variant="outlined"
            color="secondary"
            disableElevation
            size="large"
            fullWidth
            disableFocusRipple
            className="gray-btn copy"
            onClick={() => {}}
          >
            <span>
              <img alt="copy" src={copySvg} />
            </span>
          </Button>*/}
          <TextField
            type="search"
            variant="filled"
            className="search-input"
            fullWidth
            onChange={handleChange}
            value={searchText || ""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </Grid>

        {/*<Grid className="main-btns" item xs={12} md={4}>
          <Button
            variant="outlined"
            color="secondary"
            disableElevation
            size="large"
            fullWidth
            disableFocusRipple
            className="gray-btn templates-btn"
            onClick={() => {}}
          >
            Templates
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            disableElevation
            size="large"
            fullWidth
            disableFocusRipple
            className="gray-btn povs-btn"
            onClick={() => {}}
          >
            Mentored POVs
          </Button>
        </Grid>*/}
      </Grid>
    </div>
  );
};

export default SearchHeader;
