import React, { useEffect, useState } from "react";

// Material UI Components
import { Alert } from "@material-ui/lab";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";


// Local
import CustomTable from "./Table";
import { SDCloudBackendAPIWithToken } from "../../../apis/backendAPI";
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import {
  FormControl,
  TextField,
  Typography,
  Button,
  TextareaAutosize,
} from "@material-ui/core";

// Styling
const useStyles = makeStyles((theme) => ({
  root: {
    // padding: "30px 20px",
  },
}));

const TrainingDetails = (props) => {
  const classes = useStyles();
  const description = props.description;
  const training_name = props.training_name;
  // States
  const [rows, setRows] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // useEffects
  useEffect(() => {
    renderData();
  }, []);

  const columns = [
    {
      Header: "Username",
      accessor: "username",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Privilege",
      accessor: "privilege",
    },
    {
      Header: "Permission Profile",
      accessor: "profile",
    },
  ];

  let data = [];

  const renderData = async () => {
    let response = SDCloudBackendAPIWithToken()
      .post("instructer/get_training_details", {
        training_name:props.training_name,
        reference_id:props.reference_id,
      })
      .then((resp) => resp.data.users)
      .then((resp) => {
        resp.forEach((item) => {
          data.push({
            email: item.email,
            username: item.username,
            privilege: item.privilege,
            profile: item.permission_profile_name
              ? item.permission_profile_name
              : "None",
          });
        });

        setRows(data);
        setIsLoading(false);
      })
      .catch((err) => {
        let message = err.response.data.message;
        setShowNotification(true);
        setNotificationType("error");
        setNotificationMessage("Failed to retrieve the data");
      });
  };


  const renderuser = () =>{
    renderData()

  }
  return (
    <div style={{padding: 20}}>
      <Grid container className={classes.root} spacing={1}>
        <Grid item xs={12} md={12}>
          {showNotification ? (
            <Alert
              onClose={() => setShowNotification(false)}
              severity={notificationType}
            >
              {notificationMessage}
            </Alert>
          ) : null}
        </Grid>
        <Grid style={{paddingLeft:40}} item xs={12} sm={4} md={4}>
        <Typography variant="body2" className={classes.body2}>
            Name
          </Typography>
          <FormControl fullWidth>
              <TextField
                value={training_name}
              />
            </FormControl>
          </Grid>
        <Grid style={{paddingLeft:30}} item xs={12} sm={8} md={8}>
        <Typography variant="body2" className={classes.body2}>
            Description
          </Typography>
          <FormControl fullWidth>
              <TextField
                value={description}
              />
            </FormControl>
          </Grid>
        <Grid item xs={12} md={12}>
          <LoaderHoc isLoading={isLoading}>
            <CustomTable
              columns={columns}
              data={rows}
              tableTitle="Users"
              dropDownValues={[]}
            />
          </LoaderHoc>
        </Grid>
      </Grid>
    </div>
  );
};

export default TrainingDetails;