import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// Material UI Components
import { Alert } from "@material-ui/lab";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// Local
import { useDispatch } from "react-redux";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import CustomTable from "../components/Table";
import AddNodeModal from "./components/AddNodeModal";
import RightDrawer from "../components/RightDrawer";
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import { SDCloudBackendAPIWithToken } from "../../../apis/backendAPI";
import { IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import EditIcon from '@material-ui/icons/Edit';
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
import { getDesignerObjects } from "../../../apis/APIServices";

// Styling
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "50px",
  },
  item: {
    textAlign: "end",
  },
  button: {
    background: `${theme.palette.buttonColor.main} !important`,
    height: "40px",
    color: "white",
    border: "none",
    width: "15%",
    fontSize: "0.875rem",
    fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    cursor: "pointer",
  },
  container: {
    float: "right",
  },
}));

const ViewTraining = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [rows, setrows] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentScreen, setCurrentScreen] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [currentTraining, setCurrentTraining] = useState("");
  const [currentRef, setCurrentRef] = useState("");
  const [trainingDescription, setTrainingDescription] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState([]);
  useEffect(() => {
    dispatch(SET_NAVBAR_TITLE(" Designer Elements"));
    localStorage.removeItem("node_id");
    localStorage.removeItem("node_name");
    localStorage.removeItem("node_version");
    localStorage.removeItem("design_objects");
    getDesignObjects();
    renderrow();
  }, []);
  // States
  const columns = [
    {
      Header: "Icon",
      accessor: "icon_url",
    },
    {
      Header: "Element ID",
      accessor: "element_id",
    },
    {
      Header: "Element Name",
      accessor: "element_name",
    },
    {
      Header: "Element Version",
      accessor: "element_version",
    },
    {
      Header: "Element State",
      accessor: "element_state",
    },
    {
      Header: "Action",
      accessor: "action",
    },
  ];

  const getDesignObjects = async () => {
    const designObject = localStorage.getItem("design_objects");
    if (!designObject) {
      const response = await getDesignerObjects();
      if (response.success) {
        localStorage.setItem("design_objects", JSON.stringify(response.data));
      }
    }
  };

  let data = [];
  const renderrow = async (value) => {
    return SDCloudBackendAPIWithToken()
        .get("/inventory/list_designer_nodes")
        .then((resp) => resp.data.nodes)
        .then((resp) => {
            resp.forEach((item) => {
                data.push({
                  element_id: item.nodeid,
                  element_name: item.name,
                  element_version:item.version,
                  element_state:item.state,
                  icon_url: (<div className="display-icon">
                  {item.icon_url.trim() !== "" ? (
                    <img
                      className="img-fluid image-dim"
                      src={item.icon_url}
                      alt=""
                      style={{width:"40px",height:"40px"}}
                    ></img>
                  ) : (
                    item.icon_url
                  )}
                </div>),
                  action: (
                    <IconButton onClick={() => handleViewButton(item.nodeid, item.name, item.version)}>
                      <EditIcon />
                    </IconButton>
                  ),
                });
              });
              setrows(data);
              setIsLoading(false);
        })
        .catch((err) => {
        
        }); 
  };

  const handleDeleteButton = (selectedRowIds, selectedFlatRows) => {
    alert("This functionality is currently not available")
    return false;
  };
  const handleViewButton = (nodeid, name, version) => {
    history.push("/create-element?id="+nodeid+"&name="+name+"&version="+version+"&mode=edit"); 
  };

  const handleAddButton = (nodeid, name, version) => {
    //setIsModal(true)
    history.push("/create-element?mode=add"); 
  };

  const handleAddNodeButton = (nodeid, name, version) => {
    history.push("/create-element?id="+nodeid+"&name="+name+"&version="+version+"&mode=edit"); 
  };

  const renderNotififications = () => {
    let notifications = notificationMessage.map((item) => {
      let notificationType = item.success ? "success" : "error";
      return (
        <Alert
          onClose={() => setShowNotification(false)}
          severity={notificationType}
          spacing={1}
        >
          {item.message}
        </Alert>
      );
    });

    return notifications;
  };

  return (
    <div>
      <Grid container className={classes.root} spacing={1}>
        <Grid item xs={12} md={12}>
          {showNotification ? renderNotififications() : null}
        </Grid>
        <Grid item style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={handleAddButton}
                variant="contained"
                color="secondary"
                disableElevation
                size="large"
                fullWidth
                disableFocusRipple
                className={classes.btnColor}
                type="submit"
                style={{ height: "40px", marginRight: "10px" }}
              >
                Add New Custom Element
              </Button>
            </Grid>
          {isModal && (
          <AddNodeModal
            visible={isModal}
            onClose={() => {
              setIsModal(false);
            }}
          />
        )}
        <Grid item xs={12} md={12}>
          <LoaderHoc isLoading={isLoading}>
            <CustomTable
              columns={columns}
              data={rows}
              handleDeleteButtonClick={handleDeleteButton}
              handleViewButtonClick={handleViewButton}
              tableTitle="Designer Elements"
              hiddenColumns={[]}
            />
          </LoaderHoc>
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewTraining;
