import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { Alert } from "@material-ui/lab";
import { Card } from "@material-ui/core";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withRouter } from "react-router-dom";
import CardFooter from "components/Card/CardFooter";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { PRIMARY_COLOR } from "global_constants/app_constants";
import { Api } from "config/api";
import "./Profile.scss";
import {
  FormControl,
  InputLabel,
  FilledInput,
  makeStyles,
  Icon,
  Button
} from "@material-ui/core";

import {
  PASSWORD_FORM_LABELS,
  strongRegex,
  mediumRegex
} from "./utils/constants/constants_profile";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";

const customStyles = theme => ({
  containerStyles: {
    padding: "0.9375rem 70px"
  },
  formInputStyles: {
    margin: "10px auto 10px auto",
    [theme.breakpoints.down("sm")]: {
      margin: "15px"
    }
  },
  inputLabelStyles: {
    // marginBottom: "35px",
    position: "relative"
  },
  cardStyles: {
    color: "rgba(0, 0, 0, 0.87)",
    width: "100%",
    border: "0",
    display: "flex",
    position: "relative",
    fontSize: ".875rem",
    minWidth: "0",
    wordWrap: "break-word",
    background: "#FFF",
    marginTop: "30px",
    borderRadius: "6px",
    marginBottom: "30px",
    flexDirection: "column"
  },
  cardHeaderStyles: {
    padding: "0.9375rem 65px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative",
    "& h3": {
      fontFamily: theme.palette.fontList.selectedFont
    },
    "& p": {
      fontFamily: theme.palette.fontList.selectedFont
    }
  },
  cardBodyStyles: {
    padding: "0.9375rem 65px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative"
  },
  cardFooterStyles: {
    padding: "0.9375rem 50px",
    // margin: "35px 0 35px 0",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative"
  },
  buttonStyles: {
    background: PRIMARY_COLOR,
    color: "white",
    width: "100%",
    minHeight: "20px",
    padding: "15px 10px 15px",
    fontSize: "1rem",
    textTransform: "none"
  },
  errorText: {
    fontSize: "12px",
    color: theme.palette.error.main
  },
  btnStyle: {
    background: `${theme.palette.buttonColor.main} !important`,
  }
});

class UpdatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      formCardOpacity: "",
      showNotification: false,
      notificationType: "",
      notificationMessage: "",
      showPassword: false,
      labelPseudoClass: "input-label-pseudo",
      inputPseudoClass: "input-pseudo",
      confirmPasswordLabelClass: "input-label-pseudo",
      confirmPasswordClass: "input-pseudo",
      isPasswordStrengthMsg: false
    };
  }

  handleChangeIssue = () => {
    //console.log("ok");
  };

  handleSubmitForm = () => {
    this.setState({ formCardOpacity: 0.3 });
    SDCloudBackendAPIWithToken()
      .put(Api.updateUserProfile, {
        password: this.state.password,
        username: localStorage.getItem("userName")
      })
      .then(res => {
        const payload = {
          message: res.data
            ? res?.data?.message
            : "Password reset successfully",
          color: "success"
        };
        // dispatch(SHOW_NOTIFICATION({ payload }));
        this.setState({ formCardOpacity: 1 });
        this.setState({
          showNotification: true,
          notificationType: "success",
          notificationMessage: payload.message
        });
      })
      .catch(err => {
        const payload = {
          message: err?.response?.data?.message || "unknown error occured",
          color: "danger",
          error: err
        };
        this.setState({ formCardOpacity: 1 });
        this.setState({
          showNotification: true,
          notificationType: "error",
          notificationMessage: payload.message
        });
      });
  };

  handleClickShowPassword = () => {
    this.setState({ ...this.state, showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  handleConfirmPassword = name => {
    // Check if confirm password and original password is same
    if (name == "confirmPassword") {
      if (this.state.confirmPassword != this.state.password) {
        this.setState({
          confirmPasswordLabelClass: "wrong-pass-lable-pseudo",
          confirmPasswordClass: "wrong-pass-pseudo"
        });
      } else {
        this.setState({
          confirmPasswordLabelClass: "right-pass-lable-pseudo",
          confirmPasswordClass: "right-pass-pseudo"
        });
      }
    }
  };

  //handle state updates passed from custom child components to create node parent
  handleFormFieldChange = stateUpdateObj => {
    const {
      target: { name, value }
    } = stateUpdateObj;

    // analyze password strength
    if (strongRegex.test(value)) {
      if (name == "password") {
        this.setState({
          isPasswordStrengthMsg: false
        });
      }
      this.setState({
        labelPseudoClass: "strong-password-label-pseudo",
        inputPseudoClass: "strong-password-pseudo"
      });
    } else if (mediumRegex.test(value)) {
      if (name == "password") {
        this.setState({
          isPasswordStrengthMsg: true
        });
      }
      this.setState({
        labelPseudoClass: "medium-password-label-pseudo",
        inputPseudoClass: "medium-password-pseudo"
      });
    } else {
      if (name == "password") {
        this.setState({
          isPasswordStrengthMsg: true
        });
      }
      this.setState({ labelPseudoClass: "input-label-pseudo" });
    }

    // Update the key in the state with the new value
    this.setState(
      {
        [name]: value
      },
      () => {
        this.handleConfirmPassword(name);
      }
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <GridContainer className={classes.containerStyles}>
        <Card className={classes.cardStyles} elevation={0}>
          <CardHeader className={classes.cardHeaderStyles}>
            <div>
              <h3 style={{ fontSize: "24px" }}>
                <b>Update password</b>
              </h3>
              {this.props.notes ? (
                <p className="noteStyles">{this.props.notes}</p>
              ) : null}
            </div>
          </CardHeader>

          <CardBody
            className={classes.cardBodyStyles}
            style={{ opacity: this.state.formCardOpacity }}
          >
            <GridContainer>
              <GridItem xs={12} sm={9} md={9} lg={9}>
                <FormControl
                  fullWidth
                  className={classes.formInputStyles}
                  variant="filled"
                >
                  <InputLabel
                    className={classes.inputLabelStyles}
                    classes={{ focused: this.state.labelPseudoClass }}
                    shrink={false}
                  >
                    {PASSWORD_FORM_LABELS.password}
                  </InputLabel>
                  <FilledInput
                    onChange={this.handleFormFieldChange}
                    name="password"
                    type={this.state.showPassword ? "text" : "password"}
                    value={this.state.password}
                    endAdornment={
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                        edge="end"
                      >
                        {this.state.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    }
                  />
                  {this.state.isPasswordStrengthMsg && (
                    <FormHelperText className={classes.errorText}>
                      Weak Password. Must be at least 8 characters, 1 number, 1
                      lowercase, 1 uppercase letter , 1 special character from
                      @#$%&._!*-
                    </FormHelperText>
                  )}
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={9} md={9} lg={9}>
                <FormControl
                  fullWidth
                  className={classes.formInputStyles}
                  variant="filled"
                >
                  <InputLabel
                    className={classes.inputLabelStyles}
                    classes={{ focused: this.state.confirmPasswordLabelClass }}
                    shrink={false}
                  >
                    {PASSWORD_FORM_LABELS.confirmPassword}
                  </InputLabel>
                  <FilledInput
                    onChange={this.handleFormFieldChange}
                    name="confirmPassword"
                    type="password"
                    // className={this.state.confirmPasswordClass}
                    value={this.state.confirmPassword}
                  />
                </FormControl>
              </GridItem>
            </GridContainer>
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              size="large"
              // fullWidth
              className={classes.btnStyle}
              disableFocusRipple
              type="submit"
              disabled={
                !String(this.state.password).trim() ||
                !String(this.state.confirmPassword).trim() ||
                String(this.state.password).trim() !==
                String(this.state.confirmPassword).trim()
              }
              onClick={() => this.handleSubmitForm()}
            >
              Update password
            </Button>
            <GridContainer>
              <GridItem xs={12} md={12}>
                {this.state.showNotification ? (
                  <Alert
                    onClose={() => this.setState({ showNotification: false })}
                    severity={this.state.notificationType}
                  >
                    {this.state.notificationMessage}
                  </Alert>
                ) : null}
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridContainer>
    );
  }
}

UpdatePassword.propTypes = {
  classes: PropTypes.object.isRequired,
  notes: PropTypes.string.isRequired
};

export default withRouter(withStyles(customStyles)(UpdatePassword));