import axios from "axios";
import { Api } from "../config/api";

const CMS_API_URL = window.REACT_APP_CMS_API_URL;

export const CMSAPIWithToken = () => {
  const token = localStorage.getItem("user");
  let client_details = localStorage.getItem("client_details");

  let axiosObject = axios.create({
    baseURL: CMS_API_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`,
    },
  });
  axiosObject.interceptors.response.use(
    response => {
      return response;
    },
    function(error) {
      const originalRequest = error.config;
      let refreshToken = localStorage.getItem("refreshToken");

      if (
        refreshToken &&
        error.response.status === 403 && originalRequest._retry == undefined
      ) {
        originalRequest._retry = true;
        return axios
          .post(Api.refreshToken, { refresh: refreshToken })
          .then(res => {
            if (res.status === 200) {
              localStorage.setItem("user", res.data.access);
              originalRequest.headers.Authorization = `token ${res.data.access}`;
              return axios(originalRequest);
            }
          })
          .catch(err => {
            localStorage.removeItem("user");
            localStorage.removeItem("userName");
            localStorage.removeItem("profileData");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("available_purposes");
            localStorage.removeItem("available_zones");
            localStorage.removeItem("internal_domain");
            localStorage.removeItem("customAccountName");
            for (let i = 0; i < localStorage.length; i++) {
              let key = localStorage.key(i);
              if (key.startsWith('designer_logs_')) {
                localStorage.removeItem(key);
              }
            }
            window.location.pathname = "/auth/login-page";
          });
      }
      return Promise.reject(error);
    }
  );
  return axiosObject;
};

export const CMSAPIWithoutToken = () => {
  return axios.create({
    baseURL: CMS_API_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });
};
