import React from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import abcImg from "./abc.png";

const Part1 = () => {
  return (
    <div>
      <GridContainer>
        <GridItem xs={6}>
          <img style={{ width: "100%" }} src={abcImg} alt="img" />
        </GridItem>
        <GridItem xs={6}>
          ONE platform with on-demand capabilities for network design and
          validation, technology showcasing and sandboxing, product trainings
          and learning
        </GridItem>
      </GridContainer>

      <div>
        <h4 style={{ fontWeight: "bold" }}>
          Criterion SDCloud® is your versatile SaaS platform with on-demand
          hosted services to power network transformation and enablement of
          emerging technologies and applications.
        </h4>
      </div>
      <br />
      <div className="heading">Use Criterion SDCloud® for</div>
      <br />
      <div>
        <ul className="noneBullet">
          <li>❏ Network design and validation</li>
          <li>❏ Technology showcasing and sandboxing</li>
          <li>❏ Product training and learning needs</li>
        </ul>
      </div>
      <br />
      SDCloud hosted services are purpose built to meet the custom needs of
      customers that adopt or sell network infrastructure and services.
      <br />
    </div>
  );
};

export default Part1;
