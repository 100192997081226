import React, { Fragment, useEffect, useState } from "react";
import { saveAs } from "file-saver";
// Material UI Components
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Zoom from "@material-ui/core/Zoom/Zoom";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, IconButton, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {
  SHOW_NOTIFICATION,
  SHOW_LOADER,
  HIDE_LOADER,
} from "action_creators/actioncreator";

// Local
import { useDispatch } from "react-redux";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import LoaderHoc from "../../components/HOC/LoaderHoc";
import { SDCloudBackendAPIWithToken } from "../../apis/backendAPI";
import moment from "moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
// import AppBar from "@material-ui/core/AppBar";
import DiskTable from "views/GcpOrchestration/disktable2"
import VmTable from "views/GcpOrchestration/vmtable2"

// Styling
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "50px",
  },
  item: {
    textAlign: "end",
  },
  button: {
    background: `${theme.palette.buttonColor.main} !important`,
    height: "40px",
    color: "white",
    border: "none",
    width: "15%",
    fontSize: "0.875rem",
    fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    cursor: "pointer",
  },
  filter: {
    marginTop: "2%",
    padding: "20px 0px 50px 50px",
    background: "#FFFFFF",
    border: "1px solid #F0F0F0",
    boxSizing: "border-box",
    boxShadow: "0px 4px 12px rgb(0 0 0 / 4%)",
    position: "relative",
  },

  btnStyle: {
    background: `${theme.palette.buttonColor.main} !important`,
    height: "40px",
  },
  filterButton: {
    padding: "8px 50px 0px 0px",
  },
  icon: {
    marginTop: "30px",
    // paddingLeft: "80px",
    // [theme.breakpoints.down("md")]: {
    //   marginTop: "26px",
    //   paddingLeft: "40px",
    // },
  },
}));

const Orchestration = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchValue, setsearchvalue] = useState("");
  const [fromDate, setfromDate] = useState(
    moment(new Date())
      .subtract(12, "months")
      .format("MM/DD/YYYY")
  );
  const [toDate, settoDate] = useState(moment(new Date()).format("MM/DD/YYYY"));
  const [rows, setrow] = useState([]);
  const [rowstemp, setrowstemp] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageNo, setpageNo] = useState(1);
  const [recordCount, setrecordCount] = useState(20);
  const [ErrorMessage, setError] = React.useState({});
  const Errorcolor = "#DF0000";
  const profileData = JSON.parse(localStorage.getItem("profileData"));
  const account = profileData.account;
  let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
  let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
  let hasDomain = domain?.some((res) => userEmail.includes(res));
  const [isInternal, setIsInternal] = useState(hasDomain);
//   const [isAccountcn, setIsaccountCn] = 
  const [indexvalue, setIndexValue] = useState(0);
  const [opacity, setOpacity] = useState("");
  

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-orchestrator-${index}`}
        aria-labelledby={`simple-tab-orch-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={4}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const a11yProps = (index) => {
    return {
      id: `simple-tab-orch-${index}`,
      "aria-controls": `simple-tabpanel-orchestrator-${index}`,
    };
  };

  const handleTabChange = (event, newValue) => {
    setIndexValue(newValue);
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(SET_NAVBAR_TITLE(isInternal?account+"  - User Wise Consumption":"User Wise Consumption"));
    renderrow();
  }, []);   
  // States
  const columns = [
    {
      Header: "Name",
      accessor: "userName",
      disableGroupBy: true,
      textDecoration: "underline",
      cursor: "pointer",
      // disableSortBy: true
    },
    {
      Header: "Zone",
      accessor: "skuId",
      disableGroupBy: true,
      textOverflow: "hidden",
    },
    {
      Header: "Created Time",
      accessor: "productName",
      disableGroupBy: true,
      maxWidth: 300,
      minWidth: 300,
      width: 300,
      textOverflow: "hidden",
    },
    {
      Header: "External IP",
      accessor: "type",
      disableGroupBy: true,
    },
    {
      Header: "Labels",
      accessor: "totalHours",
      disableGroupBy: true,
    },
    {
      Header: "Status",
      accessor: "actualHours",
      disableGroupBy: true,
    },
  ];
  
  // if (!isInternal) {
  //   for (let col = 0; col < columns.length; col++) {
  //     if (columns[col].accessor == "actualHours") {
  //       columns.splice(col, 1);
  //     }
  //     if (columns[col].accessor == "billableCost") {
  //       columns.splice(col, 1);
  //     }
  //   }
  // }

  let data = [];
  const renderrow = async (value, search_value) => {
    let from_date = moment(fromDate).format("YYYY-MM-DD HH:mm:ss");
    let to_date = moment(toDate).format(
      "YYYY-MM-DD" + " " + moment().format("HH:mm:ss")
    );
    SDCloudBackendAPIWithToken()
      .get(
        `/statistics/get_statistics_report?search=${search_value ??
          searchValue}&from_date=${from_date}&to_date=${to_date}&page_no=${
          value ? value : pageNo
        }&record_count=${recordCount}`
      )
      .then((resp) => resp.data)
      .then((resp) => {
        resp.data.forEach((item) => {
          data.push({
            userName: item.username,
            productName: item.product_name,
            skuId: item.sku_id,
            type: item.type,
            totalHours: item.total_hours,
            actualHours: item.actual_consumption,
            billableCost: item.billing_cost,
            suspendedHours: item.suspended_hours,
            designerUsage: (item.designer_usage / 3600).toFixed(2),
            profileName: item.profile_name,
            dealid:item.deal_id,
            createdTime: item.created_at
              ? moment(item.created_at).format("MM/DD/YYYY hh:mm a")
              : "",
            deletedTime: item.deleted_at
              ? moment(item.deleted_at).format("MM/DD/YYYY hh:mm a")
              : "",
          });
        });
        setrow(data);
        setrowstemp(resp);
        setIsLoading(false);
      })
      .catch((err) => {
        let message = err.response.data.message;
        //console.log(message);
        setIsLoading(false);
      });
    // setrow(rowstemp.data);
    // setIsLoading(false);
  };

  // const handlePagination = (value) => {
  //   // setpageNo(value ? value : 1);
  //   renderrow(value);
  // };

  // const handleChange = (event) => {
  //   setsearchvalue(event.target.value);
  //   handleSearch(event.target.value);
  // };

  // const handlefromDate = (value) => {
  //   let errors = {};
  //   if (value === null) {
  //     setfromDate(null);
  //     errors["fromDate"] = "Please select Date";
  //     setError(errors);
  //   } else if (Date.parse(value) > Date.parse(toDate)) {
  //     errors["fromDate"] = "Please select valid range";
  //     setError(errors);
  //   } else {
  //     setfromDate(moment(value).format("MM/DD/YYYY") || null);
  //     errors["fromDate"] = "";
  //     setError(errors);
  //   }
  // };

  // const handletoDate = (value) => {
  //   let errors = {};
  //   if (value === null) {
  //     settoDate(null);
  //     errors["toDate"] = "Please select Date";
  //     setError(errors);
  //   } else if (Date.parse(value) < Date.parse(fromDate) || value === null) {
  //     errors["toDate"] = "Please select valid range";
  //     setError(errors);
  //   } else {
  //     settoDate(moment(value).format("MM/DD/YYYY") || null);
  //     errors["toDate"] = "";
  //     setError(errors);
  //   }
  // };

  // const handleSearch = (search_value) => {
  //   if (!isValid()) {
  //     return false;
  //   }
  //   setIsLoading(true);
  //   renderrow(null, search_value);
  // };

  const isValid = () => {
    let errors = {};
    let formvalid = true;
    if (fromDate === null) {
      errors["fromDate"] = "Please select Date";
      setError(errors);
      formvalid = false;
    } else if (Date.parse(fromDate) > Date.parse(toDate)) {
      errors["fromDate"] = "Please select valid range";
      setError(errors);
      formvalid = false;
    } else {
      errors["fromDate"] = "";
      setError(errors);
    }
    if (toDate === null) {
      errors["toDate"] = "Please select Date";
      setError(errors);
      formvalid = false;
    } else if (Date.parse(toDate) < Date.parse(fromDate)) {
      errors["toDate"] = "Please select valid range";
      setError(errors);
      formvalid = false;
    } else {
      errors["toDate"] = "";
      setError(errors);
    }
    return formvalid;
  };

  // const handleExcel = () => {
  //   if (!isValid()) {
  //     return false;
  //   }
  //   let from_date = moment(fromDate).format("YYYY-MM-DD HH:mm:ss");
  //   let to_date = moment(toDate).format(
  //     "YYYY-MM-DD" + " " + moment().format("HH:mm:ss")
  //   );
  //   dispatch(SHOW_LOADER());
  //   SDCloudBackendAPIWithToken()
  //     .get(
  //       `/statistics/download_statistics_report?search=${searchValue}&from_date=${from_date}&to_date=${to_date}`,
  //       {
  //         responseType: "blob",
  //       }
  //     )
  //     .then((resp) => {
  //       var blob = new Blob([resp.data], {
  //         type: "application/ms-excel;charset=utf-8",
  //       });
  //       saveAs(blob, `${searchValue ? searchValue : "Reports"}.xls`);
  //       dispatch(HIDE_LOADER());
  //     })
  //     .catch((err) => {
  //       const payload = {
  //         message: err?.response?.data?.message || "unknown error occured",
  //         color: "danger",
  //         error: err,
  //         showErrorPage: true,
  //       };
  //       dispatch(SHOW_NOTIFICATION({ payload }));
  //       dispatch(HIDE_LOADER());
  //     });
  // };
  return (
    <div>
        <Paper elevation={0}>
        <Grid container style={{marginTop:'2%'}}>
            <div style={{marginTop:'2%'}}>
                <Tabs
                value={indexvalue}
                classes={{
                    root: classes.customTabRoot,
                }}
                onChange={handleTabChange}
                aria-label="simple tabs example"
                >
                    <Tab
                        label="Virtual Machines"
                        {...a11yProps(0)}
                        // onClick={handleHeaderClick("ThemeCustomize")}
                    />
                    <Tab
                        label="Disks"
                        {...a11yProps(1)}
                        // onClick={handleHeaderClick("ThemeCustomize")}
                    />
                </Tabs>
            </div>
        </Grid>
        <Grid container style={{marginTop:'2%', display: 'block' }}>
            <TabPanel value={indexvalue} index={0}>
                <Grid item xm={12} md={12} style={{ opacity: opacity }}>
                    <LoaderHoc isLoading={isLoading}>
                        <VmTable
                        />
                    </LoaderHoc>
                </Grid>
            </TabPanel>
            <TabPanel value={indexvalue} index={1}>
                <Grid item xm={12} md={12} style={{ opacity: opacity }}>
                    <LoaderHoc isLoading={isLoading}>
                        <DiskTable
                        />
                    </LoaderHoc>
                </Grid>
            </TabPanel>
        </Grid>
        </Paper>
    </div>
  );
};

export default Orchestration;
