import React, { useEffect, useState } from "react";
import imageMapResize from "image-map-resizer";
import ReactMarkdown from "react-markdown";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

// Material UI Components
import Alert from "@material-ui/lab/Alert";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ResumeSandboxModal from "../ResumeSandboxModal";


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Local
import { Api } from "config/api";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";

const useStyles = makeStyles(theme => ({
  btnColor: {
    color: `${theme.palette.buttonColor.main} !important`,
    border: `1px solid ${theme.palette.buttonColor.main} !important`,
    "&:hover": {
      color: `${theme.palette.buttonColor.main} !important`,
      border: `1px solid ${theme.palette.buttonColor.main} !important`
    },
    "& .Mui-disabled": {
      color: `${theme.palette.buttonColor.main} !important`,
      border: `1px solid ${theme.palette.buttonColor.main} !important`
    }
  },
  customTabRoot: {
    color: `#000`,
    "& .Mui-selected .MuiTab-wrapper": {
      color: `${theme.palette.secondary.main}`
    },
    "& .MuiTab-wrapper": {
      fontFamily: theme.palette.fontList.selectedFont
    }
  },
  root: {
    padding: "0 20px",
    backgroundColor: "#ffffff"
  },
  title: {
    margin: "20px 0",
    textTransform: "capitalize"
  },
  description: {
    marginBottom: "10px"
  },
  warning: {
    margin: "20px 0"
  },
  appBar: {
    backgroundColor: "#ffffff !important",
    color: theme.palette.grey["600"],
    boxShadow: "none"
  },
  topologyImage: {
    width: "100%",
    "& img": {
      maxWidth: "100%"
    }
  },
  tabPanel: {
    backgroundColor: "#ffffff",
    "& div": {
      // width: "100%",
      margin: "0",
      paddingTop: "15px"
    }
  },
  terminateButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function SandboxSettings(props) {
  const [openResumeModal, setOpenResumeModal] = useState(false);
  const [value, setValue] = useState(0);
  const params = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const a11yProps = index => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleModifyClusterStatus = () => {
    if (props.status === "suspend") {
      if (
        window.confirm(
          "Are you sure you want to " + props.status + " this cluster?"
        )
      ) {
        let payload = {
          uuid: params.uuid,
          action: props.status
        };

        SDCloudBackendAPIWithToken()
          .post(Api.modifyCluster, payload)
          .then(resp => resp.data)
          .then(data => {
            let action = props.status === "suspend" ? "suspended" : "resumed";
            const payload = {
              message: `Sandbox successfully ${action}`,
              color: "success"
            };

            dispatch(SHOW_NOTIFICATION({ payload }));
            window.location.reload();
          })
          .catch(err => {
            // //console.log(err);
            // const payload = {
            //   message: err?.response?.data?.message || "unknown error occured",
            //   color: "danger",
            //   error: err,
            // };
            // dispatch(SHOW_NOTIFICATION({payload}));
          });
      }
    } else {
      setOpenResumeModal(true);
    }
  };

  const handleTerminateButton = () => {
    if (window.confirm("Are you sure you want to delete this cluster?")) {
      let payload = {
        uuid: params.uuid
      };

      SDCloudBackendAPIWithToken()
        .post(Api.terminateCluster, payload)
        .then(resp => resp.data)
        .then(data => {
          history.push("/experience-center-sandboxes", {
            currentTab: "Active"
          });
        })
        .catch(err => {
          // //console.log(err);
          // const payload = {
          //   message: err?.response?.data?.message || "unknown error occured",
          //   color: "danger",
          //   error: err,
          // };
          // dispatch(SHOW_NOTIFICATION({ payload }));
        });
    }
  };

  // if (!props.data.access_device) return null;

  return (
    <div className={classes.root}>
      <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
      <ResumeSandboxModal
        open={openResumeModal}
        uuid={props.clusterDetails.uuid}
        action={props.status}
        name={props.clusterDetails.clustername}
        onClose={setOpenResumeModal}
      />
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          classes={{
            root: classes.customTabRoot
          }}
        >
          <Tab label="Sandbox Settings" {...a11yProps(0)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} className={classes.tabPanel}>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ flexDirection: "row", flexWrap: "wrap" }}
        >
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.title}>
              {props.status === "suspend"
                ? "Suspend deployment"
                : "Resume deployment"}
            </Typography>
            <Typography variant="body2" className={classes.description}>
              Are you sure you want to {props.status} your deployment?
            </Typography>
            {props.status === "suspend" ? (
              <Alert
                icon={<></>}
                severity="warning"
                className={classes.warning}
              >
                You can suspend the cluster if you dont want to use it now. You
                can resume it when you wish to use further.
              </Alert>
            ) : null}

            <Grid container justify="flex-start">
              <Grid item xs={12} sm={3} md={2} lg={2}>
                <Button
                  variant="outlined"
                  color="secondary"
                  disableElevation
                  size="large"
                  fullWidth
                  disableFocusRipple
                  type="submit"
                  className={(classes.button, classes.btnColor)}
                  onClick={handleModifyClusterStatus}
                  disabled={
                    ["ready", "suspended"].includes(
                      props.clusterDetails.status
                    ) || props.status === "suspended"
                      ? false
                      : true
                  }
                >
                  {props.status === "suspend" ? "Suspend" : "Resume"}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <hr />
            <Typography variant="h4" className={classes.title}>
              Terminate deployment
            </Typography>
            <Typography variant="body2" className={classes.description}>
              Are you sure you want to terminate your deployment?
            </Typography>
            <Alert icon={<></>} severity="error" className={classes.warning}>
              <b>Warning!</b> Once a cluster is deleted, it cannot be recovered.
              <br />
              <br />
              Before terminating this cluster ensure that you have copied any
              data that is stored on the nodes. This action cannot be undone.
            </Alert>
            <Grid container justify="flex-start">
              <Grid item xs={12} sm={3} md={2} lg={2}>
                <Button
                  variant="outlined"
                  color="secondary"
                  disableElevation
                  size="large"
                  fullWidth
                  disableFocusRipple
                  type="submit"
                  className={
                    (classes.button, classes.terminateButton, classes.btnColor)
                  }
                  onClick={handleClickOpen}
                  disabled={
                    ["ready", "suspended", "error"].includes(
                      props.clusterDetails.status
                    ) || props.status === "suspended"
                      ? false
                      : true
                  }
                >
                  Terminate
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </TabPanel>
    </div>
  );
}

export default SandboxSettings;
