import React, { useEffect, useState } from "react";


// Material UI Components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, TextField } from "@material-ui/core";
import "../../Accounts/accounts.css";
import LoaderHoc from "../../../components/HOC/LoaderHoc";
// import moment from "moment-timezone";
import { Visibility } from "@material-ui/icons";
import moment from "moment";
import { alpha } from "@material-ui/core";

// Styling
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 100,
  bgcolor: 'white',
  border: '2px solid #000',
  // boxShadow: 24,
  p: 4,
};
const useStyles = makeStyles((theme, styles) => ({
  root: {
    textAlign: "end"
  },
  labelRoot: {
    fontSize: "14px",
    padding: "40px 10px 0px 0px"
  }
}));

const AuditLogs = props => {
  const [auditLogs, setAuditLogs] = useState([{}]);
  const audit_logs = props.audit_logs
  //const al = JSON.parse(JSON.stringify(audit_logs))
  ////console.log(al)
  useEffect(() => {
    
  }, []);

  
  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
            <div className="inventoryedit-card">
              <h3 className="createaccount-widget-title">
                View Audit Logs
              </h3>
              <Grid container className="box-inner-height mt-2">
                <Grid item xs={12} sm={12} md={12}></Grid>
                <h3 className="createaccount-widget-title">Audit Logs</h3>
                  <Grid container className="audit-log">
                    <Grid item xs={12} sm={3} md={3}>
                    <strong>Time Stamp</strong>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3}>
                      <strong>Action by</strong>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <strong>Action Performed</strong>
                    </Grid>
                  </Grid>
                  <Grid container style={{ borderBottom: "1px solid lightgray" }}>
                    {audit_logs.map(logs => (
                    <Grid container className="audit-log-data">
                      <Grid item xs={12} sm={3} md={3}>
                        <span>{logs.time_stamp ? moment.utc(logs.time_stamp).local().format("MM/DD/YYYY hh:mm a"): ""}</span>
                      </Grid>
                      <Grid item xs={12} sm={3} md={3}>
                        <span>{logs.action_by}</span>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <span>{logs.action}</span>
                      </Grid>
                    </Grid>
                    ))}
                  </Grid>
              </Grid>
            </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AuditLogs;
