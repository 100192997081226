import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Loader from "react-loader-spinner";
import CardFooter from "components/Card/CardFooter.js";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { withStyles } from "@material-ui/styles";
import chipicon from "assets/criterion_networks/svg/white_chip.svg";
import imageicon from "assets/criterion_networks/svg/white_imageico.svg";

// import CardFooter from "components/Card/CardFooter.js";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import NodeTable from "./components/TableWrapper";
import ImageTable from "views/ServiceLifeCycle/components/TableWrapper";

import classes from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import { buildAxiosParams } from "views/ServiceLifeCycle/utils/constants/urls";
import { getGlobalStore } from "store/getGlobalStore";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import { Api, Urls } from "config/api";
import { PRIMARY_COLOR } from "global_constants/app_constants";
import { CAKEPHP_URLS } from "views/ServiceLifeCycle/utils/constants/urls";
import theme from "../../../theme";
const TABLE_NAMES = {
  NODE_TABLE: "NodeTable",
  IMAGE_TABLE: "ImageTable"
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.mounted = undefined;
    this.state = {
      // dataTable: []
      open: false,
      nodeCount: "",
      imageCount: "",
      redirect: false,
      loadStatus: {
        imageLoaded: false,
        nodeLoaded: false
      }
    };
  }

  // using react router to redirect to create-node
  redirectTo = component => {
    this.props.history.replace(component);
  };

  handleOpen = tableName => {
    this.setState({
      tableName: tableName,
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
    // this.execPromises();
  };

  renderModals = () => {
    switch (this.state.tableName) {
      case TABLE_NAMES.NODE_TABLE:
        return <NodeTable />;
      case TABLE_NAMES.IMAGE_TABLE:
        return <ImageTable />;
      default:
        return null;
    }
  };

  execPromises = () => {
    this.setState({
      loadStatus: { imageLoaded: false, nodeLoaded: false } // can be made to ? allLoaded = true
    });
    axios
      .all([
        SDCloudBackendAPIWithToken().post(Api.listImages),
        SDCloudBackendAPIWithToken().get(Api.listNodes)
      ])
      .then(results => {
        if (this.mounted) {
          this.setState({
            nodeCount: results[1].data.nodes.length,
            imageCount: results[0].data.images.length,
            loadStatus: { imageLoaded: true, nodeLoaded: true } // can be made to ? allLoaded = true
          });
        }
      })
      .catch(() => {
        // //console.log(err);
        if (this.mounted) {
          this.setState({
            loadStatus: { imageLoaded: true, nodeLoaded: true } // can be made to ? allLoaded = true
          });
        }
      });
  };

  componentDidMount = () => {
    this.mounted = true;
    this.execPromises();
  };

  componentWillUnmount = () => {
    this.mounted = false;
  };

  render() {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <img
                  src={CAKEPHP_URLS.BASE_URL + chipicon}
                  style={iconStyle}
                  alt="chip icon"
                />
              </CardIcon>
              <p style={cardCategory}>Total Nodes</p>
              <h3 className={classes.cardTitle}>
                {this.state.loadStatus.nodeLoaded ? this.state.nodeCount : ""}
              </h3>
              <Loader
                type="ThreeDots"
                color={theme.palette.secondary.main}
                height={50}
                width={50}
                visible={!this.state.loadStatus.nodeLoaded}
              />
            </CardHeader>
            <CardFooter stats>
              <GridContainer alignItems="center">
                <GridItem xs={6} sm={6} md={6} lg={6}>
                  <GridContainer direction="row">
                    <GridItem>
                      <h5
                        onClick={e => {
                          e.preventDefault();
                          this.redirectTo(Urls.createElement);
                        }}
                        style={{ color: PRIMARY_COLOR, cursor: "pointer" }}
                      >
                        {" "}
                        Add
                      </h5>
                    </GridItem>
                    <GridItem>
                      <h5
                        onClick={() => this.handleOpen(TABLE_NAMES.NODE_TABLE)}
                        // onClick={e => e.stopPropagation()}
                        style={{
                          color: PRIMARY_COLOR,
                          cursor: "pointer"
                        }}
                      >
                        Update
                      </h5>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={6} sm={6} md={6} lg={6}>
                  <GridContainer justify="flex-end">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={e => {
                        e.stopPropagation();
                        this.execPromises();
                      }}
                    >
                      <i
                        className="material-icons"
                        style={{ marginRight: "15px" }}
                      >
                        refresh
                      </i>
                    </span>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} lg={6}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <img
                  src={CAKEPHP_URLS.BASE_URL + imageicon}
                  style={iconStyle}
                  alt="icon"
                />
              </CardIcon>
              <p className={classes.cardCategory} style={cardCategory}>
                Total Images
              </p>
              <h3 className={classes.cardTitle}>
                {this.state.loadStatus.imageLoaded ? this.state.imageCount : ""}
              </h3>
              <Loader
                type="ThreeDots"
                color={theme.palette.secondary.main}
                height={50}
                width={50}
                visible={!this.state.loadStatus.nodeLoaded}
              />
            </CardHeader>
            <CardFooter stats>
              <GridContainer alignItems="center">
                <GridItem xs={6} sm={6} md={6} lg={6}>
                  <GridContainer direction="row">
                    <GridItem>
                      <h5
                        onClick={e => {
                          e.preventDefault();
                          this.redirectTo(Urls.updateImage);
                        }}
                        style={{ color: PRIMARY_COLOR, cursor: "pointer" }}
                      >
                        {/*stopping propagating event to parent.*/}
                        Add
                      </h5>
                    </GridItem>
                    <GridItem>
                      <h5
                        onClick={() => this.handleOpen(TABLE_NAMES.IMAGE_TABLE)}
                        // onClick={e => e.stopPropagation()}
                        style={{ color: PRIMARY_COLOR, cursor: "pointer" }}
                      >
                        View
                      </h5>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={6} sm={6} md={6} lg={6}>
                  <GridContainer justify="flex-end">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={e => {
                        e.stopPropagation();
                        this.execPromises();
                      }}
                    >
                      <i
                        className="material-icons"
                        style={{ marginRight: "15px" }}
                      >
                        refresh
                      </i>
                    </span>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>
        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            style={styles.modal}
            open={this.state.open}
            onClose={this.handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500
            }}
          >
            <Fade in={this.state.open}>
              <div style={{ ...styles.paper, overflowY: "scroll" }}>
                <GridContainer justify="center" alignItems="center">
                  <GridItem xs={12} sm={12}>
                    {this.renderModals()}
                  </GridItem>
                </GridContainer>
              </div>
            </Fade>
          </Modal>
        </div>
        {/* <GridItem xs={12} sm={12} md={6}>
          <Card chart className={classes.cardHover}>
            <CardHeader color="info" className={classes.cardHeaderHover}>
              <ChartistGraph
                className="ct-chart-white-colors"
                data={emailsSubscriptionChart.data}
                type="Bar"
                options={emailsSubscriptionChart.options}
                responsiveOptions={emailsSubscriptionChart.responsiveOptions}
                listener={emailsSubscriptionChart.animation}
              />
            </CardHeader>
            <CardBody>
              <div className={classes.cardHoverUnder}>
                <Button simple color="info" justIcon>
                  <Refresh className={classes.underChartIcons} />
                </Button>
              </div>
              <h4 className={classes.cardTitle}>Email Subscriptions</h4>
              <p className={classes.cardCategory}>Last Campaign Performance</p>
            </CardBody>
            <CardFooter chart></CardFooter>
          </Card>
          <GridItem xs={12} sm={12} md={6}></GridItem>
        </GridItem> */}
      </GridContainer>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object
};

export default withStyles(classes)(Dashboard);

const styles = {
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    width: Math.round(window.innerWidth / 1.25),
    maxHeight: Math.round(window.innerHeight * 0.8),
    backgroundColor: "white"
  }
};

const iconStyle = {
  margin: "10px auto 0",
  width: "50px",
  height: "50px"
};

const cardCategory = {
  color: "black",
  margin: "0",
  fontSize: "14px",
  marginTop: "0",
  paddingTop: "10px",
  marginBottom: "0"
};
