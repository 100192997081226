import dashRoutes from "../routes";

export const asyncSetState = (state, context) => {
  return new Promise(resolve => {
    context.setState(state, resolve());
  });
};

export const calcTimeLeft = (lab_remaining_hours, lab_status) => {
  // if lab status is expired or not provisioned then show time left as 0 hours.
  // if (["expired"].includes(lab_status)) {
  //   return "0 hours";
  // } else {
  // show lab_remaining_hours field
  return lab_remaining_hours ? lab_remaining_hours : "null";
  // }
};

export const getRouteByName = name => {
  const route = (dashRoutes || []).find(route => route.name === name);
  return `${route?.layout}${route?.path}`;
};

export const expTabs = [
  {
    value: "sales",
    label: "Showcases"
  },
  {
    value: "poc",
    label: "Sandboxes"
  }
];
