import axios from "axios";
import { Api } from "../config/api";
import { SDCloudBackendAPIWithToken } from "./backendAPI";
import {HIDE_LOADER} from "../action_creators/actioncreator";
const BACKEND_API_URL = window.REACT_APP_API_BASE_URL;
const headers = () => {
  const token = localStorage.getItem("user");
  const header = {
    headers: {
      "Content-type": "application/json",
    },
  };
  if (token) {
    header.headers.Authorization = `token ${token}`;
  }
  return header;
};

export const getAllTemplates = async () => {
  try {
    const response = await SDCloudBackendAPIWithToken().get(Api.getTemplates);
    return { success: true, data: response.data.templates || false };
  } catch (e) {
    //console.log(e);
    return { success: false, msg: "Something went wrong", data: false };
  }
};

export const getAllWizards = async () => {
  try {
    const response = await SDCloudBackendAPIWithToken().get(Api.getWizards);
    return { success: true, data: response.data.wizards || [] };
  } catch (e) {
    //console.log(e);
    return { success: false, msg: "Something went wrong" };
  }
};

export const removeTemplate = async (clusterId) => {
  try {
    const payload = {
      cluster_uuid: clusterId,
      action: "delete",
    };
    await SDCloudBackendAPIWithToken().post(
      Api.deleteTemplate(clusterId),
      payload
    );
    return { success: true, clusterId };
  } catch (error) {
    //console.log(error.response);
    return { success: false, msg: error?.response?.data?.message ? error.response.data.message : "Something went wrong" };
  }
};

export const getResourcesStatus = async () => {
  try {
    const response = await SDCloudBackendAPIWithToken().get(Api.getResources);
    return { success: true, data: response.data || [] };
  } catch (e) {
    //console.log(e);
    return { success: false, msg: "Something went wrong" };
  }
};

export const getServiceStatus = async () => {
  try {
    const response = await SDCloudBackendAPIWithToken().get(
      Api.getServiceStatus
    );
    return { success: true, data: response.data || [] };
  } catch (e) {
    //console.log(e);
    return { success: false, msg: "Something went wrong" };
  }
};

export const getSandboxesCluster = async () => {
  try {
    const response = await SDCloudBackendAPIWithToken().get(Api.getClusterlist);
    return { success: true, data: response.data || [] };
  } catch (e) {
    //console.log(e);
    return {
      success: false,
      msg: e?.response?.data?.message || "Something went wrong",
    };
  }
};

export const getDesignerObjects = async () => {
  try {
    const response = await SDCloudBackendAPIWithToken().get(Api.getObjects);
    return { success: true, data: response.data || [] };
  } catch (e) {
    //console.log(e);
    return { success: false, msg: "Something went wrong" };
  }
};

export const forgetPassword = async (data) => {
  let result = {};
  try {
    const res = await axios.post(
      `${BACKEND_API_URL}/forgetPassword/`,
      data,
      headers()
    );
    result = res.data || {};
    return { success: true, data: result };
  } catch (err) {
    return {
      success: false,
      message: err?.response?.data?.message || "something went wrong",
    };
  }
};

export const resetPassword = async (data) => {
  let result = {};
  try {
    const res = await axios.post(
      `${BACKEND_API_URL}/reset_password`,
      data,
      headers()
    );
    result = res.data || {};
    return { success: true, data: result };
  } catch (err) {
    return {
      success: false,
      message: err?.response?.data?.message || "something went wrong",
    };
  }
};
