// view for rendering right hand side drawer from navbar
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import moment from "moment";

// material-UI imports
import CloseIcon from "@material-ui/icons/Close";
import { Badge, Card, Grid, Tooltip } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { inputBorderColor } from "assets/jss/material-dashboard-pro-react.js";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import BellIcon from "react-bell-icon";
import { SECONDARY_COLOR } from "global_constants/app_constants";
// import LoaderHoc from "../../components/HOC/LoaderHoc";
import MySkeleton from "../../components/HOC/SkeletonLoader";
import { Api } from "config/api";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import { whiteColor } from "assets/jss/material-dashboard-pro-react";
import { dangerColor } from "assets/jss/material-dashboard-pro-react";
import theme from "../../theme";
import { DoneAll, ScheduleOutlined } from "@material-ui/icons";
import { Button } from "react-scroll";

const styles = (theme) => ({
  mycard: {
    borderRadius: "0.5rem",
    backgroundColor: "#FFF5EE",
    marginLeft: "1rem",
    width: "20rem",
  },
  container: {
    padding: "15px 30px",
  },
  contentContainer: {
    borderColor: "black",
  },
  contentHeading: {
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: theme.palette.fontList.selectedFont,
  },
  form: {
    padding: "15px 0 15px 0",
  },
  dropdownPadding: {
    paddingTop: "15px",
  },
  customInputStyle: {
    margin: "15px 0 15px 0",
    paddingTop: "5px",
    "& .MuiFilledInput-input": {
      paddingTop: "10px",
    },
    "& .MuiFilledInput-multiline": {
      paddingTop: "10px",
    },
    "& .MuiSelect-filled.MuiSelect-filled": {
      paddingTop: "27px",
    },
    "& label.Mui-focused": {
      color: inputBorderColor[0],
      border: "0px",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: inputBorderColor[0],
      border: "0px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: inputBorderColor[0],
      },
      "&:hover fieldset": {
        borderColor: inputBorderColor[0],
      },
      "&.Mui-focused fieldset": {
        borderColor: inputBorderColor[0],
      },
    },
  },

  customDropDownStyle: {
    marginTop: "15px",
  },
  select: {
    "&:before": {
      borderColor: inputBorderColor[0],
    },
    "&:after": {
      borderColor: inputBorderColor[0],
    },
  },
  labelText: {
    color: "black",
  },
  uploadContainer: {
    border: "1px solid #8C8C8C",
    boxShadow: "0px 4px 4px 0px #000000 25%",
    borderStyle: "dashed",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
  },
  notificationDate: {
    height: "14px",
    fontWeight: 300,
    fontSize: "12px",
    lineHeight: "14px",
    color: SECONDARY_COLOR,
    paddingLeft: "1rem",
    fontFamily: theme.palette.fontList.selectedFont,
  },
  notificationHeading: {
    marginTop: "15px",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "21px",
    color: SECONDARY_COLOR,
    paddingLeft: "1rem",
    fontFamily: theme.palette.fontList.selectedFont,
  },
  notificationDescription: {
    paddingTop: "0.6rem",
    paddingBottom: "0.6rem",
    fontWeight: 400,
    fontSize: "14px",
    color: "#222222",
    paddingLeft: "1rem",
    backgroundColor: "#FFF5EE",
    fontFamily: theme.palette.fontList.selectedFont,
  },
  notificationImage: {
    width: "100%",
    paddingTop: "15px",
  },
  notificationEllipseContainer: {
    marginTop: "2px",
  },
  notificationSeparater: {
    margin: "10px 0px 14px 0px",
    height: "1px",
    background: "#CBCBCB",
    width: "120%",
  },
  notificationEllipse: {
    width: "5px",
    height: "5px",
    display: "inline-block",
    background: theme.palette.secondary.main,
    borderRadius: "100%",
    marginLeft: "0.7rem",
  },
  notificationTabEllipse: {
    width: "5px",
    height: "5px",
    background: theme.palette.secondary.main,
    borderRadius: "100%",
    marginLeft: "-1.5rem",
    marginTop: "1rem",
  },
  notificationNumber: {
    "& .MuiBadge-badge": {
      backgroundColor: dangerColor[0],
      color: whiteColor,
      height: "16px",
      fontSize: "9px",
      minWidth: "16px",
      textAlign: "center",
      lineHeight: "14px",
      borderRadius: "10px",
      verticalAlign: "middle",
    },
  },
  appBarTab: {
    backgroundColor: "#ffffff !important",
    color: "#000 !important",
    "& .Mui-selected .MuiTab-wrapper": {
      color: theme.palette.secondary.main,
    },
    marginBottom: "20px",
  },
  font: {
    fontFamily: theme.palette.fontList.selectedFont,
  },
  closeIt: {
    fontSize: "20px",
    float: "right",
    marginRight: "0.5rem",
    marginBottom: "0.2rem",
    cursor: "pointer",
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel1-${index}`}
      aria-labelledby={`simple-tab1-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={4}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const handleClickHere = () => {
  SDCloudBackendAPIWithToken()
    .get(Api.getSSO)
    .then((sso) => window.open(sso.data.urls, "_blank"));
};
const handleTermsOfServices = () => {
  window.location.href = "http://criterionnetworks.com/terms-of-service/";
};

const Notification = ({
  date,
  heading,
  description,
  sku_name,
  schedule_time,
  click_here,
  terms_of_services,
  media,
  marked_read,
}) => {
  const classes = useStyles();
  const [showed, setShowed] = useState(false);
  return (
    <Grid
      container
      justify="center"
      alignItems="flex-start"
      className={classes.notificationContainer}
    >
      <div style={showed ? { display: "none" } : { display: "block" }}>
        <Card className={classes.mycard} id="mycard">
          <Grid item xs={11}>
            <Grid container alignItems="left">
              <Card item xs={12} className={classes.notificationDescription}>
                <Typography variant="body1">
                  {
                    <p
                      style={{
                        color: "#494F55",
                        fontWeight: "bold",
                        fontSizeAdjust: "inherit",
                      }}
                    >
                      {heading}
                    </p>
                  }{" "}
                  {
                    <p
                      style={{
                        color: "#606060",
                        fontWeight: 420,
                        fontSizeAdjust: "inherit",
                      }}
                    >
                      {description}
                    </p>
                  }{" "}
                  {
                    <p
                      style={{
                        color: "#606060",
                        fontWeight: 450,
                        fontSizeAdjust: "inherit",
                      }}
                    >
                      {schedule_time}
                    </p>
                  }{" "}
                  {
                    <p
                      style={{
                        fontWeight: 470,
                        color: "black",
                        fontSizeAdjust: "inherit",
                      }}
                    >
                      {sku_name}
                    </p>
                  }{" "}
                  {click_here ? (
                    <Button
                      onClick={handleClickHere}
                      style={{
                        borderRadius: "0.5rem",
                        cursor: "pointer",
                        borderBottom: "3px solid orange",
                        outline: "none",
                        borderTop: "none",
                        borderRight: "none",
                        borderLeft: "none",
                      }}
                      size="inherit"
                      value="Click here to get started"
                    ></Button>
                  ) : null}{" "}
                  {terms_of_services ? (
                    <Button
                      onClick={handleTermsOfServices}
                      style={{
                        borderRadius: "0.5rem",
                        cursor: "pointer",
                        borderBottom: "3px solid orange",
                        outline: "none",
                        borderTop: "none",
                        borderRight: "none",
                        borderLeft: "none",
                      }}
                      size="inherit"
                      value="Terms of Services"
                    ></Button>
                  ) : null}
                </Typography>
              </Card>
              <IconButton
                style={{
                  textDecoration: "none",
                  color: "grey",
                  position: "relative",
                }}
                size="small"
                disabled
              >
                <ScheduleOutlined />
                <Typography variant="body2">{date}</Typography>
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={1} className={classes.notificationEllipseContainer}>
            {!marked_read ? (
              <p className={classes.notificationEllipse}></p>
            ) : null}
          </Grid>
        </Card>
        <Grid item xs={12} className={classes.notificationSeparater} />
      </div>
    </Grid>
  );
};

const useStyles = makeStyles(styles);
const Header = ({ toggleDrawer, notificationCount }) => {
  const classes = useStyles();
  const StaticBell = () => {
    return <BellIcon width="20" active={false} animate={false} />;
  };

  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={2} sm={1}>
        <Grid container justify="flex-start" alignItems="center">
          <Badge
            badgeContent={notificationCount ? notificationCount : null}
            className={classes.notificationNumber}
          >
            <StaticBell />
          </Badge>
        </Grid>
      </Grid>
      <Grid item xs={8} sm={9} className={classes.font}>
        <Typography
          variant="h5"
          style={{
            color: "#606060",
            fontWeight: 450,
          }}
        >
          Alerts
        </Typography>
      </Grid>

      <Grid item xs={2}>
        <Grid
          container
          justify="center"
          alignItems="center"
          onClick={() => toggleDrawer()}
          style={{ cursor: "pointer" }}
        >
          <CloseIcon style={{ paddingBottom: "3%" }} />
        </Grid>
      </Grid>
    </Grid>
  );
};

const Content = (props) => {
  const classes = useStyles();
  const {
    handleChange,
    pushNotificationData,
    whatsNewData,
    notificationCount,
    pCount,
    wCount,
  } = props;
  pushNotificationData.sort((a, b) => {
    if (moment(a.date) > moment(b.date)) return -1;
    if (moment(a.date) < moment(b.date)) return 1;
  });
  whatsNewData.sort((a, b) => {
    if (moment(a.date) > moment(b.date)) return -1;
    if (moment(a.date) < moment(b.date)) return 1;
  });
  const [indexvalue, setIndexValue] = useState(0);

  const a11yProps = (index) => {
    return {
      id: `simple-tab1-${index}`,
      "aria-controls": `simple-tabpanel1-${index}`,
    };
  };

  const handleTabChange = (event, newValue) => {
    setIndexValue(newValue);
  };
  return (
    <Grid
      className={classes.contentContainer}
      container
      justify="center"
      alignItems="center"
    >
      {notificationCount == 0 ? (
        <Typography
          variant="body2"
          style={{ color: "#606060", fontWeight: 450 }}
        >
          No unread notifications
        </Typography>
      ) : (
        <Typography
          variant="body2"
          style={{ color: "#606060", fontWeight: 450 }}
        >
          You have {notificationCount} unread notifications
        </Typography>
      )}
      {notificationCount == 0 ? (
        <Tooltip title=" Mark all as read" placement="top" disabled>
          <IconButton color="secondary" onClick={handleChange}>
            <DoneAll />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title=" Mark all as read" placement="top">
          <IconButton color="secondary" onClick={handleChange}>
            <DoneAll />
          </IconButton>
        </Tooltip>
      )}

      <AppBar position="static" className={classes.appBarTab}>
        <Tabs
          value={indexvalue}
          classes={{
            root: classes.customTabRoot,
          }}
          onChange={handleTabChange}
          aria-label="simple tabs example"
        >
          <Tab
            label={
              <>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span>Notifications</span>
                  <Grid
                    item
                    xs={1}
                    className={classes.notificationEllipseContainer}
                  >
                    {pCount > 0 ? (
                      <p className={classes.notificationEllipse}></p>
                    ) : null}
                  </Grid>
                </div>
              </>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <>
                {wCount > 0 ? (
                  <div style={{ display: "flex" }}>
                    <span>What's new</span>
                    <Grid
                      item
                      xs={1}
                      className={classes.notificationEllipseContainer}
                    >
                      {wCount > 0 ? (
                        <p
                          style={{ display: "inline-block" }}
                          className={classes.notificationEllipse}
                        ></p>
                      ) : null}
                    </Grid>
                  </div>
                ) : (
                  <>
                    <span style={{ marginRight: "1rem" }}>What's new</span>
                    <Grid
                      item
                      xs={1}
                      className={classes.notificationEllipseContainer}
                    >
                      {wCount > 0 ? (
                        <p
                          style={{ display: "inline-block" }}
                          className={classes.notificationEllipse}
                        ></p>
                      ) : null}
                    </Grid>
                  </>
                )}
              </>
            }
            {...a11yProps(0)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={indexvalue} index={1}>
        <Grid item xs={12}>
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            direction="row"
          ></Grid>
        </Grid>
        {whatsNewData.map((notification, index) => {
          return <Notification {...notification} key={index} />;
        })}
      </TabPanel>
      <TabPanel value={indexvalue} index={0}>
        <Grid item xs={12}>
          <Grid
            container
            justify="flex-start"
            alignItems="center"
            direction="row"
          ></Grid>
        </Grid>
        {pushNotificationData.map((notification, index) => {
          return <Notification {...notification} key={index} />;
        })}
      </TabPanel>
    </Grid>
  );
};

let notificationCount = 0;
let pushNotificationData = [];
let whatsNewData = [];
let pCount = 0;
let wCount = 0;

export default function NotificationRightSideDrawer({ toggleDrawer }) {
  const classes = useStyles();
  const [offeringValue, setOfferingValue] = useState("");
  const [files, setFiles] = useState(null);

  const handleSubmit = () => {
    if (!isValid()) {
      alert("fill all the required fields");
    } else {
      // api call logic goes here.
      //console.log({ images: files, ...state });
    }
  };
  const [state, setState] = useState({
    title: "",
    email: "",
    description: "",
    sku_name: "",
    schedule_time: "",
    click_here: "",
    terms_of_services: "",
  });

  const [isLoading, setIsLoading] = useState(true);
  const [loadMore, setLoadMore] = useState(false);
  const [ellipse, setEllipse] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [notificationCountState, setNotificationCountState] = useState(
    notificationCount
  );
  const [pushNotificationDataState, setPushNotificationDataState] = useState(
    pushNotificationData
  );
  const [whatsNewDataState, setWhatsnewDataState] = useState(whatsNewData);
  const [pushNotificationCountState, setPushNotificationCountState] = useState(
    pCount
  );
  const [whatsNewCountState, setWhatsNewCountState] = useState(wCount);

  // useEffects
  useEffect(() => {
    renderData();
  }, []);

  const renderData = () => {
    let pushNotification = [];
    let whatsNew = [];
    const options = {
      hour12: true,
    };

    let response = SDCloudBackendAPIWithToken()
      .get(`${Api.getNotifications}`)
      .then((resp) => resp.data)
      .then((resp) => {
        resp.data.forEach((item) => {
          if (item.is_push) {
            let dateTime = new Date(
              moment(item.updated_at).format("MM/DD/YYYY hh:mm a")
            ).toLocaleString(options);

            pushNotification.push({
              date: dateTime,
              heading: item.content.heading,
              description: item.content.description,
              media: item.content.media,
              marked_read: item.mark_read,
              sku_name: item.content.sku_name ? item.content.sku_name : "",
              schedule_time: item.content.schedule_time
                ? item.content.schedule_time + "(in UTC)"
                : "",
              click_here: item.content.click_here
                ? item.content.click_here
                : null,
              terms_of_services: item.content.terms_of_services
                ? item.content.terms_of_services
                : null,
              action: (
                <IconButton>
                  <CloseIcon />
                </IconButton>
              ),
            });
          }
          if (item.is_alert) {
            let dateTime = new Date(
              moment(item.updated_at).format("MM/DD/YYYY hh:mm a")
            ).toLocaleString(options);

            whatsNew.push({
              date: dateTime ? dateTime : "",
              heading: item.content.heading ? item.content.heading : "",
              description: item.content.description,
              media: item.content.media,
              marked_read: item.mark_read,
              click_here: item.content.click_here
                ? item.content.click_here
                : "",
              terms_of_services: item.content.terms_of_services
                ? item.content.terms_of_services
                : "",
              action: (
                <IconButton>
                  <CloseIcon />
                </IconButton>
              ),
            });
          }
        });

        // let count = 0;
        // pushNotification.map((el) => {
        //   if (el.marked_read === false) {
        //     count += 1;
        //   }
        // });

        let pushNotificationCount = resp.data.filter(function (x) {
          return x.is_push === true && x.mark_read === false;
        });
        let pCount = pushNotificationCount ? pushNotificationCount.length : 0;

        let whatsNewCount = resp.data.filter(function (y) {
          return y.is_alert === true && y.mark_read === false;
        });
        let wCount = whatsNewCount ? whatsNewCount.length : 0;

        let totalCount = pCount + wCount;

        //let readCount = resp.data.filter((x) => x.mark_read === false);
        notificationCount = totalCount ? totalCount : 0;
        pushNotificationCount = pCount;
        whatsNewCount = wCount;

        pushNotificationData = [];
        whatsNewData = [];
        pushNotificationData = [...pushNotificationData, ...pushNotification];
        whatsNewData = [...whatsNewData, ...whatsNew];

        setPushNotificationDataState(pushNotificationData);
        setWhatsnewDataState(whatsNewData);
        setNotificationCountState(notificationCount);
        setPushNotificationCountState(pushNotificationCount);
        setWhatsNewCountState(whatsNewCount);

        setIsLoading(false);
        setLoadMore(false);
        setEllipse(true);
      })
      .catch((err) => {
        let message =
          err?.response?.data?.message || "Failed to retrieve the data";

        setShowNotification(true);
        setIsLoading(false);
        setEllipse(true);
        setLoadMore(false);
        setNotificationType("error");
        setNotificationMessage(message);
      });
    return response;
  };

  const handleChangeEllipse = () => {
    setEllipse(false);
    pushNotificationData = [];

    let response = SDCloudBackendAPIWithToken()
      .post(Api.deleteNotification, { mark_all_read: true })
      .then((resp) => resp.data)
      .then((resp) => {
        pushNotificationData = [];

        setEllipse(true);
        renderData();
      })
      .catch((err) => {
        let message =
          err?.response?.data?.message || "Failed to retrieve the data";
        renderData();
        setEllipse(true);
        setShowNotification(true);
        setIsLoading(false);
        setNotificationType("error");
        setNotificationMessage(message);
      });
  };
  const isValid = () => {
    return (
      state.title.trim().length > 0 &&
      state.email.trim().length > 0 &&
      state.description.trim().length > 0 &&
      offeringValue.trim().length > 0
    );
  };
  return (
    <div>
      <Grid
        container
        className={classes.container}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} md={12}>
          {showNotification ? (
            <Alert
              onClose={() => setShowNotification(false)}
              severity={notificationType}
            >
              {notificationMessage}
            </Alert>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12} style={{ opacity: ellipse ? 1 : 0.3 }}>
          <MySkeleton isLoading={isLoading}>
            <Header
              toggleDrawer={toggleDrawer}
              notificationCount={notificationCountState}
            />
            <Content
              offeringValue={offeringValue}
              setOfferingValue={setOfferingValue}
              handleSubmit={handleSubmit}
              handleChange={handleChangeEllipse}
              pushNotificationData={pushNotificationDataState}
              whatsNewData={whatsNewDataState}
              notificationCount={notificationCountState}
              pushNotificationCount={pushNotificationCountState}
              whatsNewCount={whatsNewCountState}
              fetchNextNotification={renderData}
              pCount={pushNotificationCountState}
              wCount={whatsNewCountState}
              setFiles={setFiles}
              {...state}
            />
          </MySkeleton>
        </Grid>
        {loadMore ? (
          <Grid item xs={12} md={12}>
            <div>
              <Grid container justify="center">
                <Loader
                  type="ThreeDots"
                  color={theme.palette.secondary.main}
                  height={500}
                  width={50}
                  visible={true}
                />
              </Grid>
            </div>
          </Grid>
        ) : null}
      </Grid>
    </div>
  );
}

//############## Unused code #######################//
//noitfication content(media)
{
  /* <Grid item xs={12}>
              {media != ""}
              <Paper>
                <img className={classes.notificationImage} src={media} />
              </Paper>
            </Grid> */
}
// useEffect(() => {
//   const list = document.getElementById("infiniteScrollNotification");
//   list.addEventListener("scroll", (e) => {
//     const el = e.target;
//     if (el.scrollTop + el.clientHeight === el.scrollHeight) {
//       if (currentPage != totalPage) {
//         setLoadMore(true);
//         renderData();
//       }
//     }
//   });
// }, []);
