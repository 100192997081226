import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button";
import styles from "assets/jss/material-dashboard-pro-react/views/errorPageStyles.js";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const useStyles = makeStyles(styles);

export default function ErrorPage() {
  const classes = useStyles();
  const history = useHistory();

  const handleHomeBtn = () => {
    history.push("/dashboard");
  };

  return (
    <div className={classes.contentCenter}>
      <GridContainer>
        <GridItem md={12}>
          <h1 className={classes.title}>Oops!</h1>
          <h2 className={classes.subTitle}>Something went wrong.</h2>
          {/* <h4 className={classes.description}>Please come back later later.</h4> */}
          <Button className={classes.buttStyle} color="info" onClick={handleHomeBtn}>
            Go to homepage
          </Button>
        </GridItem>
      </GridContainer>
    </div>
  );
}