import React, { useState } from "react";
import { BrowserRouter, Route, NavLink } from "react-router-dom";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import {
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import designtile from "assets/criterion_networks/img/design-tile.png";
import { getRouteByName } from "../../utils/utilfunction";
import HeaderImage from "assets/img/w1.png";
import ArrowImage from "assets/img/arrow-right.png";
// import UpgradeSubscription from "./UpgradeSubscription.js";
import Drawer from "@material-ui/core/Drawer";
import UpgradeSubscription from "views/Dashboard/UpgradeSubscription";
import arrowright from "assets/criterion_networks/img/arrow-right.png";
import { ReactComponent as DesignSVG } from "assets/criterion_networks/svg/Design center.svg";
import { ReactComponent as svg5 } from "assets/criterion_networks/svg/05.svg";
import SvgComponent from "utils/SvgComponent";
import { orange, red } from "@material-ui/core/colors";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import { Api } from "config/api";
import Chatbot from "./chatbot/Chatbot";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "20px",
  },
  title: {
    color: theme.palette.secondary.main,
    marginTop: "10px",
  },
  subtitle: {
    marginBottom: "20px",
  },
  drawerPaper: {
    width: "30%",
  },
  btnStyle: {
    color: `${theme.palette.secondary.main}`,
    "&:hover": {
      color: `${theme.palette.secondary.main} !important`,
    },
    "&:focus": {
      color: `${theme.palette.secondary.main} !important`,
    },
    fontSize: "15px",
    cursor: "pointer",
  },
  btnBackground: {
    background: `${theme.palette.buttonColor.main}`,
    borderRadius: "3px",
  },
  font: {
    fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    "& li": {
      fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    },
  },
  ClsOpacity: {
    opacity: "0.5",
  },
  arrow: {
    marginBottom: "1%",
    marginLeft: "1px",
  },
}));

const DesignCenterUpgrade = () => {
  const classes = useStyles();
  const [upgrade, setUpgrade] = useState("");
  const [showRightDrawer, toggleRightDrawer] = useState(false);

  let sections = {
    name: "Designer Templates",
    id: "designer",
    path: getRouteByName("Design"),
    Headerimg: HeaderImage,
    Arrowimg: ArrowImage,
    ScreenName: "Design",
    Upgrade: "Upgrade Design Center",
  };

  const handleClickHelp = () => {
    SDCloudBackendAPIWithToken()
      .get(Api.getSSO)
      .then((sso) => window.open(sso.data.urls, "_blank"));
  };
  // const handleClickCustomerSupport = (value) => {
  //
  // setUpgrade(value);
  // toggleRightDrawer(!showRightDrawer);
  //   window.location.href("https://criterion.zendesk.com/hc/en-us");
  // };

  // const DesignCenterUpgrade = () => {
  //   return (
  //     <div style={{ marginTop: "20px" }}>
  //       <Drawer
  //         anchor={"right"}
  //         open={showRightDrawer}
  //         classes={{
  //           paper: classes.drawerPaper
  //         }}
  //         PaperProps={{ id: "infiniteScrollNotification" }}
  //         onClose={() => toggleRightDrawer(!showRightDrawer)}
  //       >
  //         {generateDrawerComponent(upgrade)}
  //       </Drawer>
  //     </div>
  //   )
  // }

  const generateDrawerComponent = (value) => {
    return (
      <UpgradeSubscription
        toggleDrawer={toggleRightDrawer}
        expanded="support"
        upgradetype={value}
      />
    );
  };

  return (
    // <div className={classes.root}>
    <Grid container>
      <Grid container spacing={3} className="bg-gray pt-2">
        <Grid item xs={12} sm={6} className="ml-5 pt-0">
          <div class="design-cards">
            <h3 className={`design-title ${classes.font}`}>Design center </h3>
            <p className={`notavailale ${classes.font}`}>
              Not available in your plan
            </p>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className="mr-3 ">
          <button
            className={`button upgrade-button ${classes.btnBackground +
              " " +
              classes.font}`}
            style={{ cursor: "pointer", height: "40px" }}
            onClick={handleClickHelp}
          >
            Upgrade your subscription
          </button>
        </Grid>
      </Grid>

      <Grid container spacing={3} className="bg-gray pt-2">
        <Grid item xs={12} sm={6} className="pl-8 pt-0">
          <div class="design-card top-space">
            <ul className={`design-list ${classes.font}`}>
              <li>Design your custom topology</li>
              <li>One click provisioning</li>
            </ul>
            <a
              className={`sub-title-link ${classes.btnStyle +
                " " +
                classes.font}`}
              onClick={handleClickHelp}
              target="_blank"
              rel="noreferrer noopener"
            >
              Please visit our help center and submit a request to upgrade your
              subscription
              <SvgComponent
                className={classes.arrow}
                type="stroke"
                svgSource={svg5}
              />
              {/* <img src={arrowright} class="arrow" /> */}
            </a>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className="mr-3 ">
          <SvgComponent
            className={classes.ClsOpacity}
            type="fill"
            svgSource={DesignSVG}
          />
          {/* <img src={designtile} class="align-center" /> */}
        </Grid>
      </Grid>
      {/* <Grid item sm={12} md={6} lg={6}>
          <Typography variant="h3" className={classes.title}>
            Criterion SDCloud®
          </Typography>
          <Typography variant="h1" className={classes.subtitle}>
            Design Center
          </Typography>
          <Typography variant="body1" className={classes.description}>
            Transform quickly at any scale with environments to design &
            validate new networks
          </Typography>
          <List>
            <ListItem>
              <ListItemText>
                1. Drag-and-drop canvas to design new networks
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                2. Full connectivity to cloud platforms & physical devices
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                3. Automation & API framework for rapid deployment
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <Grid container justify="flex-end">
            <img src={DesignCenterUpgradeImage} className={classes.image} />
          </Grid>
        </Grid> */}
      {/* </Grid> */}

      <Drawer
        anchor={"right"}
        open={showRightDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}
        PaperProps={{ id: "infiniteScrollNotification" }}
        onClose={() => toggleRightDrawer(!showRightDrawer)}
      >
        {generateDrawerComponent(upgrade)}
      </Drawer>
      <Chatbot/>
    </Grid>
  );
};

export default DesignCenterUpgrade;
