import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

// Material UI Components
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  customTabRoot: {
    color: `#000`,
    "& .Mui-selected .MuiTab-wrapper": {
      color: `${theme.palette.secondary.main}`
    },
    "& .MuiTab-wrapper": {
      fontFamily: theme.palette.fontList.selectedFont
    }
  },
  root: {
    padding: "0 20px",
    backgroundColor: "#ffffff",
  },
  appBar: {
    backgroundColor: "#ffffff !important",
    color: theme.palette.grey["600"],
    boxShadow: "none",
  },
  topologyImage: {
    width: "100%",
    "& img": {
      width: "100%",
    },
  },
  tabPanel: {
    backgroundColor: "#ffffff",
    "& div": {
      width: "100%",
      margin: "0",
      paddingTop: "30px",
    },
  },
  ipDetailAccess: {
    width: "100%",
    "& table": {
      width: "100%",
      "& thead": {
        textAlign: "left",
      },
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function Access(props) {
  const [value, setValue] = useState(0);

  const classes = useStyles();

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (!props.clusterDetails.ssh_key) return null;

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          classes={{
            root: classes.customTabRoot
          }}
        >
          <Tab label="SSH" {...a11yProps(0)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} className={classes.tabPanel}>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ flexDirection: "row", flexWrap: "wrap" }}
        >
          <Typography variant="body2">
            All the nodes can be accessed via SSH through their public IPs,
            (provided in cluster details) using public/private key
            authentication. Download the private key to access via SSH.
          </Typography>
        </Grid>
      </TabPanel>
    </div>
  );
}

export default Access;
