// This component creates a modal for Reserved IP with respective form fields, the
// modal is popped up during provision or resuming of a lab or poc.

import React, { Component, useState, useEffect } from "react";
import { SECONDARY_COLOR } from "global_constants/app_constants";
import Button from "@material-ui/core/Button";
// Formik
import { useFormik } from "formik";
import MuiPhoneNumber from "material-ui-phone-number";
import Datetime from "react-datetime";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Api, Urls } from "config/api";
import Loader from "react-loader-spinner";
import LoaderHoc from "../../components/HOC/LoaderHoc";
import { LOADER_TYPE } from "global_constants/app_constants";
import "views/ServiceOffering/LearningLab/acess labs/Overview.scss";
import NotificationAlert from "views/Components/NotificationAlert";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import Typography from "@material-ui/core/Typography";
import theme from "theme";
import { staticIpRegex } from "../MyProfile/utils/constants/constants_profile.js";

const inputProps = {
  disableAnimation: true,
  shrink: false
};

const customStyles = theme => ({
  mainContainer: {
    padding: "40px"
  },
  container: {
    marginTop: "20px"
  },
  formContainer: {
    width: "100%"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    position: "absolute",
    width: 550,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    display: "block"
  },
  buttStyle: {
    background: theme.palette.buttonColor.main,
    "&:hover": {
      background: theme.palette.buttonColor.main
    }
  },
  accountTitle: {
    marginBottom: "40px"
  },
  firstTitleLabel: {
    padding: "0 15px"
  },
  titleLabel: {
    padding: "0 15px",
    marginTop: "30px"
  },
  errorText: {
    fontSize: "12px",
    color: theme.palette.error.main
  }
});

const AccountInformation = props => {
  const useStyles = makeStyles(customStyles);
  const classes = useStyles();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [prevAccountName, setPrevAccountName] = React.useState("");

  useEffect(() => {
    getAccountInfo();
  }, []);

  const formik = useFormik({
    initialValues: {
      accountName: "",
      firstName: "",
      lastName: "",
      email: "",
      spocFirstName: "",
      spocLastName: "",
      phone: "",
      spocEmail: "",
      adminFirstName: "",
      adminLastName: "",
      spocPhone: "",
      adminEmail: "",
      adminPhone: ""
    },
    onSubmit: values => {
      setShowNotification(false);
      setBtnDisabled(true);
      props.setOpacity(0.3);
      SDCloudBackendAPIWithToken()
        .post(Api.accountInfo, {
          account_mgmt_info: {
            account_name: values.accountName,
            account_manager: {
              first_name: values.firstName,
              last_name: values.lastName,
              email: values.email,
              mobile: values.phone
            },
            account_admin_manager: {
              first_name: values.adminFirstName,
              last_name: values.adminLastName,
              email: values.adminEmail,
              mobile: values.adminPhone
            },
            criterion_spoc: {
              first_name: values.spocFirstName,
              last_name: values.spocLastName,
              email: values.spocEmail,
              mobile: values.spocPhone
            }
          }
        })
        .then(res => {
          //   setShowNotification(true);
          //   setNotificationMessage(
          //     "Ip created successfully!"
          //   );
          //   setNotificationType("success");
          // localStorage.setItem("customAccountName", values.accountName);
          getAccountInfo();
          setBtnDisabled(false);
          props.setOpacity(1);
          // if (prevAccountName != values.accountName) {
          //   setPrevAccountName(values.accountName);
          //   window.location.reload();
          // }
        })
        .catch(err => {
          let error = err.response.data;
          setShowNotification(true);
          setNotificationMessage(error.message);
          setNotificationType("error");
          setBtnDisabled(false);
          props.setOpacity(1);
        });
    },
    validate: values => {
      let errors = {};

      if (!values.accountName) {
        errors.accountName = "Required";
      }
      if (!values.firstName) {
        errors.firstName = "Required";
      }
      if (!values.email) {
        errors.email = "Required";
      }
      if (!values.spocFirstName) {
        errors.spocFirstName = "Required";
      }
      if (!values.spocEmail) {
        errors.spocEmail = "Required";
      }
      if (!values.adminFirstName) {
        errors.adminFirstName = "Required";
      }
      if (!values.adminEmail) {
        errors.adminEmail = "Required";
      }

      return errors;
    }
  });

  const getAccountInfo = () => {
    SDCloudBackendAPIWithToken()
      .get(Api.accountInfo)
      .then(res => {
        let managerInfo = res.data.account_mgmt_info.account_manager;
        let adminInfo = res.data.account_mgmt_info.account_admin_manager;
        let spocInfo = res.data.account_mgmt_info.criterion_spoc;
        formik.setFieldValue(
          "accountName",
          res.data.account_mgmt_info?.account_name
        );
        formik.setFieldValue("firstName", managerInfo?.first_name);
        formik.setFieldValue("lastName", managerInfo?.last_name);
        formik.setFieldValue("email", managerInfo?.email);
        formik.setFieldValue("phone", managerInfo?.mobile);
        formik.setFieldValue("spocFirstName", spocInfo?.first_name);
        formik.setFieldValue("spocLastName", spocInfo?.last_name);
        formik.setFieldValue("spocEmail", spocInfo?.email);
        formik.setFieldValue("spocPhone", spocInfo?.mobile);
        formik.setFieldValue("adminFirstName", adminInfo?.first_name);
        formik.setFieldValue("adminLastName", adminInfo?.last_name);
        formik.setFieldValue("adminEmail", adminInfo?.email);
        formik.setFieldValue("adminPhone", adminInfo?.mobile);
      })
      .then(() => {
        setIsLoading(false);
      })
      .catch(err => {
        //console.log(err.response);
        setIsLoading(false);
      });
  };

  return (
    // Form modal for add hours to lab
    <div>
      <LoaderHoc isLoading={isLoading}>
        <Paper elevation={0}>
          <Grid
            container
            className={classes.mainContainer}
            style={{ marginTop: "2%" }}
          >
            <form
              onSubmit={formik.handleSubmit}
              className={classes.formContainer}
            >
              {/* <Typography variant="h4">{props.formTitle}</Typography> */}
              <Typography variant="h4" className={classes.firstTitleLabel}>
                Account
              </Typography>
              <Grid container className={classes.accountTitle}>
                <GridItem md={6} lg={6}>
                  {formik.errors.accountName && formik.touched.accountName ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Account name"
                      name="accountName"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.accountName}
                      onChange={formik.handleChange}
                      helperText={formik.errors.accountName}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Account name"
                      name="accountName"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.accountName}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>
              </Grid>
              <Typography variant="h4" className={classes.firstTitleLabel}>
                Account manager
              </Typography>
              <Grid container className={classes.container}>
                <GridItem md={4} lg={4}>
                  {formik.errors.firstName && formik.touched.firstName ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="First name"
                      name="firstName"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      helperText={formik.errors.firstName}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="First name"
                      name="firstName"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>
                <GridItem md={4} lg={4}>
                  <TextField
                    type="text"
                    variant="filled"
                    label="Last name"
                    name="lastName"
                    fullWidth
                    InputLabelProps={inputProps}
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                  />
                </GridItem>
                <GridItem md={4} lg={4}>
                  {formik.errors.email && formik.touched.email ? (
                    <TextField
                      type="email"
                      variant="filled"
                      label="Email"
                      name="email"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      helperText={formik.errors.email}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="email"
                      variant="filled"
                      label="Email"
                      name="email"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>
                <GridItem md={4} lg={4}>
                  <MuiPhoneNumber
                    name="phone"
                    variant="filled"
                    label="Phone"
                    // data-cy="user-phone"
                    fullWidth
                    InputLabelProps={inputProps}
                    defaultCountry={"us"}
                    disableDropdown={true}
                    value={formik.values.phone}
                    onChange={e => formik.setFieldValue("phone", e)}
                  />
                </GridItem>
              </Grid>
              <Typography variant="h4" className={classes.titleLabel}>
                Technical point of contact
              </Typography>
              <Grid container className={classes.container}>
                <GridItem xs={12} sm={4} md={4} lg={4}>
                  {formik.errors.spocFirstName &&
                  formik.touched.spocFirstName ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="First name"
                      name="spocFirstName"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.spocFirstName}
                      onChange={formik.handleChange}
                      helperText={formik.errors.spocFirstName}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="First name"
                      name="spocFirstName"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.spocFirstName}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>
                <GridItem xs={12} sm={4} md={4} lg={4}>
                  <TextField
                    type="text"
                    variant="filled"
                    label="Last name"
                    name="spocLastName"
                    fullWidth
                    InputLabelProps={inputProps}
                    value={formik.values.spocLastName}
                    onChange={formik.handleChange}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={4} lg={4}>
                  {formik.errors.spocEmail && formik.touched.spocEmail ? (
                    <TextField
                      type="email"
                      variant="filled"
                      label="Email"
                      name="spocEmail"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.spocEmail}
                      onChange={formik.handleChange}
                      helperText={formik.errors.spocEmail}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="email"
                      variant="filled"
                      label="Email"
                      name="spocEmail"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.spocEmail}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>
                <GridItem xs={12} sm={4} md={4} lg={4}>
                  <MuiPhoneNumber
                    name="spocPhone"
                    variant="filled"
                    label="Phone"
                    // data-cy="user-phone"
                    fullWidth
                    InputLabelProps={inputProps}
                    defaultCountry={"us"}
                    disableDropdown={true}
                    value={formik.values.spocPhone}
                    onChange={e => formik.setFieldValue("spocPhone", e)}
                  />
                </GridItem>
              </Grid>
              <Typography variant="h4" className={classes.titleLabel}>
                Account administrator
              </Typography>
              <Grid container className={classes.container}>
                <GridItem xs={12} sm={4} md={4} lg={4}>
                  {formik.errors.adminFirstName &&
                  formik.touched.adminFirstName ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="First name"
                      name="adminFirstName"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.adminFirstName}
                      onChange={formik.handleChange}
                      helperText={formik.errors.adminFirstName}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="First name"
                      name="adminFirstName"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.adminFirstName}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>
                <GridItem xs={12} sm={4} md={4} lg={4}>
                  <TextField
                    type="text"
                    variant="filled"
                    label="Last name"
                    name="adminLastName"
                    fullWidth
                    InputLabelProps={inputProps}
                    value={formik.values.adminLastName}
                    onChange={formik.handleChange}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={4} lg={4}>
                  {formik.errors.adminEmail && formik.touched.adminEmail ? (
                    <TextField
                      type="email"
                      variant="filled"
                      label="Email"
                      name="adminEmail"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.adminEmail}
                      onChange={formik.handleChange}
                      helperText={formik.errors.adminEmail}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="email"
                      variant="filled"
                      label="Email"
                      name="adminEmail"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.adminEmail}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>
                <GridItem xs={12} sm={4} md={4} lg={4}>
                  <MuiPhoneNumber
                    name="adminPhone"
                    variant="filled"
                    label="Phone"
                    // data-cy="user-phone"
                    fullWidth
                    InputLabelProps={inputProps}
                    defaultCountry={"us"}
                    disableDropdown={true}
                    value={formik.values.adminPhone}
                    onChange={e => formik.setFieldValue("adminPhone", e)}
                  />
                </GridItem>
              </Grid>
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{ marginTop: "20px", marginBottom: "30px" }}
              >
                <GridItem xs={12} sm={6} md={3} lg={3}>
                  {!btnDisabled ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      disableElevation
                      size="large"
                      className={classes.buttStyle}
                      fullWidth
                      disableFocusRipple
                      type="submit"
                    >
                      Submit
                    </Button>
                  ) : null}
                  {btnDisabled ? (
                    <Loader
                      type={LOADER_TYPE}
                      color={theme.palette.secondary.main}
                      width={50}
                      height={50}
                      style={{ float: "right", marginRight: "15px" }}
                    />
                  ) : null}
                </GridItem>
                <GridItem xs={12} sm={6} md={3} lg={3}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    disableElevation
                    size="large"
                    // className={classes.buttStyle}
                    fullWidth
                    // onClick={hideForm}
                    disableFocusRipple
                    type="submit"
                  >
                    Cancel
                  </Button>
                </GridItem>
              </Grid>

              <NotificationAlert
                notificationType={notificationType}
                showNotification={showNotification}
                message={notificationMessage}
              />
            </form>
          </Grid>
        </Paper>
      </LoaderHoc>
    </div>
  );
};

export default AccountInformation;
