import React, { useState } from "react";
import {
    getStringBetween, msToStr,
    replaceToButton
} from "views/ServiceOffering/LearningLab/acess labs/sdntokenizerhelper";
import Danger from "components/Typography/Danger.js";
import "./Overview.scss";
import { Animated } from "react-animated-css";
import Button from "@material-ui/core/Button";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import copyIcon from "assets/criterion_networks/svg/copy.svg";
import { SECONDARY_COLOR } from "global_constants/app_constants";
import * as clipboard from "clipboard-polyfill/text";
import { useDispatch } from "react-redux";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
import ReservedIpModal from "views/Components/ServiceOffering/ReservedIpModal";
import DemoLaunchModal from "views/Components/ServiceOffering/DemoLaunchModal";
import moment from "moment";
const ReactMarkdown = require("react-markdown");

const useStyles = makeStyles(theme => ({
  imageIcon: {
    display: "flex",
    height: "inherit",
    width: "inherit",
    alignSelf: "middle",
    verticalAlign: "super"
  },
  iconRoot: {
    textAlign: "center",
    "& svg": {
      fill: "grey"
    },
    "& svg:hover": {
      fill: "#F96B13"
    }
  },
  buttStyle: {
    background: theme.palette.buttonColor.main,
    "&:hover": {
      background: theme.palette.buttonColor.main
    }
  }
}));

const Overview = props => {
  const {
    data,
    ipAddress,
    hostName,
    labData,
    reservedIp,
    provisionLabBtnHandler,
    handleLaunchDemo,
    isLabProvisioned,
    labStatus,
    offeringType,
    selectedMenu
  } = props;
  var timeDiff
  if(props?.labData?.expiryDate) {
       timeDiff = msToStr(
          moment(new Date()).format("YYYY-MM-DD HH:mm"),
          props.labData.expiryDate
      );
  }
  const dispatch = useDispatch();
  const classes = useStyles();
  const [hideIpModal, setHideIpModal] = useState(true);
  const [hideDemoModal, setHideDemoModal] = useState(true);

  const hideReservedIpModal = () => {
    setHideIpModal(true);
  };
  const showReservedIpModal = () => {
    setHideIpModal(false);
  };

  const hideReservedDemoModal = () => {
    setHideDemoModal(true);
  };

  const showReservedDemoModal = () => {
    setHideDemoModal(false);
  };

  // setHideModal(true);
  if (!data) {
    return <Danger>{""}</Danger>;
  }

  const renderers = {
    // code is the name of the node which will be captured by renderer.
    // code is also the tag in html
    // eslint-disable-next-line react/prop-types
    // eslint-disable-next-line react/display-name

    // whiteSpace: "pre-wrap" /* Since CSS 2.1 */,
    // whiteSpace: "-moz-pre-wrap" /* Mozilla, since 1999 */,
    // whiteSpace: "-pre-wrap" /* Opera 4-6 */,
    // whitepace: "-o-pre-wrap" /* Opera 7 */,
    // wordWrap: "break-word" /* Internet Explorer 5.5+ */

    code: ({ language, value }) => {
      return (
        <pre
          style={{
            cursor: "auto",
            whiteSpace: "break-spaces"
          }}
        >
          <p
            style={{
              width: "100%",
              padding: "15px",
              backgroundColor: SECONDARY_COLOR,
              cursor: "auto"
            }}
          >
            <code
              style={{
                color: "white",
                width: "100%",
                height: "auto",
                padding: "0px",
                backgroundColor: SECONDARY_COLOR
              }}
              language={language}
              children={value}
            />
            <span
              style={{ color: "white", float: "right", cursor: "pointer" }}
              onClick={() => {
                clipboard.writeText(value);
                const payload = {
                  message: "Code copied successfully.",
                  color: "success",
                  delay: 1000
                };
                dispatch(SHOW_NOTIFICATION({ payload }));
              }}
            >
              <Icon classes={{ root: classes.iconRoot }}>
                <img className={classes.imageIcon} alt="logo" src={copyIcon} />
              </Icon>
            </span>
          </p>
        </pre>
      );
    }
    // paragraph: props => {
    //   //console.log("para", props);
    //   return <p style={{ color: "cyan" }} children={props.value} />;
    // }
  };

  //for every begin and end panel search for begin and end button and if that is found then extract the link from it and use it to create a simple button with <button> tag and return it.
  // Sinch react markdown component is nothing but tree of rendered react element so our button will fit properly to it.
  const panels = getStringBetween(data, "%%beginpanel%%", "%%endpanel%%");
  if (panels.length !== 0) {
    return panels.map((panel, index) => {
      panel = replaceToButton(
        panel,
        ipAddress,
        hostName,
        labStatus,
        "%%beginbtn%%",
        "%%endbtn%%"
      );
      var launch = true;
      if(panel.includes('class="button" value="Launch"')){
        launch = false;
      }
      const provisionLabToken = panel.search("{{token_provision_lab}}");
      if (provisionLabToken !== -1) {
        panel = panel.replace("{{token_provision_lab}}", "");
      }
      return (
        <>
          <Animated
            animationIn="fadeIn"
            animationOut="fadeOut"
            animationInDuration={2000}
            isVisible={true}
          >
            <ReactMarkdown
              className="panel"
              source={panel}
              escapeHtml={false}
              renderers={renderers}
            />
            {panel?.replace("\r", "")?.replace("\n", "")?.length ? (
              <hr />
            ) : null}
            {provisionLabToken !== -1 &&
            !isLabProvisioned &&
            offeringType !== "sales demo" ? (
              <GridContainer justify="flex-start">
                <GridItem xs={12} sm={5} md={4} lg={3}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    size="medium"
                    fullWidth
                    className={classes.buttStyle}
                    onClick={() => showReservedIpModal()}
                    disableFocusRipple
                  >
                    Provision Lab
                  </Button>
                </GridItem>
              </GridContainer>
            ) : null}
            {provisionLabToken !== -1 &&
            !isLabProvisioned &&
            offeringType === "sales demo" ? (
              <GridContainer justify="flex-start">
                <GridItem xs={12} sm={5} md={4} lg={3}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    size="medium"
                    fullWidth
                    className={classes.buttStyle}
                    onClick={() => showReservedDemoModal()}
                    disableFocusRipple
                  >
                    Provision Lab
                  </Button>
                </GridItem>
              </GridContainer>
            ) : null}
            {selectedMenu === "Demo Use Cases" &&
            offeringType === "sales demo" &&
            labStatus === "ready" && launch ? (
              <GridContainer justify="flex-start">
                <GridItem xs={12} sm={5} md={4} lg={3}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    size="large"
                    fullWidth
                    className={classes.buttStyle}
                    onClick={() => handleLaunchDemo()}
                    disableFocusRipple
                  >
                    Launch Sales demo
                  </Button>
                </GridItem>
              </GridContainer>
            ) : null}
          </Animated>
          {!hideIpModal || !hideDemoModal ? (
            offeringType == "learninglabs" ? (
              <ReservedIpModal
                labLaunchData={{
                  labId: labData.labId,
                  labIdentifier: labData.labIdentifier,
                  labEnabled: true, // labEnabled parameter
                  fullTitle: "Launch learning lab",
                  labName: labData.labName,
                  launchLabBtnHandler: provisionLabBtnHandler
                }}
                reservedIp={reservedIp}
                notes={
                  "Option selected will reflect the IP address for the learning lab."
                }
                offeringType={offeringType}
                openModal={hideIpModal}
                hideIpModal={hideReservedIpModal}
              />
            ) : (
              <DemoLaunchModal
                demoLaunchData={{
                  labId: labData.labId,
                  labIdentifier: labData.labIdentifier,
                  labEnabled: true, // labEnabled parameter
                  fullTitle: "Launch sales demo",
                  labName: labData.labName,
                  launchLabBtnHandler: provisionLabBtnHandler,
                  runTime: props
                }}
                reservedIp={reservedIp}
                notes={
                  "Option selected will reflect the IP address for the sales demo."
                }
                offeringType={offeringType}
                openModal={hideDemoModal}
                hideDemoModal={hideReservedDemoModal}
              />
            )
          ) : null}
            {!hideIpModal? (<ReservedIpModal
              labLaunchData={{
                labId: props.labData.labId,
                labIdentifier: props.labData.labIdentifier,
                labEnabled: true, // labEnabled parameter
                fullTitle: props.labData.labName,
                labName: labData.labName,
                launchLabBtnHandler: provisionLabBtnHandler,
                runTime: timeDiff
              }}
              offeringType={offeringType}
              openModal={hideIpModal}
              hideIpModal={hideReservedIpModal}
          />) : null}
        </>
      );
    });
  } else {
    // no panel found. It's a no panel, plain makdown.
    return (
      <>
        <Animated
          animationIn="fadeIn"
          animationOut="fadeOut"
          animationInDuration={2000}
          isVisible={true}
        >
          <ReactMarkdown className="panel" source={data} escapeHtml={false} />
          <hr />
          {isLabProvisioned && offeringType !== "sales demo" ? null : (
            <GridContainer justify="flex-start">
              <GridItem xs={12} sm={2} md={2} lg={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  disableElevation
                  size="medium"
                  className={classes.buttStyle}
                  fullWidth
                  onClick={() => showReservedIpModal()}
                  disableFocusRipple
                >
                  Provision Lab
                </Button>
              </GridItem>
            </GridContainer>
          )}
          {isLabProvisioned && offeringType === "sales demo" ? null : (
            <GridContainer justify="flex-start">
              <GridItem xs={12} sm={2} md={2} lg={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  disableElevation
                  size="medium"
                  fullWidth
                  className={classes.buttStyle}
                  onClick={() => showReservedDemoModal()}
                  disableFocusRipple
                >
                  Provision Lab
                </Button>
              </GridItem>
            </GridContainer>
          )}
        </Animated>
        {!hideIpModal || !hideDemoModal ? (
          offeringType == "learninglabs" ? (
            <ReservedIpModal
              labLaunchData={{
                labId: labData.labId,
                labIdentifier: labData.labIdentifier,
                labEnabled: true, // labEnabled parameter
                fullTitle: "Launch learning lab",
                labName: labData.labName,
                launchLabBtnHandler: provisionLabBtnHandler,
                runTime: props
              }}
              reservedIp={reservedIp}
              notes={
                "Option selected will reflect the IP address for the learning lab."
              }
              offeringType={offeringType}
              openModal={hideIpModal}
              hideIpModal={hideReservedIpModal}
            />
          ) : (
            <DemoLaunchModal
              demoLaunchData={{
                labId: labData.labId,
                labIdentifier: labData.labIdentifier,
                labEnabled: true, // labEnabled parameter
                fullTitle: "Launch sales demo",
                labName: labData.labName,
                launchLabBtnHandler: provisionLabBtnHandler,
                runTime: props
              }}
              reservedIp={reservedIp}
              notes={
                "Option selected will reflect the IP address for the sales demo."
              }
              offeringType={offeringType}
              openModal={hideDemoModal}
              hideDemoModal={hideReservedDemoModal}
            />
          )
        ) : null}
      </>
    );
  }
};

export default Overview;