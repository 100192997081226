import React from "react";
import { Grid } from "@material-ui/core";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import brand from "assets/criterion_networks/img/brand-logo.png";

const sdcloudImage = require("assets/criterion_networks/img/sdcloud-login-page_Updated.png");

const useStyles = makeStyles(styles);

const ListHolder = () => {
  const classes = useStyles();
  const ListArr = [
    {
      data: "Accelerating Network Transformation with Criterion SDCloud®",
      link: "",
    },
    {
      data: "Accelerating Network Transformation with Criterion SDCloud®",
      link: "",
    },
    {
      data: "Accelerating Network Transformation with Criterion SDCloud®",
      link: "",
    },
  ];
  const List = ({ data }) => {
    return (
      <Grid
        className={classes.listItem}
        container
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} className={classes.listData}>
          {data.data}
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      container
      className={classes.ListContainer}
      justify="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={12} className={classes.listHolderHeading}>
        Future webinar/News/etc
      </Grid>
      {ListArr.map((item, index) => {
        return (
          <Grid item xs={12} key={index}>
            <List data={item} />
          </Grid>
        );
      })}
    </Grid>
  );
};
const AdvertisingComponent = (props) => {
  const classes = useStyles();
  return (
    // <Grid
    //   container
    //   className={classes.advertisingComponentContainer}
    //   alignItems="center"
    // >
     
    //   <Grid item xs={12} className={classes.advertisingComponentLowerDiv}>
        
        <img
          src={sdcloudImage}
          style={{ maxWidth: "90%", height: "auto", alignSelf:"center"}}
        />
      // </Grid>
    //</Grid> 
  );
};

const BrandImage = () => {
  const classes = useStyles();
  return (
    <div className={classes.brandImage}>
      <img
        src={brand}
        alt="brand-logo"
        style={{
          width: "164px",
        }}
      />
    </div>
  );
};

export default AdvertisingComponent;
