import React, { useEffect } from "react";

// Material UI Components
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

// Other Components
import { useTable, useSortBy, usePagination, useRowSelect } from "react-table";

// icons
import arrowIcon from "../../assets/img/up-arrow.png";

// Styling
import "./CustomTable.scss";
const useStyles = makeStyles((theme) => ({
  tablehead: {
    // fontWeight: "bold !important",
    padding: "5px",
  },
  tablebody: {
    padding: "5px",
  },
  paginationToolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
    fontFamily: theme.palette.fontList.selectedFont,
  },
  font: {
    fontFamily: `${theme.palette.fontList.selectedFont}`,
    "& .table-pagination .MuiPaginationItem-page": {
      fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    },
    "& .table-pagination .MuiPagination-ul li:first-child .MuiPaginationItem-page:after": {
      fontFamily: `${theme.palette.fontList.selectedFont} !important`,
      color: theme.palette.secondary.main,
    },
    "& .table-pagination .MuiPagination-ul li:last-child .MuiPaginationItem-page:after": {
      fontFamily: `${theme.palette.fontList.selectedFont} !important`,
      color: theme.palette.secondary.main,
    },
  },
  paginationNumbers: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& p, div": {
      margin: "0 10px",
    },
  },
  numberButton: {
    minWidth: "0px",
    padding: "4px",
    fontWeight: 300,
    fontSize: 14,
    "& .MuiButton-label": {
      width: "24px",
      height: "24px",
      backgroundColor: "#3868C2",
      color: "#ffffff",
    },
    "& .MuiButton-text": {
      padding: "0 1px",
    },
  },
  CustomCheckbox: {
    "&.Mui-checked": {
      color: `${theme.palette.secondary.main} !important`,
    },
  },
}));

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    const classes = useStyles();
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <Checkbox
        className={`checkbox ${classes.CustomCheckbox}`}
        inputRef={resolvedRef}
        {...rest}
      />
    );
  }
);

const CustomTable = ({
  isRowSelection,
  columns,
  data,
  hiddenColumns,
  onRowSelect,
  table,
  count,
  setTemplatesList,
}) => {
  const classes = useStyles();
  const [initialState, setinitialState] = React.useState(true);
  const [pageNo,setpageNo] = React.useState(1)
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    pageCount,
    gotoPage,
    setPageSize,
    selectedFlatRows,
    // state: { pageSize }, //eslint-disable-line
    state: { pageIndex, pageSize, selectedRowIds },
    toggleAllRowsExpanded,
    isAllRowsExpanded,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 10,
        hiddenColumns: hiddenColumns ? hiddenColumns : [],
      },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (isRowSelection) {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox
                  {...getToggleAllPageRowsSelectedProps()}
                />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
      }
    }
  );

  useEffect(() => {
    onRowSelect(selectedFlatRows);
  }, [selectedFlatRows]);

  const handlePage = (event, newPage) => {
    document.getElementById("table-head").scrollIntoView();
    setTemplatesList(newPage,pageSize);
    setpageNo(newPage)
    gotoPage(newPage - 1);
  };
  const handleRecordCount = (event) => {
    const newPageSize = Number(event.target.value)
    setPageSize(newPageSize)
    gotoPage(1);
    setpageNo(1)
    setTemplatesList(1, newPageSize);
  };
  
  return (
    <div className={`tableView ${classes.font}`}>
      <Paper className="white-section" elevation={0}>
        <Table
          className="custom-table"
          aria-label="customized table"
          {...getTableProps()}
        >
          <TableHead id="table-head">
            {headerGroups.map((headerGroup, hi) => (
              <TableRow key={hi} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, ci) => (
                  <TableCell
                    key={ci}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={classes.tablehead}
                  >
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span>
                          <img className="desc" src={arrowIcon} alt="down" />
                        </span>
                      ) : (
                        <span>
                          <img className="asc" src={arrowIcon} alt="up" />
                        </span>
                      )
                    ) : null}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {page.map((row, ri) => {
              prepareRow(row);
              return (
                <TableRow key={ri} {...row.getRowProps()}>
                  {row.cells.map((cell, ci) => {
                    return (
                      <TableCell key={ci} {...cell.getCellProps()}>
                        <div className={`name-col ${classes.tablebody}`}>
                          {cell.render("Cell")}
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>

      <div className={classes.paginationToolbar}>
        <div>
          <span>
            <select
              className={`display-page ${classes.font}`}
              value={pageSize}
              onChange={handleRecordCount}
            >
              {[5, 10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize} Items
                </option>
              ))}
            </select>
            &nbsp;&nbsp;per page
          </span>
        </div>
        <Pagination
          className={`table-pagination ${classes.font}`}
          count={count}
          page={pageNo} 
          variant="outlined"
          onChange={handlePage}
          style={{paddingRight:"45px"}}
        />
      </div>
    </div>
  );
};

export default CustomTable;
