import React from "react";
import { useHistory } from "react-router-dom";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";
import { PropTypes } from "prop-types";
import { SECONDARY_COLOR } from "global_constants/app_constants";
import Button from "@material-ui/core/Button";
import {
  activeTextColor,
  errorTextColor,
} from "assets/jss/material-dashboard-pro-react";
import { withStyles } from "@material-ui/core/styles";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { Grid } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import { calcTimeLeft } from "utils/utilfunction";
import moment from "moment";
import { msToStr } from "views/ServiceOffering/LearningLab/acess labs/sdntokenizerhelper";
import { Urls } from "config/api";
import { Offerings } from "config/api";
import LearningCenterTable from "components/Table/LearningCenterTable";
// import LearningTableHeader from "components/Table/LearningTableHeader";
import SearchHeader from "../ExperienceCenter/components/SearchHeader";
// import { Button } from "react-scroll";
import lock from "assets/img/lock.png";
import unlock from "assets/img/unlock.png";
import globalTheme from "../../../theme";
import { ReactComponent as svg7 } from "../../../assets/criterion_networks/svg/07.svg";
import SvgComponent from "utils/SvgComponent";
import { ReactComponent as svg11 } from "../../../assets/criterion_networks/svg/11.svg";
import { ReactComponent as svg12 } from "../../../assets/criterion_networks/svg/12.svg";
const placeholderImage = require("assets/criterion_networks/img/LearningLab_defaultimg.png");

const handleAccessLabBtn = (
  lab_uuid,
  lab_identifier,
  createdDate,
  expiryDate,
  history,
  getLabIdfromLabIdentifier,
  offeringType,
  addHoursDetails,
  profileObj = null,
  clusterUUID,
  template_name
) => {
  let redirectRoute = null;

  if (offeringType.title === Offerings.SALES_DEMO.title) {
    redirectRoute = Urls.accessSalesDemo;
  } else if (offeringType.title === Offerings.LEARNING_LABS.title) {
    redirectRoute = Urls.accessLab;
  } else if (offeringType.title === Offerings.POC_LIBRARY.title) {
    this.props.history.push(`/access-sandbox/${clusterUUID}/${template_name}`);
    return true;
  }
  getLabIdfromLabIdentifier(lab_identifier).then(({ id, full_title }) => {
    const stateParams = {
      labId: id,
      labName: full_title,
      labEnabled: false,
      labIdentifier: lab_identifier,
      uuid: lab_uuid,
      createdDate: createdDate,
      expiryDate: expiryDate,
      addHoursDetails: addHoursDetails,
    };
    // push access learning labs
    if (lab_uuid !== null) {
      stateParams.labEnabled = true;
    } else {
      stateParams.labEnabled = false;
    }
    let additionParams = stateParams;
    additionParams["addHoursDetails"] = profileObj;
    additionParams["offeringType"] = offeringType;
    history.push({
      pathname: redirectRoute,
      state: { stateParams: stateParams },
      search: `?labId=${stateParams.labId}&uuid=${stateParams.uuid}&labIdentifier=${stateParams.labIdentifier}&labEnabled=${stateParams.labEnabled}&labName=${stateParams.labName}&expiryDate=${stateParams.expiryDate}&createdDate=${stateParams.createdDate}&offeringType=${stateParams.offeringType.title}`,
    });
  });
};

const handleTest = () => {};

const customStyles = (theme) => ({
  hrLine: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  roundActiveLabIcon: {
    color: "green",
    fontSize: 12,
    paddingRight: "2px",
  },
  roundInactiveLabIcon: {
    color: "red",
    fontSize: 12,
    paddingRight: "2px",
  },
  labelStyle: {
    fontWeight: 500,
  },
  header: {
    backgroundColor: SECONDARY_COLOR,
    color: "#ffffff",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
  },
  labCountHeading: {
    fontWeight: 400,
  },
  accessLabBtnStyle: {
    float: "right",
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px",
      width: "100%",
    },
  },
  cardStyle: {
    borderRadius: "4px",
    backgroundColor: "white",
    marginBottom: "30px",
    // border: `1px solid ${PRIMARY_COLOR}`,
    boxShadow: "0 1px 2px 0 rgba(0,0,0,0.2)",
    "&:hover, &$focusVisible": {
      boxShadow: "0 8px 16px 0 rgba(0,0,0,0.6)",
    },
  },
  cardBody: {
    flex: "1 1 auto",
    padding: "30px 30px !important",
    position: "relative",
    "-webkit-box-flex": "1",
  },
  imgFilter: {
    filter: theme.palette.secondary.filterImage,
    background: "transparent !important",
  },
  font: {
    fontFamily: `${theme.palette.fontList.selectedFont} !important`,
  },
  unsetIcon: {
    fontSize: "unset",
    overflow: "unset",
  },
  absoluteImg: {
    // position: "absolute",
    height: "114px",
    width: "114px",
    left: "56px",
    borderRadius: "0px",
    top: "28px",
    background: "black",
  },
});
const selectedlabTypeStyle = {
  color: globalTheme.palette.secondary.main,
};
const LearningCardsContent = ({
  learningCards,
  rows,
  labFilter,
  getLabIdfromLabIdentifier,
  onRowSelect,
  handleActive,
  privilege,
  filterLabCounts,
  offeringType,
  // buttonTitle,
  handleLaunchNewLab,
  courseHeadings,
  setSearchedTemplates,
  addHoursDetails,
}) => {
  const useStyles = makeStyles(customStyles);
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <SearchHeader
        handleDelete={() => {}}
        // setSearchList={setSearchedTemplates}
        rows={learningCards}
        labFilter={labFilter}
        filterLabCounts={filterLabCounts}
        handleActive={handleActive}
        handleLaunchLab={handleLaunchNewLab}
      />
      <GridContainer>
        <GridItem className="list-view" md={12}>
          {/* {learningCards.length === 0 ? (
            <h4 style={{ fontWeight: 400 }}>
              {" "}
              No learning lab(s) found.{" "}
            </h4>
          ) : ( */}
          <LearningCenterTable
            rows={rows}
            onRowSelect={onRowSelect}
            handleAccessLabBtn={handleAccessLabBtn}
            courseTableHeadings={courseHeadings}
            privilege={privilege}
            getLabId={getLabIdfromLabIdentifier}
            history={history}
            offeringType={offeringType}
            labFilter={labFilter}
            addHoursDetails={addHoursDetails}
          />
          {/* )} */}
        </GridItem>
      </GridContainer>
    </>
  );
};

class LearningCards extends React.Component {
  constructor(props) {
    super(props);
    this.offeringType = this.props.offeringType;

    this.state = {
      labs: this.props.labs,
      learningCards: [],
      labFilter: this.props.labFilter,
      addHoursDetails: this.props.addHoursDetails,
      privilege: this.props.privilege,
      rows: [],
      courseHeadings: {},
    };
  }

  componentDidMount = () => {
    this.buildLearningCards();

    let statusUpdateInterval = setInterval(() => {
      this.setState({
        learningCards: this.state.labs.activeLabs,
      });
    }, 15000);

    return () => {
      clearInterval(statusUpdateInterval);
    };
  };

  componentWillReceiveProps(newProps) {
    if (newProps.labFilter === "All") {
      this.setState({
        learningCards: newProps.labs.allLabs,
      });
    }
    if (newProps.labFilter === "Active") {
      this.setState({
        learningCards: newProps.labs.activeLabs,
      });
    }
    if (newProps.labFilter === "Inactive") {
      this.setState({
        learningCards: newProps.labs.inactiveLabs,
      });
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.labFilter !== this.props.labFilter) {
      this.setState(
        {
          labs: this.props.labs,
          labFilter: this.props.labFilter,
        },
        () => {
          this.buildLearningCards();
        }
      );
    }
  };

  handleAccessLabBtn = (
    lab_uuid,
    lab_identifier,
    createdDate,
    expiryDate,
    history,
    getLabIdfromLabIdentifier,
    offeringType,
    addHoursDetails,
    profileObj = null,
    clusterUUID,
    template_name
  ) => {
    let redirectRoute = null;

    if (offeringType.resource === Offerings.SALES_DEMO.resource) {
      redirectRoute = Urls.accessSalesDemo;
    } else if (offeringType === Offerings.LEARNING_LABS.resource) {
      redirectRoute = Urls.accessLab;
    } else if (offeringType === Offerings.POC_LIBRARY.resource) {
      this.props.history.push(
        `/access-sandbox/${clusterUUID}/${template_name}`
      );
      return true;
    }
    getLabIdfromLabIdentifier(lab_identifier).then(({ id, full_title }) => {
      const stateParams = {
        labId: id,
        labName: full_title,
        labEnabled: false,
        labIdentifier: lab_identifier,
        uuid: lab_uuid,
        createdDate: createdDate,
        expiryDate: expiryDate,
        addHoursDetails: addHoursDetails,
        offeringType: offeringType,
      };
      // push access learning labs
      if (lab_uuid !== null) {
        stateParams.labEnabled = true;
      } else {
        stateParams.labEnabled = false;
      }
      let additionParams = stateParams;
      additionParams["addHoursDetails"] = profileObj;
      additionParams["offeringType"] = offeringType;
      history.push({
        pathname: redirectRoute,
        state: { stateParams: stateParams },
        search: `?labId=${stateParams.labId}&uuid=${stateParams.uuid}&labIdentifier=${stateParams.labIdentifier}&labEnabled=${stateParams.labEnabled}&labName=${stateParams.labName}&expiryDate=${stateParams.expiryDate}&createdDate=${stateParams.createdDate}&offeringType=${stateParams.offeringType}`,
      });
    });
  };

  setSearchedTemplates = (learnings) => {
    // building row data
    let updatedList = learnings.reduce((accum, labItem) => {
      let learningCardObj = {};
      let labTimeLeft = msToStr(labItem.created, labItem.expiry_date);
      if (
        labItem.is_active &&
        (labItem.lab_status == "terminated" || labItem.lab_status == "ready")
      ) {
        labTimeLeft = msToStr(
          moment(new Date()).format("YYYY-MM-DD HH:mm"),
          labItem.expiry_date
        );
      }
      // eslint-disable-line
      learningCardObj["name"] = (
        <Grid container style={{ width: "250px" }}>
          <Grid item xs={3}>
            {/* replace props.thumbnail with api value */}
            <img
              src={
                labItem.thumbnail ||
                require("assets/criterion_networks/img/LearningLab_defaultimg.png")
              }
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={9}>
            <Grid container style={{ marginLeft: "15px" }}>
              <Grid item xs={12} style={{ fontWeight: 400 }}>
                {labItem.name ||
                  labItem.display_name ||
                  labItem.resource_name ||
                  labItem.template_name}
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  fontSize: "12px",
                }}
              >
                Launched on {labItem.labCreated}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );

      learningCardObj["daysLeft"] = calcTimeLeft(
        labTimeLeft,
        labItem.lab_status
      );
      learningCardObj["status"] = (
        <Grid container style={{ width: "100%" }} alignItems="center">
          <Grid
            item
            xs={3}
            style={{
              color: labItem.is_active ? activeTextColor : errorTextColor,
            }}
          >
            <Grid container>{labItem.is_active ? "active" : "inactive"}</Grid>
          </Grid>
          <Grid item xs={9}>
            <Grid container style={{ marginLeft: "15px" }}>
              <Grid
                item
                xs={12}
                style={{ fontWeight: 400, textTransform: "capitalize" }}
              >
                {labItem.lab_status}
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  fontSize: "12px",
                }}
              >
                Lab status
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
      learningCardObj["user"] = (
        <Grid container style={{ width: "100%" }} alignItems="center">
          <Grid item xs={10}>
            <Grid item xs={12} style={{ fontWeight: 400 }}>
              {labItem.user_name}
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                fontSize: "12px",
              }}
            >
              {labItem.user_email}
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container style={{ paddingLeft: "15px" }}>
              <Grid item xs={12}>
                <Tooltip
                  id="tooltip-top"
                  enterDelay={200}
                  leaveDelay={200}
                  TransitionComponent={Zoom}
                  title={`Go to ${this.offeringType}`}
                  placement="bottom-start"
                >
                  <ArrowRightIcon
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      // handleAccessLabBtn(
                      //   labItem.lab_uuid,
                      //   labItem.name,
                      //   labItem.created,
                      //   labItem.expiry_date,
                      //   this.props.history,
                      //   this.props.getLabIdfromLabIdentifier,
                      //   this.offeringType,
                      //   this.state.addHoursDetails
                      // )
                      handleTest()
                    }
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
      return accum.concat(learningCardObj);
    }, []);
    this.setState({ rows: learnings });
  };

  // creates learning cards based on the filter selected.
  buildLearningCards = async () => {
    switch (this.state.labFilter) {
      case "Active":
        this.setState({ learningCards: this.state.labs.activeLabs });
        break;
      case "Inactive":
        this.setState({ learningCards: this.state.labs.inactiveLabs });
        break;
      case "All":
        this.setState({ learningCards: this.state.labs.allLabs });
        break;
      default:
        this.setState({ learningCards: this.state.labs.allLabs });
    }
  };

  render() {
    var { learningCards } = this.state;

    return (
      <>
        {learningCards &&
          learningCards.length > 0 &&
          learningCards.map((item, key) => {
            // let labTimeLeft = msToStr(item.created, item.expiry_date);
            let labTimeLeft = msToStr(
              moment(new Date()).format("YYYY-MM-DD HH:mm"),
              item.expiry_date
            );
            let endTime = moment(item.expiry_date);
            let startTime = moment(
              moment(new Date()).format("YYYY-MM-DD HH:mm")
            );
            let differenceInMs = endTime.diff(startTime);
            let dif = moment.duration(differenceInMs);
            let hours = dif.hours();
            let minutes = dif.minutes();
            // if (
            //   item.is_active &&
            //   (item.lab_status == "terminated" || item.lab_status == "ready")
            // ) {
            //   labTimeLeft = msToStr(
            //     moment(new Date()).format("YYYY-MM-DD HH:mm"),
            //     item.expiry_date
            //   );
            //   //console.log("SecLabTime",labTimeLeft)
            // }
            let status =
              item.lab_status == "Not provisioned" ||
              (item.lab_status !== "terminated" &&
                item.lab_status !== "suspended" && item.lab_status !== "archived")
                ? "Active"
                : "Inactive";
            const imageUrl = item.thumbnail ? item.thumbnail : "";
            // this.props.offeringType.resource === "SalesDemo" ?
            //   "https://sdcloud-imgs.s3.us-west-2.amazonaws.com/default_logos/default_experience.png"
            //   : "https://sdcloud-imgs.s3.us-west-2.amazonaws.com/default_logos/default_learning.png"
            if (minutes > 0 && hours >= 0) {
              return (
                <div className="sortable-moves">
                  {/* <img class='img-fluid p-absolute image-dim' src={imageUrl} alt=''></img> */}
                  {imageUrl.trim() !== "" ? (
                    <img
                      className="img-fluid p-absolute image-dim"
                      src={imageUrl}
                      alt=""
                    ></img>
                  ) : this.props.offeringType.resource === "SalesDemo" ? (
                    <div className="imgdefault">
                      <SvgComponent
                        className={this.props.classes.absoluteImg}
                        type="default"
                        svgSource={svg11}
                      />
                    </div>
                  ) : (
                    <div className="imgdefault">
                      <SvgComponent
                        className={this.props.classes.absoluteImg}
                        type="default"
                        svgSource={svg12}
                      />
                    </div>
                  )}
                  <h6 className={`card-title ${this.props.classes.font}`}>
                    {item.display_name}
                    <SvgComponent
                      className={`new-lock ${this.props.classes.unsetIcon}`}
                      type="icon"
                      svgSource={svg7}
                    />
                    {/* <img className={`new-lock ${this.props.classes.imgFilter}`} src={svg7} alt=''></img> */}
                  </h6>
                  <p className="card-content"></p>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={9}>
                      <p
                        className={`card-content position float-left pt-4 ${this.props.classes.font}`}
                        style={{ paddingBottom: "10px", width: "76%" }}
                      >
                        Time Left : <span>{labTimeLeft}</span>{" "}
                        <span className="sepbar">|</span> Status :{" "}
                        <span>{status}</span> <br /> Lab Status :{" "}
                        <span style={{ textTransform: "capitalize" }}>
                          {item.lab_status}
                        </span>
                        <span className="sepbar">|</span>Launched By :{" "}
                        <span style={{ textTransform: "capitalize" }}>
                          {item.user_name}
                        </span>
                      </p>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      justifyContent="flex-end"
                      className="right-button"
                    >
                      <div className="card-content float-right position pt-4">
                        <Button
                          className={this.props.classes.font}
                          onClick={() =>
                            this.handleAccessLabBtn(
                              item.lab_uuid,
                              item.name,
                              item.created,
                              item.expiry_date,
                              this.props.history,
                              this.props.getLabIdfromLabIdentifier,
                              this.offeringType,
                              this.state.addHoursDetails
                            )
                          }
                          style={selectedlabTypeStyle}
                        >
                          {this.props.offeringType.resource === "SalesDemo"
                            ? "Access Salesdemo"
                            : "Access Learning Lab"}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              );
            } else {
              learningCards = [];
            }
          })}
        {learningCards &&
        learningCards.length === 0 &&
        this.props.offeringType.resource === "SalesDemo" ? (
          <h4 className={this.props.classes.font} style={{ fontWeight: 400 }}>
            {" "}
            {/* No {this.state.labFilter} lab(s) found.{" "} */}
            No matches found.{" "}
          </h4>
        ) : learningCards && learningCards.length === 0 && learningCards.length < 1 ? (
          <h4 className={this.props.classes.font} style={{ fontWeight: 400 }}>
            {" "}
            {/* No {this.state.labFilter} lab(s) found.{" "} */}
            {/* No active labs.{" "} */}
          </h4>
        ) : (
          ""
        )}
      </>
    );
  }
}

LearningCards.propTypes = {
  classes: PropTypes.object.isRequired,
  labFilter: PropTypes.string.isRequired,
  labs: PropTypes.object.isRequired,
  getLabIdfromLabIdentifier: PropTypes.func,
};

let stylesHOC = withStyles(customStyles)(LearningCards);
export default withRouter(stylesHOC);
