import React, { Component, useState, useEffect, useRef } from "react";
import {CircularProgress } from '@material-ui/core/';
import { connect, useDispatch } from "react-redux";
import * as QueryString from "query-string";
import { Api, Urls } from "config/api";
import moment from "moment";
import Loader from "react-loader-spinner";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Grid from "@material-ui/core/Grid";
import { Alert } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AddIcon from "@material-ui/icons/Add";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import CloudReadyIcon from "@material-ui/icons/CloudDone";
import Icon from "@material-ui/core/Icon";
import checkIcon from "assets/criterion_networks/svg/check.svg";
import Zoom from "@material-ui/core/Zoom";
import { Card } from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Danger from "components/Typography/Danger.js";
import { tooltip } from "assets/jss/material-dashboard-pro-react.js";
import CardBody from "components/Card/CardBody";
import { PropTypes } from "prop-types";
import AddHoursModal from "views/Components/ServiceOffering/AddHoursModal";
import ResumeLearningsModel from "views/Components/ServiceOffering/ResumeLearningsModel";
import "./Overview.scss";
import { v4 as uuidv4 } from "uuid";
import {
  sortedTitleArr,
  msToStr
} from "views/ServiceOffering/LearningLab/acess labs/sdntokenizerhelper";
import { LOADER_TYPE } from "global_constants/app_constants";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "global_constants/app_constants";
import Overview from "./Overview";
import AccessDevices from "./AccessDevices";
import DemoSummary from "./DemoSummary";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
import { SHOW_LOADER } from "action_creators/actioncreator";
import { HIDE_LOADER } from "action_creators/actioncreator";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import { CMSAPIWithToken } from "apis/CMS";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Icons
import WarningTwoTone from '@material-ui/icons/WarningTwoTone';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Typography } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import Box from "@material-ui/core/Box";
import "../../../ServiceOffering/ExperienceCenter/Sandboxes/AccessSandbox/progressBar.css";

import theme from "theme";
import { CompareArrowsOutlined } from "@material-ui/icons";
const VisibilityIcon = "icons/learnings/overview_certi.png";
const DescriptionIcon = "icons/learnings/labs_certi.png";


const customStyle = theme => ({
  dialogtitle:{
    fontSize:"1.5rem",
    fontFamily:"Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
    width:485,
    textAlign:"center"
  },
  containerStyle: {
    padding: "2%",

    // paddingRight: "15px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0px",
      paddingLeft: "0px",
      paddingRight: "0px"
    }
  },
  containerTitle: {
    color: "#000000",
    fontWeight: "normal"
  },
  containerBodyStyle: {
    marginTop: "30px"
  },
  cardStyle: {
    paddingLeft: "20px",
    paddingRight: "20px",
    fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    "& h2": {
      fontFamily: `${theme.palette.fontList.selectedFont} !important`
    }
  },
  menuContainerStyle: {
    marginTop: "15px",
    marginBottom: "15px",
    paddingBottom: "15px"
  },
  menuCard: {
    cursor: "pointer",
    color: "#0F1A2B",
    fontSize: "14px",
    lineHeight: "2rem",
    fontWeight: "normal",
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px"
    },
    fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    "&:hover": {
      color: theme.palette.secondary.main
    }
  },
  menuCardSelected: {
    color: "white",
    backgroundColor: theme.palette.secondary.main
  },
  menuHeader: {
    marginTop: "15px"
  },
  statusCard: {
    marginTop: "15px",
    width: "100%"
  },
  labDescription: {
    fontWeight: 300
  },
  labStatus: {
    color: theme.palette.secondary.main,
    padding: "0px 0px 0px 5px !important",
    "&::first-letter": {
      textTransform: "capitalize"
    },
    textAlign: "end"
  },
  intermediateStatus: {
    color: theme.palette.secondary.main,
    padding: "0px 0px 0px 5px !important",
    "&::first-letter": {
      textTransform: "capitalize"
    },
    textAlign: "start",
    alignSelf: "center"
  },
  timerGridItem: {
    textAlign: "right",
    alignSelf: "center",
    padding: "0px !important"
  },
  addHoursStyle: {
    color: theme.palette.secondary.main,
    padding: "0px 0px 0px 5px !important",
    cursor: "pointer"
  },
  labStatusIcon: {
    color: theme.palette.secondary.main,
    fontSize: "14px",
    marginTop: "7px",
    padding: "0px !important"
  },
  statusIcon: {
    color: theme.palette.secondary.main,
    marginRight: "5px"
  },
  addHoursIcon: {
    fontSize: "14px",

  },
  backButton: {
    margin: "15px 0",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& svg": {
      marginRight: "5px"
    },
    "&:hover": {
      color: theme.palette.secondary.main,
      "& svg": {
        fill: theme.palette.secondary.main
      }
    }
  },
  timeLeft: {
    fontWeight: 400,
    padding: "0px !important"
  },
  labTitle: {
    fontSize: "36px"
  },
  exerciseTitle: {
    bottom: 0,
    marginLeft: "15px",
    paddingLeft: "15px",
    paddingRight: "15px",
    cursor: "pointer",
    marginBottom: "0px",
    paddingBottom: "15px",
    fontWeight: "normal",
    color: "rgba(15, 26, 43, 0.5)",
    borderBottom: `2px solid white`,
    "&$focusVisible": {
      borderBottom: `2px solid ${theme.palette.secondary.main}`
    },
    fontFamily: `${theme.palette.fontList.selectedFont} !important`
  },
  headerStyle: {
    backgroundColor: "white",
    borderBottom: "1px solid #d8d8d8",
    // [theme.breakpoints.down("sm")]: {
    //   margin: "15px"
    // }
    margin: "0px",
    width: "100%"
  },
  contentStyle: {
    color: theme.palette.secondary.main,
    backgroundColor: "red",
    fontFamily: `${theme.palette.fontList.selectedFont} !important`
  },
  tooltipStyle: {
    tooltip
  },
  btnStyle: {
    width: "100px"
  },
  imageIcon: {
    verticalAlign: "super"
  },
  minimizeMenu: {
    paddingRight: "0px !important"
  },
  minimizeExerciseCard: {
    paddingLeft: "0px !important",
    paddingRight: "0px !important"
  },
  buttStyle: {
    background: theme.palette.buttonColor.main,
    "&:hover": {
      background: theme.palette.buttonColor.main
    }
  },
  buttOutlined: {
    color: theme.palette.buttonColor.main,
    borderColor: theme.palette.buttonColor.main,
    "&:hover": {
      border: `1px solid ${theme.palette.buttonColor.main}`
    }
  },
  pgress: {
    fontFamily: `${theme.palette.fontList.selectedFont} !important`,
    "&.active:before": {
      color: `${theme.palette.secondary.main} !important`,
      background: `${theme.palette.secondary.main} !important`
    },
    "&.active:after": {
      background: `${theme.palette.secondary.main} !important`
    },
    "&.inactive:before": {
      color: "#C4C4C4 !important",
      background: "#C4C4C4 !important"
    },
    "&.inactive:after": {
      background: "#C4C4C4 !important"
    },
    "&.selected": {
      color: `${theme.palette.secondary.main} !important`,
      "&.active": {
        color: `${theme.palette.secondary.main} !important`,
        background: `${theme.palette.secondary.main} !important`
      },
      "&:before": {
        color: `${theme.palette.secondary.main} !important`,
        background: `${theme.palette.secondary.main} !important`
      },
      "&:after": {
        background: `${theme.palette.secondary.main} !important`
      }
    }
  },
  font: {
    fontFamily: `${theme.palette.fontList.selectedFont} !important`
  },
  textfont: {
    fontFamily: `${theme.palette.fontList.selectedFont} !important`
  },
  progressBar: {
    backgroundColor: theme.palette.grey[300]
  },
  barColorPrimary: {
    backgroundColor: `${theme.palette.secondary.main} !important`
  },
  clusterStatus: {
    marginTop: "35px",
    position:"relative",
    right:"-500px",
    top:"-70px"

  },
  tooltipProgressbar: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12)
  },
  runningTimeTitle: {
    fontSize: "0.875rem",
    fontWeight: "300",
    marginRight: "5px",
    fontFamily: `${theme.palette.fontList.selectedFont} !important`
  },
  runningTime: {
    fontSize: "0.875rem",
    fontWeight: "500",
    fontFamily: `${theme.palette.fontList.selectedFont} !important`
  }
});

const selectedlabTypeStyle = {
  color: theme.palette.secondary.main,
  borderBottom: `2px solid ${theme.palette.secondary.main}`
};
const selectedlabMenuStyle = {
  color: theme.palette.secondary.main
};

const useStyles = makeStyles(customStyle);

const Sidebar = props => {
  const classes = useStyles();
  const dataBuildObj = props.dataBuildObj;
  const MenuCard = props => {
    const stateFromProps = { ...props };
    return (
      <GridItem xs={12}>
        <h4
          className={classes.menuCard}
          style={
            stateFromProps.selectedMenu === stateFromProps.name
              ? selectedlabMenuStyle
              : {}
          }
          onClick={() => {
            stateFromProps.updateSelectedSidebar(
              stateFromProps.name,
              stateFromProps.isCollapseSelected,
              stateFromProps.primaryMenu
            );
          }}
        >
          {stateFromProps.showSidebar ? stateFromProps.name : null}
        </h4>
      </GridItem>
    );
  };
  return (
    <GridContainer className="sidebar">
      <GridItem xs={12} sm={12} md={12} className={classes.menuHeader}>
        <GridContainer justify="flex-start" alignItems="center" direction="row">
          <GridItem>
            <Button
              onClick={props.toggleSideBarMenu}
              justIcon
              round
              color="info"
              style={{ minWidth: "auto", padding: "0px" }}
            >
              {props.showSidebar ? (
                <ListIcon style={{ fill: theme.palette.secondary.main }} />
              ) : (
                <MoreVertIcon style={{ fill: theme.palette.secondary.main }} />
              )}
            </Button>
          </GridItem>
          {props.showSidebar ? (
            <GridItem
              style={{
                color: "#0F1A2B",
                fontFamily: theme.palette.fontList.selectedFont
              }}
            >
              {props.offeringType == "sales demo"
                ? "Showcase menu"
                : "Learning menu"}{" "}
            </GridItem>
          ) : null}
        </GridContainer>
      </GridItem>
      {Object.keys(dataBuildObj).map(item => {
        return (
          <>
            <MenuCard
              key={uuidv4()}
              name={dataBuildObj[item].name}
              icon={dataBuildObj[item].icon}
              primaryMenu={dataBuildObj[item].name}
              showSidebar={props.showSidebar}
              updateSelectedSidebar={props.updateSelectedSidebar}
              selectedMenu={props.selectedMenu}
              selectedPrimaryMenu={props.selectedPrimaryMenu}
            />
            {dataBuildObj[item].collapseMenu
              ? Object.keys(dataBuildObj[item].collapseMenu).map(subItem => {
                  return (
                    <MenuCard
                      key={uuidv4()}
                      name={dataBuildObj[item].collapseMenu[subItem].name}
                      icon={dataBuildObj[item].collapseMenu[subItem].icon}
                      primaryMenu={dataBuildObj[item].name}
                      showSidebar={props.showSidebar}
                      updateSelectedSidebar={props.updateSelectedSidebar}
                      selectedMenu={props.selectedMenu}
                      selectedPrimaryMenu={props.selectedPrimaryMenu}
                      isCollapseSelected={true}
                    />
                  );
                })
              : null}
          </>
        );
      })}
    </GridContainer>
  );
};

// An equivalent to shouldComponentUpdate to control when to render the component
// Update Content component only when required
const isIntermediateState = (prevProps, nextProps) => {
  if (
    nextProps.labStatus != "ready" &&
    nextProps.labStatus != "error" &&
    nextProps.labStatus != "not provisioned" &&
    prevProps.selectedMenu + prevProps.currentTitle ==
      nextProps.selectedMenu + nextProps.currentTitle
  ) {
    return true;
  } else {
    return false;
  }
};

const Content = React.memo(props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    isCollapseSelected,
    dataBuildObj,
    selectedMenu,
    selectedPrimaryMenu,
    titleArr,
    currentTitle,
    provisionLabBtnHandler,
    handleLaunchDemo,
    isLabProvisioned,
    offeringType,
    enableExport
  } = props;

  const getTitleIndex = () => {
    return titleArr.findIndex(title => currentTitle === title);
  };

  // set the component used to render data based on primary or collapse menu selected.
  const SelectedContentComponent = !isCollapseSelected
    ? dataBuildObj[selectedMenu].component
    : dataBuildObj[selectedPrimaryMenu].collapseMenu[selectedMenu].component;

  // set data based on primary menu or collapse menu selected.
  const getData = key => {
    try {
      if (!isCollapseSelected) {
        return dataBuildObj[selectedMenu].data[getTitleIndex()][key];
      } else {
        return dataBuildObj[selectedPrimaryMenu].collapseMenu[selectedMenu]
          .data[getTitleIndex()][key];
      }
    } catch (err) {
      const payload = {
        message: "Something went wrong.",
        color: "danger",
        redirect: "/admin/learning-lab"
      };
      dispatch(SHOW_NOTIFICATION({ payload }));
      return "";
    }
  };

  return (
    <Card className={classes.cardStyle} elevation={0}>
      <CardBody>
        <GridContainer>
          <GridItem xs={12}>
            {/* render intro list */}
            <SelectedContentComponent
              className={classes.contentStyle}
              data={getData("intro_list")}
              labStatus={props.labStatus}
              ipAddress={props.ipAddress}
              hostName={props.host}
              labData={props.labData}
              reservedIp={props.reservedIp}
              provisionLabBtnHandler={provisionLabBtnHandler}
              handleLaunchDemo={handleLaunchDemo}
              isLabProvisioned={isLabProvisioned}
              offeringType={offeringType}
              selectedMenu={selectedMenu}
              currentTitle={currentTitle}
              enableExport={enableExport}
              topoSvg={props.topoSvg}
            />
          </GridItem>
          {getData("solution") ? (
            <GridItem xs={12}>
              {/* render solution */}
              <SelectedContentComponent
                className={classes.contentStyle}
                data={getData("solution")}
                labStatus={props.labStatus}
                ipAddress={props.ipAddress}
                hostName={props.host}
                provisionLabBtnHandler={provisionLabBtnHandler}
                handleLaunchDemo={handleLaunchDemo}
                isLabProvisioned={isLabProvisioned}
                offeringType={offeringType}
                selectedMenu={selectedMenu}
                currentTitle={currentTitle}
                enableExport={enableExport}
              />
            </GridItem>
          ) : null}
        </GridContainer>
      </CardBody>
    </Card>
  );
}, isIntermediateState);

const StatusCard = props => {
  const classes = useStyles();
  const { labStatus, timeLeft, previous_status } = props;
  const myRef = useRef(null);
  // let statusUpdateInterval = false; // Allow animation fadein fadeout

  let status = labStatus;
  const dispatch = useDispatch();
  

  const showErrorNotification = () => {
    const payload = {
      message: "Error in provisioning. Please terminate the cluster and provision it again.",
      color: "danger", // Optional: customize the delay as needed
    };
    dispatch(SHOW_NOTIFICATION({ payload }));
  };

  // Invoke showErrorNotification based on status
  useEffect(() => {
    if (status === "error") {
      showErrorNotification();
    }
  }, [status]); 
  // set fadein fadeout animation for lab status
  // switch (labStatus) {
  //   case "ready":
  // use intervalId from the state to clear the interval
  //   statusUpdateInterval = false;
  //   clearInterval(props.intervalId);
  //   break;
  // case "queued":
  // case "provisioning":
  // case "stabilizing":
  // case "spawning":
  // case "suspending":
  // case "resuming":
  //   statusUpdateInterval = true;
  //   break;
  // case "suspended":
  //   statusUpdateInterval = false;
  //   clearInterval(props.intervalId);
  //   break;
  // case "error":
  //   statusUpdateInterval = false;
  //   clearInterval(props.intervalId);
  //   break;
  // case "not provisioned":
  //   statusUpdateInterval = false;
  //   // clearInterval(intervalId);
  //   break;
  // default:
  //   break;
  // }
  // run this function from an event handler or an effect to execute scroll
  return (
    <>
      <div>
        {status === "suspending" ? (
          <ul className="progressbar">
            <li
              className={`${classes.textfont + " " + classes.pgress}  ${
                status === "ready"
                  ? "selected"
                  : [
                      status === "ready" || "suspending" || "suspended"
                        ? "active"
                        : "inactive"
                    ]
              }`}
            >
              Ready
            </li>
            <li
              className={`${classes.textfont + " " + classes.pgress}  ${
                status === "suspending"
                  ? "selected"
                  : [
                      status === "suspending" || "suspended"
                        ? "active"
                        : "inactive"
                    ]
              }`}
            >
              Suspending
            </li>
            <li
              className={`${classes.textfont + " " + classes.pgress}  ${
                status === "suspended"
                  ? "selected"
                  : [status === "suspended" ? "active" : "inactive"]
              }`}
            >
              Suspended
            </li>
          </ul>
        ) : (
          [
            status === "suspended" ||
            (status === "suspended" && previous_status === " ") ? (
              <ul className="progressbar">
                <li
                  className={`${classes.textfont + " " + classes.pgress}  ${
                    status === "ready"
                      ? "selected"
                      : [
                          status === "ready" || "suspending" || "suspended"
                            ? "active"
                            : "inactive"
                        ]
                  }`}
                >
                  Ready
                </li>
                <li
                  className={`${classes.textfont + " " + classes.pgress} ${
                    status === "suspending"
                      ? "selected"
                      : [
                          status === "suspending" || "suspended"
                            ? "active"
                            : "inactive"
                        ]
                  }`}
                >
                  Suspending
                </li>
                <li
                  className={`${classes.textfont + " " + classes.pgress}  ${
                    status === "suspended"
                      ? "selected"
                      : [status === "suspended" ? "active" : "inactive"]
                  }`}
                >
                  Suspended
                </li>
              </ul>
            ) : (
              [
                status === "resuming" ? (
                  <ul className="progressbar">
                    <li
                      className={`${classes.textfont + " " + classes.pgress}  ${
                        status === "suspended"
                          ? "selected"
                          : [
                              status === "suspended" || "resuming" || "ready"
                                ? "active"
                                : "inactive"
                            ]
                      }`}
                    >
                      Suspended
                    </li>
                    <li
                      className={`${classes.textfont + " " + classes.pgress}  ${
                        status === "resuming"
                          ? "selected"
                          : [
                              status === "resuming" || "ready"
                                ? "active"
                                : "inactive"
                            ]
                      }`}
                    >
                      Resuming
                    </li>
                    <li
                      className={`${classes.textfont + " " + classes.pgress}  ${
                        status === "ready"
                          ? "selected"
                          : [status === "ready" ? "active" : "inactive"]
                      }`}
                    >
                      Ready
                    </li>
                  </ul>
                ) : (
                  [
                    (status === "ready" && previous_status === " ") ||
                    (status === "ready" && previous_status === "resuming") ? (
                      <ul className="progressbar">
                        <li
                          className={`${classes.textfont +
                            " " +
                            classes.pgress}  ${
                            status === "suspended"
                              ? "selected"
                              : [
                                  status === "suspended" ||
                                  "resuming" ||
                                  "ready"
                                    ? "active"
                                    : "inactive"
                                ]
                          }`}
                        >
                          Suspended
                        </li>
                        <li
                          className={`${classes.textfont +
                            " " +
                            classes.pgress}  ${
                            status === "resuming"
                              ? "selected"
                              : [
                                  status === "resuming" || "ready"
                                    ? "active"
                                    : "inactive"
                                ]
                          }`}
                        >
                          Resuming
                        </li>
                        <li
                          className={`${classes.textfont +
                            " " +
                            classes.pgress}  ${
                            status === "ready"
                              ? "selected"
                              : [status === "ready" ? "active" : "inactive"]
                          }`}
                        >
                          Ready
                        </li>
                      </ul>
                    ) : (
                      [
                        status === "Not provisioned" ||
                        status === "error" ? null : (
                          <ul className="progressbar">
                            <li
                              className={`${classes.textfont +
                                " " +
                                classes.pgress}  ${
                                status === "queued"
                                  ? "selected"
                                  : [
                                      status === "queued" ||
                                      status === "spawning" ||
                                      status === "provisioning" ||
                                      status === "stabilizing" ||
                                      status === "ready"
                                        ? "active"
                                        : "inactive"
                                    ]
                              }`}
                            >
                              Queued
                            </li>
                            <li
                              className={`${classes.textfont +
                                " " +
                                classes.pgress}  ${
                                status === "spawning"
                                  ? "selected"
                                  : [
                                      status === "spawning" ||
                                      status === "provisioning" ||
                                      status === "stabilizing" ||
                                      status === "ready"
                                        ? "active"
                                        : "inactive"
                                    ]
                              }`}
                            >
                              Spawning
                            </li>
                            <li
                              className={`${classes.textfont +
                                " " +
                                classes.pgress}  ${
                                status === "provisioning"
                                  ? "selected"
                                  : [
                                      status === "provisioning" ||
                                      status === "stabilizing" ||
                                      status === "ready"
                                        ? "active"
                                        : "inactive"
                                    ]
                              }`}
                            >
                              Provisioning
                            </li>
                            <li
                              className={`${classes.textfont +
                                " " +
                                classes.pgress}  ${
                                status === "stabilizing"
                                  ? "selected"
                                  : [
                                      status === "stabilizing" ||
                                      status === "ready"
                                        ? "active"
                                        : "inactive"
                                    ]
                              }`}
                            >
                              Stabilizing
                            </li>
                            <li
                              className={`${classes.textfont +
                                " " +
                                classes.pgress}  ${
                                status === "ready"
                                  ? "selected"
                                  : [status === "ready" ? "active" : "inactive"]
                              }`}
                            >
                              Ready
                            </li>
                          </ul>
                        )
                      ]
                    )
                  ]
                )
              ]
            )
          ]
        )}

        {/* <GridItem xs={12} sm={2} md={2} className={classes.timerGridItem}>
              {labStatus != "ready" && labStatus != "error" ? (
                <span className={classes.labStatus}>
                  <AccessTimeIcon className={classes.labStatusIcon} />
                </span>
              ) : null}
            </GridItem>
            {statusUpdateInterval ? (
              <GridItem
                xs={12}
                sm={4}
                md={4}
                className={`${classes.intermediateStatus} ${
                  props.visible ? "fadeIn" : "fadeOut"
                }`}
              >
                {labStatus}
              </GridItem>
            ) : labStatus == "not provisioned" ||
              labStatus == "fetching. . ." ? (
              <GridItem
                xs={12}
                sm={4}
                md={4}
                className={classes.intermediateStatus}
              >
                {labStatus}
              </GridItem>
            ) : labStatus !== "ready" ? (
              <GridItem xs={12} sm={2} md={2} className={classes.labStatus}>
                {labStatus}
              </GridItem>
            ) : null}
            {labStatus === "ready" ? (
              <>
                <GridItem xs={12} sm={6} md={6}>
                  <GridContainer>
                    <GridItem xs={12} sm={2} md={2}>
                      <CloudReadyIcon className={classes.statusIcon} />{" "}
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3}>
                      <span
                        style={{
                          verticalAlign: "text-top",
                          textTransform: "capitalize",
                          color: PRIMARY_COLOR
                        }}
                      >
                        {labStatus}
                      </span>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </>
            ) : null}
            {/* <GridItem xs={12} sm={2} md={2} className={classes.labStatus}> */}
        {/* </GridItem> */}
        {/* <Icon classes="check-icon">
                    <img
                      className={classes.imageIcon}
                      alt="logo"
                      src={checkIcon}
                    />
                  </Icon> */}

        {/* <GridItem xs={12} sm={6} md={6} className="time" alignContent="center">
          <GridContainer justify="flex-end">
            <GridItem>
              <p className="time-left">
                Time Left : <b>{timeLeft}</b>
              </p>
              {userProfile?.add_hours ? (
                props.labStatus !== "not provisioned" &&
                ((userProfile.privilege == "developer" && userAccess) ||
                  (userProfile.privilege == "admin" && adminAccess)) ? (
                  <span className="add-hours" onClick={props.showAddHoursModal}>
                    <AddIcon className={classes.addHoursIcon} />
                    Add hours
                  </span>
                ) : null
              ) : null}
            </GridItem>
          </GridContainer>
        </GridItem> */}
      </div>
    </>
  );
};

const Header = props => {
  const classes = useStyles();
  const { titleClickHandler, currentTitle } = props;
  let [titleArr, setTitleArr] = useState(props.titleArr);
  useEffect(() => {
    setTitleArr(props.titleArr);
  }, [props.titleArr]);
  if (titleArr.length > 0) {
    return (
      <GridContainer
        alignItems="center"
        justify="flex-start"
        className={classes.headerStyle}
      >
        {titleArr.map(item => {
          return (
            <h4
              className={classes.exerciseTitle}
              key={uuidv4()}
              onClick={() => titleClickHandler(item, titleArr)}
              style={currentTitle === item ? selectedlabTypeStyle : {}}
            >
              {item}
            </h4>
          );
        })}
      </GridContainer>
    );
  } else {
    return <Danger> Oops! No content Found.</Danger>;
  }
};
class AccessLabLandingPage extends Component {
  constructor(props) {
    super(props);
    const params = QueryString.parse(this.props.location.search);
    if (
      !params.labId ||
      !params.uuid ||
      !params.labIdentifier ||
      !params.labEnabled ||
      !params.labName ||
      !params.offeringType
    ) {
      this.props.history.replace("/admin/dashboard");
    }

    this.stateParams = this.props.location.state
      ? this.props.location.state.stateParams
      : params
      ? params
      : null;

    this.labStatusLoop = null;
    this.state = {
      underlay_ubuntu_version: "22",
      loading: false,
      open:false,
      action:"",
      showSidebar: true,
      hideAddHoursModal: true,
      showResumeModal: true,
      selectedMenu: "", // species the name of menu. can be primary or secondary.
      selectedPrimaryMenu: "", // specifies the name of primary menu.
      isCollapseSelected: false, // specifies if selected menu is a collapsed menu.
      titleArr: [""], // titles available for a sub menu
      currentTitle: "", // selected exercise title
      dataBuildObj: {},
      visible: true,
      ipAddress: null,
      host: null,
      reservedIp: null,
      isLabLoaded: true,
      showNotification: false,
      notificationType: "",
      notificationMessage: "",
      intervalId: null,
      dataBuildArr: [],
      navigationArr: [],
      labStatus: "Not provisioned",
      previous_status: "no",
      timeLeft: "0 sec",
      runTime: "0 sec",
      runTimeMinutes: 0,
      remainingTimeMinutes: 0,
      totalRunTime: 0,
      userProfile: JSON.parse(localStorage.getItem("profileData")),
      admin_learnings_id: this.stateParams?.labId ?? null,
      labIdentifier: this.stateParams?.labIdentifier ?? null,
      labName: this.stateParams?.labName ?? "",
      labEnabled: this.stateParams?.labEnabled ?? false,
      uuid: this.stateParams?.uuid ?? null,
      offeringType: this.stateParams?.offeringType?.title
        ? this.stateParams.offeringType.title
        : params.offeringType,
      demoSummaryDetails: null,
      fetchIp: true,
      enableExport: false
    };
  }

  titleClickHandler = (title, titleArr = this.state.titleArr) => {
    this.setState({ currentTitle: title, titleArr }, () => {
      this.updateIndex();
    });
  };
  handleClickOpen=(val)=>{
    if(val == "suspend" || val == "terminate"){
    this.setState({action:val})
    this.setState({open:true})
    }else{
      this.setState({
        showResumeModal: false
      });
    }
  }
  handleClose=(val)=>{

    this.setState({open:false})
    if(this.state.action=='terminate'){
      this.terminateLab()
    }else if(this.state.action=='suspend'){
      this.suspendResumeLab()
    }

  }
  toggleSideBarMenu = () => {
    this.setState({
      showSidebar: !this.state.showSidebar
    });
  };

  hideAddHoursModal = () => {
    this.setState({
      hideAddHoursModal: true
    });
  };
  showAddHoursModal = () => {
    this.setState({
      hideAddHoursModal: false
    });
  };
  showResumeModal = () => {
    this.setState({
      showResumeModal: true
    });
  };
  hideResumeModal = () => {
    this.setState({
      showResumeModal: true
    });
  };

  // update current index of selected menu configuration.
  updateIndex = () => {
    const menuName = this.state.selectedMenu;
    const selectedPrimaryMenu = this.state.selectedPrimaryMenu;
    const title = this.state.currentTitle;
    let indexMatch = 0;
    this.state.navigationArr.forEach((item, index) => {
      if (
        item.menuName === menuName &&
        item.selectedPrimaryMenu === selectedPrimaryMenu &&
        item.title === title
      ) {
        indexMatch = index;
        this.setState({
          navigationIndex: indexMatch
        });
      }
    });
  };

  navigationBtnClickHandler = async btnType => {
    let navigationItem = undefined;
    if (btnType === "next") {
      // For scrolling exercise to the top
      document.getElementById("exerciseScroll").scrollIntoView();
      navigationItem = this.state.navigationArr[this.state.navigationIndex + 1];
    } else {
      document.getElementById("exerciseScroll").scrollIntoView();
      navigationItem = this.state.navigationArr[this.state.navigationIndex - 1];
    }

    if (!navigationItem) {
      return;
    }

    await this.updateSelectedSidebar(
      navigationItem.menuName,
      navigationItem.isCollapseSelected,
      navigationItem.selectedPrimaryMenu
    );

    await this.titleClickHandler(navigationItem.title);
  };

  buildNavigationArr = () => {
    // a list of object with index to object mapping
    // and object contains details about current selected config. take value from the
    // config and call updatetitle and updateselected menubar accordingly

    let navigationArr = [];
    let index = 0;
    try {
      let dataBuildSet = this.state.dataBuildArr;
      dataBuildSet = dataBuildSet.reduce((unique, item) => {
        if (!unique.some(obj => obj.name === item.name)) {
          unique.push(item);
        }
        return unique;
      }, []);
      dataBuildSet.forEach(item => {
        if (!item.collapseMenu) {
          const titleArr = item.data.flatMap(x => x.title);
          for (let i = 0; i < titleArr.length; i++) {
            let navigationItem = {};

            navigationItem.menuName = item.name;
            navigationItem.isCollapseSelected = false;
            navigationItem.selectedPrimaryMenu = item.name;
            navigationItem.title = titleArr[i];

            navigationArr.push(navigationItem);
            index++;
          }
        } else {
          // adding main menu which contains the submenu.
          // **(optimize later)** assuming this main menu which has a collapse menu cannot have multiple titles.
          let navigationItem = {};

          navigationItem.menuName = item.name;
          navigationItem.isCollapseSelected = false;
          navigationItem.selectedPrimaryMenu = item.name;
          navigationItem.title = item.data.flatMap(x => x.title)[0];

          navigationArr.push(navigationItem);
          index++;

          Object.keys(item.collapseMenu).forEach(submenuItem => {
            let collapseMenuArr = sortedTitleArr(
              item.collapseMenu[submenuItem].data
            );

            let collapseMenuTitleArr = collapseMenuArr.flatMap(x => x.title);
            // if there is no title then its navigation index wont be build since len(titlearr)=0.
            for (let i = 0; i < collapseMenuTitleArr.length; i++) {
              let navigationItem = {};

              navigationItem.menuName = item.collapseMenu[submenuItem].name;
              navigationItem.isCollapseSelected = true;
              navigationItem.selectedPrimaryMenu = item.name;
              navigationItem.title = collapseMenuTitleArr[i];

              navigationArr.push(navigationItem);
              index++;
            }
          });
        }
      });
    } catch (err) {
      const payload = {
        message: "some error occured,",
        color: "danger",
        redirect: "/admin/learning-lab"
      };
      this.props.dispatch(SHOW_NOTIFICATION({ payload }));
    }
    return new Promise(resolve => {
      this.setState(
        {
          navigationArr,
          navigationIndex: 0
        },
        () => {
          resolve("");
        }
      );
    }).catch(err => {
      // const payload = {
      //   message: err?.response?.data?.message || "unknown error occured",
      //   color: "danger",
      //   error: err
      // };
      // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
    });
  };

  updateSelectedSidebar = (
    menuName = this.state.selectedMenu,
    isCollapseSelected = false,
    selectedPrimaryMenu = this.state.selectedPrimaryMenu
  ) => {
    let titleArr;

    if (!isCollapseSelected) {
      // accessing title info from primary menu
      titleArr = sortedTitleArr(
        this.state.dataBuildObj[selectedPrimaryMenu].data
      );
      titleArr = titleArr.flatMap(x => x.title);
    } else {
      titleArr = sortedTitleArr(
        this.state.dataBuildObj[selectedPrimaryMenu].collapseMenu[menuName].data
      );
      // accessing title info from collapse menu
      titleArr = titleArr.flatMap(x => x.title);
    }

    return new Promise(resolve => {
      this.setState(
        {
          selectedMenu: menuName,
          isCollapseSelected,
          selectedPrimaryMenu,
          titleArr: titleArr,
          currentTitle: titleArr[0]
        },
        () => {
          this.updateIndex();
          resolve("");
        }
      );
    }).catch(err => {
      const payload = {
        message: "some error occured.",
        color: "danger",
        redirect: "admin/learning-lab"
      };
      this.props.dispatch(SHOW_NOTIFICATION({ payload }));
    });
  };

  updateProvisionLabStatus = async () => {
    let timeD = msToStr(
      moment(new Date()).format("YYYY-MM-DD HH:mm"),
      this.stateParams.expiryDate
    );
    if (this.state.uuid) {
      this.setState({
        labStatus: "fetching. . .",
        labStatusPercentage: 0,
        timeLeft: "fetching. . ."
      });
      this.fetchLabStatus();
      let labStatus = "";
      const response = await SDCloudBackendAPIWithToken()
        .get(Api.getLabStatus(this.state.uuid))
        .then(res => {
          labStatus = res.data.status;
        });
      if (
        !this.labStatusLoop &&
        (labStatus != "ready" &&
          labStatus != "error" &&
          labStatus != "not provisioned")
      ) {
        this.labStatusLoop = setInterval(() => {
          // Waiting after 60 seconds
          if (
            this.state.labStatus == "ready" ||
            this.state.labStatus == "error" ||
            this.state.labStatus == "Not provisioned"
          ) {
            clearInterval(this.labStatusLoop);
            this.labStatusLoop = null;
          }
          this.fetchLabStatus();
        }, 60000);
      }
      // if (!this.state.intervalId) {
      //   let intervalId = setInterval(() => {
      //     if (
      //       this.state.labStatus == "ready" ||
      //       this.state.labStatus == "error" ||
      //       this.state.labStatus == "not provisioned"
      //     ) {
      //       clearInterval(this.state.intervalId);
      //       this.setState({ intervalId: null });
      //     }
      //     this.setState({ visible: !this.state.visible });
      //   }, 2000);
      //   this.setState({ intervalId: intervalId });
      // }
      return this.fetchLabStatus();
    }
    // set time left for unprovisioned lab
    else {
      this.setState({
        labStatus: "Not provisioned",
        labStatusPercentage: 0,
        // timeLeft: "0 sec",
        timeLeft: timeD,
        demoSummaryDetails: null,
        dataBuildObj: {},
        dataBuildArr: [],
        navigationArr: []
      });
    }
    let timeDiff = msToStr(
      moment(new Date()).format("YYYY-MM-DD HH:mm"),
      this.stateParams.expiryDate
    );
    if (timeDiff > 0) {
      this.setState({
        timeLeft: msToStr(
          moment(new Date()).format("YYYY-MM-DD HH:mm"),
          this.stateParams.expiryDate
        )
      });
    }
  };

  
  export_configurations_labs = () => {
    this.setState({ loading: true });
    const template_name = this.stateParams?.labName ?? "";
    const template_uuid = this.stateParams?.uuid ??"";
    const action = "export_all_frozen";
    //console.log("template_uuid", template_uuid,
    // "action", action,
    // "template_name", template_name,)
    
    SDCloudBackendAPIWithToken()
      .post(Api.exportLearningSalesconfig, {
        
        "action": action,
        "template_uuid": template_uuid,
        "template_name": template_name,
      })
      .then(response => {
        // Log the variables and response data

        let zipData = response.data.download_info.content;
                let filename = response.data.download_info.output_file_name
                
                try{
                    const byteCharacters = atob(zipData);
                    const byteArrays = [];
  
                    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                        const slice = byteCharacters.slice(offset, offset + 512);
  
                        const byteNumbers = new Array(slice.length);
                        for (let i = 0; i < slice.length; i++) {
                            byteNumbers[i] = slice.charCodeAt(i);
                        }
  
                        const byteArray = new Uint8Array(byteNumbers);
                        byteArrays.push(byteArray);
                    }
                    const blob = new Blob(byteArrays, { type: 'application/zip' });
                    
                    // Create an anchor tag for our Blob
                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    const url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
                  catch (error) {
                    console.error('Error:', error);
                    this.setState({ loading: false });
                    
                  } finally {
                    // Hide the loader after processing the response
                    this.setState({ loading: false });
                  }
          
      })
      .catch(error => {
        console.error("API request failed:", error);
        this.setState({ loading: false });
      });
  };
                                                              

  terminateLab = () => {
    this.props.dispatch(SHOW_LOADER());
    if (this.state.uuid) {
      let data = { learning: this.state.labIdentifier, uuid: this.state.uuid };
      SDCloudBackendAPIWithToken()
        .post(Api.terminateLab, data)
        .then(res => {
          this.state.offeringType == "sales demo"
            ? this.props.history.replace("/experience-center-showcases", {
                currentTab: "Active"
              })
            : this.props.history.replace("/learning-lab", {
                currentTab: "Active"
              });
          if (res.data) {
            clearInterval(this.labStatusLoop);
            this.labStatusLoop = null;
            this.setState(
              {
                labEnabled: false,
                uuid: null,
                intervalId: null
              },
              () => {
                this.loadLab();
              }
            );
            this.props.dispatch(HIDE_LOADER());
          } else {
            const payload = {
              message: "Error in terminating.",
              color: "danger"
            };
            this.props.dispatch(HIDE_LOADER());
            this.setState({
              showNotification: true,
              notificationType: "error",
              notificationMessage: payload.message
            });
          }
        })
        .catch(err => {
          const payload = {
            message: err?.response?.data?.message || "unknown error occured",
            color: "danger",
            error: err
          };
          this.props.dispatch(HIDE_LOADER());
          this.setState({
            showNotification: true,
            notificationType: "error",
            notificationMessage: payload.message
          });
        });
    } else {
      this.props.dispatch(HIDE_LOADER());
      this.setState({
        showNotification: true,
        notificationType: "error",
        notificationMessage: "Lab does not have a valid uuid"
      });
    }
  };
// suspend or resume learnings
  suspendResumeLab = () => {
    this.props.dispatch(SHOW_LOADER());
    if (this.state.uuid) {
      let data = { learning: this.state.labIdentifier, uuid: this.state.uuid ,"action":"suspend"};
      SDCloudBackendAPIWithToken()
        .post(Api.modifyCluster, data)
        .then(res => {
          //console.log(res,"response")
          if (res.data) {
            clearInterval(this.labStatusLoop);
            this.labStatusLoop = null;
            this.setState(
              {
                labEnabled: false,
                uuid: null,
                intervalId: null
              },
              () => {
                this.loadLab();
              }
            );
            this.props.dispatch(HIDE_LOADER());
          } else {
            const payload = {
              message: "Error in suspending.",
              color: "danger"
            };
            this.props.dispatch(HIDE_LOADER());
            this.setState({
              showNotification: true,
              notificationType: "error",
              notificationMessage: payload.message
            });
          }
          window.location.href = window.location.href;
        })
        .catch(err => {
          const payload = {
            message: err?.response?.data?.message || "unknown error occured",
            color: "danger",
            error: err
          };
          this.props.dispatch(HIDE_LOADER());
          this.setState({
            showNotification: true,
            notificationType: "error",
            notificationMessage: payload.message
          });
        });
    } else {
      this.props.dispatch(HIDE_LOADER());
      this.setState({
        showNotification: true,
        notificationType: "error",
        notificationMessage: "Lab does not have a valid uuid"
      });
    }
  };
  // function that builds menu
  buildMenu = (
    name,
    icon = DescriptionIcon,
    data = "",
    component = Overview,
    collapseMenu = {}, // false if there is no collapse menu.
    position = 0
  ) => {
    return {
      name: name,
      icon: icon,
      data: data,
      component: component,
      collapseMenu: collapseMenu,
      position: position
    };
  };

  // restructuring the dataBuildArr to change it from index -> value to key(side menu)->value
  buildContentData = () => {
    return new Promise(resolve => {
      resolve(
        (() => {
          let dataBuildArr = [...this.state.dataBuildArr];
          let sortedByTabPosition = dataBuildArr.sort((a, b) => {
            return a.position - b.position;
          });
          const dataBuildObj = sortedByTabPosition.reduce((accum, item) => {
            accum[item.name] = item;
            return accum;
          }, {});
          this.setState({ dataBuildObj: { ...dataBuildObj } });
        })()
      );
    }).catch(err => {
      const payload = {
        message: "some error occured.",
        color: "danger",
        redirect: "admin/learning-lab"
      };
      this.props.dispatch(SHOW_NOTIFICATION({ payload }));
    });
  };
  
  fetchLabStatus = () => {
    let updatedIpdetails = null;
    return SDCloudBackendAPIWithToken()
      .get(Api.getLabDetail(this.state.uuid))
      .then(res => {
        return SDCloudBackendAPIWithToken()
          .get(Api.getLabStatus(this.state.uuid))
          .then(res => {
            return {
              labStatus: res.data.status,
              percentage: res.data.percentage,
              provisionTime: res.data.provision_time,
              createdDate: res.data.created_date,
              expiryDate: res.data.expiry_date,
              demoSummaryDetails: res.data,
              previous_status: res.data.previous_status,
              topoSvg: res.data.topo_svg,
              underlay_ubuntu_version: res.data.underlay_ubuntu_version
            };
          })
          .then(
            ({
              labStatus,
              percentage,
              provisionTime,
              createdDate,
              expiryDate,
              demoSummaryDetails,
              topoSvg,
              underlay_ubuntu_version
            }) => {
              if (labStatus === "error") {
                clearInterval(this.labStatusLoop);
              }
              // let labTimeLeft = msToStr(provisionTime, expiryDate);
              let labTimeLeft = msToStr(
                moment(new Date()).format("YYYY-MM-DD HH:mm"),
                expiryDate
              );
              let runTime = msToStr(
                createdDate,
                moment(new Date()).format("YYYY-MM-DD HH:mm")
              );
              let createdTime = new Date(
                createdDate?.replace(/ /g, "T").split(".")[0]
              );
              let endTime = new Date(
                expiryDate?.replace(/ /g, "T").split(".")[0]
              );
              let totalRunTime = endTime - createdTime;
              let remainingTime =
                endTime -
                new Date(
                  new Date()
                    .toISOString()
                    .replace(/ /g, "T")
                    .split(".")[0]
                );
              let runningTime = totalRunTime - remainingTime;
              let runTimeMinutes = runningTime / (1000 * 60).toFixed(2);
              totalRunTime = totalRunTime / (1000 * 60).toFixed(2);
              let remainingTimeMinutes = remainingTime / (1000 * 60).toFixed(2);

              if (labStatus == "ready" || labStatus == "error") {
                updatedIpdetails = res.data;
                this.setState({
                  host: Object.keys(updatedIpdetails.ipdetails)[0],
                  ipAddress: Object.values(updatedIpdetails.ipdetails)[0]
                });
                if (this.state.fetchIp) {
                  this.loadLab();
                }
                this.setState({ fetchIp: false });
              }
              this.setState({
                labStatus: labStatus,
                labStatusPercentage: percentage,
                timeLeft: labTimeLeft,
                demoSummaryDetails: demoSummaryDetails,
                runTime: runTime,
                runTimeMinutes: runTimeMinutes,
                remainingTimeMinutes: remainingTimeMinutes,
                totalRunTime: totalRunTime,
                topoSvg: topoSvg,
                underlay_ubuntu_version: underlay_ubuntu_version
              });
            }
          )
          .catch(err => {
            clearInterval(this.labStatusLoop);
            this.setState({
              labStatus: "error in fetching",
              labStatusPercentage: 0,
              timeLeft: "error",
              error: err
            });
            const payload = {
              message: err?.response?.data?.message || "lab provision failed.",
              color: "danger"
            };

            if (payload.message !== "lab provision failed.") {
              this.props.dispatch(SHOW_NOTIFICATION({ payload }));
            }
            this.setState(
              {
                labEnabled: false,
                uuid: null
              },
              () => this.loadLab()
            );
          });
      });
  };

  // fetch collapse menu names
  adminLearningsCourseLabsCategories = () => {
    if (this.state.offeringType === "sales demo") {
      const labIdtoNameMap = {
        1: "Demo Notes",
        2: "Demo Use Cases",
        3: "Demo Summary"
      };
      const collapseMenuObj = {};
      const promiseArr = [];
      promiseArr.push(this.adminSalesDemosCheatSheets(1));
      promiseArr.push(this.adminSalesDemosUseCases(2));
      promiseArr.push(this.adminDemoSummary(3));
      return Promise.all(promiseArr).then(resultArr => {
        resultArr.forEach(response => {
          // response contains result of each promise one by one.
          const collapseMenuItemObj = this.buildMenu(
            labIdtoNameMap[response.admin_learnings_course_labs_category_id],
            "",
            response.exerciseObjArr,
            Overview,
            false
          );
          // return from api consists of array from which we are picking course_lab_category_id.
          collapseMenuObj[
            labIdtoNameMap[response.admin_learnings_course_labs_category_id]
          ] = collapseMenuItemObj;
        });
        return collapseMenuObj;
      });
    }
    let adminLearningsCourseLabsCategories_url = `${Api.adminLearningsCourseLabsCategories}?admin_learnings_id=${this.state.admin_learnings_id}&limit=100`;
    return CMSAPIWithToken()
      .get(adminLearningsCourseLabsCategories_url)
      .then(res => {
        const sortedDataByCategoryPosition = res.data.results.sort(
          (a, b) =>
            parseInt(a.category_position) - parseInt(b.category_position)
        );
        const collapseMenu = sortedDataByCategoryPosition.reduce(
          (accum, item) => {
            if (
              String(item.admin_learnings_id) ===
              String(this.state.admin_learnings_id)
            ) {
              accum[item.lab_name] = item;
            }
            return accum;
          },
          {}
        );

        const labNameArr = Object.keys(collapseMenu);

        const collapseMenuObj = {};
        // eslint-disable-next-line no-unused-vars
        for (let menuKey of labNameArr) {
          collapseMenuObj[menuKey] = {};
        }
        const labIdtoIconMap = {};
        const labIdtoNameMap = labNameArr.reduce((accum, item) => {
          accum[collapseMenu[item].id] = collapseMenu[item].lab_name;
          labIdtoIconMap[collapseMenu[item].id] = collapseMenu[item].lab_icon;
          return accum;
        }, {});

        const promiseArr = [];
        // eslint-disable-next-line no-unused-vars
        for (let exerciseId in labIdtoNameMap) {
          promiseArr.push(this.adminLearningsCourseLabsExercises(exerciseId));
        }
        // optimise by making a single call and pass exerciseID array
        // and find all relevant data at once.
        return Promise.all(promiseArr).then(resultArr => {
          resultArr.forEach(response => {
            // response contains result of each promise one by one.
            const collapseMenuItemObj = this.buildMenu(
              labIdtoNameMap[response.admin_learnings_course_labs_category_id],
              labIdtoIconMap[response.admin_learnings_course_labs_category_id],
              response.exerciseObjArr,
              Overview,
              false
            );
            // return from api consists of array from which we are picking course_lab_category_id.
            collapseMenuObj[
              labIdtoNameMap[response.admin_learnings_course_labs_category_id]
            ] = collapseMenuItemObj;
          });
          return collapseMenuObj;
        });
      })
      .catch(err => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  //fetch demo exercise cheat sheets
  adminSalesDemosCheatSheets = admin_learnings_course_labs_category_id => {
    let adminSalesDemosCheatSheets_url = `${Api.admin_sales_demos_cheat_sheets}?admin_sales_demos_id=${this.state.admin_learnings_id}&limit=100`;
    return CMSAPIWithToken()
      .get(adminSalesDemosCheatSheets_url)
      .then(res => {
        const exerciseObjArr = res.data.results;
        //   .filter(item => {
        //   return (
        //     String(item.admin_learnings_course_labs_category_id) ===
        //     String(admin_learnings_course_labs_category_id)
        //   );
        // });

        return {
          exerciseObjArr: exerciseObjArr,
          admin_learnings_course_labs_category_id: admin_learnings_course_labs_category_id
        };
      })
      .catch(err => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  //fetch demo use case content
  adminSalesDemosUseCases = admin_learnings_course_labs_category_id => {
    let adminSalesDemosUseCases_url = `${Api.admin_sales_demos_use_cases}?admin_sales_demos_id=${this.state.admin_learnings_id}&limit=100`;
    return CMSAPIWithToken()
      .get(adminSalesDemosUseCases_url)
      .then(res => {
        const exerciseObjArr = res.data.results[0];
        //   .filter(item => {
        //   return (
        //     String(item.admin_learnings_course_labs_category_id) ===
        //     String(admin_learnings_course_labs_category_id)
        //   );
        // });

        return {
          exerciseObjArr: [
            {
              title: "Access use cases",
              intro_list: exerciseObjArr.content
            }
          ],
          admin_learnings_course_labs_category_id: admin_learnings_course_labs_category_id
        };
      })
      .catch(err => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  adminDemoSummary = admin_learnings_course_labs_category_id => {
    return {
      exerciseObjArr: [
        {
          title: "Demo Summary",
          intro_list: "",
          demoSummaryDetails: this.state.demoSummaryDetails
        }
      ],
      admin_learnings_course_labs_category_id: admin_learnings_course_labs_category_id
    };
  };
  fetchLabTime = () => {
    const lastVisitTime = moment(new Date()).utc().format("YYYY-MM-DD HH:mm:ss")
    const payload = {
      "uuid":this.state.uuid,
      "time":lastVisitTime,
    }
    return SDCloudBackendAPIWithToken()
    .post(Api.getWebPageVisit,payload,{
    headers: {
      "Content-type": "application/json",
      Authorization: `Token ${localStorage.getItem("user")}`
    }
  })
    .then(data => {
        
    })
    .catch(error => {
      
    })
  };
  //fetch exercise
  adminLearningsCourseLabsExercises = admin_learnings_course_labs_category_id => {
    let adminLearningsCourseLabsExercises_url = `${Api.adminLearningsCourseLabsExercises}?admin_learnings_id=${this.state.admin_learnings_id}&admin_learnings_course_labs_category_id=${admin_learnings_course_labs_category_id}`;
    return CMSAPIWithToken()
      .get(adminLearningsCourseLabsExercises_url)
      .then(res => {
        const exerciseObjArr = res.data.results;
        //   .filter(item => {
        //   return (
        //     String(item.admin_learnings_course_labs_category_id) ===
        //     String(admin_learnings_course_labs_category_id)
        //   );
        // });

        return {
          exerciseObjArr: exerciseObjArr,
          admin_learnings_course_labs_category_id: admin_learnings_course_labs_category_id
        };
      })
      .catch(err => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  //fetch data for overview
  adminLearningCourseOverView = () => {
    if (this.state.offeringType === "sales demo") {
      return;
    }
    let menuDict = {}; // contains all the info related to a menu content.
    let adminLearningsCourseOverviews_url = `${Api.adminLearningsCourseOverviews}?admin_learnings_id=${this.state.admin_learnings_id}`;
    return CMSAPIWithToken()
      .get(adminLearningsCourseOverviews_url)
      .then(res => {
        const { content } = res.data.results[0];
        // every data must be converted into array to have a uniform format same as exerise lab.
        let contentObjArr = [{ title: "Overview", intro_list: content }];
        menuDict = this.buildMenu(
          "Overview",
          VisibilityIcon,
          contentObjArr,
          Overview,
          false,
          0
        );
        const prevStateDataBuild = this.state.dataBuildArr;
        this.setState({
          dataBuildArr: prevStateDataBuild.concat(menuDict)
        });
      })
      .catch(err => {
        const payload = {
          message: err?.response?.data?.message || "unknown error occured",
          color: "danger",
          error: err
        };

        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  //fetch data for labs introduction
  adminLearningsCourseLabs = () => {
    let adminCourseLabApi = Api.adminLearningsCourseLabs;
    let adminLearningsCourseLabs_url = `${adminCourseLabApi}?admin_learnings_id=${this.state.admin_learnings_id}`;
    if (this.state.offeringType == "sales demo") {
      adminCourseLabApi = Api.admin_sales_demos_course_demos;
      adminLearningsCourseLabs_url = `${adminCourseLabApi}?admin_sales_demos_id=${this.state.admin_learnings_id}`;
    }
    return CMSAPIWithToken()
      .get(adminLearningsCourseLabs_url)
      .then(res => {
        let labsIntroduction = {};
        if (this.state.offeringType == "sales demo") {
          labsIntroduction = res.data.results.reduce((accum, item) => {
            if (
              String(item.admin_sales_demos_id) ===
              String(this.state.admin_learnings_id)
            ) {
              accum[item.admin_sales_demos_id] = {
                content: item.content,
                ipDetailsAccess: item.ip_details_access
              };
            }
            return accum;
          }, {});
        } else {
          labsIntroduction = res.data.results.reduce((accum, item) => {
            if (
              String(item.admin_learnings_id) ===
              String(this.state.admin_learnings_id)
            ) {
              accum[item.admin_learnings_id] = {
                content: item.content,
                ipDetailsAccess: item.ip_details_access
              };
            }
            return accum;
          }, {});
        }
        // every data must be converted into array to have a uniform format same as exerise lab.
        let contentObjArr = [
          {
            title:
              this.state.offeringType === "sales demo"
                ? "Demo Introduction"
                : "Lab Introduction",
            intro_list: labsIntroduction[this.state.admin_learnings_id].content
          }
        ];
        let menuDict = {};
        let prevStateDataBuild = {};
        let ipdetails =
          labsIntroduction[this.state.admin_learnings_id].ipDetailsAccess;
        if (!this.state.labEnabled) {
          // //console.log(
          //   "Access Device ===>",
          //   labsIntroduction[this.state.admin_learnings_id]
          // );
          prevStateDataBuild = this.state.dataBuildArr;
          menuDict = this.buildMenu(
            this.state.offeringType === "sales demo"
              ? "Demo Introduction"
              : "Lab Introduction",
            VisibilityIcon,
            contentObjArr,
            Overview,
            false,
            0
          );
          return new Promise(resolve => {
            this.setState(
              {
                dataBuildArr: prevStateDataBuild.concat(menuDict)
              },
              () => {
                // return access devices table data on promise resolve
                resolve(ipdetails);
              }
            );
          });
        } else {
          return this.adminLearningsCourseLabsCategories()
            .then(collapseMenuObj => {
              menuDict = this.buildMenu(
                this.state.offeringType === "sales demo"
                  ? "Demo Introduction"
                  : "Lab Introduction",
                VisibilityIcon,
                contentObjArr,
                Overview,
                collapseMenuObj,
                1
              );

              prevStateDataBuild = this.state.dataBuildArr;
            })
            .then(() => {
              return new Promise(resolve => {
                this.setState(
                  {
                    dataBuildArr: prevStateDataBuild.concat(menuDict)
                  },
                  () => {
                    // Check if ip exists and return access devices table data on promise resolve
                    resolve(
                      this.state.labStatus === "ready" ||
                        this.state.labStatus === "error"
                        ? ipdetails.replaceAll(
                            this.state.host?.split(/-(.+)/)[1],
                            this.state.ipAddress
                          )
                        : ipdetails
                    );
                  }
                );
              });
            });
        }
      })
      .catch(err => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  adminLearningsAccessDevices = accessDevicesTable => {
    let adminLabApi = Api.viewLearningLab(null);
    if (this.state.offeringType == "sales demo") {
      adminLabApi = Api.adminSalesDemo(null);
    }
    return CMSAPIWithToken()
      .get(`${adminLabApi}${this.state.admin_learnings_id}/`)
      .then(res => {
        if (res?.data?.enable_export) {
          this.setState({
            enableExport: true
          });
        }
        let accessDetailTopo = res.data.access_details;
        if (
          this.state.labStatus === "ready" ||
          this.state.labStatus === "error"
        ) {
          accessDetailTopo = accessDetailTopo.replaceAll(
            this.state.host?.split(/-(.+)/)[1],
            this.state.ipAddress
          );
        }
        let contentObjArr = [
          { title: "Topology", intro_list: accessDetailTopo }
        ];
        if (String(accessDevicesTable).trim() !== "") {
          contentObjArr.push({
            title: "Lab Details",
            intro_list: accessDevicesTable
          });
        }

        let menuDict = this.buildMenu(
          "Access Devices",
          VisibilityIcon,
          contentObjArr,
          AccessDevices,
          false,
          2
        );
        let prevStateDataBuild = this.state.dataBuildArr;
        this.setState({
          dataBuildArr: prevStateDataBuild.concat(menuDict)
        });
      })
      .catch(err => {
        // const payload = {
        //   message: err?.response?.data?.message || "Unknown error",
        //   color: "danger",
        //   error: err
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  // get reserved IP settings for user, account and offering specific
  getReservedIp = async () => {
    const isSalesDemo = this.state.offeringType === "sales demo";
    return SDCloudBackendAPIWithToken()
      .get(Api.getUserProfile)
      .then(res => {
        let reservedIpObj = {};
        if (res.data.static_ips?.length) {
          reservedIpObj["reservedIps"] = [...res.data.static_ips];
          reservedIpObj["ipService"] = true;
        }
        return reservedIpObj;
      })
      .then(reservedIpObj => {
        return SDCloudBackendAPIWithToken()
          .get(Api.getAccountDetails)
          .then(res => {
            if (res.data.reserved_ip) {
              reservedIpObj["staticIp"] = res.data.reserved_ip;
            }
            return reservedIpObj;
          });
      })
      .then(reservedIpObj => {
        if (
          reservedIpObj.ipService &&
          (reservedIpObj.reservedIps || reservedIpObj.staticIp)
        ) {
          reservedIpObj["dynamicIp"] = "default";
          this.setState(
            {
              reservedIp: reservedIpObj
            },
            () => {
              return;
            }
          );
        }
      })
      .catch(err => {
        // const payload = {
        //   message: err?.response?.data?.message || "unknown error occured",
        //   color: "danger",
        //   error: err,
        //   showErrorPage: true
        // };
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
  };

  // provision a learning lab
  provisionLabBtnHandler = async (
    labId = null,
    labIdentifier = null,
    labEnabled = null,
    labName = null,
    isReservedIp = null,
    ip = null,
    customerName = null,
    demoName = null,
    purpose = null,
    zone = null,
    testValue = null
  ) => {
    this.props.dispatch(SHOW_LOADER());

    // Reinitialize the relevant state variables before launching the lab
    this.setState({
      selectedMenu: "", // species the name of menu. can be primary or secondary.
      selectedPrimaryMenu: "", // specifies the name of primary menu.
      isCollapseSelected: false, // specifies if selected menu is a collapsed menu.
      titleArr: [""], // titles available for a sub menu
      currentTitle: "", // selected exercise title
      dataBuildObj: {},
      visible: true,
      ipAddress: null,
      host: null,
      reservedIp: null,
      isLabLoaded: true,
      showNotification: false,
      notificationType: "",
      notificationMessage: "",
      intervalId: null,
      dataBuildArr: [],
      navigationArr: [],
      labStatus: "not provisioned",
      timeLeft: "0 sec"
    });
    const data = {
      learning: this.state.labIdentifier
    };
    if (isReservedIp) {
      data["reserved_ip"] = isReservedIp;
    }
    if (ip) {
      data["ip_address"] = ip;
    }
    if (testValue) {
      data["mode"] = testValue;
    }
    let res_uuid = null;
    await SDCloudBackendAPIWithToken()
      .post(Api.provisionLearningLab, data)
      .then(res => {
        // this.props.dispatch(HIDE_LOADER());
        if (res.data.uuid) {
          res_uuid = res.data.uuid;
        } else {
          const payload = {
            message: "Error in provisioning.",
            color: "danger"
          };
          this.props.dispatch(SHOW_NOTIFICATION({ payload }));
        }
      })
      .catch(err => {
        this.props.dispatch(HIDE_LOADER());
        const payload = {
          message: err?.response?.data?.message || "unknown error occured",
          color: "danger",
          error: err
        };

        this.setState({
          showNotification: true,
          notificationType: "error",
          notificationMessage: payload.message
        });
        // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
      });
    if (res_uuid) {
      this.setState(
        {
          labEnabled: true,
          uuid: res_uuid
        },
        async () => {
          await this.loadLab();
          await this.setState({ isLabLoaded: true });
          await this.props.dispatch(HIDE_LOADER());
        }
      );
    }
  };

  handleLaunchDemo = () => {
    if (
      localStorage.getItem("BASE_URL") == null ||
      localStorage.getItem("BASE_URL_CMS") == null
    ) {
      const BASE_URL_CMS = window.REACT_APP_CMS_API_URL;
      const BASE_URL = window.REACT_APP_API_BASE_URL;
      localStorage.setItem("BASE_URL", BASE_URL);
      localStorage.setItem("BASE_URL_CMS", BASE_URL_CMS);
    }
    const uuid = this.state.uuid;
    const identifier = this.state.labIdentifier;
    const demoUrl = `/layout_generator/preview.html?identifier=${identifier}?uuid=${uuid}/`;
    window.open(demoUrl, "_blank");
  };

  componentWillUnmount = () => {
    clearInterval(this.labStatusLoop);
    // resetting navbar to show current route name
    this.props.dispatch(SET_NAVBAR_TITLE(""));
  };

  loadLab = async () => {
    const profileData = JSON.parse(localStorage.getItem("profileData"));
    const account = profileData.account;
    let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
    let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
    let hasDomain = domain?.some((res) => userEmail.includes(res));
    const isInternal =hasDomain
    try {
      this.state.offeringType == "sales demo"
        ? this.props.dispatch(SET_NAVBAR_TITLE(isInternal?account+"  - Experience Center - Showcases":"Experience Center - Showcases"))
        : this.props.dispatch(SET_NAVBAR_TITLE(isInternal?account+"  - Learning Center":"Learning Center"));
      await this.updateProvisionLabStatus();
      await this.adminLearningCourseOverView(); // load overview
      // capturing accessDeviceTable data to be used in adminLearningsAccessDevices.
      const accessDevicesTable = await this.adminLearningsCourseLabs(); // load labs introduction
      await this.adminLearningsAccessDevices(accessDevicesTable); // get access devices data
      await this.buildContentData(); // convert 'index to obj mapping' to 'name to obj mapping'.

      // **** assuming first menu is not a collapsable menu. ****
      await this.updateSelectedSidebar(
        Object.keys(this.state.dataBuildObj)[0],
        false,
        Object.keys(this.state.dataBuildObj)[0]
      );
      await this.buildNavigationArr();
    } catch (err) {
      this.props.dispatch(HIDE_LOADER());
      const payload = {
        message: "Error in loading labs.",
        color: "danger",
        error: err
      };
      // this.props.dispatch(SHOW_NOTIFICATION({ payload }));
    }
    // finally {
    //   this.props.dispatch(HIDE_LOADER());
    // }
  };

  handleWindowBeforeUnload = e => {
    e.preventDefault();
    let newParam = this.stateParams;
    newParam.uuid = this.state.uuid;
    newParam.labEnabled = this.state.labEnabled;
    let redirectRoute =
      this.state.offeringType == "sales demo"
        ? Urls.accessSalesDemo
        : Urls.accessLab;
    this.props.history.push({
      pathname: this.props.location.pathname,
      state: { stateParams: newParam }
    });
  };

  gobackShowcase() {
    this.props.history.replace("/experience-center-showcases", {
      currentTab: "Active"
    });
  }

  gobackLearn(labStatus) {
    if (["suspended","archived"].includes(labStatus)) {
      this.props.history.replace("/learning-lab", { currentTab: "Inactive" });
    } else {
      this.props.history.replace("/learning-lab", { currentTab: "Active" });
    }
  }
  componentDidMount = async () => {
    window.addEventListener("beforeunload", this.handleWindowBeforeUnload);
    // when the component is mount using history.push()
    this.getReservedIp();
    if (this.props.history?.action === "PUSH") {
      this.setState({ isLabLoaded: false });
    }

    this.props.dispatch(SHOW_LOADER());
    await this.loadLab();
    await this.props.dispatch(HIDE_LOADER());
    if (this.state.totalRunTime === "0 sec") {
      let runTime = msToStr(
        this.stateParams.createdDate,
        moment(new Date()).format("YYYY-MM-DD HH:mm")
      );
      let provisionTime = new Date(
        this.stateParams.createdDate?.replace(/ /g, "T").split(".")[0]
      );
      let endTime = new Date(
        this.stateParams.expiryDate?.replace(/ /g, "T").split(".")[0]
      );
      let totalRunTime = endTime - provisionTime;
      let remainingTime =
        endTime -
        new Date(
          new Date()
            .toISOString()
            .replace(/ /g, "T")
            .split(".")[0]
        );
      let runningTime = totalRunTime - remainingTime;
      let runTimeMinutes = runningTime / (1000 * 60).toFixed(2);
      totalRunTime = totalRunTime / (1000 * 60).toFixed(2);
      let remainingTimeMinutes = remainingTime / (1000 * 60).toFixed(2);
      this.setState({
        runTime: runTime,
        runTimeMinutes: runTimeMinutes,
        remainingTimeMinutes: remainingTimeMinutes,
        totalRunTime: totalRunTime
      });
    }

    if (this.props.history?.action === "PUSH") {
      this.setState({ isLabLoaded: true });
    }
    let statusUpdateInterval = setInterval(() => {
      this.fetchLabStatus(statusUpdateInterval);
    }, 15000);
    let LabVisitTime = setInterval(() => {
      this.fetchLabTime();
    }, 180000);
    return () => {
      clearInterval(statusUpdateInterval);
    };
  };

  render() {
    const { classes } = this.props;
    const { loading } = this.state;

    if (
      (Object.keys(this.state.dataBuildObj).length === 0 ||
        this.state.selectedMenu === "" ||
        this.state.selectedPrimaryMenu === "") &&
      this.state.isLabLoaded
    ) {
      return null;
    } else {
      return this.state.isLabLoaded ? (
        <GridContainer className="access-lab" id="exerciseScroll">
          <GridItem sm={12} md={12}>
            <Typography
              variant="body1"
              className={classes.backButton + " " + classes.font}
              onClick={() => {
                this.state.offeringType == "sales demo"
                  ? this.gobackShowcase()
                  : this.gobackLearn(this.state.labStatus);
              }}
            >
              <ArrowBackIcon /> Back
            </Typography>
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <div className={`lab-title ${classes.font}`}>
              {this.state.labName}
            </div>
            {/* <p className={`time-left time ${classes.font}`}>
              Time Left : <b>{this.state.timeLeft}</b>
            </p> */}
            <Tooltip
              className={classes.tooltip}
              arrow
              title={
                <React.Fragment>
                  <div>
                    <span className={classes.runningTimeTitle}>
                      Running Time:
                    </span>
                    <span className={classes.runningTime}>
                      {this.state.runTime}
                    </span>
                  </div>
                  <div className={classes.runTime}>
                    <span className={classes.runningTimeTitle}>
                      Remaining Time:
                    </span>
                    <span className={classes.runningTime}>
                      {this.state.timeLeft}
                    </span>
                  </div>
                </React.Fragment>
              }
            >
              <Box display="flex" alignItems="center" id={"progress-box"}>
                <Box
                  width="100%"
                  mr={1}
                  style={{ width: "80%" }}
                  id={"progress-box1"}
                >
                  <LinearProgress
                    variant="determinate"
                    className={classes.progressBar}
                    classes={{ barColorPrimary: classes.barColorPrimary }}
                    value={ NaN ? 100 :  Math.round(
                      (this.state.runTimeMinutes * 100) /
                        this.state.totalRunTime
                    )}
                  />
                </Box>
                <Box minWidth={35} id={"progress-box2"}>
                  <Typography variant="body2">
                    {`${Math.round(
                      (this.state.runTimeMinutes * 100) /
                        this.state.totalRunTime
                    )}%`}
                  </Typography>
                </Box>
              </Box>
            </Tooltip>
          </GridItem>
          <GridItem
            // className="time-status"
            xs={12}
            sm={8}
            md={8}
            lg={8}
            className={classes.clusterStatus}
          >
            {this.state.showResumeModal ? null : (
              <ResumeLearningsModel
                addHoursDetails={{
                  uuid: this.stateParams?.uuid ?? this.state.uuid,
                  labName: this.stateParams?.labName ?? this.state.labName,
                  labIdentifier: this.stateParams?.labIdentifier ?? this.state.labIdentifier,
                }}
                offeringType={this.stateParams?.offeringType?? this.state.offeringType}
                openModal={this.state.showResumeModal}
                hideModal={this.hideResumeModal}
              />
            )}
          </GridItem>
          <GridItem
            // className="time-status"
            xs={12}
            sm={8}
            md={8}
            lg={8}
            className={classes.clusterStatus}
          >
            {this.state.hideAddHoursModal ? null : (
              <AddHoursModal
                addHoursDetails={{
                  uuid: this.stateParams?.uuid ?? this.state.uuid,
                  labName: this.stateParams?.labName ?? "",
                  labIdentifier: this.stateParams?.labIdentifier ?? null,
                  addhoursLimit:
                    this.state?.userProfile?.add_hours?.hours_limit ?? null
                }}
                offeringType={this.stateParams?.offeringType?.title ?? null}
                openModal={this.state.hideAddHoursModal}
                hideModal={this.hideAddHoursModal}
              />
            )}
            <StatusCard
              labStatus={this.state.labStatus}
              previous_status={this.state.previous_status}
              timeLeft={this.state.timeLeft}
              addHoursDetails={this.state.userProfile}
              showAddHoursModal={this.showAddHoursModal}
              intervalId={this.state.intervalId}
              visible={this.state.visible}
            />
          </GridItem>
          <GridItem xs={12} md={12}>
            {this.state.showNotification ? (
              <Alert
                onClose={() => this.setState({ showNotification: false })}
                severity={this.state.notificationType}
              >
                {this.state.notificationMessage}
              </Alert>
            ) : null}
          </GridItem>
          {/* toggle side bar */}
          {this.state.showSidebar ? (
            <GridItem xs={12} sm={3} md={3} className="sidebar-main">
              <GridItem
                xs={12}
                sm={12}
                md={12}
                // className={classes.sidebarStyle}
              >
                <Sidebar
                  dataBuildObj={this.state.dataBuildObj}
                  toggleSideBarMenu={this.toggleSideBarMenu}
                  showSidebar={this.state.showSidebar}
                  updateSelectedSidebar={this.updateSelectedSidebar}
                  selectedMenu={this.state.selectedMenu}
                  selectedPrimaryMenu={this.state.selectedPrimaryMenu}
                  offeringType={this.state.offeringType}
                  containerRef={this.containerRef}
                />
              </GridItem>
              <GridItem xs={12} sm={8} md={8} style={{position:'relative',top:'-8rem'}}>
                {this.state.labStatus !== "not provisioned" ? (
                  <Button
                    variant="outlined"
                    color="secondary"
                    disableElevation
                    size="large"
                    className={classes.buttOutlined}
                    fullWidth
                    onClick={this.showAddHoursModal}
                    disableFocusRipple
                    disabled={
                      ["queued","spawning","provisioning", "stabilizing","resuming","suspending","error","archived","suspended"].includes(this.state.labStatus,)? true
                        : false
                    }
                  >
                    {/* <span
                        className="add-hours"
                        onClick={props.showAddHoursModal}
                      > */}
                    <AddIcon className={classes.addHoursIcon} />
                    Add hours
                    {/* </span> */}
                  </Button>
                ) : null}
                {this.state.offeringType.includes("Learning Lab") || this.state.offeringType.includes("learninglabs") || this.state.offeringType.includes("learning lab")?
                  <Button
                    variant="outlined"
                    color="secondary"
                    disableElevation
                    size="large"
                    className={classes.buttOutlined}
                    fullWidth
                    onClick={() => this.handleClickOpen(this.state.labStatus === "suspended" ? "resume" : "suspend")}
                    disableFocusRipple
                    disabled={
                      ["queued","spawning","provisioning", "stabilizing","resuming","suspending","error","archived"].includes(this.state.labStatus)? true
                        : false
                    }
                  >
                    {this.state.labStatus === "suspended" ? "Resume" : "Suspend"} 
                  </Button> :null}
                
              <div>
      <Dialog
        open={this.state.open && this.state.action == 'terminate'}
        onClose={()=>this.setState({open:false})}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography  className={classes.dialogtitle} variant="h1"> {`Are you sure! you want to terminate the Lab?`}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{
            color:'black',
            color: "rgba(0, 0, 0, 0.6)",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: 400,
            fontSize: "1rem",
            marginLeft:"60px"
            }}><WarningTwoTone style={{
              marginRight:"10px",
              position:"relative",
              top:9,
              color:"red",
              fontSize:"3rem"
              }}/>  
            Once you terminate you will loose your Lab.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{width:100,color:'#F96B13',border:'1px solid #F96B13'}} onClick={()=>this.setState({open:false})}>Cancel</Button>
          <Button style={{width:100,color:'#F96B13',border:'1px solid #F96B13'}} onClick={()=>this.handleClose(this.state.action)} autoFocus>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </div>
              </GridItem>
              <GridItem xs={12} sm={8} md={8} style={{position:'relative',top:'-8rem'}}>
                {this.state.labStatus === "error" ||
                this.state.labStatus === "ready" ||
                this.state.labStatus === "suspended" ||
                this.state.labStatus === "archived" ||
                (this.state.offeringType === "sales demo" &&
                  (this.state.labStatus === "error" ||
                    this.state.labStatus === "ready")) ? (
                  <Button
                    variant="outlined"
                    color="secondary"
                    disableElevation
                    size="large"
                    className={classes.buttOutlined}
                    fullWidth
                    onClick={() => this.handleClickOpen("terminate")}
                    disableFocusRipple
                    disabled={
                      this.state.labStatus === "archived"
                    }
                  >
                    Terminate
                  </Button>
                                    
                  
                ) : null}
                <div>
                </div>
                  </GridItem>
                  <GridItem xs={12} sm={8} md={8} style={{position:'relative',top:'-8rem'}}>
                  {(this.state.labStatus === "error" ||
                    this.state.labStatus === "ready" ||
                    this.state.labStatus === "suspended" ||
                    this.state.labStatus === "archived" ||
                    (this.state.offeringType === "sales demo" &&
                      (this.state.labStatus === "error" ||
                        this.state.labStatus === "ready"))) &&
                    this.state.underlay_ubuntu_version !== "14" ? (
                      <Button
                        variant="outlined"
                        color="secondary"
                        disableElevation
                        size="large"
                        className={this.props.classes.buttOutlined}
                        fullWidth
                        onClick={() => this.export_configurations_labs()}
                        disableFocusRipple
                        disabled={this.state.labStatus !== "ready" || this.state.loading}
                      >
                        {this.state.loading ? (
                          <CircularProgress size={24} color="secondary" /> // Show loading indicator
                        ) : (
                          'Export Configurations'
                        )}
                      </Button>
                    ) : null}
            <div>
      <Dialog
        open={this.state.open && this.state.action == 'suspend'}
        onClose={()=>this.setState({open:false})}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography  className={classes.dialogtitle} variant="h1"> {`Are you sure! you want to suspend the Lab?`}</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{
            color:'black',
            color: "rgba(0, 0, 0, 0.6)",
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: 400,
            fontSize: "1rem",
            marginLeft:'80px'
            }}><WarningTwoTone style={{
              marginRight:"10px",
              position:"relative",
              top:9,
              color:"red",
              fontSize:"3rem"
              }}/>  
            Cluster will suspend now.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{width:100,color:'#F96B13',border:'1px solid #F96B13'}} onClick={()=>this.setState({open:false})}>Cancel</Button>
          <Button style={{width:100,color:'#F96B13',border:'1px solid #F96B13'}} onClick={()=>this.handleClose(this.state.action)} autoFocus>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </div>
              </GridItem>
            </GridItem>
          ) : (
            <GridItem xs={3} sm={1} md={1} className={classes.minimizeMenu}>
              <Sidebar
                dataBuildObj={this.state.dataBuildObj}
                toggleSideBarMenu={this.toggleSideBarMenu}
                showSidebar={this.state.showSidebar}
                updateSelectedSidebar={this.updateSelectedSidebar}
                selectedMenu={this.state.selectedMenu}
                selectedPrimaryMenu={this.state.selectedPrimaryMenu}
                containerRef={this.containerRef}
              />
            </GridItem>
          )}
          <GridItem
            xs={12}
            sm={this.state.showSidebar ? 9 : 11}
            className={classes.minimizeExerciseCard}
          >
            <GridContainer className={classes.containerBodyStyle}>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                className={
                  !this.state.showSidebar ? classes.minimizeExerciseCard : null
                }
              >
                <Card className={classes.cardStyle} elevation={0}>
                  <CardBody>
                    <Header
                      titleArr={this.state.titleArr}
                      titleClickHandler={title =>
                        this.titleClickHandler(title, this.state.titleArr)
                      }
                      currentTitle={this.state.currentTitle}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem
                xs={12}
                className={
                  this.state.showSidebar
                    ? "card-padding"
                    : `card-padding ${classes.minimizeExerciseCard}
                `
                }
              >
                {this.state.offeringType === "sales demo" &&
                this.state.selectedMenu === "Demo Summary" ? (
                  <DemoSummary
                    demoSummaryDetails={this.state.demoSummaryDetails}
                    uuid={this.state.uuid}
                  />
                ) : (
                  <Content
                    selectedMenu={this.state.selectedMenu}
                    selectedPrimaryMenu={this.state.selectedPrimaryMenu}
                    dataBuildObj={this.state.dataBuildObj}
                    isCollapseSelected={this.state.isCollapseSelected}
                    titleArr={this.state.titleArr}
                    labStatus={this.state.labStatus}
                    labData={this.stateParams}
                    reservedIp={this.state.reservedIp}
                    ipAddress={this.state.ipAddress}
                    host={this.state.host}
                    currentTitle={this.state.currentTitle}
                    provisionLabBtnHandler={this.provisionLabBtnHandler}
                    handleLaunchDemo={this.handleLaunchDemo}
                    isLabProvisioned={this.state.uuid ? true : false}
                    offeringType={this.state.offeringType}
                    enableExport={this.state.enableExport}
                    topoSvg={this.state.topoSvg}
                  />
                )}
              </GridItem>
              <GridItem className="next-prev" xs={12}>
                <div>
                  <Tooltip
                    id="tooltip-top"
                    enterDelay={200}
                    leaveDelay={200}
                    TransitionComponent={Zoom}
                    title={
                      this.state.navigationArr[this.state.navigationIndex - 1]
                        ? this.state.navigationArr[
                            this.state.navigationIndex - 1
                          ].title
                        : ""
                    }
                    placement="bottom-start"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.btnStyle + " " + classes.buttStyle}
                      style={{
                        display: this.state.navigationIndex === 0 ? "none" : ""
                      }}
                      disableElevation
                      size="medium"
                      fullWidth
                      onClick={() => this.navigationBtnClickHandler("prev")}
                      disableFocusRipple
                    >
                      Prev
                    </Button>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip
                    id="tooltip-top"
                    enterDelay={200}
                    leaveDelay={200}
                    TransitionComponent={Zoom}
                    title={
                      this.state.navigationArr[this.state.navigationIndex + 1]
                        ? this.state.navigationArr[
                            this.state.navigationIndex + 1
                          ].title
                        : ""
                    }
                    placement="bottom-start"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{
                        display:
                          this.state.navigationIndex ===
                          this.state.navigationArr.length - 1
                            ? "none"
                            : ""
                      }}
                      disableElevation
                      className={classes.buttStyle}
                      size="medium"
                      fullWidth
                      onClick={() => this.navigationBtnClickHandler("next")}
                      disableFocusRipple
                    >
                      Next
                    </Button>
                  </Tooltip>
                </div>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      ) : (
        <div>
          <GridContainer justify="center">
            <Loader
              type={LOADER_TYPE}
              color={theme.palette.secondary.main}
              width={50}
              height={50}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)"
              }}
            />
          </GridContainer>
        </div>
      );
    }
  }
}

AccessLabLandingPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect()(withStyles(customStyle)(AccessLabLandingPage));
