import React, { useEffect, useState } from "react";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
//react 
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// Formik
import { useFormik } from "formik";
// Material UI Components
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {hotjar} from "react-hotjar";
import GridItem from "components/Grid/GridItem";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
//markdown editor
import Editor from "react-markdown-editor-lite";
import ReactMarkdown from "react-markdown";
import MarkdownIt from 'markdown-it';
//markdown editor import style manually
import 'react-markdown-editor-lite/lib/index.css';
//API Call
import { CMSAPIWithToken } from "../../../apis/CMS";
//checkbox
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';



const useStyles = makeStyles((theme) => ({
  
  gridContainer: {
    padding: "0 40px 30px 40px",
  },
  radioButton: {
    "& .MuiTypography-root": {
      marginLeft: "10px",
    },
  },
  formControl: {
    width: "100%",
    marginTop: "5px",
  },
  Select: {
    marginBottom: "50px",
  },
  warningNote: {
    "& .MuiAlert-icon": {
      display: "none",
    },
  },
  button: {
    marginTop: "50px",
  },
  buttOutlined: {
    color: theme.palette.buttonColor.main,
    borderColor: theme.palette.buttonColor.main,
    "&:hover": {
      border: `1px solid ${theme.palette.buttonColor.main}`,
    },
  },
}));

//sandbox view main
const CheatSheetsView = (props) => {
  const classes = useStyles();
  hotjar.initialize(2281731, 6);
  hotjar.identify(localStorage.userName, { userProperty: localStorage.userName});
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const mdEditor = React.useRef(null);
  const [notificationType, setNotificationType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [introList, setIntroList] = useState("");
  const [solution, setSolution] = useState("");
  const dispatch = useDispatch();
    const history = useHistory();
    const inputProps = {
        disableAnimation: true,
        shrink: false
      };  

      const ITEM_HEIGHT = 48;
      const ITEM_PADDING_TOP = 8;
      const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
          },
        },
      };
      
      const names = [
        'Criterion Enterprise',
        'criterion networks DEV',
        'TataCommunication',
        'LiveAction',
        'verizonlearning-dev',
        'verizonlearning',
        'LiveActiondev',
        'CiscoWorkshops',
        'TataCommunicationDev',
        'CN',
        'ciscotraining',
      
      ];
      const multipleselectvalue=["1","2","3","4","5","6","7","8","9","10","11"];
    
      const [account, setAccount] = React.useState([]);
    
      const handleMultipleChange = (event) => {
        setAccount(event.target.value);
      };    

  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
        admin_sales_demos_id: null,
        title: "",
        intro_list:"",
        solution:"",
        exercise_position:"",
              },
    onSubmit: (values) => {
      let payload = {
        admin_sales_demos_id: values.admin_sales_demos_id,
        title: values.title,
        intro_list: introList,
        solution:solution,
        exercise_position:values.exercise_position,
      };
    },
    validate: (values) => {
      let errors = {};
      if (!values.admin_sales_demos_id) {
        errors.admin_sales_demos_id = "A valid integer is required.";
      }
      if (!values.title) {
        errors.title = "Required.";
      }
      if (!values.exercise_position) {
        errors.exercise_position = "Required.";
      }
      return errors;
    },
  });

    //mark down editor
    const handleEditorChange = ({ html, text }) => {
      const newValue = text.replace(/\d/g, "");
      //console.log(newValue);
      setIntroList(newValue);
    };
    const handleSolutionChange = ({ html, text }) => {
      const newValue = text.replace(/\d/g, "");
      //console.log(newValue);
      setSolution(newValue);
    };
      // Initialize a markdown parser
      const mdParser = new MarkdownIt(/* Markdown-it options */);

  let data = [];

  const renderSanboxView = async () => {
    let sanboxView = CMSAPIWithToken()
      .get("/sales/admin_sales_demos_cheat_sheets/")
      .then((resp) => resp.data.results)
      .then((resp) => {
        resp.forEach((item) => {
          if(props.user == item.id) { 
          formik.setFieldValue("admin_sales_demos_id", item?.admin_sales_demos_id);
          formik.setFieldValue("title", item?.title);
          setIntroList(item.intro_list);
          setSolution(item.solution);
          formik.setFieldValue("exercise_position", item?.exercise_position);
          formik.setFieldValue("created", item?.created);
          formik.setFieldValue("modified", item?.modified);
        }
        });
      })      
      .catch((err) => {
        let message = err.response.data.message;
        // setRows([]);
        // setIsLoading(false);
        setShowNotification(true);
        setNotificationType("error");
        setNotificationMessage("Failed to retrieve the data");
      });

      

      //setUserDetails({ ...formik.userDetails, ...data });
  };

  useEffect(() => {
    renderSanboxView();
  }, []);

  //checkbox
  
    const [state, setState] = React.useState({
      checkedA: true,
      checkedB: true,
      checkedF: true,
      checkedG: true,
    });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div>
        <form
            onSubmit={formik.handleSubmit}
            className={classes.formContainer}
          >
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.gridContainer}
      >
        <Grid item xs={12} md={5}>
          <Typography variant="h4">CheatSheets Update</Typography>
          
        </Grid>

        <GridItem md={12}>
                  {formik.errors.admin_sales_demos_id && formik.touched.admin_sales_demos_id ? (
                    <TextField
                      type="number"
                      variant="filled"
                      label="Showcase Name"
                      name="admin_sales_demos_id"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.admin_sales_demos_id}
                      onChange={formik.handleChange}
                      helperText={formik.errors.admin_sales_demos_id}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="Number"
                      variant="filled"
                      label="Showcase Name"
                      name="admin_sales_demos_id"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.admin_sales_demos_id}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>

                <GridItem md={12}>
                  {formik.errors.title && formik.touched.title ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Title"
                      name="title"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.title}
                      onChange={formik.handleChange}
                      helperText={formik.errors.title}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Title"
                      name="title"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.title}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>
                

                <GridItem md={12}>
                    <p>Intro List</p>
                        <Editor
                          ref={mdEditor}
                          value={introList}
                          style={{
                            height: "500px"
                          }}
                          onChange={handleEditorChange}
                          renderHTML={text => <ReactMarkdown source={text} />}
                        />
                </GridItem>

                <GridItem md={12}>
                    <p>Solution</p>
                    <Editor
                          ref={mdEditor}
                          value={solution}
                          style={{
                            height: "500px"
                          }}
                          onChange={handleSolutionChange}
                          renderHTML={text => <ReactMarkdown source={text} />}
                        />
                </GridItem>

                <GridItem md={12}>
                  {formik.errors.exercise_position && formik.touched.exercise_position ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Exercise Position"
                      name="exercise_position"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.exercise_position}
                      onChange={formik.handleChange}
                      helperText={formik.errors.exercise_position}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Exercise Position"
                      name="exercise_position"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.exercise_position}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>

                <GridItem md={9}>
                    <h6>Created : {formik.values.created}</h6>
                    <h6>Modified : {formik.values.modified}</h6>
                </GridItem>



      </Grid>
      </form>       
    </div>
  );
};

export default CheatSheetsView;
