import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import "./LearnMore.scss";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Part1 from "./part1";
import Part2 from "./part2";

const customStyles = () => ({
  root: {
    padding: "0 63px 18px 27px",
    color: "black",
    fontSize: "17px",
    fontWeight: "bolder",
    lineHeight: "23px"
  },
  paper: {
    marginTop: "10px",
    background: "#FFFFFF",
    border: "none",
    boxShadow: "none",
    borderRadius: 0,
    padding: "27px"
  },
  divTable: {
    display: "table-cell",
    backgroundColor: "white",
    padding: "25px !important",
    borderLeft: "15px solid #f6f6f6",
    borderRight: "15px solid #f6f6f6",
    marginTop: "20px"
  },
  header: {
    color: "orange"
  }
});

const LearnMore = props => {
  const useStyles = makeStyles(customStyles);
  const classes = useStyles();
  useEffect(() => {
    if (props.history.location.hash) {
      let elmnt = document.getElementById(
        props.history.location.hash.slice(1, props.history.location.hash.length)
      );
      if (elmnt) elmnt.scrollIntoView(true);
    }
  });
  return (
    <div className={classes.root}>
      <div className="container">
        <GridContainer>
          <GridItem className={classes.divTable} sm={12} md={6}>
            <Part1 />
          </GridItem>
          <GridItem className={classes.divTable} sm={12} md={6}>
            <Part2 />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
};

export default LearnMore;
