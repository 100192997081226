import React from "react";
import { Link } from "react-router-dom";
import "../Dashboard/Dashboard.scss";
import { getRouteByName } from "../../utils/utilfunction";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
    font: {
        fontFamily: theme.palette.fontList.selectedFont
    }
}))

const HelpSideBarLinks = props => {
    const classes = useStyles();
    const { toggleDrawer } = props;
    const link = getRouteByName("Learn more");
    return (
        <div className="helpsidebarlinks">
            <Link
                target="_blank"
                to={{ pathname: "https://docs.google.com/document/u/1/d/e/2PACX-1vQF9gMbdbqHIwrkRa8agoMJjrqHOB4TNjOKp-cNCaw469MOL4ZI-mYgvZe7jM4Cf9gEwiyf36c09RoE/pub?urp=gmail_link"}}
                style={{color:'#000000'}}
                // onClick={() => toggleDrawer()}
                className={`sidebarLinks ${classes.font}`}
            >
                Getting Started
            </Link>
            <br />
            <br />
            <Link
                // to={`${link}#faqs`}
                // onClick={() => toggleDrawer()}
                className={`sidebarLinks ${classes.font}`}
            >
                FAQ's
            </Link>
        </div>
    );
};

export default HelpSideBarLinks;
