// Table warapper for uploaded images.

import React, { Component } from "react";
import Button from "components/CustomButtons/Button.js";
import Close from "@material-ui/icons/Close";
import { Animated } from "react-animated-css";

import SweetAlert from "react-bootstrap-sweetalert";
import ReactTable from "react-table";
import NodeListTable from "../dashboard/components/NodeTable";
import Snackbar from "components/Snackbar/Snackbar.js";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";
import {
  buildAxiosParams,
  NODE_URLS
} from "views/ServiceLifeCycle/utils/constants/urls";

import axios from "axios";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import { Api } from "config/api";

import classes from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { PRIMARY_COLOR } from "global_constants/app_constants";
import theme from "../../../theme";
// const BUCKET_NAME = "django-drf-filepond";
// const USER_NAME = "default_user";
// const classes = useStyles();

const COLS = ["name"];
const TABLE_HEADERS = [
  {
    Header: "#",
    accessor: "id",
    sortable: false,
    filterable: false
  },
  {
    Header: "Name",
    accessor: "image"
  },
  {
    Header: "Version",
    accessor: "version"
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false
  }
];

export default class TableWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataTable: [],
      isTableLoaded: true,
      selectedImageToDelete: null,
      showDeleteAlert: false,
      showNotification: false,
      notificationMessage: "",
      notificationColor: "success"
    };
  }

  build2DArray = data => {
    return data.reduce((accum, val, index) => {
      let _1Darr = [];
      _1Darr[0] = val.image;
      _1Darr[1] = val.version;
      accum.push(_1Darr);
      return accum;
    }, []);
  };

  handleDeleteImage = (obj, key) => {
    let imageObj = this.state.dataTable.find(_each => {
      return _each.id === key + 1 ? true : false;
    });
    if (imageObj) {
      this.setState({
        showDeleteAlert: true,
        selectedImageToDelete: imageObj
      });
    }
  };

  handleConfirmDeleteImage = () => {
    this.deleteImageApi(this.state.selectedImageToDelete);
    this.setState({ showDeleteAlert: false });
  };

  hideAlert = () => {
    this.setState({
      showDeleteAlert: false,
      selectedImageToDelete: null
    });
  };

  callNotification = (notificationColor, notificationMessage) => {
    this.setState({
      notificationColor,
      notificationMessage,
      showNotification: true
    });
    // setTimeout(() => {
    //   this.setState({
    //     showNotification: false,
    //     notificationMessage: ""
    //   });
    // }, 3500);
  };

  deleteImageApi = imageObj => {
    this.setState({ isTableLoaded: false });
    let data = {
      image_name: imageObj.image,
      version: imageObj.version,
      permanent_image_del: true,
      bname: NODE_URLS.BUCKET_NAME
    };
    SDCloudBackendAPIWithToken()
      .post(Api.deleteImage, data)
      .then(res => {
        if (res.status === 200) {
          this.setState({
            showDeleteAlert: false
          });
          this.callNotification(
            "success",
            `${res.data.image} deleted successfully.`
          );
          this.fetchTableData();
        }
      })
      .catch(err => {
        this.callNotification("danger", `${err.response.data.message}`);
        this.setState({ showDeleteAlert: false });
        this.fetchTableData();
      });
  };

  buildTable = data => {
    const tableFormat = {
      headerRow: COLS,
      footerRow: COLS,
      dataRows: data.apiData
    };
    return tableFormat.dataRows.map((prop, key) => {
      return {
        id: key + 1,
        image: prop[0],
        version: prop[1],
        actions: (
          // use this button to remove the data row
          <div>
            <Button
              justIcon
              round
              simple
              onClick={() => {
                this.handleDeleteImage(prop, key);
              }}
              color="danger"
              className="remove"
              style={closeBtnStyle}
            >
              <Close />
            </Button>{" "}
          </div>
        )
      };
    });
  };

  fetchTableData = () => {
    this.setState({ isTableLoaded: false });
    SDCloudBackendAPIWithToken()
      .post(Api.listImages)
      .then(res => {
        // //console.log(res.data);
        const finaltable = this.buildTable({
          apiData: this.build2DArray(res.data["images"])
        });
        this.setState({ dataTable: finaltable, isTableLoaded: true });
      })
      .catch(err => {
        // //console.log("error in getting image list ", err);
        // //console.log(err);
        this.setState({ isTableLoaded: true });
      });
  };

  componentDidMount() {
    this.fetchTableData();
  }
  render() {
    if (this.state.isTableLoaded) {
      return (
        <>
          <GridContainer justify="center" style={{ padding: "2%" }}>
            <GridItem xs={12} sm={12}>
              <Animated
                animationIn="fadeIn"
                animationOut="fadeOut"
                animationInDuration={1000}
                animationOutDuration={1000}
                isVisible={true}
              >
                <NodeListTable
                  data={this.state.dataTable}
                  // filterable
                  columns={TABLE_HEADERS}
                  // defaultPageSize={5}
                  // showPaginationTop
                  // showPaginationBottom={false}
                  // className="-striped -highlight"
                  // tableHeaderColor="warning"
                  tableTitle="Elements"
                  buttonTitle="Show details"
                />
              </Animated>
              {this.state.showDeleteAlert ? (
                <SweetAlert
                  warning
                  style={{ display: "block", marginTop: "-100px" }}
                  title="Confirm Delete Image?"
                  onConfirm={() => this.handleConfirmDeleteImage()}
                  onCancel={() => this.hideAlert()}
                  confirmBtnStyle={{ ...classes.button, ...classes.info }}
                  cancelBtnStyle={{ ...classes.button }}
                  showCancel
                >
                  <strong> {this.state.selectedImageToDelete.image} </strong>{" "}
                  will be deleted permanently.
                </SweetAlert>
              ) : null}
              <Snackbar
                place="tr"
                color={this.state.notificationColor}
                //   icon={AddAlert}
                message={this.state.notificationMessage}
                open={this.state.showNotification}
                closeNotification={() =>
                  this.setState({ showNotification: false })
                }
                close
              />
            </GridItem>
          </GridContainer>
        </>
      );
    } else {
      return (
        // <Loader />
        <GridContainer justify="center">
          <Loader
            type="ThreeDots"
            color={theme.palette.secondary.main}
            height={50}
            width={50}
            visible={true}
          />
        </GridContainer>
      );
    }
  }
}

const closeBtnStyle = {
  float: "right"
};
