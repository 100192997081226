import React, { createContext, useContext,useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// Local
import Menu from "../../Components/Menu";
import { SDCloudBackendAPIWithToken } from "../../../apis/backendAPI";
import { CMSAPIWithToken } from "../../../apis/CMS";
import { useDispatch } from "react-redux";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import queryString from "query-string";
import SandboxView from "./SandboxView";
import SandboxUpdate from "./SandboxUpdate";
import SandboxOptionsView from "./SandboxOptionsView";
import SandboxOptionsUpdate from "./SandboxOptionsUpdate";
import SandboxDetailsUpdate from "./SandboxDetailsUpdate";
import SandboxDetailsView from "./SandboxDetailsView";
import SandboxesCategoriesUpdate from "./SandboxesCategoriesUpdate";
import SandboxesCategoriesView from "./SandboxesCategoriesView";
import SandboxesCheatSheetsUpdate from "./SandboxesCheatSheetsUpdate";
import SandboxesCheatSheetsView from "./SandboxesCheatSheetsView";
import SandboxesExercisesUpdate from "./SandboxesExercisesUpdate";
import SandboxesExercisesView from "./SandboxesExercisesView";
import SandboxesLabsUpdate from "./SandboxesLabsUpdate";
import SandboxesLabsView from "./SandboxesLabsView";
// Material UI Components
import { Alert } from "@material-ui/lab";
// Icons
import Visibility from "@material-ui/icons/Visibility";
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// Local
import CustomTable from "../Components/Table";
import RightDrawer from "../Components/RightDrawer";
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import { IconButton } from "@material-ui/core";
//import Visibility from "@material-ui/icons/Visibility";
import ViewInventory from "../../InventoryManagement/components/ViewInventory"
import AddInventory from "../../InventoryManagement/components/AddInventory"
import {hotjar} from "react-hotjar";
import ForwardIcon from '@material-ui/icons/Forward';

// Styling
const useStyles = makeStyles(theme => ({
    root: {
        marginTop: "50px"
    },
    backButton: {
      margin: "15px 0",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "& svg": {
        marginRight: "5px",
      },
      "&:hover": {
        color: theme.palette.secondary.main,
        "& svg": {
          fill: theme.palette.secondary.main,
        },
      },
    },
}));

const ProductContentSandboxesMenuItems = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const profileData = JSON.parse(localStorage.getItem("profileData"));
    const account = profileData.account;
    let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
    let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
    let hasDomain = domain?.some((res) => userEmail.includes(res));
    const [isInternal, setIsInternal] = useState(hasDomain);
    useEffect(() => {
      dispatch(SET_NAVBAR_TITLE(isInternal?account+"  - Content Management Sandboxes":"Content Management Sandboxes"));
        renderoption();
        renderdetails();
        rendercheatsheets();
        renderlabs();
        rendercategories();
        renderexercises();
    }, []);
    hotjar.initialize(2281731, 6);
    hotjar.identify(localStorage.userName, { userProperty: localStorage.userName});
    // States
    
    const [optionRows, setOptionRows] = useState([]);
    const [detailsRows, setDetailsRows] = useState([]);
    const [cheatsheetsRows, setCheatsheetsRows] = useState([]);
    const [labsRows, setLabsRows] = useState([]);
    const [categoriesRows, setCategoriesRows] = useState([]);
    const [categoriesValue, setCategoriesValue] = useState([]);
    const [catData, setCatData] = useState([]);
    const [exercisesRows, setExercisesRows] = useState([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [currentInventory, setCurrentInventory] = useState("");
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState([]);
    const [currentScreen, setCurrentScreen] = useState("Sandbox Options");
    const [currentDrawerScreen, setcurrentDrawerScreen] = useState();
    let queryParams = queryString.parse(window.location.search);
    var sb_id = queryParams.id;
    var sandbox_id_title_map = JSON.parse(localStorage.getItem("sandbox_id_title_map"))
    var sandbox_title = sandbox_id_title_map[sb_id]
    var sandbox_cat_id_map = {};
    const [currentUser, setCurrentUser] = useState();
    
    const [notificationType, setNotificationType] = useState("");
    

    const optioncolumns = [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Label",
        accessor: "label",
      },
      {
        Header: "Value",
        accessor: "value",
      },
      {
        Header: "Identifier",
        accessor: "identifier",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ];
    const detailscolumns = [ 
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Modified",
        accessor: "modified",
      },
      {
        Header: "Created",
        accessor: "created",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ];
    const cheatsheetscolumns = [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Title",
        accessor: "title",
      },
      // {
      //   Header: "Intro List",
      //   accessor: "intro_list",
      // },
      {
        Header: "Created",
        accessor: "created",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ];
    const labscolumns = [
      {
        Header: "Content",
        accessor: "content",
      },
      {
        Header: "Created",
        accessor: "created",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ];
    
    const categoriescolumns = [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Sandboxes Category Name",
        accessor: "sandboxes_category_name",
      },
      {
        Header: "Identifier",
        accessor: "identifier",
      },
      {
        Header: "Created",
        accessor: "created",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ];

    const exercisescolumns = [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Category Name",
        accessor: "admin_sandboxes_category_name",
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Created",
        accessor: "created",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ];
    
    let optionData = [];let detailsData = [];let cheatsheetsData = [];let labsData = [];let categoriesData = [];let exercisesData = [];
    var menuItems = ["Sandbox Options", "Sandbox Details", "Sandboxes Cheat sheets", "Sandboxes Labs", "Sandboxes Categories", "Sandboxes Exercises"];

    const [isLoading, setIsLoading] = useState(true);
    const renderNotififications = () => {
        let notifications = notificationMessage.map((item) => {
          let notificationType = item.success ? "success" : "error";
          return (
            <Alert
              onClose={() => setShowNotification(false)}
              severity={notificationType}
              spacing={1}
            >
              {item.message}
            </Alert>
          );
        });
    
        return notifications;
      };

      //sandbox option API call --- for data featch
      const renderoption = async (value) => {
        return CMSAPIWithToken()
        .get("/poc/admin_sandboxes_options/")
        .then((resp) => resp.data.results)
          .then((resp) => {
            resp.forEach((item) => {
              if(item.admin_sandboxes_id == sb_id){
                optionData.push({
                  id: item.id,
                  label: item.label,
                  value: item.value,
                  identifier: item.identifier,
                actions: (
                  <Grid container wrap="wrap" spacing={1}>
                    <IconButton onClick={() => handleOptionsViewButton(item.id)}>
                      <Visibility />
                    </IconButton>
                    <IconButton onClick={() => handleOptionEditButton(item.id)}>
                      <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleOptionDeleteButton(item.id)}>
                      <DeleteForeverIcon/>
                    </IconButton>
                  </Grid>
                ),
                });
              }
             
            });
           
            setOptionRows(optionData);
            
            setIsLoading(false);
          })
          .catch((err) => {
            let message = err.response.data.message || "unknown error occured";
            setIsLoading(false);
          });
      };


      //Sandbox Details API call --- for data featch
      const renderdetails = async (value) => {
        return CMSAPIWithToken()
        .get("/poc/admin_sandboxes_details/")
        .then((resp) => resp.data.results)
          .then((resp) => {
            resp.forEach((item) => {
              if(item.admin_sandboxes_id == sb_id){
                detailsData.push({
                    id: item.id,
                    admin_sandboxes_id: item.admin_sandboxes_id,
                    modified: item.modified,
                    created: item.created,
                  actions: (
                    <Grid container wrap="wrap" spacing={1}>
                      <IconButton onClick={() => handlDetailsViewButton(item.id)}>
                        <Visibility />
                      </IconButton>
                      <IconButton onClick={() => handleDetailsEditButton(item.id)}>
                        <EditIcon/>
                      </IconButton>
                      <IconButton onClick={() => handleDetailsDeleteButton(item.id)}>
                        <DeleteForeverIcon/>
                      </IconButton>
                    </Grid>
                  ),
                  });
                }
            });
           
            setDetailsRows(detailsData);
            
            setIsLoading(false);
          })
          .catch((err) => {
            let message = err.response.data.message || "unknown error occured";
            setIsLoading(false);
          });
      };

      //Sandbox Cheat Sheets API call --- for data featch
      const rendercheatsheets = async (value) => {
        return CMSAPIWithToken()
        .get("/poc/admin_sandboxes_cheatsheets/")
        .then((resp) => resp.data.results)
          .then((resp) => {
            resp.forEach((item) => {
              if(item.admin_sandboxes_id == sb_id){
                cheatsheetsData.push({
                    id: item.id,
                    title: item.title,
                    intro_list: item.intro_list,
                    created: item.created,
                  actions: (
                    <Grid container wrap="wrap" spacing={1}>
                      <IconButton onClick={() => handlCheatSheetsViewButton(item.id)}>
                        <Visibility />
                      </IconButton>
                      <IconButton onClick={() => handleCheatSheetsEditButton(item.id)}>
                        <EditIcon/>
                      </IconButton>
                      <IconButton onClick={() => handleCheatSheetsDeleteButton(item.id)}>
                        <DeleteForeverIcon/>
                      </IconButton>
                    </Grid>
                  ),
                  });
                }
            });
           
            setCheatsheetsRows(cheatsheetsData);
            
            setIsLoading(false);
          })
          .catch((err) => {
            let message = err.response.data.message || "unknown error occured";
            setIsLoading(false);
          });
      };

      //Sandbox Admin Labs API call --- for data featch
      const renderlabs = async (value) => {
        return CMSAPIWithToken()
        .get("/poc/admin_sandboxes_labs/")
        .then((resp) => resp.data.results)
          .then((resp) => {
            resp.forEach((item) => {
              if(item.admin_sandboxes_id == sb_id){
                labsData.push({
                    id: item.id,
                    admin_sandboxes_id: item.admin_sandboxes_id,
                    content: item.content,
                    created: item.created,
                  actions: (
                    <Grid container wrap="wrap" spacing={1}>
                      <IconButton onClick={() => handlLabsViewButton(item.id)}>
                        <Visibility />
                      </IconButton>
                      <IconButton onClick={() => handleLabsEditButton(item.id)}>
                        <EditIcon/>
                      </IconButton>
                      <IconButton onClick={() => handleLabsDeleteButton(item.id)}>
                        <DeleteForeverIcon/>
                      </IconButton>
                    </Grid>
                  ),
                  });
                }
            });
           
            setLabsRows(labsData);
            
            setIsLoading(false);
          })
          .catch((err) => {
            let message = err.response.data.message || "unknown error occured";
            setIsLoading(false);
          });
      };


      //Sandbox Admin Categories API call --- for data featch
      const rendercategories = async (value) => {
        return CMSAPIWithToken()
        .get("/poc/admin_sandboxes_categories/")
        .then((resp) => resp.data.results)
          .then((resp) => {
            resp.forEach((item) => {
              if(item.admin_sandboxes_id == sb_id){
                sandbox_cat_id_map[item.id] = item.sandboxes_category_name
                categoriesData.push({
                    id: item.id,
                    sandboxes_category_name: item.sandboxes_category_name,
                    identifier: item.identifier,
                    created: item.created,
                  actions: (
                    <Grid container wrap="wrap" spacing={1}>
                      <IconButton onClick={() => handlCategoriesViewButton(item.id)}>
                        <Visibility />
                      </IconButton>
                      <IconButton onClick={() => handleCategoriesEditButton(item.id)}>
                        <EditIcon/>
                      </IconButton>
                      <IconButton onClick={() => handleCategoriesDeleteButton(item.id)}>
                        <DeleteForeverIcon/>
                      </IconButton>
                      <IconButton onClick={() => handleExerciseForwardButton(item.id)}>
                      <ForwardIcon/>
                      </IconButton>
                    </Grid>
                  ),
                  });
                }
            });
            localStorage.removeItem("sandbox_cat_id_map")
            localStorage.setItem("sandbox_cat_id_map", JSON.stringify(sandbox_cat_id_map))
            setCategoriesRows(categoriesData);
            setIsLoading(false);
          })
          .catch((err) => {
            let message = err.response.data.message || "unknown error occured";
            setIsLoading(false);
          });
      };

      //Sandbox Admin Categories API call --- for data featch
      const renderexercises = async () => {

        setIsLoading(true); // Set loading before starting API requests
        return CMSAPIWithToken()
          .get("/poc/admin_sandboxes_categories/")
          .then((categoriesResponse) => {
            const categoriesData = categoriesResponse.data.results;
            setCategoriesValue(categoriesData);
            // Now fetch exercises after fetching categories
            return CMSAPIWithToken()
              .get("/poc/admin_sandboxes_exercises/")
              .then((resp) => resp.data.results)
              .then((resp) => {
                resp.forEach((item) => {
                  if(item.admin_sandboxes_id == sb_id){
                    const associatedCategory = categoriesData.find(
                                  (category) => category.id === item.admin_sandboxes_categories_id
                                );
                                exercisesData.push({
                                                id: item.id,
                                                admin_sandboxes_category_name:associatedCategory ? associatedCategory.sandboxes_category_name : null,
                                                title: item.title,
                                                created: item.created,
                                              actions: (
                                                <Grid container wrap="wrap" spacing={1}>
                                                  <IconButton onClick={() => handlExercisesViewButton(item.id)}>
                                                    <Visibility />
                                                  </IconButton>
                                                  <IconButton onClick={() => handleExercisesEditButton(item.id)}>
                                                    <EditIcon/>
                                                  </IconButton>
                                                  <IconButton onClick={() => handleExercisesDeleteButton(item.id)}>
                                                    <DeleteForeverIcon/>
                                                  </IconButton>
                                                </Grid>
                                              ),
                                              });
                  }
                });
                setExercisesRows(exercisesData);

                setIsLoading(false);

              })
              .catch((err) => {
                      let message = err.response.data.message || "unknown error occured";
                      setIsLoading(false);
                    });
                  });
      }
      


      //Sandbox Delete Items 
      const handleDeleteButton = (id) => {
        let url="/poc/admin_sandboxes"+`/${id}/`;
        let user_confirm = window.confirm("Do you want to delete excercise?")
        if(user_confirm){
        CMSAPIWithToken()
        .delete("/poc/admin_sandboxes"+`/${id}/`)
        .then((resp) => resp.data.results)
        .then((data) => {
          const payload = {
              message: "Sandbox Deleted Successfully",
              color: "success",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
          history.push("ProductContentSandboxesMenuItems?id="+sb_id+"&title="+sandbox_title);
          window.location.reload()
      })
      .catch((err) => {
        let message = err.response.data.message;
        const payload = {
          message: message,
          color: "danger",
      };
      payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
      });
      };
      };
      //Sandbox Option Delete Items 
      const handleOptionDeleteButton = (id) => {
        let user_confirm = window.confirm("Do you want to delete option?")
        if(user_confirm){
        CMSAPIWithToken()
        .delete("/poc/admin_sandboxes_options"+`/${id}/`)
          .then((resp) => resp.data.results)
          .then((data) => {
          const payload = {
          message: "Sandbox Option Deleted Successfully",
          color: "success",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
          history.push("ProductContentSandboxesMenuItems?id="+sb_id+"&title="+sandbox_title);
          // window.location.reload()
          })
          .catch((err) => {
            let message = err.response.data.message;
            const payload = {
              message: message,
              color: "danger",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
        };
      }
      //Sandbox Details Delete Items 
      const handleDetailsDeleteButton = (id) => {
        let user_confirm = window.confirm("Do you want to delete details?")
        if(user_confirm){
        CMSAPIWithToken()
        .delete("/poc/admin_sandboxes_details"+`/${id}/`)
          .then((resp) => resp.data.results)
          .then((data) => {
          const payload = {
          message: "Sandbox Details Deleted Successfully",
          color: "success",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
          history.push("ProductContentSandboxesMenuItems?id="+sb_id+"&title="+sandbox_title);
          // window.location.reload()
          })
          .catch((err) => {
            let message = err.response.data.message;
            const payload = {
              message: message,
              color: "danger",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
        };
      }
//Sandbox Cheat Sheets Delete Items 
      const handleCheatSheetsDeleteButton = (id) => {
        let user_confirm = window.confirm("Do you want to delete cheatsheet?")
        if(user_confirm){
        CMSAPIWithToken()
        .delete("/poc/admin_sandboxes_cheatsheets"+`/${id}/`)
          .then((resp) => resp.data.results)
          .then((data) => {
          const payload = {
          message: "Sandbox Cheat Sheets Deleted Successfully",
          color: "success",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
          history.push("ProductContentSandboxesMenuItems?id="+sb_id+"&title="+sandbox_title);
          // window.location.reload()
          })
          .catch((err) => {
            let message = err.response.data.message;
            const payload = {
              message: message,
              color: "danger",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
        };
      }
  //Sandbox CLabs Delete Items 
      const handleLabsDeleteButton = (id) => {
        let user_confirm = window.confirm("Do you want to delete lab?")
        if(user_confirm){
        CMSAPIWithToken()
        .delete("/poc/admin_sandboxes_labs"+`/${id}/`)
          .then((resp) => resp.data.results)
          .then((data) => {
          const payload = {
          message: "Sandbox Labs Deleted Successfully",
          color: "success",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
          history.push("ProductContentSandboxesMenuItems?id="+sb_id+"&title="+sandbox_title);
          // window.location.reload()
          })
          .catch((err) => {
            let message = err.response.data.message;
            const payload = {
              message: message,
              color: "danger",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
        };
      }
      const handleExerciseForwardButton = (cat_id) => {
        const query=new URLSearchParams(window.location.search);
        const title = query.get("title");
        history.push("SandboxExercisesList?cat_id="+cat_id+"&id="+
        sb_id+ "&title="+title);
      };
//Sandbox Categories Delete Items 
      const handleCategoriesDeleteButton = (id) => {
        let user_confirm = window.confirm("Do you want to delete Category?")
        if(user_confirm){
        CMSAPIWithToken()
        .delete("/poc/admin_sandboxes_categories"+`/${id}/`)
          .then((resp) => resp.data.results)
          .then((data) => {
          const payload = {
          message: "Sandbox Categories Deleted Successfully",
          color: "success",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
          history.push("ProductContentSandboxesMenuItems?id="+sb_id+"&title="+sandbox_title);
          // window.location.reload()
          })
          .catch((err) => {
            let message = err.response.data.message;
            const payload = {
              message: message,
              color: "danger",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
        }; 
      } 
//Sandbox Exercises Delete Items 
      const handleExercisesDeleteButton = (id) => {
        let user_confirm = window.confirm("Do you want to delete excercise?")
        if(user_confirm){
        CMSAPIWithToken()
        .delete("/poc/admin_sandboxes_exercises"+`/${id}/`)
          .then((resp) => resp.data.results)
          .then((data) => {
          const payload = {
          message: "Sandbox Exercises Deleted Successfully",
          color: "success",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
          history.push("ProductContentSandboxesMenuItems?id="+sb_id+"&title="+sandbox_title);
          // window.location.reload()
          })
          .catch((err) => {
            let message = err.response.data.message;
            const payload = {
              message: message,
              color: "danger",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
        };
      }

      
      // view single product details
      const renderDrawerComponent = () => {
        switch (currentDrawerScreen) {
          case "Sandboxes Edit":
            return <SandboxUpdate user={currentUser} />;
          case "Sandboxes Option View":
            return <SandboxOptionsView user={currentUser} />;  
          case "Sandboxes Option Edit":
            return <SandboxOptionsUpdate user={currentUser} />;
          case "Sandboxes Details View":
            return <SandboxDetailsView user={currentUser} />;  
          case "Sandboxes Details Edit":
            return <SandboxDetailsUpdate user={currentUser} />;
          case "Sandboxes Cheat Sheets View":
            return <SandboxesCheatSheetsView user={currentUser} />;  
          case "Sandboxes Cheat Sheets Edit":
            return <SandboxesCheatSheetsUpdate user={currentUser} />;
          case "Sandboxes Labs View":
            return <SandboxesLabsView user={currentUser} />;  
          case "Sandboxes Labs Edit":
            return <SandboxesLabsUpdate user={currentUser} />;
          case "Sandboxes Categories View":
            return <SandboxesCategoriesView user={currentUser} />;  
          case "Sandboxes Categories Edit":
            return <SandboxesCategoriesUpdate user={currentUser} />;
          case "Sandboxes Exercises View":
            return <SandboxesExercisesView user={currentUser} />;  
          case "Sandboxes Exercises Edit":
            return <SandboxesExercisesUpdate user={currentUser} categories={categoriesValue} />;                       
          case "default":
            return null;
        }
      };
//code for view button
      const handleViewButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Sandboxes View");
        setCurrentUser(username);
      };
      const handleOptionsViewButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Sandboxes Option View");
        setCurrentUser(username);
      };
      const handlDetailsViewButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Sandboxes Details View");
        setCurrentUser(username);
      };
      const handlCheatSheetsViewButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Sandboxes Cheat Sheets View");
        setCurrentUser(username);
      };
      const handlLabsViewButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Sandboxes Labs View");
        setCurrentUser(username);
      };
      const handlCategoriesViewButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Sandboxes Categories View");
        setCurrentUser(username);
      };
      const handlExercisesViewButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Sandboxes Exercises View");
        setCurrentUser(username);
      };
//code for edit button      

      const handleOptionEditButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Sandboxes Option Edit");
        setCurrentUser(username);
      };
      const handleDetailsEditButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Sandboxes Details Edit");
        setCurrentUser(username);
      };
      const handleCheatSheetsEditButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Sandboxes Cheat Sheets Edit");
        setCurrentUser(username);
      };
      const handleLabsEditButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Sandboxes Labs Edit");
        setCurrentUser(username);
      };
      const handleCategoriesEditButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Sandboxes Categories Edit");
        setCurrentUser(username);
      };
      const handleExercisesEditButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Sandboxes Exercises Edit");
        setCurrentUser(username);
      };

      //Add new sandbox , Option , Details ,Cheat Sheets, Labs ,Categories,Exercises
      const handleAddUserOption = () => {
        history.push("SandboxOptionsCreate?id="+sb_id);  
      };
      const handleAddUserDetails = () => {
        history.push("SandboxDetailsCreate?id="+sb_id);  
      };
      const handleAddUserCheatSheets = () => {
        history.push("SandboxesCheatSheetsCreate?id="+sb_id);  
      };
      const handleAddUserLabs = () => {
        history.push("SandboxesLabsCreate?id="+sb_id);  
      };
      const handleAddUserCategories = () => {
        history.push("SandboxesCategoriesCreate?id="+sb_id);  
      };
      const handleAddUserExercises = () => {
        history.push({
          pathname: 'SandboxesExercisesCreate',
          search: '?id='+sb_id,
          state: { detail: 'some_value' }
        });  
      };
      const backButton = () => {
        history.goBack();
      }
      
    const renderContent = () => {
      const query=new URLSearchParams(window.location.search);
        const title = query.get("title");
        switch (currentScreen) {
            case "Sandbox Options":
              return (
                <Grid container className={classes.root} spacing={1}>
                  <Grid item xs={12} md={12}>
                    {showNotification ? renderNotififications() : null}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LoaderHoc isLoading={isLoading}>
                      <CustomTable
                        columns={optioncolumns}
                        data={optionRows}
                        handleDeleteButtonClick={handleDeleteButton}
                        handleButtonClick={handleAddUserOption}
                        handleViewButtonClick={handleOptionsViewButton}
                        tableTitle={title}
                        buttonTitle="Add Sandbox Options"
                        hiddenColumns={[]}
                      />
                    </LoaderHoc>
                  </Grid>
                </Grid>
              );
              case "Sandbox Details":
              return (
                <Grid container className={classes.root} spacing={1}>
                  <Grid item xs={12} md={12}>
                    {showNotification ? renderNotififications() : null}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LoaderHoc isLoading={isLoading}>
                      <CustomTable
                        columns={detailscolumns}
                        data={detailsRows}
                        handleDeleteButtonClick={handleDeleteButton}
                        handleButtonClick={handleAddUserDetails}
                        handleViewButtonClick={handleViewButton}
                        tableTitle={title}
                        buttonTitle="Add Sandbox Details"
                        hiddenColumns={[]}
                      />
                    </LoaderHoc>
                  </Grid>
                </Grid>
              );
              case "Sandboxes Cheat sheets":
              return (
                <Grid container className={classes.root} spacing={1}>
                  <Grid item xs={12} md={12}>
                    {showNotification ? renderNotififications() : null}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LoaderHoc isLoading={isLoading}>
                      <CustomTable
                        columns={cheatsheetscolumns}
                        data={cheatsheetsRows}
                        handleDeleteButtonClick={handleDeleteButton}
                        handleButtonClick={handleAddUserCheatSheets}
                        handleViewButtonClick={handleViewButton}
                        tableTitle={title}
                        buttonTitle="Add Cheat sheets"
                        hiddenColumns={[]}
                      />
                    </LoaderHoc>
                  </Grid>
                </Grid>
              );
              case "Sandboxes Labs":
              return (
                <Grid container className={classes.root} spacing={1}>
                  <Grid item xs={12} md={12}>
                    {showNotification ? renderNotififications() : null}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LoaderHoc isLoading={isLoading}>
                      <CustomTable
                        columns={labscolumns}
                        data={labsRows}
                        handleDeleteButtonClick={handleDeleteButton}
                        handleButtonClick={handleAddUserLabs}
                        handleViewButtonClick={handleViewButton}
                        tableTitle={title}
                        buttonTitle="Add New Labs"
                        hiddenColumns={[]}
                      />
                    </LoaderHoc>
                  </Grid>
                </Grid>
              );
              case "Sandboxes Categories":
              return (
                <Grid container className={classes.root} spacing={1}>
                  <Grid item xs={12} md={12}>
                    {showNotification ? renderNotififications() : null}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LoaderHoc isLoading={isLoading}>
                      <CustomTable
                        columns={categoriescolumns}
                        data={categoriesRows}
                        handleDeleteButtonClick={handleDeleteButton}
                        handleButtonClick={handleAddUserCategories}
                        handleViewButtonClick={handleViewButton}
                        tableTitle={title}
                        buttonTitle="Add New Categories"
                        hiddenColumns={[]}
                      />
                    </LoaderHoc>
                  </Grid>
                </Grid>
              );
              case "Sandboxes Exercises":
              return (
                <Grid container className={classes.root} spacing={1}>
                  <Grid item xs={12} md={12}>
                    {showNotification ? renderNotififications() : null}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LoaderHoc isLoading={isLoading}>
                      <CustomTable
                        columns={exercisescolumns}
                        data={exercisesRows}
                        handleDeleteButtonClick={handleDeleteButton}
                        handleButtonClick={handleAddUserExercises}
                        handleViewButtonClick={handleViewButton}
                        tableTitle={title}
                        buttonTitle="Add New Exercises"
                        hiddenColumns={[]}
                      />
                    </LoaderHoc>
                  </Grid>
                </Grid>
              );
        }
    };
    return (
        <div>
            <Grid container className={classes.root}>
            <GridContainer className="backbutton">
                <GridItem sm={12} md={12}>
                  <Typography
                    variant="body1"
                    className={classes.backButton}
                    onClick={backButton}
                  >
                    <ArrowBackIcon /> Back
                  </Typography>
                </GridItem>
              </GridContainer>
                <Grid item md={2}>
                    <Menu
                        items={menuItems}
                        onItemClick={setCurrentScreen}
                        currentScreen={currentScreen}
                    />
                </Grid>

                <Grid item md={10}>
                    {renderContent()}
                </Grid>
                 {/* Drawer */}
                 <RightDrawer
                      open={openDrawer}
                      onClose={setOpenDrawer}
                      title={currentInventory}
                    >
                      {renderDrawerComponent()}
                    </RightDrawer>
            </Grid>
        </div>
    );
};

export default ProductContentSandboxesMenuItems;