import React from "react";

const successAlertBorder = "#048001";
const successAlertBackground = "rgba(4, 128, 1, 0.1)";
const successAlertTextColor = successAlertBorder;

const errorAlertBorder = "#DF0000";
const errorAlertBackground = "rgba(223, 0, 0, 0.2)";
const errorTextColor = errorAlertBorder;

const AlertDiv = ({ message, status }) => {
  const successStyle = {
    backgroundColor: successAlertBackground,
    border: `1px solid ${successAlertBorder}`,
    color: successAlertTextColor,
    boxSizing: "border-box",
    padding: "17px 25px",
    marginTop: "15px"
  };

  const errorStyle = {
    backgroundColor: errorAlertBackground,
    border: `1px solid ${errorAlertBorder}`,
    color: errorTextColor,
    boxSizing: "border-box",
    padding: "17px 25px",
    marginTop: "15px"
  };

  if (status && message) {
    return (
      <div style={status === "error" ? { ...errorStyle } : { ...successStyle }}>
        {message}
      </div>
    );
  } else {
    return null;
  }
};

export default AlertDiv;
