/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import Axios from "axios";

// @material-ui/core components
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";
// import Button from "components/CustomButtons/Button.js";
import Button from "@material-ui/core/Button";
import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { Api } from "config/api";
import { FormControl, Grid, TextField } from "@material-ui/core";
import { useDispatch } from "react-redux";
import errImg from "assets/criterion_networks/img/err.png";
import {
  SAVE_TOKEN,
  SAVE_PROFILE,
  SHOW_LOADER,
  HIDE_LOADER,
  SHOW_NOTIFICATION
} from "action_creators/actioncreator";
import { PRIMARY_COLOR } from "global_constants/app_constants";
import {
  errorAlertBackground,
  errorAlertBorder,
  successAlertBackground,
  successAlertBorder
} from "assets/jss/material-dashboard-pro-react.js";
import SecurityQuestionComponent from "components/Login/SecurityQuestions";
import AdvertisingComponent from "components/Login/AdvertisingComponent";
import { SDCloudBackendAPIWithToken, jwtToken } from "apis/backendAPI";
import { SDCloudBackendAPIWithoutToken } from "apis/backendAPI";
import { forgetPassword } from "../../apis/APIServices";
import brand from "assets/criterion_networks/svg/brand-logo.svg";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import theme from "../../theme";
import { SDCloudCustomizeAPIWithToken } from "apis/backendAPI";

const sdcloudBrandingLogo = require("assets/criterion_networks/img/sdcloud_branding_logo2.png");
//let brandimage = localStorage.getItem("BrandLogo") !== null && localStorage.getItem("BrandLogo").includes('assets') === false ? localStorage.getItem("BrandLogo") : brand;
let brandimage = brand;
const useStyles = makeStyles(styles);

const VIEW = {
  forgetpassword: "forgetpassword",
  loginview: "loginview",
  signupview: "signupview",
  securityquestion: "securityquestion",
  mfaview: "mfaview",
  // ssoview: "ssoview"
};

const inputLabelProps = {
  disableAnimation: true,
  shrink: false
};

const Errorcolor = "#DF0000";

const CustomCheckBox = ({ checked, handleToggleCheckbox }) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      control={
        <Checkbox
          tabIndex={-1}
          className={classes.checkboxRoot}
          color="secondary"
          disableRipple
          checked={checked}
          onClick={() => handleToggleCheckbox()}
          checkedIcon={
            <span className={clsx(classes.icon, classes.checkedIcon)} />
          }
          icon={<span className={classes.icon} />}
          classes={{
            checked: classes.checked,
            root: classes.checkRoot
          }}
        />
      }
      classes={{
        label: classes.label,
        root: classes.labelRoot
      }}
      label={
        <span className={classes.agree}>
          I agree to{" "}
          <a
            style={{ color: "#F96B13" }}
            href="http://criterionnetworks.com/terms-of-service/"
            target="blank"
          >
            Terms-of-Service
          </a>{" "}
          and{" "}
          <a
            style={{ color: "#F96B13" }}
            href="http://criterionnetworks.com/privacy-policy/"
            target="blank"
          >
            Privacy Policy
          </a>{" "}
          of Criterion Networks
        </span>
      }
    />
  );
};
const LoginComponent = props => {
  const {
    handleLogin,
    // handleSSO,
    setLoginView,
    handleInputUser,
    handleInputPassword,
    handleToggleCheckbox,
    username,
    password,
    resetAlertAndCheckbox,
    checked,
    errors,
    ResetError
  } = props;
  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);

  const setPasswordPage = () => {
    ResetError("login");
    setLoginView(VIEW.forgetpassword);
    resetAlertAndCheckbox();
  };

  const setsign = () => {
    ResetError("login");
    setLoginView(VIEW.signupview);
    resetAlertAndCheckbox();
  };

  return (
    <Grid
      className={classes.loginContainer}
      // container
      // justify="center"
      // alignItems="center"
      // style={{ display: "flex" }}
    >
      <Grid
        item
        sm={10}
        md={6}
        lg={6}
        xs={12}
        style={{
          //  height: "100%",
          marginLeft: "auto",
          marginRight: "auto"
        }}
      >
        <Grid
          className={classes.loginCard}
          // container justify="center" alignItems="center"
          style={{
            marginBottom: "3rem",
            padding: 0,
            border: 0,
            borderRadius: 0
          }}
        >
          <Grid
            className={classes.loginCard}
            //  item xs={12} sm={10} md={8} lg={6}
          >
            {/* <div className={classes.brandingLogo}> */}
            {/* <img src={sdcloudBrandingLogo} /> */}
            <img
              src={brandimage}
              alt="brand-logo"
              //  className={classes.loginLogoImage}
              style={
                props.alertVisibility
                  ? {
                      width: "100%",
                      margin: "3rem auto 3rem auto",
                      // margin: "3rem auto 6.5rem auto",
                      // width: "100%",
                      height: "auto"
                    }
                  : {
                      margin: "3rem auto",
                      width: "100%",
                      height: "auto"
                    }
              }
            />
            <AlertBox
              alertVisibility={props.alertVisibility}
              alertData={props.alertData}
              isErrorAlert={props.isErrorAlert}
            />

            <form
              onSubmit={e => {
                e.preventDefault();
                handleLogin(e);
              }}
            >
              <FormControl
                fullWidth
                focused
                className={classes.inputMargin}
                // className={classes.margin + " " + classes.customInputStyle}
              >
                {/* <InputLabel
                    htmlFor="username"
                    disableAnimation
                    shrink={false}
                  >
                    Username
                  </InputLabel>
                  <Input
                    id="username"
                    onChange={handleInputUser}
                    value={username}
                  /> */}
                <TextField
                  type="text"
                  variant="standard"
                  label="Username"
                  fullWidth
                  InputLabelProps={{
                    disableAnimation: true,
                    shrink: false,
                    classes: {
                      root: classes.labelRoot
                    }
                  }}
                  value={username}
                  onChange={handleInputUser}
                  //  color="secondary"
                  className={classes.textField + " " + classes.InputUnderline}
                />
                <span className="field-error" style={{ color: Errorcolor }}>
                  {errors["UserName"]}
                </span>
              </FormControl>
              <FormControl
                fullWidth
                focused
                className={classes.inputMargin}
                // className={classes.margin + " " + classes.customInputStyle}
              >
                {/* <InputLabel
                    htmlFor="password"
                    disableAnimation
                    shrink={false}
                  >
                    Password
                  </InputLabel>
                  <Input
                    id="password"
                    type="password"
                    onChange={handleInputPassword}
                    value={password}
                  /> */}
                <TextField
                  type={showPassword ? "text" : "password"}
                  variant="standard"
                  label="Password"
                  fullWidth
                  InputLabelProps={{
                    disableAnimation: true,
                    shrink: false,
                    classes: {
                      root: classes.labelRoot
                    }
                  }}
                  className={classes.textField + " " + classes.InputUnderline}
                  value={password}
                  onChange={handleInputPassword}
                  // color="secondary"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          onMouseDown={event => event.preventDefault()}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
                <span className="field-error" style={{ color: Errorcolor }}>
                  {errors["Password"]}
                </span>
              </FormControl>
              <br />
              <br />
              <CustomCheckBox
                checked={checked}
                handleToggleCheckbox={handleToggleCheckbox}
              />
              <span className="field-error" style={{ color: Errorcolor }}>
                {errors["LoginAgree"]}
              </span>
              <br />
              <br />
              <Grid item xs={12} className={classes.justifyContentCenter}>
                <Button
                  variant="contained"
                  color="secondary"
                  disableElevation
                  size="large"
                  fullWidth
                  disableFocusRipple
                  className={classes.btnStyle}
                  type="submit"
                  // className={classes.loginBtn}
                  // onClick={() => handleLogin()}
                  style={{ fontSize: "16px" }}
                  // color="info"
                >
                  Login
                </Button>

                <br />
                {/* <Grid item xs={12}>
                  <Grid
                    container
                    spacing={1}
                    justify="center"
                    alignItems="center"
                    style={{ margin: "0px", width: "100%" }}
                  >
                    <Grid item xs={5} className={classes.separator}></Grid>
                    <Grid item xs={2} className={classes.ortext}>
                      <Grid container justify="center" alignItems="center">
                        OR
                      </Grid>
                    </Grid>
                    <Grid item xs={5} className={classes.separator}></Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={() => handleSSO()}
                    variant="outlined"
                    color="secondary"
                    disableElevation
                    size="large"
                    fullWidth
                    disableFocusRipple
                    type="button"
                    className={classes.outLinedBtnStyle}
                    style={{ fontSize: "16px" }}
                  >
                    SSO Login
                  </Button>
                </Grid> */}
                <Grid item xs={12} className={classes.inviteContainer}>
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    className={classes.inviteText}
                    style={{ fontSize: "14px" }}
                  >
                    <span
                      style={{
                        color: "#F96B13",
                        cursor: "pointer"
                      }}
                      onClick={setsign}
                    >
                      Sign up
                    </span>{" "}
                    &nbsp; here if you have an invite code
                    {/* Do you have invite?{" "}
                    <span
                      style={{
                        color: PRIMARY_COLOR,
                        cursor: "pointer"
                      }}
                      onClick={() => setLoginView(VIEW.signupview)}
                    >
                      &nbsp; Sign up by invite code
                    </span> */}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  className={classes.forgotPassword}
                  onClick={setPasswordPage}
                >
                  <Grid
                    container
                    justify="center"
                    alignItems="center"
                    style={{ fontSize: "14px", color: "#F96B13" }}
                  >
                    Forgot password ?
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const SignupComponent = props => {
  const {
    handleSignup,
    setLoginView,
    handleToggleCheckbox,
    resetAlertAndCheckbox,
    userState,
    setUserState,
    checked,
    alertData,
    alertVisibility,
    isErrorAlert,
    errors,
    ResetError
  } = props;
  const classes = useStyles();
  return (
    <Grid
      className={classes.loginContainer}
      // container
      // justify="center"
      // alignItems="center"
      style={{
        height: "100%"
      }}
    >
      <Grid item xs={12} style={{ height: "100%" }}>
        <Grid
          item
          sm={10}
          md={6}
          lg={6}
          xs={12}
          style={{
            //  height: "100%",
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          <Grid
            className={classes.loginCard}
            // container justify="center" alignItems="center"
            style={{
              marginBottom: "3rem",
              padding: 0,
              border: 0,
              borderRadius: 0
            }}
          >
            <Grid
              className={classes.loginCard}
              //  item xs={12} sm={10} md={8} lg={6}
            >
              {/* <div className={classes.brandingLogo}> */}
              {/* <img src={sdcloudBrandingLogo} /> */}
              <img
                src={brandimage}
                alt="brand-logo"
                //  className={classes.loginLogoImage}
                style={
                  props.alertVisibility
                    ? {
                        //  width: "164px"
                        margin: "3rem auto 3rem auto",
                        // margin: "3rem auto 6.5rem auto",
                        width: "100%",
                        height: "auto"
                      }
                    : {
                        margin: "3rem auto",
                        width: "100%",
                        height: "auto"
                      }
                }
              />
              <h4
                style={{ marginTop: "20px", marginBottom: "20px" }}
                className={classes.loginTitle}
              >
                Sign up
              </h4>
              <AlertBox
                alertVisibility={alertVisibility}
                alertData={alertData}
                isErrorAlert={isErrorAlert}
              />
              <form>
                <Grid container justify="flex-start" alignItems="flex-start">
                  <Grid item xs={12} sm={6}>
                    <Grid container justify="flex-start">
                      <Grid item xs={12} sm={11}>
                        <FormControl
                          focused
                          fullWidth
                          className={classes.inputMargin}
                          // className={
                          //   classes.margin + " " + classes.customInputStyle
                          // }
                        >
                          {/* <InputLabel htmlFor="firstName">
                              First Name
                            </InputLabel>
                            <Input
                              id="firstName"
                              onChange={(event) => {
                                const fname = event.target.value;
                                setUserState((prev) => {
                                  return {
                                    ...prev,
                                    firstName: fname,
                                  };
                                });
                              }}
                              value={userState.firstName}
                            /> */}
                          <TextField
                            type="text"
                            variant="standard"
                            label="First Name"
                            fullWidth
                            InputLabelProps={{
                              disableAnimation: true,
                              shrink: false,
                              classes: {
                                root: classes.labelRoot
                              }
                            }}
                            className={
                              classes.inputMargin + " " + classes.InputUnderline
                            }
                            onChange={event => {
                              const fname = event.target.value;
                              setUserState(prev => {
                                return {
                                  ...prev,
                                  firstName: fname
                                };
                              });
                            }}
                            value={userState.firstName}
                            //  color="secondary"
                          />
                          <span
                            className="field-error"
                            style={{ color: Errorcolor }}
                          >
                            {errors["SignUpFirstName"]}
                          </span>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container justify="flex-end">
                      <Grid item xs={12} sm={11}>
                        <FormControl
                          focused
                          fullWidth
                          className={classes.inputMargin}
                          // className={
                          //   classes.margin + " " + classes.customInputStyle
                          // }
                        >
                          {/* <InputLabel htmlFor="lastName">
                              Last Name
                            </InputLabel>
                            <Input
                              id="lastName"
                              onChange={(event) => {
                                const lname = event.target.value;
                                setUserState((prev) => {
                                  return {
                                    ...prev,
                                    lastName: lname,
                                  };
                                });
                              }}
                              value={userState.lastName}
                            /> */}
                          <TextField
                            type="text"
                            variant="standard"
                            label="Last Name"
                            fullWidth
                            InputLabelProps={{
                              disableAnimation: true,
                              shrink: false,
                              classes: {
                                root: classes.labelRoot
                              }
                            }}
                            className={
                              classes.inputMargin + " " + classes.InputUnderline
                            }
                            onChange={event => {
                              const lname = event.target.value;
                              setUserState(prev => {
                                return {
                                  ...prev,
                                  lastName: lname
                                };
                              });
                            }}
                            value={userState.lastName}
                            //  color="secondary"
                          />
                          <span
                            className="field-error"
                            style={{ color: Errorcolor }}
                          >
                            {errors["SignUpLastName"]}
                          </span>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <FormControl
                  focused
                  fullWidth
                  className={classes.inputMargin}
                  // className={classes.margin + " " + classes.customInputStyle}
                >
                  {/* <InputLabel htmlFor="email">Email</InputLabel>
                    <Input
                      id="email"
                      onChange={(event) => {
                        const email = event.target.value;
                        setUserState((prev) => {
                          return {
                            ...prev,
                            email: email,
                          };
                        });
                      }}
                      value={userState.email}
                    /> */}
                  <TextField
                    type="text"
                    variant="standard"
                    label="Email"
                    fullWidth
                    InputLabelProps={{
                      disableAnimation: true,
                      shrink: false,
                      classes: {
                        root: classes.labelRoot
                      }
                    }}
                    className={
                      classes.inputMargin + " " + classes.InputUnderline
                    }
                    onChange={event => {
                      const email = event.target.value;
                      setUserState(prev => {
                        return {
                          ...prev,
                          email: email
                        };
                      });
                    }}
                    value={userState.email}
                    // color="secondary"
                  />
                  <span className="field-error" style={{ color: Errorcolor }}>
                    {errors["SignUpEmail"]}
                  </span>
                </FormControl>

                <FormControl
                  focused
                  fullWidth
                  className={classes.inputMargin}
                  // className={classes.margin + " " + classes.customInputStyle}
                >
                  {/* <InputLabel htmlFor="inviteCode">Invite code</InputLabel>
                    <Input
                      id="inviteCode"
                      onChange={(event) => {
                        const inviteCode = event.target.value;
                        setUserState((prev) => {
                          return {
                            ...prev,
                            inviteCode: inviteCode,
                          };
                        });
                      }}
                      value={userState.inviteCode}
                    /> */}
                  <TextField
                    type="text"
                    variant="standard"
                    label="Invite Code"
                    fullWidth
                    InputLabelProps={{
                      disableAnimation: true,
                      shrink: false,
                      classes: {
                        root: classes.labelRoot
                      }
                    }}
                    className={
                      classes.inputMargin + " " + classes.InputUnderline
                    }
                    onChange={event => {
                      const inviteCode = event.target.value;
                      setUserState(prev => {
                        return {
                          ...prev,
                          inviteCode: inviteCode
                        };
                      });
                    }}
                    value={userState.inviteCode}
                    //   color="secondary"
                  />
                  <span className="field-error" style={{ color: Errorcolor }}>
                    {errors["SignUpInviteCode"]}
                  </span>
                </FormControl>
                <br />
                <br />
                <CustomCheckBox
                  checked={checked}
                  handleToggleCheckbox={handleToggleCheckbox}
                />
                <span className="field-error" style={{ color: Errorcolor }}>
                  {errors["SignUpAgree"]}
                </span>

                <br />
                <br />
                <Grid item xs={12} className={classes.justifyContentCenter}>
                  <Button
                    onClick={() => handleSignup()}
                    variant="contained"
                    color="secondary"
                    disableElevation
                    size="large"
                    fullWidth
                    disableFocusRipple
                    className={classes.btnStyle}
                    type="button"
                    style={{ fontSize: "16px" }}
                  >
                    Sign up
                  </Button>
                  <br />
                  {/* <br /> */}
                </Grid>
              </form>
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    setLoginView(VIEW.loginview);
                    resetAlertAndCheckbox();
                    ResetError("signup");
                  }}
                  variant="outlined"
                  color="secondary"
                  disableElevation
                  size="large"
                  fullWidth
                  className={classes.outLinedBtnStyle}
                  disableFocusRipple
                  type="submit"
                  style={{ fontSize: "16px" }}
                >
                  Go back
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const AlertBox = ({ alertVisibility, alertData, isErrorAlert }) => {
  const classes = useStyles();
  if (alertVisibility) {
    return (
      <Grid
        justify="center"
        alignItems="center"
        className={`${
          !isErrorAlert
            ? classes.alertboxcontainers
            : classes.errorAlertBackground
        }`}
      >
        <Grid item xs={12} sm={10} md={12}>
          {alertData}
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
};

// const SSOComponent = props => {
//   const classes = useStyles();
//   const {
//     accountId,
//     handleSSOLogin,
//     handleInputAccountId,
//     setLoginView,
//     alertData,
//     alertVisibility,
//     isErrorAlert,
//     resetAlertAndCheckbox,
//     ResetError
//   } = props;
//   return (
//     <Grid
//       className={classes.loginContainer}
//       // container
//       // justify="center"
//       // alignItems="center"
//       style={{ height: "100%", display: "flex" }}
//     >
//       {/* <Grid item xs={12} style={{ height: "100%" }}>
//         <Grid container justify="center" alignItems="center" style={{ height: "100%" }}> */}
//       <Grid
//         item
//         xs={12}
//         sm={10}
//         md={6}
//         style={{
//           //  height: "100%",
//           marginLeft: "auto",
//           marginRight: "auto"
//         }}
//       >
//         <Grid container justify="flex-start" alignItems="center">
//           <img
//             src={brandimage}
//             alt="brand-logo"
//             //  className={classes.loginLogoImage}
//             style={
//               alertVisibility
//                 ? {
//                     //  width: "164px"
//                     // margin: "3rem auto 1rem auto"
//                     margin: "3rem auto 3rem auto",
//                     top: 0,
//                     position: "absolute",
//                     width: "100%",
//                     height: "auto"
//                   }
//                 : {
//                     margin: "3rem auto",
//                     top: 0,
//                     width: "100%",
//                     height: "auto"
//                   }
//             }
//           />
//           <h4 className={classes.SSOTitle}>{`Single sign on`}</h4>
//           <p className={classes.subheading}>
//             To go to your company&apos;s login page, enter the account id
//           </p>
//           <AlertBox
//             alertVisibility={alertVisibility}
//             alertData={alertData}
//             isErrorAlert={isErrorAlert}
//           />

//           {!alertVisibility ? (
//             <form style={{ width: "100%" }}>
//               <FormControl
//                 fullWidth
//                 focused
//                 className={classes.inputMargin}
//                 // className={classes.margin + " " + classes.customInputStyle}
//                 // variant="outlined"
//               >
//                 {/* <InputLabel htmlFor="accountId">Account Id</InputLabel>
//                     <Input
//                       id="accountId"
//                       onChange={handleInputAccountId}
//                       value={accountId}
//                     /> */}
//                 <TextField
//                   type="text"
//                   variant="standard"
//                   label="Account ID"
//                   fullWidth
//                   InputLabelProps={{
//                     disableAnimation: true,
//                     shrink: false,
//                     classes: {
//                       root: classes.labelRoot
//                     }
//                   }}
//                   className={classes.inputMargin + " " + classes.InputUnderline}
//                   onChange={handleInputAccountId}
//                   value={accountId}
//                   //  color="secondary"
//                 />
//               </FormControl>
//               <br />
//               <br />
//               <Grid item xs={12} className={classes.justifyContentCenter}>
//                 <Button
//                   onClick={() => handleSSOLogin()}
//                   variant="contained"
//                   color="secondary"
//                   disableElevation
//                   size="large"
//                   fullWidth
//                   disableFocusRipple
//                   className={classes.btnStyle}
//                   type="submit"
//                   style={{ fontSize: "16px" }}
//                 >
//                   Log in
//                 </Button>
//                 <br />
//                 {/* <br /> */}
//               </Grid>
//             </form>
//           ) : null}
//           <Grid item xs={12}>
//             <Button
//               onClick={() => {
//                 setLoginView(VIEW.loginview);
//                 resetAlertAndCheckbox();
//                 ResetError("sso");
//               }}
//               variant="outlined"
//               color="secondary"
//               disableElevation
//               size="large"
//               className={classes.outLinedBtnStyle}
//               fullWidth
//               disableFocusRipple
//               type="submit"
//               style={{ fontSize: "16px" }}
//             >
//               Go back
//             </Button>
//           </Grid>
//         </Grid>
//       </Grid>
//       {/* </Grid>
//       </Grid> */}
//     </Grid>
//   );
// };

const ForgotPasswordComponent = props => {
  const classes = useStyles();
  const {
    email,
    username,
    handleResetPassword,
    handleInputEmail,
    handleInputUser,
    setLoginView,
    alertData,
    alertVisibility,
    isErrorAlert,
    resetAlertAndCheckbox,
    errors,
    ResetError
  } = props;

  return (
    <Grid
      className={classes.loginContainer}
      // container
      // justify="center"
      // alignItems="center"
      style={{ height: "100%" }}
    >
      <Grid item xs={12} style={{ height: "100%" }}>
        <Grid
          item
          sm={10}
          md={6}
          lg={6}
          xs={12}
          style={{
            //  height: "100%",
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          <Grid
            className={classes.loginCard}
            // container justify="center" alignItems="center"
            style={{
              marginBottom: "3rem",
              padding: 0,
              border: 0,
              borderRadius: 0
            }}
          >
            <Grid
              className={classes.loginCard}
              //  item xs={12} sm={10} md={8} lg={6}
            >
              {/* <div className={classes.brandingLogo}> */}
              {/* <img src={sdcloudBrandingLogo} /> */}
              <img
                src={brandimage}
                alt="brand-logo"
                //  className={classes.loginLogoImage}
                style={
                  props.alertVisibility
                    ? {
                        //  width: "164px"
                        margin: "3rem auto 3rem auto",
                        // margin: "3rem auto 6.5rem auto",
                        width: "100%",
                        height: "auto"
                      }
                    : {
                        margin: "3rem auto",
                        width: "100%",
                        height: "auto"
                      }
                }
              />
              <h4
                style={{ marginTop: "20px", marginBottom: "20px" }}
                className={classes.loginTitle}
              >{`Recover password`}</h4>
              <AlertBox
                alertVisibility={alertVisibility}
                alertData={alertData}
                isErrorAlert={isErrorAlert}
              />
              <form style={{ width: "100%" }}>
                <FormControl
                  fullWidth
                  focused
                  // margin="none"
                  className={classes.inputMargin}
                  // variant="outlined"
                >
                  {/* <InputLabel htmlFor="username">Username</InputLabel>
                    <Input
                      id="username"
                      onChange={handleInputUser}
                      value={username}
                    /> */}
                  <TextField
                    type="text"
                    variant="standard"
                    label="Username"
                    fullWidth
                    InputLabelProps={{
                      disableAnimation: true,
                      shrink: false,
                      classes: {
                        root: classes.labelRoot
                      }
                    }}
                    className={
                      classes.inputMargin + " " + classes.InputUnderline
                    }
                    onChange={handleInputUser}
                    value={username}
                    // color="secondary"
                  />
                  <span className="field-error" style={{ color: Errorcolor }}>
                    {errors["ForgetUserName"]}
                  </span>
                </FormControl>
                <FormControl
                  fullWidth
                  focused
                  className={classes.inputMargin}
                  // className={classes.margin + " " + classes.customInputStyle}
                >
                  {/* <InputLabel htmlFor="password">Email</InputLabel>
                    <Input
                      id="email"
                      onChange={handleInputEmail}
                      value={email}
                    /> */}
                  <TextField
                    type="email"
                    variant="standard"
                    label="Email"
                    fullWidth
                    InputLabelProps={{
                      disableAnimation: true,
                      shrink: false,
                      classes: {
                        root: classes.labelRoot
                      }
                    }}
                    className={
                      classes.inputMargin + " " + classes.InputUnderline
                    }
                    onChange={handleInputEmail}
                    value={email}
                    // color="secondary"
                  />
                  <span className="field-error" style={{ color: Errorcolor }}>
                    {errors["ForgetEmail"]}
                  </span>
                </FormControl>
                <br />
                <br />
                <Grid item xs={12}>
                  <Button
                    onClick={() => handleResetPassword()}
                    variant="contained"
                    color="secondary"
                    disableElevation
                    size="large"
                    fullWidth
                    className={classes.btnStyle}
                    disableFocusRipple
                    style={{ fontSize: "16px" }}
                  >
                    Reset Password
                  </Button>

                  <br />
                  {/* <br /> */}
                </Grid>
              </form>
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    setLoginView(VIEW.loginview);
                    resetAlertAndCheckbox();
                    ResetError("forgotpassword");
                  }}
                  variant="outlined"
                  color="secondary"
                  disableElevation
                  className={classes.outLinedBtnStyle}
                  size="large"
                  fullWidth
                  disableFocusRipple
                  type="submit"
                  style={{ fontSize: "16px" }}
                >
                  Back to login
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const MfaComponent = props => {
  const classes = useStyles();
  const {
    handleMfaAuthentication,
    handleInputCode,
    mfaCode,
    setLoginView,
    alertData,
    alertVisibility,
    isErrorAlert,
    resetAlertAndCheckbox
  } = props;
  return (
    <Grid
      className={classes.loginContainer}
      container
      justify="center"
      alignItems="center"
      style={{ height: "100%" }}
    >
      <Grid item xs={12}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={10} md={6}>
            <Grid container justify="flex-start" alignItems="center">
              <h4
                className={classes.loginTitle}
              >{`Multi-factor authentication`}</h4>
              <p className={classes.noteStyles}>
                Enter the MFA code to complete login
              </p>
              <AlertBox
                alertVisibility={alertVisibility}
                alertData={alertData}
                isErrorAlert={isErrorAlert}
              />
              <form style={{ width: "100%" }}>
                <FormControl
                  fullWidth
                  focused
                  // className={classes.margin + " " + classes.customInputStyle}
                  // variant="outlined"
                >
                  {/* <InputLabel htmlFor="username">Username</InputLabel>
                    <Input
                      id="username"
                      onChange={handleInputUser}
                      value={username}
                    /> */}
                  <TextField
                    type="text"
                    variant="standard"
                    label="MFA Code"
                    fullWidth
                    InputLabelProps={inputLabelProps}
                    onChange={handleInputCode}
                    value={mfaCode}
                    //  color="secondary"
                  />
                </FormControl>
                <br />
                <br />
                <Grid item xs={12}>
                  <Button
                    onClick={() => handleMfaAuthentication(mfaCode)}
                    variant="contained"
                    color="secondary"
                    disableElevation
                    size="large"
                    className={classes.btnStyle}
                    fullWidth
                    disableFocusRipple
                    style={{ fontSize: "16px" }}
                  >
                    Submit
                  </Button>

                  <br />
                  <br />
                </Grid>
              </form>
              <Grid item xs={12}>
                <Button
                  onClick={() => {
                    localStorage.removeItem("isMfaLoaded");
                    setLoginView(VIEW.loginview);
                    resetAlertAndCheckbox();
                  }}
                  variant="outlined"
                  color="secondary"
                  disableElevation
                  className={classes.outLinedBtnStyle}
                  size="large"
                  fullWidth
                  disableFocusRipple
                  type="submit"
                  style={{ fontSize: "16px" }}
                >
                  Back to login
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default function LoginPage(props) {
  const classes = useStyles();
  // state for checkbox
  let errors = {};
  const [ErrorMessage, setError] = React.useState({});

  const [checked, setChecked] = React.useState(false);
  const [alertVisibility, setAlertVisibility] = React.useState(false);
  const [alertData, setAlertData] = React.useState("");
  const [isErrorAlert, setErrorAlert] = React.useState(false);
  const [loginViewType, setLoginView] = React.useState(VIEW.loginview);
  const [email, setEmail] = React.useState("");
  const [mfaCode, setMfaCode] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [userState, setUserState] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    inviteCode: ""
  });
  const [securityComponentState, setSecurityComponentState] = React.useState([
    {
      id: 0,
      question: "",
      answer: ""
    }
  ]);
  const [accountId, setAccountId] = React.useState("");
  // localStorage.removeItem("isMfaLoaded");

  const handleInputAccountId = e => {
    setAccountId(e.target.value);
  };
  const header = {
    "Content-type": "application/json"
  };

  const dispatch = useDispatch();

  const handleToggleCheckbox = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    if (localStorage.getItem("isMfaLoaded")) {
      handleMfaView();
    }
  }, []);
  const resetErrors = mode => {
    if (!isvalid(mode)) {
      return;
    }
  };

  const handleLogin = async () => {
    if (!isvalid("login")) {
      return;
    }

    // For adding browser and location info of the client
    const browser = window => {
      let currentBrowser = "Not known";
      if (window.navigator.userAgent.indexOf("Chrome") !== -1) {
        currentBrowser = "Google Chrome";
      } else if (window.navigator.userAgent.indexOf("Firefox") !== -1) {
        currentBrowser = "Mozilla Firefox";
      } else if (window.navigator.userAgent.indexOf("MSIE") !== -1) {
        currentBrowser = "Internet Exployer";
      } else if (window.navigator.userAgent.indexOf("Edge") !== -1) {
        currentBrowser = "Edge";
      } else if (window.navigator.userAgent.indexOf("Safari") !== -1) {
        currentBrowser = "Safari";
      } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
        currentBrowser = "Opera";
      } else if (window.navigator.userAgent.indexOf("Opera") !== -1) {
        currentBrowser = "YaBrowser";
      } else {
        //console.log("Others");
      }

      return currentBrowser;
    };

    var res = {};
    const ipapi = require('ipapi.co');
    ipapi.location((data) => {
        data.browser = browser(window);
        res.browser = data.browser;
        res.country = data.country_name;
        res.ip = data.ip;
        localStorage.setItem("client_details", JSON.stringify(res));
    // dispatch(SHOW_LOADER());

    SDCloudBackendAPIWithoutToken()
      .post("/authorize/", {
        username: username,
        password: password
      })
      .then(resp => {
        dispatch(SHOW_LOADER());

        SDCloudBackendAPIWithoutToken()
          .post(Api.login, { username: username, password: password })
          .then(data => {
            let expiryTime = new Date();
            expiryTime = expiryTime.setMinutes(expiryTime.getMinutes() + 15);
            localStorage.setItem("user", data.data.access);
            localStorage.setItem("refreshToken", data.data.refresh);
            localStorage.setItem("token_expire_at", expiryTime);

            const payload = { token: data.data.access };
            dispatch(SAVE_TOKEN({ payload }));
            SDCloudBackendAPIWithToken()
              .get(Api.getUserProfile)
              .then(value => {
                getAdminDashboard(value.data).then(() => {
                  localStorage.setItem("userName", value.data.username);
                  // if (value.data.account_name) {
                  //   localStorage.setItem(
                  //     "customAccountName",
                  //     value.data.account_name
                  //   );
                  // }
                  localStorage.setItem(
                    "available_purposes",
                    JSON.stringify(value.data.available_purposes)
                  );
                  localStorage.setItem(
                    "internal_domains",
                    JSON.stringify(value.data.internal_domain)
                  );
                  localStorage.setItem(
                    "available_zones",
                    JSON.stringify(value.data.available_zones)
                  )
                  if (value.data.mfa_enabled) {
                    localStorage.setItem("isMfaLoaded", true);
                    dispatch(HIDE_LOADER());
                  } else {
                    localStorage.setItem(
                      "profileData",
                      JSON.stringify(value.data)
                    );
                    // making redux call to save profile.
                    const payload = { data: value.data };
                    dispatch(SAVE_PROFILE({ payload }));
                    // dispatch(HIDE_LOADER());
                    // props.history.push("/dashboard");
                  }
                });
              });
          })
          .catch(err => {
            dispatch(HIDE_LOADER());
            const payload = {
              message: err?.response?.data?.message,
              color: "danger",
              error: err
            };
            payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
          });
      })
      .catch(err => {
        const alertData = err.response.data.message;
        setAlertData(alertData);
        setAlertVisibility(true);
        setErrorAlert(true);
        dispatch(HIDE_LOADER());
      });
    });
  };

  const getAdminDashboard = profileData => {
    dispatch(SHOW_LOADER());
    return SDCloudCustomizeAPIWithToken()
      .get(Api.CustomizeAccount)
      .then(response => {
        dispatch(HIDE_LOADER());
        if (response.data.results.length > 0) {
          let filterData = response.data.results.filter(
            f => f.name == profileData.account
          );
          if (filterData.length > 0) {
            dispatch(SHOW_LOADER());
            return SDCloudCustomizeAPIWithToken()
              .get(Api.dashboardCustomize + "" + filterData[0].id + "/")
              .then(response2 => {
                dispatch(HIDE_LOADER());
                if (response2.data) {
                  localStorage.setItem(
                    "isThemeSet",
                    theme.palette.secondary.main
                  );
                  if (response2.data.title !== "") {
                    localStorage.setItem("Title", response2.data.title);
                  }
                  if (response2.data.subtitle !== "") {
                    localStorage.setItem("SubTitle", response2.data.subtitle);
                  }
                  if (response2.data.primary_color_code !== "") {
                    localStorage.setItem(
                      "PrimaryColor",
                      response2.data.primary_color_code
                    );
                  }
                  if (response2.data.secondary_color_code !== "") {
                    localStorage.setItem(
                      "SecondaryColor",
                      response2.data.secondary_color_code
                    );
                  }
                  if (response2.data.button_color_code !== "") {
                    localStorage.setItem(
                      "ButtonColor",
                      response2.data.button_color_code
                    );
                  }
                  if (response2.data.panel_color_code !== "") {
                    localStorage.setItem(
                      "PanelColor",
                      response2.data.panel_color_code
                    );
                  }
                  if (response2.data.branding_logo !== "") {
                    localStorage.setItem(
                      "BrandLogo",
                      response2.data.branding_logo
                    );
                  }
                  if (response2.data.branding_logo_inv !== "") {
                    localStorage.setItem(
                      "BrandLogoInv",
                      response2.data.branding_logo_inv
                    );
                  }
                  if (response2.data.branding_logo_small !== "") {
                    localStorage.setItem(
                      "BrandLogoSmall",
                      response2.data.branding_logo_small
                    );
                  }
                  if (
                    response2.data.font_family &&
                    response2.data.font_family !== ""
                  ) {
                    localStorage.setItem(
                      "FontFamily",
                      response2.data.font_family
                    );
                  }
                  window.history.pushState(
                    { firstLogin: true },
                    "",
                    "/dashboard"
                  );
                  window.location.reload();
                }
              })
              .catch(err2 => {
                dispatch(HIDE_LOADER());
                //console.log(err2.response);
              });
          }
        }
      })
      .catch(err => {
        dispatch(HIDE_LOADER());
        //console.log(err.response);
      });
  };

  const handleGoBack = () => {
    setLoginView(VIEW.loginview);
  };

  const handleSignup = () => {
    if (!isvalid("signup")) {
      return false;
    }
    SDCloudBackendAPIWithoutToken()
      .post(
        Api.addNewUser,
        {
          first_name: userState.firstName,
          last_name: userState.lastName,
          email: userState.email,
          invitation_code: userState.inviteCode,
          base_url: `https://${window.location.host}/auth/`,
        },
        { headers: header }
      )
      .then(res => {
        const alertData = "user created successfully.";
        setAlertData(alertData);
        setAlertVisibility(true);
        setErrorAlert(false);
      })
      .catch(e => {
        const alertData = e.response.data.message;
        setAlertData(alertData);
        setAlertVisibility(true);
        setErrorAlert(true);
        //console.log(e);
      });
  };

  const isvalid = mode => {
    errors = {};
    setError(errors);
    let formvalid = true;
    if (mode === "login") {
      if (!String(username).trim()) {
        errors["UserName"] = "Username cannot be empty";
        setError(errors);
        formvalid = false;
      }
      if (!checked) {
        errors["LoginAgree"] = "Please agree to terms and conditions.";
        setError(errors);
        formvalid = false;
      }
      let chkName = /^[a-zA-Z0-9]+([._]?[a-zA-Z0-9]+)*$/;
      if (!chkName.test(String(username))) {
        errors["UserName"] = "Only alphanumeric characters and underscores are allowed";
        setError(errors);
        formvalid = false;
      }

      if (!String(password).trim()) {
        errors["Password"] = "Password cannot be empty";
        setError(errors);
        formvalid = false;
      }
      return formvalid;
    } else if (mode === "forgotpassword") {
      if (!String(username).trim()) {
        errors["ForgetUserName"] = "Username cannot be empty";
        setError(errors);
        formvalid = false;
      }
      if (!String(email).trim()) {
        errors["ForgetEmail"] = "Email cannot be empty";
        setError(errors);
        formvalid = false;
      }
      return formvalid;
    } else if (mode === "mfa") {
      if (!String(mfaCode).trim()) {
        alert("Please fill a valid MFA code");
        return false;
      }
    } else if (mode === "signup") {
      if (!String(userState.firstName).trim()) {
        errors["SignUpFirstName"] = "First name cannot be empty";
        // alert("First name cannot be empty");
        setError(errors);
        formvalid = false;
      }
      if (!String(userState.lastName).trim()) {
        errors["SignUpLastName"] = "Last name cannot be empty";
        // alert("First name cannot be empty");
        setError(errors);
        formvalid = false;
      }
      if (!String(userState.email).trim()) {
        errors["SignUpEmail"] = "Email cannot be empty";
        setError(errors);
        formvalid = false;
      }
      if (!String(userState.inviteCode).trim()) {
        errors["SignUpInviteCode"] = "Invite code cannot be empty";
        setError(errors);
        formvalid = false;
      }
      if (!checked) {
        errors["SignUpAgree"] = "Please agree to terms and conditions.";
        setError(errors);
        formvalid = false;
      }
      return formvalid;
    // } else if (mode === "sso") {
    //   setError(errors);
    //   formvalid = false;
    // }
    }
    return true;
  };

  // const handleSSOLogin = () => {
  //   alert(
  //     `Dear ${accountId}, feature coming soon. redirection to client page.`
  //   );
  // };

  // const handleSSO = () => {
  //   resetAlertAndCheckbox();
  //   setLoginView(VIEW.ssoview);
  // };
  const handleInputUser = e => {
    setUsername(e.target.value);
  };
  const handleInputEmail = e => {
    setEmail(e.target.value);
  };
  const handleInputPassword = e => {
    setPassword(e.target.value);
  };

  const handleResetPassword = async () => {
    if (!isvalid("forgotpassword")) {
      return false;
    }
    const res = await forgetPassword({
      username: username,
      email: email,
      base_url: `https://${window.location.host}/auth/`
    });
    if (res.success) {
      const alertData =
        "Your password recovery settings have been mailed to your registered email, please check your email account for recovery options.";
      setAlertData(alertData);
      setAlertVisibility(true);
      setErrorAlert(false);
      // uncomment for notificationa alert
      // const payload = {
      //   message: `password reset mail sent to ${email}.`,
      //   color: "success"
      // };
      // dispatch(SHOW_NOTIFICATION({ payload }));
    } else {
      setAlertData(res.message);
      setAlertVisibility(true);
      setErrorAlert(true);
      // uncomment for notificationa alert
      // const payload = {
      //   message: err?.response?.data?.message || "unknown error occured",
      //   color: "danger",
      //   error: err
      // };
      // dispatch(SHOW_NOTIFICATION({ payload }));
    }
  };

  const handleInputCode = e => {
    setMfaCode(e.target.value);
  };

  const handleMfaView = () => {
    if (localStorage.getItem("mfaError")) {
      setAlertData("Authentication failed");
      setAlertVisibility(true);
      setErrorAlert(true);
      localStorage.removeItem("mfaError");
    }

    resetAlertAndCheckbox();
    setLoginView(VIEW.mfaview);
    if (localStorage.getItem("user") && localStorage.getItem("refreshToken")) {
      localStorage.setItem("accessToken", localStorage.getItem("user"));
      localStorage.setItem(
        "userRefreshToken",
        localStorage.getItem("refreshToken")
      );
      localStorage.setItem(
        "tokenExpire",
        localStorage.getItem("token_expire_at")
      );

      localStorage.removeItem("user");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("token_expire_at");
      localStorage.removeItem("userName");
      localStorage.removeItem("client_details");
      localStorage.removeItem("available_purposes");
      localStorage.removeItem("internal_domain");
      localStorage.removeItem("deal_ids");
      localStorage.removeItem("customAccountName");
      localStorage.removeItem("available_zones");
      localStorage.removeItem("sessionId1")
      localStorage.removeItem("sessionId2")
      for (let i = 0; i < localStorage.length; i++) {
        let key = localStorage.key(i);
        if (key.startsWith('designer_logs_')) {
          localStorage.removeItem(key);
        }
      }
    }
  };

  const handleMfaAuthentication = code => {
    if (!isvalid("mfa")) {
      return false;
    }

    dispatch(SHOW_LOADER());
    Axios.post(`${Api.activateMfaConfig}${code}/`, null, {
      headers: {
        "Content-type": "application/json",
        Authorization: `Token ${localStorage.getItem("userToken")}`
      }
    })
      .then(resp => {
        let expiryTime = new Date();
        expiryTime = expiryTime.setMinutes(expiryTime.getMinutes() + 15);
        // Add back login authentication tokens
        localStorage.setItem(
          "refreshToken",
          localStorage.getItem("userRefreshToken")
        );
        localStorage.setItem("token_expire_at", expiryTime);
        localStorage.setItem("user", localStorage.getItem("accessToken"));

        // Remove step 1 authntication tokens
        localStorage.removeItem("userRefreshToken");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("tokenExpire");

        SDCloudBackendAPIWithToken()
          .get(Api.getUserProfile)
          .then(value => {
            localStorage.removeItem("isMfaLoaded");
            localStorage.setItem("userName", value.data.username);
            localStorage.setItem("profileData", JSON.stringify(value.data));

            // making redux call to save profile.
            const payload = { data: value.data };
            dispatch(SAVE_PROFILE({ payload }));
            dispatch(HIDE_LOADER());
            props.history.push("/dashboard");
          })
          .catch(err => {
            dispatch(HIDE_LOADER());
            const payload = {
              message: err?.response?.data?.message,
              color: "danger",
              error: err
            };
            payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
          });
      })
      .catch(err => {
        // const alertData = err.response.data.message;
        localStorage.setItem("mfaError", true);
        dispatch(HIDE_LOADER());
      });
  };

  const resetAlertAndCheckbox = () => {
    if (checked) {
      setChecked(false);
    }
    if (alertVisibility) {
      setAlertVisibility(false);
    }
    if (alertData !== "") {
      setAlertData("");
    }
  };

  const getComponent = () => {
    // //console.log(ErrorMessage);
    if (loginViewType === VIEW.loginview) {
      return (
        <LoginComponent
          handleLogin={handleLogin}
          handleInputUser={handleInputUser}
          handleInputPassword={handleInputPassword}
          handleToggleCheckbox={handleToggleCheckbox}
          username={username}
          password={password}
          checked={checked}
          setLoginView={setLoginView}
          // handleSSO={handleSSO}
          alertData={alertData}
          alertVisibility={alertVisibility}
          isErrorAlert={isErrorAlert}
          errors={ErrorMessage}
          ResetError={resetErrors}
          resetAlertAndCheckbox={resetAlertAndCheckbox}
        />
      );
    } else if (loginViewType === VIEW.forgetpassword) {
      return (
        <ForgotPasswordComponent
          handleInputUser={handleInputUser}
          handleInputEmail={handleInputEmail}
          username={username}
          email={email}
          handleResetPassword={handleResetPassword}
          setLoginView={setLoginView}
          alertData={alertData}
          alertVisibility={alertVisibility}
          isErrorAlert={isErrorAlert}
          setAlertVisibility={setAlertVisibility}
          resetAlertAndCheckbox={resetAlertAndCheckbox}
          errors={ErrorMessage}
          ResetError={resetErrors}
        />
      );
    } else if (loginViewType === VIEW.mfaview) {
      return (
        <MfaComponent
          handleMfaAuthentication={handleMfaAuthentication}
          handleInputCode={handleInputCode}
          mfaCode={mfaCode}
          setLoginView={setLoginView}
          alertData={alertData}
          alertVisibility={alertVisibility}
          isErrorAlert={isErrorAlert}
          setAlertVisibility={setAlertVisibility}
          resetAlertAndCheckbox={resetAlertAndCheckbox}
          errors={ErrorMessage}
          ResetError={resetErrors}
        />
      );
    } else if (loginViewType === VIEW.signupview) {
      return (
        <SignupComponent
          setAlertVisibility={setAlertVisibility}
          setLoginView={setLoginView}
          handleSignup={handleSignup}
          userState={userState}
          setUserState={setUserState}
          handleToggleCheckbox={handleToggleCheckbox}
          resetAlertAndCheckbox={resetAlertAndCheckbox}
          alertData={alertData}
          alertVisibility={alertVisibility}
          isErrorAlert={isErrorAlert}
          errors={ErrorMessage}
          ResetError={resetErrors}
        />
      );
    } else if (loginViewType === VIEW.securityquestion) {
      return (
        <SecurityQuestionComponent
          securityComponentState={securityComponentState}
          setSecurityComponentState={setSecurityComponentState}
        />
      );
    // } else if (loginViewType === VIEW.ssoview) {
    //   return (
    //     <SSOComponent
    //       accountId={accountId}
    //       handleSSOLogin={handleSSOLogin}
    //       handleInputAccountId={handleInputAccountId}
    //       setLoginView={setLoginView}
    //       alertData={alertData}
    //       alertVisibility={alertVisibility}
    //       isErrorAlert={isErrorAlert}
    //       resetAlertAndCheckbox={resetAlertAndCheckbox}
    //       ResetError={resetErrors}
    //     />
    //   );
    // }
    }
  };
  return (
    <Grid
      container
      className={classes.container}
      justify="flex-start"
      alignItems="flex-start"
      direction="row"
      // style={{overflowY:"hidden"}}
    >
      <Grid
        item
        xs={12}
        sm={5}
        md={5}
        pl={0}
        pr={0}
        className={classes.loginGridItemContainer}
      >
        {getComponent()}
      </Grid>
      <Grid
        item
        xs={12}
        sm={7}
        md={7}
        className={classes.advertisingGridItemContainer}
      >
        <AdvertisingComponent />
      </Grid>
    </Grid>
  );
}
