import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";

// Material UI Components
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: "0 20px",
    backgroundColor: "#ffffff",
  },
  appBar: {
    backgroundColor: "#ffffff !important",
    color: theme.palette.grey["600"],
    boxShadow: "none",
  },
  topologyImage: {
    fontFamily: "Roboto",
    color: "black",
    fontWeight:"normal",
    width: "100%",
    "& img": {
      maxWidth: "100%",
    },
    "& h4": {
      fontWeight: "bolder",
    },
    "& h3":{
      fontFamily: theme.palette.fontList.selectedFont
    },
    paddingLeft:"25px",
    paddingRight: "25px",
    paddingBottom:"30px",
  },
  tabPanel: {
    backgroundColor: "#ffffff",
    "& div": {
      width: "100%",
      margin: "0",
      paddingTop: "15px",
    },
  },
  ipDetailAccess: {
    width: "100%",
    "& table": {
      width: "100%",
      "& thead": {
        textAlign: "left",
      },
    },
  },
  customTabRoot: {
    color: `#000`,
    "& .Mui-selected .MuiTab-wrapper": {
      color: `${theme.palette.secondary.main}`
    },
    "& .MuiTab-wrapper": {
      fontFamily: theme.palette.fontList.selectedFont
    }
  },
  buttonStyle2: {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    float:"right",
    marginTop: "20px",
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function Overview(props) {
  const [value, setValue] = useState(0);

  const classes = useStyles();

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleNext = () => {
    if (value === 0) {
      if (props.categoriesData && props.categoriesData[0].length > 0) {
        const firstCategoryName = props.categoriesData[0][0].sandboxes_category_name;
        props.changeActiveTab(firstCategoryName);
      } else if (props.sandboxCheatsheets && props.sandboxCheatsheets.length > 0){
        props.changeActiveTab("Cheat sheets");
      } else {
        props.changeActiveTab("Access devices");
      }
    }
  };

  const renderOverviewContent = () => {
    let content = props.data.overview;

    return (
      <>
        <ReactMarkdown
          source={content}
          skipHtml={false}
          className={classes.topologyImage}
        />
      </>
    );
  };

  if (!props.data.overview) return null;

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          classes={{
            root: classes.customTabRoot
          }}
        >
          <Tab label="Overview" {...a11yProps(0)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} className={classes.tabPanel}>
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ flexDirection: "row", flexWrap: "wrap" }}
        >
          {renderOverviewContent()}
        </Grid>
      </TabPanel>
      <Button
            onClick={handleNext}
            className={classes.buttonStyle2}
          >
            Next
          </Button>
    </div>
  );
}

export default Overview;