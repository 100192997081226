import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import {
  FormControl,
  Grid,
  Input,
  InputLabel,
  TextField,
  IconButton,
  InputAdornment,
  FormHelperText,
} from "@material-ui/core";
import Select from "@material-ui/core/Select/Select";
import AdvertisingComponent from "../../../components/Login/AdvertisingComponent";
import MenuItem from "@material-ui/core/MenuItem";
import "./recoverPassword.scss";
import Button from "@material-ui/core/Button";
import { resetPassword } from "../../../apis/APIServices";
import { useDispatch } from "react-redux";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
import { Api } from "config/api";
import { SDCloudBackendAPIWithoutToken } from "apis/backendAPI";
import { makeStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import {
  PASSWORD_FORM_LABELS,
  strongRegex,
  mediumRegex,
} from "../../../views/MyProfile/utils/constants/constants_profile";

const customStyle = (theme) => ({
  buttStyle: {
    background: theme.palette.buttonColor.main,
    "&:hover": {
      background: theme.palette.buttonColor.main,
    },
  },
  advertisingGridItemContainer: {
    height: "100vh",
    display: "flex",
    textAlign: "center",
  },
  leftcontainer: {
    height: "100% !important",
  },
  errorText: {
    fontSize: "12px",
    color: theme.palette.error.main,
  },
});
const useStyles = makeStyles(customStyle);
const SECURITY_QUESTIONS = [
  "What primary school did you attend?",
  "In what town or city was your first full time job?",
  "What is your mother's maiden name?",
  "What was your childhood nickname?",
  "What was your dream job as a child?",
  "What is your pet's name?",
  "What is the name of the company of your first job?",
  "What is the name of your favorite childhood friend?",
];

const AlertBox = ({ alertVisibility, alertData, isErrorAlert }) => {
  if (alertVisibility) {
    return (
      <Grid
        justify="center"
        alignItems="center"
        className={`${
          !isErrorAlert ? "alertboxcontainer" : " errorAlertBackground"
        }`}
      >
        <Grid item xs={12} sm={10} md={12}>
          {alertData}
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
};

const ResetPassword = (props) => {
  const classes = useStyles();
  const { queryDetail } = props;
  const [userInput, setUserInput] = React.useState({});
  const [alertVisibility, setAlertVisibility] = React.useState(false);
  const [isErrorAlert, setErrorAlert] = React.useState(true);
  const [alertData, setAlertData] = React.useState("");
  const [newPassword, setnewPassword] = React.useState(false);
  const [confirmPassword, setconfirmPassword] = React.useState(false);
  const [labelPseudoClass, setLabelPseudoClass] = useState(
    "input-label-pseudo"
  );
  const [inputPseudoClass, setInputPseudoClass] = useState("input-pseudo");
  const [isPasswordStrengthMsg, setIsPasswordStrengthMsg] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setErrorAlert(true);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (strongRegex.test(value)) {
      if (name == "password") {
        setIsPasswordStrengthMsg(false);
      }
      setLabelPseudoClass("strong-password-label-pseudo");
      setInputPseudoClass("strong-password-pseudo");
    } else if (mediumRegex.test(value)) {
      if (name == "password") {
        setIsPasswordStrengthMsg(true);
      }
      setLabelPseudoClass("medium-password-label-pseudo");
      setInputPseudoClass("medium-password-pseudo");
    } else {
      if (name == "password") {
        setIsPasswordStrengthMsg(true);
      }
      setLabelPseudoClass("input-label-pseudo");
    }
  };

  const reset_Password = async () => {
    if (userInput.password === userInput.confirmPassword) {
      setAlertVisibility(false);
      setAlertData("");
      const res = await resetPassword({
        username: queryDetail.username,
        email: queryDetail.email,
        reset_id: queryDetail.id,
        access_token: "",
        password: userInput.password,
      });
      if (res.success) {
        // const alertData =
        //   "Password updated Successfully.";

        const payload = {
          message: "Password updated successfully.",
          color: "success",
        };
        dispatch(SHOW_NOTIFICATION({ payload }));

        history.push("/auth/login-page");
      } else {
        const alertData = res.message;
        setAlertData(alertData);
        setErrorAlert(true);
        setAlertVisibility(true);
      }
    } else {
      const alertData = "New password and confirm password do not match";
      setAlertData(alertData);
      setAlertVisibility(true);
      setErrorAlert(true);
    }
  };
  return (
    <Grid
      className="loginContainer"
      container
      justify="center"
      alignItems="center"
      style={{ height: "100%" }}
    >
      <Grid item xs={12}>
        <Grid container justify="center" alignItems="center">
          <Grid item xs={12} sm={10} md={6}>
            <h4 className="loginTitle">{`Reset password`}</h4>
            <AlertBox
              alertVisibility={alertVisibility}
              alertData={alertData}
              isErrorAlert={isErrorAlert}
            />
            <form style={{ width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel htmlFor="password" className="label-title">
                  {PASSWORD_FORM_LABELS.password}
                </InputLabel>
                <Input
                  type={newPassword ? "text" : "password"}
                  id="password"
                  name="password"
                  onChange={handleChange}
                  value={userInput.password}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setnewPassword(!newPassword)}
                        onMouseDown={(event) => event.preventDefault()}
                      >
                        {newPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {isPasswordStrengthMsg && (
                  <FormHelperText className={classes.errorText}>
                    Weak Password. Must be at least 8 characters, 1 number, 1
                    lowercase, 1 uppercase letter , 1 special character from
                    @#$%&._!*-
                  </FormHelperText>
                )}
              </FormControl>
              <br />
              <br />
              <FormControl fullWidth>
                <InputLabel htmlFor="confirm-password" className="label-title">
                  Confirm new password
                </InputLabel>
                <Input
                  type={confirmPassword ? "text" : "password"}
                  id="confirm-password"
                  name="confirmPassword"
                  onChange={handleChange}
                  value={userInput.confirmPassword}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setconfirmPassword(!confirmPassword)}
                        onMouseDown={(event) => event.preventDefault()}
                      >
                        {confirmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {isPasswordStrengthMsg ? (
                <Button
                  disabled
                  variant="contained"
                  color="secondary"
                  disableElevation
                  size="large"
                  fullWidth
                  disableFocusRipple
                  className={`submit-button ${classes.buttStyle}`}
                >
                  Reset password
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  disableElevation
                  size="large"
                  fullWidth
                  disableFocusRipple
                  className={`submit-button ${classes.buttStyle}`}
                  onClick={reset_Password}
                >
                  Reset password
                </Button>
              )}
            </form>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const SecurityQuestions = (props) => {
  const classes = useStyles();

  const [securityQuestion, setSecurityQuestion] = React.useState(
    SECURITY_QUESTIONS[2]
  );
  const [resetpassword, setResetpassword] = useState(false);
  const [userAnswer, setUserAnswer] = React.useState("");
  const [queryDetail, setQueryDetail] = useState("");
  const [isSecurityQuestion, setIsSecurityQuestion] = useState(false);
  const [alertVisibility, setAlertVisibility] = React.useState(false);
  const [alertData, setAlertData] = React.useState("");
  const [isErrorAlert, setErrorAlert] = React.useState(false);

  useEffect(() => {
    const parsed = queryString.parse(props.location.search);
    //console.log(parsed);
    var payload = { reset_id: parsed.id };
    SDCloudBackendAPIWithoutToken()
      .post(Api.passwordLink, payload)
      .then((resp) => resp.data)
      .then((data) => {
        let user_name = data.response.username;
        setQueryDetail(data.response);
        SDCloudBackendAPIWithoutToken()
          .post(Api.getSecurityQs, { username: user_name })
          .then((res) => res.data)
          .then((data) => {
            let question = data.question;
            if (SECURITY_QUESTIONS.includes(question)) {
              setSecurityQuestion(question);
            }
            setIsSecurityQuestion(true);
          })
          .catch((err) => {
            setIsSecurityQuestion(false);
          });
      })
      .catch((err) => {
        let message = err.response.data.message;
      });
    // setQueryDetail(data.response);
  }, []);

  const handleDropDown = (event) => {
    setSecurityQuestion(event.target.value);
  };

  const handleAnswerInputText = (event) => {
    setUserAnswer(event.target.value);
  };

  const handleUpdate = () => {
    if (isSecurityQuestion) {
      let payload = {
        answer: userAnswer,
        username: queryDetail["username"],
      };

      SDCloudBackendAPIWithoutToken()
        .post(Api.validateSecurityQs, payload)
        .then((res) => res.data)
        .then((data) => {
          setResetpassword(true);
        })
        .catch((err) => {
          if (
            err?.response?.data?.message ==
            "Failed to validate the security question and answer"
          ) {
            const supportInfo = (
              <p>
                Something went wrong, visit our{" "}
                <a
                  href="https://criterion.zendesk.com/hc/en-us"
                  target="_blank"
                >
                  help center
                </a>{" "}
                for resources and support
              </p>
            );

            setAlertData(supportInfo);
          } else {
            setAlertData(err?.response?.data?.message);
          }
          setAlertVisibility(true);
          setErrorAlert(true);
        });
    } else {
      let payload = {
        answer: userAnswer,
        question: securityQuestion,
        username: queryDetail["username"],
      };

      SDCloudBackendAPIWithoutToken()
        .post(Api.storeSecurityQs, payload)
        .then((res) => res.data)
        .then((data) => {
          setResetpassword(true);
        })
        .catch((err) => {
          if (
            err?.response?.data?.message ==
            "Failed to validate the security question and answer"
          ) {
            const supportInfo = (
              <p>
                Something went wrong, visit our{" "}
                <a
                  href="https://criterion.zendesk.com/hc/en-us"
                  target="_blank"
                >
                  help center
                </a>{" "}
                for resources and support
              </p>
            );

            setAlertData(supportInfo);
          } else {
            setAlertData(err?.response?.data?.message);
          }
          setAlertVisibility(true);
          setErrorAlert(true);
        });
    }
  };

  return (
    <>
      <Grid
        container
        className="reset-password"
        justify="flex-start"
        alignItems="flex-start"
        direction="row"
      >
        <Grid
          item
          xs={12}
          sm={5}
          md={5}
          className={`loginGridItemContainer ${classes.leftcontainer}`}
        >
          {resetpassword ? (
            <ResetPassword queryDetail={queryDetail} />
          ) : (
            <Grid
              className="loginContainer"
              container
              justify="center"
              alignItems="center"
              style={{ height: "100%" }}
            >
              <Grid item xs={12}>
                <Grid container justify="center" alignItems="center">
                  <Grid item xs={12} sm={10} md={6}>
                    <h4 className="loginTitle">
                      {isSecurityQuestion
                        ? "Answer the security question"
                        : `Please update Security Questions`}
                    </h4>
                    <form style={{ width: "100%" }}>
                      {isSecurityQuestion ? (
                        <FormControl fullWidth>
                          <TextField
                            floatingLabelText="Question"
                            value={securityQuestion}
                          />
                        </FormControl>
                      ) : (
                        <FormControl fullWidth>
                          <InputLabel
                            htmlFor="questionSelect"
                            className="label-title"
                          >
                            Question
                          </InputLabel>
                          <Select
                            value={securityQuestion}
                            // disableUnderline
                            MenuProps={{
                              className: "question-options",
                            }}
                            onChange={(event) => handleDropDown(event)}
                            inputProps={{
                              name: "questionSelect",
                            }}
                          >
                            {SECURITY_QUESTIONS.map((menuItemVal, index) => {
                              return (
                                <MenuItem key={index} value={menuItemVal}>
                                  {menuItemVal}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      )}
                      <br />
                      <br />
                      <FormControl fullWidth>
                        <InputLabel
                          htmlFor="securityanswer"
                          className="label-title"
                        >
                          Your answer
                        </InputLabel>
                        <Input
                          id="securityanswer"
                          onChange={handleAnswerInputText}
                          value={userAnswer}
                        />
                      </FormControl>
                      <Button
                        variant="contained"
                        color="secondary"
                        disableElevation
                        size="large"
                        fullWidth
                        disableFocusRipple
                        type="button"
                        className={`submit-button ${classes.buttStyle}`}
                        onClick={handleUpdate}
                      >
                        {isSecurityQuestion
                          ? "Submit"
                          : "Update Security Question"}
                      </Button>
                    </form>

                    {/* Notification */}
                    <AlertBox
                      alertVisibility={alertVisibility}
                      alertData={alertData}
                      isErrorAlert={isErrorAlert}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={7}
          md={7}
          className={classes.advertisingGridItemContainer}
        >
          <AdvertisingComponent onlyLogo />
        </Grid>
      </Grid>
    </>
  );
};

export default SecurityQuestions;
