// React specific library imports
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Loader from "react-loader-spinner";
import { Api } from "config/api";
import { PropTypes } from "prop-types";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui imports
import { withStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import { Card } from "@material-ui/core";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import "./Profile.scss";
import { PRIMARY_COLOR } from "global_constants/app_constants";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import theme from "../../../../theme";
const customStyles = theme => ({
  containerStyles: {
    padding: "0.9375rem 70px"
  },
  inputStyles: {
    margin: "10px auto 20px auto",
    [theme.breakpoints.down("sm")]: {
      margin: "15px"
    }
  },
  inputLabelStyles: {
    marginBottom: "35px",
    position: "relative"
  },
  cardStyles: {
    color: "rgba(0, 0, 0, 0.87)",
    width: "100%",
    border: "0",
    display: "flex",
    position: "relative",
    fontSize: ".875rem",
    minWidth: "0",
    wordWrap: "break-word",
    background: "#FFF",
    marginTop: "0px",
    borderRadius: "6px",
    marginBottom: "30px",
    flexDirection: "column",
    overflow: "auto"
  },
  cardHeaderStyles: {
    padding: "0.9375rem 65px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative"
  },
  cardBodyStyles: {
    padding: "0.9375rem 65px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative"
  },
  cardFooterStyles: {
    padding: "0.9375rem 65px",
    margin: "35px 0 35px 0",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative"
  },
  buttonStyles: {
    background: PRIMARY_COLOR,
    color: "white",
    width: "100%",
    minHeight: "20px",
    paddingTop: "0.9375rem",
    paddingBottom: "0.9375rem"
  },
  subHeadingsStyles: {
    margin: "25px 0 10px 25px",
    color: "#8F8A8A",
    fontSize: "16px"
  },
  smallTopHeadingStyles: {
    fontSize: "14px",
    fontWeight: "normal",
    marginBottom: "25px"
  }
});
class LogDetailsPagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logRecords: [],
      currentPage: 1,
      perPage: 10,
      paginateLogDetails: false,
      pageEnd: false,
      searchQuery: " ",
    };
  }
  handleSearch = (event) => {
    const { value } = event.target;
    const trimmedValue = value.trim();
    this.setState({ searchQuery: trimmedValue.toLowerCase() });
    this.LogDetails(trimmedValue.toLowerCase());
  };
  expandLogDetails = logAction => {
    this.setState({ paginateLogDetails: true });
    let appliedFilters = [];
    Object.keys(this.props.unselectFilters).forEach(key => {
      return this.props.unselectFilters[key].length
        ? appliedFilters.push(
            `${key}:"${this.props.unselectFilters[key].join(",")}"`
          ) // explode array and create string
        : null;
    });
    const data = appliedFilters.length
      ? {
          query: `{ logRecords(first:${this.state.currentPage},last:${this.state
            .currentPage + 10}, name:"${localStorage.getItem(
            "userName"
          )}", action:"${logAction}", ${appliedFilters.join(
            ", "
          )}){ createdAt, action, privilege, requestUser, level, msg, ip, location, browser, offeringType }}`
        }
      : {
          query: `{ logRecords(first:${this.state.currentPage},last:${this.state
            .currentPage + 10}, name:"${
            this.props.profile.profileObj.username
          }", action:"${logAction}"){ createdAt, action, privilege, requestUser, level, msg, ip, location, browser, offeringType }}`
        };
    SDCloudBackendAPIWithToken()
      .post(Api.logRecord, data)
      .then(res => {
        let nextRecord = res.data["data"]["logRecords"];
        let nextDetailLogs = [];
        nextRecord.forEach(item => {
          nextDetailLogs.push(item);
        });
        if (nextRecord.length < 10) {
          this.setState(prevState => {
            return {
              logRecords: [...prevState.logRecords, ...nextDetailLogs],
              pageEnd: true,
              paginateLogDetails: false
            };
          });
        } else {
          this.setState(prevState => {
            return {
              logRecords: [...prevState.logRecords, ...nextDetailLogs],
              paginateLogDetails: false,
              currentPage: prevState.currentPage + 11
            };
          });
        }
      })
      .catch(err => {});
  };
  LogDetails = (searchUser) => {
    this.setState({ paginateLogDetails: true });
    const data = {
      query: `{ logRecords(name:"${this.props.profile.profileObj.username}",filterUser:"${searchUser}",action:"${this.props.logAction.logType}"){ createdAt, action, privilege, requestUser, level, msg, ip, location, browser, offeringType }}`
    }
    SDCloudBackendAPIWithToken()
      .post(Api.logRecord, data)
      .then(res => {
        let nextRecord = res.data["data"]["logRecords"];
        let nextDetailLogs = [];
        nextRecord.forEach(item => {
          nextDetailLogs.push(item);
        });
        this.setState({ logRecords: nextRecord });
        if (res.status === 200) {
          this.setState({ paginateLogDetails: false });
          this.setState({ pageEnd: true })
          };
        }
      )
      .catch(err => {})
    
  };

  componentDidMount() {
    this.expandLogDetails(this.props.logAction.logType);
  }

  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.cardStyles} elevation={0}>
        <CardHeader className={classes.cardHeaderStyles}>
          <GridContainer>
            <GridItem className={classes.smallTopHeadingStyles}>
              <p>Log Details</p>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem>
              <h3 style={{ fontSize: "24px" }}>
                <b>{this.props.logAction.logType}</b>
              </h3>
              {this.props?.notes ? (
                <p className="noteStyles">Note : {this.props?.notes}</p>
              ) : null}
            </GridItem>
            <GridItem>
              <p className={classes.subHeadingsStyles}>
                {this.props.logAction.mostRecent}
              </p>
            </GridItem>
            <GridItem>
              <p className={classes.subHeadingsStyles}>
                {this.props.logAction.leastRecent}
              </p>
            </GridItem>
          </GridContainer>
          {JSON.parse(localStorage.getItem("profileData")).privilege === "admin" ? (
          <GridItem style={{width:"30%",paddingTop:20,paddingBottom:-15,marginLeft: "-15px"}}>
            <TextField
            type="search"
            label="User Name"
            variant="filled"
            value={this.state.searchQuery}
            fullWidth
            // style={{width:"40%",paddingLeft:"160px"}}
            onChange={this.handleSearch}
            InputProps={{
              className: classes.searchBar,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    type="submit"
                    className={classes.iconButton}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          </GridItem>
          ) : null } 
        </CardHeader>
        <CardBody className={classes.cardBodyStyles}>
          <GridContainer>
            <GridItem xs={12}>
              <GridContainer
                className="row-expand"
                style={{
                  borderBottom: "1px solid #D8D8D8",
                  marginBottom: "10px"
                }}
              >
                <GridItem xs={12} sm={2} style={{ marginBottom: "25px" }}>
                  Timestamp
                </GridItem>
                <GridItem xs={12} sm={1}>
                  Level
                </GridItem>
                {this.state.logRecords.some(item => item.msg.includes("template_name")) ? (
                  <GridItem xs={12} sm={2}>
                    Sku name
                  </GridItem>
                ) : null }
                <GridItem xs={12} sm={3}>
                  Message
                </GridItem>
                {this.state.logRecords.some((item) => item.offeringType) ? (
                  <GridItem xs={12} sm={2}>
                    offeringType
                  </GridItem>
                ) : null}
                {this.state.logRecords.some((item) => !item.offeringType && item.ip) ? (
                  <GridItem xs={12} sm={3}>
                    Ip
                  </GridItem>
                ) : null }
                <GridItem xs={12} sm={2}>
                  Username
                </GridItem>
              </GridContainer>
              {this.state.logRecords.map((item, index) => (
                <GridContainer key={index} style={{ marginBottom: "10px" }}>
                  <GridItem xs={12} sm={2}>
                    {new Date(item.createdAt).toLocaleString()}
                  </GridItem>
                  <GridItem xs={12} sm={1}>
                    {item.level}
                  </GridItem>
                  {item.msg.includes("template_name") ? (
                    <GridItem xs={12} sm={2}>
                    {item.msg.replace(/.*'template_name':\s*'([^']*)'.*/, '$1')}
                    </GridItem>
                  ) : null}
                  <GridItem xs={12} sm={3}>
                    {item.msg.replace(/.*'message':\s*'([^']*)'.*/, '$1')}
                  </GridItem>
                  {item.offeringType ? (
                    <GridItem xs={12} sm={2}>
                      {item.offeringType}
                    </GridItem>
                  ) : null}
                  { (item.ip && !item.offeringType) ? (
                    <GridItem xs={12} sm={3}>
                      {item.ip}
                    </GridItem>
                  ) : null}
                  <GridItem xs={12} sm={2}>
                    {item.requestUser}
                  </GridItem>
                </GridContainer>
              ))}
              {this.state.paginateLogDetails ? (
                <GridContainer justify="center">
                  <Loader
                    type="ThreeDots"
                    color={theme.palette.secondary.main}
                    height={50}
                    width={50}
                    visible={true}
                  />
                </GridContainer>
              ) : null}
              {!this.state.searchQuery || !this.state.pageEnd  ? (
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={12}
                    style={{
                      textAlign: "center",
                      marginTop: "20px",
                      marginBottom: "20px",
                      cursor: "pointer"
                    }}
                    onClick={() =>
                      this.expandLogDetails(this.props.logAction.logType)
                    }
                  >
                    <strong>
                      <p>More</p>
                    </strong>
                    <b className="row-expand-caret"></b>
                  </GridItem>
                </GridContainer>
              ) : (
                <GridItem
                  xs={12}
                  sm={12}
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                    marginBottom: "20px"
                  }}
                >
                  <p>
                    <strong>End of Result</strong>
                  </p>
                  <b style={{ borderWidth: "2px" }}>...</b>
                </GridItem>
              )}

            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}

LogDetailsPagination.propTypes = {
  classes: PropTypes.object.isRequired,
  logAction: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    profile: state.profileObj
  };
};

// const dispatchToProps =
const LogPaginationConnect = withRouter(
  withStyles(customStyles)(LogDetailsPagination)
);
export default connect(mapStateToProps)(LogPaginationConnect);
