import * as React from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ReactMarkdown from "react-markdown";

const useStyles = makeStyles(theme => ({
  colour: {
    color: `${theme.palette.secondary.main} !important`,
  },
  f2: {
    fontSize: "16px"
  },
  font: {
    fontFamily: theme.palette.fontList.selectedFont
  }
}));
export default function BasicPopover(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  let data = props.value
  let msg = props.msg
  return (
    <>
      <a style={{ color: "#F96B13", cursor: "pointer" }}
        aria-describedby={id} onClick={handleClick} className={classes.colour + ' ' + classes.h2 + ' ' + classes.font}>
        {msg==="sandboxes" ? "...Show more" : "Show more" }
      </a>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Typography style={{ backgroundColor: "#0F1A2B", width: "600px", color: "white", padding: "5px" }} className={classes.font} sx={{ p: 4 }}>{msg === "sandboxes" ? <ReactMarkdown source={data} skipHtml={true} /> : <ReactMarkdown source={data} skipHtml={true} />}</Typography>
      </Popover>
    </>

  );
}