import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { activeTextColor } from "assets/jss/material-dashboard-pro-react";
import { makeStyles } from "@material-ui/core/styles";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import BlockIcon from "@material-ui/icons/Block";
import { calcTimeLeft } from "utils/utilfunction";
import moment from "moment";
import { msToStr } from "views/ServiceOffering/LearningLab/acess labs/sdntokenizerhelper";
import { errorTextColor } from "assets/jss/material-dashboard-pro-react";
import CustomTable from "../CustomTable/CustomTable";
import leftArrow from "../../assets/img/circle_left_arrow.svg";
import redLeftArrow from "../../assets/img/red_left_arrow.svg";
import "./LearningCenterTable.scss";

const useStyles = makeStyles(theme => ({
  notificationEllipse: {
    width: "5px",
    height: "5px",
    display: "inline-block",
    background: theme.palette.success.main,
    // marginRight: "3px",
    borderRadius: "75%"
  }
}));

function TableWrapper({
  rows,
  handleAccessLabBtn,
  getLabId,
  history,
  offeringType,
  onRowSelect,
  courseTableHeadings,
  privilege,
  labFilter,
  addHoursDetails
}) {
  const [data, setData] = useState([]);
  useEffect(() => {
    renderRows(rows, labFilter, privilege, offeringType);
  }, [rows]);
  const profileObj = useSelector(
    state => state.profileObj.profileObj.add_hours
  );

  const nameRef = React.createRef();
  // customise row here.
  // eslint-disable-next-line react/display-name
  const NameCol = ({ thumbnail, name, labCreated }) => {
    return (
      <Grid container style={{ width: "250px" }}>
        <Grid item xs={3}>
          {/* replace props.thumbnail with api value */}
          <img
            src={
              thumbnail ||
              require("assets/criterion_networks/img/LearningLab_defaultimg.png")
            }
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={9}>
          <Grid
            container
            style={{ marginLeft: "15px", textOverflow: "ellipsis" }}
          >
            <div>{name}</div>
          </Grid>
          <Grid
            container
            style={{ marginLeft: "15px", textOverflow: "ellipsis" }}
          >
            <div
              style={{
                fontSize: "12px",
                color: "gray"
              }}
            >
              Launched on {labCreated}
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const TechnologyCol = ({ altText }) => {
    const classes = useStyles();
    return (
      <Grid container justify="flex-start" alignItems="flex-start">
        <Grid item xs={1}>
          <p className={classes.notificationEllipse}></p>
        </Grid>
        <Grid item xs={11}>
          {altText}
        </Grid>
      </Grid>
    );
  };
  const StatusCol = ({ status, labStatus, isProvisioned }) => {
    return (
      <Grid container style={{ width: "100%" }} alignItems="center">
        <Grid
          item
          xs={3}
          style={{
            color:
              status &&
              (labStatus != "Not provisioned" && labStatus != "suspended")
                ? activeTextColor
                : errorTextColor
          }}
        >
          <Grid container>
            {status &&
            (labStatus != "Not provisioned" && labStatus != "suspended") ? (
              <>
                <img
                  src={leftArrow}
                  alt=""
                  style={{ height: 17.5, width: 17.5, marginRight: 6.15 }}
                />
                &nbsp;Active
              </>
            ) : (
              <>
                <img
                  src={redLeftArrow}
                  alt=""
                  style={{
                    height: 17.5,
                    width: 17.5,
                    marginRight: 6.15,
                    fill: "red"
                  }}
                />
                &nbsp;Inactive
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={9}>
          <Grid container style={{ marginLeft: "15px" }}>
            <Grid item xs={12} style={{ textTransform: "capitalize" }}>
              {isProvisioned == "notLaunched"
                ? "Not launched"
                : isProvisioned
                ? labStatus
                : "Not Provisioned"}
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                fontSize: "12px",
                color: "gray"
              }}
            >
              Lab status
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };
  const ActionCol = () => {
    return (
      <Grid container style={{ width: "100%" }} alignItems="center">
        <Grid item xs={12}>
          <BlockIcon />
        </Grid>
      </Grid>
    );
  };
  const UserCol = ({
    userName,
    userMail,
    status,
    labStatus,
    privilege,
    accessLabData,
    handleAccessLabBtn,
    labFilter,
    getLabId,
    history,
    offeringType
  }) => {
    // return privilege === "admin" &&
    //   (labFilter === "allLabs" || labFilter === "active") ? (
    //console.log("status ==>", userName, status);
    return privilege === "admin" ? (
      <Grid container style={{ width: "100%" }} alignItems="center">
        <Grid item xs={10}>
          <Grid item xs={12}>
            {userName ? userName : localStorage.getItem("userName")}
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              fontSize: "12px",
              color: "gray"
            }}
          >
            {userMail}
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid container style={{ paddingLeft: "15px" }}>
            <Grid item xs={12}>
              {userName && status ? (
                <Tooltip
                  id="tooltip-top"
                  enterDelay={200}
                  leaveDelay={200}
                  TransitionComponent={Zoom}
                  title={`Go to ${offeringType.title}`}
                  placement="bottom-start"
                >
                  <ArrowRightIcon
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      handleAccessLabBtn(
                        accessLabData.lab_uuid,
                        accessLabData.name,
                        accessLabData.created,
                        accessLabData.expiry_date,
                        history,
                        getLabId,
                        offeringType,
                        addHoursDetails,
                        profileObj,
                        accessLabData.uuid,
                        accessLabData.template_name
                      )
                    }
                  />
                </Tooltip>
              ) : (
                // : labFilter === "allLabs" || labStatus == "Not provisioned" ? (
                //   <Tooltip
                //   id="tooltip-top"
                //   enterDelay={200}
                //   leaveDelay={200}
                //   TransitionComponent={Zoom}
                //   title={`Go to ${offeringType.title}`}
                //   placement="bottom-start"
                // >
                <BlockIcon />
              )}
              {/* ) : null} */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    ) : (
      <Grid container style={{ width: "100%" }} alignItems="center">
        <Grid item xs={12}>
          {userName && status ? (
            <Tooltip
              id="tooltip-top"
              enterDelay={200}
              leaveDelay={200}
              TransitionComponent={Zoom}
              title={`Go to ${offeringType.title}`}
              placement="bottom-start"
            >
              <ArrowRightIcon
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleAccessLabBtn(
                    accessLabData.lab_uuid,
                    accessLabData.name,
                    accessLabData.created,
                    accessLabData.expiry_date,
                    history,
                    getLabId,
                    offeringType,
                    addHoursDetails,
                    profileObj,
                    accessLabData.uuid,
                    accessLabData.template_name
                  )
                }
              />
            </Tooltip>
          ) : (
            // labFilter === "allLabs" ? (
            //   <Tooltip
            //   id="tooltip-top"
            //   enterDelay={200}
            //   leaveDelay={200}
            //   TransitionComponent={Zoom}
            //   title={`Go to ${offeringType.title}`}
            //   placement="bottom-start"
            // >
            <BlockIcon />
          )}
          {/* ) : null} */}
        </Grid>
      </Grid>
    );
  };

  const renderRows = (list, labFilter, privilege, offeringType) => {
    // building row data
    const updatedList = list.reduce((accum, labItem) => {
      let learningCardObj = {};
      // required datetime in format '%Y-%m-%d %H:%M'
      learningCardObj["name"] = (
        <NameCol
          ref={nameRef}
          name={
            labItem.display_name ||
            labItem.clustername ||
            labItem.name ||
            labItem.resource_name ||
            labItem.template_name
          }
          labCreated={moment(labItem.created).format("DD/MM/YY")}
        />
      );
      // moment(new Date()).format("YYYY-MM-DD HH:mm")
      let labTimeLeft = msToStr(labItem.created, labItem.expiry_date);
      if (
        labItem.is_active &&
        (labItem.lab_status === "terminated" || labItem.lab_status === "ready")
      ) {
        labTimeLeft = msToStr(
          moment(new Date()).format("YYYY-MM-DD HH:mm"),
          labItem.expiry_date
        );
      }

      let sandboxRuntime = null;
      let sandboxRuntime1 = null;
      let sandboxRuntime2 = null;
      if (offeringType.title == "poc" && labItem.status && !labItem.deleted) {
        if (!labItem.timeStamp) {
          if (!labItem.terminate_time) {
            sandboxRuntime1 = msToStr(
              60 * 60 * labItem.suspended_hours,
              moment(new Date()).format("YYYY-MM-DD HH:mm")
            );
          } else {
            sandboxRuntime1 = moment(labItem.terminate_time).format(
              "YYYY-MM-DD HH:mm"
            );
          }
        } else {
          sandboxRuntime1 = moment
            .utc(labItem.timeStamp)
            .format("YYYY-MM-DD HH:mm");
        }

        if (!labItem.terminate_time) {
          sandboxRuntime2 = msToStr(
            60 * 60 * labItem.suspended_hours,
            moment(new Date()).format("YYYY-MM-DD HH:mm")
          );
        } else {
          sandboxRuntime2 = moment(labItem.terminate_time).format(
            "YYYY-MM-DD HH:mm"
          );
        }

        sandboxRuntime = msToStr(sandboxRuntime1, sandboxRuntime2);
      }

      if (labFilter === "subscribed" || labFilter === "unsubscribed") {
        learningCardObj["allocated"] = labItem.allocated_hours;
        learningCardObj["consumed"] =
          labItem.consumed_hours > labItem.allocated_hours
            ? labItem.allocated_hours
            : labItem.consumed_hours;
        learningCardObj["action"] = <ActionCol />;
        // learningCardObj["technology"] = labItem.technology ? (
        //   labItem.technology[0]
        // ) : (
        //   <TechnologyCol altText={"Upcoming"} />
        // );
        // learningCardObj["vendor"] = labItem.vendor
        //   ? labItem.vendor[0]
        //   : "No vendor to show";
      } else {
        if (offeringType.title == "poc") {
          learningCardObj["runtime"] = sandboxRuntime
            ? calcTimeLeft(sandboxRuntime, labItem.status)
            : calcTimeLeft(labTimeLeft, labItem.status);
        } else {
          learningCardObj["daysLeft"] = calcTimeLeft(
            labTimeLeft,
            labItem.lab_status
          );
        }
        learningCardObj["status"] = (
          <StatusCol
            status={
              labItem.is_active
                ? labItem.is_active
                : labItem.deleted == false
                ? !labItem.deleted
                : undefined
            }
            labStatus={labItem.lab_status ? labItem.lab_status : labItem.status}
            isProvisioned={
              labItem.lab_uuid === null && labItem.is_active
                ? false
                : labItem.lab_uuid || labItem.uuid
                ? true
                : "notLaunched"
            }
          />
        );
      }
      learningCardObj["user"] = (
        <UserCol
          userName={labItem.user_name}
          userMail={labItem.user_email}
          status={
            labItem.is_active
              ? labItem.is_active
              : labItem.deleted == false
              ? !labItem.deleted
              : undefined
          }
          labStatus={labItem.lab_status ? labItem.lab_status : labItem.status}
          privilege={privilege}
          accessLabData={{ ...labItem }}
          handleAccessLabBtn={handleAccessLabBtn}
          labFilter={labFilter}
          getLabId={getLabId}
          history={history}
          offeringType={offeringType}
        />
      );
      return accum.concat(learningCardObj);
    }, []);
    setData(updatedList);
  };

  let tableHeaders = [
    {
      Header: <p style={{ textAlign: "start" }}>Name</p>,
      accessor: "name" // accessor is the "key" in the data
    },
    {
      Header:
        offeringType.title == "poc" ? (
          <p style={{ textAlign: "start" }}>Running time</p>
        ) : (
          <p style={{ textAlign: "start" }}>Time left</p>
        ),
      accessor: offeringType.title == "poc" ? "runtime" : "daysLeft"
    },
    {
      Header: <p style={{ textAlign: "start", paddingLeft: "20px" }}>Status</p>,
      accessor: "status"
    },
    {
      Header: (
        <p style={{ textAlign: "start" }}>
          {privilege === "admin" &&
          (labFilter === "allLabs" || labFilter === "active")
            ? "User"
            : "Action"}
        </p>
      ),
      accessor: "user"
    }
  ];

  if (labFilter === "subscribed" || labFilter === "unsubscribed") {
    tableHeaders = [
      {
        Header: <p style={{ textAlign: "start" }}>Name</p>,
        accessor: "name" // accessor is the "key" in the data
      },
      {
        Header: (
          <p style={{ textAlign: "start" }}>
            {courseTableHeadings.allocatedHours}
          </p>
        ),
        accessor: "allocated"
      },
      {
        Header: (
          <p style={{ textAlign: "start" }}>
            {courseTableHeadings.consumedHours}
          </p>
        ),
        accessor: "consumed"
      },
      {
        Header: <p style={{ textAlign: "start" }}>Action</p>,
        accessor: "action"
      }
      // {
      //   Header: (
      //     <p style={{ textAlign: "start" }}>{courseTableHeadings.technology}</p>
      //   ),
      //   accessor: "technology"
      // },
      // {
      //   Header: (
      //     <p style={{ textAlign: "start" }}>{courseTableHeadings.vendor}</p>
      //   ),
      //   accessor: "vendor"
      // },
      // {
      //   Header: <p style={{ textAlign: "start" }}>User</p>,
      //   accessor: "user"
      // }
    ];
  }

  const columns = React.useMemo(() => tableHeaders, [
    labFilter,
    courseTableHeadings
  ]);

  return (
    <div className="table-container">
      <CustomTable data={data} columns={columns} onRowSelect={onRowSelect} />
    </div>
  );
}

export default TableWrapper;
