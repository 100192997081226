import React, { useEffect, useState } from "react";

// Material UI Components
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Reports from "../../Reports/reports";
// Icons
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CloseIcon from "@material-ui/icons/Close";

const drawerWidth = 1100;

const useStyles = makeStyles((theme) => ({
    drawerPaper: {
        border: "none",
        top: "0",
        bottom: "0",
        // zIndex: "1032",
        transitionProperty: "top, bottom, width",
        transitionDuration: ".2s, .2s, .35s",
        transitionTimingFunction: "linear, linear, ease",
        width: drawerWidth * 1.5,
        [theme.breakpoints.down("sm")]: {
            width: drawerWidth,
        },
    },
    header: {
        padding: "10px 40px 30px 40px",
    },
}));

const RightDrawerLarge = (props) => {
    const classes = useStyles();

    const Header = ({ toggleDrawer }) => {
        return (
            <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.header}
            >
                {/* <Grid item xs={1}>
          <Grid container justify="flex-start" alignItems="center">
            <HelpOutlineIcon fontSize="small" />
          </Grid>
        </Grid> */}
                <Grid item xs={10}>
                    <Typography variant="body1">{props.title}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Grid
                        container
                        justify="flex-end"
                        alignItems="center"
                        onClick={() => props.onClose(false)}
                        style={{ cursor: "pointer" }}
                    >
                        <CloseIcon />
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    return (
        <div>
            <Drawer
                anchor={"right"}
                open={props.open}
                classes={{
                    paper: classes.drawerPaper,
                }}
                onClose={() => props.onClose(false)}
            >
                <Header />
                {props.children}
<Reports></Reports>
            </Drawer>
        </div>
    );
};

export default RightDrawerLarge;