import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import { useHistory } from "react-router-dom";
import NotificationAlert from "views/Components/NotificationAlert";
import {
  FormControl,
  TextField,
  InputLabel,
  FilledInput,
  InputAdornment,
  FormControlLabel,
  Icon,
} from "@material-ui/core";
import {
  SDCloudBackendAPIWithToken,
  SDCloudCustomizeAPIWithToken,
} from "apis/backendAPI";
import queryString from "query-string";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import { useDispatch } from "react-redux";

import { getDesignerObjects } from "../../../apis/APIServices";
import CreateTemplateModal from "../../Design/Components/CreateTemplateModal";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: "35px",
    width: "80px",
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  buttonHide:{
    display:"None"
  }
}));

function getSteps() {
  return [
    "Element Added to the Inventory",
    "Element Configured",
    "Place your node",
  ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return "";
    case 1:
      return "";
    case 2:
      return "Select the listed categories under which you want your node to appear in design center. Click on create new category to add a new one.";
    default:
      return "Unknown step";
  }
}

export default function TestNode(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [consumedRam, setConsumedRam] = useState("");
  const [consumedCpu, setConsumedCpu] = useState("");
  const [consumedDisk, setConsumedDisk] = useState("");
  const [bootTime, setBootTime] = useState("");
  const [activeStep, setActiveStep] = React.useState(2);
  const [nodeData, setNodeData] = useState({});
  const [actionWizard, setActionWizard] = useState("");
  const [isNewTemplateVisible, setNewTemplateVisible] = useState(false);
  const [testNotes, setTestNotes] = useState("");
  const [nodeCategories, setNodeCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  
  
  let queryParams = queryString.parse(window.location.search);
  var mode = queryParams.mode;
  if (mode == "add") {
    var node_name = localStorage.getItem("node_name");
    var node_id = localStorage.getItem("node_id");
    var node_version = localStorage.getItem("node_version");
  } else {
    var node_id = queryParams.id;
    var node_name = queryParams.name;
    var node_version = queryParams.version;
  }

  const [state, setState] = React.useState({
    devBoot: false,
    devAcc: false,
    ipConn: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleCategoryChange = (event) => {
    const selectedCat = event.target.value;
    setSelectedCategory(selectedCat);
  };

  const handleFinish = () => {
    let payload = { node_id: node_id, category: selectedCategory };
    SDCloudBackendAPIWithToken()
      .post("/lifecycle/add_node_to_category", payload)
      .then((resp) => resp.data)
      .then((data) => {
        let payload = {
          message: "Node successfully moved to the selected category.",
          color: "success",
        };
        payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      })
      .catch((err) => {
        let message = err.response.data.message;
        const payload = {
          message: message,
          color: "danger",
        };
        payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      });
    history.push("/custom-elements");
  };
  const handleOpenDesignCenter = async (name, uuid, isMultiVM) => {
    if (
      localStorage.getItem("BASE_URL") == null ||
      localStorage.getItem("BASE_URL") == undefined
    ) {
      const BASE_URL = window.REACT_APP_API_BASE_URL;
      localStorage.setItem("BASE_URL", BASE_URL);
    }
    if (
      localStorage.getItem("design_objects") == null ||
      localStorage.getItem("design_objects") == undefined
    ) {
      var resp = await getDesignerObjects();
      if (resp.success) {
        localStorage.setItem("design_objects", JSON.stringify(resp.data));
      }
    }
    var route;
    if (
      window.location.protocol !== "https:" &&
      window.location.hostname !== "localhost"
    ) {
      route = `https:${window.location.hostname}` + "/design_center/?";
    } else {
      route = "/design_center/?";
    }
    if (uuid) {
      route = `${route}uuid=${uuid}`;
    } else if (actionWizard) {
      if (isMultiVM) {
        route = `${route}new_template=${name}&title=${actionWizard}&multi_vm=${isMultiVM}`;
      } else {
        route = `${route}new_template=${name}&title=${actionWizard}`;
      }
    } else {
      if (isMultiVM) {
        route = `${route}new_template=${name}&multi_vm=${isMultiVM}`;
      } else {
        route = `${route}new_template=${name}`;
      }
    }
    window.open(route);
    setNewTemplateVisible(false);
  };

  const steps = getSteps();

  useEffect(() => {
    SDCloudBackendAPIWithToken()
      .get("/lifecycle/get_categories")
      .then((resp) => resp.data)
      .then((data) => {
        setNodeCategories(data["categories"]);
        //setActiveStep((prevActiveStep) => prevActiveStep + 1);
      })
      .catch((err) => {
        let message = err.response.data.message;
        const payload = {
          message: message,
          color: "danger",
        };
        payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
      });
    const handleTabClose = (event) => {
      event.preventDefault();
      localStorage.removeItem("node_id");
      localStorage.removeItem("node_version");
      localStorage.removeItem("node_name");
      //console.log("beforeunload event triggered");
      return (event.returnValue = "Are you sure you want to exit?");
    };
    
    window.addEventListener("beforeunload", handleTabClose);
    dispatch(SET_NAVBAR_TITLE("Test Node: " + node_name));
    let payload = {};
    if (mode == "add") {
      if (
        localStorage.getItem("node_id") != undefined &&
        localStorage.getItem("node_id") != ""
      ) {
        payload = {
          node_id: localStorage.getItem("node_id"),
          node_version: localStorage.getItem("node_version"),
        };
        getNodeInventoryDetails(payload);
      }
    } else {
      payload = { node_id: node_id, node_version: node_version };
      getNodeInventoryDetails(payload);
    }
    localStorage.removeItem("design_objects");
    getDesignObjects();
    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  const handleNext = (e) => {
    if (activeStep == 2) {
      let payload = nodeData;
      payload["node_version"] = node_version;
      payload["selected_category"] = selectedCategory;
      payload["state"] = "tested";
      SDCloudBackendAPIWithToken()
        .post("/inventory/update_designer_node", payload)
        .then((resp) => resp.data)
        .then((data) => {
          SDCloudBackendAPIWithToken()
            .post("/inventory/add_node_to_products_inventory", payload)
            .then((resp) => resp.data)
            .then((data) => {
              let payload = {
                message: "Designer Element Inventory Updated Sussessfully",
                color: "success",
              };
              payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
            })
            .catch((err) => {
              let message = err.response.data.message;
              const payload = {
                message: message,
                color: "danger",
              };
              payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
            });
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        })
        .catch((err) => {
          let message = err.response.data.message;
          const payload = {
            message: message,
            color: "danger",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));
        });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getDesignObjects = async () => {
    const designObject = localStorage.getItem("design_objects");
    if (!designObject) {
      const response = await getDesignerObjects();
      if (response.success) {
        localStorage.setItem("design_objects", JSON.stringify(response.data));
      }
    }
  };

  const getNodeInventoryDetails = async (payload) => {
    SDCloudBackendAPIWithToken()
      .post("/inventory/get_designer_node_details", payload)
      .then((resp) => resp.data)
      .then((data) => {
        let node_details = data["node_details"];
        setNodeData(node_details);
        if (
          node_details["category"] == null ||
          node_details["category"] == undefined
        ) {
          setSelectedCategory("custom_elements");
        } else {
          setSelectedCategory(node_details["category"]);
        }
        setConsumedCpu(node_details["test_info"]["consumed_cpu"]);
        setConsumedRam(node_details["test_info"]["consumed_ram"]);
        setConsumedDisk(node_details["test_info"]["consumed_disk"]);
        setBootTime(node_details["test_info"]["boot_time"]);
        setTestNotes(node_details["test_info"]["test_notes"]);
        setState({
          devBoot: node_details["test_info"]["boot_test"],
          devAcc: node_details["test_info"]["dev_accessibility"],
          ipConn: node_details["test_info"]["ip_connectivity"],
        });
      });
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
            <NotificationAlert
          notificationType={notificationType}
          showNotification={showNotification}
          message={notificationMessage}
        />
              <Typography>{getStepContent(index)}</Typography>
              <div className={classes.actionsContainer}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer
                      justify="left"
                      style={{ marginLeft: "20px" }}
                    >
                      {activeStep === 2 && (
                        <GridItem xs={10} sm={10}>
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl
                              fullWidth
                              className={classes.customInputStyle}
                              variant="filled"
                              style={{ marginTop: "10", marginRight: "25px" }}
                            >
                              <InputLabel id="categories">
                                Select category
                              </InputLabel>
                              <Select
                                labelId="categories"
                                id="categories"
                                labelWidth={100}
                                fullWidth
                                name="categories"
                                native
                                onChange={handleCategoryChange}
                                value={selectedCategory}
                                variant="filled"
                              >
                                <option value="">Select Category</option>
                                {nodeCategories.map(({ id, title }) => (
                                  <option value={id}>{title}</option>
                                ))}
                              </Select>
                            </FormControl>
                            <FormControl
                              fullWidth
                              className={classes.customInputStyle}
                            >
                              <TextField
                                style={{ paddingRight: "", margin: 0 }}
                                label="Test Notes"
                                InputLabelProps={{
                                  disableAnimation: true,
                                  shrink: false,
                                }}
                                InputProps={{ disableUnderline: true }}
                                id="test_notes"
                                value={testNotes}
                                multiline
                                onChange={(event) =>
                                  setTestNotes(event.target.value)
                                }
                                rows={10}
                                variant="filled"
                              />
                            </FormControl>
                          </Grid>
                        </GridItem>
                      )}
                      <br />
                      <br />
                      <br />
                      <GridItem xs={10} sm={10}>
                        {isNewTemplateVisible && (
                          <CreateTemplateModal
                            visible={isNewTemplateVisible}
                            onClose={() => {
                              setActionWizard("");
                              setNewTemplateVisible(false);
                            }}
                            onCreateTemplate={handleOpenDesignCenter}
                            is_multi_vm={
                              JSON.parse(localStorage.getItem("profileData"))
                                .multi_vm
                            }
                          />
                        )}
                        <Button
                          right
                          variant="contained"
                          color="secondary"
                          style={{ cursor: "pointer" }}
                          onClick={() => setNewTemplateVisible(true)}
                        >
                          Open Design Center
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>

      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>
            All steps completed. Node successfully tested. Please click finish
            to complete all steps.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            right
            style={{ float: "right" }}
            onClick={handleFinish}
          >
            Finish
          </Button>
        </Paper>
      )}
      <Paper square elevation={0} className={classes.resetContainer}>
        {activeStep === steps.length ? (
          <Button 
            variant="contained"
            color="secondary"
            disabled={activeStep <= 2}
            onClick={handleBack}
            className={classes.button}
          >
            Back
          </Button>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            right
            style={{ float: "left" }}
            onClick={props.handleBack}
          >
            Back
          </Button>
        )}
        <Button
          variant="contained"
          color="secondary"
          right
          style={{ float: "right" }}
          onClick={handleNext}
          className={activeStep >=3 ? classes.buttonHide:classes.button}
        >
          {activeStep === steps.length - 1 ? "Save" : "Next"}
        </Button>
      </Paper>
    </div>
  );
}
