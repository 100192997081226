import React from "react";
import { withRouter } from "react-router-dom";
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    const { history } = this.props;

    history.listen((location, action) => {
      if (this.state.hasError) {
        this.setState({
          hasError: false
        });
      }
    });
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !this.state.error || nextState.error;
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //console.log(error, errorInfo);
    // for a user friendly component trace errorInfo.componentStack
  }

  render() {
    // if (this.state.hasError) {
    //   // You can render any custom fallback UI
    //   return <h1>Something went wrong.</h1>;
    // }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
