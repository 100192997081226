import React from "react";
import GridContainer from "components/Grid/GridContainer";
import imageMapResize from "image-map-resizer";
import Button from "@material-ui/core/Button";
import ReactExport from "react-data-export";

const ReactMarkdown = require("react-markdown");
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const AccessDevices = props => {
  const [multiDataSet, setMultiDataSet] = React.useState(null);
  const [flag, setFlag] = React.useState(false);
  const queryParams = new URLSearchParams(window.location.search)
  const uuid = queryParams.get("uuid")

  React.useEffect(() => {
    imageMapResize();
    const table = document.getElementsByTagName("table")[0];
    if (!flag && table) {
      table.setAttribute("id", "a_details");

      let dataSet = [
        {
          columns: [
            { title: "Hostname", width: { wpx: 100 } }, //pixels width
            { title: "SSH link", width: { wch: 40 } }, //char width
            { title: "Web link", width: { wpx: 100 } },
            { title: "Static IP", width: { wpx: 100 } },
            { title: "Port", width: { wpx: 80 } },
            { title: "Username", width: { wpx: 90 } },
            { title: "Password", width: { wpx: 90 } }
          ],
          data: []
        }
      ];

      for (let i = 1; i < table.rows.length; i++) {
        let row = table.rows[i];
        if (row.cells) {
          let sshLink = `https://${row.cells[5].textContent}/ssh/host/${row.cells[2].textContent}?port=22&user=${row.cells[3].textContent}&pass=${row.cells[4].textContent}`;
          let webLink = `https://${row.cells[5].textContent}/`;
          let details = [
            { value: row.cells[2].textContent },
            { value: sshLink },
            { value: webLink },
            { value: row.cells[5].textContent },
            { value: row.cells[6].textContent },
            { value: row.cells[3].textContent },
            { value: row.cells[4].textContent }
          ];
          dataSet[0].data.push(details);
        }
      }
      setMultiDataSet(dataSet);
      setFlag(true);
    }
  });

  const handleInteractiveTopology = () => {
    if (
        localStorage.getItem("BASE_URL") == null ||
        localStorage.getItem("BASE_URL") == undefined
    ) {
      const BASE_URL = window.REACT_APP_API_BASE_URL;
      localStorage.setItem("BASE_URL", BASE_URL);
    }
    window.open(
      `/design_center/interactive_topo.html?uuid=${uuid}`,
      "_blank"
    );
  };

  let { data } = props;
  data = data.replace(/\n\s*\n/g, "\n");
  if (!data) return null;
  return (
    <GridContainer
      justify="center"
      alignItems="center"
      style={{ flexDirection: "row", flexWrap: "wrap" }}
    >
      {props.currentTitle == "Lab Details" && props.enableExport ? (
        <GridContainer justify="flex-end" alignItems="center">
          <ExcelFile
            element={
              <Button
                // onClick={handleExcel}
                variant="contained"
                color="secondary"
                disableElevation
                size="large"
                // fullWidth
                disableFocusRipple
                // className={classes.btnColor}
                type="submit"
                style={{ height: "40px", marginRight: "50px" }}
              >
                Download
              </Button>
            }
            filename={"showcase_details"}
          >
            <ExcelSheet dataSet={multiDataSet} name="Access Details" />
          </ExcelFile>
        </GridContainer>
      ) : null}
     <ReactMarkdown className="panel" source={data} escapeHtml={false} /> 
     {props.topoSvg != "" ? (
     <><div>
        </div><div>
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              size="large"
              disableFocusRipple
              type="button"
              onClick={handleInteractiveTopology}
              disabled={props.labStatus !== "ready" ? true : false}
            >
              Open Interactive Topology
            </Button>
            {/* <div dangerouslySetInnerHTML={{ __html: props.topoSvg }} /> */}
          </div>
        </>
      ) : 
      null
      }
    </GridContainer>
  );
};

export default AccessDevices;
