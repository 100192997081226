import React from "react";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  menu: {
    margin: "15px 0px",
    cursor: "pointer",
    "& a:hover, a:active": {
      color: theme.palette.secondary.main,
    },
    width: "100%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textTransform: 'capitalize',
    fontWeight: 'normal'
  },
  container: {
    width: "100%",
    transition: "width 0.5s",
  },
  inactive: {
    width: "0",
    overflow: "hidden",
  },
}));

const Menu = ({ items, onItemClick, currentScreen, showMenu }) => {
  const classes = useStyles();

  const handleMenuItemClick = (e, item) => {
    e.preventDefault();

    onItemClick(item);
  };

  if (showMenu === undefined) {
    showMenu = true;
  }

  const renderMenuList = () => {
    const menuList = items.map((item) => {
      return (
        <Typography variant="h6" className={classes.menu} key={item}>
          <Link
            onClick={(e) => handleMenuItemClick(e, item)}
            underline="none"
            color={item === currentScreen ? "secondary" : "inherit"}
          >
            {item}
          </Link>
        </Typography>
      );
    });

    return menuList;
  };

  return (
    <Grid
      container
      className={
        showMenu
          ? classes.container
          : `${classes.container} ${classes.inactive}`
      }
    >
      <Grid item xs={12} md={12}>
        {renderMenuList()}
      </Grid>
    </Grid>
  );
};

export default Menu;
