import React, { useState } from "react";

// Material UI Components
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";

import deleteSvg from "../../../../assets/criterion_networks/svg/trash.svg";

const useStyles = makeStyles(theme => ({
  deleteIcon: {
    height: "40px",
    width: "40px",
    border: "1px solid grey",
    borderRadius: "0",
    fill: theme.palette.grey["900"]
  },
  headerContainer: {
    marginTop: "45px"
  },
  formControl: {
    marginTop: "0",
    width: "100%"
  },
  flex: {
    display: "flex"
  }
}));

const SearchHeader = ({
  handleDelete,
  rows,
  handleActive,
  setSearchList,
  handleLaunchLab,
  labFilter,
  filterLabCounts
}) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState("");

  const handleChange = e => {
    const { value } = e.target;
    const searchList = rows.filter(obj =>
      [
        "display_name",
        "name",
        "resource_name",
        "template_name",
        "clustername"
      ].some(key => {
        return obj[key] && obj[key].toLowerCase().includes(value.toLowerCase());
      })
    );
    setSearchText(value);
    setSearchList(value ? searchList : rows);
  };

  return (
    <div className="search-header">
      <Grid
        container
        className={classes.headerContainer}
        alignItems="center"
        justify="space-between"
        wrap="wrap"
      >
        <Grid className="first-col" item xs={12} sm={12} md={12} lg={10}>
          <div className={classes.flex}>
            {/* <Button
              disableElevation
              size="large"
              disableFocusRipple
              className="gray-btn trash"
              onClick={handleDelete}
            >
              <span>
                <img alt="trash" src={deleteSvg} />
              </span>
            </Button> */}
            <TextField
              type="search"
              variant="filled"
              className="search-input"
              onChange={handleChange}
              value={searchText || ""}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div className="main-btns">
            <Tooltip
              id="tooltip-top"
              enterDelay={100}
              leaveDelay={200}
              style={{ fontSize: "16px" }}
              TransitionComponent={Zoom}
              title={
                "Libraries in Queued, Spawning, Provisioning, Resumimg, Suspending, Stabilizing, Ready and Error states"
              }
              placement="bottom-start"
            >
              <Button
                disableElevation
                size="large"
                disableFocusRipple
                disabled={filterLabCounts.active == 0 ? true : false}
                className={`gray-btn ${labFilter === "active" && "active"}`}
                onClick={() => handleActive("active")}
              >
                Active
              </Button>
            </Tooltip>
            <Tooltip
              id="tooltip-top"
              enterDelay={100}
              leaveDelay={200}
              style={{ fontSize: "16px" }}
              TransitionComponent={Zoom}
              title={"Libraries in Not provisioned and Suspended states"}
              placement="bottom-start"
            >
              <Button
                disableElevation
                size="large"
                disableFocusRipple
                disabled={filterLabCounts.inactive == 0 ? true : false}
                className={`gray-btn ${labFilter === "inactive" && "active"}`}
                onClick={() => handleActive("inactive")}
              >
                Inactive
              </Button>
            </Tooltip>
            <Button
              disableElevation
              size="large"
              disableFocusRipple
              disabled={filterLabCounts.allLabs == 0 ? true : false}
              className={`gray-btn ${labFilter === "allLabs" && "active"}`}
              onClick={() => handleActive("allLabs")}
            >
              All
            </Button>
            {/* <Tooltip
              id="tooltip-top"
              enterDelay={100}
              leaveDelay={200}
              TransitionComponent={Zoom}
              title={"Your subscribed libraries"}
              placement="bottom-start"
            >
              <Button
                disableElevation
                size="large"
                disableFocusRipple
                disabled={filterLabCounts.subscribed == 0 ? true : false}
                className={`gray-btn ${labFilter === "subscribed" && "active"}`}
                onClick={() => handleActive("subscribed")}
              >
                Subscribed
              </Button>
            </Tooltip>
            <Tooltip
              id="tooltip-top"
              enterDelay={100}
              leaveDelay={200}
              TransitionComponent={Zoom}
              title={"Libraries not signed up for"}
              placement="bottom-start"
            >
              <Button
                disableElevation
                size="large"
                disableFocusRipple
                disabled={filterLabCounts.unsubscribed == 0 ? true : false}
                className={`gray-btn ${labFilter === "unsubscribed" &&
                  "active"}`}
                onClick={() => handleActive("unsubscribed")}
              >
                Unsubscribed
              </Button>
            </Tooltip> */}
          </div>
        </Grid>

        <Grid className="launch-btn" item xs={12} sm={12} md={12} lg={2}>
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            size="large"
            disableFocusRipple
            onClick={handleLaunchLab}
          >
            Browse Library
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default SearchHeader;
