import React, {useState} from "react";

// Material UI Components
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
// import SvgtoIcon from "utils/SvgtoIcon";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Typography from "@material-ui/core/Typography";

// Other Components
import {
  useTable,
  usePagination,
  useRowSelect,
  useGlobalFilter,
  useAsyncDebounce
} from "react-table";
import { v4 as uuidv4 } from "uuid";
import Reports from "../../Reports/reports";
import RightDrawerLarge from "../../Usermanagement/components/RightDrawerLarge";
// Styling
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: "20px"
  },
  container: {
    padding: "20px",
    paddingTop: "0px"
  },
  pagesDropdown: {
    backgroundColor: "#ffffff",
    color: theme.palette.grey[700],
    border: "none",
    padding: "5px",
    "& .MuiOutlinedInput-input": {
      padding: "0 25px 0 0"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    }
  },
  paginationToolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px"
  },
  paginationNumbers: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& p, div": {
      margin: "0 10px"
    }
  },
  numberButton: {
    minWidth: "0px",
    padding: "0px",
    "& .MuiButton-label": {
      width: "35px",
      height: "24px",
      backgroundColor: "#ffffff",
      color: theme.palette.grey["600"]
    },
    "& .MuiButton-text": {
      padding: "0 1px"
    }
  },
  table: {
    root: {
      "& .MuiTableCell-root": {
        borderBottom: "5px solid green"
      },
      "& .MuiTableCell-body": {
        color: "black"
      },
      "& ,MuiFormControl-root": {
        marginTop: "0"
      }
    }
  },
  previousButton: {
    color: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.secondary.main
    }
  },
  nextButton: {
    color: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.secondary.main
    }
  },
  active: {
    "& .MuiButton-label": {
      backgroundColor: "#3868C2",
      color: "#fff"
    }
  },
  font: {
    fontFamily: theme.palette.fontList.selectedFont
  },
  tablehead: {
    fontWeight: "bold",
    padding: "5px"
  },
  tablebody: {
    padding: "5px"
  },
  headerContainer: {
    margin: "30px 0"
  },
  title: {
    margin: "30px 0 30px 0"
  }
}));

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const UsageDetails = ({
  columns,
  data,
  handleButtonClick,
  handleDeleteButtonClick,
  handleNavigate,
  tableTitle,
  buttonTitle,
  dropDownValues,
  handleDropDownChange,
  hiddenColumns,
  showDropdown
}) => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentScreen, setCurrentScreen] = useState();
  const [currentUser, setCurrentUser] = useState("");
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    preGlobalFilteredRows,
    state,
    setGlobalFilter,
    state: { pageIndex, pageSize, selectedRowIds }
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        hiddenColumns: hiddenColumns ? hiddenColumns : []
      }
    },
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  const renderPaginationButtons = () => {
    let paginationNumbers = [];

    let startIndex =
      pageOptions.length - pageIndex > 8
        ? pageIndex
        : pageOptions.length - 8 < 0
        ? 0
        : pageOptions.length - 8;
    let endIndex =
      pageOptions.length - pageIndex > 8 ? pageIndex + 8 : pageOptions.length;

    for (let index = startIndex; index < endIndex; index++) {
      paginationNumbers.push(
        <Button
          className={`
            ${classes.numberButton} 
            ${index === pageIndex ? classes.active : null}
          `}
          key={uuidv4()}
          onClick={() => gotoPage(index)}
        >
          {index + 1}
        </Button>
      );
    }

    return paginationNumbers;
  };

  const handleDeleteButton = () => {
    handleDeleteButtonClick(selectedRowIds, selectedFlatRows);
  };

  const handleButton = () => {
    handleNavigate();
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h4" className={classes.title}>
          {tableTitle}
        </Typography>
      </Grid>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          {/* <Grid container alignItems="center" justify="flex-end">
            <Grid item style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                size="large"
                disableFocusRipple
                className={classes.btnColor}
                type="submit"
                style={{ height: "40px", marginLeft: "10px" }}
                onClick={handleButton}
              >
                {buttonTitle}
              </Button>
            </Grid>
          </Grid> */}

          {/* <TableHeader
            handleButtonClick={handleButton}
            handleDeleteButtonClick={handleDeleteButton}
            buttonTitle={buttonTitle}
            dropDownValues={dropDownValues}
            handleDropDownChange={handleDropDownChange}
            showDropdown={showDropdown}
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            useAsyncDebounce={useAsyncDebounce}
          /> */}

          <Table
            className={classes.table}
            aria-label="customized table"
            {...getTableProps()}
          >
            <TableHead>
              {headerGroups.map(headerGroup => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <TableCell
                      {...column.getHeaderProps()}
                      className={classes.tablehead}
                    >
                      {column.render("Header")}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return (
                        <TableCell
                            {...cell.getCellProps({
                              onClick: ()=> {
                                if(cell.column.id == "hours"){
                                  setOpenDrawer(true);
                                }
                              },
                              style: {
                                textOverflow: cell.column.textOverflow,
                                textDecoration: cell.column.textDecoration,
                                cursor: cell.column.cursor
                              }
                            })}
                          className={classes.tablebody}
                        >
                          {cell.render("Cell")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <RightDrawerLarge
          open={openDrawer}
          onClose={setOpenDrawer}
          title={currentScreen}
      >
      </RightDrawerLarge>
      {/* <div className={classes.paginationToolbar}> */}
      {/* <div>
          <span className={classes.font}>
            Show&nbsp;&nbsp;
            <Select
              className={classes.pagesDropdown}
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value));
              }}
              variant="outlined"
              native
            >
              {[5, 10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {pageSize} Items
                </option>
              ))}
            </Select>
            &nbsp;&nbsp;per page
          </span>
        </div> */}

      {/* <div className={classes.paginationNumbers}> */}
      {/* <Typography variant="body2">
            <Link
              onClick={e => {
                e.preventDefault();
                previousPage();
              }}
              disabled={!canPreviousPage}
              underline="none"
              className={classes.previousButton}
            >
              Previous
            </Link>
          </Typography> */}

      {/* <div> */}
      {/* {Array.from(Array(8 > pageCount ? pageCount : 8)).map(
              (_, index) => {
                return (
                  <Button
                    className={[
                      classes.numberButton,
                      index === pageIndex ? classes.active : null,
                    ]}
                    key={uuidv4()}
                    onClick={() => gotoPage(index)}
                  >
                    {index + 1}
                  </Button>
                );
              }
            )} */}
      {/* {renderPaginationButtons()} */}
      {/* </div> */}

      {/* <Typography variant="body2">
            <Link
              onClick={e => {
                e.preventDefault();
                nextPage();
              }}
              disabled={!canNextPage}
              underline="none"
              className={classes.nextButton}
            >
              Next
            </Link>
          </Typography> */}
      {/* </div> */}
      {/* </div> */}
    </>
  );
};

export default UsageDetails;
