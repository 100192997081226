import React, { Component } from "react";
import { withRouter } from "react-router";
import Button from "components/CustomButtons/Button.js";
import { Animated } from "react-animated-css";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import SweetAlert from "react-bootstrap-sweetalert";
import NodeListTable from "./NodeTable";
import Snackbar from "components/Snackbar/Snackbar.js";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { buildAxiosParams } from "views/ServiceLifeCycle/utils/constants/urls";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.js";

import axios from "axios";
import { PRIMARY_COLOR } from "global_constants/app_constants";
import classes from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
// import ROUTING_URLS from "views/ServiceLifeCycle/utils/constants/urls";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import { Api } from "config/api";
import theme from "theme";
import "./NodeList.css";
// const classes = useStyles();

const COLS = ["name", "image_url", "image_name"];
const TABLE_HEADERS = [
  {
    Header: "#",
    accessor: "id",
    sortable: false,
    filterable: false
  },
  {
    Header: "Name",
    accessor: "name"
  },
  {
    Header: "Display icon",
    accessor: "image_url"
  },
  {
    Header: "Image",
    accessor: "image_name"
  },
  {
    Header: "Version",
    accessor: "image_version"
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    filterable: false
  }
];

class TableWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataTable: [],
      isTableLoaded: true,
      selectedNodeToDelete: null,
      showDeleteAlert: false,
      showNotification: false,
      notificationMessage: ""
    };
  }

  build2DArray = data => {
    return data.reduce((accum, val, index) => {
      let _1Darr = [];
      _1Darr[0] = val.name;
      _1Darr[1] = val.category;
      _1Darr[2] = val.image_url; // display url for icon
      _1Darr[3] = val.slc_node; // checking if it is a company provided node.
      accum.push(_1Darr);
      return accum;
    }, []);
  };

  handleDeleteNode = (obj, key) => {
    let nodeObj = this.state.dataTable.find(_each => {
      return _each.id === key + 1 ? true : false;
    });
    if (nodeObj) {
      this.setState({
        showDeleteAlert: true,
        selectedNodeToDelete: nodeObj
      });
    }
  };

  handleConfirmDeleteNode = () => {
    this.deleteNodeApi(this.state.selectedNodeToDelete);
    this.setState({ showDeleteAlert: false });
  };

  hideAlert = () => {
    this.setState({
      showDeleteAlert: false,
      selectedNodeToDelete: null
    });
  };

  callNotification = (notificationColor, notificationMessage) => {
    this.setState({
      notificationColor,
      notificationMessage,
      showNotification: true
    });
    // setTimeout(() => {
    //   this.setState({
    //     showNotification: false,
    //     notificationMessage: ""
    //   });
    // }, 3500);
  };

  deleteNodeApi = nodeObj => {
    this.setState({ isTableLoaded: false });
    let data = {
      name: nodeObj.name,
      category: nodeObj.category
    };
    SDCloudBackendAPIWithToken()
      .post(Api.deleteImage, data)
      .then(res => {
        if (res.status === 200) {
          // let newDataTable = [...this.state.dataTable];
          // newDataTable.splice(nodeObj.id - 1, 1) // minus 1 because index is + 1
          this.setState({
            // dataTable: [...newDataTable],
            showDeleteAlert: false
          });
          this.callNotification(
            "success",
            `${res.data.name} deleted successfully.`
          );
          this.fetchTableData();
        }
      })
      .catch(err => {
        this.callNotification("danger", `${err.response.data.message}.`);
        this.setState({ showDeleteAlert: false });
        this.fetchTableData();
      });
  };

  handleEditNode = (obj, key) => {
    this.props.history.push({
      pathname: window.REACT_APP_CREATE_NODE_ROUTE,
      state: { editNode: obj }
    });
  };

  buildTable = data => {
    const tableFormat = {
      headerRow: COLS,
      footerRow: COLS,
      dataRows: data.apiData
    };
    return tableFormat.dataRows.map((prop, key) => {
      return {
        id: key + 1,
        name: prop[0],
        image_url: (
          // use this button to remove the data row
          <div className="display-icon">
            {prop[2].trim() !== "" ? (
              <img
                className="img-fluid p-absolute image-dim"
                src={prop[2]}
                alt=""
              ></img>
            ) : (
              prop[2]
            )}
          </div>
        ),
        image_name: prop[1],
        image_version: prop[1],
        actions: (
          // use this button to remove the data row
          <div>
            <Button
              justIcon
              round
              simple
              onClick={() => {
                this.handleDeleteNode(prop, key);
              }}
              color="danger"
              className="remove"
            >
              <Close />
            </Button>

            <Button
              justIcon
              round
              simple
              onClick={() => {
                this.handleEditNode(prop, key);
              }}
              color="info"
              // className="success"
            >
              <Edit />
            </Button>
          </div>
        )
      };
    });
  };

  // renders image list associate with that image.
  // renderDeleteImageList = () => {
  //   return (
  //     <div style={{}}>
  //       <div
  //         style={{
  //           overflowY: "auto",
  //           height: Math.ceil(window.outerHeight * 0.15)
  //         }}
  //       >
  //         <p>image 1</p>
  //         <p>image 2</p>
  //         <p>image 3</p>
  //         <p>image 4</p>
  //       </div>
  //     </div>
  //   );
  // };

  fetchTableData = () => {
    this.setState({ isTableLoaded: false });
    SDCloudBackendAPIWithToken()
      .get(Api.listNodes)
      .then(res => {
        if (res.status === 200) {
          const finaltable = this.buildTable({
            apiData: this.build2DArray(res.data.nodes)
          });
          this.setState({ dataTable: finaltable, isTableLoaded: true });
        }
      })
      .catch(err => {
        // //console.log(err);
        this.setState({ isTableLoaded: true });
      });
  };

  componentDidMount() {
    this.fetchTableData();
  }
  render() {
    if (this.state.isTableLoaded) {
      return (
        <>
          <GridContainer
            justify="center"
            alignItems="center"
            style={{ paddingRight: "2%", paddingLeft: "2%" }}
          >
            <GridItem xs={12} sm={12}>
              <Animated
                animationIn="fadeIn"
                animationOut="fadeOut"
                animationInDuration={1000}
                animationOutDuration={1000}
                isVisible={true}
              >
                <NodeListTable
                  data={this.state.dataTable}
                  // filterable
                  columns={TABLE_HEADERS}
                  // defaultPageSize={5}
                  // showPaginationTop
                  // showPaginationBottom={false}
                  // className="-striped -highlight"
                  tableTitle="My Elements"
                  buttonTitle="Show details"
                />
              </Animated>

              {this.state.showDeleteAlert ? (
                <SweetAlert
                  warning
                  style={{ display: "block", marginTop: "-100px" }}
                  title="Confirm Delete Node?"
                  onConfirm={() => this.handleConfirmDeleteNode()}
                  onCancel={() => this.hideAlert()}
                  confirmBtnStyle={{ ...classes.button, ...classes.info }}
                  cancelBtnStyle={{ ...classes.button }}
                  showCancel
                >
                  <strong> {this.state.selectedNodeToDelete.name} </strong> will
                  be deleted permanently.
                  {/* {this.renderDeleteImageList()} */}
                </SweetAlert>
              ) : null}
              <Snackbar
                place="tr"
                color={this.state.notificationColor}
                //   icon={AddAlert}
                message={this.state.notificationMessage}
                open={this.state.showNotification}
                closeNotification={() =>
                  this.setState({ showNotification: false })
                }
                close
              />
            </GridItem>
          </GridContainer>
        </>
      );
    } else {
      return (
        <GridContainer justify="center" alignItems="center">
          <Loader
            type="ThreeDots"
            color={theme.palette.secondary.main}
            height={50}
            width={50}
            visible={true}
          />
        </GridContainer>
      );
    }
  }
}

export default withRouter(TableWrapper);
