import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Learn from "assets/img/learning.png";
import ShowcaseImg from "assets/img/sales.png";
import SandboxImg from "assets/img/enggsandbox.png";
import LockIcon from "@material-ui/icons/Lock";
import ViewOffering from "views/Dashboard/ViewOffering";
import { SECONDARY_COLOR } from "global_constants/app_constants";

const PlaceholderCard = props => {
  const offeringType = props.offeringType.title;
  if (offeringType === "sales demo") {
    return (
      <GridContainer>
        <GridItem xs={0} sm={2} md={2}></GridItem>
        <GridItem xs={12} sm={8} md={8}>
          <GridContainer justify="center" alignItems="center">
            <GridItem>
              <h3
                style={{
                  color: SECONDARY_COLOR,
                  fontWeight: "bold"
                }}
              >
                Showcases
              </h3>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center" alignItems="center">
            <GridItem>
              <h3
                style={{
                  color: SECONDARY_COLOR,
                  fontWeight: "bold"
                }}
              >
                <LockIcon />
              </h3>
            </GridItem>
          </GridContainer>
          <ViewOffering
            list={[
              "Self-paced sales demo for SD-WAN",
              "Real-world use cases & detailed examples",
              "Multiple use cases for feature demonstration"
            ]}
            icon={ShowcaseImg}
            button={""}
            offeringType={offeringType}
          />
          <GridContainer justify="center" alignItems="center">
            <p
              style={{
                color: SECONDARY_COLOR,
                textDecoration: "underline",
                fontWeight: "bolder"
              }}
            >
              Looks like you haven’t signed up for any showcases yet. Please
              contact your respective administrators to request access to
              Showcases.
            </p>
          </GridContainer>
        </GridItem>
        <GridItem xs={0} sm={2} md={2}></GridItem>
      </GridContainer>
    );
  } else if (offeringType === "learning lab") {
    return (
      <GridContainer>
        <GridItem xs={0} sm={2} md={2}></GridItem>
        <GridItem xs={12} sm={8} md={8}>
          <GridContainer justify="center" alignItems="center">
            <GridItem>
              <h3
                style={{
                  color: SECONDARY_COLOR,
                  fontWeight: "bold"
                }}
              >
                Learnings Labs
              </h3>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center" alignItems="center">
            <GridItem>
              <h3
                style={{
                  color: SECONDARY_COLOR,
                  fontWeight: "bold"
                }}
              >
                <LockIcon />
              </h3>
            </GridItem>
          </GridContainer>
          <ViewOffering
            list={[
              "Understand the SD-WAN basics and cisco SD-WAN solution.",
              "On-Demand Lab access.",
              "Self paced learning focused on skills development.",
              "Prerequisite: 1-Day Cisco SD-WAN Foundations Virtual Session."
            ]}
            icon={Learn}
            button={""}
            offeringType={offeringType}
          />
          <GridContainer justify="center" alignItems="center">
            <p
              style={{
                color: SECONDARY_COLOR,
                textDecoration: "underline",
                fontWeight: "bolder"
              }}
            >
              Looks like you haven’t signed up for any Learning Lab yet. Please
              contact your respective administrators to request access to
              Learning Lab.
            </p>
          </GridContainer>
        </GridItem>
        <GridItem xs={0} sm={2} md={2}></GridItem>
      </GridContainer>
    );
  } else if (offeringType === "poc") {
    return (
      <GridContainer>
        <GridItem xs={0} sm={2} md={2}></GridItem>
        <GridItem xs={12} sm={8} md={8}>
          <GridContainer justify="center" alignItems="center">
            <GridItem>
              <h3
                style={{
                  color: SECONDARY_COLOR,
                  fontWeight: "bold"
                }}
              >
                Sandbox
              </h3>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center" alignItems="center">
            <GridItem>
              <h3
                style={{
                  color: SECONDARY_COLOR,
                  fontWeight: "bold"
                }}
              >
                <LockIcon />
              </h3>
            </GridItem>
          </GridContainer>
          <ViewOffering
            list={[
              "Experiment with self service deployments.",
              "Test your workflow with realtime cloud orchestration",
              "Develop & Integrate with multi-vendor SDN/NFV solutions"
            ]}
            icon={SandboxImg}
            button={""}
            offeringType={offeringType}
          />
          <GridContainer justify="center" alignItems="center">
            <p
              style={{
                color: SECONDARY_COLOR,
                textDecoration: "underline",
                fontWeight: "bolder"
              }}
            >
              Looks like you haven’t signed up for any sandboxes yet. Please
              contact your respective administrators to request access to
              sandbox.
            </p>
          </GridContainer>
        </GridItem>
        <GridItem xs={0} sm={2} md={2}></GridItem>
      </GridContainer>
    );
  }
};

export default PlaceholderCard;
