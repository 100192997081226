import * as actions from "../actions/action";
const initialState = {
  profileObj: {
    token: null
  }
};

export const authreducer = (state = initialState, action) => {
  switch (action.type) {
    // saves auth token
    case actions.SAVE_TOKEN:
      return {
        ...state,
        profileObj: {
          ...state.profileObj,
          token: action.data.token
        }
      };
    // saves data from get_profile
    case actions.SAVE_PROFILE:
      return {
        ...state,
        profileObj: {
          ...state.profileObj,
          ...action.data
        }
      };
    case actions.THEME_CUSTOMIZE:
      return {
        ...state,
        themeData: action.data
      };
    case actions.LOGOUT: {
      return {
        ...state,
        profileObj: {}
      };
    }
    case actions.MYPROFILE:
      return {
        ...state,
        profileObj: {
          ...state.profileObj,
          ...action.data
        }
      };
    default:
      return state;
  }
};

const utilState = {
  showNotification: false,
  notificationMessage: "",
  notificationColor: "info",
  showLoader: false,
  showErrorPage: false
};
export const utilReducer = (state = utilState, action) => {
  switch (action.type) {
    case actions.SHOW_NOTIFICATION:
      return {
        ...state,
        showNotification: true,
        notificationColor: action.color,
        notificationMessage: action.message
      };
    case actions.HIDE_NOTIFICATION:
      return {
        ...state,
        showNotification: false
      };
    case actions.SHOW_LOADER:
      return {
        ...state,
        showLoader: true
      };
    case actions.HIDE_LOADER:
      return {
        ...state,
        showLoader: false
      };
    case actions.SHOW_ERROR_PAGE:
      return {
        ...state,
        showErrorPage: true
      };
    case actions.HIDE_ERROR_PAGE:
      return {
        ...state,
        showErrorPage: false
      };
    default:
      return state;
  }
};

const learningLabState = {
  navbarTitle: ""
};
export const learningLabReducer = (state = learningLabState, action) => {
  switch (action.type) {
    case actions.SET_NAVBAR_TITLE:
      return {
        ...state,
        navbarTitle: action.navbarTitle
      };
    default:
      return state;
  }
};

const designPageState = {
  templates: [],
  wizards: {}
};
export const designPageReducer = (state = designPageState, action) => {
  switch (action.type) {
    case actions.SET_TEMPLATES:
      return {
        ...state,
        templates: action.payload || false
      };
    case actions.SET_WIZARDS:
      return {
        ...state,
        wizards: action.payload || {}
      };
    default:
      return state;
  }
};