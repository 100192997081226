import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import cx from "classnames";
import { useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";

// material-ui icons
import Menu from "@material-ui/icons/Menu";

// core components
import AdminNavbarLinks from "./AdminNavbarLinks";
import Button from "components/CustomButtons/Button.js";
import OfferingExperienceFilter from "views/Components/ServiceOffering/OfferingExperienceFilter";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.js";
import { SECONDARY_COLOR } from "global_constants/app_constants";

const useStyles = makeStyles(styles);

export default function AdminNavbar(props) {
  const profileData = JSON.parse(localStorage.getItem("profileData"));
  let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
  let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
  let hasDomain = domain?.some((res) => userEmail.includes(res));
  const isInternal = hasDomain
  const account = (isInternal?profileData.account+"  - ":"") 
  const title = localStorage.getItem("Title") ? account+localStorage.getItem("Title") : account+"Criterion SDCloud Platform"
  const classes = useStyles();
  const { learningLab } = useSelector(state => state);
  const { color, rtlActive, brandText, history } = props;
  let customHeading = learningLab.navbarTitle || "";
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });
  const sidebarMinimize =
    classes.sidebarMinimize +
    " " +
    cx({
      [classes.sidebarMinimizeRTL]: rtlActive
    });
  const HeaderTitle = (
    <div className="page-title">
      {customHeading === "Home" || brandText === "Home" ?
        <h6 className={classes.headerTitle}>{title}</h6>
        :
        <h6 className={classes.headerTitle}>{customHeading || brandText}</h6>
      }
    </div>
  );
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          {customHeading === "Learning" ? (
            <>
              {HeaderTitle}
              {/*<OfferingExperienceFilter />*/}
            </>
          ) : (
            HeaderTitle
          )}
        </div>
        <Hidden smDown implementation="css">
          <AdminNavbarLinks rtlActive={rtlActive} history={history} />
        </Hidden>
        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

AdminNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func
};
