import React, { useEffect } from "react";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { FormControl, TextField } from "@material-ui/core";
import './accounts.css'
import { useDispatch } from "react-redux";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

// Styling
const useStyles = makeStyles((theme, styles) => ({
    root: {
        textAlign: "end"
    },
    labelRoot: {
        fontSize: "14px",
        padding: "40px 10px 0px 0px"
    },
}));

const InventoryEdit = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(SET_NAVBAR_TITLE("Account Inventory Edit"));
    }, []);

    return (
        <div>
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <div className="inventoryedit-card">
                        <h3 className="createaccount-widget-title">Product Permissions</h3>
                        <p className={classes.span}>Edit account Inventory details</p>
                        <Grid container className='box-inner-height mt-2'>
                            <Grid item xs={12} sm={12} md={12}>
                                <span>Permissions</span><br></br>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            disableRipple
                                            value={""}
                                            name={"permissions"}
                                            color="secondary"
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Name"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="name"
                                        variant="filled"
                                    // value={""}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Lab Hours"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="lab-hours"
                                        variant="filled"
                                    // value={""}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Consumed Hours"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="consumed-hours"
                                        variant="filled"
                                    // value={""}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Available Hours"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="available-hours"
                                        variant="filled"
                                    // value={""}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Allocated Hours"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="allocated-hours"
                                        variant="filled"
                                    // value={""}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}></Grid>
                            <h3 className="createaccount-widget-title">Audit Log</h3>

                            <Grid container className="audit-log">
                                <Grid item xs={12} sm={3} md={3}>
                                    <strong>Time Stamp</strong>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3}>
                                    <strong>Action by</strong>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3}>
                                    <strong>Available Hours</strong>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3}>
                                    <strong>Comments</strong>
                                </Grid>
                            </Grid>
                            <Grid container style={{ borderBottom: "1px solid lightgray"}}>
                                <Grid container className="audit-log-data">
                                    <Grid item xs={12} sm={3} md={3}>
                                        <span>29-06-2021 06:36</span>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3}>
                                        <span>TA230330303</span>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3}>
                                        <span>981</span>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3}>
                                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur</span>
                                    </Grid>
                                </Grid>
                                <Grid container className="audit-log-data">
                                    <Grid item xs={12} sm={3} md={3}>
                                        <span>29-06-2021 06:36</span>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3}>
                                        <span>TA230330303</span>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3}>
                                        <span>981</span>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={3}>
                                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={3} md={3}></Grid>
                                <Grid item xs={12} sm={3} md={3} style={{ padding: "0px 20px 0px 20px" }}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            disableElevation
                                            className={classes.btnStyle}
                                            disableFocusRipple
                                            type="submit"
                                        >
                                            Save
                                        </Button>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3} style={{ padding: "0px 20px 0px 20px" }}>
                                    <FormControl
                                        fullWidth
                                        className={classes.customInputStyle}
                                    >
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            disableElevation
                                            className={classes.btnStyle}
                                            disableFocusRipple
                                            type="submit"
                                        >
                                            Cancel
                                        </Button>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3} md={3}></Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default InventoryEdit;