import React from "react";
import Loader from "react-loader-spinner";
import { LOADER_TYPE } from "global_constants/app_constants";
import { SECONDARY_COLOR } from "global_constants/app_constants";
import PropTypes from "prop-types";
import { PRIMARY_COLOR } from "global_constants/app_constants";
import theme from "../../theme";
const LoaderHoc = ({ children, isLoading }) => {
  if (isLoading) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Loader
          type={LOADER_TYPE}
          color={theme.palette.secondary.main}
          width={50}
          height={50}
        />
      </div>
    );
  } else {
    return <>{children}</>;
  }
};

LoaderHoc.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.node
};
export default LoaderHoc;