import { labelColor } from "assets/jss/material-dashboard-pro-react";
import { skipTextColor } from "assets/jss/material-dashboard-pro-react";
import { inputBorderBottom } from "assets/jss/material-dashboard-pro-react";
import {
  whiteColor,
  grayColor,
  primaryColor,
  secondaryColor,
  inputBorderColor,
} from "assets/jss/material-dashboard-pro-react.js";
const textColor = {
  color: "#6f6f6f",
};
const loginPageStyle = (theme) => ({
  absoluteImg: {
    height: "114px",
    width: "114px",
    left: "56px",
    borderRadius: "0px",
    top: "28px",
    background: "black",
  },
  agree: {
    fontFamily: "Roboto",
    color: "black",
    fontSize: "14px",
  },
  InputUnderline: {
    "& .MuiInputBase-root": {
      fontFamily: "Roboto",
    },
    "& .MuiInput-underline": {
      "&:hover:not(.Mui-disabled):before": {
        borderBottomColor: "#F96B13",
        borderWidth: "thin",
      },
      "&:hover:after": {
        borderBottom: `1px solid "#F96B13"`,
        borderWidth: "thin",
      },
      "&:active:after": {
        borderBottom: `1px solid "#F96B13"`,
        borderWidth: "thin",
      },
      "&:after": {
        borderBottomColor: "#F96B13",
        borderWidth: "thin",
      },
    },
  },
  font: {
    fontFamily: `${theme.palette.fontList.selectedFont} !important`,
  },
  f1: {
    fontSize: "14px",
  },
  f2: {
    fontSize: "16px",
  },
  customTabRoot: {
    color: `#000`,
    "& .Mui-selected .MuiTab-wrapper": {
      color: `${theme.palette.secondary.main}`,
    },
    "& .MuiTab-wrapper": {
      fontFamily: theme.palette.fontList.selectedFont,
    },
  },
  scheduleBtnStyle: {
    color: `${theme.palette.secondary.main} !important`,
  },
  btnStyle: {
    "& .MuiButton-label": {
      fontFamily: "Roboto",
    },
    background: "#F96B13",
    "&:hover": {
      background: "#F96B13",
    },
  },
  outLinedBtnStyle: {
    "& .MuiButton-label": {
      fontFamily: "Roboto",
    },
    color: "#F96B13",
    border: "1px solid #F96B13",
    "&:hover": {
      border: "1px solid #F96B13",
      backgroundColor: "transparent",
    },
  },
  container: {
    ...textColor,
    width: "100%",
    height: "100%",
    margin: "0px !important",
  },
  inputMargin: {
    margin: 0,
  },
  loginGridItemContainer: {
    backgroundColor: "#FFFFFF",
    // height: "calc(100vh - 80px)",
    height: "100%",
    zIndex: "4",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
      // margin: "15px"
    },
  },
  labelRoot: {
    fontSize: "14px",
    fontFamily: "Roboto",
    "&.Mui-focused": {
      color: "#0F1A2B",
    },
  },
  advertisingGridItemContainer: {
    backgroundImage: "url(/assets/criterion_networks/img/background-image.png)",
    //  height: "calc(100vh - 80px)",
    //  height: "100%",
    height: "100vh",
    display: "flex",
    textAlign: "center",
    zIndex: "4",
    [theme.breakpoints.down("xs")]: {
      height: "100% !important",
    },
  },
  loginCard: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    minWidth: 0,
    wordWrap: "break-word",
    backgroundColor: "#fff",
    backgroundClip: "border-box",
  },
  loginContainer: {
    // [theme.breakpoints.down("xs")]: {
    //   padding: "15px",
    //   height: "auto"
    // }
    background: "#fff",
    // marginRight: "-15px",
    marginLeft: "-15px",
    flexWrap: "wrap",
  },
  noteStyles: {
    color: "#838383",
    fontSize: "13px",
    fontWeight: "300",
  },
  advertisingComponentUpperDiv: {
    minHeight: "20%",
    backgroundColor: "transparent",
    paddingRight: "10%",
    [theme.breakpoints.down("xs")]: {
      minHeight: "0px !important",
    },
  },
  advertisingComponentLowerDiv: {
    backgroundColor: "transparent",
    minHeight: "80%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      minHeight: "100% !important",
      backgroundColor: secondaryColor[0],
    },
  },
  brandingLogo: {
    display: "flex",
    justifyContent: "center",
    "& img": {
      width: "100px",
      marginBottom: "20px",
    },
  },
  loginLogoImage: {
    marginBottom: "5rem !important",
    marginTop: "5rem",
    marginLeft: "auto",
    marginRight: "auto",
    width: "100% !important",
  },
  brandImage: {
    float: "right",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  registerBtn: {},
  carouselContainer: {
    display: "inline-block",
    color: "white",
    alignSelf: "flex-start",
    margin: "40% 0 0 10%",
    [theme.breakpoints.down("xs")]: {
      alignSelf: "center",
      margin: "20% 5% 0 0",
    },
  },
  ListContainer: {
    display: "inline-block",
    color: "white",
    width: "300px",
    alignSelf: "flex-start",
    margin: "40% 0 0 10%",
    [theme.breakpoints.up("md")]: {
      margin: "30% 0 0 10%",
    },
    [theme.breakpoints.down("xs")]: {
      alignSelf: "center",
      margin: "20% 0 0 15px",
    },
  },
  listItem: {
    marginBottom: "18px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#ffffff1f",
    },
  },
  listData: {
    borderLeft: `2px solid ${primaryColor[0]}`,
    paddingLeft: "16px",
    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "19px",
    textTransform: "capitalize",
  },
  listHolderHeading: {
    marginBottom: "23px",
    fontSize: "14px",
    lineHeight: "16px",
    color: primaryColor[0],
    paddingLeft: "16px",
  },
  alertboxcontainer: {
    "box-sizing": "border-box",
    marginBottom: "40px",
    padding: "38px 25px",
    fontSize: "16px",
  },
  carouselScroller: {
    width: "38px",
    height: "0px",
    border: "2px solid #83A0CF",
  },
  carouselTitle: {
    fontSize: "18px",
    color: primaryColor[0],
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "21px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  carouselContent: {
    maxWidth: "60%",
    fontWeight: "normal",
    fontSize: "36px",
    lineHeight: "42px",
  },
  advertisingComponentContainer: {
    height: "100%",
    width: "100%",
    display: "block",
  },
  loginBtn: {
    width: "100%",
  },
  outlineBtn: {
    width: "100%",
    marginBottom: "15px",
    backgroundColor: "white",
    border: `1px solid ${primaryColor[0]}`,
    color: `${primaryColor[0]} !important`,
    boxShadow: "none",
  },
  textField: {
    marginTop: 0,
  },
  loginTitle: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "36px",
    lineHeight: "28px",
    color: secondaryColor[0],
    alignSelf: "center",
    marginBottom: "30px",
    marginTop: "30px",
    fontFamily: "Roboto",
  },
  textCenter: {
    textAlign: "center",
  },
  justifyContentCenter: {
    justifyContent: "center !important",
  },
  separator: {
    backgroundColor: "#C4C4C4",
    // margin: "37px 0px",
    height: "1px",
    padding: "0 8px 0px 8px !important",
  },
  ortext: {
    color: "#C4C4C4",
    fontFamily: "Roboto",
  },
  inviteText: {
    margin: "27px 0px 12px 0px",
    fontFamily: "Roboto",
  },
  inviteContainer: {},
  forgotPassword: {
    cursor: "pointer",
    color: primaryColor[0],
    fontFamily: "Roboto",
  },
  forgotPasswordContainer: {
    backgroundColor: "red",
  },

  customButtonClass: {
    "&,&:focus,&:hover": {
      color: whiteColor,
    },
    marginLeft: "5px",
    marginRight: "5px",
  },
  inputAdornment: {
    marginRight: "18px",
  },
  inputAdornmentIcon: {
    color: grayColor[6],
  },
  cardHidden: {
    opacity: "0",
    transform: "translate3d(0, -60px, 0)",
  },
  cardGrow: {
    opacity: "0",
    transform: "translateX(10px)",
  },
  cardHeader: {
    marginBottom: "20px",
  },
  socialLine: {
    padding: "0.9375rem 0",
  },
  cardBody: {
    padding: "20px",
    border: "20px",
    borderColor: "#067193",
  },
  sideline: {
    marginTop: "10%",
    width: "240%",
  },
  checkboxRoot: {
    alignSelf: "flex-start",
    "&:hover": {
      backgroundColor: "transparent !important",
    },
    "&.Mui-checked": {
      color: "#F96B13",
    },
  },
  fieldError: {
    fontFamily: theme.palette.fontList.selectedFont,
    fontSize: 14,
    fontWeight: 300,
    fontStyle: "normal",
    paddingTop: 3,
    color: "#DF0000",
  },
  icon: {
    borderRadius: 3,
    width: "1rem",
    height: "1rem",
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "none",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "none",
    },
  },
  checkedIcon: {
    backgroundColor: "#F96B13",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: "1rem",
      height: "1rem",
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#F96B13",
    },
  },
  customInputStyle: {
    paddingBottom: "15px",
    "& .MuiInput-underline": {
      "&:hover:not(.Mui-disabled):before": {
        borderBottomColor: primaryColor[0],
        borderWidth: "thin",
      },
      "&:hover:after": {
        borderBottom: `1px solid ${primaryColor[0]}`,
        borderWidth: "thin",
      },
      "&:active:after": {
        borderBottom: `1px solid ${primaryColor[0]}`,
        borderWidth: "thin",
      },
      "&:after": {
        borderBottomColor: inputBorderBottom,
        borderWidth: "thin",
      },
    },

    "& label.Mui-focused": {
      color: labelColor,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: inputBorderColor[0],
      },
      "&:hover": {
        borderBottomColor: primaryColor[0],
        borderWidth: "thin",
      },
      "&.Mui-focused fieldset": {
        borderColor: inputBorderColor[0],
      },
    },
  },
  customDropDownStyle: {
    marginTop: "15px",
    "& label.Mui-focused": {
      color: labelColor,
    },
  },
  select: {
    // borderColor: labelColor,
    "&:hover": {
      borderColor: inputBorderColor[0],
    },
    "&:after": {
      borderColor: primaryColor[0],
    },
  },
  skipthisstep: {
    color: skipTextColor,
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
  },
  updateSecurityQuestionBtn: {
    display: "flex",
    margin: "auto",
    marginTop: "25px",
    background: theme.palette.buttonColor.main,
  },
  SSOTitle: {
    fontSize: 36,
    fontWeight: "bold",
    color: secondaryColor[0],
    fontFamily: "Roboto",
  },
  subheading: {
    color: "#464646",
    fontSize: "14px",
    marginBottom: "40px",
    fontFamily: "Roboto",
  },
  alertboxcontainers: {
    borderRadius:"10px",
    boxSizing: "border-box",
    marginBottom: "15px",
    padding: "20px 25px",
    fontSize: "16px",
    color: "black",
    border: "1px solid #4FAD2B",
    background: "#E0F0DA",
    fontWeight: 500,
  },
  errorAlertBackground: {
    boxSizing: "border-box",
    borderRadius:"10px",
    marginBottom: "15px",
    padding: "20px 25px",
    fontSize: "16px",
    color: "#f80a41",
    border: "1px solid #DF0000",
    background: "rgba(223, 0, 0, 0.2)",
    fontWeight: 500,
  },
  // ...customCheckboxRadioSwitch
});

export default loginPageStyle;
