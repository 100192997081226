import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import {
  Button,
  TextField,
  Select,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import GridItem from "components/Grid/GridItem";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Grid from "@material-ui/core/Grid";
import ColorPicker from "material-ui-color-picker";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import theme from "theme";
import { Api } from "config/api";
import {
  SHOW_LOADER,
  HIDE_LOADER,
  SET_NAVBAR_TITLE,
} from "action_creators/actioncreator";
import {
  SDCloudCustomizeAPIWithToken,
  SDCloudBackendAPIWithToken,
} from "apis/backendAPI";
import IpInformation from "views/Accounts/IpInformation.js";
import AccountInformation from "views/Accounts/AccountInfo.js";
import DesignWizard from "./views/Contentmanagement/components/DesignWizard";
// import ElementCategories from "views/Accounts/ElementCategories";
import Archivecluster from "views/Accounts/archiveclusters.js";
import Automationscripts from "views/Accounts/AutomationScripts";
import Orchestration from "views/GcpOrchestration/orchestration";
import { useHistory } from 'react-router-dom';
import Chatbot from "views/Components/chatbot/Chatbot";

const useStyles = makeStyles(styles);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={4}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const CreateTemplateModal = ({
  visible,
  onClose,
  onCreateTemplate,
  is_multi_vm,
}) => {
  const dispatch = useDispatch();  
  const [opacity, setOpacity] = useState("");

  const [Primary, SetPrimary] = useState("");
  const [Secondary, SetSecondary] = useState("");
  const [ButtoColor, SetButtoColor] = useState("");
  const [PanelColor, SetPanelColor] = useState("");
  const [fontfamily, SetFontFamily] = useState("");
  const [disableLogo, SetdisableLogo] = useState(true);
  const [indexvalue, setIndexValue] = useState(0);
  const [ErrorMessage, setError] = React.useState({});
  const [accountId, setAccountId] = useState();
  const profileData = JSON.parse(localStorage.getItem("profileData"));
  const account = profileData.account;
  let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
  let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
  let hasDomain = domain?.some((res) => userEmail.includes(res));
  const [isInternal, setIsInternal] = useState(hasDomain);

  useEffect(() => {
    dispatch(SET_NAVBAR_TITLE(isInternal?account+"  - Settings":"Settings"));
  }, []);

  const [Title, SetTitle] = useState("");
  const [SubTitle, SetSubTitle] = useState("");
  const [BrandingLogo, SetBrandingLogo] = useState(
    "assets/criterion_networks/img/brand-logo.png"
  );
  const [BrandingLogoInv, SetBrandingLogoInv] = useState(
    "assets/criterion_networks/img/brand-logo.png"
  );
  const [BrandingLogoSmall, SetBrandingLogoSmall] = useState(
    "assets/criterion_networks/img/criterion-logo-mini.png"
  );
  const user_data = localStorage.getItem("profileData");
  const user_privilage = JSON.parse(localStorage.getItem("profileData")).privilege;
  const user_acc = JSON.parse(localStorage.getItem("profileData")).account;
  

  let errors = {};
  const classes = useStyles();
  const columns = [
    {
      Header: "SKU ID",
      accessor: "sku_id",
    },
    {
      Header: "SKU Name",
      accessor: "sku_name",
    },
    {
      Header: "Display Name",
      accessor:"display_name",
    },
    {
      Header: "Account Name",
      accessor: "account",
    },
    {
      Header: "Created On",
      accessor: "created_on",
    },
    {
      Header: "View",
      accessor: "view",
    },
  ];

  const isvalid = () => {
    errors = {};
    let formvalid = true;
    if (!String(Title).trim()) {
      errors["Title"] = "Title cannot be empty";
      setError(errors);
      formvalid = false;
    }
    if (!String(SubTitle).trim()) {
      errors["SubTitle"] = "Subtitle cannot be empty";
      setError(errors);
      formvalid = false;
    }
    if (!String(BrandingLogo).trim()) {
      errors["BrandingLogo"] = "Branding logo cannot be empty";
      setError(errors);
      formvalid = false;
    }
    if (!String(BrandingLogoInv).trim()) {
      errors["BrandingLogoInv"] = "Branding logo inv cannot be empty";
      setError(errors);
      formvalid = false;
    }
    if (!String(BrandingLogoSmall).trim()) {
      errors["BrandingLogoSmall"] = "Branding logo small cannot be empty";
      setError(errors);
      formvalid = false;
    }
    if (!String(Primary).trim()) {
      errors["Primary"] = "Primary color cannot be empty";
      setError(errors);
      formvalid = false;
    }
    if (!String(Secondary).trim()) {
      errors["Secondary"] = "Secondary color cannot be empty";
      setError(errors);
      formvalid = false;
    }
    if (!String(ButtoColor).trim()) {
      errors["ButtoColor"] = "Button color cannot be empty";
      setError(errors);
      formvalid = false;
    }
    if (!String(fontfamily).trim()) {
      errors["FontFamily"] = "Font family cannot be empty";
      setError(errors);
      formvalid = false;
    }
    // if (!String(PanelColor).trim()) {
    //   errors["SubTitle"] = "PanelColor cannot be empty";
    //   setError(errors);
    //   formvalid = false;
    // }
    return formvalid;
  };

  const setDefault = (id) => {
    if (id === "Title") {
      localStorage.removeItem("Title");
      SetTitle("Criterion SDCloud Platform");
    }
    if (id === "SubTitle") {
      localStorage.removeItem("SubTitle");
      SetSubTitle("Learning Center. Experience. Design Center");
    }
    if (id === "BrandLogo") {
      localStorage.removeItem("BrandLogo");
      SetBrandingLogo("assets/criterion_networks/img/brand-logo.png");
    }
    if (id === "BrandLogoInv") {
      localStorage.removeItem("BrandLogoInv");
      SetBrandingLogoInv("assets/criterion_networks/img/brand-logo.png");
    }
    if (id === "BrandLogoSmall") {
      localStorage.removeItem("BrandLogoSmall");
      SetBrandingLogoSmall(
        "assets/criterion_networks/img/criterion-logo-mini.png"
      );
    }
    if (id === "PrimaryColor") {
      localStorage.removeItem("PrimaryColor");
      SetPrimary("#0F1A2B");
      document.getElementById("primary").value = "#0F1A2B";
      document.getElementById("primary").style.color = "#0F1A2B";
    }
    if (id === "SecondaryColor") {
      localStorage.removeItem("SecondaryColor");
      SetSecondary("#F96B13");
      document.getElementById("secondary").value = "#F96B13";
      document.getElementById("secondary").style.color = "#F96B13";
    }
    if (id === "ButtonColor") {
      localStorage.removeItem("ButtonColor");
      SetButtoColor("#F96B13");
      document.getElementById("buttoncolor").value = "#F96B13";
      document.getElementById("buttoncolor").style.color = "#F96B13";
    }
    // if (id === "PanelColor") {
    //   localStorage.removeItem("PanelColor");
    //   SetPanelColor("#15253D")
    //   document.getElementById("buttoncolor").value = "#0F1A2B";
    //   document.getElementById("buttoncolor").style.color = "#0F1A2B";
    // }
    if (id === "FontFamily") {
      localStorage.removeItem("FontFamily");
      SetFontFamily("Roboto");
    }
  };
  useEffect(() => {
    getSettingsData();
    var account = profileData.account;
    if(account =='TataCommunication')
    {
      SetdisableLogo(false)
    }
  }, []);

  const getSettingsData = () => {
    dispatch(SHOW_LOADER());
    SDCloudCustomizeAPIWithToken()
      .get(Api.CustomizeAccount)
      .then((response) => {
        dispatch(HIDE_LOADER());
        let profileData = JSON.parse(localStorage.getItem("profileData"));
        if (response.data.results.length > 0) {
          let filterData = response.data.results.filter(
            (f) => f.name == profileData.account
          );
          if (filterData.length > 0) {
            setAccountId(filterData[0].id);
            dispatch(SHOW_LOADER());
            SDCloudCustomizeAPIWithToken()
              .get(Api.dashboardCustomize + "" + filterData[0].id + "/")
              .then((response2) => {
                dispatch(HIDE_LOADER());
                SetTitle(response2.data.title);
                SetSubTitle(response2.data.subtitle);
                SetBrandingLogo(response2.data.branding_logo);
                SetBrandingLogoInv(response2.data.branding_logo_inv);
                SetBrandingLogoSmall(response2.data.branding_logo_small);
                SetPrimary(response2.data.primary_color_code);
                document.getElementById("primary").value =
                  response2.data.primary_color_code;
                document.getElementById("primary").style.color =
                  response2.data.primary_color_code;
                SetSecondary(response2.data.secondary_color_code);
                document.getElementById("secondary").value =
                  response2.data.secondary_color_code;
                document.getElementById("secondary").style.color =
                  response2.data.secondary_color_code;
                SetButtoColor(response2.data.button_color_code);
                document.getElementById("buttoncolor").value =
                  response2.data.button_color_code;
                document.getElementById("buttoncolor").style.color =
                  response2.data.button_color_code;
                // SetPanelColor(response2.data.panel_color_code);
                // document.getElementById("panelcolor").value = response2.data.button_color_code;
                // document.getElementById("panelcolor").style.color = response2.data.button_color_code;
                let font =
                  response2.data.font_family &&
                  response2.data.font_family !== ""
                    ? response2.data.font_family
                    : "Roboto";
                SetFontFamily(font);
              })
              .catch((err2) => {
                dispatch(HIDE_LOADER());
                //console.log(err2.response);
              });
          }
        }
        //console.log(response);
      })
      .catch((err) => {
        dispatch(HIDE_LOADER());
        //console.log(err.response);
      });
  };

  const UpdateSettings = () => {
    if (isvalid()) {
      dispatch(SHOW_LOADER());
      SDCloudCustomizeAPIWithToken()
        .get(Api.dashboardCustomize, {
          headers: {
            "Content-type": "application/json",
            Authorization: `token ${localStorage.getItem("user")}`,
          },
        })
        .then((response2) => {
          dispatch(HIDE_LOADER());
          let filterData = response2.data.results.filter(
            (f) => f.id === accountId
          );
          if (filterData.length === 0) {
            const inputData1 = {
              title: Title,
              subtitle: SubTitle,
              content: "",
              created: "",
              modified: "",
              branding_logo: BrandingLogo,
              branding_logo_inv: BrandingLogoInv,
              branding_logo_small: BrandingLogoSmall,
              primary_color_code: Primary,
              secondary_color_code: Secondary,
              button_color_code: ButtoColor,
              panel_color_code: PanelColor,
              font_family: fontfamily,
              account: [accountId],
            };
            dispatch(SHOW_LOADER());
            SDCloudCustomizeAPIWithToken()
              .post(Api.dashboardCustomize, inputData1)
              .then((response2) => {
                dispatch(HIDE_LOADER());
                if (response2.data.title !== "") {
                  localStorage.setItem("Title", response2.data.title);
                }
                if (response2.data.subtitle !== "") {
                  localStorage.setItem("SubTitle", response2.data.subtitle);
                }
                if (response2.data.primary_color_code !== "") {
                  localStorage.setItem(
                    "PrimaryColor",
                    response2.data.primary_color_code
                  );
                }
                if (response2.data.secondary_color_code !== "") {
                  localStorage.setItem(
                    "SecondaryColor",
                    response2.data.secondary_color_code
                  );
                }
                if (response2.data.button_color_code !== "") {
                  localStorage.setItem(
                    "ButtonColor",
                    response2.data.button_color_code
                  );
                }
                if (response2.data.panel_color_code !== "") {
                  localStorage.setItem(
                    "PanelColor",
                    response2.data.panel_color_code
                  );
                }
                if (response2.data.branding_logo !== "") {
                  localStorage.setItem(
                    "BrandLogo",
                    response2.data.branding_logo
                  );
                }
                if (response2.data.branding_logo_inv !== "") {
                  localStorage.setItem(
                    "BrandLogoInv",
                    response2.data.branding_logo_inv
                  );
                }
                if (response2.data.branding_logo_small !== "") {
                  localStorage.setItem(
                    "BrandLogoSmall",
                    response2.data.branding_logo_small
                  );
                }
                if (
                  response2.data.font_family &&
                  response2.data.font_family !== ""
                ) {
                  localStorage.setItem(
                    "FontFamily",
                    response2.data.font_family
                  );
                }
                window.location.reload();
              })
              .catch((err) => {
                dispatch(HIDE_LOADER());
                //console.log(err.response);
              });
          } else {
            const inputData2 = {
              id: filterData[0].id,
              title: Title,
              subtitle: SubTitle,
              content: "",
              created: "",
              modified: "",
              branding_logo: BrandingLogo,
              branding_logo_inv: BrandingLogoInv,
              branding_logo_small: BrandingLogoSmall,
              primary_color_code: Primary,
              secondary_color_code: Secondary,
              button_color_code: ButtoColor,
              panel_color_code: PanelColor,
              font_family: fontfamily,
              account: [accountId],
            };
            dispatch(SHOW_LOADER());
            SDCloudCustomizeAPIWithToken()
              .put(
                Api.dashboardCustomize + "" + filterData[0].id + "/",
                inputData2
              )
              .then((response2) => {
                dispatch(HIDE_LOADER());
                if (response2.data.title !== "") {
                  localStorage.setItem("Title", response2.data.title);
                }
                if (response2.data.subtitle !== "") {
                  localStorage.setItem("SubTitle", response2.data.subtitle);
                }
                if (response2.data.primary_color_code !== "") {
                  localStorage.setItem(
                    "PrimaryColor",
                    response2.data.primary_color_code
                  );
                }
                if (response2.data.secondary_color_code !== "") {
                  localStorage.setItem(
                    "SecondaryColor",
                    response2.data.secondary_color_code
                  );
                }
                if (response2.data.button_color_code !== "") {
                  localStorage.setItem(
                    "ButtonColor",
                    response2.data.button_color_code
                  );
                }
                if (response2.data.panel_color_code !== "") {
                  localStorage.setItem(
                    "PanelColor",
                    response2.data.panel_color_code
                  );
                }
                if (response2.data.branding_logo !== "") {
                  localStorage.setItem(
                    "BrandLogo",
                    response2.data.branding_logo
                  );
                }
                if (response2.data.branding_logo_inv !== "") {
                  localStorage.setItem(
                    "BrandLogoInv",
                    response2.data.branding_logo_inv
                  );
                }
                if (response2.data.branding_logo_small !== "") {
                  localStorage.setItem(
                    "BrandLogoSmall",
                    response2.data.branding_logo_small
                  );
                }
                if (
                  response2.data.font_family &&
                  response2.data.font_family !== ""
                ) {
                  localStorage.setItem(
                    "FontFamily",
                    response2.data.font_family
                  );
                }
                window.location.reload();
              })
              .catch((err) => {
                dispatch(HIDE_LOADER());
                //console.log(err.response);
              });
          }
        })
        .catch((err2) => {
          dispatch(HIDE_LOADER());
          //console.log(err2.response);
        });
    }
  };

  const ColorSet = (color, type) => {
    if (color != null && color != undefined) {
      type == "primary"
        ? SetPrimary(color)
        : type == "secondary"
        ? SetSecondary(color)
        : type == "buttoncolor"
        ? SetButtoColor(color)
        : SetPanelColor(color);
      document.getElementById(type).value = color;
      document.getElementById(type).style.color = color;
      // //console.log("yes", color);
    }
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleHeaderClick = (selectedType) => {
    if (selectedType === "ThemeCustomize") {
    }
  };
  const handleChange = (event, newValue) => {
    setIndexValue(newValue);
  };
  return (
    <>
      <AppBar position="static">
        <Tabs
          value={indexvalue}
          classes={{
            root: classes.customTabRoot,
          }}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab
            label="Customize theme"
            {...a11yProps(0)}
            // onClick={handleHeaderClick("ThemeCustomize")}
          />
          <Tab
            label="IP information"
            {...a11yProps(1)}
            // onClick={handleHeaderClick("ThemeCustomize")}
          />
          <Tab
            label="Account information"
            {...a11yProps(2)}
            // onClick={getAccountInfo}
            // onClick={handleHeaderClick("ThemeCustomize")}
          />
          <Tab
            label="Wizard Templates"
            {...a11yProps(3)}
            // onClick={getAccountInfo}
            // onClick={handleHeaderClick("ThemeCustomize")}
          />
          {/* <Tab
            label="Element Categories"
            {...a11yProps(4)}
          /> */}
          <Tab
            label="Archived Clusters"
            {...a11yProps(4)}
            // onClick={getAccountInfo}
            // onClick={handleHeaderClick("ThemeCustomize")}
          />
           {isInternal ?(
          <Tab
            label=" Automation Scripts "
            {...a11yProps(5)}
            // onClick={getAccountInfo}
            // onClick={handleHeaderClick("ThemeCustomize")}
          />): null}
            { user_data && user_privilage === 'admin' && (user_acc === 'CN' | user_acc === 'Criterion Enterprise') ? (
          <Tab
            label=" GCP-Resource Monitoring "
            {...a11yProps(isInternal?6:5)}
            // onClick={getAccountInfo}
            // onClick={handleHeaderClick("ThemeCustomize")}
          />): null}
        </Tabs>
      </AppBar>
      <Chatbot/>
      <TabPanel value={indexvalue} index={0}>
        <Grid item xs={12} sm={12}>
          <div
            className="home-card"
            style={{ padding: "0 20px 20px 20px", marginTop: "20px" }}
          >
            <Grid container>
              <GridItem className="" md={9}>
                <TextField
                  fullWidth
                  label="Title"
                  InputLabelProps={{
                    disableAnimation: true,
                    shrink: false,
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  className={classes.inputMargin}
                  defaultValue="Criterion SDCloud Platform"
                  value={Title}
                  onChange={(e) => SetTitle(e.target.value)}
                />
                <span className="field-error" style={{ color: "#DF0000" }}>
                  {ErrorMessage["Title"]}
                </span>
              </GridItem>
              <GridItem className="" md={3}>
                <div style={{ paddingTop: "49px", float: "right" }}>
                  <a
                    className={classes.linkStyle}
                    onClick={() => setDefault("Title")}
                  >
                    Set Default
                  </a>
                </div>
              </GridItem>
            </Grid>
            <Grid container>
              <GridItem className="" md={9}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    disableAnimation: true,
                    shrink: false,
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  label="Subtitle"
                  className={classes.inputMargin}
                  defaultValue="Learning Center. Experience. Design Center"
                  value={SubTitle}
                  onChange={(e) => SetSubTitle(e.target.value)}
                />
                <span className="field-error" style={{ color: "#DF0000" }}>
                  {ErrorMessage["SubTitle"]}
                </span>
              </GridItem>
              <GridItem className="" md={3}>
                <div style={{ paddingTop: "49px", float: "right" }}>
                  <a
                    className={classes.linkStyle}
                    onClick={() => setDefault("SubTitle")}
                  >
                    Set Default
                  </a>
                </div>
              </GridItem>
            </Grid>
            <Grid container>
              <GridItem className="" md={9}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    disableAnimation: true,
                    shrink: false,
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  label="Branding Logo"
                  className={classes.inputMargin}
                  disabled={disableLogo}
                  defaultValue="URL"
                  value={BrandingLogo}
                  onChange={(e) => SetBrandingLogo(e.target.value)}
                />
                <span className="field-error" style={{ fontSize: "12px" }}>
                  Recommended image size: 558 x 124
                </span>
                <br></br>
                <span className="field-error" style={{ color: "#DF0000" }}>
                  {ErrorMessage["BrandingLogo"]}
                </span>
              </GridItem>
              <GridItem className="" md={3}>
                <div style={{ paddingTop: "49px", float: "right" }}>
                  <a
                    className={classes.linkStyle}
                    onClick={() => setDefault("BrandLogo")}
                    style={{
                      pointerEvents: "none",
                      cursor: "default",
                      opacity: "0.6",
                    }}
                  >
                    Set Default
                  </a>
                </div>
              </GridItem>
            </Grid>
            <Grid container>
              <GridItem className="" md={9}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    disableAnimation: true,
                    shrink: false,
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  label="Branding Logo Inv"
                  disabled={disableLogo}
                  className={classes.inputMargin}
                  defaultValue="URL"
                  value={BrandingLogoInv}
                  onChange={(e) => SetBrandingLogoInv(e.target.value)}
                />
                <span className="field-error" style={{ fontSize: "12px" }}>
                  Recommended image size: 558 x 124
                </span>
                <br></br>
                <span className="field-error" style={{ color: "#DF0000" }}>
                  {ErrorMessage["BrandingLogoInv"]}
                </span>
              </GridItem>
              <GridItem className="" md={3}>
                <div style={{ paddingTop: "49px", float: "right" }}>
                  <a
                    className={classes.linkStyle}
                    onClick={() => setDefault("BrandLogoInv")}
                    style={{
                      pointerEvents: "none",
                      cursor: "default",
                      opacity: "0.6",
                    }}
                  >
                    Set Default
                  </a>
                </div>
              </GridItem>
            </Grid>
            <Grid container>
              <GridItem className="" md={9}>
                <TextField
                  fullWidth
                  InputLabelProps={{
                    disableAnimation: true,
                    shrink: false,
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  label="Branding Logo Small"
                  disabled={disableLogo}
                  className={classes.inputMargin}
                  defaultValue="URL"
                  value={BrandingLogoSmall}
                  onChange={(e) => SetBrandingLogoSmall(e.target.value)}
                />
                <span className="field-error" style={{ fontSize: "12px" }}>
                  Recommended image size: 111 x 111
                </span>
                <br></br>
                <span className="field-error" style={{ color: "#DF0000" }}>
                  {ErrorMessage["BrandingLogoSmall"]}
                </span>
              </GridItem>
              <GridItem className="" md={3}>
                <div style={{ paddingTop: "49px", float: "right" }}>
                  <a
                    className={classes.linkStyle}
                    onClick={() => setDefault("BrandLogoSmall")}
                    style={{
                      pointerEvents: "none",
                      cursor: "default",
                      opacity: "0.6",
                    }}
                  >
                    Set Default
                  </a>
                </div>
              </GridItem>
            </Grid>
            <Grid container>
              <GridItem className="" md={9}>
                <ColorPicker
                  fullWidth
                  label="Primary Color Code "
                  id="primary"
                  name="color"
                  defaultValue={Primary}
                  InputLabelProps={{
                    disableAnimation: true,
                    shrink: false,
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  className={classes.inputMargin}
                  onChange={(color) => ColorSet(color, "primary")}
                />
                <span className="field-error" style={{ color: "#DF0000" }}>
                  {ErrorMessage["Primary"]}
                </span>
              </GridItem>
              <GridItem className="" md={3}>
                <div style={{ paddingTop: "49px", float: "right" }}>
                  <a
                    className={classes.linkStyle}
                    onClick={() => setDefault("PrimaryColor")}
                  >
                    Set Default
                  </a>
                </div>
              </GridItem>
            </Grid>
            <Grid container>
              <GridItem className="" md={9}>
                <ColorPicker
                  fullWidth
                  label="Secondary Color Code"
                  id="secondary"
                  name="color"
                  defaultValue={Secondary}
                  InputLabelProps={{
                    disableAnimation: true,
                    shrink: false,
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  className={classes.inputMargin}
                  //  value={Secondary}
                  onChange={(color) => ColorSet(color, "secondary")}
                />
                <span className="field-error" style={{ color: "#DF0000" }}>
                  {ErrorMessage["Secondary"]}
                </span>
              </GridItem>
              <GridItem className="" md={3}>
                <div style={{ paddingTop: "49px", float: "right" }}>
                  <a
                    className={classes.linkStyle}
                    onClick={() => setDefault("SecondaryColor")}
                  >
                    Set Default
                  </a>
                </div>
              </GridItem>
            </Grid>
            <Grid container>
              <GridItem className="" md={9}>
                <ColorPicker
                  fullWidth
                  label="Button Color Code"
                  id="buttoncolor"
                  name="color"
                  defaultValue={ButtoColor}
                  InputLabelProps={{
                    disableAnimation: true,
                    shrink: false,
                    classes: {
                      root: classes.labelRoot,
                    },
                  }}
                  className={classes.inputMargin}
                  // value={ButtoColor}
                  onChange={(color) => ColorSet(color, "buttoncolor")}
                />
                <span className="field-error" style={{ color: "#DF0000" }}>
                  {ErrorMessage["ButtoColor"]}
                </span>
              </GridItem>
              <GridItem className="" md={3}>
                <div style={{ paddingTop: "49px", float: "right" }}>
                  <a
                    className={classes.linkStyle}
                    onClick={() => setDefault("ButtonColor")}
                  >
                    Set Default
                  </a>
                </div>
              </GridItem>
            </Grid>
            <Grid container>
              <GridItem className="" md={9}>
                <FormControl fullWidth className={classes.inputMargin}>
                  <InputLabel
                    classes={{
                      root: classes.labelRoot,
                    }}
                    disableAnimation={true}
                    shrink={false}
                  >
                    Font Family
                  </InputLabel>
                  <Select
                    fullWidth
                    name="deal_id"
                    defaultValue={fontfamily}
                    value={fontfamily}
                    onChange={(e) => SetFontFamily(e.target.value)}
                    native
                  >
                    {theme.palette.fontList.fonts.map((x, index) => (
                      <option key={index} value={x}>
                        {x}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <span className="field-error" style={{ color: "#DF0000" }}>
                  {ErrorMessage["FontFamily"]}
                </span>
              </GridItem>
              <GridItem className="" md={3}>
                <div style={{ paddingTop: "49px", float: "right" }}>
                  <a
                    className={classes.linkStyle}
                    onClick={() => setDefault("FontFamily")}
                  >
                    Set Default
                  </a>
                </div>
              </GridItem>
            </Grid>
            {/* <Grid container>
              <GridItem className="" md={9}>
                <ColorPicker fullWidth label="Panel Color Code" id="panelcolor"
                  name="color"
                  defaultValue={PanelColor}
                  InputLabelProps={{
                    disableAnimation: true,
                    shrink: false,
                    classes: {
                      root: classes.labelRoot
                    }
                  }}
                  className={classes.inputMargin}
                  //  value={PanelColor}
                  onChange={color => ColorSet(color, "panelcolor")}
                />
              </GridItem>
              <GridItem className="" md={3}>
                <div style={{ paddingTop: "49px", float: "right" }}>
                  <a className={classes.linkStyle} onClick={() => setDefault("PanelColor")}>Set Default</a>
                </div>
              </GridItem>
            </Grid> */}
            <GridItem className="mt-2" md={3}>
              <Button
                onClick={() => UpdateSettings()}
                variant="contained"
                color="secondary"
                disableElevation
                size="large"
                fullWidth
                disableFocusRipple
                className={classes.backColor}
                type="button"
                style={{ cursor: "pointer" }}
              >
                Apply
              </Button>
              {/* <button className={`button goto-button ${classes.backColor}`} onClick={() => UpdateSettings()} style={{ cursor: "pointer" }} >Apply</button> */}
            </GridItem>
          </div>
        </Grid>
      </TabPanel>
      <TabPanel value={indexvalue} index={1}>
        <Grid item xs={12} sm={12}>
          <IpInformation />
        </Grid>
      </TabPanel>
      <TabPanel value={indexvalue} index={2}>
        <Grid item xs={12} sm={12} style={{ opacity: opacity }}>
          <AccountInformation
            setOpacity={setOpacity}
            onClick={() => AccountInformation()}
          />
        </Grid>
      </TabPanel>
      <TabPanel value={indexvalue} index={3}>
        <Grid item xs={12} sm={12} style={{ opacity: opacity }}>
          <DesignWizard setOpacity={setOpacity} />
        </Grid>
      </TabPanel>
      {/* <TabPanel value={indexvalue} index={4}>
        <Grid item xs={12} sm={12} style={{ opacity: opacity }}>
        <ElementCategories />
        </Grid>
      </TabPanel> */}
      <TabPanel value={indexvalue} index={4}>
        <Grid item xs={12} sm={12}>
          <Archivecluster/>
        </Grid>
      </TabPanel>
      {isInternal && (
      <TabPanel value={indexvalue} index={5}>
        <Grid item xs={12} sm={12}>
          <Automationscripts/>
        </Grid>
      </TabPanel>
      )}
      <TabPanel value={indexvalue} index={isInternal?6:5}>
        <Grid item xs={12} sm={12}>
          <Orchestration/>
        </Grid>
      </TabPanel>
      
    </>
  );
};

export default withRouter(CreateTemplateModal);
