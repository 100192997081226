import React from "react";

// Material UI Components
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";

// Icons
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import supportSvg from "../../assets/criterion_networks/svg/support-icon.svg";

// Custom Components
import RightSideDrawer from "./SubcriptionRightSideDrawer.js";


const ExpansionPanel = withStyles(theme => ({
  root: {
    // border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0
    },
    "&:before": {
      display: "none"
    },
    "&$expanded": {
      margin: "auto"
    }
  },
  expanded: {}
}))(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    padding: "0",
    minHeight: 56,
    "&$expanded": {
      minHeight: 56
    },
    flexDirection: "row-reverse",
    "& .MuiIconButton-root": {
      marginRight: "20px"
    },
    "& .MuiExpansionPanelSummary-expandIcon.Mui-expanded": {
      transform: "rotate(90deg)"
    }
  },
  content: {
    "&$expanded": {
      margin: "12px 0"
    }
  },
  expanded: {}
}))(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    display: "block"
  }
}))(MuiExpansionPanelDetails);

const useStyles = makeStyles(theme => ({
  container: {
    padding: "15px 5px"
  },
  header: {
    padding: "10px 10px 30px 10px"
  },
  helpIcon: {
    width: "23px !important",
    height: "23px !important"
  },
  title: {
    lineHeight: "5px"
  }
}));

export default function UpgradeSubscription({ toggleDrawer, expanded , upgradetype }) {
  const classes = useStyles();

  const Header = ({ toggleDrawer, expanded }) => {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.header}
      >
       
          <Grid item xs={1}>
            <Grid container justify="flex-start" alignItems="center">
              <img
                src={supportSvg}
                alt="Support"
                className={classes.helpIcon}
              />
            </Grid>
          </Grid>
        
        
          <Grid item xs={9} className={classes.title}>
            Upgrade Subscription
          </Grid>
        <Grid item xs={2}>
          <Grid
            container
            justify="flex-end"
            alignItems="center"
            onClick={() => toggleDrawer()}
            style={{ cursor: "pointer" }}
          >
            <CloseIcon />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <div>
      <Grid
        container
        className={classes.container}
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Header toggleDrawer={toggleDrawer} expanded={expanded} />
            <ExpansionPanel square expanded={expanded === "support"}>
              <ExpansionPanelDetails>
                <CssBaseline>
                  <RightSideDrawer upgradetype={upgradetype}/>
                </CssBaseline>
              </ExpansionPanelDetails>
            </ExpansionPanel>
        </Grid>
      </Grid>
    </div>
  );
}
