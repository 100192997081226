import React, { useEffect, useState } from "react";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

// Local
import Menu from "../Components/Menu";
// import ManageUsers from "./components/ManageUsers";
import Freeze from "./components/Freeze";
import Watermarking from "./components/Watermarking";
// import Freezeold from "./components/Freezeold";
// import PermissionProfile from "./components/PermissionProfile";
import { useDispatch } from "react-redux";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import S3upload from "./components/S3upload";
import queryString from "query-string";
import { Redirect, Route } from "react-router-dom";
import Chatbot from "views/Components/chatbot/Chatbot";
// Styling
const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "50px"
  }
}));

const Contentmanagement = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const profileData = JSON.parse(localStorage.getItem("profileData"));
  const account = profileData.account;
  let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
  let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
  let hasDomain = domain?.some((res) => userEmail.includes(res));
  const [isInternal, setIsInternal] = useState(hasDomain);
  useEffect(() => {
    dispatch(SET_NAVBAR_TITLE(isInternal?account+"  - Content Management":"Content Management"));
    let queryParams = queryString.parse(window.location.search);
    if (queryParams?.option == "freeze") {
      setCurrentScreen("Lab creation");
    }
  }, []);
  // States

  const [currentScreen, setCurrentScreen] = useState("s3 Upload");
  if (JSON.parse(localStorage.profileData).is_ui_admin) {
    // var content_admin = "";
    var content_admin2 = "";
    var s3_upload = "";
    var freeze = "";
    // var freezeold = "";
    var watermarking = "";
    var inventorymanagement = "";

    // content_admin = "Content Admin";
    content_admin2 = "Content Admin";
    s3_upload = "s3 Upload";
    freeze = "Lab creation";
    // freezeold = "Freeze"
    watermarking = "Watermarking";
    inventorymanagement = "Inventory Management";

    const menuItems = [
      s3_upload,
      freeze,
      // freezeold,
      watermarking,
      inventorymanagement,
      // content_admin,
      content_admin2
    ];
    const REDIRECT_URL = window.REACT_APP_REDIRECT_URL + "users/admin-redirect";
    const user_name = localStorage.userName;
    const renderContent = () => {
      switch (currentScreen) {
        case "s3 Upload":
          return <S3upload />;
        case "Lab creation":
          return <Freeze />;
        // case "Freeze":
        //   return <Freezeold />;
        case "Watermarking":
          return <Watermarking />;
        case "Inventory Management":
          return (
            <Route>
              <Redirect to="/inventorymanagement"></Redirect>
            </Route>
          );
        // case "Content Admin":
        //   openInNewTab(
        //     REDIRECT_URL +
        //       "?tkn=" +
        //       localStorage.getItem("user") +
        //       "&username=" +
        //       user_name
        //   );
        case "Content Admin":
          return (
            <Route>
              <Redirect to="/product-admin"></Redirect>
            </Route>
          );  
          setCurrentScreen("s3 Upload");
      }
    };

    const openInNewTab = url => {
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    };

    return (
      <div>
        <Grid container className={classes.root}>
          <Grid item md={2}>
            <Menu
              items={menuItems}
              onItemClick={setCurrentScreen}
              currentScreen={currentScreen}
            />
          </Grid>

          <Grid item md={10}>
            {renderContent()}
          </Grid>
        </Grid>
        <Chatbot/>
      </div>
    );
  } else {
    return (
      <Route>
        <Redirect to="/dashboard"></Redirect>
      </Route>
    );
  }
};

export default Contentmanagement;
