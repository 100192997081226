import React, { useEffect } from "react";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { FormControl, TextField } from "@material-ui/core";
import './accounts.css'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";

// Styling
const useStyles = makeStyles((theme, styles) => ({
    root: {
        textAlign: "end"
    },
    btnStyle: {
        background: `${theme.palette.buttonColor.main} !important`,
        height: "40px"
        // padding: "20px 150px"
    },
    rootbottom: {
        textAlign: "center",
        fontWeight: 400,
        color: "gray"
    },
    span: {
        color: "gray"
    },
    checkboxRoot: {
        alignSelf: "flex-start",
        "&:hover": {
            backgroundColor: "transparent !important"
        },
    }, icon: {
        borderRadius: 3,
        width: "1rem",
        height: "1rem",
        boxShadow:
            "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "#f5f8fa",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2
        },
        "input:hover ~ &": {
            backgroundColor: "none"
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "none"
        }
    },
    checkedIcon: {
        backgroundColor: theme.palette.secondary.main,
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&:before": {
            display: "block",
            width: "1rem",
            height: "1rem",
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""'
        },
        "input:hover ~ &": {
            backgroundColor: theme.palette.secondary.main
        }
    },
    labelRoot: {
        fontSize: "14px",
        padding: "40px 10px 0px 0px"
    },
}));

const CreateAccount = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(SET_NAVBAR_TITLE("Create Account"));
      }, []);

    return (
        <div>
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <div className="createaccount-card">
                        <h3 className="createaccount-widget-title">Create Account</h3>
                        <p className={classes.span}>Enter text field for creating new account</p>
                        <Grid container className='box-inner-height mt-2'>
                            <Grid item xs={12} sm={4}>
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Customer Name"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="customer-name"
                                        variant="filled"
                                    // value={""}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="PIC Name"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="pic-name"
                                        variant="filled"
                                    // value={""}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="PIC Mail ID"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="email"
                                        variant="filled"
                                    // value={""}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Country"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="country"
                                        variant="filled"
                                    // value={""}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Deal ID"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="deal-id"
                                        variant="filled"
                                    // value={""}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}></Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            className={classes.checkboxRoot}
                                            color="secondary"
                                            disableRipple
                                            // checked={checked}
                                            checkedIcon={
                                                <span className={clsx(classes.icon, classes.checkedIcon)} />
                                            }
                                            icon={<span className={classes.icon} />}
                                        />
                                    }
                                    classes={{
                                        label: classes.label,
                                        root: classes.labelRoot
                                    }}
                                    label={
                                        <span style={{ fontSize: "14px"}}>
                                            Is Dev Account Required?
                                        </span>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="User Name"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="user-name"
                                        variant="filled"
                                    // value={""}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <TextField
                                        style={{ paddingRight: "50px", margin: 0 }}
                                        rows={1}
                                        label="Password"
                                        InputLabelProps={{ disableAnimation: true, shrink: false }}
                                        InputProps={{ disableUnderline: true }}
                                        id="password"
                                        variant="filled"
                                    // value={""}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} style={{ paddingRight: "50px" }}>
                                <FormControl
                                    fullWidth
                                    className={classes.customInputStyle}
                                >
                                    <Button
                                        // onClick={() => {
                                        //     handleSubmit();
                                        // }}
                                        fullWidth
                                        variant="contained"
                                        color="secondary"
                                        disableElevation
                                        className={classes.btnStyle}
                                        disableFocusRipple
                                        type="submit"
                                    >
                                        Create
                                    </Button>
                                </FormControl>
                            </Grid>

                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default CreateAccount;