import React, { useEffect, useState } from "react";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
//react 
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// Formik
import { useFormik } from "formik";
// Material UI Components
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {hotjar} from "react-hotjar";
import GridItem from "components/Grid/GridItem";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
//markdown editor
import Editor from "react-markdown-editor-lite";
import ReactMarkdown from "react-markdown";
import MarkdownIt from 'markdown-it';
//markdown editor import style manually
import 'react-markdown-editor-lite/lib/index.css';
//API Call
import { CMSAPIWithToken } from "../../../apis/CMS";
//checkbox
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import ListItemText from '@material-ui/core/ListItemText';



const useStyles = makeStyles((theme) => ({
  
  gridContainer: {
    padding: "0 40px 30px 40px",
  },
  radioButton: {
    "& .MuiTypography-root": {
      marginLeft: "10px",
    },
  },
  formControl: {
    width: "100%",
    marginTop: "5px",
  },
  Select: {
    marginBottom: "50px",
  },
  warningNote: {
    "& .MuiAlert-icon": {
      display: "none",
    },
  },
  button: {
    marginTop: "50px",
  },
  buttOutlined: {
    color: theme.palette.buttonColor.main,
    borderColor: theme.palette.buttonColor.main,
    "&:hover": {
      border: `1px solid ${theme.palette.buttonColor.main}`,
    },
  },
}));

//sandbox view main
const LearningView = (props) => {
  const classes = useStyles();
  hotjar.initialize(2281731, 6);
  hotjar.identify(localStorage.userName, { userProperty: localStorage.userName});
  const mdEditor = React.useRef(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [description, setDescription] = useState("");
  const [accessDetails, setAccessDetails] = useState("");
  const dispatch = useDispatch();
    const history = useHistory();
    const inputProps = {
        disableAnimation: true,
        shrink: false
      };  

      const ITEM_HEIGHT = 48;
      const ITEM_PADDING_TOP = 8;
      const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
          },
        },
      };
      
      const names = [
        'Criterion Enterprise',
        'criterion networks DEV',
        'TataCommunication',
        'LiveAction',
        'verizonlearning-dev',
        'verizonlearning',
        'LiveActiondev',
        'CiscoWorkshops',
        'TataCommunicationDev',
        'CN',
        'ciscotraining',
      
      ];
      const multipleselectvalue=["1","2","3","4","5","6","7","8","9","10","11"];
    
      const [account, setAccount] = React.useState([]);
    
      const handleMultipleChange = (event) => {
        setAccount(event.target.value);
      };    

  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
        short_title: "",
        full_title: "",
        description: "",
        type: "",
        stage: "",
        category: "",
        icon: "",
        active: false,
        identifier: "",
        show_ip_details: false,
        email_content: "",
        access_details: "",
        lab_run_time: "",
        image: "",
        enable_suspend_resume: false,
        auto_lab_provisioning: false,
        enable_guacamole: false,
        is_scripted_demo: false,
        admin_lab_types_id: null,
        access_impairment: false,
        access_tcpreplay: false,
        enable_export: false,
        account: [],
              },
    onSubmit: (values) => {
      let payload = {
        short_title: values.short_title,
        full_title: values.full_title,
        description: description,
        type: values.type,
        stage: values.stage,
        category: values.category,
        icon: values.icon,
        active: values.active,
        identifier: values.identifier,
        show_ip_details: values.show_ip_details,
        email_content: values.email_content,
        access_details: accessDetails,
        lab_run_time: values.lab_run_time,
        image: values.image,
        enable_suspend_resume: values.enable_suspend_resume,
        auto_lab_provisioning: values.auto_lab_provisioning,
        enable_guacamole: values.enable_guacamole,
        is_scripted_demo: values.is_scripted_demo,
        admin_lab_types_id: values.admin_lab_types_id,
        access_impairment: values.access_impairment,
        access_tcpreplay: values.access_tcpreplay,
        enable_export: values.enable_export,
        account: [],
      };
    },
  });
   //mark down editor
   const handleEditorChange = ({ html, text }) => {
    const newValue = text;
    //console.log(newValue);
    setDescription(newValue);
  };
  const handleAccessDetails = ({ html, text }) => {
    const newValue = text;
    //console.log(newValue);
    setAccessDetails(newValue);
  };
    // Initialize a markdown parser
    const mdParser = new MarkdownIt(/* Markdown-it options */);

  let data = [];

  const renderSanboxView = async () => {
    let sanboxView = CMSAPIWithToken()
      .get("/learnings/admin_learnings/")
      .then((resp) => resp.data.results)
      .then((resp) => {
        resp.forEach((item) => {
          if(props.user == item.id) { 
          formik.setFieldValue("short_title", item?.short_title);
          formik.setFieldValue("full_title", item?.full_title);
          setDescription(item.description)
          formik.setFieldValue("type", item?.type);
          formik.setFieldValue("stage", item?.stage);
          formik.setFieldValue("category", item?.category);
          formik.setFieldValue("icon", item?.icon);
          formik.setFieldValue("active", item?.active);
          formik.setFieldValue("identifier", item?.identifier);
          formik.setFieldValue("show_ip_details", item?.show_ip_details);
          formik.setFieldValue("email_content", item?.email_content);
          setAccessDetails(item.access_details);
          formik.setFieldValue("lab_run_time", item?.lab_run_time);
          formik.setFieldValue("image", item?.image);
          formik.setFieldValue("enable_suspend_resume", item?.enable_suspend_resume);
          formik.setFieldValue("auto_lab_provisioning", item?.auto_lab_provisioning);
          formik.setFieldValue("enable_guacamole", item?.enable_guacamole);
          formik.setFieldValue("is_scripted_demo", item?.is_scripted_demo);
          formik.setFieldValue("admin_lab_types_id", item?.admin_lab_types_id);
          formik.setFieldValue("access_impairment", item?.access_impairment);
          formik.setFieldValue("access_tcpreplay", item?.access_tcpreplay);
          formik.setFieldValue("enable_export", item?.enable_export);
          setAccount(item.account);
        }
        });
      })      
      .catch((err) => {
        let message = err.response.data.message;
        // setRows([]);
        // setIsLoading(false);
        setShowNotification(true);
        setNotificationType("error");
        setNotificationMessage("Failed to retrieve the data");
      });

      

      //setUserDetails({ ...formik.userDetails, ...data });
  };

  useEffect(() => {
    renderSanboxView();
  }, []);

  //checkbox
  
    const [state, setState] = React.useState({
      checkedA: true,
      checkedB: true,
      checkedF: true,
      checkedG: true,
    });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div>
        <form
            onSubmit={formik.handleSubmit}
            className={classes.formContainer}
          >
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.gridContainer}
      >
        <Grid item xs={12} md={5}>
          <Typography variant="h4">Learnings </Typography>
          
        </Grid>

        <GridItem md={12}>
                  {formik.errors.short_title && formik.touched.short_title ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Short Title"
                      name="short_title"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.short_title}
                      onChange={formik.handleChange}
                      helperText={formik.errors.short_title}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Short Title"
                      name="short_title"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.short_title}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>
                <GridItem md={12}>
                  {formik.errors.full_title && formik.touched.full_title ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Full Title"
                      name="full_title"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.full_title}
                      onChange={formik.handleChange}
                      helperText={formik.errors.full_title}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Full Title"
                      name="full_title"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.full_title}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>

                <GridItem md={12}>
                    <p>Description</p>
                        <Editor
                          ref={mdEditor}
                          value={description}
                          style={{
                            height: "500px"
                          }}
                          onChange={handleEditorChange}
                          renderHTML={text => <ReactMarkdown source={text} />}
                        />
                </GridItem>

                <GridItem md={12}>
                <TextField
                      type="text"
                      variant="filled"
                      label="Type"
                      name="type"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.type}
                      onChange={formik.handleChange}
                    />
                </GridItem>

                <GridItem md={12}>
                <TextField
                      type="text"
                      variant="filled"
                      label="Stage"
                      name="stage"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.stage}
                      onChange={formik.handleChange}
                    />
                </GridItem>

                <GridItem md={12}>
                  {formik.errors.category && formik.touched.category ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Category"
                      name="category"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.category}
                      onChange={formik.handleChange}
                      helperText={formik.errors.category}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Category"
                      name="category"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.category}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>

                <GridItem md={12}>
                <TextField
                      type="text"
                      variant="filled"
                      label="Icon"
                      name="icon"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.icon}
                      onChange={formik.handleChange}
                    />
                </GridItem>

                <GridItem md={12}>
                <span>Active</span>
                   <FormControlLabel
                            control={<Checkbox checked={formik.values.active} />} 
                            name="active"
                            onChange={formik.handleChange}
                            />
                </GridItem>

                <GridItem md={12} >
                  {formik.errors.identifier && formik.touched.identifier ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Identifier"
                      name="identifier"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.identifier}
                      onChange={formik.handleChange}
                      helperText={formik.errors.identifier}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Identifier"
                      name="identifier"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.identifier}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>

                <GridItem md={12}>
                <span>Show Ip Details</span>
                   <FormControlLabel
                            control={<Checkbox checked={formik.values.show_ip_details} />} 
                            name="show_ip_details"
                            onChange={formik.handleChange}
                            />
                </GridItem>

                <GridItem md={12}>
                <TextField
                      type="text"
                      variant="filled"
                      label="Email Content"
                      name="email_content"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.email_content}
                      onChange={formik.handleChange}
                      multiline
                      rows={8}
                    />
                </GridItem>

                <GridItem md={12}>
                     <p>Access Details</p>
                        <Editor
                          ref={mdEditor}
                          value={accessDetails}
                          style={{
                            height: "500px"
                          }}
                          onChange={handleAccessDetails}
                          renderHTML={text => <ReactMarkdown source={text} />}
                        />
                </GridItem>

                <GridItem md={12}>
                <TextField
                      type="text"
                      variant="filled"
                      label="Lab Run Time"
                      name="lab_run_time"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.lab_run_time}
                      onChange={formik.handleChange}
                    />
                </GridItem>

                <GridItem md={12}>
                <TextField
                      type="text"
                      variant="filled"
                      label="Image"
                      name="image"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.image}
                      onChange={formik.handleChange}
                    />
                </GridItem>

                <GridItem md={12}>
                <span>Enable Suspend Resume</span>
                   <FormControlLabel
                            control={<Checkbox checked={formik.values.enable_suspend_resume} />} 
                            name="enable_suspend_resume"
                            onChange={formik.handleChange}
                            />
                </GridItem>

                <GridItem md={12}>
                <span>Auto Lab Provisioning</span>
                   <FormControlLabel
                            control={<Checkbox checked={formik.values.auto_lab_provisioning} />} 
                            name="auto_lab_provisioning"
                            onChange={formik.handleChange}
                            />
                </GridItem>

                <GridItem md={12}>
                <span>Enable Guacamole</span>
                   <FormControlLabel
                            control={<Checkbox checked={formik.values.enable_guacamole} />} 
                            name="enable_guacamole"
                            onChange={formik.handleChange}
                            />
                </GridItem>

                <GridItem md={9}>
                <span>Is Scripted Demo</span>
                   <FormControlLabel
                            control={<Checkbox checked={formik.values.is_scripted_demo} />} 
                            name="is_scripted_demo"
                            onChange={formik.handleChange}
                            />
                </GridItem>

                <GridItem md={12} >
                  {formik.errors.admin_lab_types_id && formik.touched.admin_lab_types_id ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Admin Lab Types Id"
                      name="admin_lab_types_id"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.admin_lab_types_id}
                      onChange={formik.handleChange}
                      helperText={formik.errors.admin_lab_types_id}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Admin Lab Types Id"
                      name="admin_lab_types_id"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.admin_lab_types_id}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>

                <GridItem md={12}>
                <span>Access Impairment</span>
                   <FormControlLabel
                            control={<Checkbox checked={formik.values.access_impairment} />} 
                            name="access_impairment"
                            onChange={formik.handleChange}
                            />
                </GridItem>

                <GridItem md={12}>
                <span>Access Tcpreplay</span>
                   <FormControlLabel
                            control={<Checkbox checked={formik.values.access_tcpreplay} />} 
                            name="access_tcpreplay"
                            onChange={formik.handleChange}
                            />
                </GridItem>

                <GridItem md={12}>
                <span>Enable Export</span>
                   <FormControlLabel
                            control={<Checkbox checked={formik.values.enable_export} />} 
                            name="enable_export"
                            onChange={formik.handleChange}
                            />
                </GridItem>
      </Grid>
      </form>       
    </div>
  );
};

export default LearningView;
