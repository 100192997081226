import React, { useEffect, useState } from "react";

// Material UI Components
import { Alert } from "@material-ui/lab";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

// Icons
import Visibility from "@material-ui/icons/Visibility";

// Local
import CustomTable from "./Table";
import { SDCloudBackendAPIWithToken } from "../../../apis/backendAPI";
import { IconButton } from "@material-ui/core";
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import RightDrawer from "./RightDrawer";
import AddUser from "./AddUser";
import UserDetails from "./UserDetails";
import { Add } from "@material-ui/icons";

// Styling
const useStyles = makeStyles((theme) => ({
  root: {
    // padding: "30px 20px",
  },
}));

const ManageUsers = () => {
  const classes = useStyles();
  // States
  const [rows, setRows] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentScreen, setCurrentScreen] = useState();
  const [currentUser, setCurrentUser] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // useEffects
  useEffect(() => {
    renderData();
  }, []);

  const columns = [
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Username",
      accessor: "username",
    },
    {
      Header: "Privilege",
      accessor: "privilege",
    },
    {
      Header: "Permission Profile",
      accessor: "profile",
    },
    {
      Header: "Actions",
      accessor: "actions",
    },
  ];

  let data = [];

  const renderData = async () => {
    let response = SDCloudBackendAPIWithToken()
      .get("/get_user")
      .then((resp) => resp.data.users)
      .then((resp) => {
        resp.forEach((item) => {
          data.push({
            email: item.email,
            username: item.username,
            privilege: item.privilege,
            profile: item.permission_profile_name
              ? item.permission_profile_name
              : "None",
            actions: (
              <IconButton onClick={() => handleViewButton(item.username)}>
                <Visibility />
              </IconButton>
            ),
          });
        });

        setRows(data);
        setIsLoading(false);
      })
      .catch((err) => {
        let message = err.response.data.message;
        // setRows([]);
        // setIsLoading(false);
        setShowNotification(true);
        setNotificationType("error");
        setNotificationMessage("Failed to retrieve the data");
      });
  };


  const renderuser = () =>{
    renderData()

  }

  const renderDrawerComponent = () => {
    switch (currentScreen) {
      case "Add User":
        return <AddUser resetapi={renderuser} />;
      case "User Details":
        return <UserDetails user={currentUser} />;
      case "default":
        return null;
    }
  };

  const handleAddUserButton = () => {
    setOpenDrawer(true);
    setCurrentScreen("Add User");
  };

  

  const handleDeleteButton = (selectedRowIds, selectedFlatRows) => {
    let payload = {};
    selectedFlatRows.forEach((item) => {
      payload["username"] = item.values.username;
      SDCloudBackendAPIWithToken()
        .post("/disable_user", payload)
        .then((resp) => resp.data)
        .then((data) => {
          setShowNotification(true);
          setNotificationType("success");
          setNotificationMessage("User disabled sucessfully");
         renderData();
        })
        .catch((err) => {
          let message = err.response.data.message;
          setShowNotification(true);
          setNotificationType("error");
          setNotificationMessage(message);
        });
    });
  };

  const handleViewButton = (username) => {
    setOpenDrawer(true);
    setCurrentScreen("User Details");
    setCurrentUser(username);
  };

  return (
    <div>
      <Grid container className={classes.root} spacing={1}>
        <Grid item xs={12} md={12}>
          {showNotification ? (
            <Alert
              onClose={() => setShowNotification(false)}
              severity={notificationType}
            >
              {notificationMessage}
            </Alert>
          ) : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <LoaderHoc isLoading={isLoading}>
            <CustomTable
              columns={columns}
              data={rows}
              handleDeleteButtonClick={handleDeleteButton}
              handleButtonClick={handleAddUserButton}
              handleViewButtonClick={handleViewButton}
              tableTitle="Users"
              buttonTitle="Add User"
              dropDownValues={[]}
            />
          </LoaderHoc>
        </Grid>
      </Grid>

      {/* Drawer */}
      <RightDrawer
        open={openDrawer}
        onClose={setOpenDrawer}
        title={currentScreen}
      >
        {renderDrawerComponent()}
      </RightDrawer>
    </div>
  );
};

export default ManageUsers;