import React from "react";

// Material UI Components
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import SvgtoIcon from "utils/SvgtoIcon";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

import SearchIcon from "@material-ui/icons/Search";
import { ReactComponent as deleteSvg } from "assets/criterion_networks/svg/delete.svg";
// const deleteSvg = require("assets/criterion_networks/svg/delete.svg");

const useStyles = makeStyles((theme) => ({
  deleteIcon: {
    height: "40px",
    width: "40px",
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: "0",
    fill: theme.palette.grey["900"],
    "& .MuiIcon-root": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& img": {
      width: "12px",
      height: "12px",
    },
  },
  headerContainer: {
    margin: "30px 0",
  },
  searchBar: {
    marginTop: "0",
    maxWidth: "350px",
    minWidth: "50px",
  },
  formControl: {
    marginTop: "0",
    width: "100%",
  },
  btnColor: {
    borderColor: theme.palette.buttonColor.main,
    color: theme.palette.buttonColor.main,
    "&:hover": {
      borderColor: theme.palette.buttonColor.main,
    }
  }
}));

const SearchBar = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  useAsyncDebounce,
}) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const classes = useStyles();

  return (
    <TextField
      type="search"
      variant="filled"
      value={value || ""}
      className={classes.searchBar}
      fullWidth
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              type="submit"
              className={classes.iconButton}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

const TableHeader = (props) => {
  const classes = useStyles();

  const renderDropDown = () => {
    let dropDownItems = props.dropDownValues.map((item) => {
      return (
        <option key={item} value={item}>
          {item}
        </option>
      );
    });

    return dropDownItems;
  };
  const showContentCopyButton = props.contentType === "sandbox" || props.contentType === "learning";
  return (
    <div>
      <Grid
        container
        className={classes.headerContainer}
        alignItems="center"
        justify="space-between"
        wrap="wrap"
        spacing={1}
      >
        <Grid item xs={12} sm={12} md={6}>
          <Grid container wrap="wrap" spacing={1}>
            <Grid item>
             
            </Grid>

            <Grid item style={{ marginLeft: "2px" }}>
              <SearchBar
                preGlobalFilteredRows={props.preGlobalFilteredRows}
                globalFilter={props.globalFilter}
                setGlobalFilter={props.setGlobalFilter}
                useAsyncDebounce={props.useAsyncDebounce}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <Grid container alignItems="center" justify="flex-end">
            {props.showDropdown ? (
              <Grid
                item
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <FormControl variant="filled" className={classes.formControl}>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="status"
                    fullWidth
                    onChange={(e) => props.handleDropDownChange(e.target.value)}
                    native
                  >
                    {renderDropDown()}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}

            <Grid item style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="outlined"
                color="secondary"
                disableElevation
                size="large"
                disableFocusRipple
                className={classes.btnColor}
                type="submit"
                style={{ height: "40px", marginLeft: "10px" }}
                onClick={props.handleButtonClick}
              >
                {props.buttonTitle}
              </Button>
              {showContentCopyButton && (
                <Button
                  variant="outlined"
                  color="secondary"
                  disableElevation
                  size="large"
                  disableFocusRipple
                  className={classes.btnColor}
                  type="submit"
                  style={{ height: "40px", marginLeft: "10px" }}
                  onClick={props.handleContentCopyClick}
                >
                  {props.copycontent}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default TableHeader;
