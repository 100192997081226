// This component creates a modal for Reserved IP with respective form fields, the
// modal is popped up during provision or resuming of a lab or poc.

import React, { Component, useState } from "react";
import { SECONDARY_COLOR } from "global_constants/app_constants";
import Button from "@material-ui/core/Button";
// Formik
import { useFormik } from "formik";
import Datetime from "react-datetime";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import TextField from "@material-ui/core/TextField";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Api, Urls } from "config/api";
import Loader from "react-loader-spinner";
import { LOADER_TYPE } from "global_constants/app_constants";
import "views/ServiceOffering/LearningLab/acess labs/Overview.scss";
import NotificationAlert from "views/Components/NotificationAlert";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import Typography from "@material-ui/core/Typography";
import theme from "theme";
import { staticIpRegex } from "../MyProfile/utils/constants/constants_profile.js";

const inputProps = {
  disableAnimation: true,
  shrink: false,
};

const customStyles = (theme) => ({
  container: {
    padding: "40px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "absolute",
    width: 550,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    display: "block",
  },
  buttStyle: {
    background: theme.palette.buttonColor.main,
    "&:hover": {
      background: theme.palette.buttonColor.main,
    },
  },
  title: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    color: "#000000",
  },
  helperCustom: {
    alignSelf: "center",
    marginLeft: "25px",
  },
  errorText: {
    fontSize: "12px",
    color: theme.palette.error.main,
  },
});

const AddStaticIp = (props) => {
  const useStyles = makeStyles(customStyles);
  const classes = useStyles();
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [isIpRegexMsg, setIsIpRegexMsg] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [value, setValue] = React.useState("ipv4");

  const hideForm = () => {
    props.handleCancelStaticIp(true);
  };
  const handleSubmit = () => {
    props.handleAddIpButton(true);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    onSubmit: (values) => {
      // if (!staticIpRegex.test(values.name)) {
      //   return false;
      // }
      setShowNotification(false);
      setBtnDisabled(true);
      props.setTableOpacity(0.3);
      SDCloudBackendAPIWithToken()
        .put(Api.addStaticIp, {
          name: values.name,
        })
        .then((res) => {
          //   setShowNotification(true);
          //   setNotificationMessage(
          //     "Ip created successfully!"
          //   );
          //   setNotificationType("success");
          props.addConfirmAlert();
          props.renderData();
          setBtnDisabled(false);
          handleSubmit();
        })
        .catch((err) => {
          let error = err.response.data;
          setShowNotification(true);
          setNotificationMessage(error.message);
          setNotificationType("error");
          setBtnDisabled(false);
          props.setTableOpacity(1);
        });
    },
    validate: (values) => {
      let errors = {};
      if(values.name.length>63){
        errors.name = "The name must not exceed 63 characters."
      }
      if (!/^[a-z].*[a-z0-9]$/.test(values.name)) {
        errors.name = "The name must start and end with a lowercase letter.";
    }
      let ipRegex = new RegExp("^[a-z0-9.-]+$");
      if(!ipRegex.test(values.name)){
        errors.name = "The name can only contain lowercase letters, numbers, and hyphens.";
      }

      // if (staticIpRegex.test(values.name)) { 
      //   setIsIpRegexMsg(false);
      // } else {
      //   setIsIpRegexMsg(true);
      // }

      if (!values.name) {
        errors.name = "Required";
      }

      return errors;
    },
  });

  return (
    // Form modal for add hours to lab
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Paper elevation={0}>
          {/* <Typography variant="h4">{props.formTitle}</Typography> */}
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <GridContainer className={classes.container}>
              <GridItem xs={12} sm={4} md={4} lg={4}>
                {formik.errors.name && formik.touched.name ? (
                  <TextField
                    type="text"
                    variant="filled"
                    label="Name"
                    name="name"
                    fullWidth
                    InputLabelProps={inputProps}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    helperText={formik.errors.name}
                    onBlur={formik.handleBlur}
                    error
                  />
                ) : (
                  <TextField
                    type="text"
                    variant="filled"
                    label="Name"
                    name="name"
                    fullWidth
                    InputLabelProps={inputProps}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                )}
                {/* {isIpRegexMsg && (
                  <FormHelperText className={classes.errorText}>
                    String must be in small letters with no special characters
                    except '-'
                  </FormHelperText>
                )} */}
              </GridItem>
              <GridItem
                xs={12}
                sm={4}
                md={3}
                lg={3}
                className={classes.helperCustom}
              >
                <Typography variant="body1">IP version</Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="ip_type"
                    name="action"
                    value={value}
                    className={classes.radioButton}
                    onChange={formik.handleChange}
                  >
                    <FormControlLabel
                      value="ipv4"
                      control={<Radio color="primary" />}
                      selected={true}
                      label="IPv4"
                    />
                    <FormControlLabel
                      value="ipv6"
                      control={<Radio color="primary" />}
                      disabled={true}
                      label="IPv6"
                    />
                  </RadioGroup>
                </FormControl>
              </GridItem>
            </GridContainer>
          </GridItem>
        </Paper>
        <GridContainer
          justify="center"
          alignItems="center"
          style={{ marginTop: "20px" }}
        >
          <GridItem xs={12} sm={6} md={3} lg={3}>
            {!btnDisabled ? (
              <Button
                variant="contained"
                color="secondary"
                disableElevation
                size="large"
                className={classes.buttStyle}
                fullWidth
                disableFocusRipple
                type="submit"
              >
                Submit
              </Button>
            ) : null}
            {btnDisabled ? (
              <Loader
                type={LOADER_TYPE}
                color={theme.palette.secondary.main}
                width={50}
                height={50}
                style={{ float: "right", marginRight: "15px" }}
              />
            ) : null}
          </GridItem>
          <GridItem xs={12} sm={6} md={3} lg={3}>
            <Button
              variant="outlined"
              color="secondary"
              disableElevation
              size="large"
              // className={classes.buttStyle}
              fullWidth
              onClick={hideForm}
              disableFocusRipple
              type="submit"
            >
              Cancel
            </Button>
          </GridItem>
        </GridContainer>

        <NotificationAlert
          notificationType={notificationType}
          showNotification={showNotification}
          message={notificationMessage}
        />
      </form>
    </div>
  );
};

export default AddStaticIp;
