import React, { useState, useEffect} from "react";
import { Modal, Box, Tabs, Tab, Typography, IconButton, Grid} from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Loader from "react-loader-spinner";
import { Alert } from "@material-ui/lab";
import {
  FormControlLabel,
  Button,
  Checkbox,
  TextField
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio/Radio";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import { LOADER_TYPE, SECONDARY_COLOR } from "global_constants/app_constants";
import GridItem from "components/Grid/GridItem";
import CustomModal from "../../../components/CustomModal";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import theme from "theme";
import FormControl from "@material-ui/core/FormControl";
import CloseIcon from '@mui/icons-material/Close';
import LaunchIcon from '@mui/icons-material/Launch'; 

const useStyles = makeStyles(styles);

const ImportFromWizard = ({
  visible,
  onClose,
  onCreateTemplate,
  is_multi_vm,
  cloud_native_enabled,
  open,
  data,
}) => {
  const [details, setDetails] = useState({
    templateName: "",
    isLiveEditing: false
  });
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [cluster, setCluster] = useState("networking");
  const [actionWizard, setActionWizard] = useState(data);
  const clusterFlavors = Object.keys(JSON.parse(localStorage.getItem("machine_types")));
  const liveEditEnabled = JSON.parse(localStorage.getItem("profileData")).liveedit_enabled;
  const [flavor,setFlavor] = useState(clusterFlavors[0])
  const handleImageExport = (imageUrl) => {
    window.open(imageUrl, '_blank');
  };
  
  useEffect(() => {
    const keys = Object.keys(data);
    if (keys.length > 0) {
      // Set the first key as the default active tab
      setActionWizard(keys[0]);
    }
  }, [data]);

  const [selectedTab, setSelectedTab] = useState(0);
  const classes = useStyles();
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setActionWizard(newValue);
  };

  const handleChange = e => {
    const { name, value, checked, type } = e.target;
    setDetails({
      ...details,
      [name]: type === "checkbox" ? checked : value
    });
  };

  const onSubmit = () => {
 
    var re = /^[a-zA-Z0-9-_]+$/;
    if (details.templateName && re.test(details.templateName) && details.templateName.length > 3 && JSON.parse(localStorage.getItem("profileData")).privilege != "admin") {
    var route;
    if (window.location.protocol !== "https:" && window.location.hostname !== "localhost"){
      route = `https:${window.location.hostname}` + "/design_center/?";
    }else{
      route = "/design_center/?";
    }
    route = `${route}new_template=${details.templateName}&title=${actionWizard}&liveEdit=${details.isLiveEditing}`;
    window.location.href = route;
    } else{
      setShowNotification(true);
      setNotificationType("error");
      if(JSON.parse(localStorage.getItem("profileData")).privilege === "admin"){
        setNotificationMessage('Admin can not create a template');
      }else{
      setNotificationMessage('Please enter a valid template name with atleast 4 characters. Special characters are not allowed except for - and _');
      }
    }
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-title">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'column',
          width: '90vw',
          maxWidth: '2000px',
          height: '90vh',
          outline: 'none',
          padding: '0 !important',
        }}
      >
        {/* Header with title and close button */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            bgcolor: '#0F1A2B',
            color: '#fff',
            width: '100%',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            padding: '0% 1% 1% 1% !important',
          }}
        >
          <Typography variant="h6" id="modal-title" sx={{ margin: 0 }}>
            Criterion Design Center Available Wizards
          </Typography>
          
          <IconButton onClick={onClose} sx={{ color: '#fff' }}>
            <CloseIcon />
          </IconButton>
        </Box>
          <br/>
        {/* Modal content */}
        <Box sx={{ display: 'flex', flexGrow: 1 }}>
          {/* Vertical Tabs */}
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={selectedTab}
            onChange={handleTabChange}
            sx={{
              borderRight: 1,
              borderColor: 'divider',
              minWidth: '150px',
              height: '100%',
            }}
          >
            {Object.keys(data).map((key, index) => (
              <Tab key={key} label={key}
              onClick={() => setActionWizard(data[key]['title'])}
              sx={{
                backgroundColor: selectedTab === index ? '#F96B13' : 'transparent', // Active tab color
                color: selectedTab === index ? '#fff' : 'inherit', // Active tab text color
                '&:hover': {
                  backgroundColor: selectedTab === index ? '#F96B13' : '#f0f0f0', // Hover color
                },
                // Ensure inactive tab text color is gray (or use a different color if preferred)
                '&.Mui-selected': {
                  color: '#fff', // Force text color to white when selected
                },
              }} />
            ))}
          </Tabs>

          {/* Content Area */}
          <Box sx={{ flexGrow: 1, p: 3, display: 'flex', flexDirection: 'column' }}>
            {Object.keys(data).map((key, index) => (
              <div
                key={key}
                role="tabpanel"
                hidden={selectedTab !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                style={{ width: '100%', height: '100%' }}
              >
                {selectedTab === index && (
                  <Grid container spacing={2}>
                    {/* Image Column */}
                    <Grid item xs={8} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          width: '100%',
                          backgroundColor: '#f0f0f0',
                          textAlign: 'center',
                          padding: '8px 0',
                          mb: 2,
                          fontWeight: 'bold',
                          color: '#333',
                        }}
                      >
                        {key}

                        <IconButton
                            onClick={() => handleImageExport(data[key]['image'])} // Opens the image in a new tab
                            sx={{ color: '#F96B13', marginLeft: '20px' }}
                          >
                            <LaunchIcon />
                        </IconButton>
                      </Typography>
                      
                      <Box
                        component="img"
                        src={data[key]['image']}
                        alt={key}
                        sx={{
                          maxWidth: '100%',
                          maxHeight: '60%',
                          objectFit: 'contain',
                          border: '1px solid #ddd',
                          borderRadius: '8px',
                        }}
                      />

                    </Grid>

                    {/* Form Column */}
                    <Grid item xs={4}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Create Template From Wizard
                      </Typography>
                      <TextField
                        type="text"
                        variant="filled"
                        name="templateName"
                        label="Enter Template Name"
                        fullWidth
                        value={details.templateName || ""}
                        onChange={handleChange}
                        sx={{ mb: 2 }}
                      />
                      <br/><br/>
                      {/* Radio Buttons */}
                      <RadioGroup defaultValue="option1" sx={{ mb: 2 }}>
                        <FormControlLabel value="option1" control={<Radio />} label="Small Topology" />
                      </RadioGroup>
                      <br/>
                        {liveEditEnabled?
                            (<FormControlLabel
                                title="The Live edit feature allows the user to make changes to the active topology"
                                control={
                                    <Checkbox
                                        checked={details.isLiveEditing}
                                        name="isLiveEditing"
                                        className="checkmark"
                                        onClick={handleChange}
                                        classes={{
                                            checked: classes.checked,
                                            root: classes.checkRoot
                                        }}
                                    />
                                }
                                label="Live editing"
                            />):null
                        }
                      <br/><br/>
                      {!btnDisabled ? (
                        <>
                        <Button
                          variant="contained"
                          color="secondary"
                          disableElevation
                          size="large"
                          fullWidth
                          onClick={onSubmit}
                          disableFocusRipple
                          className={`create-btn ${classes.backColor}`}
                        >
                          Create template
                        </Button>
                        </>
                      ) : null}
                       {showNotification ? (
                          <Alert
                              onClose={() => setShowNotification(false)}
                              severity={notificationType}
                          >
                            {notificationMessage}
                          </Alert>
                      ) : null}
                    </Grid>
                  </Grid>
                )}
              </div>
            ))}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default withRouter(ImportFromWizard);