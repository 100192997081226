import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles, Paper, Box } from "@material-ui/core";
import { BrowserRouter, Route, NavLink } from "react-router-dom";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import { Api } from "config/api";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer
} from "@material-ui/core";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import { SHOW_LOADER, HIDE_LOADER } from "action_creators/actioncreator";
import GridItem from "components/Grid/GridItem.js";
import { SECONDARY_COLOR } from "global_constants/app_constants";
import DesignCenterUpgrade from "../../views/Components/DesignCenterUpgrade";
import ExperienceCenterUpgrade from "../../views/Components/ExperienceCenterUpgrade";
import LearningCenterUpgrade from "../../views/Components/LearningCenterUpgrade";
import CustomModal from "../../components/CustomModal";
import { getRouteByName } from "../../utils/utilfunction";
import { getResourcesStatus, getServiceStatus } from "../../apis/APIServices";
import { SHOW_NOTIFICATION } from "../../action_creators/actioncreator";
import cloudImage from "assets/img/sdcloud-gr-up.png";
import HeaderImage from "assets/img/w1.png";
import HeaderImage2 from "assets/img/w2.png";
import HeaderImage3 from "assets/img/w3.png";
import ArrowImage from "assets/img/arrow-right.png";
import UpgradeSubscription from "./UpgradeSubscription.js";
import WarningDialog from "views/Pages/BrowsersWarning";
import "./Dashboard.scss";
import Grid from "@material-ui/core/Grid";
import Drawer from "@material-ui/core/Drawer";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import { SET_TEMPLATES } from "../../action_creators/actioncreator";
import { getAllTemplates } from "../../apis/APIServices";
import svg1 from "../../assets/criterion_networks/svg/01.svg";
import { ReactComponent as svg2 } from "../../assets/criterion_networks/svg/02.svg";
import { ReactComponent as svg3 } from "../../assets/criterion_networks/svg/03.svg";
import { ReactComponent as svg4 } from "../../assets/criterion_networks/svg/04.svg";
import { ReactComponent as svg5 } from "assets/criterion_networks/svg/05.svg";
import SvgComponent from "utils/SvgComponent";
import Chatbot from 'views/Components/chatbot/Chatbot';


const handleClickHelp = () => {
  SDCloudBackendAPIWithToken()
    .get(Api.getSSO)
    .then(sso => window.open(sso.data.urls, "_blank"));
};

const customStyles = theme => ({
  colour: {
    color: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.secondary.main
    },
    "&:focus": {
      color: theme.palette.secondary.main
    }
  },
  backColor: {
    background: theme.palette.primary.main,
    borderRadius: "3px"
  },
  // title: {
  //   fontSize: "36px",
  //   fontWeight: "normal",
  //   paddingBottom: "25px"
  // },
  root: {
    flexGrow: 1
  },
  subTitle: {
    fontSize: "18px",
    fontWeight: "normal",
    paddingBottom: "39px"
  },
  paper: {
    marginTop: "10px",
    background: "#FFFFFF",
    border: "none",
    boxShadow: "none",
    height: "493px",
    borderRadius: 0,
    padding: "0 63px 18px 27px"
  },
  theadCell: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: "normal",
    fontSize: "12px",
    color: "#B1B1B1",
    border: "none"
  },
  fw700: {
    fontWeight: 700
  },
  drawerPaper: {
    width: "30%"
  },
  gray: {
    color: "#B1B1B1 !important",
    fontSize: "14px",
    fontWeight: "normal"
  },
  red: {
    color: "#F30000",
    fontSize: "14px",
    fontWeight: "normal"
  },
  green: {
    color: "#028B01",
    fontSize: "14px",
    fontWeight: "normal"
  },
  orange: {
    color: "#F96B13",
    fontSize: "14px",
    fontWeight: "normal"
  },
  designModalCloseIcon: {
    position: "absolute",
    marginTop: "-125px",
    marginLeft: "45px",
    cursor: "pointer",
    color: "#FFFFFF",
    fontSize: "2rem"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paperModal: {
    position: "absolute",
    height: "493px",
    width: "auto",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3)
  },
  modalCloseIcon: {
    position: "absolute",
    marginTop: "-125px",
    marginLeft: "45px",
    cursor: "pointer",
    color: "#FFFFFF",
    fontSize: "2rem"
  },
  CursorPointer: {
    cursor: "pointer"
  },
  title: {
    background: `url(data:image/svg+xml;base64,${window.btoa(
      `<svg width="40" height="3" viewBox="0 0 40 3" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="40" height="3" fill="${theme.palette.secondary.main}"/></svg>`
    )}) no-repeat top left`
  },
  imgFilter: {
    filter: theme.palette.secondary.filterImage
  },
  font: {
    fontFamily: theme.palette.fontList.selectedFont
  },
  WidthUnset: {
    width: "unset"
  }
});

const errPayload = {
  message: "Something went wrong.",
  color: "danger"
};
export default function Dashboard(props) {
  const useStyles = makeStyles(customStyles);
  const [modal, setModalId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const store = useStore();
  const reduxDesignStore = store.getState().design;
  const reduxStore = store.getState().profileObj;
  const [resources, setResources] = useState([]);
  const [services, setServices] = useState([]);
  const [showRightDrawer, toggleRightDrawer] = useState(false);
  const [upgrade, setUpgrade] = useState("");
  const [showWarning, setShowWarning] = useState(false);

  const [MyDesignCount, setMyDesignCount] = useState(0);
  useEffect(() => {
    const { templates } = reduxDesignStore;
    setMyDesignCount(templates && templates.length ? templates.length : 0);
  }, [reduxDesignStore]);

  useEffect(() => {
    var userAgent = navigator.userAgent;
    var isFirefox = userAgent.indexOf("Firefox");
    var isChrome = userAgent.indexOf("Chrome");

    if (isChrome == -1 && isFirefox == -1) {
      // if (window.history?.state?.firstLogin) {
      setShowWarning(true);
      window.history.pushState({}, "");
      // }
    }

    dispatch(SET_NAVBAR_TITLE("Home"));
    getResources();
    getTemplates();
  }, []);

  const getTemplates = async () => {
    dispatch(SHOW_LOADER());
    const res = await getAllTemplates();
    // if (!res.success) {
    //   dispatch(SHOW_NOTIFICATION({ payload: res.msg || errPayload }));
    //   dispatch(SET_TEMPLATES(res.data));
    //   return true;
    // }
    dispatch(SET_TEMPLATES(res.data));
    // dispatch(HIDE_LOADER());
  };

  let sections = [
    {
      name: "Design Center",
      id: "designer",
      path: getRouteByName("Design"),
      Headerimg: HeaderImage,
      Arrowimg: ArrowImage,
      ScreenName: "Design",
      Upgrade: "Upgrade Design Center"
    },
    {
      name: "Learning Center",
      id: "learning",
      path: getRouteByName("Learning"),
      Headerimg: HeaderImage2,
      Arrowimg: ArrowImage,
      ScreenName: "Learning",
      Upgrade: "Upgrade Learning Center"
    },
    {
      name: "Experience Center - Showcases",
      id: "sales_demo",
      tempId: "showcases",
      path: `${getRouteByName("Experience")}?tab=sales`,
      Headerimg: HeaderImage3,
      Arrowimg: ArrowImage,
      ScreenName: "Showcases",
      Upgrade: "Upgrade Experience Center - Showcases"
    },
    {
      name: "Experience Center - Sandboxes",
      id: "poc",
      tempId: "sandboxes",
      path: `${getRouteByName("Experience")}?tab=poc`,
      Headerimg: HeaderImage3,
      Arrowimg: ArrowImage,
      ScreenName: "Sandboxes",
      Upgrade: "Upgrade Experience Center - Sandboxes"
    }
  ];
  const getResources = async () => {
    dispatch(SHOW_LOADER());
    const res = await getResourcesStatus();
    if (!res.success) {
      dispatch(SHOW_NOTIFICATION({ payload: res.msg || errPayload }));
      return;
    }
    const resources = res.data;

    sections = sections.map(item => ({
      available:
        (resources[item.id] &&
          resources[item.id].allocated_hours &&
          resources[item.id].allocated_hours -
            resources[item.id].consumed_hours) ||
        0,
      isLaunch: !!resources[item.id],
      ...item
    }));
    setResources(() => sections);
    getServices();
  };

  const getServices = async () => {
    const res = await getServiceStatus();
    if (!res.success) {
      dispatch(SHOW_NOTIFICATION({ payload: res.msg || errPayload }));
      return;
    }
    setServices(res.data);
    dispatch(HIDE_LOADER());
  };

  const FeedbackSection = () => {
    return (
      <Paper className={(classes.paper, "feedback")} elevation={0}>
        <div style={{ textAlign: "center" }}>
          <img
            className="dashboard-img"
            src={cloudImage}
            alt="Criterion SDCloud"
          />
        </div>
      </Paper>
    );
  };

  const handleModal = modalId => {
    setModalId(modalId);
    setShowModal(!showModal);
  };

  // const handleClickCustomerSupport = (value) => {

  //   setUpgrade(value);
  //   toggleRightDrawer(!showRightDrawer);
  // };

  // const TableSection = ({ classes }) => {
  //   const history = useHistory();
  //   const isLaunchHeading = resources.some(row => row.isLaunch);
  //   const handleLearnMore = () => {
  //     history.push(`${getRouteByName("Learn more")}`);
  //   };
  //   return (
  //     <Paper className="details-section" elevation={0}>
  //       <TableContainer>
  //         <Table>
  //           {isLaunchHeading && (
  //             <TableHead>
  //               <TableRow>
  //                 <TableCell className={classes.theadCell} />
  //                 <TableCell className={classes.theadCell} align="center">
  //                   Subscribed
  //                 </TableCell>
  //                 <TableCell className={classes.theadCell} align="center">
  //                   Available
  //                 </TableCell>
  //               </TableRow>
  //             </TableHead>
  //           )}
  //           <TableBody>
  //             {resources.map(row => {
  //               if (!row.isLaunch) {
  //                 return (
  //                   <TableRow key={row.name}>
  //                     <TableCell className={classes.gray} scope="row">
  //                       {row.name}
  //                     </TableCell>
  //                     <TableCell
  //                       align="center"
  //                       colSpan={2}
  //                       className={classes.CursorPointer}
  //                     >
  //                       <a
  //                         className={classes.gray}
  //                         onClick={() => handleModal(row.id)}
  //                       >
  //                         Upgrade Account
  //                       </a>
  //                     </TableCell>
  //                   </TableRow>
  //                 );
  //               }
  //               const serviceStatus =
  //                 services[row.id] || services[row.tempId] || {};
  //               return (
  //                 <TableRow key={row.name}>
  //                   <TableCell scope="row">
  //                     <Box component="span" className={classes.fw700}>
  //                       {row.name}
  //                     </Box>{" "}
  //                   </TableCell>
  //                   <TableCell className={classes.green} align="center">
  //                     {serviceStatus.active}
  //                   </TableCell>
  //                   <TableCell className={classes.gray} align="center">
  //                     {serviceStatus.inactive}
  //                   </TableCell>
  //                 </TableRow>
  //               );
  //             })}
  //           </TableBody>
  //         </Table>
  //       </TableContainer>
  //       <div className="align-center">
  //         <button className="learn-more-button" onClick={handleLearnMore}>
  //           Learn more
  //         </button>
  //       </div>
  //     </Paper>
  //   );
  // };

  const generateDrawerComponent = value => {
    return (
      <UpgradeSubscription
        expanded="support"
        upgradetype={value}
        toggleDrawer={toggleRightDrawer}
      />
    );
  };

  const TableSection = ({ classes }) => {
    const history = useHistory();
    const isLaunchHeading = resources.some(row => row.isLaunch);
    const NavigatePage = path => {
      history.push(path);
    };

    const handleChange = (value, item) => {
      if (value === "Learning") {
        history.push("/learning-lab", { currentTab: item });
      }
      if (value === "Design") {
        history.push("/design-center");
      }
      if (value === "Showcases") {
        history.push("/experience-center-showcases", { currentTab: item });
      }
      if (value === "Sandboxes") {
        history.push("/experience-center-sandboxes", { currentTab: item });
      }
    };

    return resources.map(row => {
      const serviceStatus = services[row.id] || services[row.tempId] || {};
      let headerImg = "";
      if (!row.isLaunch) {
        return (
          <Grid item xs={12} sm={6}>
            <div className="home-card ">
              <h3
                id="title"
                className={`home-widget-title ${classes.title +
                  " " +
                  classes.font}`}
              >
                {row.name}
              </h3>
              {row.name === "Design Center" ? (
                <SvgComponent
                  className={`home-wid-img2 ${classes.WidthUnset}`}
                  type="fill"
                  svgSource={svg2}
                />
              ) : row.name === "Learning Center" ? (
                <SvgComponent
                  className={`home-wid-img2 ${classes.WidthUnset}`}
                  type="fill"
                  svgSource={svg3}
                />
              ) : (
                <SvgComponent
                  className={`home-wid-img2 ${classes.WidthUnset}`}
                  type="fill"
                  svgSource={svg4}
                />
              )}
              {/* <img src={headerImg} className={`home-wid-img2 ${classes.imgFilter}`} /> */}
              <Grid container className="box-inner-height mt-2">
                <Grid item xs={6} sm={6}>
                  <span className={classes.colour + " " + classes.font}>
                    Not available in your plan.
                  </span>
                </Grid>
                <Grid item xs={6} sm={6}>
                  {" "}
                </Grid>
              </Grid>
              <div
                className="row text-center mt-5"
                style={{ marginTop: "2rem" }}
                align="center"
              >
                <button
                  className={`button goto-button ${classes.backColor +
                    " " +
                    classes.font}`}
                  style={{ cursor: "pointer" }}
                  onClick={handleClickHelp}
                >
                  Upgrade your subscription
                </button>
              </div>
            </div>
          </Grid>
        );
      } else {
        if (row.name === "Design Center") {
          return (
            <Grid item xs={12} sm={6} className={classes.font}>
              <div className="home-card ">
                <h3
                  className={`home-widget-title ${classes.title +
                    " " +
                    classes.font}`}
                >
                  {row.name}
                </h3>
                {row.name === "Design Center" ? (
                  <SvgComponent
                    className={`home-wid-img2 ${classes.WidthUnset}`}
                    type="fill"
                    svgSource={svg2}
                  />
                ) : row.name === "Learning Center" ? (
                  <SvgComponent
                    className={`home-wid-img2 ${classes.WidthUnset}`}
                    type="fill"
                    svgSource={svg3}
                  />
                ) : (
                  <SvgComponent
                    className={`home-wid-img2 ${classes.WidthUnset}`}
                    type="fill"
                    svgSource={svg4}
                  />
                )}
                {/* <img src={headerImg} className={`home-wid-img2 ${classes.imgFilter}`} /> */}
                <Grid container className="box-inner-height mt-2">
                  <Grid item xs={12} sm={6}>
                    <p className={`home-sub-title mt-5 ${classes.font}`}>
                      My Designs: {MyDesignCount}
                    </p>
                    {/* <a href="" className="sub-title-link" onClick={() => handleChange(row.ScreenName)}>My Available {row.ScreenName}<img src={row.Arrowimg} className="arrow" /></a> */}
                  </Grid>
                  {/* <Grid item xs={6} sm={6}>
                            <p className="home-sub-title mt-5">Subscribed: {serviceStatus.active}</p>
                            <a href="" className="sub-title-link" onClick={() => handleChange(row.ScreenName)}>My Subscribed {row.ScreenName}<img src={row.Arrowimg} className="arrow" /></a>
                            </Grid> */}
                </Grid>
                <div
                  className={`row text-center mt-5 ${classes.mTop}`}
                  style={{ marginTop: "2.4rem" }}
                  align="center"
                >
                  <button
                    className={`button goto-button ${classes.backColor +
                      " " +
                      classes.font}`}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleChange(row.ScreenName, "All")}
                  >
                    Goto {row.name}
                  </button>
                </div>
              </div>
            </Grid>
          );
        } else if (row.name === "Learning Center") {
          return (
            <Grid item xs={12} sm={6}>
              <div className="home-card ">
                <h3
                  className={`home-widget-title ${classes.title +
                    " " +
                    classes.font}`}
                >
                  {row.name}
                </h3>
                {row.name === "Design Center" ? (
                  <SvgComponent
                    className={`home-wid-img2 ${classes.WidthUnset}`}
                    type="fill"
                    svgSource={svg2}
                  />
                ) : row.name === "Learning Center" ? (
                  <SvgComponent
                    className={`home-wid-img2 ${classes.WidthUnset}`}
                    type="fill"
                    svgSource={svg3}
                  />
                ) : (
                  <SvgComponent
                    className={`home-wid-img2 ${classes.WidthUnset}`}
                    type="fill"
                    svgSource={svg4}
                  />
                )}
                {/* <img src={headerImg} className={`home-wid-img2 ${classes.imgFilter}`} /> */}
                <Grid container className="box-inner-height mt-2">
                  <Grid item xs={12} sm={6}>
                    <p className={`home-sub-title mt-5 ${classes.font}`}>
                      Available: {serviceStatus.inactive}
                    </p>
                    <a
                      href=""
                      className={`${classes.colour +
                        " " +
                        classes.font} sub-title-link`}
                      onClick={() => handleChange(row.ScreenName, "All")}
                    >
                      Learning Catalog
                      <SvgComponent
                        className="arrow"
                        type="stroke"
                        svgSource={svg5}
                      />
                      {/* <img src={svg5} className={`arrow ${classes.imgFilter}`} /> */}
                    </a>
                  </Grid>
                  {JSON.parse(localStorage.getItem("profileData")).privilege !==
                    "admin" && (
                    <Grid item xs={12} sm={6}>
                      <p className={`home-sub-title mt-5 ${classes.font}`}>
                        Subscribed: {serviceStatus.active}
                      </p>
                      <a
                        href=""
                        className={`${classes.colour +
                          " " +
                          classes.font} sub-title-link`}
                        onClick={() =>
                          handleChange(row.ScreenName, "Subscribed")
                        }
                      >
                        Subscribed Learnings
                        <SvgComponent
                          className="arrow"
                          type="stroke"
                          svgSource={svg5}
                        />
                        {/* <img src={svg5} className={`arrow ${classes.imgFilter}`} /> */}
                      </a>
                    </Grid>
                  )}
                </Grid>
                <div
                  className="row text-center mt-5"
                  style={{ marginTop: "2rem" }}
                  align="center"
                >
                  <button
                    className={`button goto-button ${classes.backColor +
                      " " +
                      classes.font}`}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleChange(row.ScreenName, "Active")}
                  >
                    Goto Active Learnings
                  </button>
                </div>
              </div>
            </Grid>
          );
        } else {
          return (
            <Grid item xs={12} sm={6}>
              <div className="home-card ">
                <h3
                  className={`home-widget-title ${classes.title +
                    " " +
                    classes.font}`}
                >
                  {row.name}
                </h3>
                {row.name === "Design Center" ? (
                  <SvgComponent
                    className={`home-wid-img2 ${classes.WidthUnset}`}
                    type="fill"
                    svgSource={svg2}
                  />
                ) : row.name === "Learning Center" ? (
                  <SvgComponent
                    className={`home-wid-img2 ${classes.WidthUnset}`}
                    type="fill"
                    svgSource={svg3}
                  />
                ) : (
                  <SvgComponent
                    className={`home-wid-img2 ${classes.WidthUnset}`}
                    type="fill"
                    svgSource={svg4}
                  />
                )}
                {/* <img src={headerImg} className={`home-wid-img2 ${classes.imgFilter}`} /> */}
                <Grid container className="box-inner-height mt-2">
                  <Grid item xs={12} sm={6}>
                    <p className={`home-sub-title mt-5 ${classes.font}`}>
                      Available: {serviceStatus.inactive}
                    </p>
                    <a
                      href=""
                      className={`${classes.colour +
                        " " +
                        classes.font} sub-title-link`}
                      onClick={() => handleChange(row.ScreenName, "All")}
                    >
                      {row.ScreenName==="Sandboxes"? "Sandbox": "Showcase"} Catalog
                      <SvgComponent
                        className="arrow"
                        type="stroke"
                        svgSource={svg5}
                      />
                      {/* <img src={svg5} className={`arrow ${classes.imgFilter}`} /> */}
                    </a>
                  </Grid>
                  {JSON.parse(localStorage.getItem("profileData")).privilege !==
                    "admin" && (
                    <Grid item xs={12} sm={6}>
                      <p className={`home-sub-title mt-5 ${classes.font}`}>
                        Subscribed: {serviceStatus.active}
                      </p>
                      <a
                        href=""
                        className={`${classes.colour +
                          " " +
                          classes.font} sub-title-link`}
                        onClick={() =>
                          handleChange(row.ScreenName, "Subscribed")
                        }
                      >
                        Subscribed {row.ScreenName}
                        <SvgComponent
                          className="arrow"
                          type="stroke"
                          svgSource={svg5}
                        />
                        {/* <img src={svg5} className={`arrow ${classes.imgFilter}`} /> */}
                      </a>
                    </Grid>
                  )}
                </Grid>
                <div
                  className="row text-center mt-5"
                  style={{ marginTop: "2rem" }}
                  align="center"
                >
                  <button
                    className={`button goto-button ${classes.backColor +
                      " " +
                      classes.font}`}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleChange(row.ScreenName, "Active")}
                  >
                    Goto Active {row.ScreenName}
                  </button>
                </div>
              </div>
            </Grid>
          );
        }
      }
    });

    // resources.map(row => {
    //   if (!row.isLaunch) {
    //     return (
    //       <> </>
    //     );
    //   }
    //   const serviceStatus =
    //     services[row.id] || services[row.tempId] || {};
    //   return (
    //    <> </>
    //   )
    // }))
  };

  return (
    <div>
      <Drawer
        anchor={"right"}
        open={showRightDrawer}
        classes={{
          paper: classes.drawerPaper
        }}
        PaperProps={{ id: "infiniteScrollNotification" }}
        onClose={() => toggleRightDrawer(!showRightDrawer)}
      >
        {generateDrawerComponent(upgrade)}
      </Drawer>
      <div className={classes.root}>
        {/* <div className="container-fluid ">
     <div className="row ">
    <div className="col-sm-12">
	 <div className="container ">
		 <div className="row mt-5 pb-5"> */}
        <Grid container spacing={3} className="homespace">
          {showWarning ? <WarningDialog /> : null}
          <TableSection classes={classes} />
          {/* <div className="col-sm-6 pl-5">
				<div className="home-card ">				  
					<h3 className="home-widget-title">Design Center</h3> <img src={HeaderImage} className="home-wid-img"/>					
					<div className="row box-inner-height">
					<div className="col-sm-12 col-md-6">
					<p className="home-sub-title mt-5">My Designs: 12</p>
					</div>
					<div className="col-sm-12 col-md-6"></div>
					</div>
					<div className="row text-center mt-5">			
					  <button className="button goto-button">Goto Design Center</button>
					</div>
			    </div> 
		  </div>
		  <div className="col-sm-6 pr-5">
		        <div className="home-card ">				  
					<h3 className="home-widget-title">Design Center</h3> <img src={HeaderImage2} className="home-wid-img2"/>					
					<div className="row box-inner-height">
					<div className=" col-sm-12 col-md-6">
					<p className="home-sub-title mt-5">My Designs: 12</p>
					<a href="" className="sub-title-link">All Available Learning<img src={ArrowImage} className="arrow"/></a>
					</div>
					<div className="col-sm-12 col-md-6">
					<p className="home-sub-title mt-5">Subscribed: 1</p>
					<a href="" className="sub-title-link">My Subscribed Learning<img src={ArrowImage} className="arrow"/></a>
					</div>
					</div>
					<div className="row text-center mt-5">			
					  <button className="button goto-button">Goto Active Learning</button>
					</div>
			    </div>
		  </div>
		  <div className="col-sm-6 pl-5 mt-4">
				<div className="home-card ">				  
					<h3 className="home-widget-title">Experience Center - Showcases</h3> <img src={HeaderImage} className="home-wid-img"/>					
					<div className="row box-inner-height">
					<div className="col-sm-12 col-md-6">
					<a href="" className="sub-title-link">Not available in your plan.</a>
					</div>
					<div className="col-sm-12 col-md-6"></div>
					</div>
					<div className="row text-center mt-5">			
					  <button className="button goto-button">Upgrade Your Subscription</button>
					</div>
			    </div> 
		  </div>
		  <div className="col-sm-6 pr-5  mt-4">
		        <div className="home-card ">				  
					<h3 className="home-widget-title">Experience Center - Sandboxes</h3> <img src={HeaderImage} className="home-wid-img2"/>					
					<div className="row box-inner-height">
					<div className="col-sm-12 col-md-6">
					<p className="home-sub-title mt-5">Availabe: 12</p>
					<a href="" className="sub-title-link">My Available Sandboxes<img src={ArrowImage} className="arrow"/></a>
					</div>
					<div className="col-sm-12 col-md-6">
					<p className="home-sub-title mt-5">Subscribed: 1</p>
					<a href="" className="sub-title-link">My Subscribed Sandboxes<img src={ArrowImage} className="arrow"/></a>
					</div>
					</div>
					<div className="row text-center mt-5">			
					  <button className="button goto-button">Goto Active Sandboxes</button>
					</div>
			    </div>
		  </div> */}
          {/* </div>

  
       </div>
   

			  </div>
			</div>
			
		  </div>			  
      </div>		
</div> */}
        </Grid>
      </div>
      <Chatbot />
    </div>
  );
}
