import React from "react";
import { Switch, Route } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import routes from "routes.js";
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import login from "assets/criterion_networks/img/background-image.png";
import { useSelector, useDispatch } from "react-redux";
import Loader from "react-loader-spinner";
import { LOADER_TYPE } from "global_constants/app_constants";
import { HIDE_NOTIFICATION } from "action_creators/actioncreator";
import { PRIMARY_COLOR } from "global_constants/app_constants";
import theme from "../theme";
const useStyles = makeStyles(styles);

export default function Pages(props) {
  const { util } = useSelector(state => state);
  const dispatch = useDispatch();
  const { ...rest } = props;
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBgImage = () => {
    // if (window.location.pathname.indexOf("/auth/register-page") !== -1) {
    //   return register;
    // }

    if (window.location.pathname.indexOf("/auth/login-page") !== -1 || window.location.pathname.indexOf("/auth/reset-password") !== -1) {
      return login;
    }
    // } else if (window.location.pathname.indexOf("/auth/pricing-page") !== -1) {
    //   return pricing;
    // } else if (
    //   window.location.pathname.indexOf("/auth/lock-screen-page") !== -1
    // ) {
    //   return lock;
    // } else if (window.location.pathname.indexOf("/auth/error-page") !== -1) {
    //   return error;
    // } else if (window.location.pathname.indexOf("/auth/reset-page") !== -1) {
    //   return login;
    // }
  };
  const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  const isImg = getBgImage();
  return (
    <div>
      <AuthNavbar brandText={getActiveRoute(routes)} {...rest} />
      <div className={classes.wrapper} ref={wrapper}>
        <div
          className={classes.fullPage}
          style={{
            backgroundImage: isImg ? "url(" + getBgImage() + ")" : "unset"
          }}
        >
          {util.showLoader ? (
            <Loader
              type={LOADER_TYPE}
              color={theme.palette.secondary.main}
              width={50}
              height={50}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)"
              }}
            />
          ) : (
            <div className={classes.loginContainer}>
              <Switch>{getRoutes(routes)}</Switch>
            </div>
          )}
          <Snackbar
            place="tr"
            color={util.notificationColor}
            // icon={AddAlert}
            message={util.notificationMessage}
            open={util.showNotification}
            closeNotification={() => {
              dispatch(HIDE_NOTIFICATION());
            }}
            close
          />
        </div>
      </div>
    </div>
  );
}