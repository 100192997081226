import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// Local
import Menu from "../../Components/Menu";
import { SDCloudBackendAPIWithToken } from "../../../apis/backendAPI";
import { CMSAPIWithToken } from "../../../apis/CMS";
import { useDispatch } from "react-redux";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
// Material UI Components
import { Alert } from "@material-ui/lab";
// Icons
import Visibility from "@material-ui/icons/Visibility";
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// Local
import CustomTable from "../Components/Table";
import RightDrawer from "../Components/RightDrawer";
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import { IconButton } from "@material-ui/core";
import queryString from "query-string";
import {hotjar} from "react-hotjar";
import SandboxesExercisesView from "./SandboxesExercisesView";
import SandboxesExercisesUpdate from "./SandboxesExercisesUpdate";

// Styling
const useStyles = makeStyles(theme => ({
  backButton: {
    margin: "15px 0",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& svg": {
      marginRight: "5px",
    },
    "&:hover": {
      color: theme.palette.secondary.main,
      "& svg": {
        fill: theme.palette.secondary.main,
      },
    },
  },
    root: {
        marginTop: "50px"
    }
}));

const SandboxExercisesList = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        dispatch(SET_NAVBAR_TITLE("Content Management - Sandbox"));
        renderrow();
    }, []);
    hotjar.initialize(2281731, 6);
    hotjar.identify(localStorage.userName, { userProperty: localStorage.userName});
    // States
    
    const [sandboxExercisesRows, setSandboxExercisesRows] = useState([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [currentInventory, setCurrentInventory] = useState("");
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState([]);
    const [currentScreen, setCurrentScreen] = useState("Sandboxes");
    const [currentDrawerScreen, setcurrentDrawerScreen] = useState();
    const [currentUser, setCurrentUser] = useState();
    const [categoriesValue, setCategoriesValue] = useState([]);
    const [currentCategory, setCurrentCategory] = useState();
    let queryParams = queryString.parse(window.location.search);
    var sb_id = queryParams.id;
    var cat_id = queryParams.cat_id;
    var sandbox_cat_id_map = JSON.parse(localStorage.getItem("sandbox_cat_id_map"))
    var sandbox_id_title_map = JSON.parse(localStorage.getItem("sandbox_id_title_map"))
    var sandbox_title = sandbox_id_title_map[sb_id]
    const [notificationType, setNotificationType] = useState("");
    const sandboxexercisescolumns = [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Category Name",
        accessor: "admin_sandboxes_category_name",
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Created",
        accessor: "created",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ];

    let sandboxExercisesData = [];
    let categoriesData = [];
    //var menuItems = ["Learnings", "Learning Overview", "Learning Modules", "Course Overview", "Course ELearning", "Learning Lab","Learning Category","Learning Exercises"];

    const [isLoading, setIsLoading] = useState(true);
    const renderNotififications = () => {
        let notifications = notificationMessage.map((item) => {
          let notificationType = item.success ? "success" : "error";
          return (
            <Alert
              onClose={() => setShowNotification(false)}
              severity={notificationType}
              spacing={1}
            >
              {item.message}
            </Alert>
          );
        });
    
        return notifications;
      };

//Learning  API call --- for data featch
      const renderrow = async (value) => {
        CMSAPIWithToken()
          .get("/poc/admin_sandboxes_categories/")
          .then((categoriesResponse) => {
            const catData = categoriesResponse.data.results;
            catData.forEach((item) => {
                if(item.admin_sandboxes_id == sb_id){
                  categoriesData.push(item)
                }
              });  
            setCategoriesValue(categoriesData);
          })
        return CMSAPIWithToken()
        .get("/poc/admin_sandboxes_exercises/")
        .then((resp) => resp.data.results)
          .then((resp) => {
            resp.forEach((item) => {
                if(item.admin_sandboxes_id == sb_id && item.admin_sandboxes_categories_id == cat_id){
                    sandboxExercisesData.push({
                        id: item.id,
                        admin_sandboxes_category_name:sandbox_cat_id_map[item.admin_sandboxes_categories_id],
                        title: item.title,
                        created: item.created,
                      actions: (
                        <Grid container wrap="wrap" spacing={1}>
                          <IconButton onClick={() => handlExercisesViewButton(item.id, item.admin_sandboxes_categories_id)}>
                            <Visibility />
                          </IconButton>
                          <IconButton onClick={() => handleExercisesEditButton(item.id, item.admin_sandboxes_categories_id)}>
                            <EditIcon/>
                          </IconButton>
                          <IconButton onClick={() => handleExercisesDeleteButton(item.id)}>
                            <DeleteForeverIcon/>
                          </IconButton>
                        </Grid>
                      ),
                      });
                }
            });
           
            setSandboxExercisesRows(sandboxExercisesData);
            
            setIsLoading(false);
          })
          .catch((err) => {
            let message = err.response.data.message || "unknown error occured";
            setIsLoading(false);
          });
      };

      const handleExercisesDeleteButton = (id) => {
        let user_confirm = window.confirm("Do you want to delete excercise?")
        if(user_confirm){
        CMSAPIWithToken()
        .delete("/poc/admin_sandboxes_exercises"+`/${id}/`)
          .then((resp) => resp.data.results)
          .then((data) => {
          const payload = {
          message: "Sandbox Exercise Deleted Successfully",
          color: "success",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
          history.push("ProductContentSandboxesMenuItems?id="+sb_id+"&title="+sandbox_title);
          })
          .catch((err) => {
            let message = err.response.data.message;
            const payload = {
              message: message,
              color: "danger",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
      }
    };
      // view single product details
      const renderDrawerComponent = () => {
        switch (currentDrawerScreen) {
          case "Sandboxes Exercises View":
            return <SandboxesExercisesView user={currentUser} category={currentCategory} />;
          case "Sandboxes Exercises Edit":
            return <SandboxesExercisesUpdate user={currentUser} categories={categoriesValue} />;                      
          case "default":
            return null;
        }
      };
      //code for view button
      const handlExercisesViewButton = (username, category_id) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Sandboxes Exercises View");
        setCurrentUser(username);
        setCurrentCategory(category_id)
      };
     
      const handleExercisesEditButton = (username, category_id) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Sandboxes Exercises Edit");
        setCurrentUser(username);
        setCurrentCategory(category_id);
      };

      const handleAddUserSandboxExercises = () => {
        history.push({
          pathname: 'SandboxesExercisesCreate',
          search: '?id='+sb_id,
          state: { detail: 'some_value' }
        });  
      };
      const backButton = () => {
        history.goBack()
      };
      
    const renderContent = () => {
      const query=new URLSearchParams(window.location.search);
        const title = query.get("title");
        switch (currentScreen) {
            case "Sandboxes":
              return (
                <Grid container className={classes.root} spacing={1}>
                  <LoaderHoc isLoading={isLoading}>
                  <GridContainer className="backbutton">
                    <GridItem sm={12} md={12}>
                      <Typography
                        variant="body1"
                        className={classes.backButton}
                        onClick={backButton}
                      >
                        <ArrowBackIcon /> Back
                      </Typography>
                    </GridItem>
                  </GridContainer>
                  <Grid item xs={12} md={12}>
                    {showNotification ? renderNotififications() : null}
                  </Grid>
                  <Grid item xs={12} md={12}>
                      <CustomTable
                        columns={sandboxexercisescolumns}
                        data={sandboxExercisesRows}
                        handleButtonClick={handleAddUserSandboxExercises}
                        tableTitle=  { title+" - "+"Sandbox Exercises"}
                        buttonTitle="Add Sandbox Exercises"
                        hiddenColumns={[]}
                      />
                  </Grid>
                  </LoaderHoc>
                </Grid>
              );
        }
    };

    return (
        <div>
            <Grid container className={classes.root}>
                <Grid item md={12}>
                    {renderContent()}
                </Grid>
                 <RightDrawer
                      open={openDrawer}
                      onClose={setOpenDrawer}
                      title={currentInventory}
                    >
                      {renderDrawerComponent()}
                    </RightDrawer>
            </Grid>
        </div>
    );
};

export default SandboxExercisesList;