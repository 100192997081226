import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

// Formik
import { useFormik } from "formik";

// Material UI Components
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import { IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import RightDrawer from "./RightDrawer";
import CreateProfile from "./CreateProfile";
import ProfileDetails from "./ProfileDetails";
import { useLocation } from "react-router-dom";

const inputProps = {
  disableAnimation: true,
  shrink: false,
};

const formControlProps = {
  root: {
    marginTop: "10px",
  },
};

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    padding: "0 40px",
  },
  radioButton: {
    "& .MuiTypography-root": {
      marginLeft: "10px",
    },
  },
  formControl: {
    width: "100%",
    marginTop: "5px",
  },
  Select: {
    marginBottom: "5px",
  },
  warningNote: {
    "& .MuiAlert-icon": {
      display: "none",
    },
  },
  button: {
    marginTop: "50px",
  },
  buttOutlined: {
    color: theme.palette.buttonColor.main,
    borderColor: theme.palette.buttonColor.main,
    "&:hover": {
      border: `1px solid ${theme.palette.buttonColor.main}`,
    },
  },
}));

const UserDetails = (props) => {
  const classes = useStyles();
  // States
  const [menuItems, setMenuItems] = useState([]);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentScreen, setCurrentScreen] = useState();
  const [currentProfile, setCurrentProfile] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [permissionProfileName, setProfileName] = useState("");
  const [maxTemplates, setMaxTemplates] = useState(10);
  const [userDetails, setUserDetails] = useState({
    username: "",
    email: "",
    privilege: "",
    firstName: "",
    lastName: "",
  });
  const location = useLocation();

  const renderDrawerComponent = () => {
    switch (currentScreen) {
      case "Create Profile":
        return <CreateProfile />;
      case "Profile Details":
        if (currentProfile === null || profileData === null) {
          return null;
        } else if (currentProfile.profile_name !== profileData.profile_name) {
          return null;
        } else {
          return (
            <ProfileDetails
              profile={currentProfile}
              profileData={profileData}
              maxTemplates={maxTemplates}
            />
          );
        }
      case "default":
        return null;
    }
  };
  const handleViewButton = (profile_name) => {
    let profileData = SDCloudBackendAPIWithToken()
      .post("/get_profile", {
        profile_name: profile_name,
      })
      .then((resp) => resp.data)
      .then((data) => {
        let profiles = data.profiles[0];
        let max_allowed_templates = data.profiles[0].max_allowed_templates;
        setMaxTemplates(max_allowed_templates);

        let payload = {
          profile_name: profile_name,
          learningPermissions: [],
          learningViews: [],
          sandboxPermissions: [],
          sandboxViews: [],
          designerPermissions: [],
        };

        profiles.permissions.forEach((item) => {
          if (item.resource_type === "learning" && item.permission === "view") {
            payload.learningViews.push(item.resource_name);
          } else if (
            item.resource_type === "learning" &&
            item.permission === "create"
          ) {
            payload.learningPermissions.push(item.resource_name);
          } else if (
            item.resource_type === "sandbox" &&
            item.permission === "view"
          ) {
            payload.sandboxViews.push(item.resource_name);
          } else if (
            item.resource_type === "sandbox" &&
            item.permission === "create"
          ) {
            payload.sandboxPermissions.push(item.resource_name);

            if (item.resource_name === "designer_portal") {
              if (item.designer_objects) {
                payload.designerPermissions = item.designer_objects.map(
                  (designerObject) => {
                    return designerObject.name;
                  }
                );
              }
            }
          }
        });

        setOpenDrawer(true);
        setCurrentScreen("Profile Details");
        setCurrentProfile({ ...currentProfile, ...payload });
        setProfileData({ ...profileData, ...profiles });
      });
  };

  const formik = useFormik({
    initialValues: userDetails,
    onSubmit: (values) => {
      let payload = {
        username: values.username,
        email: values.email,
        privilege: values.privilege,
        permission_profile_name: permissionProfileName,
        first_name: values.firstName,
        last_name: values.lastName,
      };
      
      SDCloudBackendAPIWithToken()
        .post("/update_user", payload)
        .then((resp) => resp.data)
        .then((data) => {
          setShowNotification(true);
          setNotificationType("success");
          setNotificationMessage("User updated successfully");
        })
        .catch((err) => {
          let message = err.response.data.message;
          setShowNotification(true);
          setNotificationType("error");
          setNotificationMessage(message);
        });
    },
    enableReinitialize: true,
  });

  const renderProfileDropdown = async () => {
    const profileItems = SDCloudBackendAPIWithToken()
      .get("/get_profile")
      .then((resp) => resp.data)
      .then((data) => {
        let profiles = data.profiles;
        let items = profiles.map((item) => {
          return item.profile_name;
        });
        setMenuItems(items);
      });
  };

  const renderUserDetails = async () => {
    const userDetails = SDCloudBackendAPIWithToken()
      .post("get_user", {
        username: props.user,
      })
      .then((resp) => resp.data)
      .then((data) => {
        let details = {
          username: data.users[0].username,
          email: data.users[0].email,
          privilege: data.users[0].privilege,
          permission_profile: data.users[0].permission_profile_name,
          firstName: data.users[0].firstname,
          lastName: data.users[0].lastname,
          
        };
        setProfileName(data.users[0].permission_profile_name)
        setUserDetails({ ...formik.userDetails, ...details });
        
      });
  };

  const handleResetPasswordButton = () => {
    let payload = {
      username: userDetails.username,
      email: userDetails.email,
      base_url: `https://${window.location.host}/auth/`,
    };

    let response = SDCloudBackendAPIWithToken()
      .post("/forgetPassword/", payload)
      .then((resp) => resp.data)
      .then((data) => {
        setShowNotification(true);
        setNotificationType("success");
        setNotificationMessage(
          "Password reset mail sent successfully to the user"
        );
      })
      .catch((err) => {
        let message = err.response.data.message;
        setShowNotification(true);
        setNotificationType("error");
        setNotificationMessage(message);
      });
  };

  useEffect(() => {
    renderProfileDropdown();
    renderUserDetails();
  }, []);

  return (
    <div>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.gridContainer}
      >
        <Grid item xs={12} md={5}>
          <Typography variant="h4">{userDetails.email}</Typography>
          <Typography variant="subtitle2">{userDetails.username}</Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <Button
            variant="outlined"
            color="secondary"
            disableElevation
            size="large"
            fullWidth
            disableFocusRipple
            type="submit"
            className={classes.buttOutlined}
            onClick={handleResetPasswordButton}
          >
            Reset Password
          </Button>
        </Grid>
      </Grid>
      <form onSubmit={formik.handleSubmit}>
        <Grid container className={classes.gridContainer}>
          <Grid item md={12}>
            <Grid container justify="space-between">
              <Grid item xs={12} md={5}>
                {formik.errors.firstName && formik.touched.firstName ? (
                  <TextField
                    type="text"
                    variant="filled"
                    label="First Name"
                    name="firstName"
                    fullWidth
                    InputLabelProps={inputProps}
                    value={formik.values.firstName}
                    disabled={location.pathname == "/reports"}
                    onChange={formik.handleChange}
                    helperText={formik.errors.firstName}
                    onBlur={formik.handleBlur}
                    error
                  />
                ) : (
                  <TextField
                    type="text"
                    variant="filled"
                    label="First Name"
                    name="firstName"
                    disabled={location.pathname == "/reports"}
                    fullWidth
                    InputLabelProps={inputProps}
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={5}>
                {formik.errors.lastName && formik.touched.lastName ? (
                  <TextField
                    type="text"
                    variant="filled"
                    label="Last Name"
                    name="lastName"
                    fullWidth
                    InputLabelProps={inputProps}
                    value={formik.values.lastName}
                    disabled={location.pathname == "/reports"}
                    onChange={formik.handleChange}
                    helperText={formik.errors.lastName}
                    onBlur={formik.handleBlur}
                    error
                  />
                ) : (
                  <TextField
                    type="text"
                    variant="filled"
                    label="Last Name"
                    name="lastName"
                    fullWidth
                    InputLabelProps={inputProps}
                    value={formik.values.lastName}
                    disabled={location.pathname == "/reports"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} style={{ marginTop: "35px" }}>
            <Grid container justify="space-between">
              <Grid item xs={12} md={6}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">User Privilege</FormLabel>
                  <RadioGroup
                    row
                    aria-label="privilege"
                    name="privilege"
                    value={formik.values.privilege}
                    className={classes.radioButton}
                    onChange={formik.handleChange}
                    defaultValue={userDetails.privilege}
                  >
                    <FormControlLabel
                      value="developer"
                      control={<Radio color="primary" />}
                      label="User"
                    />
                    <FormControlLabel
                      value="admin"
                      control={<Radio color="primary" />}
                      label="Admin"
                    />
                    {/* <FormControlLabel
                      value="profile_admin"
                      control={<Radio color="primary" />}
                      label="Profile Admin"
                    /> */}
                  </RadioGroup>
                </FormControl>
              </Grid>
              {formik.values.privilege !== "admin" ||
              formik.values.privilege !== "profile_admin" ? (
                <Grid item xs={12} md={5} style={{ marginTop: "10px" }}>
                  <Grid
                    container
                    alignItems="flex-end"
                    style={{ height: "100%" }}
                  >
                    <FormLabel>Permission Profile</FormLabel>
                    <Grid item xs={12} md={10}>
                      <FormControl
                        variant="filled"
                        className={classes.formControl}
                      >
                        <Autocomplete
                          disabled={
                            formik.values.privilege !== "admin" ? false : true
                          }
                          openOnFocus
                          id="permission_profile"
                          options={menuItems}
                          name="permission_profile"
                          value={permissionProfileName}
                          onChange={(e,newValue)=>{
                            setProfileName(newValue)
                          }}
                          renderInput={(params) =>
                            formik.errors.permission_profile &&
                            formik.touched.permission_profile ? (
                              <TextField
                                {...params}
                                type="text"
                                variant="filled"
                                fullWidth
                                helperText={formik.errors.permission_profile}
                                error
                              />
                            ) : (
                              <TextField
                                {...params}
                                type="text"
                                variant="filled"
                                fullWidth
                              />
                            )
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <Button
                        disabled={
                          formik.values.privilege == "admin" ? true : false
                        }
                        onClick={() =>
                          handleViewButton(permissionProfileName)
                        }
                        color="action"
                      >
                        <Visibility />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              variant="outlined"
              color="secondary"
              disableElevation
              size="large"
              fullWidth
              disableFocusRipple
              type="submit"
              className={classes.button + " " + classes.buttOutlined}
            >
              Save & Update
            </Button>
          </Grid>
          <Grid item xs={12} md={12}>
            {showNotification ? (
              <Alert
                onClose={() => setShowNotification(false)}
                severity={notificationType}
              >
                {notificationMessage}
              </Alert>
            ) : null}
          </Grid>
        </Grid>
        <RightDrawer
          open={openDrawer}
          onClose={setOpenDrawer}
          title={currentScreen}
        >
          {renderDrawerComponent()}
        </RightDrawer>
      </form>
    </div>
  );
};

export default UserDetails;
