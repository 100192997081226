import React, { useEffect } from "react";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import UserIcon from "assets/img/UserIcon.png";
import Edituser from "assets/img/edit-user.png";
import Usersettings from "assets/img/user-settings.png";
import Inventory from "assets/img/inventory.png";
import './accounts.css'
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";

// Styling
const useStyles = makeStyles(theme => ({
    root: {
        textAlign: "end"
    },
    btnStyle: {
        background: `${theme.palette.buttonColor.main} !important`,
    },
    rootbottom: {
        textAlign: "center",
        fontWeight: 400,
        color: "gray"
    },
}));

const Account = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(SET_NAVBAR_TITLE("Accounts"));
      }, []);

    const handleSubmit = () => {
        history.push("/createaccount");
    };

    const handleSettings = () => {
        history.push("/accountsettings");
    };

    const handleInventory = () => {
        history.push("/accountinventory");
    };

    return (
        <div>
            <Grid container>
                <Grid item xs={12} sm={12} md={12} className={classes.root}>
                    <Button
                        onClick={() => {
                            handleSubmit();
                        }}
                        variant="contained"
                        color="secondary"
                        disableElevation
                        size="medium"
                        className={classes.btnStyle}
                        disableFocusRipple
                        type="submit"
                    >
                        Create Account
                    </Button>
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                    <div class="account-card ">
                        <div className="account-card-top">
                            <h3 class="account-title">Tata Communications</h3>
                            <img src={UserIcon} class="account-wid-img1" />
                            <img src={Edituser} class="account-wid-img2" />
                            <Grid container className='box-inner-height mt-2'>
                                <Grid item xs={12} sm={6}>
                                    <p class="account-sub-title mt-5">PIC Name</p>
                                    <strong >Luke Matheww</strong>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <p class="account-sub-title mt-5">Country</p>
                                    <strong>germany</strong>
                                </Grid>
                                <Grid item xs={12} sm={6} className="mt-2">
                                    <p class="account-sub-title mt-5">PIC MAil ID</p>
                                    <strong>lukemathew@gmail.com</strong>
                                </Grid>
                            </Grid>
                        </div>
                        <Grid container className='account-inner-height mt-2'>
                            <Grid item xs={6} sm={6} md={6} className={`${classes.rootbottom} account-right`}>
                                <img src={Usersettings} class="" />
                                <Button style={{ color: "#a49e9e" }}
                                    onClick={() => {
                                        handleSettings();
                                    }}
                                >Settings</Button>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} className={classes.rootbottom}>
                                <img src={Inventory} class="" />
                                <Button style={{ color: "#a49e9e" }}
                                    onClick={() => {
                                        handleInventory();
                                    }}
                                >Inventory</Button>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                    <div class="account-card ">
                        <div className="account-card-top">
                            <h3 class="account-title">Tata Communications</h3>
                            <img src={UserIcon} class="account-wid-img1" />
                            <img src={Edituser} class="account-wid-img2" />
                            <Grid container className='box-inner-height mt-2'>
                                <Grid item xs={12} sm={6}>
                                    <p class="account-sub-title mt-5">PIC Name</p>
                                    <strong >Luke Matheww</strong>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <p class="account-sub-title mt-5">Country</p>
                                    <strong>germany</strong>
                                </Grid>
                                <Grid item xs={12} sm={6} className="mt-2">
                                    <p class="account-sub-title mt-5">PIC MAil ID</p>
                                    <strong>lukemathew@gmail.com</strong>
                                </Grid>
                            </Grid>
                        </div>
                        <Grid container className='account-inner-height mt-2'>
                            <Grid item xs={6} sm={6} md={6} className={`${classes.rootbottom} account-right`}>
                                <img src={Usersettings} class="" />
                                <Button style={{ color: "#a49e9e" }}
                                    onClick={() => {
                                        handleSettings();
                                    }}
                                >Settings</Button>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} className={classes.rootbottom}>
                                <img src={Inventory} class="" />
                                <Button style={{ color: "#a49e9e" }}
                                    onClick={() => {
                                        handleInventory();
                                    }}
                                >Inventory</Button>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                    <div class="account-card ">
                        <div className="account-card-top">
                            <h3 class="account-title">Tata Communications</h3>
                            <img src={UserIcon} class="account-wid-img1" />
                            <img src={Edituser} class="account-wid-img2" />
                            <Grid container className='box-inner-height mt-2'>
                                <Grid item xs={12} sm={6}>
                                    <p class="account-sub-title mt-5">PIC Name</p>
                                    <strong >Luke Matheww</strong>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <p class="account-sub-title mt-5">Country</p>
                                    <strong>germany</strong>
                                </Grid>
                                <Grid item xs={12} sm={6} className="mt-2">
                                    <p class="account-sub-title mt-5">PIC MAil ID</p>
                                    <strong>lukemathew@gmail.com</strong>
                                </Grid>
                            </Grid>
                        </div>
                        <Grid container className='account-inner-height mt-2'>
                            <Grid item xs={6} sm={6} md={6} className={`${classes.rootbottom} account-right`}>
                                <img src={Usersettings} class="" />
                                <Button style={{ color: "#a49e9e" }}
                                    onClick={() => {
                                        handleSettings();
                                    }}
                                >Settings</Button>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} className={classes.rootbottom}>
                                <img src={Inventory} class="" />
                                <Button style={{ color: "#a49e9e" }}
                                    onClick={() => {
                                        handleInventory();
                                    }}
                                >Inventory</Button>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

                <Grid item xs={12} sm={4} md={4}>
                    <div class="account-card ">
                        <div className="account-card-top">
                            <h3 class="account-title">Tata Communications</h3>
                            <img src={UserIcon} class="account-wid-img1" />
                            <img src={Edituser} class="account-wid-img2" />
                            <Grid container className='box-inner-height mt-2'>
                                <Grid item xs={12} sm={6}>
                                    <p class="account-sub-title mt-5">PIC Name</p>
                                    <strong >Luke Matheww</strong>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <p class="account-sub-title mt-5">Country</p>
                                    <strong>germany</strong>
                                </Grid>
                                <Grid item xs={12} sm={6} className="mt-2">
                                    <p class="account-sub-title mt-5">PIC MAil ID</p>
                                    <strong>lukemathew@gmail.com</strong>
                                </Grid>
                            </Grid>
                        </div>
                        <Grid container className='account-inner-height mt-2'>
                            <Grid item xs={6} sm={6} md={6} className={`${classes.rootbottom} account-right`}>
                                <img src={Usersettings} class="" />
                                <Button style={{ color: "#a49e9e" }}
                                    onClick={() => {
                                        handleSettings();
                                    }}
                                >Settings</Button>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} className={classes.rootbottom}>
                                <img src={Inventory} class="" />
                                <Button style={{ color: "#a49e9e" }}
                                    onClick={() => {
                                        handleInventory();
                                    }}
                                >Inventory</Button>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <div class="account-card ">
                        <div className="account-card-top">
                            <h3 class="account-title">Tata Communications</h3>
                            <img src={UserIcon} class="account-wid-img1" />
                            <img src={Edituser} class="account-wid-img2" />
                            <Grid container className='box-inner-height mt-2'>
                                <Grid item xs={12} sm={6}>
                                    <p class="account-sub-title mt-5">PIC Name</p>
                                    <strong >Luke Matheww</strong>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <p class="account-sub-title mt-5">Country</p>
                                    <strong>germany</strong>
                                </Grid>
                                <Grid item xs={12} sm={6} className="mt-2">
                                    <p class="account-sub-title mt-5">PIC MAil ID</p>
                                    <strong>lukemathew@gmail.com</strong>
                                </Grid>
                            </Grid>
                        </div>
                        <Grid container className='account-inner-height mt-2'>
                            <Grid item xs={6} sm={6} md={6} className={`${classes.rootbottom} account-right`}>
                                <img src={Usersettings} class="" />
                                <Button style={{ color: "#a49e9e" }}
                                    onClick={() => {
                                        handleSettings();
                                    }}
                                >Settings</Button>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6} className={classes.rootbottom}>
                                <img src={Inventory} class="" />
                                <Button style={{ color: "#a49e9e" }}
                                    onClick={() => {
                                        handleInventory();
                                    }}
                                >Inventory</Button>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>

            </Grid>
        </div>
    );
};

export default Account;