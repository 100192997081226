import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";

// Local
import { CMSAPIWithToken } from "../../../apis/CMS";
import { useDispatch } from "react-redux";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import ShowcaseView from "./ShowcaseView";
import ShowcaseUpdate from "./ShowcaseUpdate";
// Material UI Components
import { Alert } from "@material-ui/lab";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
// Icons
import Visibility from "@material-ui/icons/Visibility";
import EditIcon from '@material-ui/icons/Edit';
import ForwardIcon from '@material-ui/icons/Forward';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// Local
import CustomTable from "../Components/Table";
import RightDrawer from "../Components/RightDrawer";
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import { IconButton } from "@material-ui/core";
//import Visibility from "@material-ui/icons/Visibility";
import {hotjar} from "react-hotjar";


// Styling
const useStyles = makeStyles(theme => ({
    root: {
        marginTop: "50px"
    },
    backButton: {
      margin: "15px 0",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "& svg": {
        marginRight: "5px",
      },
      "&:hover": {
        color: theme.palette.secondary.main,
        "& svg": {
          fill: theme.palette.secondary.main,
        },
      },
    },
}));

const ProductContentShowcases = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const profileData = JSON.parse(localStorage.getItem("profileData"));
    const account = profileData.account;
    let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
    let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
    let hasDomain = domain?.some((res) => userEmail.includes(res));
    const [isInternal, setIsInternal] = useState(hasDomain);
    useEffect(() => {
        dispatch(SET_NAVBAR_TITLE(isInternal?account+"  - Content Management Showcases":"Content Management Showcases"));
        renderrow();

    }, []);
    hotjar.initialize(2281731, 6);
    hotjar.identify(localStorage.userName, { userProperty: localStorage.userName});
    // States
    
    const [showcaseRows, setShowcaseRows] = useState([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [currentInventory, setCurrentInventory] = useState("");
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState([]);
    const [currentScreen, setCurrentScreen] = useState("Showcase");
    const [currentDrawerScreen, setcurrentDrawerScreen] = useState();
    
    const [currentUser, setCurrentUser] = useState();
    var showcases_id_title_map = {};
    const [notificationType, setNotificationType] = useState("")
    const columns = [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Short Title",
        accessor: "short_title",
      },
      {
        Header: "Full Title",
        accessor: "full_title",
      },
      {
        Header: "Created",
        accessor: "created",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ];

    let showcaseData = [];
  
    //var menuItems = ["Learnings", "Sandboxes", "Showcases",'Designer'];
    //const [currentScreen, setCurrentScreen] = useState(menuItems[0]);
    const [isLoading, setIsLoading] = useState(true);
    const renderNotififications = () => {
        let notifications = notificationMessage.map((item) => {
          let notificationType = item.success ? "success" : "error";
          return (
            <Alert
              onClose={() => setShowNotification(false)}
              severity={notificationType}
              spacing={1}
            >
              {item.message}
            </Alert>
          );
        });
    
        return notifications;
      };

//showcase  API call --- for data featch
      const renderrow = async (value) => {
        return CMSAPIWithToken()
        .get("/sales/admin_sales_demos/")
        .then((resp) => resp.data.results)
          .then((resp) => {
            resp.forEach((item) => {
              showcases_id_title_map[item.id] = item.short_title;
              showcaseData.push({
                  id: item.id,
                  short_title: item.short_title,
                  full_title: item.full_title,
                  created: item.created,
                actions: (
                  <Grid container wrap="wrap" spacing={1}>
                    <IconButton onClick={() => handleViewButton(item.id)}>
                      <Visibility />
                    </IconButton>
                    <IconButton onClick={() => handleEditButton(item.id)}>
                      <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleDeleteButton(item.id)}>
                      <DeleteForeverIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleForwardButton(item.id,item.short_title)}>
                      <ForwardIcon/>
                    </IconButton>
                  </Grid>
                ),
                });
            });
            localStorage.removeItem("showcases_id_title_map");
            localStorage.setItem("showcases_id_title_map", JSON.stringify(showcases_id_title_map));
            setShowcaseRows(showcaseData);
            
            setIsLoading(false);
          })
          .catch((err) => {
            let message = err.response.data.message || "unknown error occured";
            setIsLoading(false);
          });
      };


//Sandbox Delete Items 
      const handleDeleteButton = (id) => {
        let user_confirm = window.confirm("Do you want to delete excercise?")
        if(user_confirm){
        CMSAPIWithToken()
        .delete("/sales/admin_sales_demos"+`/${id}/`)
        .then((resp) => resp.data.results)
        .then((data) => {
          const payload = {
              message: "Showcase Deleted Successfully",
              color: "success",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
          history.push("/product-admin-showcases");
          window.location.reload()
      })
      .catch((err) => {
        let message = err.response.data.message;
        const payload = {
          message: message,
          color: "danger",
      };
      payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
      });
      };
    }

      
      // view single product details
      const renderDrawerComponent = () => {
        switch (currentDrawerScreen) {
          //case "Add User":
          //  return <AddUser resetapi={renderuser} />;
          case "Showcase View":
            return <ShowcaseView user={currentUser} />; 
          case "Showcase Edit":
            return <ShowcaseUpdate user={currentUser} />;
           
          case "default":
            return null;
        }
      };
//code for view button
      const handleViewButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Showcase View");
        setCurrentUser(username);
      };
      
      //code for edit button 
      const handleForwardButton = (sb_id,short_title) => {
        history.push("ProductContentShowcases/ProductContentShowcasesMenuItems?id="+sb_id+"&title="+short_title);
      };     
      const handleEditButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Showcase Edit");
        setCurrentUser(username);
      };
     
     /* const handleAddInventoryButton = () => {
        setOpenDrawer(true);
        setCurrentDrawerScreen("Add New Inventory");
        setCurrentInventory("Add New Inventory");
      }; 
      */

      //Add new sandbox , Option , Details ,Cheat Sheets, Labs ,Categories,Exercises
      const handleAddUserShowcase = () => {
        history.push("ProductContentShowcases/ShowcaseCreate");  
      };
      
    const renderContent = () => {
        switch (currentScreen) {
            case "Showcase":
              return (
                <Grid container className={classes.root} spacing={1}>
                  <Grid item xs={12} md={12}>
                    {showNotification ? renderNotififications() : null}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LoaderHoc isLoading={isLoading}>
                      <CustomTable
                        columns={columns}
                        data={showcaseRows}
                        handleDeleteButtonClick={handleDeleteButton}
                        handleButtonClick={handleAddUserShowcase}
                        handleViewButtonClick={handleViewButton}
                        tableTitle="Showcase"
                        buttonTitle="Add New Showcase"
                        hiddenColumns={[]}
                      />
                    </LoaderHoc>
                  </Grid>
                </Grid>
              );
    };
  }
    const backButton = () => {
      history.goBack();
    };
    return (
        <div>
            <Grid container className={classes.root}>
            <GridContainer className="backbutton">
                <GridItem sm={12} md={12}>
                  <Typography
                    variant="body1"
                    className={classes.backButton}
                    onClick={backButton}
                  >
                    <ArrowBackIcon /> Back
                  </Typography>
                </GridItem>
              </GridContainer>
                <Grid item md={12}>
                    {renderContent()}
                </Grid>
                 {/* Drawer */}
                 <RightDrawer
                      open={openDrawer}
                      onClose={setOpenDrawer}
                      title={currentInventory}
                    >
                      {renderDrawerComponent()}
                    </RightDrawer>
            </Grid>
        </div>
    );
};

export default ProductContentShowcases;