import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { Card } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/VisibilityOutlined";
import FormHelperText from "@material-ui/core/FormHelperText";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Issue from "@material-ui/icons/Edit";
import { Alert } from "@material-ui/lab";
import CardFooter from "components/Card/CardFooter";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { PRIMARY_COLOR } from "global_constants/app_constants";
import { Api } from "config/api";
import "../Profile.scss";
import {
  FormControl,
  InputLabel,
  FilledInput,
  InputAdornment,
  makeStyles,
  Icon,
  Button
} from "@material-ui/core";

import {
  USER_FORM_FIELDS,
  USER_FORM_LABELS
} from "../utils/constants/constants_profile";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import ViewPermissionProfile from "./components/ViewPermissionProfile";

const customStyles = theme => ({
  containerStyles: {
    padding: "0.9375rem 70px"
  },
  noteStyles: {
    color: "#838383",
    fontSize: "13px",
    fontWeight: "300"
  },
  formInputStyles: {
    margin: "10px auto 10px auto",
    [theme.breakpoints.down("sm")]: {
      margin: "15px"
    }
  },
  inputStyles: {
    padding: "7px 15px 7px",
    height: "36px"
  },
  inputUnderlineStyles: {
    borderBottom: "none"
  },
  inputLabelStyles: {
    // marginBottom: "35px",
    position: "relative"
  },
  cardStyles: {
    color: "rgba(0, 0, 0, 0.87)",
    width: "100%",
    border: "0",
    display: "flex",
    position: "relative",
    fontSize: ".875rem",
    minWidth: "0",
    wordWrap: "break-word",
    background: "#FFF",
    marginTop: "30px",
    borderRadius: "6px",
    marginBottom: "30px",
    flexDirection: "column"
  },
  cardHeaderStyles: {
    padding: "0.9375rem 65px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative",
    "& h3": {
      fontFamily: theme.palette.fontList.selectedFont
    }
  },
  cardBodyStyles: {
    padding: "0.9375rem 65px",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative"
  },
  cardFooterStyles: {
    padding: "0.9375rem 50px",
    // margin: "35px 0 35px 0",
    flex: "1 1 auto",
    WebkitBoxFlex: "1",
    position: "relative"
  },
  buttonStyles: {
    background: theme.palette.buttonColor.main,
    color: "white",
    width: "100%",
    minHeight: "20px",
    padding: "15px 10px 15px",
    fontSize: "1rem",
    textTransform: "none"
  },
  viewPermissionProfile: {
    fontSize: "16px",
    fontFamily: theme.palette.fontList.selectedFont
  },
  errorText: {
    fontSize: "12px",
    color: theme.palette.error.main
  },
  btnStyle: {
    background: `${theme.palette.buttonColor.main} !important`
  }
});

class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formCardOpacity: "",
      showNotification: false,
      notificationType: "",
      notificationMessage: "",
      showPermissionProfile: false,
      firstNameError: false,
      phoneError: false,
      lastNameError: false,
      ...this.props.userData
    };
  }

  getProfileDetails = () => {
    return SDCloudBackendAPIWithToken()
      .get(Api.getUserProfile)
      .then(res => {
        this.setState({
          firstname: res.data.firstname,
          lastname: res.data.lastname,
          username: res.data.username,
          email: res.data.email,
          phone: res.data.phone,
          company: res.data.company,
          invitation_code: res.data.invitation_code
        });
      })
      .catch(err => {
        const payload = {
          message: err?.response?.data?.message || "unknown error occured",
          color: "danger",
          error: err,
          showErrorPage: true
        };
      });
  };

  handleSubmitForm = () => {
    //console.log(this.state);
    if (!String(this.state.firstname).trim()) {
      this.setState({ firstNameError: true });
      return null;
    } else {
      this.setState({ firstNameError: false });
    }

    if (this.state.phone.length != 10) {
      this.setState({ phoneError: true });
      return null;
    } else {
      this.setState({ phoneError: false });
    }
    if (!String(this.state.lastname).trim()) {
      this.setState({ lastNameError: true });
      return null;
    } else {
      this.setState({ lastNameError: false });
    }

    const formFieldskeys = Object.keys(USER_FORM_FIELDS);

    // filtering only required formfields from state and building formObj
    const formObj = Object.keys(this.state).reduce((accum, val) => {
      if (formFieldskeys.includes(val)) {
        accum[val] = this.state[val];
        return accum;
      }
      return accum;
    }, {});
    this.setState({ formCardOpacity: 0.3 });
    SDCloudBackendAPIWithToken()
      .put(Api.updateUserProfile, {
        ...formObj,
        username: localStorage.getItem("userName")
      })
      .then(res => {
        const payload = {
          message: res.data
            ? res?.data?.message
            : "User information updated successfully",
          color: "success"
        };
        this.setState({ formCardOpacity: 1 });
        this.setState({
          showNotification: true,
          notificationType: "success",
          notificationMessage: payload.message
        });
      })
      .catch(err => {
        const payload = {
          message: err?.response?.data?.message || "unknown error occured",
          color: "danger",
          error: err
        };
        this.setState({ formCardOpacity: 1 });
        this.setState({
          showNotification: true,
          notificationType: "error",
          notificationMessage: payload.message
        });
      });
  };

  //handle state updates passed from custom child components to create node parent
  handleFormFieldChange = stateUpdateObj => {
    const {
      target: { name, value }
    } = stateUpdateObj;
    // Update the key in the state with the new value
    this.setState({ [name]: value });
  };

  hanldeViewProfile = () => {
    this.setState({ showPermissionProfile: true });
  };

  setCloseDrawer = () => {
    this.setState({ showPermissionProfile: false });
  };

  componentDidMount = () => {
    this.getProfileDetails();
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer className={classes.containerStyles}>
          <Card className={classes.cardStyles} elevation={0}>
            <CardHeader className={classes.cardHeaderStyles}>
              <div>
                <h3 style={{ fontSize: "24px" }}>
                  <b>My information</b>
                </h3>
              </div>
            </CardHeader>

            <CardBody
              className={classes.cardBodyStyles}
              style={{ opacity: this.state.formCardOpacity }}
            >
              <GridContainer>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    className={classes.formInputStyles}
                    variant="filled"
                  >
                    <InputLabel
                      className={classes.inputLabelStyles}
                      shrink={false}
                    >
                      {USER_FORM_LABELS.first_name}
                    </InputLabel>
                    <FilledInput
                      onChange={this.handleFormFieldChange}
                      name="firstname"
                      classes={{
                        input: classes.inputStyles,
                        root: classes.inputUnderlineStyles
                      }}
                      value={this.state.firstname}
                    />
                    {this.state.firstNameError && (
                      <FormHelperText className={classes.errorText}>
                        This field is required!
                      </FormHelperText>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    className={classes.formInputStyles}
                    variant="filled"
                  >
                    <InputLabel
                      className={classes.inputLabelStyles}
                      shrink={false}
                    >
                      {USER_FORM_LABELS.last_name}
                    </InputLabel>
                    <FilledInput
                      onChange={this.handleFormFieldChange}
                      name="lastname"
                      classes={{
                        input: classes.inputStyles,
                        root: classes.inputUnderlineStyles
                      }}
                      value={this.state.lastname}
                    />
                    {this.state.lastNameError && (
                      <FormHelperText className={classes.errorText}>
                        This field is required!
                      </FormHelperText>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    className={classes.formInputStyles}
                    variant="filled"
                    disabled={true}
                  >
                    <InputLabel
                      className={classes.inputLabelStyles}
                      shrink={false}
                    >
                      {USER_FORM_LABELS.user_name}
                    </InputLabel>
                    <FilledInput
                      onChange={this.handleFormFieldChange}
                      type="text"
                      name=""
                      classes={{
                        input: classes.inputStyles,
                        root: classes.inputUnderlineStyles
                      }}
                      value={this.state.username}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    className={classes.formInputStyles}
                    variant="filled"
                    disabled={true}
                  >
                    <InputLabel
                      className={classes.inputLabelStyles}
                      shrink={false}
                    >
                      {USER_FORM_LABELS.email}
                    </InputLabel>
                    <FilledInput
                      onChange={this.handleFormFieldChange}
                      type="email"
                      name="email"
                      classes={{
                        input: classes.inputStyles,
                        root: classes.inputUnderlineStyles
                      }}
                      value={this.state.email}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    className={classes.formInputStyles}
                    variant="filled"
                  >
                    <InputLabel
                      className={classes.inputLabelStyles}
                      shrink={false}
                    >
                      {USER_FORM_LABELS.phone}
                    </InputLabel>
                    <FilledInput
                      onChange={this.handleFormFieldChange}
                      type="number"
                      name="phone"
                      classes={{
                        input: classes.inputStyles,
                        root: classes.inputUnderlineStyles
                      }}
                      value={this.state.phone}
                    />
                    {this.state.phoneError && (
                        <FormHelperText className={classes.errorText}>
                          Phone number must be 10 digits long.
                        </FormHelperText>
                    )}
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    className={classes.formInputStyles}
                    variant="filled"
                  >
                    <InputLabel
                      className={classes.inputLabelStyles}
                      shrink={false}
                    >
                      {USER_FORM_LABELS.organization}
                    </InputLabel>
                    <FilledInput
                      onChange={this.handleFormFieldChange}
                      name="company"
                      classes={{
                        input: classes.inputStyles,
                        root: classes.inputUnderlineStyles
                      }}
                      value={this.state.company}
                    />
                  </FormControl>
                </GridItem>
                {/* <GridItem xs={12} sm={6} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    className={classes.formInputStyles}
                    variant="filled"
                  >
                    <InputLabel
                      className={classes.inputLabelStyles}
                      shrink={false}
                    >
                      {USER_FORM_LABELS.title}
                    </InputLabel>
                    <FilledInput
                      onChange={this.handleFormFieldChange}
                      name="title"
                      classes={{
                        input: classes.inputStyles,
                        root: classes.inputUnderlineStyles
                      }}
                    />
                  </FormControl>
                </GridItem> */}
                <GridItem xs={12} sm={6} md={6} lg={6}>
                  <FormControl
                    fullWidth
                    className={classes.formInputStyles}
                    variant="filled"
                    disabled={true}
                  >
                    <InputLabel
                      className={classes.inputLabelStyles}
                      shrink={false}
                    >
                      {USER_FORM_LABELS.invite_code}
                    </InputLabel>
                    <FilledInput
                      onChange={this.handleFormFieldChange}
                      name="invitation_code"
                      classes={{
                        input: classes.inputStyles,
                        root: classes.inputUnderlineStyles
                      }}
                      value={this.state.invitation_code}
                    />
                  </FormControl>
                </GridItem>
                {this.state.profile_name ? (
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <FormControl
                      fullWidth
                      className={classes.formInputStyles}
                      variant="filled"
                      disabled={true}
                    >
                      <InputLabel
                        className={classes.inputLabelStyles}
                        shrink={false}
                      >
                        Permission Profile
                      </InputLabel>
                      <GridContainer>
                        <GridItem
                          xs={12}
                          sm={6}
                          md={6}
                          lg={6}
                          className={classes.viewPermissionProfile}
                        >
                          {this.state.profile_name}{" "}
                          <IconButton onClick={this.hanldeViewProfile}>
                            <VisibilityIcon />
                          </IconButton>
                        </GridItem>
                      </GridContainer>
                    </FormControl>
                  </GridItem>
                ) : null}
              </GridContainer>
            </CardBody>

            <CardFooter className={classes.cardFooterStyles}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={10} lg={6}>
                  {/* <Button
                  onClick={() => this.handleSubmitForm()}
                  // simple
                  // classes={{ root: "button-pseudo" }}
                  // className={classes.buttonStyles}
                  size="large"
                  variant="contained"
                > */}
                  <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    size="large"
                    fullWidth
                    disableFocusRipple
                    type="submit"
                    className={classes.btnStyle}
                    onClick={() => this.handleSubmitForm()}
                  >
                    Update information
                  </Button>
                </GridItem>
                <GridItem xs={12} md={12}>
                  {this.state.showNotification ? (
                    <Alert
                      onClose={() => this.setState({ showNotification: false })}
                      severity={this.state.notificationType}
                    >
                      {this.state.notificationMessage}
                    </Alert>
                  ) : null}
                </GridItem>
              </GridContainer>
            </CardFooter>
          </Card>
        </GridContainer>
        {this.state.showPermissionProfile ? (
          <ViewPermissionProfile
            profileName={this.state.profile_name}
            openDrawer={this.state.showPermissionProfile}
            setCloseDrawer={this.setCloseDrawer}
          />
        ) : null}
      </div>
    );
  }
}

UserInfo.propTypes = {
  classes: PropTypes.object.isRequired,
  notes: PropTypes.string.isRequired,
  userData: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  return {
    profile: state.profileObj
  };
};

export default connect(mapStateToProps)(
  withRouter(withStyles(customStyles)(UserInfo))
);
