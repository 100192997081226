import React, { useEffect, useState } from "react";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
//react 
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
// Formik
import { useFormik } from "formik";
// Material UI Components
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {hotjar} from "react-hotjar";
import GridItem from "components/Grid/GridItem";

import MarkdownIt from 'markdown-it';
//markdown editor import style manually
import 'react-markdown-editor-lite/lib/index.css';
//API Call
import { CMSAPIWithToken } from "../../../apis/CMS";
import queryString from "query-string";

const useStyles = makeStyles((theme) => ({
  
  gridContainer: {
    padding: "0 40px 30px 40px",
  },
  radioButton: {
    "& .MuiTypography-root": {
      marginLeft: "10px",
    },
  },
  formControl: {
    width: "100%",
    marginTop: "5px",
  },
  Select: {
    marginBottom: "50px",
  },
  warningNote: {
    "& .MuiAlert-icon": {
      display: "none",
    },
  },
  button: {
    marginTop: "50px",
  },
  buttOutlined: {
    color: theme.palette.buttonColor.main,
    borderColor: theme.palette.buttonColor.main,
    "&:hover": {
      border: `1px solid ${theme.palette.buttonColor.main}`,
    },
  },
}));

//sandbox view main
const LearningCategoryUpdate = (props) => {
  const classes = useStyles();
  hotjar.initialize(2281731, 6);
  hotjar.identify(localStorage.userName, { userProperty: localStorage.userName});
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [btnDisabled, setBtnDisabled] = useState(false);
  let queryParams = queryString.parse(window.location.search);
  var lern_id = queryParams.id;
  var learning_id_title_map = JSON.parse(localStorage.getItem("lern_id_title_map"))
  var learning_title = learning_id_title_map[lern_id]
  const dispatch = useDispatch();
    const history = useHistory();
    const inputProps = {
        disableAnimation: true,
        shrink: false
      };  

      const ITEM_HEIGHT = 48;
      const ITEM_PADDING_TOP = 8;
      const MenuProps = {
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
          },
        },
      };
      
  
  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {
        lab_name: "",
        lab_identifier: "",
        lab_icon: "",
        admin_learnings_id: lern_id,
        category_position: null
    },
    onSubmit: (values) => {
      let payload = {
        lab_name: values.lab_name,
        lab_identifier: values.lab_identifier,
        lab_icon:values.lab_icon,
        admin_learnings_id: lern_id,
        category_position:values.category_position,
      };

        CMSAPIWithToken() 
        .patch("/learnings/admin_learnings_course_labs_categories"+`/${props.user}/`,payload) 
        .then((resp) => resp.data.results)
        .then((data) => {
            const payload = {
                message: "Learning Category updated Successfully",
                color: "success",
            };
            payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
            history.push("ProductContentLearningsMenuItems?id="+lern_id+"&title="+learning_title);
        })
        .catch((err) => {
          let message = err.response.data.message;
          const payload = {
            message: message,
            color: "danger",
        };
        payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
    },
    validate: (values) => {
      let errors = {};
      if (!values.lab_name) {
        errors.lab_name = "Required";
      }
      if (!values.lab_identifier) {
        errors.lab_identifier = "Required";
      }
      if (!values.lab_icon) {
        errors.lab_icon = "Required";
      }
      if (!values.category_position) {
        errors.category_position = "Valid intiger Required";
      }
      return errors;
    },
  });

 //mark down editor
function handleEditorChange({ html, text }) {
    //console.log('handleEditorChange', html, text);
  }
  // Initialize a markdown parser
  const mdParser = new MarkdownIt(/* Markdown-it options */);

  let data = [];

  const renderSanboxView = async () => {
    let sanboxView = CMSAPIWithToken()
      .get("/learnings/admin_learnings_course_labs_categories/")
      .then((resp) => resp.data.results)
      .then((resp) => {
        resp.forEach((item) => {
          if(props.user == item.id) { 
          formik.setFieldValue("lab_name", item?.lab_name);
          formik.setFieldValue("lab_identifier", item?.lab_identifier);
          formik.setFieldValue("lab_icon", item?.lab_icon);
          formik.setFieldValue("admin_learnings_id", item?.admin_learnings_id);
          formik.setFieldValue("category_position", item?.category_position);
          formik.setFieldValue("created", item?.created);
          formik.setFieldValue("modified", item?.modified);
        }
        });
      })      
      .catch((err) => {
        let message = err.response.data.message;
        // setRows([]);
        // setIsLoading(false);
        setShowNotification(true);
        setNotificationType("error");
        setNotificationMessage("Failed to retrieve the data");
      });

      

      //setUserDetails({ ...formik.userDetails, ...data });
  };

  useEffect(() => {
    renderSanboxView();
  }, []);

  //checkbox
  
    const [state, setState] = React.useState({
      checkedA: true,
      checkedB: true,
      checkedF: true,
      checkedG: true,
    });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div>
        <form
            onSubmit={formik.handleSubmit}
            className={classes.formContainer}
          >
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.gridContainer}
      >
        <Grid item xs={12} md={5}>
          <Typography variant="h4">Learning category Update</Typography>
        </Grid>
        <GridItem md={12}>
                {formik.errors.admin_learnings_id && formik.touched.admin_learnings_id ? (
                  <TextField
                    type="text"
                    variant="filled"
                    label="Admin Learnings Name"
                    name="admin_learnings_name"
                    fullWidth
                    InputLabelProps={inputProps}
                    value={learning_title}
                    onChange={formik.handleChange}
                    helperText={formik.errors.admin_learnings_id}
                    onBlur={formik.handleBlur}
                    error
                    disabled={"disabled"}
                  />
                ) : (
                  <TextField
                    type="text"
                    variant="filled"
                    label="Admin Learnings Name"
                    name="admin_learnings_name"
                    fullWidth
                    InputLabelProps={inputProps}
                    value={learning_title}
                    onChange={formik.handleChange}
                    disabled={"disabled"}
                  />
                )}
        </GridItem>
        <GridItem md={12}>
                
                <TextField
                      type="text"
                      variant="filled"
                      label="Lab Name"
                      name="lab_name"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.lab_name}
                      onChange={formik.handleChange}
                    />
                </GridItem>

                <GridItem md={12}>
                  {formik.errors.lab_identifier && formik.touched.lab_identifier ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Lab Identifier"
                      name="lab_identifier"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.lab_identifier}
                      onChange={formik.handleChange}
                      helperText={formik.errors.lab_identifier}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Lab Identifier"
                      name="lab_identifier"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.lab_identifier}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>

                <GridItem md={12}>
                  {formik.errors.lab_icon && formik.touched.lab_icon ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Lab Icon"
                      name="lab_icon"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.lab_icon}
                      onChange={formik.handleChange}
                      helperText={formik.errors.lab_icon}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Lab Icon"
                      name="lab_icon"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.lab_icon}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>

                <GridItem md={12}>
                  {formik.errors.category_position && formik.touched.category_position ? (
                    <TextField
                      type="number"
                      variant="filled"
                      label="Category Position"
                      name="category_position"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.category_position}
                      onChange={formik.handleChange}
                      helperText={formik.errors.category_position}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="number"
                      variant="filled"
                      label="Category Position"
                      name="category_position"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.category_position}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>

                <GridItem md={9}>
                    <h6>Created Date : {formik.values.created}</h6>
                    <h6>Modified Date : {formik.values.modified}</h6>
                
                </GridItem>

        <GridItem xs={12} sm={6} md={3} lg={3}>
                 <Button
                    variant="contained"
                    color="secondary"
                    disableElevation
                    size="large"
                    className={classes.buttStyle}
                    fullWidth
                    disableFocusRipple
                    type="submit"
                  >
                    Update
                  </Button>
              </GridItem>

      </Grid>
      </form>       
    </div>
  );
};

export default LearningCategoryUpdate;
