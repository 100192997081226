import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";

// Local
import Menu from "../../Components/Menu";
import { SDCloudBackendAPIWithToken } from "../../../apis/backendAPI";
import { CMSAPIWithToken } from "../../../apis/CMS";
import { useDispatch } from "react-redux";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import LearningView from "./LearningView";
import LearningUpdate from "./LearningUpdate";
import ContentCopyModal from "./ContentCopyModal";

// Material UI Components
import { Alert } from "@material-ui/lab";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// Icons
import Visibility from "@material-ui/icons/Visibility";
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// Local
import CustomTable from "../Components/Table";
import RightDrawer from "../Components/RightDrawer";
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import { IconButton } from "@material-ui/core";
import ForwardIcon from '@material-ui/icons/Forward';
import {hotjar} from "react-hotjar";


// Styling
const useStyles = makeStyles(theme => ({
    // root: {
    //     marginTop: "50px"
    // }
    backButton: {
      margin: "15px 0",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "& svg": {
        marginRight: "5px",
      },
      "&:hover": {
        color: theme.palette.secondary.main,
        "& svg": {
          fill: theme.palette.secondary.main,
        },
      },
    },
}));

const ProductContentLearning = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const profileData = JSON.parse(localStorage.getItem("profileData"));
    const account = profileData.account;
    let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
    let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
    let hasDomain = domain?.some((res) => userEmail.includes(res));
    const [isInternal, setIsInternal] = useState(hasDomain);
    useEffect(() => {
        dispatch(SET_NAVBAR_TITLE(isInternal?account+"  - Content Management - Learning Centre":"Content Management - Learning Centre"));
        renderrow();
        renderrowsandbox() 
    }, []);
    hotjar.initialize(2281731, 6);
    hotjar.identify(localStorage.userName, { userProperty: localStorage.userName});
    // States
    const [learningIdentifiers, setLearningIdentifiers] = useState([]);
    const [sandboxIdentities, setSandboxIdentities] = useState([]); 
    const [learningsRows, setLearningsRows] = useState([]);
    const [learningOverviewRows, setlearningOverviewRows] = useState([]);
    const [learningModulesRows, setLearningModulesRows] = useState([]);
    const [courseOverviewRows, setCourseOverviewRows] = useState([]);
    const [courseELearningRows, setCourseELearningRows] = useState([]);
    const [learningLabRows, setLearningLabRows] = useState([]);
    const [learningCategoryRows, setLearningCategoryRows] = useState([]);
    const [learningExercisesRows, setLearningExercisesRows] = useState([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [currentInventory, setCurrentInventory] = useState("");
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState([]);
    const [currentScreen, setCurrentScreen] = useState("Learnings");
    const [currentDrawerScreen, setcurrentDrawerScreen] = useState();
    const [currentUser, setCurrentUser] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    var learning_id_title_map = {};
    const [notificationType, setNotificationType] = useState("");
    const columns = [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Short Title",
        accessor: "short_title",
      },
      {
        Header: "Identifier",
        accessor: "identifier",
      },
      {
        Header: "Created",
        accessor: "created",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ];

    let learningData = [];
    let identifiers = [];
    let identitys=[]
    //var menuItems = ["Learnings", "Learning Overview", "Learning Modules", "Course Overview", "Course ELearning", "Learning Lab","Learning Category","Learning Exercises"];
    const backButton = () => {
      history.push("/product-admin");
    };
    const [isLoading, setIsLoading] = useState(true);
    const renderNotififications = () => {
        let notifications = notificationMessage.map((item) => {
          let notificationType = item.success ? "success" : "error";
          return (
            <Alert
              onClose={() => setShowNotification(false)}
              severity={notificationType}
              spacing={1}
            >
              {item.message}
            </Alert>
          );
        });
    
        return notifications;
      };

//Learning  API call --- for data featch
      const renderrow = async (value) => {
        return CMSAPIWithToken()
        .get("/learnings/admin_learnings/")
        .then((resp) => resp.data.results)
          .then((resp) => {
            resp.forEach((item) => {
              learning_id_title_map[item.id] = item.short_title;
              learningData.push({
                  id: item.id,
                  short_title: item.short_title,
                  identifier: item.identifier,
                  created: item.created,
                actions: (
                  <Grid container wrap="wrap" spacing={1}>
                    <IconButton onClick={() => handleViewButton(item.id)}>
                      <Visibility />
                    </IconButton>
                    <IconButton onClick={() => handleEditButton(item.id)}>
                      <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleDeleteButton(item.id)}>
                      <DeleteForeverIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleForwardButton(item.id,item.short_title)}>
                      <ForwardIcon/>
                    </IconButton>
                  </Grid>
                ),
                });
                identifiers.push(item.identifier);
            });
            localStorage.removeItem("lern_id_title_map");
            localStorage.setItem("lern_id_title_map", JSON.stringify(learning_id_title_map));
            setLearningsRows(learningData);
            setLearningIdentifiers(identifiers);
            setIsLoading(false);
          })
          .catch((err) => {
            let message = err.response.data.message || "unknown error occured";
            setIsLoading(false);
          });
      };



      const renderrowsandbox = async (value) => {
        return CMSAPIWithToken()
        .get("/poc/admin_sandboxes/")
        .then((resp) => resp.data.results)
          .then((resp) => {
            resp.forEach((item) => {
              // sandbox_id_title_map[item.id] = item.title;
              identitys.push(item.identity)
              // //console.log(item.identity,"itmmm")
            });
            //console.log(identitys,"identitys")
            setSandboxIdentities(identitys);
            setIsLoading(false);
          })
          .catch((err) => {
            let message = err.response.data.message || "unknown error occured";
            setIsLoading(false);
          });
      };
      

//Learnings Delete Items 
      const handleDeleteButton = (id) => {
        let user_confirm = window.confirm("Do you want to delete excercise?")
        if(user_confirm){
        CMSAPIWithToken()
        .delete("/learnings/admin_learnings"+`/${id}/`)
        .then((resp) => resp.data.results)
        .then((data) => {
          const payload = {
              message: "Learnings Deleted Successfully",
              color: "success",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
          history.push("/product-admin-learnings");
          window.location.reload()
      })
      .catch((err) => {
        let message = err.response.data.message;
        const payload = {
          message: message,
          color: "danger",
      };
      payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
      });
      };
    }
      // view single product details
      const renderDrawerComponent = () => {
        switch (currentDrawerScreen) {
          case "Learnings View":
            return <LearningView user={currentUser} />;
          case "Learnings Edit":
            return <LearningUpdate user={currentUser} />;                      
          case "default":
            return null;
        }
      };
//code for view button
      const handleViewButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Learnings View");
        setCurrentUser(username);
      };
     
//code for edit button      
      const handleEditButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Learnings Edit");
        setCurrentUser(username);
      };
      
      const handleForwardButton = (sb_id,short_title) => {
        history.push("ProductContentLearnings/ProductContentLearningsMenuItems?id="+sb_id+"&title="+short_title);
      };

      //Add new sandbox , Option , Details ,Cheat Sheets, Labs ,Categories,Exercises
      const handleAddUserLearnings = () => {
        history.push("ProductContentLearnings/LearningCreate");  
      };

      // New function for handling Content Copy button click
      const handleContentCopy = () => {
        setIsModalOpen(true);
        //console.log("trueee")
      };
  
      const handleCloseModal = () => {
        setIsModalOpen(false);
      };
      
    const renderContent = () => {
        switch (currentScreen) {
            case "Learnings":
              return (
                <Grid container className={classes.root} spacing={1}>
                  <Grid item xs={12} md={12}>
                    {showNotification ? renderNotififications() : null}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LoaderHoc isLoading={isLoading}>
                      <CustomTable
                        columns={columns}
                        data={learningsRows}
                        handleDeleteButtonClick={handleDeleteButton}
                        handleButtonClick={handleAddUserLearnings}
                        handleContentCopyClick={handleContentCopy}
                        handleViewButtonClick={handleViewButton}
                        tableTitle="Learnings"
                        buttonTitle="Add New Learnings"
                        copycontent="Content Copy" // **New prop added**
                        hiddenColumns={[]}
                        contentType="learning"
                      />
                      
                    </LoaderHoc>
                  </Grid>
                </Grid>
              );
        }
    };

    return (
        <div>
            <Grid container className={classes.root}>
              <GridContainer className="backbutton">
                  <GridItem sm={12} md={12}>
                    <Typography
                      variant="body1"
                      className={classes.backButton}
                      onClick={backButton}
                    >
                      <ArrowBackIcon /> Back
                    </Typography>
                  </GridItem>
                </GridContainer>
                <Grid item md={12}>
                    {renderContent()}
                </Grid>
                 {/* Drawer */}
                 <RightDrawer
                      open={openDrawer}
                      onClose={setOpenDrawer}
                      title={currentInventory}
                    >
                      {renderDrawerComponent()}
                    </RightDrawer>
                    <ContentCopyModal open={isModalOpen} handleClose={handleCloseModal} 
                     learningIdentifiers={learningIdentifiers}
                     sandboxIdentities= {sandboxIdentities}
                    />
            </Grid>
        </div>
    );

};

export default ProductContentLearning;