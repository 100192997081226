import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { v4 as uuidv4 } from "uuid";

// Material UI Components
import { Alert } from "@material-ui/lab";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";

// Icons
import Visibility from "@material-ui/icons/Visibility";

// Local
import CustomTable from "./Table";
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import { SDCloudBackendAPIWithToken } from "../../../apis/backendAPI";
import { IconButton } from "@material-ui/core";
import RightDrawer from "./RightDrawer";
import AddUser from "./AddUser";
import UserDetails from "./UserDetails";
import { Add } from "@material-ui/icons";

// Styling
const useStyles = makeStyles((theme) => ({
  root: {
    // padding: "30px 20px",
  },
}));

const SignUpRequests = () => {
  const classes = useStyles();
  // States
  const [rows, setRows] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [currentScreen, setCurrentScreen] = useState();
  const [currentUser, setCurrentUser] = useState("");
  const [signUpStatus, setSignUpStatus] = useState("Open");
  const [profileList, setProfileList] = useState({});
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // useEffects
  useEffect(() => {
    renderData();
  }, []);

  useEffect(() => {
    renderData();
  }, [signUpStatus]);

  const columns = [
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Invite Code",
      accessor: "invite_code",
    },
    {
      Header: "Permission Profile",
      accessor: "profile",
      width: 350,
    },
    {
      Header: "Status",
      accessor: "status",
    },
  ];

  const filterDropDownValues = ["Open", "Accepted", "Rejected", "All"];

  let data = [];

  const renderData = async () => {
    let profiles = await renderProfileDropdown();

    let response = SDCloudBackendAPIWithToken()
      .get("/get_pending_user_requests")
      .then((resp) => {
        return resp.data.pending_user_requests;
      })
      .then((resp) => {
        resp.forEach(async (item) => {
          if (item.status === signUpStatus || signUpStatus === "All") {
            data.push({
              id: item.id,
              email: item.email,
              name: `${item.first_name} ${item.last_name}`,
              invite_code: item.invitation_code,
              profile: (
                <FormControl
                  variant="filled"
                  style={{ marginTop: "0", marginRight: "5px" }}
                >
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    fullWidth
                    name="permission_profile"
                    // defaultValue={profiles[0].profile_name}
                    style={{ minWidth: "250px" }}
                    native
                    inputProps={{ id: `profile_dropdown_${item.id}` }}
                  >
                    {profiles}
                  </Select>
                </FormControl>
              ),
              status: (
                <FormControl
                  variant="filled"
                  style={{ marginTop: "0", marginRight: "5px" }}
                >
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id={`demo-simple-select-filled_${item.id}`}
                    fullWidth
                    name={`status_${item.id}`}
                    style={{ minWidth: "200px" }}
                    native={true}
                    inputProps={{ id: `status_dropdown_${item.id}` }}
                  >
                    <option value="" selected>
                      None
                    </option>
                    <option value="accept">Accept</option>
                    <option value="reject">Reject</option>
                  </Select>
                </FormControl>
              ),
            });
          }
        });

        setRows(data);
        setIsLoading(false);
      });
  };

  const renderProfileDropdown = async () => {
  //   // const profileItems = SDCloudBackendAPIWithToken.get("/get_profile")
  //   //   .then((resp) => resp.data)
  //   //   .then((data) => {
  //   //     let profiles = data.profiles;

  //   //     let items = profiles.map((item) => {
  //   //       return (
  //   //         <option key={uuidv4()} value={item.profile_name}>
  //   //           {item.profile_name}
  //   //         </option>
  //   //       );
  //   //     });

  //   //     return (
  //   //       <FormControl variant="filled" style={{ marginTop: "0" }}>
  //   //         <Select
  //   //           labelId="demo-simple-select-filled-label"
  //   //           id="demo-simple-select-filled"
  //   //           fullWidth
  //   //           name="permission_profile"
  //   //           defaultValue={profiles[0].profile_name}
  //   //           style={{ minWidth: "250px" }}
  //   //           native
  //   //           inputProps={{ id: `profile_dropdown_${1}` }}
  //   //         >
  //   //           {items}
  //   //         </Select>
  //   //       </FormControl>
  //   //     );
  //   //   });

    let profileItems = await SDCloudBackendAPIWithToken().get("/get_profile");

    profileItems = profileItems.data.profiles.map((item) => {
      return (
        <option key={uuidv4()} value={item.profile_name}>
          {item.profile_name}
        </option>
      );
    });

    return profileItems;
  };

  const renderDrawerComponent = () => {
    switch (currentScreen) {
      case "Add User":
        return <AddUser />;
      case "User Details":
        return <UserDetails user={currentUser} />;
      case "default":
        return null;
    }
  };

  const handleSubmitButton = (selectedFlatRows) => {
    let payload = {
      update_pending_requests: [],
    };

    let responses = [];

    selectedFlatRows.forEach((item) => {
      let id = item.original.id;
      let action = document.getElementById(
        `status_dropdown_${item.original.id}`
      ).value;
      let profile = document.getElementById(
        `profile_dropdown_${item.original.id}`
      ).value;

      payload.update_pending_requests.push({
        id: id,
        action: action,
        reference_profile_name: profile,
        base_url: `https://${window.location.host}/auth/`,
      });
    });
    
    let response = SDCloudBackendAPIWithToken()
      .post("/update_pending_user_request", payload)
      .then((resp) => resp.data)
      .then((data) => {
        responses = [...data.response];
        setShowNotification(true);
        setNotificationMessage(responses);
        renderData();
      });
  };

  const handleDeleteButton = (selectedRowIds, selectedFlatRows) => {
    let payload = {};
    selectedFlatRows.forEach((item) => {
      let id = item.original.id;

      SDCloudBackendAPIWithToken()
        .post(`/delete_pending_user_request/${id}`)
        .then((resp) => resp.data)
        .then((data) => {
          //console.log(data);
        });
    });
  };

  const handleViewButton = (username) => {
    setOpenDrawer(true);
    setCurrentScreen("User Details");
    setCurrentUser(username);
  };

  const renderNotififications = () => {
    let notifications = notificationMessage.map((item) => {
      let notificationType = item.success ? "success" : "error";
      return (
        <Alert
          onClose={() => setShowNotification(false)}
          severity={notificationType}
          spacing={1}
        >
          {item.message}
        </Alert>
      );
    });

    return notifications;
  };

  return (
    <div>
      <Grid container className={classes.root} spacing={1}>
        <Grid item xs={12} md={12}>
          {showNotification ? renderNotififications() : null}
        </Grid>
        <Grid item xs={12} md={12}>
          <LoaderHoc isLoading={isLoading}>
            <CustomTable
              columns={columns}
              data={rows}
              handleDeleteButtonClick={handleDeleteButton}
              handleButtonClick={handleSubmitButton}
              handleViewButtonClick={handleViewButton}
              tableTitle="Sign Up Requests"
              buttonTitle="Submit Request"
              dropDownValues={filterDropDownValues}
              handleDropDownChange={setSignUpStatus}
              hiddenColumns={[]}
              showDropdown={true}
            />
          </LoaderHoc>
        </Grid>
      </Grid>

      {/* Drawer */}
      <RightDrawer
        open={openDrawer}
        onClose={setOpenDrawer}
        title={currentScreen}
      >
        {renderDrawerComponent()}
      </RightDrawer>
    </div>
  );
};

export default SignUpRequests;
