export const PRIMARY_COLOR = "#F96B13"; // orange
export const SECONDARY_COLOR = "#0F1A2B"; // dark_blue
export const RED = "#E3241B";
export const YELLOW = "#FBAB18";
export const SKY_BLUE = "#00BCEB";
export const COMPANY_LOGO_RED = "#EF5349";
export const GREEN_CHECK_COLOR = "#58d85e";
export const LOADER_TYPE = "ThreeDots";
export const UPLOAD_IMG =
  "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
  " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
  "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")";

export const SECURITY_QUESTIONS = [
  "What primary school did you attend?",
  "In what town or city was your first full time job?",
  "What is your mother's maiden name?",
  "What was your childhood nickname?",
  "What was your dream job as a child?",
  "What is your pet's name?",
  "What is the name of the company of your first job?",
  "What is the name of your favorite childhood friend?"
];
