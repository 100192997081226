import React, { useEffect, useState } from "react";
// Formik
import { useFormik } from "formik";
// Material UI Components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {hotjar} from "react-hotjar";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
//checkbox
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
//markdown editor
import MdEditor from 'react-markdown-editor-lite';
import MarkdownIt from 'markdown-it';
//markdown editor import style manually
import 'react-markdown-editor-lite/lib/index.css';
//API Call
import { CMSAPIWithToken } from "../../../apis/CMS";


const useStyles = makeStyles((theme) => ({
  
  gridContainer: {
    padding: "0 40px 30px 40px",
  },
  radioButton: {
    "& .MuiTypography-root": {
      marginLeft: "10px",
    },
  },
  formControl: {
    width: "100%",
    marginTop: "5px",
  },
  Select: {
    marginBottom: "50px",
  },
  warningNote: {
    "& .MuiAlert-icon": {
      display: "none",
    },
  },
  button: {
    marginTop: "50px",
  },
  buttOutlined: {
    color: theme.palette.buttonColor.main,
    borderColor: theme.palette.buttonColor.main,
    "&:hover": {
      border: `1px solid ${theme.palette.buttonColor.main}`,
    },
  },
}));

//mark down editor
function handleEditorChange({ html, text }) {
  //console.log('handleEditorChange', html, text);
}
// Initialize a markdown parser
const mdParser = new MarkdownIt(/* Markdown-it options */);

//sandbox view main
const SandboxOptionsView = (props) => {
  const classes = useStyles();
  hotjar.initialize(2281731, 6);
  hotjar.identify(localStorage.userName, { userProperty: localStorage.userName});
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const formik = useFormik({
    initialValues:{
        admin_sandboxes_id: null,
        label: "",
        value: "",
        identifier: "",
        is_hidden: false
    } 
  });

  let data = [];

  const renderSanboxView = async () => {
    let sanboxView = CMSAPIWithToken()
      .get("/poc/admin_sandboxes_options/")
      .then((resp) => resp.data.results)
      .then((resp) => {
        resp.forEach((item) => {
          if(props.user == item.id) { 
          formik.setFieldValue("admin_sandboxes_id", item?.admin_sandboxes_id);
          formik.setFieldValue("label", item?.label);
          formik.setFieldValue("value", item?.value);
          formik.setFieldValue("identifier", item?.identifier);
          formik.setFieldValue("is_hidden", item?.is_hidden);
        }
        });
      })      
      .catch((err) => {
        let message = err.response.data.message;
        setShowNotification(true);
        setNotificationType("error");
        setNotificationMessage("Failed to retrieve the data");
      });
  };
  
  useEffect(() => {
    renderSanboxView();
  }, []);

  //checkbox
  
    const [state, setState] = React.useState({
      checkedA: true,
      checkedB: true,
      checkedF: true,
      checkedG: true,
    });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.gridContainer}
      >
        <Grid item xs={12} md={5}>
          <Typography variant="h4">Sandbox Options</Typography>
          
        </Grid>

        <Grid item xs={12} md={7}>
        <TextField
          type="text"
          variant="filled"
          label="Sandboxes Id"
          name="admin_sandboxes_id"
          fullWidth
          InputLabelProps=""
          inputProps={{readOnly: true }}
          value={formik.values.admin_sandboxes_id}
          onChange=""
        /> 
        </Grid>

        <Grid item xs={12} md={5}>
        <TextField
          type="text"
          variant="filled"
          label="Label"
          name="label"
          fullWidth
          InputLabelProps=""
          inputProps={{readOnly: true }}
          value={formik.values.label}
          onChange=""
        /> 
        </Grid>

        <Grid item xs={12} md={6}>
        <TextField
          type="text"
          variant="filled"
          label="Identifier"
          name="identifier"
          fullWidth
          InputLabelProps=""
          inputProps={{readOnly: true }}
          value={formik.values.identifier}
          onChange=""
        /> 
        </Grid>
        

        
        </Grid>

        <Grid item xs={12} md={12}>
        <FormGroup row>
      <FormControlLabel
        disabled
        control={<Checkbox checked={formik.values.is_hidden} onChange={handleChange} name="checkedA" />}
        label="is_enabled"
      />
    </FormGroup>
        </Grid>                 
    </div>
  );
};

export default SandboxOptionsView;
