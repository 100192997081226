import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
// Finish!

// This component creates a modal for Reserved IP with respective form fields, the
// modal is popped up during provision or resuming of a lab or poc.

import React, { Component, useState, useEffect } from "react";
import { SECONDARY_COLOR } from "global_constants/app_constants";
import Button from "@material-ui/core/Button";
// Formik
import { useFormik } from "formik";
import MuiPhoneNumber from "material-ui-phone-number";
import Datetime from "react-datetime";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Api, Urls } from "config/api";
import Loader from "react-loader-spinner";
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import { LOADER_TYPE } from "global_constants/app_constants";
import "views/ServiceOffering/LearningLab/acess labs/Overview.scss";
import NotificationAlert from "views/Components/NotificationAlert";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import Typography from "@material-ui/core/Typography";
import theme from "theme";
//API Call
import { CMSAPIWithToken } from "../../../apis/CMS";
//checkbox
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from "@material-ui/core/Switch";
//react 
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
//markdown editor
import Editor from "react-markdown-editor-lite";
import ReactMarkdown from "react-markdown";
import MarkdownIt from 'markdown-it';
//markdown editor import style manually
import 'react-markdown-editor-lite/lib/index.css';


const inputProps = {
  disableAnimation: true,
  shrink: false
};

const customStyles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: 300,
      },
  mainContainer: {
    padding: "40px"
  },
  container: {
    marginTop: "20px"
  },
  formContainer: {
    width: "100%"
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    position: "absolute",
    width: 550,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    display: "block"
  },
  buttStyle: {
    background: theme.palette.buttonColor.main,
    "&:hover": {
      background: theme.palette.buttonColor.main
    }
  },
  firstTitleLabel: {
    padding: "0 15px"
  },
  titleLabel: {
    padding: "0 15px",
    marginTop: "30px"
  },
  errorText: {
    fontSize: "12px",
    color: theme.palette.error.main
  }
});

//mark down editor
function handleEditorChange({ html, text }) {
  //console.log('handleEditorChange', html, text);
}
// Initialize a markdown parser
const mdParser = new MarkdownIt(/* Markdown-it options */);


const LearningCreate = props => {
  const useStyles = makeStyles(customStyles);
  const classes = useStyles();
  const mdEditor = React.useRef(null);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [value, setValue] = React.useState("ipv4");
  const [description, setDescription] = useState("");
  const [accessDetails, setAccessDetails] = useState("");
  const dispatch = useDispatch();
    const history = useHistory();

  useEffect(() => {
    getAccountInfo();
  }, []);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  
  const names = [
    'Criterion Enterprise',
    'criterion networks DEV',
    'TataCommunication',
    'LiveAction',
    'verizonlearning-dev',
    'verizonlearning',
    'LiveActiondev',
    'CiscoWorkshops',
    'TataCommunicationDev',
    'CN',
    'ciscotraining',
  
  ];
  const multipleselectvalue=["1","2","3","4","5","6","7","8","9","10","11"];

  const [account, setAccount] = React.useState([]);

  const handleMultipleChange = (event) => {
    setAccount(event.target.value);
  };

  const formik = useFormik({
    enableReinitialize:true,
    initialValues: {  
            short_title: "",
            full_title: "",
            description: "",
            type: "",
            stage: "",
            category: "",
            icon: "",
            active: false,
            identifier: "",
            show_ip_details: false,
            email_content: "",
            access_details: "",
            lab_run_time: "",
            image: "",
            enable_suspend_resume: false,
            auto_lab_provisioning: false,
            enable_guacamole: false,
            is_scripted_demo: false,
            admin_lab_types_id: 1,
            access_impairment: false,
            access_tcpreplay: false,
            enable_export: false,
            account: [],
              },
    onSubmit: (values) => {
      let payload = {
        short_title: values.short_title,
        full_title: values.full_title,
        description: description,
        type: values.type,
        stage: values.stage,
        category: values.category,
        icon: values.icon,
        active: values.active,
        identifier: values.identifier,
        show_ip_details: values.show_ip_details,
        email_content: values.email_content,
        access_details: accessDetails,
        lab_run_time: values.lab_run_time,
        image: values.image,
        enable_suspend_resume: values.enable_suspend_resume,
        auto_lab_provisioning: values.auto_lab_provisioning,
        enable_guacamole: values.enable_guacamole,
        is_scripted_demo: values.is_scripted_demo,
        admin_lab_types_id: values.admin_lab_types_id,
        access_impairment: values.access_impairment,
        access_tcpreplay: values.access_tcpreplay,
        enable_export: values.enable_export,
        account: [],
      };

        CMSAPIWithToken() 
        .post("/learnings/admin_learnings/",payload) 
        .then((resp) => resp.data.results)
        .then((data) => {
            const payload = {
                message: "Learnings Created Successfully",
                color: "success",
            };
            payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
            history.push("/product-admin-learnings");
            window.location.reload()
        })
        .catch((err) => {
          let message = err.response.data.message;
          const payload = {
            message: message,
            color: "danger",
        };
        payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
    },
    validate: (values) => {
      let errors = {};
      if (!values.short_title) {
        errors.short_title = "Required";
      }
      if (!values.full_title) {
        errors.full_title = "Required";
      }
      if(!values.category){
        errors.category = "Required";
      }
      if(!values.identifier){
        errors.identifier = "Required";
      }
      return errors;
    },
  });

    //mark down editor
    const handleEditorChange = ({ html, text }) => {
      const newValue = text;
      //console.log(newValue);
      setDescription(newValue);
    };
    const handleAccessDetails = ({ html, text }) => {
      const newValue = text;
      //console.log(newValue);
      setAccessDetails(newValue);
    };
      // Initialize a markdown parser
      const mdParser = new MarkdownIt(/* Markdown-it options */);
  

  const getAccountInfo = () => {
    SDCloudBackendAPIWithToken()
      .get(Api.accountInfo)
      .then(res => {
      
      })
      .then(() => {
        setIsLoading(false);
      })
      .catch(err => {
        //console.log(err.response);
        setIsLoading(false);
      });
  };
let Cancelbutton=()=>{
  history.push("/product-admin-learnings");
  window.location.reload()
}
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
    checkedF: true,
    checkedG: true,
  });

const handleChange = (event) => {
  setState({ ...state, [event.target.name]: event.target.checked });
};

  return (
    // Form modal for sandbox create
    <div>
      <LoaderHoc isLoading={isLoading}>
        <Paper elevation={0}>
          <Grid
            container
            className={classes.mainContainer}
            style={{ marginTop: "2%" }}
          >
            <form
              onSubmit={formik.handleSubmit}
              className={classes.formContainer}
            >
              {/* <Typography variant="h4">{props.formTitle}</Typography> */}
              <Typography variant="h4" className={classes.firstTitleLabel}>
              Add New Learnings
              </Typography>
              <Grid container className={classes.container}>
              <GridItem md={12}>
                  {formik.errors.short_title && formik.touched.short_title ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Short Title"
                      name="short_title"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.short_title}
                      onChange={formik.handleChange}
                      helperText={formik.errors.short_title}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Short Title"
                      name="short_title"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.short_title}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>
                <GridItem md={12}>
                  {formik.errors.full_title && formik.touched.full_title ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Full Title"
                      name="full_title"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.full_title}
                      onChange={formik.handleChange}
                      helperText={formik.errors.full_title}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Full Title"
                      name="full_title"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.full_title}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>

                <GridItem md={12}>
                    <p>Description</p>
                        <Editor
                          ref={mdEditor}
                          value={description}
                          style={{
                            height: "500px"
                          }}
                          onChange={handleEditorChange}
                          renderHTML={text => <ReactMarkdown source={text} />}
                        />
                </GridItem>

                <GridItem md={12}>
                <TextField
                      type="text"
                      variant="filled"
                      label="Type"
                      name="type"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.type}
                      onChange={formik.handleChange}
                    />
                </GridItem>

                <GridItem md={12}>
                <TextField
                      type="text"
                      variant="filled"
                      label="Stage"
                      name="stage"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.stage}
                      onChange={formik.handleChange}
                    />
                </GridItem>

                <GridItem md={12}>
                  {formik.errors.category && formik.touched.category ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Category"
                      name="category"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.category}
                      onChange={formik.handleChange}
                      helperText={formik.errors.category}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Category"
                      name="category"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.category}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>

                <GridItem md={12}>
                <TextField
                      type="text"
                      variant="filled"
                      label="Icon"
                      name="icon"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.icon}
                      onChange={formik.handleChange}
                    />
                </GridItem>

                <GridItem md={12}>
                <span>Active</span>
                   <FormControlLabel
                            control={<Checkbox checked={formik.values.active} />} 
                            name="active"
                            onChange={formik.handleChange}
                            />
                </GridItem>

                <GridItem md={12} >
                  {formik.errors.identifier && formik.touched.identifier ? (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Identifier"
                      name="identifier"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.identifier}
                      onChange={formik.handleChange}
                      helperText={formik.errors.identifier}
                      onBlur={formik.handleBlur}
                      error
                    />
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      label="Identifier"
                      name="identifier"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.identifier}
                      onChange={formik.handleChange}
                    />
                  )}
                </GridItem>

                <GridItem md={12}>
                <span>Show Ip Details</span>
                   <FormControlLabel
                            control={<Checkbox checked={formik.values.show_ip_details} />} 
                            name="show_ip_details"
                            onChange={formik.handleChange}
                            />
                </GridItem>

                <GridItem md={12}>
                <TextField
                      type="text"
                      variant="filled"
                      label="Email Content"
                      name="email_content"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.email_content}
                      onChange={formik.handleChange}
                      multiline
                      rows={8}
                    />
                </GridItem>

                <GridItem md={12}>
                     <p>Access Details</p>
                        <Editor
                          ref={mdEditor}
                          value={accessDetails}
                          style={{
                            height: "500px"
                          }}
                          onChange={handleAccessDetails}
                          renderHTML={text => <ReactMarkdown source={text} />}
                        />
                </GridItem>

                <GridItem md={12}>
                <TextField
                      type="text"
                      variant="filled"
                      label="Lab Run Time"
                      name="lab_run_time"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.lab_run_time}
                      onChange={formik.handleChange}
                    />
                </GridItem>

                <GridItem md={12}>
                <TextField
                      type="text"
                      variant="filled"
                      label="Image"
                      name="image"
                      fullWidth
                      InputLabelProps={inputProps}
                      value={formik.values.image}
                      onChange={formik.handleChange}
                    />
                </GridItem>

                <GridItem md={12}>
                <span>Enable Suspend Resume</span>
                   <FormControlLabel
                            control={<Checkbox checked={formik.values.enable_suspend_resume} />} 
                            name="enable_suspend_resume"
                            onChange={formik.handleChange}
                            />
                </GridItem>

                <GridItem md={12}>
                <span>Auto Lab Provisioning</span>
                   <FormControlLabel
                            control={<Checkbox checked={formik.values.auto_lab_provisioning} />} 
                            name="auto_lab_provisioning"
                            onChange={formik.handleChange}
                            />
                </GridItem>

                <GridItem md={12}>
                <span>Enable Guacamole</span>
                   <FormControlLabel
                            control={<Checkbox checked={formik.values.enable_guacamole} />} 
                            name="enable_guacamole"
                            onChange={formik.handleChange}
                            />
                </GridItem>

                <GridItem md={9}>
                <span>Is Scripted Demo</span>
                   <FormControlLabel
                            control={<Checkbox checked={formik.values.is_scripted_demo} />} 
                            name="is_scripted_demo"
                            onChange={formik.handleChange}
                            />
                </GridItem>

                <GridItem md={12}>
                <span>Access Impairment</span>
                   <FormControlLabel
                            control={<Checkbox checked={formik.values.access_impairment} />} 
                            name="access_impairment"
                            onChange={formik.handleChange}
                            />
                </GridItem>

                <GridItem md={12}>
                <span>Access Tcpreplay</span>
                   <FormControlLabel
                            control={<Checkbox checked={formik.values.access_tcpreplay} />} 
                            name="access_tcpreplay"
                            onChange={formik.handleChange}
                            />
                </GridItem>

                <GridItem md={12}>
                <span>Enable Export</span>
                   <FormControlLabel
                            control={<Checkbox checked={formik.values.enable_export} />} 
                            name="enable_export"
                            onChange={formik.handleChange}
                            />
                </GridItem>
              </Grid>
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{ marginTop: "20px", marginBottom: "30px" }}
              >
                <GridItem xs={12} sm={6} md={3} lg={3}>
                  {!btnDisabled ? (
                    <Button
                      variant="contained"
                      color="secondary"
                      disableElevation
                      size="large"
                      className={classes.buttStyle}
                      fullWidth
                      disableFocusRipple
                      type="submit"
                    >
                      Submit
                    </Button>
                  ) : null}
                  {btnDisabled ? (
                    <Loader
                      type={LOADER_TYPE}
                      color={theme.palette.secondary.main}
                      width={50}
                      height={50}
                      style={{ float: "right", marginRight: "15px" }}
                    />
                  ) : null}
                </GridItem>
                <GridItem xs={12} sm={6} md={3} lg={3}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    disableElevation
                    size="large"
                    // className={classes.buttStyle}
                    fullWidth
                    onClick={Cancelbutton}
                    disableFocusRipple
                    type="submit"
                  >
                    Cancel
                  </Button>
                </GridItem>
              </Grid>

              <NotificationAlert
                notificationType={notificationType}
                showNotification={showNotification}
                message={notificationMessage}
              />
            </form>
          </Grid>
        </Paper>
      </LoaderHoc>
    </div>
  );
};

export default LearningCreate;
