import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Material UI Components
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { SHOW_NOTIFICATION } from "action_creators/actioncreator";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Typography } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// Local
import Menu from "../../Components/Menu";
import { SDCloudBackendAPIWithToken } from "../../../apis/backendAPI";
import { CMSAPIWithToken } from "../../../apis/CMS";
import { useDispatch } from "react-redux";
import { SET_NAVBAR_TITLE } from "action_creators/actioncreator";
import LearningView from "./LearningView";
import LearningUpdate from "./LearningUpdate";
import LearningOverviewView from "./LearningOverviewView";
import LearningOverviewUpdate from "./LearningOverviewUpdate";
import LearningModulesView from "./LearningModulesView";
import LearningModulesUpdate from "./LearningModulesUpdate";
import LearningLabView from "./LearningLabView";
import LearningLabUpdate from "./LearningLabUpdate";
import LearningExercisesView from "./LearningExercisesView";
import LearningExercisesUpdate from "./LearningExercisesUpdate";
import LearningCategoryView from "./LearningCategoryView";
import LearningCategoryUpdate from "./LearningCategoryUpdate";
import CourseOverviewView from "./CourseOverviewView";
import CourseOverviewUpdate from "./CourseOverviewUpdate";
import CourseELearningUpdate from "./CourseELearningUpdate";
import CourseELearningView from "./CourseELearningView";
// Material UI Components
import { Alert } from "@material-ui/lab";
// Icons
import Visibility from "@material-ui/icons/Visibility";
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
// Local
import CustomTable from "../Components/Table";
import RightDrawer from "../Components/RightDrawer";
import LoaderHoc from "../../../components/HOC/LoaderHoc";
import { IconButton } from "@material-ui/core";
import ForwardIcon from '@material-ui/icons/Forward';
//import Visibility from "@material-ui/icons/Visibility";
import {hotjar} from "react-hotjar";
import queryString from "query-string";


// Styling
const useStyles = makeStyles(theme => ({
    // root: {
    //     marginTop: "50px"
    // },
    backButton: {
      margin: "15px 0",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      "& svg": {
        marginRight: "5px",
      },
      "&:hover": {
        color: theme.palette.secondary.main,
        "& svg": {
          fill: theme.palette.secondary.main,
        },
      },
    },
}));

const ProductContentLearningMenuItems = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const profileData = JSON.parse(localStorage.getItem("profileData"));
    const account = profileData.account;
    let domain = JSON.parse(localStorage.getItem("internal_domains"))?.domains;
    let userEmail = JSON.parse(localStorage.getItem("profileData"))?.email;
    let hasDomain = domain?.some((res) => userEmail.includes(res));
    const [isInternal, setIsInternal] = useState(hasDomain);
    useEffect(() => {
        dispatch(SET_NAVBAR_TITLE(isInternal?account+"  - Content Management - Learning Centre":"Content Management - Learning Centre"));
        renderlearningoverview();
        renderlearningmodules();
        rendercourseoverview();
        rendercourseelearning();
        renderlearninglab();
        renderlearningcategory();
        renderlearningexercises();
    }, []);
    hotjar.initialize(2281731, 6);
    hotjar.identify(localStorage.userName, { userProperty: localStorage.userName});
    // States
    
    const [learningOverviewRows, setlearningOverviewRows] = useState([]);
    const [learningModulesRows, setLearningModulesRows] = useState([]);
    const [courseOverviewRows, setCourseOverviewRows] = useState([]);
    const [courseELearningRows, setCourseELearningRows] = useState([]);
    const [learningLabRows, setLearningLabRows] = useState([]);
    const [learningCategoryRows, setLearningCategoryRows] = useState([]);
    const [learningExercisesRows, setLearningExercisesRows] = useState([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [currentInventory, setCurrentInventory] = useState("");
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState([]);
    const [currentScreen, setCurrentScreen] = useState("Learning Overview");
    const [currentDrawerScreen, setcurrentDrawerScreen] = useState();
    let queryParams = queryString.parse(window.location.search);
    var sb_id = queryParams.id;
    var learning_id_title_map = JSON.parse(localStorage.getItem("lern_id_title_map"))
    var learning_title = learning_id_title_map[sb_id]
    var learn_cat_id_map = {};
    const [currentUser, setCurrentUser] = useState();
    const [currentCategory, setCurrentCategory] = useState();
    const [notificationType, setNotificationType] = useState("");
    

    const learningOverviewcolumns = [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Pricing",
        accessor: "pricing",
      },
      {
        Header: "Pricing Units",
        accessor: "pricing_units",
      },
      {
        Header: "Created",
        accessor: "created",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ];
    const learningmodulescolumns = [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Learnings Name",
        accessor: "admin_learnings_id",
      },
      {
        Header: "Created",
        accessor: "created",
      },
      {
        Header: "Modified",
        accessor: "modified",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ];
    const courseoverviewcolumns = [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Learnings Name",
        accessor: "admin_learnings_id",
      },
      {
        Header: "Created",
        accessor: "created",
      },
      {
        Header: "Modified",
        accessor: "modified",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ];
    const courseelearningcolumns = [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Learnings Name",
        accessor: "admin_learnings_id",
      },
      {
        Header: "Created",
        accessor: "created",
      },
      {
        Header: "Modified",
        accessor: "modified",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ];
    
    const learninglabcolumns = [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Learnings Name",
        accessor: "admin_learnings_id",
      },
      {
        Header: "Created",
        accessor: "created",
      },
      {
        Header: "Modified",
        accessor: "modified",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ];

    const learningcategorycolumns = [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Lab Name",
        accessor: "lab_name",
      },
      {
        Header: "Lab Identifier",
        accessor: "lab_identifier",
      },
      {
        Header: "Created",
        accessor: "created",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ];

    const learningexercisescolumns = [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Category Name",
        accessor: "admin_learnings_cat_id",
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Created",
        accessor: "created",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ];
    
    let learningOverviewData = [];let learningModulesData = [];let courseOverviewData = [];let courseELearningData = [];let learningLabData = [];let learningCategoryData = [];let learningExercisesData = [];
    var menuItems = ["Learning Overview", "Learning Modules", "Course Overview", "Course ELearning", "Learning Lab","Learning Category"];
    const backButton = () => {
      history.push("/product-admin-learnings");
    };
    const [isLoading, setIsLoading] = useState(true);
    const renderNotififications = () => {
        let notifications = notificationMessage.map((item) => {
          let notificationType = item.success ? "success" : "error";
          return (
            <Alert
              onClose={() => setShowNotification(false)}
              severity={notificationType}
              spacing={1}
            >
              {item.message}
            </Alert>
          );
        });
    
        return notifications;
      };

      //Learning Overview API call --- for data featch
      const renderlearningoverview = async (value) => {
        return CMSAPIWithToken()
        .get("/learnings/admin_learnings_overviews/")
        .then((resp) => resp.data.results)
          .then((resp) => {
            resp.forEach((item) => {
              if(item.admin_learnings_id == sb_id){
              learningOverviewData.push({
                  id: item.id,
                  pricing: item.pricing,
                  pricing_units: item.pricing_units,
                  created: item.created,
                actions: (
                  <Grid container wrap="wrap" spacing={1}>
                    <IconButton onClick={() => handleOverviewViewButton(item.id)}>
                      <Visibility />
                    </IconButton>
                    <IconButton onClick={() => handleOverviewEditButton(item.id)}>
                      <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleOverviewDeleteButton(item.id)}>
                      <DeleteForeverIcon/>
                    </IconButton>
                  </Grid>
                ),
                });
              }
            });
           
            setlearningOverviewRows(learningOverviewData);
            
            setIsLoading(false);
          })
          .catch((err) => {
            let message = err.response.data.message || "unknown error occured";
            setIsLoading(false);
          });
      };


      //Learnings Modules API call --- for data featch
      const renderlearningmodules = async (value) => {
        return CMSAPIWithToken()
        .get("/learnings/admin_learnings_modules/")
        .then((resp) => resp.data.results)
          .then((resp) => {
            resp.forEach((item) => {
              if(item.admin_learnings_id == sb_id){
              learningModulesData.push({
                  id: item.id,
                  admin_learnings_id: learning_title,
                  created: item.created,
                  modified: item.modified,
                actions: (
                  <Grid container wrap="wrap" spacing={1}>
                    <IconButton onClick={() => handlModulesViewButton(item.id)}>
                      <Visibility />
                    </IconButton>
                    <IconButton onClick={() => handleModulesEditButton(item.id)}>
                      <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleModulesDeleteButton(item.id)}>
                      <DeleteForeverIcon/>
                    </IconButton>
                  </Grid>
                ),
                });
              }
            });
           
            setLearningModulesRows(learningModulesData);
            
            setIsLoading(false);
          })
          .catch((err) => {
            let message = err.response.data.message || "unknown error occured";
            setIsLoading(false);
          });
      };

      //Course Overviews API call --- for data featch
      const rendercourseoverview = async (value) => {
        return CMSAPIWithToken()
        .get("/learnings/admin_learnings_course_overviews/")
        .then((resp) => resp.data.results)
          .then((resp) => {
            resp.forEach((item) => {
              if(item.admin_learnings_id == sb_id){
              courseOverviewData.push({
                id: item.id,
                admin_learnings_id: learning_title,
                created: item.created,
                modified: item.modified,
                actions: (
                  <Grid container wrap="wrap" spacing={1}>
                    <IconButton onClick={() => handlCourseOverviewsViewButton(item.id)}>
                      <Visibility />
                    </IconButton>
                    <IconButton onClick={() => handleCourseOverviewsEditButton(item.id)}>
                      <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleCourseOverviewsDeleteButton(item.id)}>
                      <DeleteForeverIcon/>
                    </IconButton>
                  </Grid>
                ),
                });
              }
            });
           
            setCourseOverviewRows(courseOverviewData);
            
            setIsLoading(false);
          })
          .catch((err) => {
            let message = err.response.data.message || "unknown error occured";
            setIsLoading(false);
          });
      };

      //Course ELearnings API call --- for data featch
      const rendercourseelearning = async (value) => {
        return CMSAPIWithToken()
        .get("/learnings/admin_learnings_course_elearnings/")
        .then((resp) => resp.data.results)
          .then((resp) => {
            resp.forEach((item) => {
              if(item.admin_learnings_id == sb_id){
              courseELearningData.push({
                id: item.id,
                admin_learnings_id: learning_title,
                created: item.created,
                modified: item.modified,
                actions: (
                  <Grid container wrap="wrap" spacing={1}>
                    <IconButton onClick={() => handlELearningsViewButton(item.id)}>
                      <Visibility />
                    </IconButton>
                    <IconButton onClick={() => handleELearningsEditButton(item.id)}>
                      <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleELearningsDeleteButton(item.id)}>
                      <DeleteForeverIcon/>
                    </IconButton>
                  </Grid>
                ),
                });
              }
            });
           
            setCourseELearningRows(courseELearningData);
            
            setIsLoading(false);
          })
          .catch((err) => {
            let message = err.response.data.message || "unknown error occured";
            setIsLoading(false);
          });
      };


      //Learning Lab API call --- for data featch
      const renderlearninglab = async (value) => {
        return CMSAPIWithToken()
        .get("/learnings/admin_learnings_course_labs/")
        .then((resp) => resp.data.results)
          .then((resp) => {
            resp.forEach((item) => {
              if(item.admin_learnings_id == sb_id){
              learningLabData.push({
                id: item.id,
                admin_learnings_id: learning_title,
                created: item.created,
                modified: item.modified,
                actions: (
                  <Grid container wrap="wrap" spacing={1}>
                    <IconButton onClick={() => handlLabViewButton(item.id)}>
                      <Visibility />
                    </IconButton>
                    <IconButton onClick={() => handleLabEditButton(item.id)}>
                      <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleLabDeleteButton(item.id)}>
                      <DeleteForeverIcon/>
                    </IconButton>
                  </Grid>
                ),
                });
              }
            });
           
            setLearningLabRows(learningLabData);
            
            setIsLoading(false);
          })
          .catch((err) => {
            let message = err.response.data.message || "unknown error occured";
            setIsLoading(false);
          });
      };


      //Labs Categories API call --- for data featch
      const renderlearningcategory = async (value) => {
        return CMSAPIWithToken()
        .get("/learnings/admin_learnings_course_labs_categories/")
        .then((resp) => resp.data.results)
          .then((resp) => {
            resp.forEach((item) => {
              if(item.admin_learnings_id == sb_id){
                learn_cat_id_map[item.id] = item.lab_name;
                learningCategoryData.push({
                  id: item.id,
                  lab_name: item.lab_name,
                  lab_identifier: item.lab_identifier,
                  created: item.created,
                actions: (
                  <Grid container wrap="wrap" spacing={1}>
                    <IconButton onClick={() => handlCategoriesViewButton(item.id)}>
                      <Visibility />
                    </IconButton>
                    <IconButton onClick={() => handleCategoriesEditButton(item.id)}>
                      <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleCategoriesDeleteButton(item.id)}>
                      <DeleteForeverIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleExerciseForwardButton(item.id)}>
                      <ForwardIcon/>
                    </IconButton>
                  </Grid>
                ),
                });
              }
            });
            localStorage.removeItem("learn_cat_id_map")
            localStorage.setItem("learn_cat_id_map", JSON.stringify(learn_cat_id_map))
            setLearningCategoryRows(learningCategoryData);
            setIsLoading(false);
          })
          .catch((err) => {
            let message = err.response.data.message || "unknown error occured";
            setIsLoading(false);
          });
      };

      //Learning Exercises API call --- for data featch
      const renderlearningexercises = async (value) => {
        return CMSAPIWithToken()
        .get("/learnings/admin_learnings_course_labs_exercises/")
        .then((resp) => resp.data.results)
          .then((resp) => {
            resp.forEach((item) => {
              if(item.admin_learnings_id == sb_id){
              learningExercisesData.push({
                  id: item.id,
                  admin_learnings_cat_id: learn_cat_id_map[item.admin_learnings_course_labs_category_id
                  ],
                  title: item.title,
                  created: item.created,
                actions: (
                  <Grid container wrap="wrap" spacing={1}>
                    <IconButton onClick={() => handlExercisesViewButton(item.id, item.admin_learnings_course_labs_category_id)}>
                      <Visibility />
                    </IconButton>
                    <IconButton onClick={() => handleExercisesEditButton(item.id, item.admin_learnings_course_labs_category_id)}>
                      <EditIcon/>
                    </IconButton>
                    <IconButton onClick={() => handleExercisesDeleteButton(item.id)}>
                      <DeleteForeverIcon/>
                    </IconButton>
                  </Grid>
                ),
                });
              }
            });
           
            setLearningExercisesRows(learningExercisesData);
            
            setIsLoading(false);
          })
          .catch((err) => {
            let message = err.response.data.message || "unknown error occured";
            setIsLoading(false);
          });
      };


//Sandbox Delete Items 
      const handleDeleteButton = (id) => {
        let user_confirm = window.confirm("Do you want to delete excercise?")
        if(user_confirm){
        CMSAPIWithToken()
        .delete("/learnings/admin_learnings"+`/${id}/`)
        .then((resp) => resp.data.results)
        .then((data) => {
          const payload = {
              message: "Learnings Deleted Successfully",
              color: "success",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
          history.push("ProductContentLearningsMenuItems?id="+sb_id+"&title="+learning_title);
          window.location.reload()
      })
      .catch((err) => {
        let message = err.response.data.message;
        const payload = {
          message: message,
          color: "danger",
      };
      payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
      });
      };
      };
//learnings Overview Delete Items 
      const handleOverviewDeleteButton = (id) => {
        let user_confirm = window.confirm("Do you want to delete excercise?")
        if(user_confirm){
        CMSAPIWithToken()
        .delete("/learnings/admin_learnings_overviews"+`/${id}/`)
          .then((resp) => resp.data.results)
          .then((data) => {
          const payload = {
          message: "learnings Overview Deleted Successfully",
          color: "success",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
          history.push("ProductContentLearningsMenuItems?id="+sb_id+"&title="+learning_title);
          window.location.reload()
          })
          .catch((err) => {
            let message = err.response.data.message;
            const payload = {
              message: message,
              color: "danger",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
        };
      }
//learnings modules Delete Items 
      const handleModulesDeleteButton = (id) => {
        let user_confirm = window.confirm("Do you want to delete excercise?")
        if(user_confirm){
        CMSAPIWithToken()
        .delete("/learnings/admin_learnings_modules"+`/${id}/`)
          .then((resp) => resp.data.results)
          .then((data) => {
          const payload = {
          message: "learnings modules Deleted Successfully",
          color: "success",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
          history.push("ProductContentLearningsMenuItems?id="+sb_id+"&title="+learning_title);
          window.location.reload()
          })
          .catch((err) => {
            let message = err.response.data.message;
            const payload = {
              message: message,
              color: "danger",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
        };
      }
//Learnings Course Overviews Delete Items 
      const handleCourseOverviewsDeleteButton = (id) => {
        let user_confirm = window.confirm("Do you want to delete excercise?")
        if(user_confirm){
        CMSAPIWithToken()
        .delete("/learnings/admin_learnings_course_overviews"+`/${id}/`)
          .then((resp) => resp.data.results)
          .then((data) => {
          const payload = {
          message: "Learnings Course Overviews Deleted Successfully",
          color: "success",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
          history.push("ProductContentLearningsMenuItems?id="+sb_id+"&title="+learning_title);
          window.location.reload()
          })
          .catch((err) => {
            let message = err.response.data.message;
            const payload = {
              message: message,
              color: "danger",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
        };
      }
  //Learnings ELearnings Delete Items 
      const handleELearningsDeleteButton = (id) => {
        let user_confirm = window.confirm("Do you want to delete excercise?")
        if(user_confirm){
        CMSAPIWithToken()
        .delete("/learnings/admin_learnings_course_elearnings"+`/${id}/`)
          .then((resp) => resp.data.results)
          .then((data) => {
          const payload = {
          message: "Learnings ELearnings Deleted Successfully",
          color: "success",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
          history.push("ProductContentLearningsMenuItems?id="+sb_id+"&title="+learning_title);
          window.location.reload()
          })
          .catch((err) => {
            let message = err.response.data.message;
            const payload = {
              message: message,
              color: "danger",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
        };
      }
//Learnings Labs Delete Items 
      const handleLabDeleteButton = (id) => {
        let user_confirm = window.confirm("Do you want to delete excercise?")
        if(user_confirm){
        CMSAPIWithToken()
        .delete("/learnings/admin_learnings_course_labs"+`/${id}/`)
          .then((resp) => resp.data.results)
          .then((data) => {
          const payload = {
          message: "Learnings Labs Deleted Successfully",
          color: "success",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
          history.push("ProductContentLearningsMenuItems?id="+sb_id+"&title="+learning_title);
          window.location.reload()
          })
          .catch((err) => {
            let message = err.response.data.message;
            const payload = {
              message: message,
              color: "danger",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
        }; 
      } 
//Course Labs Categories Delete Items 
      const handleCategoriesDeleteButton = (id) => {
        let user_confirm = window.confirm("Do you want to delete excercise?")
        if(user_confirm){
        CMSAPIWithToken()
        .delete("/learnings/admin_learnings_course_labs_categories"+`/${id}/`)
          .then((resp) => resp.data.results)
          .then((data) => {
          const payload = {
          message: "Course Labs Categories Deleted Successfully",
          color: "success",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
          history.push("ProductContentLearningsMenuItems?id="+sb_id+"&title="+learning_title);
          window.location.reload()
          })
          .catch((err) => {
            let message = err.response.data.message;
            const payload = {
              message: message,
              color: "danger",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
        };
      }
      //Course Labs Exercises Delete Items 
      const handleExercisesDeleteButton = (id) => {
        let user_confirm = window.confirm("Do you want to delete excercise?")
        if(user_confirm){
        CMSAPIWithToken()
        .delete("/learnings/admin_learnings_course_labs_exercises"+`/${id}/`)
          .then((resp) => resp.data.results)
          .then((data) => {
          const payload = {
          message: "Course Labs Exercises Deleted Successfully",
          color: "success",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload }));   
          history.push("ProductContentLearningsMenuItems?id="+sb_id+"&title="+learning_title);
          window.location.reload()
          })
          .catch((err) => {
            let message = err.response.data.message;
            const payload = {
              message: message,
              color: "danger",
          };
          payload.message && dispatch(SHOW_NOTIFICATION({ payload })); 
        });
      }
    }
      // view single product details
      const renderDrawerComponent = () => {
        switch (currentDrawerScreen) {
          case "Learnings Overview View":
            return <LearningOverviewView user={currentUser} />;  
          case "Learnings Overview Edit":
            return <LearningOverviewUpdate user={currentUser} />;
          case "Learnings Modules View":
            return <LearningModulesView user={currentUser} />;  
          case "Learnings Modules Edit":
            return <LearningModulesUpdate user={currentUser} />;
          case "Learnings CourseOverviews View":
            return <CourseOverviewView user={currentUser} />;  
          case "Learnings CourseOverviews Edit":
            return <CourseOverviewUpdate user={currentUser} />;
          case "Learnings ELearnings View":
            return <CourseELearningView user={currentUser} />;  
          case "Learnings ELearnings Edit":
            return <CourseELearningUpdate user={currentUser} />;
          case "Learnings Lab View":
            return <LearningLabView user={currentUser} />;  
          case "Learnings Lab Edit":
            return <LearningLabUpdate user={currentUser} />;
          case "Learnings Categories View":
            return <LearningCategoryView user={currentUser} />;  
          case "Learnings Categories Edit":
            return <LearningCategoryUpdate user={currentUser} />;  
          case "Learnings Exercises View":
            return <LearningExercisesView user={currentUser} category={currentCategory}/>;  
          case "Learnings Exercises Edit":
            return <LearningExercisesUpdate user={currentUser} category={currentCategory}/>;                      
          case "default":
            return null;
        }
      };

      const handleViewButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Learnings View");
        setCurrentUser(username);
      };

//code for view button
      const handleOverviewViewButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Learnings Overview View");
        setCurrentUser(username);
      };
      const handlModulesViewButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Learnings Modules View");
        setCurrentUser(username);
      };
      const handlCourseOverviewsViewButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Learnings CourseOverviews View");
        setCurrentUser(username);
      };
      const handlELearningsViewButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Learnings ELearnings View");
        setCurrentUser(username);
      };
      const handlLabViewButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Learnings Lab View");
        setCurrentUser(username);
      };
      const handlCategoriesViewButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Learnings Categories View");
        setCurrentUser(username);
      };
      const handlExercisesViewButton = (username, category_id) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Learnings Exercises View");
        setCurrentUser(username);
        setCurrentCategory(category_id)
      };
//code for edit button      
      const handleOverviewEditButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Learnings Overview Edit");
        setCurrentUser(username);
      };
      const handleModulesEditButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Learnings Modules Edit");
        setCurrentUser(username);
      };
      const handleCourseOverviewsEditButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Learnings CourseOverviews Edit");
        setCurrentUser(username);
      };
      const handleELearningsEditButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Learnings ELearnings Edit");
        setCurrentUser(username);
      };
      const handleLabEditButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Learnings Lab Edit");
        setCurrentUser(username);
      };
      const handleCategoriesEditButton = (username) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Learnings Categories Edit");
        setCurrentUser(username);
      };
      const handleExercisesEditButton = (username, category_id) => {
        setOpenDrawer(true);
        setcurrentDrawerScreen("Learnings Exercises Edit");
        setCurrentUser(username);
        setCurrentCategory(category_id)
      };
    
      //Add new Learning , overview , Details ,Cheat Sheets, Labs ,Categories,Exercises
      const handleAddUserLearningOverview = () => {
        history.push("LearningOverviewCreate?id="+sb_id);  
      };
      const handleAddUserLearningModules = () => {
        history.push("LearningModulesCreate?id="+sb_id);  
      };
      const handleAddUserCourseOverview = () => {
        history.push("CourseOverviewCreate?id="+sb_id);  
      };
      const handleAddUserCourseELearning = () => {
        history.push("CourseELearningCreate?id="+sb_id);  
      };
      const handleAddUserLearningLab = () => {
        history.push("LearningLabCreate?id="+sb_id);  
      };
      const handleAddUserLearningCategory = () => {
        history.push("LearningCategoryCreate?id="+sb_id);  
      };
      const handleAddUserLearningExercises = () => {
        history.push({
          pathname: 'LearningExercisesCreate',
          search: '?id='+sb_id,
          state: { detail: 'some_value' }
        }); 
      };
      const handleExerciseForwardButton = (cat_id) => {
        const query=new URLSearchParams(window.location.search);
        const title = query.get("title");
        history.push("LearningExercisesList?cat_id="+cat_id+"&id="+
        sb_id+ "&title="+title);
      };
    const renderContent = () => {
        const query=new URLSearchParams(window.location.search);
        const title = query.get("title");
        switch (currentScreen) {
            case "Learning Overview":
              return (
                <Grid container className={classes.root} spacing={1}>
                  
                  <Grid item xs={12} md={12}>
                    {showNotification ? renderNotififications() : null}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LoaderHoc isLoading={isLoading}>
                      <CustomTable
                        columns={learningOverviewcolumns}
                        data={learningOverviewRows}
                        handleButtonClick={handleAddUserLearningOverview}
                        tableTitle={title}
                        buttonTitle="Add Learning Overview"
                        hiddenColumns={[]}
                      />
                    </LoaderHoc>
                  </Grid>
                </Grid>
              );
              case "Learning Modules":
              return (
                <Grid container className={classes.root} spacing={1}>
                  <Grid item xs={12} md={12}>
                    {showNotification ? renderNotififications() : null}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LoaderHoc isLoading={isLoading}>
                      <CustomTable
                        columns={learningmodulescolumns}
                        data={learningModulesRows}
                        handleButtonClick={handleAddUserLearningModules}
                        tableTitle={title}
                        buttonTitle="Add Learning Modules"
                        hiddenColumns={[]}
                      />
                    </LoaderHoc>
                  </Grid>
                </Grid>
              );
              case "Course Overview":
              return (
                <Grid container className={classes.root} spacing={1}>
                  <Grid item xs={12} md={12}>
                    {showNotification ? renderNotififications() : null}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LoaderHoc isLoading={isLoading}>
                      <CustomTable
                        columns={courseoverviewcolumns}
                        data={courseOverviewRows}
                        handleButtonClick={handleAddUserCourseOverview}
                        tableTitle={title}
                        buttonTitle="Add Course Overview"
                        hiddenColumns={[]}
                      />
                    </LoaderHoc>
                  </Grid>
                </Grid>
              );
              case "Course ELearning":
              return (
                <Grid container className={classes.root} spacing={1}>
                  <Grid item xs={12} md={12}>
                    {showNotification ? renderNotififications() : null}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LoaderHoc isLoading={isLoading}>
                      <CustomTable
                        columns={courseelearningcolumns}
                        data={courseELearningRows}
                        handleButtonClick={handleAddUserCourseELearning}
                        tableTitle={title}
                        buttonTitle="Add Course ELearning"
                        hiddenColumns={[]}
                      />
                    </LoaderHoc>
                  </Grid>
                </Grid>
              );
              case "Learning Lab":
              return (
                <Grid container className={classes.root} spacing={1}>
                  <Grid item xs={12} md={12}>
                    {showNotification ? renderNotififications() : null}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LoaderHoc isLoading={isLoading}>
                      <CustomTable
                        columns={learninglabcolumns}
                        data={learningLabRows}
                        handleButtonClick={handleAddUserLearningLab}
                        tableTitle={title}
                        buttonTitle="Add Learning Lab"
                        hiddenColumns={[]}
                      />
                    </LoaderHoc>
                  </Grid>
                </Grid>
              );
              case "Learning Category":
              return (
                <Grid container className={classes.root} spacing={1}>
                  <Grid item xs={12} md={12}>
                    {showNotification ? renderNotififications() : null}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LoaderHoc isLoading={isLoading}>
                      <CustomTable
                        columns={learningcategorycolumns}
                        data={learningCategoryRows}
                        handleButtonClick={handleAddUserLearningCategory}
                        tableTitle={title}
                        buttonTitle="Add Learning Category"
                        hiddenColumns={[]}
                      />
                    </LoaderHoc>
                  </Grid>
                </Grid>
              );
              case "Learning Exercises":
              return (
                <Grid container className={classes.root} spacing={1}>
                  <Grid item xs={12} md={12}>
                    {showNotification ? renderNotififications() : null}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <LoaderHoc isLoading={isLoading}>
                      <CustomTable
                        columns={learningexercisescolumns}
                        data={learningExercisesRows}
                        handleButtonClick={handleAddUserLearningExercises}
                        tableTitle={title}
                        buttonTitle="Add Learning Exercises"
                        hiddenColumns={[]}
                      />
                    </LoaderHoc>
                  </Grid>
                </Grid>
              );
          
        }
    };
    return (
        <div>

            <Grid container className={classes.root}>
              <GridContainer className="backbutton">
                <GridItem sm={12} md={12}>
                  <Typography
                    variant="body1"
                    className={classes.backButton}
                    onClick={backButton}
                  >
                    <ArrowBackIcon /> Back
                  </Typography>
                </GridItem>
              </GridContainer>
                <Grid item md={2}>
                    <Menu
                        items={menuItems}
                        onItemClick={setCurrentScreen}
                        currentScreen={currentScreen}
                    />
                </Grid>

                <Grid item md={10}>
                    {renderContent()}
                </Grid>
                 {/* Drawer */}
                 <RightDrawer
                      open={openDrawer}
                      onClose={setOpenDrawer}
                      title={currentInventory}
                    >
                      {renderDrawerComponent()}
                    </RightDrawer>
            </Grid>
        </div>
    );
};

export default ProductContentLearningMenuItems;