import React from "react";

const Part2 = () => {
  return (
    <div>
      <div>
        <div className="heading">
          SDCloud for Enterprises and System Integrators
        </div>
        <br />
        <div className="ml-40">
          Use Criterion SDCloud to{" "}
          <span style={{ fontWeight: " bold" }}>achieve outcomes faster</span>
          &nbsp; with hosted capabilities to evaluate products and technologies,
          design and validate new networks and automate ongoing operations.
        </div>
        <br />
      </div>
      <br />
      <div>
        <div className="heading">
          SDCloud for Product Vendors and Managed Service Providers
        </div>
        <br />
        <div className="ml-40">
          Use Criterion SDCloud to{" "}
          <span style={{ fontWeight: " bold" }}>deliver results faster</span>
          &nbsp; with hosted capabilities to create new services, showcase new
          products and technologies, and train sales and operations teams.
        </div>
        <br />
      </div>
      <div>
        <div className="heading">Mentored Programs</div>
        <br />
        <div className="ml-40">
          Get the most of your SDCloud subscription. Criterion Networks offers
          learning programs for users to get up to speed with the latest
          technologies. Criterion offers guided programs to support your Proof
          of Concepts with Mentored Engagements to get you going in the right
          direction with confidence.
        </div>
        <br />
      </div>
    </div>
  );
};

export default Part2;
