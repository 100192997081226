import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
// import axios from 'axios';

import LoaderHoc from "../../components/HOC/LoaderHoc";

import {SDCloudBackendAPIWithToken} from "../../apis/backendAPI";



function DiskTable() {
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  // const pageSize = 10;
  const [pageSize, setPageSize] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize]);

  const fetchData = async () => {
    // dispatch(SHOW_LOADER());
    try {
      // //console.log(params, 'paramss');

      const response = await SDCloudBackendAPIWithToken()
        .get(`/gcp/get_disk_details?page=${currentPage}&per_page=${pageSize}`);
      setRows(response.data.data);
      setTotalRows(response.data.total);
      setIsLoading(false);
    //   dispatch(HIDE_LOADER());
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // const handlePageChange = (page) => {
  //   //console.log(page, 'pagee')
  //   fetchData({ page, per_page: pageSize });
  // };

  const handlePageChange = (newPage) => {
    //console.log(newPage, 'new page')
    setCurrentPage(newPage);
    fetchData();
    
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const columns = [
    {
        field: 'name',
        headerName: 'Name (UUID)',
        // width: 150
        minWidth: 300, flex: 1
    },
    {
        field: 'zone',
        headerName: 'Zone',
        minWidth: 200, flex: 1
    },
    {
        field: 'status',
        headerName: 'GCP-Status',
        minWidth: 200, flex: 1
    },
    {
        field: 'created_at',
        headerName: 'Created On',
        minWidth: 200, flex: 1
    },
    {
        field: 'size_gb',
        headerName: 'Disk Size (GB)',
        minWidth: 200, flex: 1
    },
    {
        field: 'uuid_db_status',
        headerName: 'Cluster-Status',
        minWidth: 200, flex: 1
    },
    
  ];

  return (
    <div style={{ width: '100%' }}>
      {isLoading ? ( // Conditional rendering based on the loading state
        <div style={{ alignItems:'center', width: '100%' }}>
            <LoaderHoc isLoading={isLoading}></LoaderHoc> 
        </div>
      ) : (
      
      <DataGrid
        getRowId={(row) => row.name}
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        rowCount={totalRows}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
        onPageChange={(newPage) => handlePageChange(newPage)}
        onPageSizeChange={handlePageSizeChange}
        // handling client side pagination for timebeing as there is no much data from the backend
      />
    )}
    </div>
  );
}

export default DiskTable;