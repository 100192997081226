import React, { useEffect, useState } from "react";
// Formik
import { useFormik } from "formik";
// Material UI Components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import {hotjar} from "react-hotjar";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
//checkbox
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
//markdown editor
import MdEditor from 'react-markdown-editor-lite';
import MarkdownIt from 'markdown-it';
//markdown editor import style manually
import 'react-markdown-editor-lite/lib/index.css';
//API Call
import { CMSAPIWithToken } from "../../../apis/CMS";


const useStyles = makeStyles((theme) => ({
  
  gridContainer: {
    padding: "0 40px 30px 40px",
  },
  radioButton: {
    "& .MuiTypography-root": {
      marginLeft: "10px",
    },
  },
  formControl: {
    width: "100%",
    marginTop: "5px",
  },
  Select: {
    marginBottom: "50px",
  },
  warningNote: {
    "& .MuiAlert-icon": {
      display: "none",
    },
  },
  button: {
    marginTop: "50px",
  },
  buttOutlined: {
    color: theme.palette.buttonColor.main,
    borderColor: theme.palette.buttonColor.main,
    "&:hover": {
      border: `1px solid ${theme.palette.buttonColor.main}`,
    },
  },
}));

//mark down editor
function handleEditorChange({ html, text }) {
  //console.log('handleEditorChange', html, text);
}
// Initialize a markdown parser
const mdParser = new MarkdownIt(/* Markdown-it options */);

//sandbox view main
const SandboxView = (props) => {
  const classes = useStyles();
  hotjar.initialize(2281731, 6);
  hotjar.identify(localStorage.userName, { userProperty: localStorage.userName});
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const formik = useFormik({
    initialValues:{
      title: "",
      subtitle:"",
      identity:"",
      price:"",
      price_unit:"",
      description:"",
      email_content:"",
      diagram:"",
      diagram_img:"",
      icon_image:"",
      is_enabled:"",
      access_impairment:"",
      access_tcpreplay:""
    } 
  });

  let data = [];

  const renderSanboxView = async () => {
    let sanboxView = CMSAPIWithToken()
      .get("/poc/admin_sandboxes/")
      .then((resp) => resp.data.results)
      .then((resp) => {
        resp.forEach((item) => {
          if(props.user == item.id) { 
          formik.setFieldValue("title", item?.title);
          formik.setFieldValue("subtitle", item?.subtitle);
          formik.setFieldValue("identity", item?.identity);
          formik.setFieldValue("price", item?.price);
          formik.setFieldValue("price_unit", item?.price_unit);
          formik.setFieldValue("description", item?.description);
          formik.setFieldValue("email_content", item?.email_content);
          formik.setFieldValue("diagram", item?.diagram);
          formik.setFieldValue("diagram_img", item?.diagram_img);
          formik.setFieldValue("icon_image", item?.icon_image);
          formik.setFieldValue("is_enabled", item?.is_enabled);
          formik.setFieldValue("access_impairment", item?.access_impairment);
          formik.setFieldValue("access_tcpreplay", item?.access_tcpreplay);
          formik.setFieldValue("created", item?.created);
          formik.setFieldValue("modified", item?.modified);
        }
        });
      })      
      .catch((err) => {
        let message = err.response.data.message;
        setShowNotification(true);
        setNotificationType("error");
        setNotificationMessage("Failed to retrieve the data");
      });
  };
  const onSubmit = (e) => {
  let item = { title: "", 
                subtitle: "",
                identity: "", 
                price: "",
                price_unit: "", 
                description: "", 
                diagram: "",
                diagram_img: "", 
                icon_image: "", 
                email_content: "",
                is_enabled: false, 
                access_impairment: false, 
                access_tcpreplay: false, 
                account: [ ] } 
  let submit = CMSAPIWithToken() 
  .post("/poc/admin_sandboxes/",item) 
  .then((resp) => resp.data.results) };
  
  useEffect(() => {
    onSubmit();
    renderSanboxView();
  }, []);

  //checkbox
  
    const [state, setState] = React.useState({
      checkedA: true,
      checkedB: true,
      checkedF: true,
      checkedG: true,
    });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <div>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.gridContainer}
      >
        <Grid item xs={12} md={5}>
          <Typography variant="h4">Sandbox View</Typography>
          
        </Grid>

        <Grid item xs={12} md={7}>
        <TextField
          type="text"
          variant="filled"
          label="Title"
          name="title"
          fullWidth
          InputLabelProps=""
          inputProps={{readOnly: true }}
          value={formik.values.title}
          onChange=""
        /> 
        </Grid>

        <Grid item xs={12} md={5}>
        <TextField
          type="text"
          variant="filled"
          label="Subtitle"
          name="subtitle"
          fullWidth
          InputLabelProps=""
          inputProps={{readOnly: true }}
          value={formik.values.subtitle}
          onChange=""
        /> 
        </Grid>

        <Grid item xs={12} md={6}>
        <TextField
          type="text"
          variant="filled"
          label="Identity"
          name="identity"
          fullWidth
          InputLabelProps=""
          inputProps={{readOnly: true }}
          value={formik.values.identity}
          onChange=""
        /> 
        </Grid>
        

        <Grid item xs={12} md={5}>
        <TextField
          type="text"
          variant="filled"
          label="Price"
          name="price"
          fullWidth
          InputLabelProps=""
          inputProps={{readOnly: true }}
          value={formik.values.price}
          onChange=""
        /> 
        </Grid>

        <Grid item xs={12} md={6}>
        <TextField
          type="text"
          variant="filled"
          label="Price Unit"
          name="price_unit"
          fullWidth
          InputLabelProps=""
          inputProps={{readOnly: true }}
          value={formik.values.price_unit}
          onChange=""
        /> 
        </Grid>

        <Grid item xs={12} md={12}>
        <p>Description</p>
          <MdEditor style={{ height: '300px' }} renderHTML={text => mdParser.render(text)} onChange={handleEditorChange} value={formik.values.description}/>
          
        </Grid>

        <Grid item xs={12} md={12}>      
        <TextField
          type="text"
          variant="filled"
          label="Email Content"
          name="email_content"
          fullWidth
          InputLabelProps=""
          inputProps={{readOnly: true }}
          value={formik.values.email_content}
          onChange=""
          multiline
          rows={8}
        /> 
        </Grid>

        <Grid item xs={12} md={12}>
        <TextField
          type="text"
          variant="filled"
          label="Diagram Javascript"
          name="diagram"
          fullWidth
          InputLabelProps=""
          inputProps={{readOnly: true }}
          value={formik.values.diagram}
          onChange=""
        /> 
        </Grid>

        <Grid item xs={12} md={12}>
        <TextField
          type="text"
          variant="filled"
          label="Diagram Img"
          name="diagram_img"
          fullWidth
          InputLabelProps=""
          inputProps={{readOnly: true }}
          value={formik.values.diagram_img}
          onChange=""
        /> 
        </Grid>

        <Grid item xs={12} md={12}>
        <TextField
          type="text"
          variant="filled"
          label="Icon Image"
          name="icon_image"
          fullWidth
          InputLabelProps=""
          inputProps={{readOnly: true }}
          value={formik.values.icon_image}
          onChange=""
        /> 
        </Grid>

        <Grid item xs={12} md={12}>
        <FormGroup row>
      <FormControlLabel
        disabled
        control={<Checkbox checked={formik.values.is_enabled} onChange={handleChange} name="checkedA" />}
        label="is_enabled"
      />

<FormControlLabel
        disabled
        control={<Checkbox checked={formik.values.access_impairment} onChange={handleChange} name="checkedA" />}
        label="access_impairment"
      />

      <FormControlLabel
        disabled
        control={
          <Checkbox
            checked={formik.values.access_tcpreplay}
            onChange={handleChange}
            name="checkedB"
            color="primary"
          />
        }
        label="access_tcpreplay"
      />
      
    </FormGroup>
        </Grid>

        <Grid item xs={12} md={12}>
        <h6>Created Date : {formik.values.created}</h6>
        <h6>Modified Date : {formik.values.modified}</h6>
        </Grid>

      </Grid>
                 
    </div>
  );
};

export default SandboxView;
