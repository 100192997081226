const BASE_URL_CMS = window.REACT_APP_CMS_API_URL;
const BASE_URL = window.REACT_APP_API_BASE_URL;
const ENV = window.REACT_APP_ENV_NAME
localStorage.setItem("BASE_URL", BASE_URL);
localStorage.setItem("BASE_URL_CMS", BASE_URL_CMS);
localStorage.setItem("ENVIRONMENT",ENV)
const token = localStorage.getItem("user");
export const Api = {
  baseURL: BASE_URL,
  // account related
  login: BASE_URL + "/api/token/",
  refreshToken: BASE_URL + "/api/token/refresh/",
  forgetPassword: BASE_URL + "/forgetPassword/",
  resetPassword: BASE_URL + "/reset_password",
  passwordLink: BASE_URL + "/passwordLink",
  getUserProfile: BASE_URL + "/getUserData/",
  updateUserProfile: BASE_URL + "/updateUserData/",
  storeSecurityQs: BASE_URL + "/security/store_qa/",
  validateSecurityQs: BASE_URL + "/security/validate_security_qa/",
  getSecurityQs: BASE_URL + "/security/get_security_qa/",
  getAccountDetails: BASE_URL + "/get_account_details/",
  getSSO: BASE_URL + "/get_sso/",
  accountInfo: BASE_URL + "/account_mgmt_info/",
  getDealIds: BASE_URL + "/get_deal_ids",

  // Self Service Portal
  addTrustedDomain: BASE_URL + "/add_trusted_domain",
  deleteTrustedDomain: BASE_URL + "/delete_trusted_domain",
  addNewUser: BASE_URL + "/add_new_pending_user_request",

  // support
  support: BASE_URL + "/support",
  getSupportresources: BASE_URL + "/resources_info",

  // sales demo
  addHours: BASE_URL + "/add_hours",
  addHoursToSandbox: BASE_URL + "/add_hours_sandbox",
  updateDemoSummary: BASE_URL + "/update_learning_data/",
  terminate: BASE_URL + "/terminate/",

  // learning labs
  provisionLearningLab: BASE_URL + "/provision_newlearning_lab",
  subscribeLearningLab: BASE_URL + "/subscribe_newlearning",

  // sales demo and learning labs
  getNewLearning: BASE_URL + "/get_newlearnings",
  getLabStatus: uuid => `${BASE_URL}/${uuid}/labstatus/`,
  getLabDetail: uuid => `${BASE_URL}/${uuid}/labdetail/`,
  getWebPageVisit: BASE_URL + "/webhook/save_webpage_visit/",
  getResource: BASE_URL + "/get_resources",
  getResource_showcases: BASE_URL + "/get_resources_showcases",
  getResource_sandboxes: BASE_URL + "/get_resources_sandboxes",
  getResource_learnigs: BASE_URL + "/get_resources_learnings",
  getProfile: BASE_URL + "/get_profile",
  terminateLab: BASE_URL + "/terminate_newlearning_lab",
  freeze: BASE_URL + "/freeze_cluster",
  exportLearningSalesconfig:"/export_configurations/",
  // other
  provisonCluster: BASE_URL + "/provision/",
  getClusterlist: BASE_URL + "/clusterlist/",
  getScheduleCluster: BASE_URL + "/list_gcp_scheduler_tasks/",
  delScheduleCluster: BASE_URL + "/delete_gcp_schedule_task/",
  scheduleCluster: BASE_URL + "/schedule_clusters/",
  viewPoCLibraryDetail: BASE_URL + "/",
  logRecord: BASE_URL + "/logrecords/",
  viewPoCLibrary: "/poc/admin_sandboxes/",
  viewPoCLibraryTopo: "/poc/admin_sandboxes_details/",
  s3Upload: BASE_URL + "/s3_upload",
  getLabStats: "/statistics/get_labs_number",
  get_quota_admin: BASE_URL + "/statistics/get_user_quota_admin",
  designerTemplateList: BASE_URL + "/designer/template_list",
  getAccount: BASE_URL + "/get_account",

  // cms api for learning labs and sale demo.
  adminLabTypes: "/general/admin_lab_types/",

  // learning labs CMS
  viewLearningLab: limit =>
    limit
      ? `/learnings/admin_learnings/?limit=${limit}`
      : "/learnings/admin_learnings/",
  admin_learnings_course_elearnings:
    "/learnings/admin_learnings_course_elearnings/",
  // labs introduction (primary)
  adminLearningsCourseLabs: "/learnings/admin_learnings_course_labs/",
  // secondary menu for a course
  adminLearningsCourseLabsCategories:
    "/learnings/admin_learnings_course_labs_categories/",
  // exercise menu of courses
  adminLearningsCourseLabsExercises:
    "/learnings/admin_learnings_course_labs_exercises/",
  // labs overview
  adminLearningsCourseOverviews: "/learnings/admin_learnings_course_overviews/",

  // sales demo CMS
  adminSalesDemo: limit =>
    limit
      ? `/sales/admin_sales_demos/?limit=${limit}`
      : "/sales/admin_sales_demos/",
  admin_sales_demos_cheat_sheets: "/sales/admin_sales_demos_cheat_sheets/",
  admin_sales_demos_course_demos: "/sales/admin_sales_demos_course_demos/",
  admin_sales_demos_use_cases: "/sales/admin_sales_demos_use_cases/",

  // SSO endpoints
  // samlVerify: BASE_URL + "/saml_verify/",
  // getSsoDetails: BASE_URL + "/get_sso_details/",
  // editSsoSettings: BASE_URL + "/edit_sso_settings/",

  // MFA endpoints
  getMfaDetails: BASE_URL + "/totp/create/",
  activateMfaConfig: BASE_URL + "/totp/login/",

  // design page
  validateTemplateName: `${BASE_URL}/designer/validate_template_name`,
  getTemplates: `${BASE_URL}/designer/template_list`,
  getReadyLabCreationTemplates: `${BASE_URL}/designer/list_ready_labcreation_templates`,
  getWizards: `${BASE_URL}/designer/designer_get_wizards_preview`,
  getWizardsXML: `${BASE_URL}/designer/designer_get_wizards`,
  updateWizards: `${BASE_URL}/designer/modify_wizard`,
  deleteTemplate: clusterId =>
    `${BASE_URL}/designer/${clusterId}/action_template`,
  freezeTemplate: clusterId =>
    `${BASE_URL}/designer/${clusterId}/action_template`,
  getResources: "/resources_status",
  getServiceStatus: "/get_service_status/",
  getSandboxClusters: "/clusterlist",
  getObjects: "designer/getobjects",
  createLiveeditTemplate:"designer/create_liveedit_template",
  updateLiveeditTemplate: BASE_URL + "/update_liveedit_template/",
  ExportDeviceConfig: BASE_URL + "/export_configurations/",
  importDeviceConfig: BASE_URL + "/import_configurations/",



  // CMS Sandbox/PoC
  adminSandboxes: "/poc/admin_sandboxes/",
  adminSandboxesDetails: "/poc/admin_sandboxes_details/",
  adminSandboxesExercises: "/poc/admin_sandboxes_exercises/",
  adminSandboxesCategories: "/poc/admin_sandboxes_categories/",
  adminSandboxesCheatsheets: "/poc/admin_sandboxes_cheatsheets/",
  // Sandboxes APIs
  provision: "/provision/",
  clusterStatus: uuid => `/${uuid}/details/`,
  modifyCluster: "/modify_state/",
  terminateCluster: "/terminate/",
  exportSandboxconfig:"/export_configurations/",

  // Platform notification urls
  getNotifications: "/get_notifications",
  getEmailNotifications: "/get_email_notifications",
  createNotification: "/create_notification/",
  updateNotification: "/update_notification/",
  deleteNotification: "/delete_notification/",
  dashboardCustomize: BASE_URL_CMS + "/general/admin_dashboard/",
  CustomizeAccount: BASE_URL_CMS + "/general/accounts/",

  // Static ips url
  getStaticIp: "/staticip/get_static_ip/",
  addStaticIp: "/staticip/create_static_ip/",
  updateStaticIp: "/staticip/update_static_ip/",
  deleteStaticIp: "/staticip/delete_static_ip/",
  downloadIpDetails: "/staticip/download_ip_details/",
  watermarking: BASE_URL_CMS + "/learnings/export_lab_guide",
  get_category:BASE_URL_CMS + "/learnings/get_watermarking_category",
  convert_sandbox:BASE_URL_CMS + "/learnings/convert_sandboxes_to_learnings",
  convert_content:BASE_URL_CMS + "/poc/convert_learnings_to_sandboxes" ,
  //Inventory url
  downloadInventoryExcel : "/inventory/download_inventory_excel",

  // Statistics
  getUserStatistics: "/statistics/user_statistics/",

  // Lifecycle node
  listNodes: "/lifecycle/get_node",
  createNode: "/lifecycle/nodes",
  updateNode: "/lifecycle/nodes",
  listImages: "/lifecycle/get_images",
  deleteImage: "/lifecycle/del_images",
  deleteNode: "/lifecycle/del_node",
  getNodeDetails: "/lifecycle/get_node_details",
  addNodeToInventory: "/inventory/add_new_designer_node",

  // Freeze
  getActiveClusters: option => `/get_active_clusters/${option}`,
  getInventoryOrder:"/inventory/get_inventory_order"
};

export const header = {
  "Content-type": "application/json",
  Authorization: `token ${token}`
};

export const Urls = {
  accessLab: "/access-lab",
  accessSalesDemo: "/access-salesdemo",
  launchNewLearningLab: "/launch-learning-lab",
  launchNewSalesDemo: "/launch-sales-demo",
  launchNewPocDemo: "/launch-poc-demo",
  profile: "/profile",
  passwordReset: "/profile",
  activityLogs: "profile",
  launchSandbox: "launch-sandbox",
  accessSandbox: "access-sandbox",
  elementDetails: "/element-details",
  createElement: "/create-element",
  updateImage: "/upload-image"
};

export const Offerings = {
  LEARNING_LABS: { title: "learning lab", resource: "learninglabs" },
  POC_LIBRARY: { title: "poc", resource: "PoCLibrary" },
  SALES_DEMO: { title: "sales demo", resource: "SalesDemo" }
};
