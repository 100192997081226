import React, { useState, useEffect } from "react";
import Axios from "axios";

// Material UI Components
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { FormGroup, FormControl } from "@material-ui/core";

// Local
import { SDCloudBackendAPIWithToken } from "../../apis/backendAPI";
import NotificationAlert from "../Components/NotificationAlert";
import { Api } from "config/api";

const inputProps = {
  disableAnimation: true,
  shrink: false
};
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },btnStyle: {
    background: `${theme.palette.buttonColor.main} !important`,
  }
}));

const formControlProps = {
  root: {
    marginTop: "10px"
  }
};

function FeedbackSideBar() {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [email, setEmail] = useState(
    JSON.parse(localStorage.getItem("profileData")).email
  );
  const [feedback, setFeedback] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");

  const handleSubmit = e => {
    e.preventDefault();

    SDCloudBackendAPIWithToken()
      .post("/feedback", {
        name: name,
        email: email,
        content: feedback
      })
      .then(resp => {
        return resp.data;
      })
      .then(data => {
        setShowNotification(true);
        setNotificationMessage(data.message);
        setNotificationType("success");
      })
      .catch(err => {
        let message = err.response.data.message;
        setShowNotification(true);
        setNotificationMessage(message);
        setNotificationType("error");
      });
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit}>
            <TextField
              type="text"
              variant="filled"
              label="Your Name"
              fullWidth
              InputLabelProps={inputProps}
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <TextField
              type="email"
              variant="filled"
              label="Enter Your Email For Further Communication"
              fullWidth
              InputLabelProps={inputProps}
              formControlProps={formControlProps}
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
            <TextField
              type="text"
              variant="filled"
              label="Your Feedback"
              fullWidth
              InputLabelProps={inputProps}
              formControlProps={formControlProps}
              multiline
              rows={5}
              value={feedback}
              onChange={e => setFeedback(e.target.value)}
            />

            <Button
              variant="contained"
              color="secondary"
              disableElevation
              size="large"
              fullWidth
              className={classes.btnStyle}
              disableFocusRipple
              type="submit"
            >
              Submit
            </Button>

            <NotificationAlert
              notificationType={notificationType}
              showNotification={showNotification}
              message={notificationMessage}
            />
          </form>
        </Grid>
      </Grid>
    </div>
  );
}

export default FeedbackSideBar;