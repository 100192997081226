import axios from "axios";
import { Api } from "../config/api";

const BACKEND_API_URL = window.REACT_APP_API_BASE_URL;

// export const SDCloudBackendAPIWithToken = () => {
//   const token = localStorage.getItem("user");

//   let axiosObject = axios.create({
//     baseURL: BACKEND_API_URL,
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`,
//     },
//   });

//   const expireAt = localStorage.getItem("token_expire_at");
//   let currentTime = new Date();

//   if (currentTime > expireAt) {
//     //console.log("Expire");
//     axiosObject.interceptors.request.use(
//       (config) => {
//         axios
//           .post(Api.refreshToken, {
//             refresh: localStorage.getItem("refreshToken"),
//           })
//           .then((resp) => {
//             let newToken = resp.data.access;
//             let expiryTime = new Date();

//             expiryTime = expiryTime.setMinutes(expiryTime.getMinutes() + 1);

//             localStorage.setItem("user", newToken);
//             localStorage.setItem("token_expire_at", expiryTime);

//             config.headers["Authorization"] = `Bearer ${newToken}`;
//           })
//           .catch((err) => {
//             //console.log(err);

//             localStorage.removeItem("user");
//             localStorage.removeItem("userName");
//             localStorage.removeItem("refreshToken");
//             localStorage.removeItem("scheduleToken");
//           });

//         return config;
//       },
//       (err) => {
//         //console.log("error in getting ", err);
//       }
//     );

//     return axiosObject;
//   } else {
//     return axiosObject;
//   }
// };

export const SDCloudBackendAPIWithToken = () => {
  let token = localStorage.getItem("user");
  let client_details = localStorage.getItem("client_details");

  let axiosObject = axios.create({
    baseURL: BACKEND_API_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      details: client_details
    }
  });

  axiosObject.interceptors.response.use(
    response => {
      return response;
    },
    function(error) {
      const originalRequest = error.config;
      originalRequest.baseURL = BACKEND_API_URL;
      let refreshToken = localStorage.getItem("refreshToken");
      if (
        refreshToken &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        return axios
          .post(Api.refreshToken, { refresh: refreshToken })
          .then(res => {
            if (res.status === 200) {
              localStorage.setItem("user", res.data.access);
              originalRequest.headers.Authorization = `Bearer ${res.data.access}`;
              return axios(originalRequest);
            }
          })
          .catch(err => {
            localStorage.removeItem("user");
            localStorage.removeItem("userName");
            localStorage.removeItem("profileData");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("available_purposes");
            localStorage.removeItem("available_zones");
            localStorage.removeItem("internal_domain");
            localStorage.removeItem("customAccountName");
            for (let i = 0; i < localStorage.length; i++) {
              let key = localStorage.key(i);
              if (key.startsWith('designer_logs_')) {
                localStorage.removeItem(key);
              }
            }
            window.location.pathname = "/auth/login-page";
          });
      }
      return Promise.reject(error);
    }
  );

  return axiosObject;
};

export const SDCloudBackendAPIWithoutToken = () => {
  let client_details = localStorage.getItem("client_details");
  return axios.create({
    baseURL: BACKEND_API_URL,
    headers: {
      "Content-Type": "application/json",
      details: client_details
    }
  });
};

export const SDCloudCustomizeAPIWithToken = () => {
  let token = localStorage.getItem("user");
  //let client_details = localStorage.getItem("client_details");

  let axiosObject = axios.create({
    baseURL: BACKEND_API_URL,
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${token}`
      // details: client_details
    }
  });

  axiosObject.interceptors.response.use(
    response => {
      return response;
    },
    function(error) {
      const originalRequest = error.config;
      let refreshToken = localStorage.getItem("refreshToken");

      if (
        refreshToken &&
        // error.response.status === 401 &&
        // !originalRequest._retry &&
        error.response.status === 403
      ) {
        originalRequest._retry = true;
        return axios
          .post(Api.refreshToken, { refresh: refreshToken })
          .then(res => {
            if (res.status === 200) {
              localStorage.setItem("user", res.data.access);
              originalRequest.headers.Authorization = `token ${res.data.access}`;
              return axios(originalRequest);
            }
          })
          .catch(err => {
            localStorage.removeItem("user");
            localStorage.removeItem("userName");
            localStorage.removeItem("profileData");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("available_purposes");
            localStorage.removeItem("internal_domain");
            localStorage.removeItem("customAccountName");
            localStorage.removeItem("available_zones");
            for (let i = 0; i < localStorage.length; i++) {
              let key = localStorage.key(i);
              if (key.startsWith('designer_logs_')) {
                localStorage.removeItem(key);
              }
            }
            window.location.pathname = "/auth/login-page";
          });
      }
      return Promise.reject(error);
    }
  );

  return axiosObject;
};
