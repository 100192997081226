import React from "react";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Api } from "config/api";
import { makeStyles } from "@material-ui/core/styles";

import moment from "moment";
import AddHoursModal from "views/Components/ServiceOffering/AddHoursModal";
import Snackbar from "components/Snackbar/Snackbar";
import AddAlert from "@material-ui/icons/AddAlert";
import { PRIMARY_COLOR, SECONDARY_COLOR } from "global_constants/app_constants";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
import styler from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import {
  Button,
  Fab,
  FilledInput,
  Icon,
  Paper,
  TextField,
  Typography
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import parse from "html-react-parser";
import ReactMarkdown from "react-markdown";
import htmlParser from "react-markdown/plugins/html-parser";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter";
import NavPills from "components/NavPills/NavPills";
import Loader from "react-loader-spinner";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import { header } from "config/api";
import clsx from "clsx";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import { SDCloudBackendAPIWithToken } from "apis/backendAPI";
import { Urls } from "config/api";
import theme from "theme";
const style = {
  margin: 0,
  top: "auto",
  right: "auto",
  bottom: 20,
  left: "auto",
  borderRadius: "0px",
  position: "fixed"
};

const useStyler = makeStyles(styler);
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  margin: {
    margin: theme.spacing(1)
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {
    width: "50ch"
  },
  textField2: {
    width: "105ch"
  }
}));

export default function AccessSales(props) {
  const newClass = useStyler();
  const classes = useStyles();
  const [uuid1, setUUID] = React.useState(props.location.state.stateParams);
  const [detail, setDetail] = React.useState([]);
  const [alert, setAlert] = React.useState(null);
  const [tr, setTR] = React.useState(true);
  const [ip, setIP] = React.useState([]);
  const [ac, setAcessTab] = React.useState(true);
  const [value, setValue] = React.useState([]);
  const [top, setTopo] = React.useState([]);
  const [use, setUseCase] = React.useState([]);
  const [cheat, setCheat] = React.useState([]);
  const [sol, setSol] = React.useState([]);
  const [loader, setLoader] = React.useState(true);
  const [sid, setID] = React.useState(1);
  const [pid, setPID] = React.useState("0");
  const [collapse, setCollapse] = React.useState(false);
  const [hideAddHoursModal, setHideAddHoursModal] = React.useState(true);
  const [custName, setCustName] = React.useState("");
  const [summ, setSummary] = React.useState("");

  const [fold, setFold] = React.useState({
    side: 2,
    main: 10,
    sideCollapse: 1,
    mainCollapse: 11
  });

  const handleCollapse = () => {
    setCollapse(!collapse);
  };

  setInterval(() => {
    SDCloudBackendAPIWithToken()
      .get(Api.viewPoCLibraryDetail + uuid1.uuid + "/labstatus/")
      .then(value => {
        setDetail([value.data]);
      })
      .catch(err => {
        //console.log(err);
      });
  }, 500000);

  React.useEffect(() => {
    SDCloudBackendAPIWithToken()
      .get(Api.viewPoCLibraryDetail + uuid1.uuid + "/labstatus/")
      .then(value => {
        setDetail([value.data]);
      })
      .catch(err => {
        //console.log(err);
      });

    SDCloudBackendAPIWithToken()
      .get(Api.viewPoCLibraryDetail + uuid1.uuid + "/labdetail/")
      .then(value => {
        setIP([value.data]);
      })
      .catch(err => {
        //console.log(err);
      });

    SDCloudBackendAPIWithToken()
      .get(Api.adminSalesDemo, { "Content-Type": "application/json" })
      .then(data => {
        setValue(data.data.results);
        setLoader(false);
      })
      .catch(err => {
        //console.log(err);
      });

    SDCloudBackendAPIWithToken()
      .get(Api.admin_sales_demos_course_demos, {
        "Content-Type": "application/json"
      })
      .then(data => {
        setTopo(data.data.results);
      })
      .catch(err => {
        //console.log(err);
      });

    SDCloudBackendAPIWithToken()
      .get(Api.admin_sales_demos_use_cases, {
        "Content-Type": "application/json"
      })
      .then(data => {
        setUseCase(data.data.results);
      })
      .catch(err => {
        //console.log(err);
      });
    SDCloudBackendAPIWithToken()
      .get(Api.admin_sales_demos_cheat_sheets, {
        "Content-Type": "application/json"
      })
      .then(data => {
        setCheat(data.data.results);
      })
      .then(() => {
        detail.map((x, index) => {
          value.map((y, index2) => {
            x.labname === y.identifier &&
              cheat.map((z, index3) => {
                y.id === z.admin_sales_demos_id && handleExercise(z);
              });
          });
        });
      })
      .catch(err => {
        //console.log(err);
      });
  }, []);

  const handleTerminate = (x, y) => {
    inputConfirmAlertNext();
  };

  const inputAlert = (x, y) => {
    setAlert(
      <SweetAlert
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        title="Are You Sure ? "
        onConfirm={() => {
          handleTerminate(x, y);
        }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={newClass.button + " " + newClass.info}
        cancelBtnCssClass={newClass.button}
      />
    );
  };
  const inputConfirmAlertNext = () => {
    setAlert();
    const data = {
      uuid: uuid1.uuid
    };
    SDCloudBackendAPIWithToken()
      .post(Api.terminate, data)
      .then(() => {
        setAlert(
          <Snackbar
            place="tr"
            color="success"
            message={`Terminated Successfully`}
            open={tr}
            closeNotification={() => hideAlert()}
            close
          />
        );
        props.history.push({ pathname: Urls.launchNewSalesDemo });
      })
      .catch(err => {
        setAlert(
          <Snackbar
            place="tr"
            color="error"
            message={err}
            open={tr}
            closeNotification={() => hideAlert()}
            close
          />
        );
      });
  };
  const hideAlert = () => {
    setAlert(null);
  };

  const handleMenu = event => {
    setID(event);
  };

  const handleExercise = event => {
    ComputeEndPanel(event.solution);
    setPID(event.exercise_position);
  };
  const handleComment = event => {
    setSummary(event.target.value);
  };
  const handleCustomer = event => {
    setCustName(event.target.value);
  };
  const handleSummary = (name, c_name) => {
    const data = {
      uuid: uuid1.uuid,
      name: name,
      cust_name: c_name === null ? custName : c_name,
      learning_summary: summ,
      progres_data: ""
    };
    SDCloudBackendAPIWithToken()
      .post(Api.salesDemoSummary, data)
      .then(() => {
        setAlert(
          <Snackbar
            place="tr"
            color="success"
            message={`Updated Successfully`}
            open={tr}
            closeNotification={() => hideAlert()}
            close
          />
        );
      })
      .catch(err => {
        //console.log(err);
        setAlert(
          <Snackbar
            place="tr"
            color="error"
            message={err}
            open={tr}
            closeNotification={() => hideAlert()}
            close
          />
        );
      });
  };

  const ComputeEndPanel = event => {
    let panel = "";
    panel = event.split("%%endpanel%%");
    setSol(panel);
  };

  function Image(props) {
    return <img {...props} style={{ maxWidth: "100%" }} />;
  }

  function Head(props) {
    return (
      <div style={{ padding: "10px" }}>
        <Paper
          variant="outlined"
          square
          elevation={2}
          style={{
            backgroundColor: `${PRIMARY_COLOR}`,
            padding: "10px",
            color: "#ffffff"
          }}
        >
          <Typography variant={`h${props.level}`} {...props} />
        </Paper>
      </div>
    );
  }

  const IPAddress = ip.map((x, index) => {
    let p = "0.0.0.0";
    for (p in x.ipdetails) {
      //console.log(x.ipdetails[p]);

      return x.ipdetails[p].toString();
    }
  });

  const TimeDiff = detail.map((x, index) => {
    let time = new Date(x.expiry_date);

    let ms = moment(time).diff(moment(Date()));
    let d = moment.duration(ms);
    let hrs =
      Math.floor(d.asHours()) +
      " Hours   " +
      moment.utc(ms).format("mm") +
      " Minutes";

    return hrs;
  });

  const Intro = detail.map((x, index) =>
    value.map(
      (y, index2) =>
        x.labname === y.identifier &&
        top.map(
          (z, index3) =>
            y.id === z.admin_sales_demos_id && (
              <div>
                <Card>
                  <CardBody>
                    <div style={{ marginLeft: "20px" }}>
                      <ReactMarkdown source={z.content} />
                    </div>
                  </CardBody>
                </Card>
              </div>
            )
        )
    )
  );

  const Notes = detail.map((x, index) =>
    value.map(
      (y, index2) =>
        x.labname === y.identifier && (
          <div>
            <GridContainer>
              <GridItem xs={12} md={12} sm={12}>
                <Card>
                  <CardBody>
                    <div style={{ marginLeft: "20px" }}>
                      {cheat.map(
                        (z, index3) =>
                          y.id === z.admin_sales_demos_id && (
                            <>
                              <Button
                                onClick={() => handleExercise(z)}
                                variant="outlined"
                                style={{
                                  borderBottomColor: `${
                                    pid === z.exercise_position
                                      ? PRIMARY_COLOR
                                      : "white"
                                  }`,
                                  borderLeftColor: "white",
                                  borderRightColor: "white",
                                  borderTopColor: "white"
                                }}
                              >
                                {z.title}
                              </Button>
                              {"  "}
                            </>
                          )
                      )}
                    </div>
                    {cheat.map(
                      (z, index3) =>
                        pid === z.exercise_position &&
                        y.id === z.admin_sales_demos_id && (
                          <div
                            style={{
                              padding: "15px",
                              marginLeft: "20px"
                            }}
                          >
                            <ReactMarkdown source={z.intro_list} />
                          </div>
                        )
                    )}
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} md={12} sm={12}>
                {cheat.map(
                  (z, index3) =>
                    y.id === z.admin_sales_demos_id &&
                    pid === z.exercise_position &&
                    sol.map((a, index5) => (
                      <div key={index5}>
                        {index5 < sol.length - 1 && (
                          <Paper
                            elevation={2}
                            style={{
                              fontWeight: "bolder",
                              padding: "15px"
                            }}
                          >
                            <ReactMarkdown
                              source={a.split("%%beginpanel%%")[1]}
                              renderers={{ image: Image, heading: Head }}
                            />
                          </Paper>
                        )}
                        <br />
                      </div>
                    ))
                )}
              </GridItem>
            </GridContainer>
          </div>
        )
    )
  );

  const UseCase = detail.map((x, index) =>
    value.map(
      (y, index2) =>
        x.labname === y.identifier &&
        use.map(
          (z, index3) =>
            y.id === z.admin_sales_demos_id && (
              <Card>
                <CardBody>
                  <div style={{ marginLeft: "20px" }}>
                    <ReactMarkdown source={z.content} />

                    <Button
                      href={"http://" + IPAddress}
                      target="blank"
                      variant="outlined"
                      color="secondary"
                    >
                      <b>Launch</b>
                    </Button>
                  </div>
                </CardBody>
              </Card>
            )
        )
    )
  );

  const Summary = detail.map((x, index) =>
    value.map(
      (y, index2) =>
        x.labname === y.identifier &&
        top.map(
          (z, index3) =>
            y.id === z.admin_sales_demos_id && (
              <div style={{ marginLeft: "20px" }}>
                <Card>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={1} md={1} sm={1}></GridItem>
                      <GridItem xs={5} md={5} sm={5}>
                        {" "}
                        <h2>
                          <strong>Demo summary</strong>
                        </h2>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={1} md={1} sm={1}></GridItem>
                      <GridItem xs={4} md={4} sm={4}>
                        <FormControl
                          className={clsx(classes.margin, classes.textField)}
                          variant="filled"
                        >
                          <p>Demo name</p>
                          <FilledInput
                            id="filled-adornment-weight"
                            value={x.labname}
                            aria-describedby="filled-weight-helper-text"
                            inputProps={{
                              "aria-label": "weight"
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={6} md={6} sm={6}>
                        <FormControl
                          className={clsx(classes.margin, classes.textField)}
                          variant="filled"
                        >
                          <p>Customer name</p>
                          <FilledInput
                            id="filled-adornment-weight"
                            defaultValue={x.customer_name}
                            aria-describedby="filled-weight-helper-text"
                            onChange={handleCustomer}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={1} md={1} sm={1}></GridItem>
                      <GridItem xs={4} md={4} sm={4}>
                        <FormControl
                          className={clsx(classes.margin, classes.textField)}
                          variant="filled"
                        >
                          <p>User name</p>
                          <FilledInput
                            id="filled-adornment-weight"
                            value={x.userid}
                            aria-describedby="filled-weight-helper-text"
                            inputProps={{
                              "aria-label": "weight"
                            }}
                          />
                        </FormControl>
                      </GridItem>
                      <GridItem xs={6} md={6} sm={6}>
                        <FormControl
                          className={clsx(classes.margin, classes.textField)}
                          variant="filled"
                        >
                          <p>Demo Date</p>
                          <FilledInput
                            id="filled-adornment-weight"
                            value={x.created_date}
                            aria-describedby="filled-weight-helper-text"
                            inputProps={{
                              "aria-label": "weight"
                            }}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={1} md={1} sm={1}></GridItem>

                      <GridItem xs={6} md={6} sm={6}>
                        <FormControl
                          className={clsx(classes.margin, classes.textField2)}
                          variant="filled"
                        >
                          <p>Addtional Comments</p>
                          <TextField
                            id="filled-multiline-static"
                            multiline
                            rows={6}
                            variant="filled"
                            onChange={handleComment}
                          />
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={4} md={4} sm={4}></GridItem>

                      <GridItem xs={6} md={6} sm={6}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() =>
                            handleSummary(x.labName, x.customer_name)
                          }
                        >
                          submit
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </div>
            )
        )
    )
  );

  const handleAccessDeviceTab = v => {
    setAcessTab(v);
  };
  const handleAddModal = () => {
    setHideAddHoursModal(false);
  };
  const hidAddHoursModal = () => {
    setHideAddHoursModal(true);
  };
  const AccessDev = detail.map((x, index) =>
    value.map(
      (y, index2) =>
        x.labname === y.identifier && (
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem xs={1} ms={1} sm={1}>
                  <Button
                    onClick={() => handleAccessDeviceTab(true)}
                    variant="outlined"
                    style={{
                      borderBottomColor: `${ac ? PRIMARY_COLOR : "white"}`,
                      borderLeftColor: "white",
                      borderRightColor: "white",
                      borderTopColor: "white"
                    }}
                  >
                    Topology
                  </Button>
                </GridItem>
                <GridItem xs={2} ms={2} sm={2}>
                  <Button
                    onClick={() => handleAccessDeviceTab(false)}
                    variant="outlined"
                    style={{
                      borderBottomColor: `${
                        ac == false ? PRIMARY_COLOR : "white"
                      }`,
                      borderLeftColor: "white",
                      borderRightColor: "white",
                      borderTopColor: "white"
                    }}
                  >
                    IP Details
                  </Button>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={8} md={8} sm={8}>
                  {ac ? (
                    parse(
                      y.access_details
                        .split("custom-underlay1:80")
                        .join(IPAddress)
                        .replace("img", 'img style="width:100%"')
                    )
                  ) : (
                    <span>
                      {detail.map((x, index) =>
                        value.map(
                          (y, index2) =>
                            x.labname === y.identifier &&
                            top.map(
                              (z, index3) =>
                                y.id === z.admin_sales_demos_id && (
                                  <div>
                                    {" "}
                                    <ReactMarkdown
                                      source={z.ip_details_access
                                        .split("custom-underlay1")
                                        .join(IPAddress)}
                                      renderers={{ image: Image }}
                                    />
                                  </div>
                                )
                            )
                        )
                      )}
                    </span>
                  )}
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        )
    )
  );

  return (
    <div>
      {loader ? (
        <GridContainer justify="center">
          <GridItem md={6}>
            <div style={{ marginTop: "40%", marginLeft: "40%" }}>
              <Loader type="ThreeDots" height={50} width={50} color={theme.palette.secondary.main} />
            </div>
          </GridItem>
        </GridContainer>
      ) : (
        <div>
          <GridContainer>
            <GridItem xs={6}>
              <div
                style={{
                  marginTop: "14px"
                }}
              >
                <h2>
                  {detail.map((x, index) =>
                    value.map(
                      (y, index2) =>
                        x.labname === y.identifier && (
                          <b key={index}>{y.full_title}</b>
                        )
                    )
                  )}
                </h2>
              </div>
            </GridItem>
            <GridItem xs={6}>
              <div style={{ marginTop: "14px" }}>
                <GridContainer justify="center">
                  <GridItem xs={2}>
                    <div style={{ padding: "8px", fontWeight: "bolder" }}>
                      {detail.map((x, index) => (
                        <Button
                          key={index}
                          color="secondary"
                          style={{
                            fontSize: "12px"
                          }}
                        >
                          <Icon fontSize="small" color="secondary">
                            {x.status === "ready" ? "done" : "query_builder"}
                          </Icon>
                          &nbsp;&nbsp;{x.status}
                        </Button>
                      ))}
                    </div>
                  </GridItem>
                  <GridItem xs={1}></GridItem>

                  <GridItem xs={4}>
                    <div style={{ padding: "8px" }}>
                      {" "}
                      <p>
                        Time Left :{" "}
                        <Button
                          style={{
                            fontSize: "12px"
                          }}
                        >
                          {TimeDiff}
                        </Button>
                      </p>
                    </div>
                  </GridItem>
                  <GridItem xs={4} md={4} sm={4}>
                    <AddHoursModal
                      addHoursDetails={{
                        uuid: uuid1?.uuid ?? null,
                        labName: uuid1?.labName ?? "",
                        labIdentifier: uuid1?.labIdentifier ?? null,
                        addhoursLimit:
                          uuid1?.addHoursDetails?.hours_limit ?? null
                      }}
                      offeringType={uuid1?.offeringType?.title ?? null}
                      openModal={hideAddHoursModal}
                      hideModal={hidAddHoursModal}
                    />
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={handleAddModal}
                      style={{ marginTop: "27px" }}
                    >
                      +add Hours
                    </Button>
                  </GridItem>
                </GridContainer>
              </div>
            </GridItem>
          </GridContainer>
          <br />
          <GridContainer>
            <GridItem xs={collapse ? fold.sideCollapse : fold.side}>
              {collapse === false && (
                <div style={{ marginTop: "45px" }}>
                  <Paper
                    elevation={sid === 1 ? 1 : 2}
                    onClick={() => handleMenu(1)}
                    style={{
                      padding: "10px",
                      marginTop: "10px",
                      color: sid === 1 ? "#ffffff" : SECONDARY_COLOR,
                      backgroundColor: sid === 1 ? SECONDARY_COLOR : "#ffffff"
                    }}
                  >
                    {detail.map((x, index) =>
                      value.map(
                        (y, index2) =>
                          x.labname === y.identifier && (
                            <GridContainer justify="center">
                              <GridItem xs={9}>
                                <b key={index} style={{ fontWeight: "bolder" }}>
                                  {" "}
                                  Introduction
                                </b>
                              </GridItem>
                            </GridContainer>
                          )
                      )
                    )}
                  </Paper>

                  <Paper
                    elevation={sid === 2 ? 1 : 2}
                    onClick={() => handleMenu(2)}
                    style={{
                      padding: "10px",
                      marginTop: "10px",
                      color: sid === 2 ? "#ffffff" : SECONDARY_COLOR,
                      backgroundColor: sid === 2 ? SECONDARY_COLOR : "#ffffff"
                    }}
                  >
                    {detail.map((x, index) =>
                      value.map(
                        (y, index2) =>
                          x.labname === y.identifier && (
                            <GridContainer justify="center">
                              <GridItem xs={9}>
                                <b key={index} style={{ fontWeight: "bolder" }}>
                                  {" "}
                                  Notes
                                </b>
                              </GridItem>
                            </GridContainer>
                          )
                      )
                    )}
                  </Paper>

                  <Paper
                    elevation={sid === 3 ? 1 : 2}
                    onClick={() => handleMenu(3)}
                    style={{
                      padding: "10px",
                      color: sid === 3 ? "#ffffff" : SECONDARY_COLOR,
                      marginTop: "10px",
                      backgroundColor: sid === 3 ? SECONDARY_COLOR : "#ffffff"
                    }}
                  >
                    {detail.map((x, index) =>
                      value.map(
                        (y, index2) =>
                          x.labname === y.identifier && (
                            <GridContainer justify="center">
                              <GridItem xs={9}>
                                <b key={index} style={{ fontWeight: "bolder" }}>
                                  {" "}
                                  Use Cases
                                </b>
                              </GridItem>
                            </GridContainer>
                          )
                      )
                    )}
                  </Paper>
                  <Paper
                    elevation={sid === 4 ? 1 : 2}
                    onClick={() => handleMenu(4)}
                    style={{
                      padding: "10px",
                      color: sid === 4 ? "#ffffff" : SECONDARY_COLOR,
                      marginTop: "10px",
                      backgroundColor: sid === 4 ? SECONDARY_COLOR : "#ffffff"
                    }}
                  >
                    {detail.map((x, index) =>
                      value.map(
                        (y, index2) =>
                          x.labname === y.identifier && (
                            <GridContainer justify="center">
                              <GridItem xs={9}>
                                <b key={index} style={{ fontWeight: "bolder" }}>
                                  {" "}
                                  Summary
                                </b>
                              </GridItem>
                            </GridContainer>
                          )
                      )
                    )}
                  </Paper>
                  <Paper
                    elevation={sid === 5 ? 1 : 2}
                    onClick={() => handleMenu(5)}
                    style={{
                      padding: "10px",
                      color: sid === 5 ? "#ffffff" : SECONDARY_COLOR,
                      marginTop: "10px",
                      backgroundColor: sid === 5 ? SECONDARY_COLOR : "#ffffff"
                    }}
                  >
                    {detail.map((x, index) =>
                      value.map(
                        (y, index2) =>
                          x.labname === y.identifier && (
                            <GridContainer justify="center">
                              <GridItem xs={9}>
                                <b key={index} style={{ fontWeight: "bolder" }}>
                                  {" "}
                                  Access Devices
                                </b>
                              </GridItem>
                            </GridContainer>
                          )
                      )
                    )}
                  </Paper>
                  <hr />
                  <div style={style}>
                    <p>
                      <strong>Settings</strong>
                    </p>
                    <Button
                      style={{ borderColor: "red", color: "red" }}
                      variant="outlined"
                      onClick={() => inputAlert("a", "b")}
                    >
                      <div style={{ marginTop: "7%" }}>
                        <GridContainer justify="center">
                          <GridItem xs={12} ms={12} sm={12}>
                            <p>Terminate Demo</p>
                          </GridItem>
                        </GridContainer>
                      </div>
                    </Button>
                  </div>
                </div>
              )}
            </GridItem>
            {alert}
            <GridItem xs={collapse ? fold.mainCollapse : fold.main}>
              {sid === 1
                ? Intro
                : sid === 2
                ? Notes
                : sid === 3
                ? UseCase
                : sid === 4
                ? Summary
                : sid === 5 && AccessDev}
            </GridItem>
          </GridContainer>
        </div>
      )}
    </div>
  );
}